.heading-02 {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  gap: 7px;
  position: relative;
  width: 880px;
}

.heading-02 .text-wrapper-23 {
  align-self: stretch;
  color: #454545;
  margin-top: -1px;
  position: relative;
}

.heading-02 .line-8 {
  align-self: stretch !important;
  flex: 0 0 auto !important;
  margin-bottom: -1px !important;
  position: relative !important;
  width: 100% !important;
}

.heading-02 .one-12-one {
  font-family: "Noto Sans JP", Helvetica;
  font-size: 24px;
  font-weight: 700;
  letter-spacing: 1.44px;
  line-height: 43px;
}

.heading-02 .one-12-SP {
  font-family: var(--bold-SP-font-family);
  font-size: var(--bold-SP-font-size);
  font-style: var(--bold-SP-font-style);
  font-weight: var(--bold-SP-font-weight);
  letter-spacing: var(--bold-SP-letter-spacing);
  line-height: var(--bold-SP-line-height);
}
