.privacypolicy {
  align-items: center;
  background-color: var(--backwhite);
  display: flex;
  flex-direction: column;
  position: relative;
}

.privacypolicy .header-28 {
  align-self: stretch !important;
  display: flex !important;
  flex: 0 0 auto !important;
  justify-content: space-between !important;
  width: 100% !important;
  z-index: 1 !important;
}

.privacypolicy .header-29 {
  z-index: 1 !important;
}

.privacypolicy .header-30 {
  height: 59px !important;
  position: relative !important;
  width: 60px !important;
  z-index: 0 !important;
}

.privacypolicy .div-19 {
  align-items: flex-start;
  align-self: stretch;
  background-color: #ffffff;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  position: relative;
  width: 100%;
  z-index: 0;
}

.privacypolicy .midashi-6 {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  position: relative;
  width: 100%;
}

.privacypolicy .heading-33 {
  align-self: stretch !important;
  flex: 0 0 auto !important;
  width: 100% !important;
}

.privacypolicy .heading-34 {
  color: #ffffff !important;
  left: -37px !important;
}

.privacypolicy .heading-35 {
  color: #ffffff !important;
  left: -111px !important;
}

.privacypolicy .breadcrumbs-9 {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  gap: 30px;
  justify-content: center;
  padding: 20px 15px 40px;
  position: relative;
  width: 100%;
}

.privacypolicy .frame-267 {
  align-items: center;
  display: flex;
  flex: 1;
  flex-grow: 1;
  gap: 30px;
  padding: 0px 50px;
  position: relative;
}

.privacypolicy .text-wrapper-329 {
  color: var(--gray);
  font-family: var(--tag-font-family);
  font-size: var(--tag-font-size);
  font-style: var(--tag-font-style);
  font-weight: var(--tag-font-weight);
  letter-spacing: var(--tag-letter-spacing);
  line-height: var(--tag-line-height);
  margin-top: -1px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.privacypolicy .instance-12 {
  height: 8.14px !important;
  position: relative !important;
  width: 4.57px !important;
}

.privacypolicy .privacypolicys {
  align-items: center;
  align-self: stretch;
  background-color: var(--palepurple);
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 40px;
  padding: 80px 15px;
  position: relative;
  width: 100%;
}

.privacypolicy .frame-268 {
  align-items: flex-start;
  background-color: var(--back-white);
  border-radius: 14px;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 40px;
  padding: 50px 60px;
  position: relative;
  width: 1000px;
}

.privacypolicy .frame-269 {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 24px;
  position: relative;
  width: 100%;
}

.privacypolicy .heading-02-instance {
  flex: 0 0 auto !important;
}

.privacypolicy .div-20 {
  align-self: stretch;
  color: #454545;
  font-family: "Noto Sans JP", Helvetica;
  font-size: 15px;
  font-weight: 400;
  letter-spacing: 0.75px;
  line-height: 25px;
  position: relative;
}

.privacypolicy .frame-270 {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 5px;
  position: relative;
  width: 100%;
}

.privacypolicy .bulletpoint {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  flex-wrap: wrap;
  gap: 5px 5px;
  position: relative;
  width: 100%;
}

.privacypolicy .group-35 {
  height: 28px;
  position: relative;
  width: 17px;
}

.privacypolicy .ellipse-4 {
  background-color: #6079c1;
  border-radius: 5px;
  height: 10px;
  left: 3px;
  position: relative;
  top: 9px;
  width: 10px;
}

.privacypolicy .text-wrapper-330 {
  color: #454545;
  flex: 1;
  font-family: var(--text-font-family);
  font-size: var(--text-font-size);
  font-style: var(--text-font-style);
  font-weight: var(--text-font-weight);
  letter-spacing: var(--text-letter-spacing);
  line-height: var(--text-line-height);
  margin-top: -1px;
  position: relative;
}

.privacypolicy .flexcontainer-35 {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.privacypolicy .text-wrapper-331 {
  letter-spacing: 0.11px;
}

.privacypolicy .text-22 {
  align-self: stretch;
  color: transparent;
  font-family: "Noto Sans JP", Helvetica;
  font-size: 15px;
  font-weight: 400;
  letter-spacing: 0.75px;
  line-height: 25px;
  position: relative;
}

.privacypolicy .text-wrapper-332 {
  color: #454545;
  letter-spacing: 0.11px;
}

.privacypolicy .text-wrapper-333 {
  color: #d45252;
  letter-spacing: 0.11px;
}

.privacypolicy .frame-271 {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 30px;
  position: relative;
  width: 100%;
}

.privacypolicy .text-23 {
  align-self: stretch;
  color: #454545;
  font-family: "Noto Sans JP", Helvetica;
  font-size: 15px;
  font-weight: 400;
  line-height: 15px;
  position: relative;
}

.privacypolicy .text-wrapper-334 {
  font-family: var(--text-bold-font-family);
  font-size: var(--text-bold-font-size);
  font-style: var(--text-bold-font-style);
  font-weight: var(--text-bold-font-weight);
  letter-spacing: var(--text-bold-letter-spacing);
  line-height: var(--text-bold-line-height);
}

.privacypolicy .text-wrapper-335 {
  font-family: var(--text-font-family);
  font-size: var(--text-font-size);
  font-style: var(--text-font-style);
  font-weight: var(--text-font-weight);
  letter-spacing: var(--text-letter-spacing);
  line-height: var(--text-line-height);
}

.privacypolicy .text-wrapper-336 {
  align-self: stretch;
  color: #454545;
  font-family: var(--text-font-family);
  font-size: var(--text-font-size);
  font-style: var(--text-font-style);
  font-weight: var(--text-font-weight);
  letter-spacing: var(--text-letter-spacing);
  line-height: var(--text-line-height);
  position: relative;
}

.privacypolicy .CONTACT-20 {
  align-items: center;
  align-self: stretch;
  background-color: var(--backwhite);
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 40px;
  padding: 100px 15px;
  position: relative;
  width: 100%;
}

.privacypolicy .CONTACT-21 {
  height: 160px;
  position: relative;
  width: 700px;
}

.privacypolicy .frame-272 {
  align-items: center;
  background-color: var(--purple);
  border-radius: 6px;
  display: flex;
  flex-wrap: wrap;
  gap: 20px 74px;
  justify-content: center;
  max-width: 700px;
  padding: 50px 60px;
  position: relative;
  width: 700px;
}

.privacypolicy .group-36 {
  height: 60px;
  position: relative;
  width: 216px;
}

.privacypolicy .overlap-group-11 {
  height: 60px;
  position: relative;
  width: 212px;
}

.privacypolicy .text-wrapper-337 {
  color: var(--white);
  font-family: "Zen Kaku Gothic New", Helvetica;
  font-size: 24px;
  font-weight: 500;
  left: 0;
  letter-spacing: 2.4px;
  line-height: normal;
  position: absolute;
  text-align: center;
  top: 0;
  width: 212px;
}

.privacypolicy .text-wrapper-338 {
  color: var(--white);
  font-family: "Slackside One", Helvetica;
  font-size: 20px;
  font-weight: 400;
  left: 39px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  text-align: center;
  top: 31px;
  width: 134px;
}

.privacypolicy .group-37 {
  height: 55px;
  position: relative;
  width: 254px;
}

.privacypolicy .flexcontainer-36 {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  gap: 5px;
  height: 55px;
  position: relative;
  width: 252px;
}

.privacypolicy .text-i-35 {
  align-self: stretch;
  color: var(--white);
  font-family: "Noto Sans JP", Helvetica;
  font-size: 15px;
  font-weight: 400;
  letter-spacing: 0.75px;
  line-height: 25px;
  position: relative;
}

.privacypolicy .footer-11 {
  align-items: center;
  align-self: stretch;
  background-color: #454545;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 50px;
  padding: 70px 40px 50px;
  position: relative;
  width: 100%;
}

.privacypolicy .frame-273 {
  align-items: flex-start;
  display: flex;
  flex: 0 0 auto;
  flex-wrap: wrap;
  gap: 50px 150px;
  justify-content: space-between;
  margin-left: -40px;
  margin-right: -40px;
  padding: 0px 50px;
  position: relative;
  width: 1200px;
}

.privacypolicy .frame-274 {
  align-items: flex-start;
  display: flex;
  flex: 1;
  flex-direction: column;
  flex-grow: 1;
  gap: 44px;
  position: relative;
}

.privacypolicy .logo-white-17 {
  height: 54.26px !important;
  position: relative !important;
  width: 163.65px !important;
}

.privacypolicy .text-i-36 {
  align-self: stretch;
  color: #ffffff;
  font-family: var(--text-font-family);
  font-size: var(--text-font-size);
  font-style: var(--text-font-style);
  font-weight: var(--text-font-weight);
  letter-spacing: var(--text-letter-spacing);
  line-height: var(--text-line-height);
  position: relative;
}

.privacypolicy .frame-275 {
  align-items: flex-start;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 60px;
  position: relative;
}

.privacypolicy .frame-276 {
  align-items: flex-start;
  display: inline-flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 40px;
  padding: 0px 20px;
  position: relative;
}

.privacypolicy .frame-277 {
  align-items: flex-start;
  display: inline-flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 6px;
  position: relative;
}

.privacypolicy .text-wrapper-339 {
  color: #ffffff;
  font-family: "Noto Sans JP", Helvetica;
  font-size: 15px;
  font-weight: 700;
  letter-spacing: 0.75px;
  line-height: 20px;
  margin-top: -1px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.privacypolicy .text-wrapper-340 {
  color: #ffffff;
  font-family: "Noto Sans JP", Helvetica;
  font-size: 13px;
  font-weight: 700;
  letter-spacing: 0.65px;
  line-height: 20px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.privacypolicy .frame-278 {
  align-items: flex-start;
  display: inline-flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 30px;
  padding: 0px 20px;
  position: relative;
}

.privacypolicy .text-wrapper-341 {
  color: #ffffff;
  font-family: "Noto Sans JP", Helvetica;
  font-size: 15px;
  font-weight: 700;
  letter-spacing: 0.75px;
  line-height: 20px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.privacypolicy .text-wrapper-342 {
  color: #ffffff;
  display: block;
  font-family: "Noto Sans JP", Helvetica;
  font-size: 15px;
  font-weight: 700;
  letter-spacing: 0.75px;
  line-height: 20px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.privacypolicy .text-wrapper-343 {
  color: #ffffff;
  font-family: "Manrope", Helvetica;
  font-size: 13.1px;
  font-weight: 400;
  letter-spacing: 1.31px;
  line-height: normal;
  position: relative;
  width: fit-content;
}
