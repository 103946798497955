.q-a {
  align-items: flex-start;
  background-color: var(--white);
  border: 1px solid;
  border-color: #efeeee;
  border-radius: 6px;
  display: flex;
  flex-direction: column;
  position: relative;
  width: 480px;
}

.q-a .frame-6 {
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  justify-content: space-between;
  position: relative;
  width: 100%;
}

.q-a .frame-7 {
  align-items: center;
  display: flex;
  gap: 14px;
  position: relative;
  width: 268.61px;
}

.q-a .frame-8 {
  align-items: center;
  display: inline-flex;
  flex: 0 0 auto;
  flex-wrap: wrap;
  gap: 10px 10px;
  justify-content: center;
  margin-right: -4.39px;
  position: relative;
}

.q-a .text-wrapper-7 {
  color: var(--dark-blue);
  flex: 1;
  margin-top: -1px;
  position: relative;
}

.q-a .frame-9 {
  align-items: flex-start;
  display: flex;
  flex: 1;
  flex-grow: 1;
  gap: 20px;
  position: relative;
}

.q-a .frame-10 {
  align-items: flex-start;
  display: inline-flex;
  flex: 0 0 auto;
  flex-wrap: wrap;
  gap: 10px 10px;
  position: relative;
}

.q-a .text-wrapper-8 {
  color: var(--dark-blue);
  flex: 1;
  margin-top: -1px;
  position: relative;
}

.q-a .class-3 {
  height: 12px !important;
  position: relative !important;
  width: 12px !important;
}

.q-a .class-4 {
  height: 17px !important;
  position: relative !important;
  width: 17px !important;
}

.q-a .frame-11 {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  gap: 136px;
  position: relative;
  width: 100%;
}

.q-a .frame-12 {
  align-items: flex-start;
  display: flex;
  flex: 1;
  flex-grow: 1;
  gap: 14px;
  position: relative;
}

.q-a .frame-13 {
  align-items: flex-start;
  display: flex;
  flex: 1;
  flex-grow: 1;
  flex-wrap: wrap;
  gap: 10px 10px;
  position: relative;
}

.q-a .div-3 {
  color: #454545;
  flex: 1;
  margin-top: -1px;
  position: relative;
}

.q-a .span {
  font-family: var(--text-font-family);
  font-size: var(--text-font-size);
  font-style: var(--text-font-style);
  font-weight: var(--text-font-weight);
  letter-spacing: var(--text-letter-spacing);
  line-height: var(--text-line-height);
}

.q-a .q-aicon {
  height: 24px !important;
  position: relative !important;
  width: 24px !important;
}

.q-a.device-0-PC {
  padding: 30px 20px;
}

.q-a.open-one {
  gap: 30px;
}

.q-a.device-0-SP {
  padding: 20px;
}

.q-a.device-0-PC.open-open {
  gap: 20px;
}

.q-a.open-open.device-0-SP {
  gap: 10px;
}

.q-a.open-open .frame-6 {
  align-items: center;
}

.q-a.device-0-SP .frame-6 {
  align-items: center;
}

.q-a.open-one.device-0-PC .frame-6 {
  align-items: flex-start;
}

.q-a.device-0-PC .text-wrapper-7 {
  font-family: "Noto Sans JP", Helvetica;
  font-size: 16px;
  font-weight: 700;
  letter-spacing: 0.8px;
  line-height: normal;
}

.q-a.device-0-SP .text-wrapper-7 {
  font-family: var(--textbold-SP-font-family);
  font-size: var(--textbold-SP-font-size);
  font-style: var(--textbold-SP-font-style);
  font-weight: var(--textbold-SP-font-weight);
  letter-spacing: var(--textbold-SP-letter-spacing);
  line-height: var(--textbold-SP-line-height);
}

.q-a.device-0-PC .text-wrapper-8 {
  font-family: "Noto Sans JP", Helvetica;
  font-size: 16px;
  font-weight: 700;
  letter-spacing: 0.8px;
  line-height: normal;
}

.q-a.device-0-SP .text-wrapper-8 {
  font-family: var(--textbold-SP-font-family);
  font-size: var(--textbold-SP-font-size);
  font-style: var(--textbold-SP-font-style);
  font-weight: var(--textbold-SP-font-weight);
  letter-spacing: var(--textbold-SP-letter-spacing);
  line-height: var(--textbold-SP-line-height);
}

.q-a.device-0-PC .div-3 {
  font-family: var(--text-font-family);
  font-size: var(--text-font-size);
  font-style: var(--text-font-style);
  font-weight: var(--text-font-weight);
  letter-spacing: var(--text-letter-spacing);
  line-height: var(--text-line-height);
}

.q-a.device-0-SP .div-3 {
  font-family: var(--text-SP-font-family);
  font-size: var(--text-SP-font-size);
  font-style: var(--text-SP-font-style);
  font-weight: var(--text-SP-font-weight);
  letter-spacing: var(--text-SP-letter-spacing);
  line-height: var(--text-SP-line-height);
}
