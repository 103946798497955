.philosophy-screen {
  align-items: center;
  display: flex;
  flex-direction: column;
  position: relative;
}

.philosophy-screen .header-44 {
  align-self: stretch !important;
  display: flex !important;
  flex: 0 0 auto !important;
  justify-content: space-between !important;
  width: 100% !important;
  z-index: 1 !important;
}

.philosophy-screen .header-45 {
  height: 54px !important;
  position: relative !important;
  top: 16px !important;
  width: 145px !important;
}

.philosophy-screen .philosophy-2 {
  align-items: flex-start;
  align-self: stretch;
  background-color: #ffffff;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  position: relative;
  width: 100%;
  z-index: 0;
}

.philosophy-screen .midashi-13 {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  position: relative;
  width: 100%;
}

.philosophy-screen .heading-54 {
  align-self: stretch !important;
  background-image: url(../../../static/img/midashi-1.png) !important;
  flex: 0 0 auto !important;
  width: 100% !important;
}

.philosophy-screen .heading-55 {
  color: #ffffff !important;
  left: -17px !important;
}

.philosophy-screen .heading-56 {
  color: #ffffff !important;
  left: 23px !important;
}

.philosophy-screen .breadcrumbs-14 {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  gap: 30px;
  justify-content: center;
  padding: 40px 15px;
  position: relative;
  width: 100%;
}

.philosophy-screen .frame-416 {
  align-items: center;
  display: flex;
  flex: 1;
  flex-grow: 1;
  gap: 30px;
  position: relative;
}

.philosophy-screen .text-wrapper-522 {
  color: var(--gray);
  display: block;
  font-family: var(--tag-font-family);
  font-size: var(--tag-font-size);
  font-style: var(--tag-font-style);
  font-weight: var(--tag-font-weight);
  letter-spacing: var(--tag-letter-spacing);
  line-height: var(--tag-line-height);
  margin-top: -1px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.philosophy-screen .component-10930-9 {
  height: 8.14px !important;
  left: unset !important;
  position: relative !important;
  top: unset !important;
  width: 4.57px !important;
}

.philosophy-screen .text-wrapper-523 {
  color: var(--gray);
  font-family: var(--tag-font-family);
  font-size: var(--tag-font-size);
  font-style: var(--tag-font-style);
  font-weight: var(--tag-font-weight);
  letter-spacing: var(--tag-letter-spacing);
  line-height: var(--tag-line-height);
  margin-top: -1px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.philosophy-screen .corporate-2 {
  align-items: center;
  align-self: stretch;
  background-image: url(../../../static/img/corporate-1.png);
  background-position: 50% 50%;
  background-size: cover;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 40px;
  padding: 100px 30px;
  position: relative;
  width: 100%;
}

.philosophy-screen .frame-417 {
  align-items: center;
  align-self: stretch;
  background-color: #ffffff;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 50px;
  opacity: 0.9;
  padding: 80px 30px;
  position: relative;
  width: 100%;
}

.philosophy-screen .heading-57 {
  position: relative !important;
}

.philosophy-screen .heading-58 {
  left: -10px !important;
}

.philosophy-screen .heading-59 {
  left: -87px !important;
}

.philosophy-screen .flexcontainer-57 {
  align-items: flex-start;
  display: inline-flex;
  flex-direction: column;
  gap: 2px;
}

.philosophy-screen .text-36 {
  align-self: stretch;
  color: #454545;
  font-family: "Noto Sans JP", Helvetica;
  font-size: 23px;
  font-weight: 700;
  letter-spacing: 2.3px;
  line-height: 43px;
  position: relative;
  text-align: center;
}

.philosophy-screen .text-wrapper-524 {
  letter-spacing: 0.53px;
}

.philosophy-screen .flexcontainer-58 {
  align-items: flex-start;
  display: inline-flex;
  flex-direction: column;
  gap: 5px;
}

.philosophy-screen .text-37 {
  align-self: stretch;
  color: var(--darkgray);
  font-family: var(--text-font-family);
  font-size: var(--text-font-size);
  font-style: var(--text-font-style);
  font-weight: var(--text-font-weight);
  letter-spacing: var(--text-letter-spacing);
  line-height: var(--text-line-height);
  position: relative;
  text-align: center;
}

.philosophy-screen .text-wrapper-525 {
  font-family: var(--text-font-family);
  font-size: var(--text-font-size);
  font-style: var(--text-font-style);
  font-weight: var(--text-font-weight);
  letter-spacing: var(--text-letter-spacing);
  line-height: var(--text-line-height);
}

.philosophy-screen .management-2 {
  align-items: center;
  align-self: stretch;
  background-color: var(--palegray);
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 50px;
  padding: 80px 15px;
  position: relative;
  width: 100%;
}

.philosophy-screen .heading-60 {
  left: -104px !important;
}

.philosophy-screen .text-wrapper-526 {
  color: #454545;
  font-family: "Noto Sans JP", Helvetica;
  font-size: 23px;
  font-weight: 700;
  letter-spacing: 2.3px;
  line-height: 43px;
  position: relative;
  text-align: center;
  white-space: nowrap;
  width: fit-content;
}

.philosophy-screen .text-38 {
  align-self: stretch;
  color: #454545;
  font-family: var(--text-font-family);
  font-size: var(--text-font-size);
  font-style: var(--text-font-style);
  font-weight: var(--text-font-weight);
  letter-spacing: var(--text-letter-spacing);
  line-height: var(--text-line-height);
  position: relative;
  text-align: center;
}

.philosophy-screen .ill-2 {
  height: 326px;
  object-fit: cover;
  position: relative;
  width: 548px;
}

.philosophy-screen .motto-2 {
  align-items: center;
  align-self: stretch;
  background-color: var(--backwhite);
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 50px;
  padding: 80px 15px;
  position: relative;
  width: 100%;
}

.philosophy-screen .heading-61 {
  left: 23px !important;
}

.philosophy-screen .heading-62 {
  left: 16px !important;
}

.philosophy-screen .mottos-2 {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 30px;
  position: relative;
  width: 100%;
}

.philosophy-screen .frame-418 {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 15px;
  position: relative;
  width: 100%;
}

.philosophy-screen .text-wrapper-527 {
  align-self: stretch;
  color: var(--darkgray);
  font-family: "Noto Sans JP", Helvetica;
  font-size: 23px;
  font-weight: 700;
  letter-spacing: 2.3px;
  line-height: 43px;
  margin-top: -1px;
  position: relative;
}

.philosophy-screen .line-2-instance {
  width: 90px !important;
}

.philosophy-screen .text-wrapper-528 {
  align-self: stretch;
  color: var(--darkgray);
  font-family: "Noto Sans JP", Helvetica;
  font-size: 15px;
  font-weight: 400;
  letter-spacing: 0.75px;
  line-height: 25px;
  position: relative;
}

.philosophy-screen .text-wrapper-529 {
  align-self: stretch;
  color: var(--darkgray);
  font-family: "Noto Sans JP", Helvetica;
  font-size: 20px;
  font-weight: 700;
  letter-spacing: 1px;
  line-height: 37px;
  margin-top: -1px;
  position: relative;
}

.philosophy-screen .CONTACT-34 {
  align-items: center;
  align-self: stretch;
  background-color: var(--backwhite);
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 40px;
  padding: 100px 20px;
  position: relative;
  width: 100%;
}

.philosophy-screen .CONTACT-35 {
  height: 160px;
  position: relative;
  width: 704px;
}

.philosophy-screen .frame-419 {
  align-items: center;
  background-color: var(--purple);
  border-radius: 6px;
  display: flex;
  flex-wrap: wrap;
  gap: 20px 74px;
  justify-content: center;
  left: 2px;
  max-width: 700px;
  padding: 50px 60px;
  position: relative;
  width: 700px;
}

.philosophy-screen .group-74 {
  height: 60px;
  position: relative;
  width: 216px;
}

.philosophy-screen .overlap-group-18 {
  height: 60px;
  position: relative;
  width: 212px;
}

.philosophy-screen .text-wrapper-530 {
  color: var(--white);
  font-family: "Zen Kaku Gothic New", Helvetica;
  font-size: 24px;
  font-weight: 500;
  left: 0;
  letter-spacing: 2.4px;
  line-height: normal;
  position: absolute;
  text-align: center;
  top: 0;
  width: 212px;
}

.philosophy-screen .text-wrapper-531 {
  color: var(--white);
  font-family: "Slackside One", Helvetica;
  font-size: 20px;
  font-weight: 400;
  left: 39px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  text-align: center;
  top: 31px;
  width: 134px;
}

.philosophy-screen .group-75 {
  height: 55px;
  position: relative;
  width: 254px;
}

.philosophy-screen .flexcontainer-59 {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  gap: 5px;
  height: 55px;
  position: relative;
  width: 252px;
}

.philosophy-screen .text-i-56 {
  align-self: stretch;
  color: var(--white);
  font-family: "Noto Sans JP", Helvetica;
  font-size: 15px;
  font-weight: 400;
  letter-spacing: 0.75px;
  line-height: 25px;
  position: relative;
}

.philosophy-screen .text-wrapper-532 {
  letter-spacing: 0.11px;
}

.philosophy-screen .footer-20 {
  align-items: center;
  align-self: stretch;
  background-color: #454545;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 50px;
  padding: 70px 20px 50px;
  position: relative;
  width: 100%;
}

.philosophy-screen .frame-420 {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  flex-wrap: wrap;
  gap: 50px 150px;
  justify-content: space-between;
  position: relative;
  width: 100%;
}

.philosophy-screen .frame-421 {
  align-items: flex-start;
  display: inline-flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 30px;
  position: relative;
}

.philosophy-screen .logo-white-23 {
  height: 54.26px !important;
  left: unset !important;
  position: relative !important;
  top: unset !important;
  width: 163.65px !important;
}

.philosophy-screen .flexcontainer-60 {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.philosophy-screen .text-i-57 {
  align-self: stretch;
  color: #ffffff;
  font-family: var(--text-font-family);
  font-size: var(--text-font-size);
  font-style: var(--text-font-style);
  font-weight: var(--text-font-weight);
  letter-spacing: var(--text-letter-spacing);
  line-height: var(--text-line-height);
  position: relative;
}

.philosophy-screen .frame-422 {
  align-items: flex-start;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 60px;
  position: relative;
}

.philosophy-screen .frame-423 {
  align-items: flex-start;
  display: inline-flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 6px;
  position: relative;
}

.philosophy-screen .text-wrapper-533 {
  color: #ffffff;
  font-family: var(--text-bold-mini-font-family);
  font-size: var(--text-bold-mini-font-size);
  font-style: var(--text-bold-mini-font-style);
  font-weight: var(--text-bold-mini-font-weight);
  letter-spacing: var(--text-bold-mini-letter-spacing);
  line-height: var(--text-bold-mini-line-height);
  margin-top: -1px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.philosophy-screen .text-wrapper-534 {
  color: #ffffff;
  font-family: var(--text-bold-mini-font-family);
  font-size: var(--text-bold-mini-font-size);
  font-style: var(--text-bold-mini-font-style);
  font-weight: var(--text-bold-mini-font-weight);
  letter-spacing: var(--text-bold-mini-letter-spacing);
  line-height: var(--text-bold-mini-line-height);
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.philosophy-screen .text-wrapper-535 {
  color: #ffffff;
  display: block;
  font-family: var(--text-bold-mini-font-family);
  font-size: var(--text-bold-mini-font-size);
  font-style: var(--text-bold-mini-font-style);
  font-weight: var(--text-bold-mini-font-weight);
  letter-spacing: var(--text-bold-mini-letter-spacing);
  line-height: var(--text-bold-mini-line-height);
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.philosophy-screen .text-wrapper-536 {
  color: #ffffff;
  font-family: "Manrope", Helvetica;
  font-size: 13.1px;
  font-weight: 400;
  letter-spacing: 1.31px;
  line-height: normal;
  position: relative;
  width: fit-content;
}
