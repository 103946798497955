.BLOG {
  align-items: flex-start;
  background-color: #ffffff;
  border-radius: 10px;
  display: flex;
  gap: 14px;
  position: relative;
  transition: all 0.3s ease;
  width: 350px;
}

.BLOG .frame-15 {
  align-items: flex-start;
  background-color: #ffffff;
  border: 1px solid;
  border-color: #efeeee;
  border-radius: 10px;
  display: flex;
  flex: 1;
  flex-direction: column;
  flex-grow: 1;
  gap: 14px;
  height: 415px;
  padding: 8px 8px 20px;
  position: relative;
}

.BLOG .frame-16 {
  align-items: center;
  align-self: stretch;
  background-color: #959595;
  border-radius: 6px;
  display: flex;
  flex: 1;
  flex-grow: 1;
  gap: 10px;
  justify-content: center;
  max-height: 240px;
  min-height: 200px;
  position: relative;
  width: 100%;
}

.BLOG .frame-17 {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 14px;
  padding: 0px 5px;
  position: relative;
  width: 100%;
}

.BLOG .text-wrapper-11 {
  color: #8a8a8a;
  font-family: "Manrope", Helvetica;
  font-size: 12px;
  font-weight: 400;
  letter-spacing: 1.2px;
  line-height: normal;
  margin-top: -1px;
  position: relative;
  width: fit-content;
}

.BLOG .text-wrapper-12 {
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  align-self: stretch;
  color: #454545;
  display: -webkit-box;
  font-family: "Noto Sans JP", Helvetica;
  font-size: 15px;
  font-weight: 700;
  letter-spacing: 0.75px;
  line-height: 26px;
  overflow: hidden;
  position: relative;
  text-overflow: ellipsis;
}

.BLOG .vector {
  align-self: stretch;
  height: 1px;
  object-fit: cover;
  position: relative;
  width: 100%;
}

.BLOG .frame-18 {
  align-items: center;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  justify-content: center;
  padding: 0px 5px;
  position: relative;
  width: 100%;
}

.BLOG .text-wrapper-13 {
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  color: var(--purple);
  display: -webkit-box;
  flex: 1;
  font-family: "Noto Sans JP", Helvetica;
  font-size: 13px;
  font-weight: 500;
  letter-spacing: 0.65px;
  line-height: 18.8px;
  margin-top: -1px;
  overflow: hidden;
  position: relative;
  text-overflow: ellipsis;
}

.BLOG .noimage-53 {
  height: 25.92px !important;
  position: relative !important;
  width: 190.9px !important;
}
