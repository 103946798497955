.flow {
  align-items: flex-start;
  background-color: #ffffff;
  border: 1px solid;
  position: relative;
}

.flow .frame-24 {
  align-items: center;
  align-self: stretch;
  background-color: #6079c1;
  border-radius: 10px 0px 0px 10px;
  display: flex;
  flex-direction: column;
  gap: 10px;
  justify-content: center;
  padding: 0px 20px;
  position: relative;
  width: 40px;
  z-index: 3;
}

.flow .element {
  color: #ffffff;
  font-family: "Manrope", Helvetica;
  font-size: 22px;
  font-weight: 800;
  letter-spacing: 0;
  line-height: normal;
  margin-left: -5.5px;
  margin-right: -5.5px;
  position: relative;
  width: fit-content;
}

.flow .group-wrapper {
  align-items: center;
  align-self: stretch;
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative;
  width: 180px;
  z-index: 2;
}

.flow .overlap-group-wrapper {
  height: 166px;
  position: relative;
  width: 168px;
}

.flow .overlap-group {
  height: 166px;
  position: relative;
}

.flow .image-2 {
  height: 137px;
  left: 0;
  position: absolute;
  top: 15px;
  width: 168px;
}

.flow .rectangle {
  height: 166px;
  left: 1px;
  position: absolute;
  top: 0;
  width: 166px;
}

.flow .frame-25 {
  align-items: flex-start;
  display: flex;
  flex: 1;
  flex-direction: column;
  flex-grow: 1;
  gap: 12px;
  padding: 20px 24px;
  position: relative;
  z-index: 1;
}

.flow .frame-26 {
  align-items: center;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  gap: 12px;
  position: relative;
  width: 100%;
}

.flow .freeicon-instance {
  flex: 0 0 auto !important;
}

.flow .text-wrapper-17 {
  color: #454545;
  font-family: var(--bold-SP-font-family);
  font-size: var(--bold-SP-font-size);
  font-style: var(--bold-SP-font-style);
  font-weight: var(--bold-SP-font-weight);
  letter-spacing: var(--bold-SP-letter-spacing);
  line-height: var(--bold-SP-line-height);
  position: relative;
  width: fit-content;
}

.flow .text-wrapper-18 {
  align-self: stretch;
  color: #454545;
  font-family: "Noto Sans JP", Helvetica;
  font-size: 15px;
  font-weight: 400;
  height: 82px;
  letter-spacing: 0.75px;
  line-height: 25px;
  position: relative;
}

.flow .frame-27 {
  align-items: flex-start;
  align-self: stretch;
  background-color: var(--palegray);
  border-radius: 0px 10px 10px 0px;
  display: flex;
  flex-direction: column;
  gap: 10px;
  max-width: 200px;
  padding: 20px 25px 20px 20px;
  position: relative;
  width: 200px;
  z-index: 0;
}

.flow .frame-28 {
  align-items: center;
  background-color: #4c70c1;
  border-radius: 20px;
  display: inline-flex;
  flex: 0 0 auto;
  justify-content: center;
  overflow: hidden;
  padding: 5px 14px;
  position: relative;
}

.flow .text-wrapper-19 {
  color: #ffffff;
  font-family: "Noto Sans JP", Helvetica;
  font-size: 14px;
  font-weight: 700;
  letter-spacing: 0.7px;
  line-height: 14.8px;
  margin-top: -1px;
  position: relative;
  text-align: center;
  white-space: nowrap;
  width: fit-content;
}

.flow .element-2 {
  color: #454545;
  font-family: var(--text-bold-font-family);
  font-size: var(--text-bold-font-size);
  font-style: var(--text-bold-font-style);
  font-weight: var(--text-bold-font-weight);
  letter-spacing: var(--text-bold-letter-spacing);
  line-height: var(--text-bold-line-height);
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.flow .frame-29 {
  align-items: flex-start;
  display: inline-flex;
  flex: 0 0 auto;
  flex-direction: column;
  position: relative;
}

.flow .frame-30 {
  align-items: flex-start;
  display: flex;
  height: 100px;
  position: relative;
  width: 320px;
}

.flow .frame-31 {
  align-items: center;
  background-color: #6079c1;
  border-radius: 6px 0px 0px 0px;
  display: flex;
  flex-direction: column;
  gap: 10px;
  height: 100px;
  justify-content: center;
  padding: 0px 20px;
  position: relative;
  width: 50px;
}

.flow .element-3 {
  color: #ffffff;
  font-family: "Manrope", Helvetica;
  font-size: 19px;
  font-weight: 800;
  letter-spacing: 0;
  line-height: normal;
  position: relative;
  width: fit-content;
}

.flow .frame-32 {
  align-items: center;
  border-radius: 0px 6px 0px 0px;
  display: flex;
  flex: 1;
  flex-direction: column;
  flex-grow: 1;
  height: 100px;
  justify-content: flex-end;
  overflow: hidden;
  position: relative;
}

.flow .frame-33 {
  align-items: center;
  display: inline-flex;
  flex: 0 0 auto;
  flex-direction: column;
  justify-content: center;
  margin-top: -17px;
  position: relative;
}

.flow .group-3 {
  height: 117px;
  position: relative;
  width: 144px;
}

.flow .overlap-group-2 {
  height: 117px;
  position: relative;
}

.flow .rectangle-2 {
  height: 75px;
  left: 1px;
  position: absolute;
  top: 0;
  width: 123px;
}

.flow .image-3 {
  height: 100px;
  left: 0;
  position: absolute;
  top: 17px;
  width: 144px;
}

.flow .line-3 {
  align-self: stretch;
  height: 1px;
  object-fit: cover;
  position: relative;
  width: 100%;
}

.flow .frame-34 {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  position: relative;
  width: 100%;
}

.flow .frame-35 {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 12px;
  padding: 20px 15px;
  position: relative;
  width: 100%;
}

.flow .freeicon-2 {
  font-size: 12px !important;
  letter-spacing: 0.6px !important;
  margin-bottom: -1px !important;
  margin-left: -2px !important;
  margin-right: -2px !important;
  margin-top: -3px !important;
}

.flow .text-wrapper-20 {
  color: #454545;
  flex: 1;
  font-family: var(--bold-SP-font-family);
  font-size: var(--bold-SP-font-size);
  font-style: var(--bold-SP-font-style);
  font-weight: var(--bold-SP-font-weight);
  letter-spacing: var(--bold-SP-letter-spacing);
  line-height: var(--bold-SP-line-height);
  margin-top: -1px;
  position: relative;
}

.flow .text-wrapper-21 {
  align-self: stretch;
  color: #454545;
  font-family: var(--text-SP-font-family);
  font-size: var(--text-SP-font-size);
  font-style: var(--text-SP-font-style);
  font-weight: var(--text-SP-font-weight);
  letter-spacing: var(--text-SP-letter-spacing);
  line-height: var(--text-SP-line-height);
  position: relative;
}

.flow .frame-37 {
  align-items: flex-start;
  align-self: stretch;
  background-color: var(--palegray);
  border-radius: 0px 0px 6px 6px;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 6px;
  padding: 10px 15px 15px;
  position: relative;
  width: 100%;
}

.flow .text-wrapper-22 {
  color: #ffffff;
  font-family: "Noto Sans JP", Helvetica;
  font-size: 12px;
  font-weight: 700;
  letter-spacing: 0.6px;
  line-height: 14.8px;
  margin-top: -1px;
  position: relative;
  text-align: center;
  white-space: nowrap;
  width: fit-content;
}

.flow .element-4 {
  color: #454545;
  font-family: var(--textbold-SP-font-family);
  font-size: var(--textbold-SP-font-size);
  font-style: var(--textbold-SP-font-style);
  font-weight: var(--textbold-SP-font-weight);
  letter-spacing: var(--textbold-SP-letter-spacing);
  line-height: var(--textbold-SP-line-height);
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.flow.prop-2-one {
  border-color: #d7d7d7;
  border-radius: 10px;
  display: flex;
  width: 802px;
}

.flow.prop-2-SP {
  border-color: var(--linecolor);
  border-radius: 6px;
  display: inline-flex;
}
