.HOME-wrapper {
  align-items: center;
  display: flex;
  flex-direction: column;
  height: 6027px;
  position: relative;
}

.HOME-wrapper .frame-492 {
  background-image: url(../../../static/img/frame-1.png);
  background-position: 50% 50%;
  background-size: cover;
  height: 1230px;
  position: relative;
  width: 1440px;
}

.HOME-wrapper .frame-493 {
  align-items: center;
  align-self: stretch;
  background-color: var(--backwhite);
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 80px;
  padding: 100px 15px;
  position: relative;
  width: 100%;
}

.HOME-wrapper .rectangle-9 {
  height: 308px;
  object-fit: cover;
  position: relative;
  width: 491.25px;
}

.HOME-wrapper .frame-494 {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  flex-wrap: wrap;
  gap: 30px 30px;
  justify-content: center;
  position: relative;
  width: 100%;
}

.HOME-wrapper .group-100 {
  flex: 1;
  flex-grow: 1;
  height: 66px;
  max-width: 400px;
  min-width: 300px;
  position: relative;
}

.HOME-wrapper .text-wrapper-646 {
  color: var(--darkgray);
  font-family: "Noto Sans JP", Helvetica;
  font-size: 23px;
  font-weight: 700;
  left: 0;
  letter-spacing: 1.15px;
  line-height: normal;
  position: absolute;
  top: 0;
  width: 400px;
}

.HOME-wrapper .group-101 {
  flex: 1;
  flex-grow: 1;
  height: 150px;
  max-width: 500px;
  min-width: 300px;
  position: relative;
}

.HOME-wrapper .text-wrapper-647 {
  color: var(--darkgray);
  font-family: var(--text-font-family);
  font-size: var(--text-font-size);
  font-style: var(--text-font-style);
  font-weight: var(--text-font-weight);
  left: 0;
  letter-spacing: var(--text-letter-spacing);
  line-height: var(--text-line-height);
  position: absolute;
  top: 0;
  width: 500px;
}

.HOME-wrapper .frame-495 {
  align-items: center;
  align-self: stretch;
  background-image: url(../../../static/img/frame-37.png);
  background-position: 50% 50%;
  background-size: cover;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 40px;
  padding: 100px 15px;
  position: relative;
  width: 100%;
}

.HOME-wrapper .component-18 {
  height: 89px;
  position: relative;
  width: 108px;
}

.HOME-wrapper .text-wrapper-648 {
  color: #272727;
  font-family: "Zen Kaku Gothic New", Helvetica;
  font-size: 32px;
  font-weight: 500;
  left: -46px;
  letter-spacing: 1.6px;
  line-height: normal;
  position: absolute;
  text-align: center;
  top: 14px;
  white-space: nowrap;
}

.HOME-wrapper .TEXTEXT-15 {
  color: var(--purple);
  font-family: "Slackside One", Helvetica;
  font-size: 23px;
  font-weight: 400;
  left: 24px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  text-align: center;
  top: 52px;
  white-space: nowrap;
}

.HOME-wrapper .group-102 {
  align-self: stretch;
  height: 130px;
  margin-right: -2px;
  position: relative;
  width: 100%;
}

.HOME-wrapper .flexcontainer-76 {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  gap: 5px;
  height: 130px;
  position: relative;
  width: 1410px;
}

.HOME-wrapper .text-53 {
  align-self: stretch;
  color: #454545;
  font-family: var(--text-font-family);
  font-size: var(--text-font-size);
  font-style: var(--text-font-style);
  font-weight: var(--text-font-weight);
  letter-spacing: var(--text-letter-spacing);
  line-height: var(--text-line-height);
  position: relative;
  text-align: center;
}

.HOME-wrapper .text-wrapper-649 {
  font-family: var(--text-font-family);
  font-size: var(--text-font-size);
  font-style: var(--text-font-style);
  font-weight: var(--text-font-weight);
  letter-spacing: var(--text-letter-spacing);
  line-height: var(--text-line-height);
}

.HOME-wrapper .frame-496 {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  flex-wrap: wrap;
  gap: 10px 10px;
  justify-content: center;
  max-width: 1500px;
  position: relative;
  width: 100%;
}

.HOME-wrapper .frame-497 {
  font-weight: 400 !important;
}

.HOME-wrapper .frame-498 {
  display: flex !important;
  max-width: unset !important;
  padding: 10px 20px !important;
  width: 212px !important;
}

.HOME-wrapper .frame-499 {
  align-self: stretch !important;
  display: flex !important;
  max-width: 200px !important;
  width: 100% !important;
}

.HOME-wrapper .frame-500 {
  margin-left: -3.5px !important;
  margin-right: -3.5px !important;
}

.HOME-wrapper .frame-501 {
  line-height: 37px !important;
}

.HOME-wrapper .button-19 {
  all: unset;
  box-sizing: border-box;
  height: 76px;
  position: relative;
  width: 330px;
}

.HOME-wrapper .frame-502 {
  align-items: center;
  display: inline-flex;
  gap: 10px;
  justify-content: center;
  left: 5px;
  position: relative;
  top: 3px;
}

.HOME-wrapper .polygon-wrapper {
  background-color: var(--purple);
  border-radius: 36px;
  height: 70px;
  position: relative;
  width: 320px;
}

.HOME-wrapper .polygon-11 {
  height: 17px;
  left: 291px;
  position: absolute;
  top: 26px;
  width: 11px;
}

.HOME-wrapper .text-wrapper-650 {
  color: var(--white);
  font-family: "Noto Sans JP", Helvetica;
  font-size: 16px;
  font-weight: 700;
  left: 118px;
  letter-spacing: 0.8px;
  line-height: normal;
  position: absolute;
  text-align: center;
  top: 24px;
}

.HOME-wrapper .frame-503 {
  align-items: center;
  align-self: stretch;
  background-color: var(--palegray);
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 40px;
  padding: 100px 15px;
  position: relative;
  width: 100%;
}

.HOME-wrapper .text-wrapper-651 {
  color: #272727;
  font-family: "Zen Kaku Gothic New", Helvetica;
  font-size: 32px;
  font-weight: 500;
  left: 4px;
  letter-spacing: 1.6px;
  line-height: normal;
  position: absolute;
  text-align: center;
  top: 14px;
  white-space: nowrap;
}

.HOME-wrapper .TEXTEXT-16 {
  color: var(--purple);
  font-family: "Slackside One", Helvetica;
  font-size: 23px;
  font-weight: 400;
  left: 28px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  text-align: center;
  top: 52px;
  white-space: nowrap;
}

.HOME-wrapper .frame-504 {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  flex-wrap: wrap;
  gap: 30px 30px;
  justify-content: center;
  max-width: 1500px;
  position: relative;
  width: 100%;
}

.HOME-wrapper .frame-505 {
  align-items: flex-start;
  background-color: #ffffff;
  border-radius: 10px;
  display: flex;
  gap: 14px;
  position: relative;
  width: 360px;
}

.HOME-wrapper .frame-506 {
  align-items: flex-start;
  background-color: #ffffff;
  border-radius: 10px;
  display: flex;
  flex: 1;
  flex-direction: column;
  flex-grow: 1;
  gap: 14px;
  height: 442px;
  padding: 12px 12px 20px;
  position: relative;
}

.HOME-wrapper .frame-507 {
  align-items: center;
  align-self: stretch;
  background-color: #959595;
  border-radius: 6px;
  display: flex;
  gap: 10px;
  height: 240px;
  justify-content: center;
  position: relative;
  width: 100%;
}

.HOME-wrapper .noimage-17 {
  height: 25.92px !important;
  position: relative !important;
  width: 190.9px !important;
}

.HOME-wrapper .text-wrapper-652 {
  color: #8a8a8a;
  font-family: var(--date-font-family);
  font-size: var(--date-font-size);
  font-style: var(--date-font-style);
  font-weight: var(--date-font-weight);
  letter-spacing: var(--date-letter-spacing);
  line-height: var(--date-line-height);
  position: relative;
  width: fit-content;
}

.HOME-wrapper .text-wrapper-653 {
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  align-self: stretch;
  color: #454545;
  display: -webkit-box;
  font-family: "Noto Sans JP", Helvetica;
  font-size: 15px;
  font-weight: 700;
  letter-spacing: 0.75px;
  line-height: 26px;
  overflow: hidden;
  position: relative;
  text-overflow: ellipsis;
}

.HOME-wrapper .vector-6 {
  align-self: stretch;
  height: 1px;
  object-fit: cover;
  position: relative;
  width: 100%;
}

.HOME-wrapper .text-wrapper-654 {
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  align-self: stretch;
  color: var(--purple);
  display: -webkit-box;
  font-family: "Noto Sans JP", Helvetica;
  font-size: 13px;
  font-weight: 500;
  letter-spacing: 0.65px;
  line-height: 18.8px;
  overflow: hidden;
  position: relative;
  text-overflow: ellipsis;
}

.HOME-wrapper .noimage-15 {
  height: 25.92px !important;
  left: unset !important;
  position: relative !important;
  top: unset !important;
  width: 190.9px !important;
}

.HOME-wrapper .text-wrapper-655 {
  color: var(--white);
  font-family: "Noto Sans JP", Helvetica;
  font-size: 16px;
  font-weight: 700;
  left: 110px;
  letter-spacing: 0.8px;
  line-height: normal;
  position: absolute;
  text-align: center;
  top: 24px;
}

.HOME-wrapper .frame-508 {
  align-items: center;
  align-self: stretch;
  background-color: var(--palepurple);
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 40px;
  padding: 100px 15px;
  position: relative;
  width: 100%;
}

.HOME-wrapper .text-wrapper-656 {
  color: #272727;
  font-family: "Zen Kaku Gothic New", Helvetica;
  font-size: 32px;
  font-weight: 500;
  left: -12px;
  letter-spacing: 1.6px;
  line-height: normal;
  position: absolute;
  text-align: center;
  top: 14px;
  white-space: nowrap;
}

.HOME-wrapper .TEXTEXT-17 {
  color: #6079c1;
  font-family: "Slackside One", Helvetica;
  font-size: 23px;
  font-weight: 400;
  left: 12px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  text-align: center;
  top: 52px;
  white-space: nowrap;
}

.HOME-wrapper .flexcontainer-77 {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.HOME-wrapper .text-54 {
  align-self: stretch;
  color: #454545;
  font-family: "Noto Sans JP", Helvetica;
  font-size: 15px;
  font-weight: 400;
  letter-spacing: 0.75px;
  line-height: 25px;
  position: relative;
  text-align: center;
}

.HOME-wrapper .text-wrapper-657 {
  letter-spacing: 0.11px;
}

.HOME-wrapper .frame-509 {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  flex-wrap: wrap;
  gap: 30px 10px;
  justify-content: center;
  max-width: 1500px;
  position: relative;
  width: 100%;
}

.HOME-wrapper .frame-property {
  align-items: flex-start;
  background-color: var(--white);
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  gap: 14px;
  max-width: 450px;
  overflow: hidden;
  padding: 12px 12px 20px;
  position: relative;
  width: 360px;
}

.HOME-wrapper .frame-510 {
  align-items: center;
  display: flex;
  gap: 23px;
  height: 39px;
  position: relative;
  width: 332px;
}

.HOME-wrapper .frame-511 {
  margin-bottom: -1px !important;
  margin-top: -1px !important;
}

.HOME-wrapper .group-103 {
  height: 18px;
  position: relative;
  width: 93px;
}

.HOME-wrapper .text-wrapper-658 {
  color: #8a8a8a;
  font-family: var(--date-font-family);
  font-size: var(--date-font-size);
  font-style: var(--date-font-style);
  font-weight: var(--date-font-weight);
  left: 0;
  letter-spacing: var(--date-letter-spacing);
  line-height: var(--date-line-height);
  position: absolute;
  text-align: right;
  top: 0;
  width: 91px;
}

.HOME-wrapper .frame-512 {
  height: 66px;
  margin-bottom: -13.5px;
  margin-right: -285px;
  margin-top: -13.5px;
  position: relative;
  width: 330px;
}

.HOME-wrapper .line-27 {
  height: 1px;
  object-fit: cover;
  position: relative;
  width: 335px;
}

.HOME-wrapper .text-wrapper-659 {
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  align-self: stretch;
  color: #454545;
  display: -webkit-box;
  font-family: "Noto Sans JP", Helvetica;
  font-size: 15px;
  font-weight: 700;
  height: 58px;
  letter-spacing: 0.75px;
  line-height: 26px;
  overflow: hidden;
  position: relative;
  text-overflow: ellipsis;
}

.HOME-wrapper .text-wrapper-660 {
  color: #ffffff;
  font-family: "Noto Sans JP", Helvetica;
  font-size: 16px;
  font-weight: 500;
  left: 102px;
  letter-spacing: 0.8px;
  line-height: normal;
  position: absolute;
  text-align: center;
  top: 24px;
}

.HOME-wrapper .frame-513 {
  align-items: center;
  align-self: stretch;
  background-image: url(../../../static/img/frame-40.png);
  background-position: 50% 50%;
  background-size: cover;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 40px;
  padding: 100px 15px;
  position: relative;
  width: 100%;
}

.HOME-wrapper .text-wrapper-661 {
  color: var(--white);
  font-family: "Zen Kaku Gothic New", Helvetica;
  font-size: 32px;
  font-weight: 500;
  left: -12px;
  letter-spacing: 1.6px;
  line-height: normal;
  position: absolute;
  text-align: center;
  top: 14px;
  white-space: nowrap;
}

.HOME-wrapper .TEXTEXT-18 {
  color: #ffffff;
  font-family: "Slackside One", Helvetica;
  font-size: 23px;
  font-weight: 400;
  left: 6px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  text-align: center;
  top: 52px;
  white-space: nowrap;
}

.HOME-wrapper .white-button-wrapper {
  height: 70px;
  position: relative;
  width: 300px;
}

.HOME-wrapper .white-button-2 {
  background-color: var(--white);
  border-radius: 60px;
  height: 70px;
  position: relative;
}

.HOME-wrapper .text-wrapper-662 {
  color: var(--darkgray);
  font-family: "Noto Sans JP", Helvetica;
  font-size: 16px;
  font-weight: 700;
  left: 116px;
  letter-spacing: 0.8px;
  line-height: normal;
  position: absolute;
  text-align: center;
  top: 22px;
}

.HOME-wrapper .frame-514 {
  align-items: center;
  align-self: stretch;
  background-color: var(--backwhite);
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 40px;
  padding: 100px 15px;
  position: relative;
  width: 100%;
}

.HOME-wrapper .group-104 {
  height: 142px;
  position: relative;
  width: 588px;
}

.HOME-wrapper .frame-515 {
  align-items: center;
  background-color: var(--purple);
  border-radius: 6px;
  display: inline-flex;
  flex-wrap: wrap;
  gap: 40px 20px;
  justify-content: center;
  max-width: 700px;
  min-width: 280px;
  padding: 40px 70px;
  position: relative;
}

.HOME-wrapper .group-105 {
  height: 62px;
  position: relative;
  width: 160px;
}

.HOME-wrapper .overlap-group-22 {
  height: 62px;
  position: relative;
  width: 156px;
}

.HOME-wrapper .text-wrapper-663 {
  color: var(--white);
  font-family: "Zen Kaku Gothic New", Helvetica;
  font-size: 24px;
  font-weight: 500;
  left: 0;
  letter-spacing: 2.4px;
  line-height: normal;
  position: absolute;
  text-align: center;
  top: 0;
}

.HOME-wrapper .text-wrapper-664 {
  color: var(--white);
  font-family: "Slackside One", Helvetica;
  font-size: 23px;
  font-weight: 400;
  left: 31px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  text-align: center;
  top: 29px;
}

.HOME-wrapper .group-106 {
  height: 55px;
  margin-right: -2px;
  position: relative;
  width: 254px;
}

.HOME-wrapper .flexcontainer-78 {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  gap: 5px;
  height: 55px;
  position: relative;
  width: 252px;
}

.HOME-wrapper .text-55 {
  align-self: stretch;
  color: var(--white);
  font-family: "Noto Sans JP", Helvetica;
  font-size: 15px;
  font-weight: 400;
  letter-spacing: 0.75px;
  line-height: 25px;
  position: relative;
}

.HOME-wrapper .footer-27 {
  align-items: center;
  align-self: stretch;
  background-color: #454545;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 50px;
  padding: 100px 15px 40px;
  position: relative;
  width: 100%;
}

.HOME-wrapper .frame-516 {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  flex-wrap: wrap;
  gap: 150px 50px;
  justify-content: space-between;
  position: relative;
  width: 100%;
}

.HOME-wrapper .frame-517 {
  align-items: flex-start;
  display: flex;
  flex: 1;
  flex-direction: column;
  flex-grow: 1;
  gap: 44px;
  position: relative;
}

.HOME-wrapper .logo-white-36 {
  height: 54.26px !important;
  left: unset !important;
  position: relative !important;
  top: unset !important;
  width: 163.65px !important;
}

.HOME-wrapper .text-56 {
  align-self: stretch;
  color: #ffffff;
  font-family: var(--text-font-family);
  font-size: var(--text-font-size);
  font-style: var(--text-font-style);
  font-weight: var(--text-font-weight);
  letter-spacing: var(--text-letter-spacing);
  line-height: var(--text-line-height);
  position: relative;
}

.HOME-wrapper .frame-518 {
  align-items: flex-start;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 100px;
  position: relative;
}

.HOME-wrapper .frame-519 {
  align-items: flex-start;
  display: inline-flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 30px;
  position: relative;
}

.HOME-wrapper .text-wrapper-665 {
  color: #ffffff;
  font-family: var(--text-bold-font-family);
  font-size: var(--text-bold-font-size);
  font-style: var(--text-bold-font-style);
  font-weight: var(--text-bold-font-weight);
  letter-spacing: var(--text-bold-letter-spacing);
  line-height: var(--text-bold-line-height);
  margin-top: -1px;
  position: relative;
  width: fit-content;
}

.HOME-wrapper .text-wrapper-666 {
  color: #ffffff;
  font-family: var(--text-bold-font-family);
  font-size: var(--text-bold-font-size);
  font-style: var(--text-bold-font-style);
  font-weight: var(--text-bold-font-weight);
  letter-spacing: var(--text-bold-letter-spacing);
  line-height: var(--text-bold-line-height);
  position: relative;
  width: fit-content;
}

.HOME-wrapper .text-wrapper-667 {
  color: #ffffff;
  font-family: var(--text-bold-font-family);
  font-size: var(--text-bold-font-size);
  font-style: var(--text-bold-font-style);
  font-weight: var(--text-bold-font-weight);
  letter-spacing: var(--text-bold-letter-spacing);
  line-height: var(--text-bold-line-height);
  margin-top: -1px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.HOME-wrapper .text-wrapper-668 {
  color: #ffffff;
  font-family: var(--text-bold-font-family);
  font-size: var(--text-bold-font-size);
  font-style: var(--text-bold-font-style);
  font-weight: var(--text-bold-font-weight);
  letter-spacing: var(--text-bold-letter-spacing);
  line-height: var(--text-bold-line-height);
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.HOME-wrapper .group-107 {
  height: 18px;
  position: relative;
  width: 154px;
}

.HOME-wrapper .text-wrapper-669 {
  color: #ffffff;
  font-family: "Manrope", Helvetica;
  font-size: 13.1px;
  font-weight: 400;
  left: 0;
  letter-spacing: 1.31px;
  line-height: normal;
  position: absolute;
  top: 0;
}
