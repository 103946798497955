.checklist {
  align-items: center;
  display: inline-flex;
  gap: 12px;
  position: relative;
}

.checklist .checkboxes-3 {
  position: relative !important;
}

.checklist .text-wrapper-3 {
  color: #454545;
  font-family: "Noto Sans JP", Helvetica;
  font-size: 16px;
  font-weight: 700;
  letter-spacing: 0.8px;
  line-height: 26px;
  margin-top: -1px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}
