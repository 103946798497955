.freeicon {
  align-items: center;
  border-radius: 20px;
  display: inline-flex;
  gap: 10px;
  justify-content: center;
  padding: 0px 13px;
  position: relative;
}

.freeicon .div-5 {
  color: #ffffff;
  font-family: "Noto Sans JP", Helvetica;
  font-size: 14px;
  font-weight: 700;
  letter-spacing: 0.7px;
  line-height: 26px;
  margin-top: -1px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.freeicon.one-11-two {
  background-color: #6079c1;
}

.freeicon.one-11-one {
  background-color: var(--lightpurple);
}
