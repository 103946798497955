.input-field {
  align-items: flex-start;
  display: inline-flex;
  flex-direction: column;
  position: relative;
}

.input-field .frame-wrapper {
  align-items: flex-start;
  display: inline-flex;
  flex: 0 0 auto;
  position: relative;
}

.input-field .frame-4 {
  align-items: center;
  display: inline-flex;
  flex: 0 0 auto;
  position: relative;
}

.input-field .text-wrapper-5 {
  color: #454545;
  margin-top: -1px;
  position: relative;
  width: fit-content;
}

.input-field .class {
  color: #ffffff !important;
  font-family: "Noto Sans JP", Helvetica !important;
  font-size: 11px !important;
  font-style: unset !important;
  font-weight: 500 !important;
  letter-spacing: 0.55px !important;
  line-height: 14.8px !important;
}

.input-field .class-2 {
  color: #ffffff !important;
}

.input-field .frame-5 {
  align-items: center;
  align-self: stretch;
  background-color: #f3f3f3;
  border: 1px solid;
  border-color: #e1e2e4;
  border-radius: 8px;
  display: flex;
  gap: 10px;
  overflow: hidden;
  position: relative;
  width: 100%;
}

.input-field .text-wrapper-6 {
  color: #b3b3b3;
  font-family: "Noto Sans JP", Helvetica;
  font-weight: 400;
  line-height: 25px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.input-field.prop-1-two {
  gap: 9px;
}

.input-field.prop-1-SP {
  gap: 7px;
}

.input-field .prop-one {
  gap: 30px;
}

.input-field .prop-1-0-SP {
  gap: 30px;
}

.input-field .prop-three.prop-1-0-two {
  gap: 20px;
}

.input-field .frame-wrapper.prop-1-0-SP .frame-4 {
  gap: 10px;
}

.input-field .frame-wrapper.prop-1-0-two.prop-one .frame-4 {
  gap: 20px;
}

.input-field .frame-wrapper.prop-three.prop-1-0-two .frame-4 {
  gap: 30px;
}

.input-field .frame-wrapper.prop-1-0-two .text-wrapper-5 {
  font-family: "Noto Sans JP", Helvetica;
  font-size: 16px;
  font-weight: 700;
  letter-spacing: 0.8px;
  line-height: normal;
}

.input-field .frame-wrapper.prop-1-0-SP .text-wrapper-5 {
  font-family: var(--textbold-SP-font-family);
  font-size: var(--textbold-SP-font-size);
  font-style: var(--textbold-SP-font-style);
  font-weight: var(--textbold-SP-font-weight);
  letter-spacing: var(--textbold-SP-letter-spacing);
  line-height: var(--textbold-SP-line-height);
  white-space: nowrap;
}

.input-field.prop-1-two .frame-5 {
  height: 65px;
  padding: 20px;
}

.input-field.prop-1-SP .frame-5 {
  height: 50px;
  padding: 14px 15px;
}

.input-field.prop-1-two .text-wrapper-6 {
  font-size: 15px;
  letter-spacing: 0.75px;
  margin-top: -1px;
}

.input-field.prop-1-SP .text-wrapper-6 {
  font-size: 13px;
  letter-spacing: 0.65px;
  margin-bottom: -0.5px;
  margin-top: -2.5px;
}
