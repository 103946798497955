.profile-screen {
  align-items: center;
  display: flex;
  flex-direction: column;
  position: relative;
}

.profile-screen .header-42 {
  align-self: stretch !important;
  display: flex !important;
  flex: 0 0 auto !important;
  justify-content: space-between !important;
  width: 100% !important;
  z-index: 1 !important;
}

.profile-screen .header-43 {
  height: 54px !important;
  position: relative !important;
  top: 16px !important;
  width: 145px !important;
}

.profile-screen .profile-2 {
  align-items: flex-start;
  align-self: stretch;
  background-color: #ffffff;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  position: relative;
  width: 100%;
  z-index: 0;
}

.profile-screen .midashi-12 {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  position: relative;
  width: 100%;
}

.profile-screen .heading-47 {
  align-self: stretch !important;
  background-image: url(../../../static/img/midashi-1.png) !important;
  flex: 0 0 auto !important;
  width: 100% !important;
}

.profile-screen .heading-48 {
  color: #ffffff !important;
  left: 9px !important;
}

.profile-screen .heading-49 {
  color: #ffffff !important;
  left: -10px !important;
}

.profile-screen .breadcrumbs-13 {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  gap: 30px;
  justify-content: center;
  padding: 40px 15px;
  position: relative;
  width: 100%;
}

.profile-screen .frame-405 {
  align-items: center;
  display: flex;
  flex: 1;
  flex-grow: 1;
  gap: 30px;
  position: relative;
}

.profile-screen .text-wrapper-507 {
  color: var(--gray);
  display: block;
  font-family: var(--tag-font-family);
  font-size: var(--tag-font-size);
  font-style: var(--tag-font-style);
  font-weight: var(--tag-font-weight);
  letter-spacing: var(--tag-letter-spacing);
  line-height: var(--tag-line-height);
  margin-top: -1px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.profile-screen .component-10930-8 {
  height: 8.14px !important;
  left: unset !important;
  position: relative !important;
  top: unset !important;
  width: 4.57px !important;
}

.profile-screen .text-wrapper-508 {
  color: var(--gray);
  font-family: var(--tag-font-family);
  font-size: var(--tag-font-size);
  font-style: var(--tag-font-style);
  font-weight: var(--tag-font-weight);
  letter-spacing: var(--tag-letter-spacing);
  line-height: var(--tag-line-height);
  margin-top: -1px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.profile-screen .greeting-2 {
  align-items: center;
  align-self: stretch;
  background-color: var(--backwhite);
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 40px;
  padding: 80px 15px;
  position: relative;
  width: 100%;
}

.profile-screen .heading-50 {
  position: relative !important;
}

.profile-screen .heading-51 {
  left: -10px !important;
}

.profile-screen .flexcontainer-54 {
  align-items: flex-start;
  display: inline-flex;
  flex-direction: column;
  gap: 2px;
}

.profile-screen .text-35 {
  align-self: stretch;
  color: #454545;
  font-family: "Noto Sans JP", Helvetica;
  font-size: 25px;
  font-weight: 700;
  letter-spacing: 1.25px;
  line-height: normal;
  position: relative;
  text-align: center;
}

.profile-screen .text-wrapper-509 {
  letter-spacing: 0.31px;
}

.profile-screen .members-2 {
  align-items: center;
  align-self: stretch;
  background-color: var(--backwhite);
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 50px;
  padding: 80px 20px;
  position: relative;
  width: 100%;
}

.profile-screen .heading-52 {
  left: -44px !important;
}

.profile-screen .heading-53 {
  left: 3px !important;
}

.profile-screen .frame-406 {
  align-items: center;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 40px;
  overflow: hidden;
  position: relative;
  width: 100%;
}

.profile-screen .member-4 {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  gap: 40px;
  position: relative;
  width: 100%;
}

.profile-screen .rectangle-8 {
  height: 200px;
  position: relative;
  width: 201px;
}

.profile-screen .frame-407 {
  align-items: flex-start;
  display: flex;
  flex: 1;
  flex-direction: column;
  flex-grow: 1;
  gap: 16px;
  position: relative;
}

.profile-screen .frame-408 {
  align-items: flex-start;
  display: inline-flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 12px;
  position: relative;
}

.profile-screen .text-wrapper-510 {
  color: var(--black);
  font-family: "Noto Sans JP", Helvetica;
  font-size: 20px;
  font-weight: 700;
  letter-spacing: 1px;
  line-height: 37px;
  margin-top: -1px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.profile-screen .text-wrapper-511 {
  color: #454545;
  font-family: var(--text-font-family);
  font-size: var(--text-font-size);
  font-style: var(--text-font-style);
  font-weight: var(--text-font-weight);
  letter-spacing: var(--text-letter-spacing);
  line-height: var(--text-line-height);
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.profile-screen .frame-409 {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 8px;
  position: relative;
  width: 100%;
}

.profile-screen .frame-410 {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  flex-wrap: wrap;
  gap: 41px 41px;
  position: relative;
  width: 100%;
}

.profile-screen .text-wrapper-512 {
  color: var(--darkgray);
  font-family: var(--text-font-family);
  font-size: var(--text-font-size);
  font-style: var(--text-font-style);
  font-weight: var(--text-font-weight);
  letter-spacing: var(--text-letter-spacing);
  line-height: var(--text-line-height);
  margin-top: -1px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.profile-screen .text-wrapper-513 {
  color: var(--darkgray);
  flex: 1;
  font-family: var(--text-font-family);
  font-size: var(--text-font-size);
  font-style: var(--text-font-style);
  font-weight: var(--text-font-weight);
  letter-spacing: var(--text-letter-spacing);
  line-height: var(--text-line-height);
  margin-top: -1px;
  position: relative;
}

.profile-screen .line-20 {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  gap: 10px;
  height: 1px;
  justify-content: center;
  margin-left: -148px;
  margin-right: -148px;
  padding: 0px 20px;
  position: relative;
  width: 1000px;
}

.profile-screen .grayline-8 {
  align-self: stretch;
  height: 1px;
  margin-bottom: -0.5px;
  position: relative;
  width: 100%;
}

.profile-screen .line-22 {
  height: 1px;
  left: 128px;
  object-fit: cover;
  position: absolute;
  top: -1px;
  width: 704px;
}

.profile-screen .CONTACT-32 {
  align-items: center;
  align-self: stretch;
  background-color: var(--backwhite);
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 40px;
  padding: 100px 20px;
  position: relative;
  width: 100%;
}

.profile-screen .CONTACT-33 {
  height: 160px;
  position: relative;
  width: 704px;
}

.profile-screen .frame-411 {
  align-items: center;
  background-color: var(--purple);
  border-radius: 6px;
  display: flex;
  flex-wrap: wrap;
  gap: 20px 74px;
  justify-content: center;
  left: 2px;
  max-width: 700px;
  padding: 50px 60px;
  position: relative;
  width: 700px;
}

.profile-screen .group-72 {
  height: 60px;
  position: relative;
  width: 216px;
}

.profile-screen .overlap-group-17 {
  height: 60px;
  position: relative;
  width: 212px;
}

.profile-screen .text-wrapper-514 {
  color: var(--white);
  font-family: "Zen Kaku Gothic New", Helvetica;
  font-size: 24px;
  font-weight: 500;
  left: 0;
  letter-spacing: 2.4px;
  line-height: normal;
  position: absolute;
  text-align: center;
  top: 0;
  width: 212px;
}

.profile-screen .text-wrapper-515 {
  color: var(--white);
  font-family: "Slackside One", Helvetica;
  font-size: 20px;
  font-weight: 400;
  left: 39px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  text-align: center;
  top: 31px;
  width: 134px;
}

.profile-screen .group-73 {
  height: 55px;
  position: relative;
  width: 254px;
}

.profile-screen .flexcontainer-55 {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  gap: 5px;
  height: 55px;
  position: relative;
  width: 252px;
}

.profile-screen .text-i-54 {
  align-self: stretch;
  color: var(--white);
  font-family: "Noto Sans JP", Helvetica;
  font-size: 15px;
  font-weight: 400;
  letter-spacing: 0.75px;
  line-height: 25px;
  position: relative;
}

.profile-screen .text-wrapper-516 {
  letter-spacing: 0.11px;
}

.profile-screen .footer-19 {
  align-items: center;
  align-self: stretch;
  background-color: #454545;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 50px;
  padding: 70px 20px 50px;
  position: relative;
  width: 100%;
}

.profile-screen .frame-412 {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  flex-wrap: wrap;
  gap: 50px 150px;
  justify-content: space-between;
  position: relative;
  width: 100%;
}

.profile-screen .frame-413 {
  align-items: flex-start;
  display: inline-flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 30px;
  position: relative;
}

.profile-screen .logo-white-21 {
  height: 54.26px !important;
  left: unset !important;
  position: relative !important;
  top: unset !important;
  width: 163.65px !important;
}

.profile-screen .flexcontainer-56 {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.profile-screen .text-i-55 {
  align-self: stretch;
  color: #ffffff;
  font-family: var(--text-font-family);
  font-size: var(--text-font-size);
  font-style: var(--text-font-style);
  font-weight: var(--text-font-weight);
  letter-spacing: var(--text-letter-spacing);
  line-height: var(--text-line-height);
  position: relative;
}

.profile-screen .text-wrapper-517 {
  font-family: var(--text-font-family);
  font-size: var(--text-font-size);
  font-style: var(--text-font-style);
  font-weight: var(--text-font-weight);
  letter-spacing: var(--text-letter-spacing);
  line-height: var(--text-line-height);
}

.profile-screen .frame-414 {
  align-items: flex-start;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 60px;
  position: relative;
}

.profile-screen .frame-415 {
  align-items: flex-start;
  display: inline-flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 6px;
  position: relative;
}

.profile-screen .text-wrapper-518 {
  color: #ffffff;
  font-family: var(--text-bold-mini-font-family);
  font-size: var(--text-bold-mini-font-size);
  font-style: var(--text-bold-mini-font-style);
  font-weight: var(--text-bold-mini-font-weight);
  letter-spacing: var(--text-bold-mini-letter-spacing);
  line-height: var(--text-bold-mini-line-height);
  margin-top: -1px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.profile-screen .text-wrapper-519 {
  color: #ffffff;
  font-family: var(--text-bold-mini-font-family);
  font-size: var(--text-bold-mini-font-size);
  font-style: var(--text-bold-mini-font-style);
  font-weight: var(--text-bold-mini-font-weight);
  letter-spacing: var(--text-bold-mini-letter-spacing);
  line-height: var(--text-bold-mini-line-height);
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.profile-screen .text-wrapper-520 {
  color: #ffffff;
  display: block;
  font-family: var(--text-bold-mini-font-family);
  font-size: var(--text-bold-mini-font-size);
  font-style: var(--text-bold-mini-font-style);
  font-weight: var(--text-bold-mini-font-weight);
  letter-spacing: var(--text-bold-mini-letter-spacing);
  line-height: var(--text-bold-mini-line-height);
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.profile-screen .text-wrapper-521 {
  color: #ffffff;
  font-family: "Manrope", Helvetica;
  font-size: 13.1px;
  font-weight: 400;
  letter-spacing: 1.31px;
  line-height: normal;
  position: relative;
  width: fit-content;
}
