.color-white {
  align-items: flex-start;
  background-color: var(--backwhite);
  display: inline-flex;
  padding: 0px 0px 20px 50px;
  position: relative;
}

.color-white .logo-wrapper {
  height: 70px;
  position: relative;
  width: 158.36px;
}
