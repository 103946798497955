.checkboxes {
  height: 16px;
  width: 16px;
}

.checkboxes .base {
  background-color: #f3f3f3;
  border: 1px solid;
  border-color: #e1e2e4;
  border-radius: 8px;
  height: 16px;
  overflow: hidden;
}

.checkboxes .check {
  background-color: #e1e2e4;
  border-radius: 4px;
  height: 8px;
  left: 4px;
  position: relative;
  top: 4px;
  width: 8px;
}
