.required {
  background-color: #f44952;
  border-radius: 6px;
  height: 20px;
  width: 40px;
}

.required .component-10923 {
  background-color: #f44952 !important;
  width: unset !important;
}
