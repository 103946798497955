.information-security {
  align-items: center;
  display: flex;
  flex-direction: column;
  position: relative;
}

.information-security .header-35 {
  align-self: stretch !important;
  flex: 0 0 auto !important;
  height: unset !important;
  width: 100% !important;
  z-index: 1 !important;
}

.information-security .header-36 {
  margin-bottom: unset !important;
}

.information-security .menu-12 {
  height: 35px !important;
  position: relative !important;
  width: 36px !important;
}

.information-security .header-37 {
  background-image: unset !important;
  height: 54px !important;
  position: relative !important;
  top: 16px !important;
  width: 145px !important;
}

.information-security .header-38 {
  align-self: stretch !important;
  display: flex !important;
  flex: 0 0 auto !important;
  justify-content: space-between !important;
  width: 100% !important;
  z-index: 1 !important;
}

.information-security .header-39 {
  z-index: 1 !important;
}

.information-security .menu-instance {
  left: unset !important;
  position: relative !important;
  top: unset !important;
  z-index: 0 !important;
}

.information-security .blog-3 {
  align-items: flex-start;
  align-self: stretch;
  background-color: #ffffff;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  position: relative;
  width: 100%;
  z-index: 0;
}

.information-security .midashi-8 {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  position: relative;
  width: 100%;
}

.information-security .class-127 {
  color: #ffffff !important;
  left: -76px !important;
}

.information-security .class-128 {
  color: #ffffff !important;
  left: -164px !important;
}

.information-security .class-129 {
  color: #ffffff !important;
  left: -54px !important;
}

.information-security .class-130 {
  color: #ffffff !important;
  left: -145px !important;
}

.information-security .class-131 {
  align-self: stretch !important;
  display: flex !important;
  flex: 0 0 auto !important;
  padding: 5px 0px !important;
  width: 100% !important;
}

.information-security .class-132 {
  align-self: stretch !important;
  background-image: unset !important;
  flex: 0 0 auto !important;
  width: 100% !important;
}

.information-security .breadcrumbs-11 {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  gap: 30px;
  justify-content: center;
  position: relative;
  width: 100%;
}

.information-security .frame-294 {
  align-items: center;
  display: flex;
  flex: 1;
  flex-grow: 1;
  position: relative;
}

.information-security .text-wrapper-381 {
  color: var(--gray);
  display: block;
  font-family: var(--smalltext-SP-font-family);
  font-size: var(--smalltext-SP-font-size);
  font-style: var(--smalltext-SP-font-style);
  font-weight: var(--smalltext-SP-font-weight);
  letter-spacing: var(--smalltext-SP-letter-spacing);
  line-height: var(--smalltext-SP-line-height);
  margin-top: -1px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.information-security .instance-6 {
  height: 8.14px !important;
  position: relative !important;
  width: 4.57px !important;
}

.information-security .TOP-6 {
  color: var(--gray);
  margin-top: -1px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.information-security .component-10930-3 {
  height: 8.14px !important;
  left: unset !important;
  position: relative !important;
  top: unset !important;
  width: 4.57px !important;
}

.information-security .text-wrapper-382 {
  color: var(--gray);
  font-family: var(--tag-font-family);
  font-size: var(--tag-font-size);
  font-style: var(--tag-font-style);
  font-weight: var(--tag-font-weight);
  letter-spacing: var(--tag-letter-spacing);
  line-height: var(--tag-line-height);
  margin-top: -1px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.information-security .security {
  align-items: center;
  align-self: stretch;
  background-color: var(--palepurple);
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 40px;
  position: relative;
  width: 100%;
}

.information-security .frame-295 {
  align-items: flex-start;
  background-color: var(--back-white);
  border-radius: 14px;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  position: relative;
}

.information-security .frame-296 {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  position: relative;
  width: 100%;
}

.information-security .text-wrapper-383 {
  align-self: stretch;
  color: #454545;
  margin-top: -1px;
  position: relative;
}

.information-security .frame-297 {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 5px;
  position: relative;
  width: 100%;
}

.information-security .bulletpoint-2 {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  flex-wrap: wrap;
  gap: 5px 5px;
  position: relative;
  width: 100%;
}

.information-security .group-40 {
  height: 28px;
  position: relative;
}

.information-security .ellipse-5 {
  height: 6px;
  left: 3px;
  position: absolute;
  top: 9px;
  width: 6px;
}

.information-security .ellipse-6 {
  background-color: #6079c1;
  border-radius: 5px;
  height: 10px;
  left: 3px;
  position: relative;
  top: 9px;
  width: 10px;
}

.information-security .text-wrapper-384 {
  color: #454545;
  flex: 1;
  margin-top: -1px;
  position: relative;
}

.information-security .group-41 {
  height: 28px;
  position: relative;
}

.information-security .text-wrapper-385 {
  color: #454545;
  flex: 1;
  margin-top: -1px;
  position: relative;
}

.information-security .text-wrapper-386 {
  align-self: stretch;
  color: #454545;
  position: relative;
}

.information-security .frame-298 {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 30px;
  position: relative;
  width: 100%;
}

.information-security .class-133 {
  align-self: stretch !important;
  flex: 0 0 auto !important;
  width: 100% !important;
}

.information-security .class-134 {
  flex: 0 0 auto !important;
}

.information-security .line-21 {
  align-self: stretch !important;
  height: 2px !important;
  margin-bottom: -1px !important;
  position: relative !important;
  width: 100% !important;
}

.information-security .flexcontainer-39 {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
}

.information-security .text-24 {
  align-self: stretch;
  color: #454545;
  position: relative;
}

.information-security .text-25 {
  align-self: stretch;
  color: #454545;
  position: relative;
}

.information-security .text-26 {
  align-self: stretch;
  color: #454545;
  position: relative;
}

.information-security .text-27 {
  align-self: stretch;
  color: #454545;
  position: relative;
}

.information-security .text-wrapper-387 {
  align-self: stretch;
  color: #454545;
  position: relative;
}

.information-security .flexcontainer-40 {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
}

.information-security .text-28 {
  align-self: stretch;
  color: #454545;
  position: relative;
}

.information-security .text-29 {
  align-self: stretch;
  color: #454545;
  position: relative;
}

.information-security .text-30 {
  align-self: stretch;
  color: #454545;
  position: relative;
}

.information-security .CONTACT-24 {
  align-items: center;
  background-color: var(--backwhite);
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 40px;
  position: relative;
}

.information-security .CONTACT-25 {
  display: block;
  height: 160px;
  position: relative;
}

.information-security .frame-299 {
  align-items: center;
  background-color: var(--purple);
  border-radius: 6px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  max-width: 700px;
  position: relative;
}

.information-security .group-42 {
  position: relative;
  width: 216px;
}

.information-security .text-wrapper-388 {
  color: var(--white);
  font-family: "Zen Kaku Gothic New", Helvetica;
  font-size: 20px;
  font-weight: 500;
  left: 0;
  letter-spacing: 2px;
  line-height: normal;
  position: absolute;
  text-align: center;
  top: 0;
  width: 212px;
}

.information-security .text-wrapper-389 {
  color: var(--white);
  font-family: "Slackside One", Helvetica;
  font-size: 15px;
  font-weight: 400;
  left: 39px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  text-align: center;
  top: 31px;
  width: 134px;
}

.information-security .overlap-group-13 {
  height: 60px;
  position: relative;
  width: 212px;
}

.information-security .text-wrapper-390 {
  color: var(--white);
  font-family: "Zen Kaku Gothic New", Helvetica;
  font-size: 24px;
  font-weight: 500;
  left: 0;
  letter-spacing: 2.4px;
  line-height: normal;
  position: absolute;
  text-align: center;
  top: 0;
  width: 212px;
}

.information-security .text-wrapper-391 {
  color: var(--white);
  font-family: "Slackside One", Helvetica;
  font-size: 20px;
  font-weight: 400;
  left: 39px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  text-align: center;
  top: 31px;
  width: 134px;
}

.information-security .group-43 {
  position: relative;
}

.information-security .flexcontainer-41 {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  position: relative;
}

.information-security .text-i-41 {
  align-self: stretch;
  color: var(--white);
  position: relative;
}

.information-security .text-i-42 {
  align-self: stretch;
  color: var(--white);
  position: relative;
}

.information-security .footer-13 {
  align-items: center;
  align-self: stretch;
  background-color: #454545;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 50px;
  position: relative;
  width: 100%;
}

.information-security .frame-300 {
  align-items: flex-start;
  display: flex;
  flex: 0 0 auto;
  position: relative;
}

.information-security .frame-301 {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  position: relative;
}

.information-security .logo-white-27 {
  height: 40px !important;
  position: relative !important;
  width: 121px !important;
}

.information-security .logo-white-6 {
  height: 54.26px !important;
  left: unset !important;
  position: relative !important;
  top: unset !important;
  width: 163.65px !important;
}

.information-security .flexcontainer-42 {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
}

.information-security .text-i-43 {
  align-self: stretch;
  color: #ffffff;
  position: relative;
}

.information-security .text-i-44 {
  align-self: stretch;
  color: #ffffff;
  position: relative;
}

.information-security .frame-302 {
  align-items: flex-start;
  flex: 0 0 auto;
  position: relative;
}

.information-security .frame-303 {
  align-items: flex-start;
  display: inline-flex;
  flex: 0 0 auto;
  flex-direction: column;
  position: relative;
}

.information-security .frame-304 {
  align-items: flex-start;
  display: inline-flex;
  flex: 0 0 auto;
  flex-direction: column;
  position: relative;
}

.information-security .text-wrapper-392 {
  color: #ffffff;
  display: block;
  margin-top: -1px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.information-security .text-wrapper-393 {
  color: #ffffff;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.information-security .text-wrapper-394 {
  color: #ffffff;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.information-security .text-wrapper-395 {
  color: #ffffff;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.information-security .frame-305 {
  align-items: flex-start;
  display: inline-flex;
  flex: 0 0 auto;
  flex-direction: column;
  position: relative;
}

.information-security .text-wrapper-396 {
  color: #ffffff;
  margin-top: -1px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.information-security .text-wrapper-397 {
  color: #ffffff;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.information-security .text-wrapper-398 {
  color: #ffffff;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.information-security .frame-306 {
  align-items: flex-start;
  display: inline-flex;
  flex: 0 0 auto;
  flex-direction: column;
  position: relative;
}

.information-security .text-wrapper-399 {
  color: #ffffff;
  margin-top: -1px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.information-security .text-wrapper-400 {
  color: #ffffff;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.information-security .text-wrapper-401 {
  color: #ffffff;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.information-security .frame-307 {
  align-items: flex-start;
  flex-direction: column;
  gap: 30px;
  position: relative;
}

.information-security .text-wrapper-402 {
  color: #ffffff;
  display: block;
  margin-top: -1px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.information-security .text-wrapper-403 {
  color: #ffffff;
  display: block;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.information-security .text-wrapper-404 {
  color: #ffffff;
  display: block;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.information-security .text-wrapper-405 {
  color: #ffffff;
  display: block;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.information-security .text-wrapper-406 {
  color: #ffffff;
  display: block;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.information-security .text-wrapper-407 {
  color: #ffffff;
  font-family: "Manrope", Helvetica;
  font-size: 13.1px;
  font-weight: 400;
  letter-spacing: 1.31px;
  line-height: normal;
  position: relative;
  width: fit-content;
}
