.frame-36 {
  align-items: center;
  border: 2px solid;
  border-color: var(--purple);
  border-radius: 30px;
  display: inline-flex;
  gap: 10px;
  justify-content: center;
  overflow: hidden;
  padding: 17px 65px;
  position: relative;
}

.frame-36 .text-wrapper-16 {
  color: var(--purple);
  font-family: "Noto Sans JP", Helvetica;
  font-size: 15px;
  font-weight: 600;
  letter-spacing: 0.75px;
  line-height: 26px;
  margin-top: -2px;
  position: relative;
  text-align: center;
  white-space: nowrap;
  width: fit-content;
}

.frame-36.one-9-two {
  background-color: #fdfdfdcc;
}

.frame-36.one-9-one {
  background-color: var(--back-white);
}

.frame-36.one-9-three {
  background-color: var(--back-white);
}
