.BLOG {
  background-color: #fff;
  border-radius: 10px;
  align-items: flex-start;
  gap: 14px;
  width: 350px;
  transition: all .3s;
  display: flex;
  position: relative;
}

.BLOG .frame-15 {
  background-color: #fff;
  border: 1px solid #efeeee;
  border-radius: 10px;
  flex-direction: column;
  flex: 1;
  align-items: flex-start;
  gap: 14px;
  height: 415px;
  padding: 8px 8px 20px;
  display: flex;
  position: relative;
}

.BLOG .frame-16 {
  background-color: #959595;
  border-radius: 6px;
  flex: 1;
  justify-content: center;
  align-self: stretch;
  align-items: center;
  gap: 10px;
  width: 100%;
  min-height: 200px;
  max-height: 240px;
  display: flex;
  position: relative;
}

.BLOG .frame-17 {
  flex-direction: column;
  flex: none;
  align-self: stretch;
  align-items: flex-start;
  gap: 14px;
  width: 100%;
  padding: 0 5px;
  display: flex;
  position: relative;
}

.BLOG .text-wrapper-11 {
  color: #8a8a8a;
  letter-spacing: 1.2px;
  width: fit-content;
  margin-top: -1px;
  font-family: Manrope, Helvetica;
  font-size: 12px;
  font-weight: 400;
  line-height: normal;
  position: relative;
}

.BLOG .text-wrapper-12 {
  -webkit-line-clamp: 2;
  color: #454545;
  letter-spacing: .75px;
  text-overflow: ellipsis;
  -webkit-box-orient: vertical;
  align-self: stretch;
  font-family: Noto Sans JP, Helvetica;
  font-size: 15px;
  font-weight: 700;
  line-height: 26px;
  display: -webkit-box;
  position: relative;
  overflow: hidden;
}

.BLOG .vector {
  object-fit: cover;
  align-self: stretch;
  width: 100%;
  height: 1px;
  position: relative;
}

.BLOG .frame-18 {
  flex: none;
  justify-content: center;
  align-self: stretch;
  align-items: center;
  width: 100%;
  padding: 0 5px;
  display: flex;
  position: relative;
}

.BLOG .text-wrapper-13 {
  -webkit-line-clamp: 2;
  color: var(--purple);
  letter-spacing: .65px;
  text-overflow: ellipsis;
  -webkit-box-orient: vertical;
  flex: 1;
  margin-top: -1px;
  font-family: Noto Sans JP, Helvetica;
  font-size: 13px;
  font-weight: 500;
  line-height: 18.8px;
  display: -webkit-box;
  position: relative;
  overflow: hidden;
}

.BLOG .noimage-53 {
  width: 190.9px !important;
  height: 25.92px !important;
  position: relative !important;
}

.button {
  border-radius: 100px;
  justify-content: space-between;
  align-items: center;
  padding: 25px 20px;
  display: flex;
  position: relative;
}

.button .frame-2 {
  flex-direction: column;
  flex: 1;
  align-items: center;
  gap: 10px;
  display: flex;
  position: relative;
}

.button .text-wrapper {
  letter-spacing: .8px;
  text-align: center;
  width: fit-content;
  margin-top: -1px;
  font-family: Noto Sans JP, Helvetica;
  font-size: 16px;
  font-weight: 700;
  line-height: normal;
  position: relative;
}

.button .img {
  width: 10.87px;
  height: 17.13px;
  margin-left: -.49px;
  margin-right: -1.15px;
  position: relative;
}

.button .frame-3 {
  flex-direction: column;
  flex: 1;
  align-items: center;
  gap: 10px;
  margin-top: -8.5px;
  margin-bottom: -8.5px;
  display: flex;
  position: relative;
}

.button .text-wrapper-2 {
  letter-spacing: .6px;
  text-align: center;
  width: fit-content;
  margin-top: -1px;
  font-family: Noto Sans JP, Helvetica;
  font-size: 12px;
  font-weight: 700;
  line-height: normal;
  position: relative;
}

.button .arrow {
  width: 5.25px !important;
  height: 7.38px !important;
  margin: -3.07px -1.15px -4.32px -1.21px !important;
  position: relative !important;
}

.button.color-0-g-hover {
  background-color: #fff;
  border: 2px solid #454545;
}

.button.color-0-white-textgray {
  background-color: #fff;
}

.button.color-0-gray {
  background-color: #454545;
  transition: all .3s;
}

.button.color-0-white {
  background-color: #fff;
}

.button.color-0-one, .button.color-0-purple {
  background-color: #6079c1;
  transition: all .3s;
}

.button.device-PC {
  width: 308px;
}

.button.color-0-seven {
  background-color: #fff;
  border: 2px solid #6079c1;
  transition: all .3s;
}

.button.color-0-eight {
  background-color: var(--lightpurple);
  border: 2px solid;
  border-color: var(--lightpurple);
}

.button.color-0-p-hover {
  background-color: #fff;
  border: 2px solid #6079c1;
}

.button.color-0-w-hover {
  background-color: #fff;
  box-shadow: 0 0 10px #00000059;
}

.button.device-SP {
  width: 180px;
  height: 50px;
}

.button.device-SP.color-0-white {
  border: 2px solid #6079c1;
}

.button.device-PC.color-0-white {
  transition: all .3s;
}

.button.color-0-g-hover .text-wrapper {
  color: #454545;
}

.button.color-0-gray .text-wrapper {
  color: #fff;
}

.button.color-0-white .text-wrapper {
  color: #454545;
}

.button.color-0-one .text-wrapper {
  color: #fff;
}

.button.color-0-seven .text-wrapper {
  color: #6079c1;
}

.button.color-0-eight .text-wrapper {
  color: #fff;
}

.button.color-0-p-hover .text-wrapper {
  color: #6079c1;
}

.button.color-0-w-hover .text-wrapper {
  color: #454545;
}

.button.color-0-g-hover .text-wrapper-2 {
  color: #fff;
}

.button.color-0-white-textgray .text-wrapper-2 {
  color: #454545;
}

.button.color-0-gray .text-wrapper-2 {
  color: #fff;
}

.button.color-0-white .text-wrapper-2 {
  color: #6079c1;
}

.button.color-0-one .text-wrapper-2, .button.color-0-purple .text-wrapper-2, .button.color-0-seven .text-wrapper-2, .button.color-0-eight .text-wrapper-2, .button.color-0-p-hover .text-wrapper-2, .button.color-0-w-hover .text-wrapper-2 {
  color: #fff;
}

.logo {
  background-image: url("zsykr-0.252254e0.png");
  background-size: 100% 100%;
  width: 164px;
  height: 61px;
}

.color-transparent {
  align-items: flex-start;
  padding: 0 0 20px 50px;
  display: inline-flex;
  position: relative;
}

.color-transparent .group-2 {
  width: 158.36px;
  height: 70px;
  position: relative;
}

.color-transparent .logo-2 {
  width: 145px !important;
  height: 54px !important;
  position: relative !important;
  top: 16px !important;
}

.heading {
  width: 112px;
  height: 69px;
}

.heading .div {
  position: relative;
}

.heading .div-2, .heading .TEXTTEXT {
  text-align: center;
  white-space: nowrap;
  line-height: normal;
  position: absolute;
}

.heading .one {
  height: 67px;
  left: 2px;
}

.heading .SP {
  width: 117px;
  height: 59px;
  top: 10px;
}

.heading .div.one .div-2 {
  color: #272727;
  letter-spacing: 1.6px;
  font-family: Zen Kaku Gothic New, Helvetica;
  font-size: 32px;
  font-weight: 500;
  top: 7px;
  left: 6px;
}

.heading .div.SP .div-2 {
  color: #6079c1;
  letter-spacing: .6px;
  font-family: Slackside One, Helvetica;
  font-size: 12px;
  font-weight: 400;
  top: 34px;
  left: 29px;
}

.heading .div.one .TEXTTEXT {
  color: #6079c1;
  letter-spacing: 1.2px;
  font-family: Slackside One, Helvetica;
  font-size: 24px;
  font-weight: 400;
  top: 51px;
  left: 2px;
}

.heading .div.SP .TEXTTEXT {
  color: #272727;
  letter-spacing: 1.05px;
  font-family: Zen Kaku Gothic New, Helvetica;
  font-size: 21px;
  font-weight: 500;
  top: 7px;
  left: 23px;
}

.lead-ill {
  background-image: url("lead-ill.021ca26c.png");
  background-position: 50%;
  background-size: cover;
  width: 582px;
  height: 364px;
  position: relative;
}

.process {
  display: inline-flex;
  position: relative;
}

.process .image-wrapper {
  background-color: var(--white);
  border-radius: 10px;
  align-items: center;
  gap: 10px;
  padding: 17px 0;
  position: relative;
  overflow: hidden;
}

.process .image {
  object-fit: cover;
  position: relative;
}

.process .frame-14 {
  flex-direction: column;
  flex: none;
  gap: 10px;
  display: inline-flex;
  position: relative;
}

.process .text-wrapper-9 {
  color: var(--purple);
  text-align: center;
  width: fit-content;
  margin-top: -1px;
  font-family: Manrope, Helvetica;
  font-weight: 800;
  line-height: normal;
  position: relative;
}

.process .text-wrapper-10 {
  color: #272727;
  white-space: nowrap;
  width: fit-content;
  font-family: Noto Sans JP, Helvetica;
  font-weight: 700;
  position: relative;
}

.process.one-2-two {
  align-items: flex-start;
  gap: 20px;
  padding: 10px 5px;
}

.process.one-2-tab {
  flex-direction: column;
  align-items: center;
  gap: 10px;
  padding: 10px 5px;
}

.process.one-2-one {
  flex-direction: column;
  align-items: center;
  gap: 20px;
  max-width: 280px;
  padding: 10px 15px;
}

.process.one-2-two .image-wrapper {
  justify-content: center;
  width: 100px;
  height: 100px;
  display: flex;
}

.process.one-2-tab .image-wrapper {
  justify-content: center;
  width: 150px;
  height: 150px;
  display: flex;
}

.process.one-2-one .image-wrapper {
  flex-direction: column;
  flex: none;
  display: inline-flex;
}

.process.one-2-two .image {
  width: 112px;
  height: 91px;
  margin-top: -12.5px;
  margin-bottom: -12.5px;
  margin-left: -6px;
}

.process.one-2-tab .image {
  width: 160px;
  height: 130px;
  margin-top: -7px;
  margin-bottom: -7px;
  margin-left: -5px;
}

.process.one-2-one .image {
  width: 179px;
  height: 145px;
}

.process.one-2-two .frame-14 {
  align-items: flex-start;
  padding: 10px 0;
}

.process.one-2-tab .frame-14 {
  align-items: center;
  padding: 10px 0;
}

.process.one-2-one .frame-14 {
  align-items: center;
  padding: 0 16px;
}

.process.one-2-two .text-wrapper-9, .process.one-2-tab .text-wrapper-9 {
  letter-spacing: 1.3px;
  font-size: 13px;
}

.process.one-2-one .text-wrapper-9 {
  letter-spacing: 1.51px;
  font-size: 15.1px;
}

.process.one-2-two .text-wrapper-10 {
  letter-spacing: 1px;
  font-size: 20px;
  line-height: 30px;
}

.process.one-2-tab .text-wrapper-10 {
  letter-spacing: .9px;
  text-align: center;
  font-size: 18px;
  line-height: 20px;
}

.process.one-2-one .text-wrapper-10 {
  letter-spacing: 1px;
  text-align: center;
  font-size: 20px;
  line-height: 30px;
}

.frame {
  border-radius: 30px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  width: 150px;
  padding: 8px 50px;
  display: flex;
  position: relative;
  overflow: hidden;
}

.frame .div-4 {
  color: var(--white);
  font-family: var(--bold-font-family);
  font-size: var(--bold-font-size);
  font-style: var(--bold-font-style);
  font-weight: var(--bold-font-weight);
  letter-spacing: var(--bold-letter-spacing);
  line-height: var(--bold-line-height);
  text-align: center;
  white-space: nowrap;
  width: fit-content;
  margin-top: -1px;
  position: relative;
}

.frame.one-8-two {
  background-color: #8a8a8a66;
}

.frame.one-8-one {
  background-color: var(--pink);
}

.frame.one-8-two .div-4 {
  margin-left: -4.5px;
  margin-right: -4.5px;
}

.RECRUITE {
  background-color: #fff;
  border: 1px solid #efeeee;
  border-radius: 10px;
  flex-direction: column;
  align-items: flex-start;
  gap: 14px;
  width: 350px;
  height: 407px;
  padding: 8px 8px 20px;
  transition: all .3s;
  display: flex;
  position: relative;
}

.RECRUITE .frame-19 {
  background-color: #959595;
  border-radius: 6px;
  flex: 1;
  justify-content: center;
  align-self: stretch;
  align-items: center;
  gap: 10px;
  width: 100%;
  min-height: 200px;
  max-height: 240px;
  display: flex;
  position: relative;
}

.RECRUITE .frame-20 {
  flex-wrap: wrap;
  flex: none;
  justify-content: space-between;
  align-self: stretch;
  align-items: center;
  gap: 10px 14px;
  width: 100%;
  padding: 0 5px;
  display: flex;
  position: relative;
}

.RECRUITE .frame-21 {
  flex: none;
  align-items: center;
  gap: 10px;
  padding: 0 0 0 20px;
  display: inline-flex;
  position: relative;
}

.RECRUITE .text-wrapper-14 {
  color: #8a8a8a;
  letter-spacing: 1.2px;
  width: fit-content;
  margin-top: -1px;
  font-family: Manrope, Helvetica;
  font-size: 12px;
  font-weight: 400;
  line-height: normal;
  position: relative;
}

.RECRUITE .frame-22 {
  flex-direction: column;
  flex: none;
  align-self: stretch;
  align-items: flex-start;
  gap: 14px;
  width: 100%;
  padding: 0 5px;
  display: flex;
  position: relative;
}

.RECRUITE .vector-2 {
  object-fit: cover;
  align-self: stretch;
  width: 100%;
  height: 1px;
  margin-top: -.5px;
  position: relative;
}

.RECRUITE .frame-23 {
  flex: none;
  justify-content: center;
  align-self: stretch;
  align-items: center;
  gap: 10px;
  width: 100%;
  padding: 0 5px;
  display: flex;
  position: relative;
}

.RECRUITE .text-wrapper-15 {
  -webkit-line-clamp: 2;
  color: #454545;
  letter-spacing: .75px;
  text-overflow: ellipsis;
  -webkit-box-orient: vertical;
  flex: 1;
  margin-top: -1px;
  font-family: Noto Sans JP, Helvetica;
  font-size: 15px;
  font-weight: 700;
  line-height: 26px;
  display: -webkit-box;
  position: relative;
  overflow: hidden;
}

.RECRUITE .noimage-2 {
  width: 190.9px !important;
  height: 25.92px !important;
  position: relative !important;
}

.SP-transparent {
  justify-content: space-between;
  align-items: flex-start;
  width: 320px;
  height: 45px;
  padding: 0 0 20px 10px;
  display: flex;
  position: relative;
}

.SP-transparent .logo-instance-wrapper {
  width: 79.18px;
  height: 35px;
  margin-bottom: -10px;
  position: relative;
}

.SP-transparent .design-component-instance-node {
  width: 72px !important;
  height: 27px !important;
  position: relative !important;
  top: 8px !important;
}

.home {
  background-color: var(--backwhite);
  flex-direction: column;
  align-items: center;
  display: flex;
  position: relative;
}

.home .header-2 {
  z-index: 1 !important;
}

.home .SP-transparent-instance {
  z-index: 0 !important;
  width: 36px !important;
  height: 35px !important;
  margin-bottom: -10px !important;
  position: relative !important;
}

.home .header-3 {
  z-index: 1 !important;
  background-color: #0000 !important;
  align-self: stretch !important;
  width: 100% !important;
}

.home .div-7 {
  z-index: 0;
  flex-direction: column;
  flex: none;
  align-self: stretch;
  align-items: center;
  width: 100%;
  margin-top: -90px;
  display: flex;
  position: relative;
}

.home .FV {
  flex-direction: column;
  align-self: stretch;
  align-items: center;
  gap: 10px;
  width: 100%;
  display: flex;
  position: relative;
}

.home .message-wrapper {
  background-size: 100% 100%;
  position: relative;
}

.home .message {
  position: absolute;
}

.home .LEAD {
  background-color: var(--backwhite);
  flex-direction: column;
  flex: none;
  align-self: stretch;
  align-items: center;
  gap: 30px;
  width: 100%;
  padding: 0 20px 60px;
  display: flex;
  position: relative;
}

.home .lead-ill-instance {
  background-image: url("lead-ill-1.7bc88aa3.png") !important;
  background-position: unset !important;
  background-size: 100% 100% !important;
  width: 244px !important;
  height: 146px !important;
}

.home .lead-text {
  flex-direction: column;
  flex: none;
  align-self: stretch;
  align-items: flex-start;
  gap: 20px;
  width: 100%;
  display: flex;
  position: relative;
}

.home .flexcontainer-4 {
  flex-direction: column;
  align-items: flex-start;
  gap: 2px;
  display: flex;
}

.home .text-2 {
  color: var(--darkgray);
  font-family: var(--bold-SP-font-family);
  font-size: var(--bold-SP-font-size);
  font-style: var(--bold-SP-font-style);
  font-weight: var(--bold-SP-font-weight);
  letter-spacing: var(--bold-SP-letter-spacing);
  line-height: var(--bold-SP-line-height);
  align-self: stretch;
  position: relative;
}

.home .text-wrapper-50 {
  font-family: var(--bold-SP-font-family);
  font-size: var(--bold-SP-font-size);
  font-style: var(--bold-SP-font-style);
  font-weight: var(--bold-SP-font-weight);
  letter-spacing: var(--bold-SP-letter-spacing);
  line-height: var(--bold-SP-line-height);
}

.home .text-3 {
  color: var(--darkgray);
  font-family: var(--bold-SP-font-family);
  font-size: var(--bold-SP-font-size);
  font-style: var(--bold-SP-font-style);
  font-weight: var(--bold-SP-font-weight);
  letter-spacing: var(--bold-SP-letter-spacing);
  line-height: var(--bold-SP-line-height);
  align-self: stretch;
  position: relative;
}

.home .text-wrapper-51 {
  color: var(--darkgray);
  font-family: var(--lead-SP-font-family);
  font-size: var(--lead-SP-font-size);
  font-style: var(--lead-SP-font-style);
  font-weight: var(--lead-SP-font-weight);
  letter-spacing: var(--lead-SP-letter-spacing);
  line-height: var(--lead-SP-line-height);
  align-self: stretch;
  position: relative;
}

.home .FLOW {
  background-image: url("flow-2.e6da2474.png");
  background-position: 50%;
  background-size: cover;
  flex-direction: column;
  flex: none;
  align-self: stretch;
  align-items: center;
  gap: 20px;
  width: 100%;
  padding: 60px 20px;
  display: flex;
  position: relative;
}

.home .heading-3 {
  left: -10px !important;
}

.home .heading-4 {
  position: relative !important;
}

.home .heading-5 {
  left: 40px !important;
}

.home .flexcontainer-5 {
  flex-direction: column;
  align-items: flex-start;
  gap: 3px;
  display: flex;
}

.home .text-4 {
  color: #454545;
  font-family: var(--lead-SP-font-family);
  font-size: var(--lead-SP-font-size);
  font-style: var(--lead-SP-font-style);
  font-weight: var(--lead-SP-font-weight);
  letter-spacing: var(--lead-SP-letter-spacing);
  line-height: var(--lead-SP-line-height);
  align-self: stretch;
  position: relative;
}

.home .text-wrapper-52 {
  font-family: var(--lead-SP-font-family);
  font-size: var(--lead-SP-font-size);
  font-style: var(--lead-SP-font-style);
  font-weight: var(--lead-SP-font-weight);
  letter-spacing: var(--lead-SP-letter-spacing);
  line-height: var(--lead-SP-line-height);
}

.home .processe {
  flex-direction: column;
  flex: none;
  align-items: center;
  width: 280px;
  max-width: 1200px;
  display: flex;
  position: relative;
}

.home .group-5 {
  width: 280px;
  height: 364px;
  position: relative;
}

.home .process-instance {
  width: 280px !important;
  padding: 4px 5px !important;
  display: flex !important;
  position: absolute !important;
  top: 0 !important;
  left: 0 !important;
}

.home .process-2 {
  flex: 1 !important;
  gap: 5px !important;
  display: flex !important;
}

.home .process-3 {
  font-family: var(--bold-SP-font-family) !important;
  font-size: var(--bold-SP-font-size) !important;
  font-style: var(--bold-SP-font-style) !important;
  font-weight: var(--bold-SP-font-weight) !important;
  letter-spacing: var(--bold-SP-letter-spacing) !important;
  line-height: var(--bold-SP-line-height) !important;
  white-space: unset !important;
}

.home .triangle02-18 {
  width: 20px !important;
  height: 20px !important;
  position: absolute !important;
  top: 108px !important;
  left: 130px !important;
}

.home .process-4 {
  width: 280px !important;
  padding: 4px 5px !important;
  display: flex !important;
  position: absolute !important;
  top: 128px !important;
  left: 0 !important;
}

.home .triangle-02 {
  width: 20px !important;
  height: 20px !important;
  position: absolute !important;
  top: 236px !important;
  left: 130px !important;
}

.home .process-5 {
  width: 280px !important;
  padding: 4px 5px !important;
  display: flex !important;
  position: absolute !important;
  top: 256px !important;
  left: 0 !important;
}

.home .group-6 {
  width: 280px;
  height: 256px;
  position: relative;
}

.home .triangle02-1 {
  width: 20px !important;
  height: 20px !important;
  position: absolute !important;
  top: 0 !important;
  left: 130px !important;
}

.home .process-6 {
  align-items: flex-start;
  gap: 20px;
  width: 280px;
  padding: 4px 5px;
  display: flex;
  position: absolute;
  top: 20px;
  left: 0;
}

.home .frame-60 {
  background-color: var(--white);
  border-radius: 10px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  width: 100px;
  height: 100px;
  padding: 17px 0;
  display: flex;
  position: relative;
  overflow: hidden;
}

.home .image-4 {
  object-fit: cover;
  width: 112px;
  height: 91px;
  margin-top: -12.5px;
  margin-bottom: -12.5px;
  margin-left: -6px;
  position: relative;
}

.home .frame-61 {
  flex-direction: column;
  flex: 1;
  align-items: flex-start;
  gap: 5px;
  padding: 10px 0;
  display: flex;
  position: relative;
}

.home .process-7 {
  color: var(--purple);
  letter-spacing: 1.3px;
  text-align: center;
  width: fit-content;
  margin-top: -1px;
  font-family: Manrope, Helvetica;
  font-size: 13px;
  font-weight: 800;
  line-height: normal;
  position: relative;
}

.home .flexcontainer-6 {
  flex-direction: column;
  align-items: flex-start;
  gap: 2px;
  display: inline-flex;
}

.home .text-i-6 {
  color: #272727;
  font-family: var(--bold-SP-font-family);
  font-size: var(--bold-SP-font-size);
  font-style: var(--bold-SP-font-style);
  font-weight: var(--bold-SP-font-weight);
  letter-spacing: var(--bold-SP-letter-spacing);
  line-height: var(--bold-SP-line-height);
  align-self: stretch;
  position: relative;
}

.home .triangle02-17 {
  width: 20px !important;
  height: 20px !important;
  position: absolute !important;
  top: 128px !important;
  left: 130px !important;
}

.home .process-8 {
  width: 280px !important;
  padding: 4px 5px !important;
  display: flex !important;
  position: absolute !important;
  top: 148px !important;
  left: 0 !important;
}

.home .button-instance {
  max-width: 400px !important;
}

.home .button-2 {
  font-family: var(--button-SP-font-family) !important;
  font-size: var(--button-SP-font-size) !important;
  font-style: var(--button-SP-font-style) !important;
  font-weight: var(--button-SP-font-weight) !important;
  letter-spacing: var(--button-SP-letter-spacing) !important;
  line-height: var(--button-SP-line-height) !important;
}

.home .processe-2 {
  flex-wrap: wrap;
  flex: none;
  justify-content: center;
  align-self: stretch;
  align-items: flex-start;
  gap: 0;
  width: 100%;
  max-width: 1200px;
  display: flex;
  position: relative;
}

.home .frame-62 {
  flex: none;
  align-items: flex-start;
  gap: 5px;
  margin-left: -5px;
  margin-right: -5px;
  display: inline-flex;
  position: relative;
}

.home .process-9 {
  align-self: stretch !important;
  width: 100% !important;
}

.home .process-10 {
  margin-left: unset !important;
}

.home .instance-node-3 {
  flex: none !important;
}

.home .yajirushi {
  width: 20px;
  height: 170px;
  position: relative;
}

.home .triangle02-7 {
  width: 20px !important;
  height: 20px !important;
  position: absolute !important;
  top: 75px !important;
  left: 0 !important;
}

.home .process-11 {
  flex: none !important;
  height: 248px !important;
}

.home .process-12 {
  flex-direction: column;
  flex: none;
  align-items: center;
  gap: 10px;
  padding: 10px 5px;
  display: inline-flex;
  position: relative;
}

.home .frame-63 {
  background-color: var(--white);
  border-radius: 10px;
  justify-content: center;
  align-self: stretch;
  align-items: center;
  gap: 10px;
  width: 100%;
  height: 150px;
  padding: 17px 0;
  display: flex;
  position: relative;
  overflow: hidden;
}

.home .image-5 {
  object-fit: cover;
  width: 160px;
  height: 130px;
  margin-top: -7px;
  margin-bottom: -7px;
  position: relative;
}

.home .frame-64 {
  flex-direction: column;
  flex: none;
  align-items: center;
  gap: 10px;
  padding: 10px 0;
  display: inline-flex;
  position: relative;
}

.home .text-i-7 {
  color: #272727;
  letter-spacing: .9px;
  text-align: center;
  align-self: stretch;
  font-family: Noto Sans JP, Helvetica;
  font-size: 18px;
  font-weight: 700;
  line-height: 20px;
  position: relative;
}

.home .text-wrapper-53 {
  letter-spacing: .16px;
}

.home .frame-65 {
  flex: none;
  align-items: flex-start;
  gap: 5px;
  display: inline-flex;
  position: relative;
}

.home .BLOGS {
  background-color: var(--backwhite);
  flex-direction: column;
  flex: none;
  align-self: stretch;
  align-items: center;
  gap: 20px;
  width: 100%;
  padding: 40px 30px;
  display: flex;
  position: relative;
}

.home .heading-6 {
  left: 42px !important;
}

.home .div-8 {
  flex-wrap: wrap;
  flex: none;
  justify-content: center;
  align-self: stretch;
  align-items: flex-start;
  gap: 20px;
  width: 100%;
  display: flex;
  position: relative;
}

.home .BLOG-instance {
  height: unset !important;
  gap: 8px !important;
  padding: 8px 8px 19px !important;
}

.home .frame-66 {
  flex: none !important;
  flex-grow: unset !important;
}

.home .frame-67 {
  font-family: var(--smalltext-SP-font-family) !important;
  font-size: var(--smalltext-SP-font-size) !important;
  font-style: var(--smalltext-SP-font-style) !important;
  font-weight: var(--smalltext-SP-font-weight) !important;
  letter-spacing: var(--smalltext-SP-letter-spacing) !important;
  line-height: var(--smalltext-SP-line-height) !important;
}

.home .class-40 {
  width: unset !important;
  flex: 1 !important;
  min-width: 300px !important;
  max-width: 360px !important;
  margin-left: -20px !important;
  margin-right: -20px !important;
}

.home .class-41 {
  width: 300px !important;
}

.home .frame-68 {
  font-family: var(--smalltextbold-SP-font-family) !important;
  font-size: var(--smalltextbold-SP-font-size) !important;
  font-style: var(--smalltextbold-SP-font-style) !important;
  font-weight: var(--smalltextbold-SP-font-weight) !important;
  letter-spacing: var(--smalltextbold-SP-letter-spacing) !important;
  line-height: var(--smalltextbold-SP-line-height) !important;
}

.home .RECRUITES {
  background-color: var(--palepurple);
  flex-direction: column;
  flex: none;
  align-self: stretch;
  align-items: center;
  gap: 20px;
  width: 100%;
  padding: 40px 20px;
  display: flex;
  position: relative;
}

.home .heading-7 {
  left: 12px !important;
}

.home .heading-8 {
  left: 33px !important;
}

.home .class-42 {
  height: unset !important;
  width: unset !important;
  flex: 1 !important;
  min-width: 290px !important;
  max-width: 360px !important;
  margin-left: -5px !important;
  margin-right: -5px !important;
}

.home .class-43 {
  height: unset !important;
  width: 300px !important;
  max-width: 360px !important;
}

.home .RECRUITE-instance {
  width: unset !important;
  flex: none !important;
  padding: 8px 30px !important;
  display: inline-flex !important;
}

.home .frame-69 {
  font-family: var(--button-SP-font-family) !important;
  font-size: var(--button-SP-font-size) !important;
  font-style: var(--button-SP-font-style) !important;
  font-weight: var(--button-SP-font-weight) !important;
  letter-spacing: var(--button-SP-letter-spacing) !important;
  line-height: var(--button-SP-line-height) !important;
  white-space: unset !important;
}

.home .ABOUTUS {
  background-image: url("aboutus-2.9f05f6c6.png");
  background-position: 50%;
  background-size: cover;
  flex-direction: column;
  flex: none;
  align-self: stretch;
  align-items: center;
  gap: 20px;
  width: 100%;
  padding: 40px 20px;
  display: flex;
  position: relative;
}

.home .heading-9 {
  color: #fff !important;
  left: 12px !important;
}

.home .heading-10 {
  color: #fff !important;
}

.home .frame-70 {
  flex: none;
  gap: 20px;
  max-width: 400px;
  display: inline-flex;
  position: relative;
}

.home .button-3 {
  justify-content: center !important;
}

.home .CONTACT-wrapper {
  background-color: var(--backwhite);
  flex-direction: column;
  flex: none;
  justify-content: center;
  align-self: stretch;
  align-items: center;
  gap: 40px;
  width: 100%;
  display: flex;
  position: relative;
}

.home .CONTACT-3 {
  height: 160px;
  display: block;
  position: relative;
}

.home .frame-71 {
  background-color: var(--purple);
  border-radius: 6px;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  max-width: 700px;
  display: flex;
  position: relative;
}

.home .group-7 {
  width: 216px;
  height: 53px;
  position: relative;
}

.home .text-wrapper-54 {
  color: var(--white);
  letter-spacing: 2px;
  text-align: center;
  width: 212px;
  font-family: Zen Kaku Gothic New, Helvetica;
  font-size: 20px;
  font-weight: 500;
  line-height: normal;
  position: absolute;
  top: 0;
  left: 0;
}

.home .text-wrapper-55 {
  color: var(--white);
  letter-spacing: 0;
  text-align: center;
  width: 134px;
  font-family: Slackside One, Helvetica;
  font-size: 15px;
  font-weight: 400;
  line-height: normal;
  position: absolute;
  top: 31px;
  left: 39px;
}

.home .flexcontainer-i-wrapper {
  width: 212px;
  height: 48px;
  position: relative;
}

.home .flexcontainer-7 {
  flex-direction: column;
  align-items: flex-start;
  gap: 2px;
  width: 210px;
  height: 48px;
  display: flex;
  position: relative;
}

.home .text-i-8 {
  color: var(--white);
  font-family: var(--text-SP-font-family);
  font-size: var(--text-SP-font-size);
  font-style: var(--text-SP-font-style);
  font-weight: var(--text-SP-font-weight);
  letter-spacing: var(--text-SP-letter-spacing);
  line-height: var(--text-SP-line-height);
  align-self: stretch;
  position: relative;
}

.home .text-wrapper-56 {
  font-family: var(--text-SP-font-family);
  font-size: var(--text-SP-font-size);
  font-style: var(--text-SP-font-style);
  font-weight: var(--text-SP-font-weight);
  letter-spacing: var(--text-SP-letter-spacing);
  line-height: var(--text-SP-line-height);
}

.home .footer-2 {
  background-color: #454545;
  flex-direction: column;
  flex: none;
  align-self: stretch;
  align-items: center;
  gap: 50px;
  width: 100%;
  padding: 50px 20px;
  display: flex;
  position: relative;
}

.home .frame-72 {
  flex: none;
  align-self: stretch;
  align-items: flex-start;
  width: 100%;
  display: flex;
  position: relative;
}

.home .frame-73 {
  flex-direction: column;
  align-items: flex-start;
  gap: 24px;
  display: flex;
  position: relative;
}

.home .logo-white-26 {
  width: 121px !important;
  height: 40px !important;
  position: relative !important;
}

.home .text-i-9 {
  color: #fff;
  letter-spacing: .26px;
  align-self: stretch;
  font-family: Noto Sans JP, Helvetica;
  font-size: 13px;
  font-weight: 400;
  line-height: 18px;
  position: relative;
}

.home .text-wrapper-57 {
  letter-spacing: .03px;
}

.home .frame-74 {
  flex: none;
  align-items: flex-start;
  position: relative;
}

.home .frame-75 {
  flex-direction: column;
  flex: none;
  align-items: flex-start;
  gap: 30px;
  display: inline-flex;
  position: relative;
}

.home .frame-76 {
  flex-direction: column;
  flex: none;
  align-items: flex-start;
  gap: 4px;
  display: inline-flex;
  position: relative;
}

.home .text-wrapper-58 {
  color: #fff;
  font-family: var(--text-bold-mini-font-family);
  font-size: var(--text-bold-mini-font-size);
  font-style: var(--text-bold-mini-font-style);
  font-weight: var(--text-bold-mini-font-weight);
  letter-spacing: var(--text-bold-mini-letter-spacing);
  line-height: var(--text-bold-mini-line-height);
  white-space: nowrap;
  width: fit-content;
  margin-top: -1px;
  display: block;
  position: relative;
}

.home .text-wrapper-59, .home .text-wrapper-60, .home .text-wrapper-61, .home .text-wrapper-62, .home .text-wrapper-63, .home .text-wrapper-64, .home .text-wrapper-65 {
  color: #fff;
  white-space: nowrap;
  width: fit-content;
  position: relative;
}

.home .frame-77 {
  flex-direction: column;
  align-self: stretch;
  align-items: flex-start;
  gap: 30px;
  position: relative;
}

.home .text-wrapper-66 {
  color: #fff;
  font-family: var(--text-bold-mini-font-family);
  font-size: var(--text-bold-mini-font-size);
  font-style: var(--text-bold-mini-font-style);
  font-weight: var(--text-bold-mini-font-weight);
  letter-spacing: var(--text-bold-mini-letter-spacing);
  line-height: var(--text-bold-mini-line-height);
  white-space: nowrap;
  width: fit-content;
  display: block;
  position: relative;
}

.home .text-wrapper-67 {
  color: #fff;
  font-family: var(--text-bold-mini-font-family);
  font-size: var(--text-bold-mini-font-size);
  font-style: var(--text-bold-mini-font-style);
  font-weight: var(--text-bold-mini-font-weight);
  letter-spacing: var(--text-bold-mini-letter-spacing);
  line-height: var(--text-bold-mini-line-height);
  white-space: nowrap;
  width: fit-content;
  position: relative;
}

.home .text-wrapper-68 {
  color: #fff;
  letter-spacing: 1.31px;
  width: fit-content;
  font-family: Manrope, Helvetica;
  font-size: 13.1px;
  font-weight: 400;
  line-height: normal;
  position: relative;
}

.home .color-transparent-instance {
  z-index: 1 !important;
  background-color: #0000 !important;
  flex: none !important;
  justify-content: space-between !important;
  align-self: stretch !important;
  width: 100% !important;
  display: flex !important;
}

.home .header-4 {
  z-index: 0 !important;
  width: 60px !important;
  height: 59px !important;
  position: relative !important;
}

.home .div-9 {
  z-index: 0;
  flex-direction: column;
  flex: none;
  align-self: stretch;
  align-items: center;
  width: 100%;
  margin-top: -90px;
  margin-bottom: -4630px;
  display: flex;
  position: relative;
}

.home .frame-78 {
  z-index: 6;
  flex-direction: column;
  flex: none;
  align-self: stretch;
  align-items: flex-start;
  width: 100%;
  display: flex;
  position: relative;
}

.home .FV-2 {
  z-index: 1;
  flex-direction: column;
  justify-content: flex-end;
  align-self: stretch;
  align-items: center;
  gap: 10px;
  width: 100%;
  height: 1194px;
  display: flex;
  position: relative;
  overflow: hidden;
}

.home .group-8 {
  background-image: url("fv-2-2.76fe35bc.png");
  background-position: 50%;
  background-size: cover;
  width: 1987.24px;
  height: 1329px;
  margin-top: -135px;
  margin-left: -353.62px;
  margin-right: -353.62px;
  position: relative;
}

.home .message-2 {
  width: 169px;
  height: 306px;
  position: absolute;
  top: 406px;
  left: 904px;
}

.home .LEAD-2 {
  background-color: var(--backwhite);
  z-index: 0;
  flex-direction: column;
  flex: none;
  align-self: stretch;
  align-items: center;
  gap: 50px;
  width: 100%;
  margin-top: -80px;
  padding: 0 0 100px;
  display: flex;
  position: relative;
}

.home .lead-text-2 {
  flex: none;
  align-items: flex-start;
  gap: 60px;
  width: 1000px;
  max-width: 1000px;
  display: flex;
  position: relative;
}

.home .flexcontainer-8 {
  flex-direction: column;
  align-items: flex-start;
  gap: 2px;
  width: 350px;
  max-width: 450px;
  min-height: 140px;
  display: flex;
}

.home .text-5 {
  color: var(--darkgray);
  letter-spacing: 1.44px;
  align-self: stretch;
  font-family: Noto Sans JP, Helvetica;
  font-size: 24px;
  font-weight: 700;
  line-height: 43px;
  position: relative;
}

.home .text-wrapper-69 {
  letter-spacing: .35px;
}

.home .text-wrapper-70 {
  color: var(--darkgray);
  font-family: var(--lead-font-family);
  font-size: var(--lead-font-size);
  font-style: var(--lead-font-style);
  font-weight: var(--lead-font-weight);
  letter-spacing: var(--lead-letter-spacing);
  line-height: var(--lead-line-height);
  flex: 1;
  margin-top: -1px;
  position: relative;
}

.home .FLOW-2 {
  z-index: 5;
  background-image: url("flow-2.e6da2474.png");
  background-position: 50%;
  background-size: cover;
  flex-direction: column;
  flex: none;
  align-self: stretch;
  align-items: center;
  gap: 40px;
  width: 100%;
  padding: 100px 0;
  display: flex;
  position: relative;
}

.home .heading-11 {
  left: -44px !important;
}

.home .heading-12 {
  left: 24px !important;
}

.home .flexcontainer-9 {
  flex-direction: column;
  align-items: flex-start;
  gap: 5px;
  display: inline-flex;
}

.home .text-6 {
  color: #454545;
  font-family: var(--lead-font-family);
  font-size: var(--lead-font-size);
  font-style: var(--lead-font-style);
  font-weight: var(--lead-font-weight);
  letter-spacing: var(--lead-letter-spacing);
  line-height: var(--lead-line-height);
  text-align: center;
  align-self: stretch;
  position: relative;
}

.home .text-wrapper-71 {
  font-family: var(--lead-font-family);
  font-size: var(--lead-font-size);
  font-style: var(--lead-font-style);
  font-weight: var(--lead-font-weight);
  letter-spacing: var(--lead-letter-spacing);
  line-height: var(--lead-line-height);
}

.home .processe-3 {
  justify-content: center;
  align-items: flex-start;
  width: 1200px;
  max-width: 1200px;
  height: 326px;
  display: flex;
  position: relative;
}

.home .frame-79 {
  flex: none;
  align-items: flex-start;
  display: inline-flex;
  position: relative;
}

.home .process-13 {
  max-width: unset !important;
  flex: none !important;
}

.home .process-14 {
  align-self: stretch !important;
  width: 100% !important;
  display: flex !important;
}

.home .triangle-wrapper {
  width: 20px;
  height: 189px;
  position: relative;
}

.home .triangle02-5 {
  width: 20px !important;
  height: 20px !important;
  position: absolute !important;
  top: 93px !important;
  left: 0 !important;
}

.home .process-15 {
  max-width: unset !important;
  flex: none !important;
  height: 287px !important;
}

.home .process-16 {
  flex-direction: column;
  flex: none;
  align-items: center;
  gap: 20px;
  padding: 10px 15px;
  display: inline-flex;
  position: relative;
}

.home .frame-80 {
  background-color: var(--white);
  border-radius: 10px;
  flex-direction: column;
  flex: none;
  align-self: stretch;
  align-items: center;
  gap: 10px;
  width: 100%;
  padding: 17px 0;
  display: flex;
  position: relative;
  overflow: hidden;
}

.home .image-6 {
  object-fit: cover;
  width: 179px;
  height: 145px;
  position: relative;
}

.home .frame-81 {
  flex-direction: column;
  flex: none;
  align-items: center;
  gap: 10px;
  padding: 0 16px;
  display: inline-flex;
  position: relative;
}

.home .process-17 {
  color: var(--purple);
  letter-spacing: 1.51px;
  text-align: center;
  width: fit-content;
  margin-top: -1px;
  font-family: Manrope, Helvetica;
  font-size: 15.1px;
  font-weight: 800;
  line-height: normal;
  position: relative;
}

.home .text-i-10 {
  color: #272727;
  letter-spacing: 1px;
  text-align: center;
  align-self: stretch;
  font-family: Noto Sans JP, Helvetica;
  font-size: 20px;
  font-weight: 700;
  line-height: 30px;
  position: relative;
}

.home .text-wrapper-72 {
  letter-spacing: .2px;
}

.home .button-4 {
  width: 7.41px !important;
  height: 11.13px !important;
  margin-left: -1.65px !important;
  margin-right: -1.15px !important;
  position: relative !important;
}

.home .BLOGS-2 {
  background-color: var(--backwhite);
  z-index: 4;
  flex-direction: column;
  flex: none;
  align-self: stretch;
  align-items: center;
  gap: 40px;
  width: 100%;
  padding: 100px 0;
  display: flex;
  position: relative;
}

.home .heading-13 {
  left: 28px !important;
}

.home .div-10 {
  flex-wrap: wrap;
  flex: none;
  justify-content: center;
  align-items: flex-start;
  gap: 30px 20px;
  width: 1200px;
  display: flex;
  position: relative;
}

.home .frame-82 {
  width: unset !important;
  flex: 1 !important;
  min-width: 300px !important;
  max-width: 360px !important;
}

.home .RECRUITES-2 {
  background-color: var(--palepurple);
  z-index: 3;
  flex-direction: column;
  flex: none;
  align-self: stretch;
  align-items: center;
  gap: 40px;
  width: 100%;
  padding: 100px 0;
  display: flex;
  position: relative;
}

.home .heading-14 {
  left: 10px !important;
}

.home .flexcontainer-10 {
  flex-direction: column;
  align-items: flex-start;
  gap: 5px;
  display: flex;
}

.home .frame-83 {
  width: unset !important;
  flex: 1 !important;
  min-width: 300px !important;
  max-width: 360px !important;
  height: 397px !important;
}

.home .frame-84 {
  width: 120px !important;
}

.home .frame-85 {
  margin-left: -12px !important;
  margin-right: -12px !important;
}

.home .ABOUTUS-2 {
  z-index: 2;
  background-image: url("aboutus-2.9f05f6c6.png");
  background-position: 50%;
  background-size: cover;
  flex-direction: column;
  flex: none;
  align-self: stretch;
  align-items: center;
  gap: 60px;
  width: 100%;
  padding: 120px 0;
  display: flex;
  position: relative;
}

.home .heading-15 {
  color: #fff !important;
  left: -10px !important;
}

.home .frame-86 {
  flex: none;
  justify-content: center;
  align-items: flex-start;
  gap: 40px;
  width: 700px;
  display: flex;
  position: relative;
}

.home .button-5 {
  width: unset !important;
  flex: 1 !important;
  align-items: flex-start !important;
}

.home .button-6 {
  width: 7.41px !important;
  height: 11.13px !important;
  margin-top: -.57px !important;
  margin-left: -1.65px !important;
  margin-right: -1.15px !important;
  position: relative !important;
}

.home .button-7 {
  width: unset !important;
  flex: 1 !important;
}

.home .CONTACT-4 {
  background-color: var(--backwhite);
  z-index: 1;
  flex-direction: column;
  flex: none;
  align-self: stretch;
  align-items: center;
  gap: 40px;
  width: 100%;
  padding: 100px 15px;
  display: flex;
  position: relative;
}

.home .CONTACT-5 {
  width: 700px;
  height: 160px;
  display: block;
  position: relative;
}

.home .frame-87 {
  background-color: var(--purple);
  border-radius: 6px;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  gap: 20px 74px;
  width: 700px;
  max-width: 700px;
  padding: 50px 60px;
  display: flex;
  position: relative;
}

.home .group-9 {
  width: 216px;
  height: 60px;
  position: relative;
}

.home .overlap-group-4 {
  width: 212px;
  height: 60px;
  position: relative;
}

.home .text-wrapper-73 {
  color: var(--white);
  letter-spacing: 2.4px;
  text-align: center;
  width: 212px;
  font-family: Zen Kaku Gothic New, Helvetica;
  font-size: 24px;
  font-weight: 500;
  line-height: normal;
  position: absolute;
  top: 0;
  left: 0;
}

.home .text-wrapper-74 {
  color: var(--white);
  letter-spacing: 0;
  text-align: center;
  width: 134px;
  font-family: Slackside One, Helvetica;
  font-size: 20px;
  font-weight: 400;
  line-height: normal;
  position: absolute;
  top: 31px;
  left: 39px;
}

.home .group-10 {
  width: 254px;
  height: 55px;
  position: relative;
}

.home .flexcontainer-11 {
  flex-direction: column;
  align-items: flex-start;
  gap: 5px;
  width: 252px;
  height: 55px;
  display: flex;
  position: relative;
}

.home .text-i-11 {
  color: var(--white);
  letter-spacing: .75px;
  align-self: stretch;
  font-family: Noto Sans JP, Helvetica;
  font-size: 15px;
  font-weight: 400;
  line-height: 25px;
  position: relative;
}

.home .text-wrapper-75 {
  letter-spacing: .11px;
}

.home .footer-3 {
  z-index: 0;
  background-color: #454545;
  flex-direction: column;
  flex: none;
  align-self: stretch;
  align-items: center;
  gap: 50px;
  width: 100%;
  padding: 70px 40px 50px;
  display: flex;
  position: relative;
}

.home .frame-88 {
  flex-wrap: wrap;
  flex: none;
  justify-content: space-between;
  align-items: flex-start;
  gap: 50px 150px;
  width: 1200px;
  padding: 0 50px;
  display: flex;
  position: relative;
}

.home .frame-89 {
  flex-direction: column;
  flex: 1;
  align-items: flex-start;
  gap: 44px;
  display: flex;
  position: relative;
}

.home .logo-white-9 {
  width: 163.65px !important;
  height: 54.26px !important;
  position: relative !important;
}

.home .text-i-12 {
  color: #fff;
  font-family: var(--text-font-family);
  font-size: var(--text-font-size);
  font-style: var(--text-font-style);
  font-weight: var(--text-font-weight);
  letter-spacing: var(--text-letter-spacing);
  line-height: var(--text-line-height);
  align-self: stretch;
  position: relative;
}

.home .text-wrapper-76 {
  font-family: var(--text-font-family);
  font-size: var(--text-font-size);
  font-style: var(--text-font-style);
  font-weight: var(--text-font-weight);
  letter-spacing: var(--text-letter-spacing);
  line-height: var(--text-line-height);
}

.home .frame-90 {
  flex: none;
  align-items: flex-start;
  gap: 60px;
  display: inline-flex;
  position: relative;
}

.home .frame-91 {
  flex-direction: column;
  flex: none;
  align-items: flex-start;
  gap: 40px;
  padding: 0 20px;
  display: inline-flex;
  position: relative;
}

.home .frame-92 {
  flex-direction: column;
  flex: none;
  align-items: flex-start;
  gap: 6px;
  display: inline-flex;
  position: relative;
}

.home .text-wrapper-77 {
  color: #fff;
  letter-spacing: .75px;
  white-space: nowrap;
  width: fit-content;
  margin-top: -1px;
  font-family: Noto Sans JP, Helvetica;
  font-size: 15px;
  font-weight: 700;
  line-height: 20px;
  position: relative;
}

.home .text-wrapper-78 {
  color: #fff;
  letter-spacing: .65px;
  white-space: nowrap;
  width: fit-content;
  font-family: Noto Sans JP, Helvetica;
  font-size: 13px;
  font-weight: 700;
  line-height: 20px;
  position: relative;
}

.home .frame-93 {
  flex-direction: column;
  flex: none;
  align-items: flex-start;
  gap: 30px;
  padding: 0 20px;
  display: inline-flex;
  position: relative;
}

.home .text-wrapper-79 {
  color: #fff;
  letter-spacing: .75px;
  white-space: nowrap;
  width: fit-content;
  font-family: Noto Sans JP, Helvetica;
  font-size: 15px;
  font-weight: 700;
  line-height: 20px;
  position: relative;
}

.home .text-wrapper-80 {
  color: #fff;
  letter-spacing: .75px;
  white-space: nowrap;
  width: fit-content;
  font-family: Noto Sans JP, Helvetica;
  font-size: 15px;
  font-weight: 700;
  line-height: 20px;
  display: block;
  position: relative;
}

.checkboxes {
  width: 16px;
  height: 16px;
}

.checkboxes .base {
  background-color: #f3f3f3;
  border: 1px solid #e1e2e4;
  border-radius: 8px;
  height: 16px;
  overflow: hidden;
}

.checkboxes .check {
  background-color: #e1e2e4;
  border-radius: 4px;
  width: 8px;
  height: 8px;
  position: relative;
  top: 4px;
  left: 4px;
}

.checklist {
  align-items: center;
  gap: 12px;
  display: inline-flex;
  position: relative;
}

.checklist .checkboxes-3 {
  position: relative !important;
}

.checklist .text-wrapper-3 {
  color: #454545;
  letter-spacing: .8px;
  white-space: nowrap;
  width: fit-content;
  margin-top: -1px;
  font-family: Noto Sans JP, Helvetica;
  font-size: 16px;
  font-weight: 700;
  line-height: 26px;
  position: relative;
}

.color-white {
  background-color: var(--backwhite);
  align-items: flex-start;
  padding: 0 0 20px 50px;
  display: inline-flex;
  position: relative;
}

.color-white .logo-wrapper {
  width: 158.36px;
  height: 70px;
  position: relative;
}

.element-wrapper {
  background-image: url("1.89bf667b.png");
  background-position: 50%;
  background-size: cover;
  justify-content: center;
  align-items: flex-start;
  gap: 10px;
  position: relative;
}

.element-wrapper .heading-instance {
  position: relative !important;
}

.element-wrapper.one-1-two {
  padding: 10px 0;
  display: inline-flex;
}

.element-wrapper.one-1-one {
  width: 1200px;
  padding: 20px 0 50px;
  display: flex;
}

.img-wrapper {
  width: 5px;
  height: 8px;
  position: absolute;
  top: 0;
  left: 0;
}

.div-wrapper {
  background-color: var(--red);
  border-radius: 6px;
  width: 40px;
  height: 20px;
  position: relative;
}

.div-wrapper .text-wrapper-4 {
  color: var(--white);
  font-family: var(--tag-font-family);
  font-size: var(--tag-font-size);
  font-style: var(--tag-font-style);
  font-weight: var(--tag-font-weight);
  letter-spacing: var(--tag-letter-spacing);
  line-height: var(--tag-line-height);
  text-align: center;
  white-space: nowrap;
  position: absolute;
  top: 1px;
  left: 6px;
}

.required {
  background-color: #f44952;
  border-radius: 6px;
  width: 40px;
  height: 20px;
}

.required .component-10923 {
  width: unset !important;
  background-color: #f44952 !important;
}

.input-field {
  flex-direction: column;
  align-items: flex-start;
  display: inline-flex;
  position: relative;
}

.input-field .frame-wrapper {
  flex: none;
  align-items: flex-start;
  display: inline-flex;
  position: relative;
}

.input-field .frame-4 {
  flex: none;
  align-items: center;
  display: inline-flex;
  position: relative;
}

.input-field .text-wrapper-5 {
  color: #454545;
  width: fit-content;
  margin-top: -1px;
  position: relative;
}

.input-field .class {
  color: #fff !important;
  font-family: Noto Sans JP, Helvetica !important;
  font-size: 11px !important;
  font-style: unset !important;
  letter-spacing: .55px !important;
  font-weight: 500 !important;
  line-height: 14.8px !important;
}

.input-field .class-2 {
  color: #fff !important;
}

.input-field .frame-5 {
  background-color: #f3f3f3;
  border: 1px solid #e1e2e4;
  border-radius: 8px;
  align-self: stretch;
  align-items: center;
  gap: 10px;
  width: 100%;
  display: flex;
  position: relative;
  overflow: hidden;
}

.input-field .text-wrapper-6 {
  color: #b3b3b3;
  white-space: nowrap;
  width: fit-content;
  font-family: Noto Sans JP, Helvetica;
  font-weight: 400;
  line-height: 25px;
  position: relative;
}

.input-field.prop-1-two {
  gap: 9px;
}

.input-field.prop-1-SP {
  gap: 7px;
}

.input-field .prop-one, .input-field .prop-1-0-SP {
  gap: 30px;
}

.input-field .prop-three.prop-1-0-two {
  gap: 20px;
}

.input-field .frame-wrapper.prop-1-0-SP .frame-4 {
  gap: 10px;
}

.input-field .frame-wrapper.prop-1-0-two.prop-one .frame-4 {
  gap: 20px;
}

.input-field .frame-wrapper.prop-three.prop-1-0-two .frame-4 {
  gap: 30px;
}

.input-field .frame-wrapper.prop-1-0-two .text-wrapper-5 {
  letter-spacing: .8px;
  font-family: Noto Sans JP, Helvetica;
  font-size: 16px;
  font-weight: 700;
  line-height: normal;
}

.input-field .frame-wrapper.prop-1-0-SP .text-wrapper-5 {
  font-family: var(--textbold-SP-font-family);
  font-size: var(--textbold-SP-font-size);
  font-style: var(--textbold-SP-font-style);
  font-weight: var(--textbold-SP-font-weight);
  letter-spacing: var(--textbold-SP-letter-spacing);
  line-height: var(--textbold-SP-line-height);
  white-space: nowrap;
}

.input-field.prop-1-two .frame-5 {
  height: 65px;
  padding: 20px;
}

.input-field.prop-1-SP .frame-5 {
  height: 50px;
  padding: 14px 15px;
}

.input-field.prop-1-two .text-wrapper-6 {
  letter-spacing: .75px;
  margin-top: -1px;
  font-size: 15px;
}

.input-field.prop-1-SP .text-wrapper-6 {
  letter-spacing: .65px;
  margin-top: -2.5px;
  margin-bottom: -.5px;
  font-size: 13px;
}

.logo-white {
  width: 146px;
  height: 54px;
  display: block;
  position: absolute;
  top: 0;
  left: 0;
}

.q-a {
  background-color: var(--white);
  border: 1px solid #efeeee;
  border-radius: 6px;
  flex-direction: column;
  align-items: flex-start;
  width: 480px;
  display: flex;
  position: relative;
}

.q-a .frame-6 {
  flex: none;
  justify-content: space-between;
  align-self: stretch;
  width: 100%;
  display: flex;
  position: relative;
}

.q-a .frame-7 {
  align-items: center;
  gap: 14px;
  width: 268.61px;
  display: flex;
  position: relative;
}

.q-a .frame-8 {
  flex-wrap: wrap;
  flex: none;
  justify-content: center;
  align-items: center;
  gap: 10px;
  margin-right: -4.39px;
  display: inline-flex;
  position: relative;
}

.q-a .text-wrapper-7 {
  color: var(--dark-blue);
  flex: 1;
  margin-top: -1px;
  position: relative;
}

.q-a .frame-9 {
  flex: 1;
  align-items: flex-start;
  gap: 20px;
  display: flex;
  position: relative;
}

.q-a .frame-10 {
  flex-wrap: wrap;
  flex: none;
  align-items: flex-start;
  gap: 10px;
  display: inline-flex;
  position: relative;
}

.q-a .text-wrapper-8 {
  color: var(--dark-blue);
  flex: 1;
  margin-top: -1px;
  position: relative;
}

.q-a .class-3 {
  width: 12px !important;
  height: 12px !important;
  position: relative !important;
}

.q-a .class-4 {
  width: 17px !important;
  height: 17px !important;
  position: relative !important;
}

.q-a .frame-11 {
  flex: none;
  align-self: stretch;
  align-items: flex-start;
  gap: 136px;
  width: 100%;
  display: flex;
  position: relative;
}

.q-a .frame-12 {
  flex: 1;
  align-items: flex-start;
  gap: 14px;
  display: flex;
  position: relative;
}

.q-a .frame-13 {
  flex-wrap: wrap;
  flex: 1;
  align-items: flex-start;
  gap: 10px;
  display: flex;
  position: relative;
}

.q-a .div-3 {
  color: #454545;
  flex: 1;
  margin-top: -1px;
  position: relative;
}

.q-a .span {
  font-family: var(--text-font-family);
  font-size: var(--text-font-size);
  font-style: var(--text-font-style);
  font-weight: var(--text-font-weight);
  letter-spacing: var(--text-letter-spacing);
  line-height: var(--text-line-height);
}

.q-a .q-aicon {
  width: 24px !important;
  height: 24px !important;
  position: relative !important;
}

.q-a.device-0-PC {
  padding: 30px 20px;
}

.q-a.open-one {
  gap: 30px;
}

.q-a.device-0-SP {
  padding: 20px;
}

.q-a.device-0-PC.open-open {
  gap: 20px;
}

.q-a.open-open.device-0-SP {
  gap: 10px;
}

.q-a.open-open .frame-6, .q-a.device-0-SP .frame-6 {
  align-items: center;
}

.q-a.open-one.device-0-PC .frame-6 {
  align-items: flex-start;
}

.q-a.device-0-PC .text-wrapper-7 {
  letter-spacing: .8px;
  font-family: Noto Sans JP, Helvetica;
  font-size: 16px;
  font-weight: 700;
  line-height: normal;
}

.q-a.device-0-SP .text-wrapper-7 {
  font-family: var(--textbold-SP-font-family);
  font-size: var(--textbold-SP-font-size);
  font-style: var(--textbold-SP-font-style);
  font-weight: var(--textbold-SP-font-weight);
  letter-spacing: var(--textbold-SP-letter-spacing);
  line-height: var(--textbold-SP-line-height);
}

.q-a.device-0-PC .text-wrapper-8 {
  letter-spacing: .8px;
  font-family: Noto Sans JP, Helvetica;
  font-size: 16px;
  font-weight: 700;
  line-height: normal;
}

.q-a.device-0-SP .text-wrapper-8 {
  font-family: var(--textbold-SP-font-family);
  font-size: var(--textbold-SP-font-size);
  font-style: var(--textbold-SP-font-style);
  font-weight: var(--textbold-SP-font-weight);
  letter-spacing: var(--textbold-SP-letter-spacing);
  line-height: var(--textbold-SP-line-height);
}

.q-a.device-0-PC .div-3 {
  font-family: var(--text-font-family);
  font-size: var(--text-font-size);
  font-style: var(--text-font-style);
  font-weight: var(--text-font-weight);
  letter-spacing: var(--text-letter-spacing);
  line-height: var(--text-line-height);
}

.q-a.device-0-SP .div-3 {
  font-family: var(--text-SP-font-family);
  font-size: var(--text-SP-font-size);
  font-style: var(--text-SP-font-style);
  font-weight: var(--text-SP-font-weight);
  letter-spacing: var(--text-SP-letter-spacing);
  line-height: var(--text-SP-line-height);
}

.instance-node {
  width: 24px !important;
  height: 24px !important;
  position: absolute !important;
  top: 0 !important;
  left: 0 !important;
}

.SP-white {
  background-color: var(--backwhite);
  justify-content: space-between;
  align-items: flex-start;
  width: 125px;
  height: 45px;
  padding: 0 0 20px 10px;
  display: flex;
  position: relative;
}

.SP-white .group {
  width: 79.18px;
  height: 35px;
  margin-bottom: -10px;
  position: relative;
}

.SP-white .logo-instance {
  width: 72px !important;
  height: 27px !important;
  position: relative !important;
  top: 8px !important;
}

.SP-white .menu-10 {
  width: 36px !important;
  height: 35px !important;
  margin-bottom: -10px !important;
  position: relative !important;
}

.contact {
  flex-direction: column;
  align-items: center;
  display: flex;
  position: relative;
}

.contact .header {
  height: unset !important;
  z-index: 1 !important;
  flex: none !important;
  align-self: stretch !important;
  width: 100% !important;
}

.contact .SP-white-instance {
  margin-bottom: unset !important;
}

.contact .menu-3 {
  width: 36px !important;
  height: 35px !important;
  position: relative !important;
}

.contact .class-5 {
  z-index: 0 !important;
  width: 60px !important;
  height: 59px !important;
  position: relative !important;
}

.contact .color-white-instance {
  z-index: 1 !important;
  flex: none !important;
  justify-content: space-between !important;
  align-self: stretch !important;
  width: 100% !important;
  display: flex !important;
}

.contact .class-6 {
  z-index: 1 !important;
}

.contact .contacts {
  z-index: 0;
  background-color: #fff;
  flex-direction: column;
  flex: none;
  align-self: stretch;
  align-items: flex-start;
  width: 100%;
  display: flex;
  position: relative;
}

.contact .midashi {
  flex-direction: column;
  flex: none;
  align-self: stretch;
  align-items: flex-start;
  width: 100%;
  display: flex;
  position: relative;
}

.contact .class-7 {
  color: #fff !important;
  left: -10px !important;
}

.contact .class-8 {
  color: #fff !important;
  left: 4px !important;
}

.contact .class-9 {
  color: #fff !important;
  left: 30px !important;
}

.contact .class-10 {
  color: #fff !important;
  left: -44px !important;
}

.contact .class-11 {
  flex: none !important;
  align-self: stretch !important;
  width: 100% !important;
  padding: 5px 0 !important;
  display: flex !important;
}

.contact .class-12 {
  background-image: url("midashi-1.05af3916.png") !important;
  flex: none !important;
  align-self: stretch !important;
  width: 100% !important;
}

.contact .q-a-instance {
  flex: none !important;
  align-self: stretch !important;
  width: 100% !important;
}

.contact .breadcrumbs {
  flex: none;
  justify-content: center;
  align-self: stretch;
  align-items: flex-start;
  gap: 30px;
  width: 100%;
  display: flex;
  position: relative;
}

.contact .frame-38 {
  flex: 1;
  align-items: center;
  display: flex;
  position: relative;
}

.contact .text-wrapper-24 {
  color: var(--gray);
  white-space: nowrap;
  width: fit-content;
  margin-top: -1px;
  display: block;
  position: relative;
}

.contact .instance-node-2 {
  width: 4.57px !important;
  height: 8.14px !important;
  position: relative !important;
}

.contact .component-10930 {
  left: unset !important;
  top: unset !important;
  width: 4.57px !important;
  height: 8.14px !important;
  position: relative !important;
}

.contact .div-6 {
  color: var(--gray);
  white-space: nowrap;
  width: fit-content;
  margin-top: -1px;
  position: relative;
}

.contact .text-wrapper-25 {
  color: var(--gray);
  font-family: var(--tag-font-family);
  font-size: var(--tag-font-size);
  font-style: var(--tag-font-style);
  font-weight: var(--tag-font-weight);
  letter-spacing: var(--tag-letter-spacing);
  line-height: var(--tag-line-height);
  white-space: nowrap;
  width: fit-content;
  margin-top: -1px;
  position: relative;
}

.contact .contactform {
  background-color: var(--palepurple);
  flex-direction: column;
  flex: none;
  align-self: stretch;
  align-items: center;
  gap: 40px;
  width: 100%;
  display: flex;
  position: relative;
}

.contact .flexcontainer {
  flex-direction: column;
  align-items: flex-start;
  display: inline-flex;
}

.contact .text, .contact .span-wrapper {
  color: #454545;
  text-align: center;
  align-self: stretch;
  position: relative;
}

.contact .frame-39 {
  flex: none;
  align-items: center;
  position: relative;
}

.contact .p {
  color: #6079c1;
  font-family: var(--textbold-SP-font-family);
  font-size: var(--textbold-SP-font-size);
  font-style: var(--textbold-SP-font-style);
  font-weight: var(--textbold-SP-font-weight);
  letter-spacing: var(--textbold-SP-letter-spacing);
  line-height: var(--textbold-SP-line-height);
  text-align: right;
  align-self: stretch;
  position: relative;
}

.contact .text-wrapper-26 {
  font-family: var(--textbold-SP-font-family);
  font-size: var(--textbold-SP-font-size);
  font-style: var(--textbold-SP-font-style);
  font-weight: var(--textbold-SP-font-weight);
  letter-spacing: var(--textbold-SP-letter-spacing);
  line-height: var(--textbold-SP-line-height);
}

.contact .class-13 {
  font-family: var(--textbold-SP-font-family) !important;
  font-size: var(--textbold-SP-font-size) !important;
  font-style: var(--textbold-SP-font-style) !important;
  font-weight: var(--textbold-SP-font-weight) !important;
  letter-spacing: var(--textbold-SP-letter-spacing) !important;
  line-height: var(--textbold-SP-line-height) !important;
  white-space: nowrap !important;
}

.contact .class-14 {
  margin-top: -11.5px !important;
  margin-bottom: -11.5px !important;
}

.contact .class-15 {
  width: 7.41px !important;
  height: 11.13px !important;
  margin-left: -1.64px !important;
  margin-right: -1.15px !important;
  position: relative !important;
}

.contact .frame-40 {
  background-color: var(--back-white);
  border-radius: 14px;
  flex-direction: column;
  flex: none;
  align-items: flex-start;
  display: flex;
  position: relative;
}

.contact .frame-41 {
  flex: none;
  align-items: flex-start;
  position: relative;
}

.contact .class-16 {
  font-family: var(--text-bold-mini-font-family) !important;
  font-size: var(--text-bold-mini-font-size) !important;
  font-style: var(--text-bold-mini-font-style) !important;
  font-weight: var(--text-bold-mini-font-weight) !important;
  letter-spacing: var(--text-bold-mini-letter-spacing) !important;
  line-height: var(--text-bold-mini-line-height) !important;
}

.contact .class-17 {
  flex: none !important;
  gap: 8px !important;
}

.contact .checklist-instance {
  flex: none !important;
}

.contact .input-field-instance {
  flex: none !important;
  align-self: stretch !important;
  width: 100% !important;
  display: flex !important;
}

.contact .class-18 {
  margin-bottom: unset !important;
  margin-top: -1px !important;
}

.contact .class-19 {
  border-radius: unset !important;
  position: relative !important;
}

.contact .class-20 {
  position: relative !important;
}

.contact .class-21 {
  height: unset !important;
  flex: none !important;
}

.contact .class-22, .contact .class-23, .contact .class-24 {
  position: relative !important;
}

.contact .class-25 {
  align-self: stretch !important;
  width: 100% !important;
  height: 82px !important;
  display: flex !important;
}

.contact .class-26 {
  align-self: stretch !important;
  width: 100% !important;
  height: 326px !important;
  display: flex !important;
}

.contact .class-27 {
  margin-bottom: unset !important;
  white-space: unset !important;
  width: unset !important;
  flex: 1 !important;
  margin-top: -1px !important;
}

.contact .class-28 {
  position: relative !important;
}

.contact .input-field-2 {
  height: unset !important;
  flex: 1 !important;
  align-items: flex-start !important;
}

.contact .class-29 {
  font-family: var(--button-SP-font-family) !important;
  font-size: var(--button-SP-font-size) !important;
  font-style: var(--button-SP-font-style) !important;
  font-weight: var(--button-SP-font-weight) !important;
  letter-spacing: var(--button-SP-letter-spacing) !important;
  line-height: var(--button-SP-line-height) !important;
}

.contact .class-30 {
  align-self: stretch !important;
  width: 100% !important;
}

.contact .q-a-2 {
  background-color: var(--palepurple);
  flex-direction: column;
  flex: none;
  align-self: stretch;
  align-items: center;
  width: 100%;
  display: flex;
  position: relative;
}

.contact .class-31 {
  left: -10px !important;
}

.contact .class-32 {
  left: 35px !important;
}

.contact .heading-2 {
  position: relative !important;
}

.contact .class-33 {
  left: 45px !important;
}

.contact .class-34 {
  left: -44px !important;
}

.contact .frame-42 {
  flex: none;
  align-items: flex-start;
  display: flex;
  position: relative;
}

.contact .frame-43 {
  flex-direction: column;
  align-items: center;
  display: flex;
  position: relative;
}

.contact .class-35 {
  gap: 12px !important;
}

.contact .class-36 {
  align-items: flex-start !important;
  justify-content: unset !important;
  margin-right: unset !important;
  flex: 1 !important;
  display: flex !important;
}

.contact .class-37 {
  flex: none !important;
  align-self: stretch !important;
  width: 100% !important;
  padding: 20px 10px !important;
}

.contact .class-38 {
  width: 12px !important;
  height: 12px !important;
  margin-left: -10.61px !important;
  position: relative !important;
}

.contact .triangle {
  width: 17px !important;
  height: 17px !important;
  position: relative !important;
}

.contact .q-aicon-2 {
  width: 24px !important;
  height: 24px !important;
  position: relative !important;
}

.contact .class-39 {
  flex: 1 !important;
  display: flex !important;
}

.contact .q-a-3 {
  background-color: var(--white);
  border: 1px solid #efeeee;
  border-radius: 6px;
  flex-direction: column;
  flex: none;
  align-self: stretch;
  align-items: flex-start;
  gap: 20px;
  width: 100%;
  padding: 30px 20px;
  display: flex;
  position: relative;
}

.contact .frame-44 {
  flex: none;
  justify-content: space-between;
  align-self: stretch;
  align-items: center;
  width: 100%;
  display: flex;
  position: relative;
}

.contact .frame-45 {
  align-items: center;
  gap: 14px;
  width: 268.61px;
  display: flex;
  position: relative;
}

.contact .frame-46 {
  flex-wrap: wrap;
  flex: none;
  justify-content: center;
  align-items: center;
  gap: 10px;
  margin-right: -4.39px;
  display: inline-flex;
  position: relative;
}

.contact .text-wrapper-27 {
  color: var(--dark-blue);
  letter-spacing: .8px;
  flex: 1;
  margin-top: -1px;
  font-family: Noto Sans JP, Helvetica;
  font-size: 16px;
  font-weight: 700;
  line-height: normal;
  position: relative;
}

.contact .frame-47 {
  flex: none;
  align-self: stretch;
  align-items: flex-start;
  gap: 136px;
  width: 100%;
  display: flex;
  position: relative;
}

.contact .frame-48 {
  flex: 1;
  align-items: flex-start;
  gap: 14px;
  display: flex;
  position: relative;
}

.contact .frame-49 {
  flex-wrap: wrap;
  flex: 1;
  align-items: flex-start;
  gap: 10px;
  display: flex;
  position: relative;
}

.contact .text-wrapper-28 {
  color: #454545;
  font-family: var(--text-font-family);
  font-size: var(--text-font-size);
  font-style: var(--text-font-style);
  font-weight: var(--text-font-weight);
  letter-spacing: var(--text-letter-spacing);
  line-height: var(--text-line-height);
  flex: 1;
  margin-top: -1px;
  position: relative;
}

.contact .frame-50 {
  flex-direction: column;
  align-items: center;
  display: flex;
  position: relative;
}

.contact .CONTACT {
  background-color: var(--backwhite);
  flex-direction: column;
  flex: none;
  align-self: stretch;
  align-items: center;
  gap: 40px;
  width: 100%;
  display: flex;
  position: relative;
}

.contact .CONTACT-2 {
  height: 160px;
  position: relative;
}

.contact .frame-51 {
  background-color: var(--purple);
  border-radius: 6px;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  gap: 20px 74px;
  max-width: 700px;
  display: flex;
  position: relative;
}

.contact .group-4 {
  width: 216px;
  position: relative;
}

.contact .text-wrapper-29 {
  color: var(--white);
  letter-spacing: 2px;
  text-align: center;
  width: 212px;
  font-family: Zen Kaku Gothic New, Helvetica;
  font-size: 20px;
  font-weight: 500;
  line-height: normal;
  position: absolute;
  top: 0;
  left: 0;
}

.contact .text-wrapper-30 {
  color: var(--white);
  letter-spacing: 0;
  text-align: center;
  width: 134px;
  font-family: Slackside One, Helvetica;
  font-size: 15px;
  font-weight: 400;
  line-height: normal;
  position: absolute;
  top: 31px;
  left: 39px;
}

.contact .overlap-group-3 {
  width: 212px;
  height: 60px;
  position: relative;
}

.contact .text-wrapper-31 {
  color: var(--white);
  letter-spacing: 2.4px;
  text-align: center;
  width: 212px;
  font-family: Zen Kaku Gothic New, Helvetica;
  font-size: 24px;
  font-weight: 500;
  line-height: normal;
  position: absolute;
  top: 0;
  left: 0;
}

.contact .text-wrapper-32 {
  color: var(--white);
  letter-spacing: 0;
  text-align: center;
  width: 134px;
  font-family: Slackside One, Helvetica;
  font-size: 20px;
  font-weight: 400;
  line-height: normal;
  position: absolute;
  top: 31px;
  left: 39px;
}

.contact .flexcontainer-wrapper {
  position: relative;
}

.contact .flexcontainer-i {
  flex-direction: column;
  align-items: flex-start;
  display: flex;
  position: relative;
}

.contact .text-i, .contact .text-i-2 {
  color: var(--white);
  align-self: stretch;
  position: relative;
}

.contact .footer {
  background-color: #454545;
  flex-direction: column;
  flex: none;
  align-self: stretch;
  align-items: center;
  gap: 50px;
  width: 100%;
  display: flex;
  position: relative;
}

.contact .frame-52 {
  flex: none;
  align-items: flex-start;
  display: flex;
  position: relative;
}

.contact .frame-53 {
  flex-direction: column;
  align-items: flex-start;
  position: relative;
}

.contact .logo-white-22 {
  width: 121px !important;
  height: 40px !important;
  position: relative !important;
}

.contact .logo-white-instance {
  left: unset !important;
  top: unset !important;
  width: 163.65px !important;
  height: 54.26px !important;
  position: relative !important;
}

.contact .flexcontainer-2 {
  flex-direction: column;
  align-items: flex-start;
  display: flex;
}

.contact .text-i-3, .contact .text-i-4 {
  color: #fff;
  align-self: stretch;
  position: relative;
}

.contact .logo-white-30 {
  width: 163.65px !important;
  height: 54.26px !important;
  position: relative !important;
}

.contact .flexcontainer-3 {
  flex-direction: column;
  align-items: flex-start;
  gap: 5px;
  display: flex;
}

.contact .text-i-5 {
  color: #fff;
  font-family: var(--text-font-family);
  font-size: var(--text-font-size);
  font-style: var(--text-font-style);
  font-weight: var(--text-font-weight);
  letter-spacing: var(--text-letter-spacing);
  line-height: var(--text-line-height);
  align-self: stretch;
  position: relative;
}

.contact .text-wrapper-33 {
  font-family: var(--text-font-family);
  font-size: var(--text-font-size);
  font-style: var(--text-font-style);
  font-weight: var(--text-font-weight);
  letter-spacing: var(--text-letter-spacing);
  line-height: var(--text-line-height);
}

.contact .frame-54 {
  flex: none;
  align-items: flex-start;
  position: relative;
}

.contact .frame-55, .contact .frame-56 {
  flex-direction: column;
  flex: none;
  align-items: flex-start;
  display: inline-flex;
  position: relative;
}

.contact .text-wrapper-34 {
  color: #fff;
  white-space: nowrap;
  width: fit-content;
  margin-top: -1px;
  display: block;
  position: relative;
}

.contact .text-wrapper-35, .contact .text-wrapper-36, .contact .text-wrapper-37 {
  color: #fff;
  white-space: nowrap;
  width: fit-content;
  position: relative;
}

.contact .frame-57 {
  flex-direction: column;
  flex: none;
  align-items: flex-start;
  display: inline-flex;
  position: relative;
}

.contact .text-wrapper-38 {
  color: #fff;
  white-space: nowrap;
  width: fit-content;
  margin-top: -1px;
  position: relative;
}

.contact .text-wrapper-39, .contact .text-wrapper-40 {
  color: #fff;
  white-space: nowrap;
  width: fit-content;
  position: relative;
}

.contact .frame-58 {
  flex-direction: column;
  flex: none;
  align-items: flex-start;
  display: inline-flex;
  position: relative;
}

.contact .text-wrapper-41 {
  color: #fff;
  white-space: nowrap;
  width: fit-content;
  margin-top: -1px;
  position: relative;
}

.contact .text-wrapper-42, .contact .text-wrapper-43 {
  color: #fff;
  white-space: nowrap;
  width: fit-content;
  position: relative;
}

.contact .frame-59 {
  flex-direction: column;
  align-items: flex-start;
  gap: 30px;
  position: relative;
}

.contact .text-wrapper-44 {
  color: #fff;
  white-space: nowrap;
  width: fit-content;
  margin-top: -1px;
  display: block;
  position: relative;
}

.contact .text-wrapper-45, .contact .text-wrapper-46, .contact .text-wrapper-47, .contact .text-wrapper-48 {
  color: #fff;
  white-space: nowrap;
  width: fit-content;
  display: block;
  position: relative;
}

.contact .text-wrapper-49 {
  color: #fff;
  letter-spacing: 1.31px;
  width: fit-content;
  font-family: Manrope, Helvetica;
  font-size: 13.1px;
  font-weight: 400;
  line-height: normal;
  position: relative;
}

.frame-36 {
  border: 2px solid;
  border-color: var(--purple);
  border-radius: 30px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  padding: 17px 65px;
  display: inline-flex;
  position: relative;
  overflow: hidden;
}

.frame-36 .text-wrapper-16 {
  color: var(--purple);
  letter-spacing: .75px;
  text-align: center;
  white-space: nowrap;
  width: fit-content;
  margin-top: -2px;
  font-family: Noto Sans JP, Helvetica;
  font-size: 15px;
  font-weight: 600;
  line-height: 26px;
  position: relative;
}

.frame-36.one-9-two {
  background-color: #fdfdfdcc;
}

.frame-36.one-9-one, .frame-36.one-9-three {
  background-color: var(--back-white);
}

.grayline {
  background-image: url("line-13.9cc7453e.svg");
  background-position: 50%;
  background-size: cover;
  width: 900px;
  height: 1px;
  position: relative;
  top: -1px;
}

.noimage-wrapper {
  width: 191px;
  height: 26px;
  position: absolute;
  top: 0;
  left: 0;
}

.polygon {
  position: absolute;
  top: 0;
  left: 0;
}

.polygon.one-10-two {
  width: 11px;
  height: 17px;
}

.polygon.one-10-one {
  width: 10px;
  height: 16px;
}

.polygon.one-10-dark-blue, .polygon.one-10-white {
  width: 11px;
  height: 17px;
}

.blog-screen {
  flex-direction: column;
  align-items: center;
  display: flex;
  position: relative;
}

.blog-screen .header-5 {
  height: unset !important;
  z-index: 1 !important;
  flex: none !important;
  align-self: stretch !important;
  width: 100% !important;
}

.blog-screen .header-6 {
  margin-bottom: unset !important;
}

.blog-screen .menu-2 {
  width: 36px !important;
  height: 35px !important;
  position: relative !important;
}

.blog-screen .blog {
  z-index: 0;
  background-color: #fff;
  flex-direction: column;
  flex: none;
  align-items: flex-start;
  width: 320px;
  display: flex;
  position: relative;
  overflow: hidden;
}

.blog-screen .div-11 {
  flex-direction: column;
  flex: none;
  align-self: stretch;
  align-items: flex-start;
  width: 100%;
  display: flex;
  position: relative;
}

.blog-screen .heading-16 {
  flex: none !important;
  align-self: stretch !important;
  width: 100% !important;
  padding: 5px 0 !important;
  display: flex !important;
}

.blog-screen .heading-17 {
  color: #fff !important;
}

.blog-screen .heading-18 {
  color: #fff !important;
  left: 42px !important;
}

.blog-screen .breadcrumbs-2 {
  flex: none;
  justify-content: center;
  align-self: stretch;
  align-items: flex-start;
  gap: 30px;
  width: 100%;
  padding: 10px 15px 20px;
  display: flex;
  position: relative;
}

.blog-screen .frame-94 {
  flex: 1;
  align-items: center;
  gap: 17px;
  display: flex;
  position: relative;
}

.blog-screen .text-wrapper-81 {
  color: var(--gray);
  font-family: var(--smalltext-SP-font-family);
  font-size: var(--smalltext-SP-font-size);
  font-style: var(--smalltext-SP-font-style);
  font-weight: var(--smalltext-SP-font-weight);
  letter-spacing: var(--smalltext-SP-letter-spacing);
  line-height: var(--smalltext-SP-line-height);
  white-space: nowrap;
  width: fit-content;
  margin-top: -1px;
  display: block;
  position: relative;
}

.blog-screen .instance-node-4 {
  width: 4.57px !important;
  height: 8.14px !important;
  position: relative !important;
}

.blog-screen .text-wrapper-82 {
  color: var(--gray);
  font-family: var(--smalltext-SP-font-family);
  font-size: var(--smalltext-SP-font-size);
  font-style: var(--smalltext-SP-font-style);
  font-weight: var(--smalltext-SP-font-weight);
  letter-spacing: var(--smalltext-SP-letter-spacing);
  line-height: var(--smalltext-SP-line-height);
  white-space: nowrap;
  width: fit-content;
  margin-top: -1px;
  position: relative;
}

.blog-screen .view {
  background-color: var(--palepurple);
  flex-direction: column;
  flex: none;
  align-self: stretch;
  align-items: center;
  gap: 60px;
  width: 100%;
  padding: 40px 10px;
  display: flex;
  position: relative;
}

.blog-screen .frame-95 {
  flex-direction: column;
  flex: none;
  justify-content: center;
  align-self: stretch;
  align-items: flex-start;
  gap: 20px;
  width: 100%;
  display: flex;
  position: relative;
}

.blog-screen .frame-96 {
  background-color: var(--back-white);
  border-radius: 14px;
  flex-direction: column;
  flex: none;
  align-self: stretch;
  align-items: flex-start;
  gap: 15px;
  width: 100%;
  padding: 20px 15px;
  display: flex;
  position: relative;
}

.blog-screen .frame-97 {
  flex-direction: column;
  flex: none;
  align-self: stretch;
  align-items: flex-start;
  width: 100%;
  max-width: 800px;
  padding: 0 5px;
  display: flex;
  position: relative;
}

.blog-screen .group-11 {
  width: 93px;
  height: 18.74px;
  position: relative;
}

.blog-screen .text-wrapper-83 {
  color: var(--black);
  letter-spacing: 1.2px;
  width: 91px;
  font-family: Manrope, Helvetica;
  font-size: 12px;
  font-weight: 400;
  line-height: normal;
  position: absolute;
  top: 0;
  left: 0;
}

.blog-screen .frame-98 {
  flex-wrap: wrap;
  flex: none;
  align-self: stretch;
  align-items: flex-start;
  gap: 0 10px;
  width: 100%;
  display: flex;
  position: relative;
}

.blog-screen .text-wrapper-84 {
  color: var(--purple);
  letter-spacing: .2px;
  white-space: nowrap;
  width: fit-content;
  margin-top: -1px;
  font-family: Noto Sans JP, Helvetica;
  font-size: 10px;
  font-weight: 500;
  line-height: 18px;
  position: relative;
}

.blog-screen .text-wrapper-85 {
  color: var(--purple);
  letter-spacing: .2px;
  white-space: nowrap;
  width: 43px;
  height: 17px;
  margin-top: -1px;
  font-family: Noto Sans JP, Helvetica;
  font-size: 10px;
  font-weight: 500;
  line-height: 18px;
  position: relative;
}

.blog-screen .text-wrapper-86 {
  color: var(--purple);
  letter-spacing: .2px;
  white-space: nowrap;
  width: 43px;
  height: 17px;
  font-family: Noto Sans JP, Helvetica;
  font-size: 10px;
  font-weight: 500;
  line-height: 18px;
  position: relative;
}

.blog-screen .text-wrapper-87 {
  color: #454545;
  letter-spacing: .28px;
  align-self: stretch;
  font-family: Noto Sans JP, Helvetica;
  font-size: 14px;
  font-weight: 700;
  line-height: 24px;
  position: relative;
}

.blog-screen .frame-99 {
  flex-direction: column;
  flex: none;
  align-self: stretch;
  align-items: flex-start;
  gap: 10px;
  width: 100%;
  display: flex;
  position: relative;
}

.blog-screen .grayline-instance {
  top: unset !important;
  background-image: url("line-13-2.4e6ea8ec.svg") !important;
  align-self: stretch !important;
  width: 100% !important;
  margin-top: -1px !important;
}

.blog-screen .noimage-23-wrapper {
  background-color: var(--gray);
  border-radius: 6px;
  flex-direction: column;
  justify-content: center;
  align-self: stretch;
  align-items: center;
  gap: 10px;
  width: 100%;
  height: 233.92px;
  padding: 104px 70px;
  display: flex;
  position: relative;
  overflow: hidden;
}

.blog-screen .noimage-3 {
  width: 196.71px !important;
  height: 25.92px !important;
  margin-left: -33.35px !important;
  margin-right: -33.35px !important;
  position: relative !important;
}

.blog-screen .noimage-24-wrapper {
  background-color: var(--gray);
  border-radius: 6px;
  flex-direction: column;
  flex: none;
  justify-content: center;
  align-self: stretch;
  align-items: center;
  gap: 10px;
  width: 100%;
  padding: 104px 70px;
  display: flex;
  position: relative;
  overflow: hidden;
}

.blog-screen .text-wrapper-88 {
  color: #454545;
  font-family: var(--text-SP-font-family);
  font-size: var(--text-SP-font-size);
  font-style: var(--text-SP-font-style);
  font-weight: var(--text-SP-font-weight);
  letter-spacing: var(--text-SP-letter-spacing);
  line-height: var(--text-SP-line-height);
  align-self: stretch;
  position: relative;
}

.blog-screen .frame-100 {
  flex: none;
  justify-content: space-between;
  align-self: stretch;
  align-items: center;
  width: 100%;
  padding: 0 5px;
  display: flex;
  position: relative;
}

.blog-screen .group-12 {
  width: 82.68px;
  height: 23px;
  position: relative;
}

.blog-screen .overlap-group-5 {
  width: 81px;
  height: 23px;
  position: relative;
}

.blog-screen .text-wrapper-89 {
  color: var(--darkgray);
  font-family: var(--text-SP-font-family);
  font-size: var(--text-SP-font-size);
  font-style: var(--text-SP-font-style);
  font-weight: var(--text-SP-font-weight);
  letter-spacing: var(--text-SP-letter-spacing);
  line-height: var(--text-SP-line-height);
  text-align: right;
  width: 81px;
  height: 23px;
  position: absolute;
  top: 0;
  left: 0;
}

.blog-screen .arrow-31 {
  width: 9px !important;
  height: 11px !important;
  position: absolute !important;
  top: 6px !important;
  left: 1px !important;
}

.blog-screen .frame-instance {
  width: 100px !important;
  height: 40px !important;
  padding: 17px 30px !important;
  display: flex !important;
}

.blog-screen .frame-36-instance {
  font-family: var(--button-SP-font-family) !important;
  font-size: var(--button-SP-font-size) !important;
  font-style: var(--button-SP-font-style) !important;
  font-weight: var(--button-SP-font-weight) !important;
  letter-spacing: var(--button-SP-letter-spacing) !important;
  line-height: var(--button-SP-line-height) !important;
  white-space: unset !important;
  margin-top: -7.5px !important;
  margin-bottom: -3.5px !important;
}

.blog-screen .group-13 {
  width: 84px;
  height: 23px;
  margin-right: -2px;
  position: relative;
}

.blog-screen .text-wrapper-90 {
  color: var(--darkgray);
  font-family: var(--text-SP-font-family);
  font-size: var(--text-SP-font-size);
  font-style: var(--text-SP-font-style);
  font-weight: var(--text-SP-font-weight);
  letter-spacing: var(--text-SP-letter-spacing);
  line-height: var(--text-SP-line-height);
  text-align: right;
  width: 66px;
  height: 23px;
  position: absolute;
  top: 0;
  left: 0;
}

.blog-screen .arrow-38 {
  width: 7px !important;
  height: 11px !important;
  position: absolute !important;
  top: 6px !important;
  left: 76px !important;
}

.blog-screen .frame-101 {
  background-color: #6079c1;
  border-radius: 10px 10px 0 0;
  flex-direction: column;
  flex: none;
  align-self: stretch;
  align-items: center;
  gap: 30px;
  width: 100%;
  padding: 10px 15px;
  display: flex;
  position: relative;
}

.blog-screen .text-wrapper-91 {
  color: #fff;
  font-family: var(--smalltextbold-SP-font-family);
  font-size: var(--smalltextbold-SP-font-size);
  font-style: var(--smalltextbold-SP-font-style);
  font-weight: var(--smalltextbold-SP-font-weight);
  letter-spacing: var(--smalltextbold-SP-letter-spacing);
  line-height: var(--smalltextbold-SP-line-height);
  text-align: center;
  white-space: nowrap;
  width: fit-content;
  margin-top: -1px;
  position: relative;
}

.blog-screen .frame-102 {
  background-color: #fff;
  border-radius: 0 0 10px 10px;
  flex-direction: column;
  flex: none;
  align-self: stretch;
  align-items: center;
  gap: 17px;
  width: 100%;
  padding: 20px 15px;
  display: flex;
  position: relative;
}

.blog-screen .frame-103 {
  flex: none;
  justify-content: space-between;
  align-self: stretch;
  align-items: center;
  width: 100%;
  padding: 0 10px;
  display: flex;
  position: relative;
}

.blog-screen .text-wrapper-92 {
  color: var(--darkgray);
  font-family: var(--smalltextbold-SP-font-family);
  font-size: var(--smalltextbold-SP-font-size);
  font-style: var(--smalltextbold-SP-font-style);
  font-weight: var(--smalltextbold-SP-font-weight);
  letter-spacing: var(--smalltextbold-SP-letter-spacing);
  line-height: var(--smalltextbold-SP-line-height);
  flex: 1;
  margin-top: -1px;
  position: relative;
}

.blog-screen .arrow-6 {
  width: 7.41px !important;
  height: 11.13px !important;
  margin-left: -.49px !important;
  margin-right: -1.15px !important;
  position: relative !important;
}

.blog-screen .line-6 {
  object-fit: cover;
  align-self: stretch;
  width: 100%;
  height: 1px;
  margin-left: -.5px;
  margin-right: -.5px;
  position: relative;
}

.blog-screen .frame-104, .blog-screen .frame-105, .blog-screen .frame-106, .blog-screen .frame-107 {
  flex: none;
  justify-content: space-between;
  align-self: stretch;
  align-items: center;
  width: 100%;
  padding: 0 10px;
  display: flex;
  position: relative;
}

.blog-screen .CONTACT-6 {
  background-color: var(--backwhite);
  flex-direction: column;
  flex: none;
  justify-content: center;
  align-self: stretch;
  align-items: center;
  gap: 40px;
  width: 100%;
  padding: 80px 30px;
  display: flex;
  position: relative;
}

.blog-screen .CONTACT-7 {
  align-self: stretch;
  width: 100%;
  height: 160px;
  position: relative;
}

.blog-screen .frame-108 {
  background-color: var(--purple);
  border-radius: 6px;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  gap: 20px 74px;
  width: 280px;
  max-width: 700px;
  padding: 25px 60px;
  display: flex;
  position: relative;
  top: -5px;
  left: -10px;
}

.blog-screen .group-14 {
  width: 216px;
  height: 53px;
  margin-left: -26px;
  margin-right: -30px;
  position: relative;
}

.blog-screen .text-wrapper-93 {
  color: var(--white);
  letter-spacing: 2px;
  text-align: center;
  width: 212px;
  font-family: Zen Kaku Gothic New, Helvetica;
  font-size: 20px;
  font-weight: 500;
  line-height: normal;
  position: absolute;
  top: 0;
  left: 0;
}

.blog-screen .text-wrapper-94 {
  color: var(--white);
  letter-spacing: 0;
  text-align: center;
  width: 134px;
  font-family: Slackside One, Helvetica;
  font-size: 15px;
  font-weight: 400;
  line-height: normal;
  position: absolute;
  top: 31px;
  left: 39px;
}

.blog-screen .group-15 {
  width: 212px;
  height: 48px;
  margin-left: -25px;
  margin-right: -27px;
  position: relative;
}

.blog-screen .flexcontainer-12 {
  flex-direction: column;
  align-items: flex-start;
  gap: 2px;
  width: 210px;
  height: 48px;
  display: flex;
  position: relative;
}

.blog-screen .text-i-13 {
  color: var(--white);
  font-family: var(--text-SP-font-family);
  font-size: var(--text-SP-font-size);
  font-style: var(--text-SP-font-style);
  font-weight: var(--text-SP-font-weight);
  letter-spacing: var(--text-SP-letter-spacing);
  line-height: var(--text-SP-line-height);
  align-self: stretch;
  position: relative;
}

.blog-screen .text-wrapper-95 {
  font-family: var(--text-SP-font-family);
  font-size: var(--text-SP-font-size);
  font-style: var(--text-SP-font-style);
  font-weight: var(--text-SP-font-weight);
  letter-spacing: var(--text-SP-letter-spacing);
  line-height: var(--text-SP-line-height);
}

.blog-screen .footer-4 {
  background-color: #454545;
  flex-direction: column;
  flex: none;
  align-self: stretch;
  align-items: center;
  gap: 50px;
  width: 100%;
  padding: 50px 20px;
  display: flex;
  position: relative;
}

.blog-screen .frame-109 {
  flex-direction: column;
  flex: none;
  align-self: stretch;
  align-items: flex-start;
  gap: 54px;
  width: 100%;
  display: flex;
  position: relative;
}

.blog-screen .frame-110 {
  flex-direction: column;
  flex: none;
  align-self: stretch;
  align-items: flex-start;
  gap: 24px;
  width: 100%;
  display: flex;
  position: relative;
}

.blog-screen .logo-white-12 {
  width: 121px !important;
  height: 40px !important;
  position: relative !important;
}

.blog-screen .flexcontainer-13 {
  flex-direction: column;
  align-items: flex-start;
  gap: 3px;
  display: flex;
}

.blog-screen .text-i-14 {
  color: #fff;
  letter-spacing: .26px;
  align-self: stretch;
  font-family: Noto Sans JP, Helvetica;
  font-size: 13px;
  font-weight: 400;
  line-height: 18px;
  position: relative;
}

.blog-screen .text-wrapper-96 {
  letter-spacing: .03px;
}

.blog-screen .frame-111 {
  flex: none;
  align-self: stretch;
  align-items: flex-start;
  gap: 40px;
  width: 100%;
  display: flex;
  position: relative;
}

.blog-screen .frame-112 {
  flex-direction: column;
  flex: none;
  align-items: flex-start;
  gap: 30px;
  display: inline-flex;
  position: relative;
}

.blog-screen .frame-113 {
  flex-direction: column;
  flex: none;
  align-items: flex-start;
  gap: 4px;
  display: inline-flex;
  position: relative;
}

.blog-screen .text-wrapper-97 {
  color: #fff;
  font-family: var(--text-bold-mini-font-family);
  font-size: var(--text-bold-mini-font-size);
  font-style: var(--text-bold-mini-font-style);
  font-weight: var(--text-bold-mini-font-weight);
  letter-spacing: var(--text-bold-mini-letter-spacing);
  line-height: var(--text-bold-mini-line-height);
  white-space: nowrap;
  width: fit-content;
  margin-top: -1px;
  display: block;
  position: relative;
}

.blog-screen .text-wrapper-98 {
  color: #fff;
  font-family: var(--text-bold-mini-font-family);
  font-size: var(--text-bold-mini-font-size);
  font-style: var(--text-bold-mini-font-style);
  font-weight: var(--text-bold-mini-font-weight);
  letter-spacing: var(--text-bold-mini-letter-spacing);
  line-height: var(--text-bold-mini-line-height);
  white-space: nowrap;
  width: fit-content;
  position: relative;
}

.blog-screen .text-wrapper-99 {
  color: #fff;
  font-family: var(--text-bold-mini-font-family);
  font-size: var(--text-bold-mini-font-size);
  font-style: var(--text-bold-mini-font-style);
  font-weight: var(--text-bold-mini-font-weight);
  letter-spacing: var(--text-bold-mini-letter-spacing);
  line-height: var(--text-bold-mini-line-height);
  white-space: nowrap;
  width: fit-content;
  margin-top: -1px;
  position: relative;
}

.blog-screen .frame-114 {
  flex-direction: column;
  flex: 1;
  align-self: stretch;
  align-items: flex-start;
  gap: 30px;
  display: flex;
  position: relative;
}

.blog-screen .text-wrapper-100 {
  color: #fff;
  font-family: var(--text-bold-mini-font-family);
  font-size: var(--text-bold-mini-font-size);
  font-style: var(--text-bold-mini-font-style);
  font-weight: var(--text-bold-mini-font-weight);
  letter-spacing: var(--text-bold-mini-letter-spacing);
  line-height: var(--text-bold-mini-line-height);
  white-space: nowrap;
  width: fit-content;
  display: block;
  position: relative;
}

.blog-screen .text-wrapper-101 {
  color: #fff;
  letter-spacing: 1.31px;
  width: fit-content;
  font-family: Manrope, Helvetica;
  font-size: 13.1px;
  font-weight: 400;
  line-height: normal;
  position: relative;
}

.blog-screen .class-44 {
  z-index: 0 !important;
  width: 60px !important;
  height: 59px !important;
  position: relative !important;
}

.blog-screen .header-7 {
  z-index: 1 !important;
  flex: none !important;
  justify-content: space-between !important;
  align-self: stretch !important;
  width: 100% !important;
  display: flex !important;
}

.blog-screen .class-45 {
  z-index: 1 !important;
}

.blog-screen .blog-2 {
  z-index: 0;
  background-color: #fff;
  flex-direction: column;
  flex: none;
  align-self: stretch;
  align-items: flex-start;
  width: 100%;
  display: flex;
  position: relative;
}

.blog-screen .heading-19 {
  color: #fff !important;
  left: 28px !important;
}

.blog-screen .class-46 {
  background-image: url("midashi-1.05af3916.png") !important;
  flex: none !important;
  align-self: stretch !important;
  width: 100% !important;
}

.blog-screen .class-47 {
  flex: none !important;
  align-self: stretch !important;
  width: 100% !important;
}

.blog-screen .breadcrumbs-3 {
  flex: none;
  justify-content: center;
  align-self: stretch;
  align-items: flex-start;
  gap: 30px;
  width: 100%;
  display: flex;
  position: relative;
}

.blog-screen .frame-115 {
  flex: 1;
  align-items: center;
  gap: 30px;
  display: flex;
  position: relative;
}

.blog-screen .text-wrapper-102 {
  color: var(--gray);
  font-family: var(--tag-font-family);
  font-size: var(--tag-font-size);
  font-style: var(--tag-font-style);
  font-weight: var(--tag-font-weight);
  letter-spacing: var(--tag-letter-spacing);
  line-height: var(--tag-line-height);
  white-space: nowrap;
  width: fit-content;
  margin-top: -1px;
  display: block;
  position: relative;
}

.blog-screen .component-10930-instance {
  left: unset !important;
  top: unset !important;
  width: 4.57px !important;
  height: 8.14px !important;
  position: relative !important;
}

.blog-screen .div-12 {
  color: var(--gray);
  font-family: var(--tag-font-family);
  font-size: var(--tag-font-size);
  font-style: var(--tag-font-style);
  font-weight: var(--tag-font-weight);
  letter-spacing: var(--tag-letter-spacing);
  line-height: var(--tag-line-height);
  white-space: nowrap;
  width: fit-content;
  margin-top: -1px;
  position: relative;
}

.blog-screen .view-2 {
  background-color: var(--palepurple);
  flex-direction: column;
  flex: none;
  align-self: stretch;
  align-items: center;
  gap: 40px;
  width: 100%;
  padding: 80px 15px;
  display: flex;
  position: relative;
}

.blog-screen .frame-116 {
  flex: none;
  display: flex;
  position: relative;
}

.blog-screen .frame-117 {
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  display: flex;
  position: relative;
}

.blog-screen .frame-118 {
  background-color: var(--back-white);
  border-radius: 14px;
  flex-direction: column;
  flex: none;
  align-items: flex-start;
  display: flex;
  position: relative;
}

.blog-screen .frame-119 {
  flex: none;
  align-self: stretch;
  align-items: flex-start;
  gap: 30px;
  width: 100%;
  max-width: 800px;
  display: flex;
  position: relative;
}

.blog-screen .text-wrapper-103 {
  color: var(--black);
  letter-spacing: 1.31px;
  width: 91px;
  font-family: Manrope-Medium, Helvetica;
  font-size: 13.1px;
  font-weight: 500;
  line-height: normal;
  position: absolute;
  top: 0;
  left: 0;
}

.blog-screen .frame-120 {
  flex-wrap: wrap;
  flex: 1;
  align-items: flex-end;
  gap: 0 28px;
  display: flex;
  position: relative;
}

.blog-screen .text-wrapper-104 {
  color: var(--purple);
  font-family: var(--tag-font-family);
  font-size: var(--tag-font-size);
  font-style: var(--tag-font-style);
  font-weight: var(--tag-font-weight);
  letter-spacing: var(--tag-letter-spacing);
  line-height: var(--tag-line-height);
  white-space: nowrap;
  width: fit-content;
  position: relative;
}

.blog-screen .text-wrapper-105 {
  color: var(--purple);
  font-family: var(--tag-font-family);
  font-size: var(--tag-font-size);
  font-style: var(--tag-font-style);
  font-weight: var(--tag-font-weight);
  letter-spacing: var(--tag-letter-spacing);
  line-height: var(--tag-line-height);
  white-space: nowrap;
  width: 43px;
  height: 17px;
  margin-top: -1px;
  position: relative;
}

.blog-screen .frame-121 {
  flex: none;
  align-self: stretch;
  align-items: flex-start;
  gap: 30px;
  width: 100%;
  max-width: 800px;
  padding: 0 5px;
  display: flex;
  position: relative;
}

.blog-screen .text-wrapper-106 {
  color: var(--black);
  font-family: var(--date-font-family);
  font-size: var(--date-font-size);
  font-style: var(--date-font-style);
  font-weight: var(--date-font-weight);
  letter-spacing: var(--date-letter-spacing);
  line-height: var(--date-line-height);
  width: 91px;
  position: absolute;
  top: 0;
  left: 0;
}

.blog-screen .div-13 {
  color: #454545;
  letter-spacing: 1px;
  align-self: stretch;
  font-family: Noto Sans JP, Helvetica;
  font-size: 20px;
  font-weight: 700;
  line-height: 20px;
  position: relative;
}

.blog-screen .text-wrapper-107 {
  letter-spacing: .2px;
  line-height: 37px;
}

.blog-screen .text-wrapper-108 {
  letter-spacing: .2px;
  line-height: 36px;
}

.blog-screen .frame-122 {
  flex-direction: column;
  flex: none;
  align-self: stretch;
  align-items: flex-start;
  gap: 16px;
  width: 100%;
  display: flex;
  position: relative;
}

.blog-screen .grayline-2 {
  top: unset !important;
  background-image: url("line-13-1-2.5064d766.svg") !important;
  align-self: stretch !important;
  width: 100% !important;
  margin-top: -1px !important;
}

.blog-screen .frame-123 {
  flex-wrap: wrap;
  flex: none;
  align-self: stretch;
  align-items: flex-start;
  gap: 10px;
  width: 100%;
  display: flex;
  position: relative;
}

.blog-screen .noimage-9-wrapper {
  background-color: var(--gray);
  border-radius: 6px;
  flex: 1;
  height: 236.18px;
  position: relative;
}

.blog-screen .noimage-23 {
  width: 211px !important;
  height: 26px !important;
  position: absolute !important;
  top: 107px !important;
  left: 75px !important;
}

.blog-screen .group-16 {
  background-image: url("rectangle-23-2.bacee96b.svg");
  background-size: 100% 100%;
  flex: 1;
  height: 236.18px;
  position: relative;
}

.blog-screen .noimage-9 {
  width: 211px !important;
  height: 26px !important;
  position: absolute !important;
  top: 107px !important;
  left: 80px !important;
}

.blog-screen .text-wrapper-109 {
  color: #454545;
  font-family: var(--text-font-family);
  font-size: var(--text-font-size);
  font-style: var(--text-font-style);
  font-weight: var(--text-font-weight);
  letter-spacing: var(--text-letter-spacing);
  line-height: var(--text-line-height);
  align-self: stretch;
  position: relative;
}

.blog-screen .grayline-3 {
  top: unset !important;
  background-image: url("line-13-5-2.3fbc5b18.svg") !important;
  align-self: stretch !important;
  width: 100% !important;
}

.blog-screen .frame-124 {
  flex: none;
  align-self: stretch;
  align-items: flex-start;
  gap: 31px;
  width: 100%;
  display: flex;
  position: relative;
}

.blog-screen .noimage-instance-wrapper {
  background-color: var(--gray);
  border-radius: 6px;
  flex-direction: column;
  flex: 1;
  justify-content: center;
  align-self: stretch;
  align-items: center;
  gap: 10px;
  padding: 104px 70px;
  display: flex;
  position: relative;
  overflow: hidden;
}

.blog-screen .noimage-instance {
  left: unset !important;
  top: unset !important;
  width: 196.71px !important;
  height: 25.92px !important;
  margin-left: -7.6px !important;
  margin-right: -7.6px !important;
  position: relative !important;
}

.blog-screen .frame-125 {
  background-color: var(--gray);
  border-radius: 6px;
  flex-direction: column;
  flex: 1;
  justify-content: center;
  align-items: center;
  gap: 10px;
  padding: 104px 70px;
  display: flex;
  position: relative;
  overflow: hidden;
}

.blog-screen .frame-126 {
  justify-content: space-between;
  align-self: stretch;
  align-items: flex-start;
  width: 100%;
  display: flex;
  position: relative;
}

.blog-screen .group-17 {
  position: relative;
}

.blog-screen .text-wrapper-110 {
  color: var(--darkgray);
  letter-spacing: .75px;
  width: 230px;
  font-family: Noto Sans JP, Helvetica;
  font-size: 15px;
  font-weight: 400;
  line-height: 25px;
  position: absolute;
  top: 0;
  left: 27px;
}

.blog-screen .polygon-2 {
  width: 13px !important;
  height: 17px !important;
  top: 5px !important;
  left: -1px !important;
}

.blog-screen .class-48 {
  flex: 1 !important;
  min-width: 140px !important;
  max-width: 200px !important;
  margin-left: -2px !important;
  padding: 17px 30px !important;
  display: flex !important;
}

.blog-screen .class-49 {
  width: 200px !important;
  height: 60px !important;
  padding: 17px 30px !important;
  display: flex !important;
}

.blog-screen .class-50 {
  font-weight: 700 !important;
}

.blog-screen .class-51 {
  font-family: var(--button-SP-font-family) !important;
  font-size: var(--button-SP-font-size) !important;
  font-style: var(--button-SP-font-style) !important;
  font-weight: var(--button-SP-font-weight) !important;
  letter-spacing: var(--button-SP-letter-spacing) !important;
  line-height: var(--button-SP-line-height) !important;
  margin-top: unset !important;
  white-space: unset !important;
}

.blog-screen .group-18 {
  margin-right: -2px;
  position: relative;
}

.blog-screen .text-wrapper-111 {
  color: var(--darkgray);
  letter-spacing: .75px;
  text-align: right;
  width: 223px;
  font-family: Noto Sans JP, Helvetica;
  font-size: 15px;
  font-weight: 400;
  line-height: 25px;
  position: absolute;
  top: 0;
  left: 0;
}

.blog-screen .polygon-instance {
  width: 13px !important;
  height: 17px !important;
  top: 5px !important;
  left: 245px !important;
}

.blog-screen .view-3 {
  flex-direction: column;
  align-items: flex-start;
  display: flex;
  position: relative;
}

.blog-screen .frame-127 {
  background-color: #6079c1;
  border-radius: 10px 10px 0 0;
  flex-direction: column;
  flex: none;
  align-self: stretch;
  align-items: center;
  gap: 30px;
  width: 100%;
  display: flex;
  position: relative;
}

.blog-screen .text-wrapper-112 {
  color: #fff;
  letter-spacing: 0;
  text-align: center;
  width: fit-content;
  margin-top: -1px;
  font-family: Noto Sans JP, Helvetica;
  font-size: 16px;
  font-weight: 700;
  line-height: normal;
  position: relative;
}

.blog-screen .frame-128 {
  background-color: #fff;
  border-radius: 0 0 10px 10px;
  flex-direction: column;
  align-self: stretch;
  align-items: center;
  width: 100%;
  display: flex;
  position: relative;
}

.blog-screen .frame-129 {
  flex: none;
  justify-content: space-between;
  align-self: stretch;
  align-items: center;
  width: 100%;
  padding: 0 10px;
  display: flex;
  position: relative;
}

.blog-screen .text-wrapper-113 {
  color: var(--darkgray);
  letter-spacing: .75px;
  flex: 1;
  margin-top: -1px;
  font-family: Noto Sans JP, Helvetica;
  font-size: 15px;
  line-height: 26px;
  position: relative;
}

.blog-screen .polygon-2-instance {
  left: unset !important;
  top: unset !important;
  width: 6.25px !important;
  height: 16.55px !important;
  margin-left: -.42px !important;
  margin-right: -.6px !important;
  position: relative !important;
}

.blog-screen .polygon-2-7 {
  width: 6.25px !important;
  height: 16.55px !important;
  margin-left: -.42px !important;
  margin-right: -.6px !important;
  position: relative !important;
}

.blog-screen .line-7 {
  align-self: stretch;
  width: 100%;
  margin-left: -1px;
  margin-right: -1px;
  position: relative;
}

.blog-screen .frame-130 {
  flex: none;
  justify-content: space-between;
  align-self: stretch;
  align-items: center;
  width: 100%;
  padding: 0 10px;
  display: flex;
  position: relative;
}

.blog-screen .text-wrapper-114 {
  color: var(--darkgray);
  letter-spacing: .75px;
  flex: 1;
  margin-top: -1px;
  font-family: Noto Sans JP, Helvetica;
  font-size: 15px;
  line-height: 26px;
  position: relative;
}

.blog-screen .line-9 {
  align-self: stretch;
  width: 100%;
  margin-left: -1px;
  margin-right: -1px;
  position: relative;
}

.blog-screen .frame-131 {
  flex: none;
  justify-content: space-between;
  align-self: stretch;
  align-items: center;
  width: 100%;
  padding: 0 10px;
  display: flex;
  position: relative;
}

.blog-screen .text-wrapper-115 {
  color: var(--darkgray);
  letter-spacing: .75px;
  flex: 1;
  margin-top: -1px;
  font-family: Noto Sans JP, Helvetica;
  font-size: 15px;
  line-height: 26px;
  position: relative;
}

.blog-screen .line-10 {
  align-self: stretch;
  width: 100%;
  margin-left: -1px;
  margin-right: -1px;
  position: relative;
}

.blog-screen .frame-132 {
  flex: none;
  justify-content: space-between;
  align-self: stretch;
  align-items: center;
  width: 100%;
  padding: 0 10px;
  display: flex;
  position: relative;
}

.blog-screen .text-wrapper-116 {
  color: var(--darkgray);
  letter-spacing: .75px;
  flex: 1;
  margin-top: -1px;
  font-family: Noto Sans JP, Helvetica;
  font-size: 15px;
  line-height: 26px;
  position: relative;
}

.blog-screen .line-11 {
  align-self: stretch;
  width: 100%;
  margin-left: -1px;
  margin-right: -1px;
  position: relative;
}

.blog-screen .frame-133 {
  flex: none;
  justify-content: space-between;
  align-self: stretch;
  align-items: center;
  width: 100%;
  padding: 0 10px;
  display: flex;
  position: relative;
}

.blog-screen .text-wrapper-117 {
  color: var(--darkgray);
  letter-spacing: .75px;
  flex: 1;
  margin-top: -1px;
  font-family: Noto Sans JP, Helvetica;
  font-size: 15px;
  line-height: 26px;
  position: relative;
}

.blog-screen .CONTACT-8 {
  background-color: var(--backwhite);
  flex-direction: column;
  flex: none;
  align-self: stretch;
  align-items: center;
  gap: 40px;
  width: 100%;
  display: flex;
  position: relative;
}

.blog-screen .CONTACT-9 {
  height: 160px;
  position: relative;
}

.blog-screen .frame-134 {
  background-color: var(--purple);
  border-radius: 6px;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  gap: 20px 74px;
  width: 700px;
  max-width: 700px;
  padding: 50px 60px;
  display: flex;
  position: relative;
}

.blog-screen .group-19 {
  width: 216px;
  height: 60px;
  position: relative;
}

.blog-screen .overlap-group-6 {
  width: 212px;
  height: 60px;
  position: relative;
}

.blog-screen .text-wrapper-118 {
  color: var(--white);
  letter-spacing: 2.4px;
  text-align: center;
  width: 212px;
  font-family: Zen Kaku Gothic New, Helvetica;
  font-size: 24px;
  font-weight: 500;
  line-height: normal;
  position: absolute;
  top: 0;
  left: 0;
}

.blog-screen .text-wrapper-119 {
  color: var(--white);
  letter-spacing: 0;
  text-align: center;
  width: 134px;
  font-family: Slackside One, Helvetica;
  font-size: 20px;
  font-weight: 400;
  line-height: normal;
  position: absolute;
  top: 31px;
  left: 39px;
}

.blog-screen .group-20 {
  width: 254px;
  height: 55px;
  position: relative;
}

.blog-screen .flexcontainer-14 {
  flex-direction: column;
  align-items: flex-start;
  gap: 5px;
  width: 252px;
  height: 55px;
  display: flex;
  position: relative;
}

.blog-screen .text-i-15 {
  color: var(--white);
  letter-spacing: .75px;
  align-self: stretch;
  font-family: Noto Sans JP, Helvetica;
  font-size: 15px;
  font-weight: 400;
  line-height: 25px;
  position: relative;
}

.blog-screen .text-wrapper-120 {
  letter-spacing: .11px;
}

.blog-screen .footer-5 {
  background-color: #454545;
  flex-direction: column;
  flex: none;
  align-self: stretch;
  align-items: center;
  gap: 50px;
  width: 100%;
  display: flex;
  position: relative;
}

.blog-screen .frame-135 {
  flex-wrap: wrap;
  flex: none;
  justify-content: space-between;
  align-items: flex-start;
  gap: 50px 150px;
  display: flex;
  position: relative;
}

.blog-screen .frame-136 {
  flex-direction: column;
  align-items: flex-start;
  position: relative;
}

.blog-screen .logo-white-2 {
  left: unset !important;
  top: unset !important;
  width: 163.65px !important;
  height: 54.26px !important;
  position: relative !important;
}

.blog-screen .logo-white-7 {
  width: 163.65px !important;
  height: 54.26px !important;
  position: relative !important;
}

.blog-screen .flexcontainer-15 {
  flex-direction: column;
  align-items: flex-start;
  gap: 5px;
  display: flex;
}

.blog-screen .text-i-16 {
  color: #fff;
  font-family: var(--text-font-family);
  font-size: var(--text-font-size);
  font-style: var(--text-font-style);
  font-weight: var(--text-font-weight);
  letter-spacing: var(--text-letter-spacing);
  line-height: var(--text-line-height);
  align-self: stretch;
  position: relative;
}

.blog-screen .text-wrapper-121 {
  font-family: var(--text-font-family);
  font-size: var(--text-font-size);
  font-style: var(--text-font-style);
  font-weight: var(--text-font-weight);
  letter-spacing: var(--text-letter-spacing);
  line-height: var(--text-line-height);
}

.blog-screen .frame-137 {
  flex: none;
  align-items: flex-start;
  gap: 60px;
  display: inline-flex;
  position: relative;
}

.blog-screen .frame-138 {
  flex-direction: column;
  flex: none;
  align-items: flex-start;
  display: inline-flex;
  position: relative;
}

.blog-screen .frame-139 {
  flex-direction: column;
  flex: none;
  align-items: flex-start;
  gap: 6px;
  display: inline-flex;
  position: relative;
}

.blog-screen .text-wrapper-122 {
  color: #fff;
  white-space: nowrap;
  width: fit-content;
  margin-top: -1px;
  position: relative;
}

.blog-screen .text-wrapper-123, .blog-screen .text-wrapper-124, .blog-screen .text-wrapper-125 {
  color: #fff;
  white-space: nowrap;
  width: fit-content;
  position: relative;
}

.blog-screen .text-wrapper-126 {
  color: #fff;
  white-space: nowrap;
  width: fit-content;
  margin-top: -1px;
  position: relative;
}

.blog-screen .text-wrapper-127, .blog-screen .text-wrapper-128 {
  color: #fff;
  white-space: nowrap;
  width: fit-content;
  position: relative;
}

.blog-screen .text-wrapper-129 {
  color: #fff;
  white-space: nowrap;
  width: fit-content;
  margin-top: -1px;
  position: relative;
}

.blog-screen .text-wrapper-130, .blog-screen .text-wrapper-131 {
  color: #fff;
  white-space: nowrap;
  width: fit-content;
  position: relative;
}

.blog-screen .frame-140 {
  flex-direction: column;
  flex: none;
  align-items: flex-start;
  gap: 30px;
  display: inline-flex;
  position: relative;
}

.blog-screen .text-wrapper-132 {
  color: #fff;
  white-space: nowrap;
  width: fit-content;
  margin-top: -1px;
  position: relative;
}

.blog-screen .text-wrapper-133 {
  color: #fff;
  white-space: nowrap;
  width: fit-content;
  position: relative;
}

.blog-screen .text-wrapper-134 {
  color: #fff;
  white-space: nowrap;
  width: fit-content;
  display: block;
  position: relative;
}

.blog-screen .text-wrapper-135 {
  color: #fff;
  white-space: nowrap;
  width: fit-content;
  position: relative;
}

.blog-screen .text-wrapper-136 {
  color: #fff;
  white-space: nowrap;
  width: fit-content;
  display: block;
  position: relative;
}

.menuopen-SP {
  align-items: center;
  gap: 10px;
  width: 100%;
  min-width: 320px;
  height: 568px;
  display: flex;
  position: relative;
  overflow: hidden;
}

.menuopen-SP .menu-SP-wrapper {
  background-color: #fff;
  align-items: center;
  gap: 10px;
  width: 320px;
  height: 568px;
  display: flex;
}

.menuopen-SP .menu-SP {
  background-color: #6079c1;
  flex-direction: column;
  flex: 1;
  align-self: stretch;
  align-items: flex-start;
  gap: 10px;
  display: flex;
  position: relative;
}

.menuopen-SP .frame-141 {
  flex-direction: column;
  flex: 1;
  align-self: stretch;
  align-items: flex-start;
  gap: 20px;
  width: 100%;
  display: flex;
  position: relative;
}

.menuopen-SP .frame-142 {
  flex: none;
  align-self: stretch;
  align-items: flex-start;
  width: 100%;
  display: flex;
  position: relative;
}

.menuopen-SP .rectangle-3 {
  flex: 1;
  height: 36px;
  position: relative;
}

.menuopen-SP .menu-6 {
  width: 36px !important;
  height: 35px !important;
  position: relative !important;
}

.menuopen-SP .menu-4 {
  background-color: var(--purple);
  flex-direction: column;
  flex: none;
  align-self: stretch;
  align-items: flex-start;
  gap: 10px;
  width: 100%;
  padding: 0 25px;
  display: flex;
  position: relative;
}

.menuopen-SP .frame-143 {
  flex-direction: column;
  flex: none;
  align-items: flex-start;
  gap: 20px;
  margin-right: -12px;
  display: inline-flex;
  position: relative;
}

.menuopen-SP .text-wrapper-137 {
  color: #fff;
  letter-spacing: .75px;
  width: fit-content;
  margin-top: -1px;
  font-family: Noto Sans JP, Helvetica;
  font-size: 15px;
  font-weight: 700;
  line-height: normal;
  display: block;
  position: relative;
}

.menuopen-SP .frame-144 {
  flex: none;
  align-items: flex-start;
  gap: 25px;
  display: inline-flex;
  position: relative;
}

.menuopen-SP .frame-145 {
  flex-direction: column;
  flex: none;
  align-items: flex-start;
  gap: 50px;
  display: inline-flex;
  position: relative;
}

.menuopen-SP .frame-146 {
  flex-direction: column;
  flex: none;
  align-items: flex-start;
  gap: 30px;
  display: inline-flex;
  position: relative;
}

.menuopen-SP .frame-147 {
  flex-direction: column;
  flex: none;
  align-items: flex-start;
  gap: 10px;
  display: inline-flex;
  position: relative;
}

.menuopen-SP .frame-148 {
  flex-direction: column;
  flex: none;
  align-items: flex-start;
  display: inline-flex;
  position: relative;
}

.menuopen-SP .text-wrapper-138 {
  color: #fff;
  letter-spacing: .6px;
  white-space: nowrap;
  width: fit-content;
  margin-top: -1px;
  font-family: Noto Sans JP, Helvetica;
  font-size: 12px;
  font-weight: 700;
  line-height: 24px;
  position: relative;
}

.menuopen-SP .text-wrapper-139 {
  color: #fff;
  letter-spacing: .6px;
  white-space: nowrap;
  width: fit-content;
  font-family: Noto Sans JP, Helvetica;
  font-size: 12px;
  font-weight: 700;
  line-height: 24px;
  position: relative;
}

.menuopen-SP .text-wrapper-140 {
  color: #fff;
  letter-spacing: .75px;
  width: fit-content;
  margin-top: -1px;
  font-family: Noto Sans JP, Helvetica;
  font-size: 15px;
  font-weight: 700;
  line-height: normal;
  position: relative;
}

.menuopen-SP .frame-149 {
  flex-direction: column;
  flex: none;
  align-items: flex-start;
  gap: 30px;
  height: 313px;
  display: inline-flex;
  position: relative;
}

.menuopen-SP .frame-150 {
  flex-direction: column;
  flex: 1;
  align-items: flex-start;
  gap: 20px;
  display: inline-flex;
  position: relative;
}

.menuopen-SP .text-wrapper-141 {
  color: #fff;
  letter-spacing: .65px;
  width: fit-content;
  margin-top: -1px;
  font-family: Noto Sans JP, Helvetica;
  font-size: 13px;
  font-weight: 700;
  line-height: normal;
  display: block;
  position: relative;
}

.menuopen-SP .text-wrapper-142 {
  color: #fff;
  letter-spacing: .65px;
  width: fit-content;
  font-family: Noto Sans JP, Helvetica;
  font-size: 13px;
  font-weight: 700;
  line-height: normal;
  display: block;
  position: relative;
}

.menuopen-SP .text-wrapper-143 {
  color: #fff;
  letter-spacing: .65px;
  width: fit-content;
  font-family: Noto Sans JP, Helvetica;
  font-size: 13px;
  font-weight: 700;
  line-height: normal;
  position: relative;
}

.menuopen-SP .button-wrapper {
  flex-direction: column;
  flex: none;
  align-self: stretch;
  align-items: flex-start;
  gap: 10px;
  width: 100%;
  padding: 0 20px;
  display: flex;
  position: relative;
}

.menuopen-SP .button-8 {
  align-self: stretch !important;
  width: 100% !important;
}

.menuopen-PC {
  justify-content: flex-end;
  align-items: flex-start;
  gap: 10px;
  width: 100%;
  min-width: 1280px;
  height: 832px;
  display: flex;
  position: relative;
  overflow-x: hidden;
}

.menuopen-PC .menu-wrapper {
  justify-content: flex-end;
  align-items: flex-start;
  gap: 10px;
  width: 1280px;
  height: 832px;
  display: flex;
}

.menuopen-PC .menu-5 {
  background-color: var(--purple);
  flex-direction: column;
  justify-content: center;
  align-self: stretch;
  align-items: flex-end;
  gap: 10px;
  width: 512px;
  padding: 150px 60px;
  display: flex;
  position: relative;
}

.menuopen-PC .frame-151 {
  flex-direction: column;
  flex: none;
  align-items: flex-start;
  gap: 50px;
  margin-left: -13px;
  display: inline-flex;
  position: relative;
}

.menuopen-PC .text-wrapper-144 {
  color: #fff;
  letter-spacing: 1px;
  width: fit-content;
  margin-top: -1px;
  font-family: Noto Sans JP, Helvetica;
  font-size: 20px;
  font-weight: 700;
  line-height: normal;
  position: relative;
}

.menuopen-PC .frame-152 {
  flex: none;
  align-items: flex-start;
  gap: 80px;
  display: inline-flex;
  position: relative;
}

.menuopen-PC .frame-153 {
  flex-direction: column;
  flex: none;
  align-items: flex-start;
  gap: 50px;
  display: inline-flex;
  position: relative;
}

.menuopen-PC .frame-154 {
  flex-direction: column;
  flex: none;
  align-items: flex-start;
  gap: 10px;
  display: inline-flex;
  position: relative;
}

.menuopen-PC .frame-155 {
  flex-direction: column;
  flex: none;
  align-items: flex-start;
  display: inline-flex;
  position: relative;
}

.menuopen-PC .text-wrapper-145 {
  color: #fff;
  letter-spacing: .8px;
  white-space: nowrap;
  width: fit-content;
  margin-top: -1px;
  font-family: Noto Sans JP, Helvetica;
  font-size: 16px;
  font-weight: 700;
  line-height: 30px;
  position: relative;
}

.menuopen-PC .text-wrapper-146 {
  color: #fff;
  letter-spacing: .8px;
  white-space: nowrap;
  width: fit-content;
  font-family: Noto Sans JP, Helvetica;
  font-size: 16px;
  font-weight: 700;
  line-height: 30px;
  position: relative;
}

.menuopen-PC .frame-156 {
  flex-direction: column;
  flex: none;
  align-items: flex-start;
  gap: 20px;
  display: inline-flex;
  position: relative;
}

.menuopen-PC .text-wrapper-147 {
  color: #fff;
  letter-spacing: .8px;
  width: fit-content;
  margin-top: -1px;
  font-family: Noto Sans JP, Helvetica;
  font-size: 16px;
  font-weight: 700;
  line-height: normal;
  position: relative;
}

.menuopen-PC .text-wrapper-148 {
  color: #fff;
  letter-spacing: .8px;
  width: fit-content;
  font-family: Noto Sans JP, Helvetica;
  font-size: 16px;
  font-weight: 700;
  line-height: normal;
  position: relative;
}

.menuopen-PC .text-wrapper-149 {
  color: #fff;
  letter-spacing: .8px;
  width: fit-content;
  font-family: Noto Sans JP, Helvetica;
  font-size: 16px;
  font-weight: 700;
  line-height: normal;
  display: block;
  position: relative;
}

.menuopen-PC .button-9 {
  flex: none !important;
  width: 200px !important;
}

.menuopen-PC .button-10 {
  width: 7.41px !important;
  height: 11.13px !important;
  margin-left: -1.65px !important;
  margin-right: -1.15px !important;
  position: relative !important;
}

.menuopen-PC .menu-9 {
  width: 71px !important;
  height: 70px !important;
  position: absolute !important;
  top: 0 !important;
  left: 441px !important;
}

.contact-screen {
  background-color: var(--backwhite);
  flex-direction: column;
  align-items: center;
  display: flex;
  position: relative;
}

.contact-screen .header-8 {
  height: unset !important;
  z-index: 1 !important;
  flex: none !important;
  align-self: stretch !important;
  width: 100% !important;
}

.contact-screen .header-9 {
  margin-bottom: unset !important;
}

.contact-screen .menu-24 {
  width: 36px !important;
  height: 35px !important;
  position: relative !important;
}

.contact-screen .contacts-2 {
  z-index: 0;
  background-color: #fff;
  flex-direction: column;
  flex: none;
  align-self: stretch;
  align-items: flex-start;
  width: 100%;
  display: flex;
  position: relative;
}

.contact-screen .midashi-2 {
  flex-direction: column;
  flex: none;
  align-self: stretch;
  align-items: flex-start;
  width: 100%;
  display: flex;
  position: relative;
}

.contact-screen .heading-20 {
  flex: none !important;
  align-self: stretch !important;
  width: 100% !important;
  padding: 5px 0 !important;
  display: flex !important;
}

.contact-screen .heading-21 {
  color: #fff !important;
  left: -10px !important;
}

.contact-screen .heading-22 {
  color: #fff !important;
  left: 30px !important;
}

.contact-screen .breadcrumbs-4 {
  flex: none;
  justify-content: center;
  align-self: stretch;
  align-items: flex-start;
  gap: 30px;
  width: 100%;
  padding: 10px 15px 20px;
  display: flex;
  position: relative;
}

.contact-screen .frame-157 {
  flex: 1;
  align-items: center;
  gap: 17px;
  padding: 0 10px;
  display: flex;
  position: relative;
}

.contact-screen .text-wrapper-150 {
  color: var(--gray);
  font-family: var(--smalltext-SP-font-family);
  font-size: var(--smalltext-SP-font-size);
  font-style: var(--smalltext-SP-font-style);
  font-weight: var(--smalltext-SP-font-weight);
  letter-spacing: var(--smalltext-SP-letter-spacing);
  line-height: var(--smalltext-SP-line-height);
  white-space: nowrap;
  width: fit-content;
  margin-top: -1px;
  display: block;
  position: relative;
}

.contact-screen .instance-22 {
  width: 4.57px !important;
  height: 8.14px !important;
  position: relative !important;
}

.contact-screen .text-wrapper-151 {
  color: var(--gray);
  font-family: var(--smalltext-SP-font-family);
  font-size: var(--smalltext-SP-font-size);
  font-style: var(--smalltext-SP-font-style);
  font-weight: var(--smalltext-SP-font-weight);
  letter-spacing: var(--smalltext-SP-letter-spacing);
  line-height: var(--smalltext-SP-line-height);
  white-space: nowrap;
  width: fit-content;
  margin-top: -1px;
  position: relative;
}

.contact-screen .contactform-2 {
  background-color: var(--palepurple);
  flex-direction: column;
  flex: none;
  align-self: stretch;
  align-items: center;
  gap: 40px;
  width: 100%;
  padding: 40px 15px;
  display: flex;
  position: relative;
}

.contact-screen .flexcontainer-16 {
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
  display: inline-flex;
}

.contact-screen .text-7 {
  color: #454545;
  font-family: var(--tag-font-family);
  font-size: var(--tag-font-size);
  font-style: var(--tag-font-style);
  font-weight: var(--tag-font-weight);
  letter-spacing: var(--tag-letter-spacing);
  line-height: var(--tag-line-height);
  text-align: center;
  align-self: stretch;
  position: relative;
}

.contact-screen .text-wrapper-152 {
  font-family: var(--tag-font-family);
  font-size: var(--tag-font-size);
  font-style: var(--tag-font-style);
  font-weight: var(--tag-font-weight);
  letter-spacing: var(--tag-letter-spacing);
  line-height: var(--tag-line-height);
}

.contact-screen .frame-158 {
  flex: none;
  justify-content: center;
  align-self: stretch;
  align-items: flex-start;
  gap: 16px;
  width: 100%;
  display: flex;
  position: relative;
}

.contact-screen .flexcontainer-17 {
  flex-direction: column;
  align-items: flex-start;
  gap: 2px;
  display: inline-flex;
}

.contact-screen .text-8 {
  color: #6079c1;
  font-family: var(--textbold-SP-font-family);
  font-size: var(--textbold-SP-font-size);
  font-style: var(--textbold-SP-font-style);
  font-weight: var(--textbold-SP-font-weight);
  letter-spacing: var(--textbold-SP-letter-spacing);
  line-height: var(--textbold-SP-line-height);
  text-align: right;
  align-self: stretch;
  position: relative;
}

.contact-screen .text-wrapper-153 {
  font-family: var(--textbold-SP-font-family);
  font-size: var(--textbold-SP-font-size);
  font-style: var(--textbold-SP-font-style);
  font-weight: var(--textbold-SP-font-weight);
  letter-spacing: var(--textbold-SP-letter-spacing);
  line-height: var(--textbold-SP-line-height);
}

.contact-screen .button-11 {
  margin-top: -11.5px !important;
  margin-bottom: -11.5px !important;
}

.contact-screen .button-12 {
  font-family: var(--textbold-SP-font-family) !important;
  font-size: var(--textbold-SP-font-size) !important;
  font-style: var(--textbold-SP-font-style) !important;
  font-weight: var(--textbold-SP-font-weight) !important;
  letter-spacing: var(--textbold-SP-letter-spacing) !important;
  line-height: var(--textbold-SP-line-height) !important;
  white-space: nowrap !important;
}

.contact-screen .frame-159 {
  background-color: var(--back-white);
  border-radius: 14px;
  flex-direction: column;
  flex: none;
  align-self: stretch;
  align-items: flex-start;
  gap: 30px;
  width: 100%;
  padding: 20px 20px 40px;
  display: flex;
  position: relative;
}

.contact-screen .frame-160 {
  flex-wrap: wrap;
  flex: none;
  align-self: stretch;
  align-items: flex-start;
  gap: 7px 30px;
  width: 100%;
  display: flex;
  position: relative;
}

.contact-screen .checklist-2 {
  flex: none !important;
  gap: 8px !important;
}

.contact-screen .checklist-3 {
  font-family: var(--text-bold-mini-font-family) !important;
  font-size: var(--text-bold-mini-font-size) !important;
  font-style: var(--text-bold-mini-font-style) !important;
  font-weight: var(--text-bold-mini-font-weight) !important;
  letter-spacing: var(--text-bold-mini-letter-spacing) !important;
  line-height: var(--text-bold-mini-line-height) !important;
}

.contact-screen .checklist-4 {
  flex: none !important;
}

.contact-screen .input-field-3 {
  flex: none !important;
  align-self: stretch !important;
  width: 100% !important;
  display: flex !important;
}

.contact-screen .input-field-4 {
  border-radius: 4px !important;
  width: 36px !important;
  position: relative !important;
}

.contact-screen .input-field-5 {
  height: unset !important;
  flex: none !important;
}

.contact-screen .input-field-6 {
  margin-bottom: unset !important;
  margin-top: -1px !important;
}

.contact-screen .input-field-7 {
  align-self: stretch !important;
  width: 100% !important;
  height: 82px !important;
  display: flex !important;
}

.contact-screen .input-field-8 {
  height: unset !important;
  flex: 1 !important;
  align-items: flex-start !important;
}

.contact-screen .input-field-9 {
  margin-bottom: unset !important;
  white-space: unset !important;
  width: unset !important;
  flex: 1 !important;
  margin-top: -1px !important;
}

.contact-screen .button-13 {
  align-self: stretch !important;
  width: 100% !important;
}

.contact-screen .button-14 {
  font-family: var(--button-SP-font-family) !important;
  font-size: var(--button-SP-font-size) !important;
  font-style: var(--button-SP-font-style) !important;
  font-weight: var(--button-SP-font-weight) !important;
  letter-spacing: var(--button-SP-letter-spacing) !important;
  line-height: var(--button-SP-line-height) !important;
}

.contact-screen .q-a-4 {
  background-color: var(--palepurple);
  flex-direction: column;
  flex: none;
  align-self: stretch;
  align-items: center;
  gap: 20px;
  width: 100%;
  padding: 20px 15px 40px;
  display: flex;
  position: relative;
}

.contact-screen .heading-23 {
  position: relative !important;
}

.contact-screen .heading-24 {
  left: 45px !important;
}

.contact-screen .heading-25 {
  left: -10px !important;
}

.contact-screen .frame-161 {
  flex-direction: column;
  flex: none;
  align-self: stretch;
  align-items: flex-start;
  gap: 12px;
  width: 100%;
  display: flex;
  position: relative;
}

.contact-screen .frame-162 {
  flex-direction: column;
  flex: none;
  align-self: stretch;
  align-items: center;
  gap: 15px;
  width: 100%;
  display: flex;
  position: relative;
}

.contact-screen .q-a-5 {
  flex: none !important;
  align-self: stretch !important;
  width: 100% !important;
  padding: 20px 10px !important;
}

.contact-screen .q-a-6 {
  gap: 12px !important;
}

.contact-screen .icon-instance-node {
  width: 24px !important;
  height: 24px !important;
  position: relative !important;
}

.contact-screen .q-a-7 {
  align-items: flex-start !important;
  justify-content: unset !important;
  margin-right: unset !important;
  flex: 1 !important;
  display: flex !important;
}

.contact-screen .q-a-8 {
  width: 12px !important;
  height: 12px !important;
  position: relative !important;
}

.contact-screen .q-a-9 {
  flex: 1 !important;
  display: flex !important;
}

.contact-screen .CONTACT-10 {
  background-color: var(--backwhite);
  flex-direction: column;
  flex: none;
  justify-content: center;
  align-items: center;
  gap: 40px;
  width: 600px;
  padding: 40px 50px;
  display: flex;
  position: relative;
}

.contact-screen .CONTACT-11 {
  align-self: stretch;
  width: 100%;
  height: 160px;
  position: relative;
}

.contact-screen .frame-163 {
  background-color: var(--purple);
  border-radius: 6px;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  gap: 20px;
  width: 500px;
  max-width: 700px;
  padding: 40px 20px;
  display: flex;
  position: relative;
  top: 14px;
}

.contact-screen .group-21 {
  width: 216px;
  height: 53px;
  position: relative;
}

.contact-screen .text-wrapper-154 {
  color: var(--white);
  letter-spacing: 2px;
  text-align: center;
  width: 212px;
  font-family: Zen Kaku Gothic New, Helvetica;
  font-size: 20px;
  font-weight: 500;
  line-height: normal;
  position: absolute;
  top: 0;
  left: 0;
}

.contact-screen .text-wrapper-155 {
  color: var(--white);
  letter-spacing: 0;
  text-align: center;
  width: 134px;
  font-family: Slackside One, Helvetica;
  font-size: 15px;
  font-weight: 400;
  line-height: normal;
  position: absolute;
  top: 31px;
  left: 39px;
}

.contact-screen .group-22 {
  width: 212px;
  height: 48px;
  position: relative;
}

.contact-screen .flexcontainer-18 {
  flex-direction: column;
  align-items: flex-start;
  gap: 2px;
  width: 210px;
  height: 48px;
  display: flex;
  position: relative;
}

.contact-screen .text-i-17 {
  color: var(--white);
  font-family: var(--text-SP-font-family);
  font-size: var(--text-SP-font-size);
  font-style: var(--text-SP-font-style);
  font-weight: var(--text-SP-font-weight);
  letter-spacing: var(--text-SP-letter-spacing);
  line-height: var(--text-SP-line-height);
  align-self: stretch;
  position: relative;
}

.contact-screen .text-wrapper-156 {
  font-family: var(--text-SP-font-family);
  font-size: var(--text-SP-font-size);
  font-style: var(--text-SP-font-style);
  font-weight: var(--text-SP-font-weight);
  letter-spacing: var(--text-SP-letter-spacing);
  line-height: var(--text-SP-line-height);
}

.contact-screen .footer-6 {
  background-color: #454545;
  flex-direction: column;
  flex: none;
  align-self: stretch;
  align-items: center;
  gap: 50px;
  width: 100%;
  padding: 50px 20px;
  display: flex;
  position: relative;
}

.contact-screen .frame-164 {
  flex-direction: column;
  flex: none;
  align-self: stretch;
  align-items: flex-start;
  gap: 54px;
  width: 100%;
  display: flex;
  position: relative;
}

.contact-screen .frame-165 {
  flex-direction: column;
  flex: none;
  align-self: stretch;
  align-items: flex-start;
  gap: 24px;
  width: 100%;
  display: flex;
  position: relative;
}

.contact-screen .logo-white-18 {
  width: 121px !important;
  height: 40px !important;
  position: relative !important;
}

.contact-screen .flexcontainer-19 {
  flex-direction: column;
  align-items: flex-start;
  gap: 3px;
  display: flex;
}

.contact-screen .text-i-18 {
  color: #fff;
  letter-spacing: .26px;
  align-self: stretch;
  font-family: Noto Sans JP, Helvetica;
  font-size: 13px;
  font-weight: 400;
  line-height: 18px;
  position: relative;
}

.contact-screen .text-wrapper-157 {
  letter-spacing: .03px;
}

.contact-screen .frame-166 {
  flex: none;
  align-self: stretch;
  align-items: flex-start;
  gap: 40px;
  width: 100%;
  display: flex;
  position: relative;
}

.contact-screen .frame-167 {
  flex-direction: column;
  flex: none;
  align-items: flex-start;
  gap: 30px;
  display: inline-flex;
  position: relative;
}

.contact-screen .frame-168 {
  flex-direction: column;
  flex: none;
  align-items: flex-start;
  gap: 4px;
  display: inline-flex;
  position: relative;
}

.contact-screen .text-wrapper-158 {
  color: #fff;
  font-family: var(--text-bold-mini-font-family);
  font-size: var(--text-bold-mini-font-size);
  font-style: var(--text-bold-mini-font-style);
  font-weight: var(--text-bold-mini-font-weight);
  letter-spacing: var(--text-bold-mini-letter-spacing);
  line-height: var(--text-bold-mini-line-height);
  white-space: nowrap;
  width: fit-content;
  margin-top: -1px;
  display: block;
  position: relative;
}

.contact-screen .text-wrapper-159 {
  color: #fff;
  font-family: var(--text-bold-mini-font-family);
  font-size: var(--text-bold-mini-font-size);
  font-style: var(--text-bold-mini-font-style);
  font-weight: var(--text-bold-mini-font-weight);
  letter-spacing: var(--text-bold-mini-letter-spacing);
  line-height: var(--text-bold-mini-line-height);
  white-space: nowrap;
  width: fit-content;
  position: relative;
}

.contact-screen .text-wrapper-160 {
  color: #fff;
  font-family: var(--text-bold-mini-font-family);
  font-size: var(--text-bold-mini-font-size);
  font-style: var(--text-bold-mini-font-style);
  font-weight: var(--text-bold-mini-font-weight);
  letter-spacing: var(--text-bold-mini-letter-spacing);
  line-height: var(--text-bold-mini-line-height);
  white-space: nowrap;
  width: fit-content;
  margin-top: -1px;
  position: relative;
}

.contact-screen .frame-169 {
  flex-direction: column;
  flex: 1;
  align-self: stretch;
  align-items: flex-start;
  gap: 30px;
  display: flex;
  position: relative;
}

.contact-screen .text-wrapper-161 {
  color: #fff;
  font-family: var(--text-bold-mini-font-family);
  font-size: var(--text-bold-mini-font-size);
  font-style: var(--text-bold-mini-font-style);
  font-weight: var(--text-bold-mini-font-weight);
  letter-spacing: var(--text-bold-mini-letter-spacing);
  line-height: var(--text-bold-mini-line-height);
  white-space: nowrap;
  width: fit-content;
  display: block;
  position: relative;
}

.contact-screen .text-wrapper-162 {
  color: #fff;
  letter-spacing: 1.31px;
  width: fit-content;
  font-family: Manrope, Helvetica;
  font-size: 13.1px;
  font-weight: 400;
  line-height: normal;
  position: relative;
}

.freeicon {
  border-radius: 20px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  padding: 0 13px;
  display: inline-flex;
  position: relative;
}

.freeicon .div-5 {
  color: #fff;
  letter-spacing: .7px;
  white-space: nowrap;
  width: fit-content;
  margin-top: -1px;
  font-family: Noto Sans JP, Helvetica;
  font-size: 14px;
  font-weight: 700;
  line-height: 26px;
  position: relative;
}

.freeicon.one-11-two {
  background-color: #6079c1;
}

.freeicon.one-11-one {
  background-color: var(--lightpurple);
}

.flow {
  background-color: #fff;
  border: 1px solid;
  align-items: flex-start;
  position: relative;
}

.flow .frame-24 {
  z-index: 3;
  background-color: #6079c1;
  border-radius: 10px 0 0 10px;
  flex-direction: column;
  justify-content: center;
  align-self: stretch;
  align-items: center;
  gap: 10px;
  width: 40px;
  padding: 0 20px;
  display: flex;
  position: relative;
}

.flow .element {
  color: #fff;
  letter-spacing: 0;
  width: fit-content;
  margin-left: -5.5px;
  margin-right: -5.5px;
  font-family: Manrope, Helvetica;
  font-size: 22px;
  font-weight: 800;
  line-height: normal;
  position: relative;
}

.flow .group-wrapper {
  z-index: 2;
  flex-direction: column;
  justify-content: center;
  align-self: stretch;
  align-items: center;
  width: 180px;
  display: flex;
  position: relative;
}

.flow .overlap-group-wrapper {
  width: 168px;
  height: 166px;
  position: relative;
}

.flow .overlap-group {
  height: 166px;
  position: relative;
}

.flow .image-2 {
  width: 168px;
  height: 137px;
  position: absolute;
  top: 15px;
  left: 0;
}

.flow .rectangle {
  width: 166px;
  height: 166px;
  position: absolute;
  top: 0;
  left: 1px;
}

.flow .frame-25 {
  z-index: 1;
  flex-direction: column;
  flex: 1;
  align-items: flex-start;
  gap: 12px;
  padding: 20px 24px;
  display: flex;
  position: relative;
}

.flow .frame-26 {
  flex: none;
  align-self: stretch;
  align-items: center;
  gap: 12px;
  width: 100%;
  display: flex;
  position: relative;
}

.flow .freeicon-instance {
  flex: none !important;
}

.flow .text-wrapper-17 {
  color: #454545;
  font-family: var(--bold-SP-font-family);
  font-size: var(--bold-SP-font-size);
  font-style: var(--bold-SP-font-style);
  font-weight: var(--bold-SP-font-weight);
  letter-spacing: var(--bold-SP-letter-spacing);
  line-height: var(--bold-SP-line-height);
  width: fit-content;
  position: relative;
}

.flow .text-wrapper-18 {
  color: #454545;
  letter-spacing: .75px;
  align-self: stretch;
  height: 82px;
  font-family: Noto Sans JP, Helvetica;
  font-size: 15px;
  font-weight: 400;
  line-height: 25px;
  position: relative;
}

.flow .frame-27 {
  background-color: var(--palegray);
  z-index: 0;
  border-radius: 0 10px 10px 0;
  flex-direction: column;
  align-self: stretch;
  align-items: flex-start;
  gap: 10px;
  width: 200px;
  max-width: 200px;
  padding: 20px 25px 20px 20px;
  display: flex;
  position: relative;
}

.flow .frame-28 {
  background-color: #4c70c1;
  border-radius: 20px;
  flex: none;
  justify-content: center;
  align-items: center;
  padding: 5px 14px;
  display: inline-flex;
  position: relative;
  overflow: hidden;
}

.flow .text-wrapper-19 {
  color: #fff;
  letter-spacing: .7px;
  text-align: center;
  white-space: nowrap;
  width: fit-content;
  margin-top: -1px;
  font-family: Noto Sans JP, Helvetica;
  font-size: 14px;
  font-weight: 700;
  line-height: 14.8px;
  position: relative;
}

.flow .element-2 {
  color: #454545;
  font-family: var(--text-bold-font-family);
  font-size: var(--text-bold-font-size);
  font-style: var(--text-bold-font-style);
  font-weight: var(--text-bold-font-weight);
  letter-spacing: var(--text-bold-letter-spacing);
  line-height: var(--text-bold-line-height);
  white-space: nowrap;
  width: fit-content;
  position: relative;
}

.flow .frame-29 {
  flex-direction: column;
  flex: none;
  align-items: flex-start;
  display: inline-flex;
  position: relative;
}

.flow .frame-30 {
  align-items: flex-start;
  width: 320px;
  height: 100px;
  display: flex;
  position: relative;
}

.flow .frame-31 {
  background-color: #6079c1;
  border-radius: 6px 0 0;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
  width: 50px;
  height: 100px;
  padding: 0 20px;
  display: flex;
  position: relative;
}

.flow .element-3 {
  color: #fff;
  letter-spacing: 0;
  width: fit-content;
  font-family: Manrope, Helvetica;
  font-size: 19px;
  font-weight: 800;
  line-height: normal;
  position: relative;
}

.flow .frame-32 {
  border-radius: 0 6px 0 0;
  flex-direction: column;
  flex: 1;
  justify-content: flex-end;
  align-items: center;
  height: 100px;
  display: flex;
  position: relative;
  overflow: hidden;
}

.flow .frame-33 {
  flex-direction: column;
  flex: none;
  justify-content: center;
  align-items: center;
  margin-top: -17px;
  display: inline-flex;
  position: relative;
}

.flow .group-3 {
  width: 144px;
  height: 117px;
  position: relative;
}

.flow .overlap-group-2 {
  height: 117px;
  position: relative;
}

.flow .rectangle-2 {
  width: 123px;
  height: 75px;
  position: absolute;
  top: 0;
  left: 1px;
}

.flow .image-3 {
  width: 144px;
  height: 100px;
  position: absolute;
  top: 17px;
  left: 0;
}

.flow .line-3 {
  object-fit: cover;
  align-self: stretch;
  width: 100%;
  height: 1px;
  position: relative;
}

.flow .frame-34 {
  flex-direction: column;
  flex: none;
  align-self: stretch;
  align-items: flex-start;
  width: 100%;
  display: flex;
  position: relative;
}

.flow .frame-35 {
  flex-direction: column;
  flex: none;
  align-self: stretch;
  align-items: flex-start;
  gap: 12px;
  width: 100%;
  padding: 20px 15px;
  display: flex;
  position: relative;
}

.flow .freeicon-2 {
  letter-spacing: .6px !important;
  margin: -3px -2px -1px !important;
  font-size: 12px !important;
}

.flow .text-wrapper-20 {
  color: #454545;
  font-family: var(--bold-SP-font-family);
  font-size: var(--bold-SP-font-size);
  font-style: var(--bold-SP-font-style);
  font-weight: var(--bold-SP-font-weight);
  letter-spacing: var(--bold-SP-letter-spacing);
  line-height: var(--bold-SP-line-height);
  flex: 1;
  margin-top: -1px;
  position: relative;
}

.flow .text-wrapper-21 {
  color: #454545;
  font-family: var(--text-SP-font-family);
  font-size: var(--text-SP-font-size);
  font-style: var(--text-SP-font-style);
  font-weight: var(--text-SP-font-weight);
  letter-spacing: var(--text-SP-letter-spacing);
  line-height: var(--text-SP-line-height);
  align-self: stretch;
  position: relative;
}

.flow .frame-37 {
  background-color: var(--palegray);
  border-radius: 0 0 6px 6px;
  flex-direction: column;
  flex: none;
  align-self: stretch;
  align-items: flex-start;
  gap: 6px;
  width: 100%;
  padding: 10px 15px 15px;
  display: flex;
  position: relative;
}

.flow .text-wrapper-22 {
  color: #fff;
  letter-spacing: .6px;
  text-align: center;
  white-space: nowrap;
  width: fit-content;
  margin-top: -1px;
  font-family: Noto Sans JP, Helvetica;
  font-size: 12px;
  font-weight: 700;
  line-height: 14.8px;
  position: relative;
}

.flow .element-4 {
  color: #454545;
  font-family: var(--textbold-SP-font-family);
  font-size: var(--textbold-SP-font-size);
  font-style: var(--textbold-SP-font-style);
  font-weight: var(--textbold-SP-font-weight);
  letter-spacing: var(--textbold-SP-letter-spacing);
  line-height: var(--textbold-SP-line-height);
  white-space: nowrap;
  width: fit-content;
  position: relative;
}

.flow.prop-2-one {
  border-color: #d7d7d7;
  border-radius: 10px;
  width: 802px;
  display: flex;
}

.flow.prop-2-SP {
  border-color: var(--linecolor);
  border-radius: 6px;
  display: inline-flex;
}

.aboutcontents {
  background-color: var(--backwhite);
  flex-direction: column;
  align-items: center;
  display: flex;
  position: relative;
}

.aboutcontents .header-10 {
  height: unset !important;
  z-index: 1 !important;
  flex: none !important;
  align-self: stretch !important;
  width: 100% !important;
}

.aboutcontents .header-11 {
  margin-bottom: unset !important;
}

.aboutcontents .menu-3-instance {
  width: 36px !important;
  height: 35px !important;
  position: relative !important;
}

.aboutcontents .header-12 {
  z-index: 1 !important;
  flex: none !important;
  justify-content: space-between !important;
  align-self: stretch !important;
  width: 100% !important;
  display: flex !important;
}

.aboutcontents .header-13 {
  z-index: 1 !important;
}

.aboutcontents .header-14 {
  z-index: 0 !important;
  width: 60px !important;
  height: 59px !important;
  position: relative !important;
}

.aboutcontents .div-14 {
  z-index: 0;
  background-color: #fff;
  flex-direction: column;
  flex: none;
  align-self: stretch;
  align-items: flex-start;
  width: 100%;
  display: flex;
  position: relative;
}

.aboutcontents .div-15 {
  flex-direction: column;
  flex: none;
  align-self: stretch;
  align-items: flex-start;
  width: 100%;
  display: flex;
  position: relative;
}

.aboutcontents .class-52 {
  color: #fff !important;
  left: -10px !important;
}

.aboutcontents .class-53 {
  color: #fff !important;
  left: -45px !important;
}

.aboutcontents .class-54 {
  color: #fff !important;
  left: 5px !important;
}

.aboutcontents .class-55 {
  color: #fff !important;
  left: -44px !important;
}

.aboutcontents .class-56 {
  flex: none !important;
  align-self: stretch !important;
  width: 100% !important;
  padding: 5px 0 !important;
  display: flex !important;
}

.aboutcontents .class-57 {
  flex: none !important;
  align-self: stretch !important;
  width: 100% !important;
}

.aboutcontents .breadcrumbs-5 {
  flex: none;
  justify-content: center;
  align-self: stretch;
  align-items: flex-start;
  gap: 30px;
  width: 100%;
  display: flex;
  position: relative;
}

.aboutcontents .frame-170 {
  flex: 1;
  align-items: center;
  display: flex;
  position: relative;
}

.aboutcontents .text-wrapper-163 {
  color: var(--gray);
  font-family: var(--smalltext-SP-font-family);
  font-size: var(--smalltext-SP-font-size);
  font-style: var(--smalltext-SP-font-style);
  font-weight: var(--smalltext-SP-font-weight);
  letter-spacing: var(--smalltext-SP-letter-spacing);
  line-height: var(--smalltext-SP-line-height);
  white-space: nowrap;
  width: fit-content;
  margin-top: -1px;
  display: block;
  position: relative;
}

.aboutcontents .instance-node-5 {
  width: 4.57px !important;
  height: 8.14px !important;
  position: relative !important;
}

.aboutcontents .TOP {
  color: var(--gray);
  white-space: nowrap;
  width: fit-content;
  margin-top: -1px;
  position: relative;
}

.aboutcontents .text-wrapper-164 {
  color: var(--gray);
  font-family: var(--tag-font-family);
  font-size: var(--tag-font-size);
  font-style: var(--tag-font-style);
  font-weight: var(--tag-font-weight);
  letter-spacing: var(--tag-letter-spacing);
  line-height: var(--tag-line-height);
  white-space: nowrap;
  width: fit-content;
  margin-top: -1px;
  position: relative;
}

.aboutcontents .contents {
  background-color: var(--palepurple);
  flex-direction: column;
  flex: none;
  align-self: stretch;
  align-items: center;
  width: 100%;
  display: flex;
  position: relative;
}

.aboutcontents .class-58 {
  left: 12px !important;
}

.aboutcontents .class-59 {
  left: -4px !important;
}

.aboutcontents .heading-26 {
  z-index: 1 !important;
  position: relative !important;
}

.aboutcontents .class-60 {
  left: 26px !important;
}

.aboutcontents .class-61 {
  left: -10px !important;
}

.aboutcontents .frame-171 {
  z-index: 0;
  flex-direction: column;
  flex: none;
  align-self: stretch;
  align-items: center;
  width: 100%;
  display: flex;
  position: relative;
}

.aboutcontents .group-23 {
  z-index: 1;
  background-image: url("lead-ill.021ca26c.png");
  background-position: 50%;
  background-size: cover;
  position: relative;
}

.aboutcontents .frame-172 {
  background-color: var(--white);
  z-index: 0;
  border: 8px solid #d6dfff;
  border-radius: 10px;
  flex-direction: column;
  flex: none;
  align-self: stretch;
  align-items: center;
  gap: 20px;
  width: 100%;
  margin-top: -20px;
  padding: 40px 20px;
  display: flex;
  position: relative;
}

.aboutcontents .flexcontainer-20 {
  flex-direction: column;
  align-items: flex-start;
  gap: 2px;
  display: flex;
}

.aboutcontents .text-9 {
  color: var(--darkgray);
  font-family: var(--textbold-SP-font-family);
  font-size: var(--textbold-SP-font-size);
  font-style: var(--textbold-SP-font-style);
  font-weight: var(--textbold-SP-font-weight);
  letter-spacing: var(--textbold-SP-letter-spacing);
  line-height: var(--textbold-SP-line-height);
  text-align: center;
  align-self: stretch;
  position: relative;
}

.aboutcontents .text-wrapper-165 {
  font-family: var(--textbold-SP-font-family);
  font-size: var(--textbold-SP-font-size);
  font-style: var(--textbold-SP-font-style);
  font-weight: var(--textbold-SP-font-weight);
  letter-spacing: var(--textbold-SP-letter-spacing);
  line-height: var(--textbold-SP-line-height);
}

.aboutcontents .line-12 {
  object-fit: cover;
  align-self: stretch;
  width: 100%;
  height: 1px;
  position: relative;
}

.aboutcontents .frame-173 {
  flex: none;
  align-self: stretch;
  width: 100%;
  display: flex;
  position: relative;
}

.aboutcontents .frame-174 {
  flex-direction: column;
  align-items: flex-start;
  display: flex;
  position: relative;
}

.aboutcontents .ellipse {
  flex-wrap: wrap;
  flex: none;
  align-self: stretch;
  align-items: flex-start;
  gap: 5px;
  width: 100%;
  display: flex;
  position: relative;
}

.aboutcontents .ellipse-wrapper {
  width: 10px;
  height: 28px;
  position: relative;
}

.aboutcontents .ellipse-2 {
  width: 6px;
  height: 6px;
  position: absolute;
  top: 9px;
  left: 3px;
}

.aboutcontents .text-wrapper-166 {
  color: #454545;
  font-family: var(--textbold-SP-font-family);
  font-size: var(--textbold-SP-font-size);
  font-style: var(--textbold-SP-font-style);
  font-weight: var(--textbold-SP-font-weight);
  letter-spacing: var(--textbold-SP-letter-spacing);
  line-height: var(--textbold-SP-line-height);
  flex: 1;
  height: 28px;
  margin-top: -1px;
  position: relative;
}

.aboutcontents .frame-175, .aboutcontents .frame-176 {
  flex-direction: column;
  align-items: flex-start;
  display: flex;
  position: relative;
}

.aboutcontents .rectangle-4 {
  object-fit: cover;
  z-index: 1;
  width: 442px;
  height: 283px;
  position: relative;
}

.aboutcontents .frame-177 {
  background-color: var(--white);
  z-index: 0;
  border: 8px solid #d6dfff;
  border-radius: 10px;
  flex-direction: column;
  flex: none;
  align-items: center;
  gap: 30px;
  min-width: 1000px;
  margin-top: -40px;
  padding: 80px;
  display: inline-flex;
  position: relative;
}

.aboutcontents .text-wrapper-167 {
  color: var(--darkgray);
  letter-spacing: .8px;
  text-align: center;
  width: fit-content;
  margin-top: -8px;
  font-family: Noto Sans JP, Helvetica;
  font-size: 16px;
  font-weight: 700;
  line-height: normal;
  position: relative;
}

.aboutcontents .frame-178 {
  flex: none;
  justify-content: center;
  align-self: stretch;
  align-items: flex-start;
  gap: 40px;
  width: 100%;
  display: flex;
  position: relative;
}

.aboutcontents .frame-179 {
  flex-direction: column;
  flex: 1;
  align-items: flex-start;
  gap: 20px;
  display: flex;
  position: relative;
}

.aboutcontents .group-24 {
  width: 17px;
  height: 28px;
  position: relative;
}

.aboutcontents .ellipse-3 {
  background-color: #6079c1;
  border-radius: 5px;
  width: 10px;
  height: 10px;
  position: relative;
  top: 9px;
  left: 3px;
}

.aboutcontents .text-wrapper-168 {
  color: #454545;
  font-family: var(--text-bold-font-family);
  font-size: var(--text-bold-font-size);
  font-style: var(--text-bold-font-style);
  font-weight: var(--text-bold-font-weight);
  letter-spacing: var(--text-bold-letter-spacing);
  line-height: var(--text-bold-line-height);
  white-space: nowrap;
  flex: 1;
  height: 28px;
  margin-top: -1px;
  position: relative;
}

.aboutcontents .flow-2 {
  background-color: var(--palepurple);
  flex-direction: column;
  flex: none;
  align-self: stretch;
  align-items: center;
  width: 100%;
  display: flex;
  position: relative;
}

.aboutcontents .class-62 {
  left: 1px !important;
}

.aboutcontents .class-63 {
  left: 24px !important;
}

.aboutcontents .heading-27 {
  position: relative !important;
}

.aboutcontents .class-64 {
  left: 40px !important;
}

.aboutcontents .class-65 {
  left: -27px !important;
}

.aboutcontents .frame-180 {
  flex-direction: column;
  flex: none;
  align-items: center;
  display: flex;
  position: relative;
}

.aboutcontents .flow-3 {
  background-color: #fff;
  border: 1px solid;
  flex: none;
  align-self: stretch;
  align-items: flex-start;
  width: 100%;
  margin-top: -1px;
  margin-left: -1px;
  margin-right: -1px;
  display: flex;
  position: relative;
}

.aboutcontents .frame-181 {
  z-index: 3;
  background-color: #6079c1;
  border-radius: 10px 0 0 10px;
  flex-direction: column;
  justify-content: center;
  align-self: stretch;
  align-items: center;
  gap: 10px;
  width: 40px;
  padding: 0 20px;
  display: flex;
  position: relative;
}

.aboutcontents .text-wrapper-169 {
  color: #fff;
  letter-spacing: 0;
  width: fit-content;
  margin-left: -5.5px;
  margin-right: -5.5px;
  font-family: Manrope, Helvetica;
  font-size: 22px;
  font-weight: 800;
  line-height: normal;
  position: relative;
}

.aboutcontents .frame-182 {
  z-index: 2;
  flex-direction: column;
  justify-content: center;
  align-self: stretch;
  align-items: center;
  position: relative;
}

.aboutcontents .group-25 {
  width: 168px;
  height: 166px;
  position: relative;
}

.aboutcontents .image-7 {
  width: 168px;
  height: 137px;
  position: absolute;
  top: 15px;
  left: 0;
}

.aboutcontents .frame-183 {
  z-index: 1;
  flex-direction: column;
  flex: 1;
  align-items: flex-start;
  gap: 12px;
  padding: 20px 24px;
  display: flex;
  position: relative;
}

.aboutcontents .frame-184 {
  flex: none;
  align-self: stretch;
  align-items: center;
  gap: 12px;
  width: 100%;
  display: flex;
  position: relative;
}

.aboutcontents .freeicon-3 {
  flex: none !important;
}

.aboutcontents .text-wrapper-170 {
  color: #454545;
  font-family: var(--bold-SP-font-family);
  font-size: var(--bold-SP-font-size);
  font-style: var(--bold-SP-font-style);
  font-weight: var(--bold-SP-font-weight);
  letter-spacing: var(--bold-SP-letter-spacing);
  line-height: var(--bold-SP-line-height);
  width: fit-content;
  position: relative;
}

.aboutcontents .text-wrapper-171 {
  color: #454545;
  align-self: stretch;
  position: relative;
}

.aboutcontents .frame-185 {
  background-color: var(--palegray);
  z-index: 0;
  border-radius: 0 10px 10px 0;
  flex-direction: column;
  align-self: stretch;
  align-items: flex-start;
  gap: 10px;
  width: 200px;
  max-width: 200px;
  padding: 20px 25px 20px 20px;
  display: flex;
  position: relative;
}

.aboutcontents .frame-186 {
  background-color: #4c70c1;
  border-radius: 20px;
  flex: none;
  justify-content: center;
  align-items: center;
  padding: 5px 14px;
  display: inline-flex;
  position: relative;
  overflow: hidden;
}

.aboutcontents .text-wrapper-172 {
  color: #fff;
  letter-spacing: .7px;
  text-align: center;
  white-space: nowrap;
  width: fit-content;
  margin-top: -1px;
  font-family: Noto Sans JP, Helvetica;
  font-size: 14px;
  font-weight: 700;
  line-height: 14.8px;
  position: relative;
}

.aboutcontents .text-wrapper-173 {
  color: #454545;
  align-self: stretch;
  position: relative;
}

.aboutcontents .frame-187 {
  flex-direction: column;
  flex: 1;
  align-items: flex-start;
  display: flex;
  position: relative;
}

.aboutcontents .frame-188 {
  align-self: stretch;
  align-items: flex-start;
  width: 100%;
  height: 100px;
  display: flex;
  position: relative;
}

.aboutcontents .frame-189 {
  background-color: #6079c1;
  border-radius: 6px 0 0;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
  width: 50px;
  height: 100px;
  padding: 0 20px;
  display: flex;
  position: relative;
}

.aboutcontents .text-wrapper-174 {
  color: #fff;
  letter-spacing: 0;
  width: fit-content;
  font-family: Manrope, Helvetica;
  font-size: 19px;
  font-weight: 800;
  line-height: normal;
  position: relative;
}

.aboutcontents .frame-190 {
  border-radius: 0 6px 0 0;
  flex-direction: column;
  flex: 1;
  justify-content: flex-end;
  align-items: center;
  height: 100px;
  display: flex;
  position: relative;
  overflow: hidden;
}

.aboutcontents .frame-191 {
  flex-direction: column;
  flex: none;
  justify-content: center;
  align-items: center;
  margin-top: -17px;
  display: inline-flex;
  position: relative;
}

.aboutcontents .group-26 {
  width: 144px;
  height: 117px;
  position: relative;
}

.aboutcontents .image-8 {
  width: 144px;
  height: 100px;
  position: absolute;
  top: 17px;
  left: 0;
}

.aboutcontents .frame-192 {
  flex-direction: column;
  flex: none;
  align-self: stretch;
  align-items: flex-start;
  gap: 12px;
  width: 100%;
  padding: 20px 15px;
  display: flex;
  position: relative;
}

.aboutcontents .freeicon-4 {
  width: 47px !important;
  height: 22px !important;
  display: flex !important;
}

.aboutcontents .freeicon-5 {
  letter-spacing: .6px !important;
  margin: -3px -2px -1px !important;
  font-size: 12px !important;
}

.aboutcontents .text-wrapper-175 {
  color: #454545;
  font-family: var(--bold-SP-font-family);
  font-size: var(--bold-SP-font-size);
  font-style: var(--bold-SP-font-style);
  font-weight: var(--bold-SP-font-weight);
  letter-spacing: var(--bold-SP-letter-spacing);
  line-height: var(--bold-SP-line-height);
  flex: 1;
  margin-top: -1px;
  position: relative;
}

.aboutcontents .text-wrapper-176 {
  color: #454545;
  font-family: var(--text-SP-font-family);
  font-size: var(--text-SP-font-size);
  font-style: var(--text-SP-font-style);
  font-weight: var(--text-SP-font-weight);
  letter-spacing: var(--text-SP-letter-spacing);
  line-height: var(--text-SP-line-height);
  align-self: stretch;
  position: relative;
}

.aboutcontents .frame-193 {
  background-color: var(--palegray);
  border-radius: 0 0 6px 6px;
  flex-direction: column;
  flex: none;
  align-self: stretch;
  align-items: flex-start;
  gap: 6px;
  width: 100%;
  padding: 10px 15px 15px;
  display: flex;
  position: relative;
}

.aboutcontents .text-wrapper-177 {
  color: #fff;
  letter-spacing: .6px;
  text-align: center;
  white-space: nowrap;
  width: fit-content;
  margin-top: -1px;
  font-family: Noto Sans JP, Helvetica;
  font-size: 12px;
  font-weight: 700;
  line-height: 14.8px;
  position: relative;
}

.aboutcontents .text-wrapper-178 {
  color: #454545;
  font-family: var(--textbold-SP-font-family);
  font-size: var(--textbold-SP-font-size);
  font-style: var(--textbold-SP-font-style);
  font-weight: var(--textbold-SP-font-weight);
  letter-spacing: var(--textbold-SP-letter-spacing);
  line-height: var(--textbold-SP-line-height);
  align-self: stretch;
  position: relative;
}

.aboutcontents .triangle02-18-instance {
  width: 20px !important;
  height: 20px !important;
  position: relative !important;
}

.aboutcontents .triangle-02-instance {
  width: 34px !important;
  height: 34px !important;
  position: relative !important;
}

.aboutcontents .flow-instance {
  flex: none !important;
  align-self: stretch !important;
  width: 100% !important;
  margin-left: -1px !important;
  margin-right: -1px !important;
  display: flex !important;
}

.aboutcontents .flow-4 {
  flex: 1 !important;
  display: flex !important;
}

.aboutcontents .flow-5 {
  align-self: stretch !important;
  width: 100% !important;
}

.aboutcontents .flow-6 {
  margin-left: -1.5px !important;
  margin-right: -1.5px !important;
}

.aboutcontents .flow-7 {
  height: 100px !important;
  top: 17px !important;
}

.aboutcontents .flow-8 {
  height: 58px !important;
}

.aboutcontents .flow-9 {
  top: 0 !important;
}

.aboutcontents .flow-10 {
  white-space: unset !important;
  width: unset !important;
  align-self: stretch !important;
}

.aboutcontents .flow-11 {
  background-color: #fff;
  border: 1px solid #d7d7d7;
  border-radius: 10px;
  flex: none;
  align-self: stretch;
  align-items: flex-start;
  width: 100%;
  margin-left: -1px;
  margin-right: -1px;
  display: flex;
  position: relative;
}

.aboutcontents .element-5 {
  color: #fff;
  letter-spacing: 0;
  width: fit-content;
  margin-left: -7px;
  margin-right: -7px;
  font-family: Manrope, Helvetica;
  font-size: 22px;
  font-weight: 800;
  line-height: normal;
  position: relative;
}

.aboutcontents .frame-194 {
  z-index: 2;
  flex-direction: column;
  justify-content: center;
  align-self: stretch;
  align-items: center;
  position: relative;
}

.aboutcontents .text-wrapper-179, .aboutcontents .element-6 {
  color: #454545;
  align-self: stretch;
  position: relative;
}

.aboutcontents .frame-195 {
  z-index: 2;
  flex-direction: column;
  justify-content: center;
  align-self: stretch;
  align-items: center;
  position: relative;
}

.aboutcontents .text-wrapper-180, .aboutcontents .element-7 {
  color: #454545;
  align-self: stretch;
  position: relative;
}

.aboutcontents .element-8 {
  color: #fff;
  letter-spacing: 0;
  width: fit-content;
  margin-left: -7.5px;
  margin-right: -7.5px;
  font-family: Manrope, Helvetica;
  font-size: 22px;
  font-weight: 800;
  line-height: normal;
  position: relative;
}

.aboutcontents .frame-196 {
  z-index: 2;
  flex-direction: column;
  justify-content: center;
  align-self: stretch;
  align-items: center;
  position: relative;
}

.aboutcontents .text-wrapper-181, .aboutcontents .element-9 {
  color: #454545;
  align-self: stretch;
  position: relative;
}

.aboutcontents .flow-12 {
  background-color: #fff;
  border: 1px solid #d7d7d7;
  border-radius: 10px;
  flex: none;
  align-self: stretch;
  align-items: flex-start;
  width: 100%;
  margin-bottom: -1px;
  margin-left: -1px;
  margin-right: -1px;
  display: flex;
  position: relative;
}

.aboutcontents .frame-197 {
  z-index: 2;
  flex-direction: column;
  justify-content: center;
  align-self: stretch;
  align-items: center;
  position: relative;
}

.aboutcontents .text-wrapper-182, .aboutcontents .text-wrapper-183 {
  color: #454545;
  align-self: stretch;
  position: relative;
}

.aboutcontents .flow-13 {
  margin-left: -1px !important;
  margin-right: -1px !important;
}

.aboutcontents .flow-14 {
  background-color: #6079c1 !important;
  width: 47px !important;
  height: 22px !important;
  display: flex !important;
}

.aboutcontents .flow-15 {
  flex: none !important;
  align-self: stretch !important;
  width: 100% !important;
  margin-bottom: -1px !important;
  margin-left: -1px !important;
  margin-right: -1px !important;
  display: flex !important;
}

.aboutcontents .CONTACT-12 {
  background-color: var(--backwhite);
  flex-direction: column;
  flex: none;
  align-items: center;
  gap: 40px;
  display: flex;
  position: relative;
}

.aboutcontents .CONTACT-13 {
  height: 160px;
  display: block;
  position: relative;
}

.aboutcontents .frame-198 {
  background-color: var(--purple);
  border-radius: 6px;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  max-width: 700px;
  display: flex;
  position: relative;
}

.aboutcontents .group-27 {
  width: 216px;
  position: relative;
}

.aboutcontents .text-wrapper-184 {
  color: var(--white);
  letter-spacing: 2px;
  text-align: center;
  width: 212px;
  font-family: Zen Kaku Gothic New, Helvetica;
  font-size: 20px;
  font-weight: 500;
  line-height: normal;
  position: absolute;
  top: 0;
  left: 0;
}

.aboutcontents .text-wrapper-185 {
  color: var(--white);
  letter-spacing: 0;
  text-align: center;
  width: 134px;
  font-family: Slackside One, Helvetica;
  font-size: 15px;
  font-weight: 400;
  line-height: normal;
  position: absolute;
  top: 31px;
  left: 39px;
}

.aboutcontents .overlap-group-7 {
  width: 212px;
  height: 60px;
  position: relative;
}

.aboutcontents .text-wrapper-186 {
  color: var(--white);
  letter-spacing: 2.4px;
  text-align: center;
  width: 212px;
  font-family: Zen Kaku Gothic New, Helvetica;
  font-size: 24px;
  font-weight: 500;
  line-height: normal;
  position: absolute;
  top: 0;
  left: 0;
}

.aboutcontents .text-wrapper-187 {
  color: var(--white);
  letter-spacing: 0;
  text-align: center;
  width: 134px;
  font-family: Slackside One, Helvetica;
  font-size: 20px;
  font-weight: 400;
  line-height: normal;
  position: absolute;
  top: 31px;
  left: 39px;
}

.aboutcontents .group-28 {
  position: relative;
}

.aboutcontents .flexcontainer-21 {
  flex-direction: column;
  align-items: flex-start;
  display: flex;
  position: relative;
}

.aboutcontents .text-i-19, .aboutcontents .text-i-20 {
  color: var(--white);
  align-self: stretch;
  position: relative;
}

.aboutcontents .footer-7 {
  background-color: #454545;
  flex-direction: column;
  flex: none;
  align-self: stretch;
  align-items: center;
  gap: 50px;
  width: 100%;
  display: flex;
  position: relative;
}

.aboutcontents .frame-199 {
  flex: none;
  align-items: flex-start;
  display: flex;
  position: relative;
}

.aboutcontents .frame-200 {
  flex-direction: column;
  align-items: flex-start;
  display: flex;
  position: relative;
}

.aboutcontents .logo-white-3 {
  width: 121px !important;
  height: 40px !important;
  position: relative !important;
}

.aboutcontents .flexcontainer-22 {
  flex-direction: column;
  align-items: flex-start;
  gap: 3px;
  display: flex;
}

.aboutcontents .text-i-21 {
  color: #fff;
  letter-spacing: .26px;
  align-self: stretch;
  font-family: Noto Sans JP, Helvetica;
  font-size: 13px;
  font-weight: 400;
  line-height: 18px;
  position: relative;
}

.aboutcontents .text-wrapper-188 {
  letter-spacing: .03px;
}

.aboutcontents .logo-white-25 {
  width: 163.65px !important;
  height: 54.26px !important;
  position: relative !important;
}

.aboutcontents .flexcontainer-23 {
  flex-direction: column;
  align-items: flex-start;
  gap: 5px;
  display: flex;
}

.aboutcontents .text-i-22 {
  color: #fff;
  font-family: var(--text-font-family);
  font-size: var(--text-font-size);
  font-style: var(--text-font-style);
  font-weight: var(--text-font-weight);
  letter-spacing: var(--text-letter-spacing);
  line-height: var(--text-line-height);
  align-self: stretch;
  position: relative;
}

.aboutcontents .text-wrapper-189 {
  font-family: var(--text-font-family);
  font-size: var(--text-font-size);
  font-style: var(--text-font-style);
  font-weight: var(--text-font-weight);
  letter-spacing: var(--text-letter-spacing);
  line-height: var(--text-line-height);
}

.aboutcontents .frame-201 {
  flex: none;
  align-items: flex-start;
  position: relative;
}

.aboutcontents .frame-202, .aboutcontents .frame-203 {
  flex-direction: column;
  flex: none;
  align-items: flex-start;
  display: inline-flex;
  position: relative;
}

.aboutcontents .text-wrapper-190 {
  color: #fff;
  white-space: nowrap;
  width: fit-content;
  margin-top: -1px;
  display: block;
  position: relative;
}

.aboutcontents .text-wrapper-191, .aboutcontents .text-wrapper-192, .aboutcontents .text-wrapper-193 {
  color: #fff;
  white-space: nowrap;
  width: fit-content;
  position: relative;
}

.aboutcontents .frame-204 {
  flex-direction: column;
  flex: none;
  align-items: flex-start;
  display: inline-flex;
  position: relative;
}

.aboutcontents .text-wrapper-194 {
  color: #fff;
  white-space: nowrap;
  width: fit-content;
  margin-top: -1px;
  position: relative;
}

.aboutcontents .text-wrapper-195, .aboutcontents .text-wrapper-196 {
  color: #fff;
  white-space: nowrap;
  width: fit-content;
  position: relative;
}

.aboutcontents .frame-205 {
  flex-direction: column;
  flex: none;
  align-items: flex-start;
  display: inline-flex;
  position: relative;
}

.aboutcontents .text-wrapper-197 {
  color: #fff;
  white-space: nowrap;
  width: fit-content;
  margin-top: -1px;
  position: relative;
}

.aboutcontents .text-wrapper-198, .aboutcontents .text-wrapper-199 {
  color: #fff;
  white-space: nowrap;
  width: fit-content;
  position: relative;
}

.aboutcontents .frame-206 {
  flex-direction: column;
  align-items: flex-start;
  gap: 30px;
  position: relative;
}

.aboutcontents .text-wrapper-200 {
  color: #fff;
  white-space: nowrap;
  width: fit-content;
  margin-top: -1px;
  display: block;
  position: relative;
}

.aboutcontents .text-wrapper-201, .aboutcontents .text-wrapper-202, .aboutcontents .text-wrapper-203, .aboutcontents .text-wrapper-204 {
  color: #fff;
  white-space: nowrap;
  width: fit-content;
  display: block;
  position: relative;
}

.aboutcontents .text-wrapper-205 {
  color: #fff;
  letter-spacing: 1.31px;
  width: fit-content;
  font-family: Manrope, Helvetica;
  font-size: 13.1px;
  font-weight: 400;
  line-height: normal;
  position: relative;
}

.recruits {
  background-color: var(--backwhite);
  flex-direction: column;
  align-items: center;
  display: flex;
  position: relative;
}

.recruits .header-15 {
  height: unset !important;
  z-index: 1 !important;
  flex: none !important;
  align-self: stretch !important;
  width: 100% !important;
}

.recruits .header-16 {
  margin-bottom: unset !important;
}

.recruits .menu-2-instance {
  width: 36px !important;
  height: 35px !important;
  position: relative !important;
}

.recruits .header-17 {
  z-index: 1 !important;
  flex: none !important;
  justify-content: space-between !important;
  align-self: stretch !important;
  width: 100% !important;
  display: flex !important;
}

.recruits .header-18 {
  z-index: 1 !important;
}

.recruits .header-19 {
  z-index: 0 !important;
  width: 60px !important;
  height: 59px !important;
  position: relative !important;
}

.recruits .div-16 {
  z-index: 0;
  background-color: #fff;
  flex-direction: column;
  flex: none;
  align-self: stretch;
  align-items: flex-start;
  width: 100%;
  display: flex;
  position: relative;
}

.recruits .midashi-3 {
  flex-direction: column;
  flex: none;
  align-self: stretch;
  align-items: flex-start;
  width: 100%;
  display: flex;
  position: relative;
}

.recruits .class-66 {
  color: #fff !important;
  left: 12px !important;
}

.recruits .class-67 {
  color: #fff !important;
  left: 10px !important;
}

.recruits .class-68 {
  color: #fff !important;
  left: 33px !important;
}

.recruits .class-69 {
  color: #fff !important;
  left: -10px !important;
}

.recruits .class-70 {
  flex: none !important;
  align-self: stretch !important;
  width: 100% !important;
  padding: 5px 0 !important;
  display: flex !important;
}

.recruits .class-71 {
  flex: none !important;
  align-self: stretch !important;
  width: 100% !important;
}

.recruits .breadcrumbs-6 {
  flex: none;
  justify-content: center;
  align-self: stretch;
  align-items: flex-start;
  gap: 30px;
  width: 100%;
  display: flex;
  position: relative;
}

.recruits .frame-207 {
  flex: 1;
  align-items: center;
  display: flex;
  position: relative;
}

.recruits .text-wrapper-206 {
  color: var(--gray);
  font-family: var(--smalltext-SP-font-family);
  font-size: var(--smalltext-SP-font-size);
  font-style: var(--smalltext-SP-font-style);
  font-weight: var(--smalltext-SP-font-weight);
  letter-spacing: var(--smalltext-SP-letter-spacing);
  line-height: var(--smalltext-SP-line-height);
  white-space: nowrap;
  width: fit-content;
  margin-top: -1px;
  display: block;
  position: relative;
}

.recruits .instance-node-6 {
  width: 4.57px !important;
  height: 8.14px !important;
  position: relative !important;
}

.recruits .TOP-2 {
  color: var(--gray);
  white-space: nowrap;
  width: fit-content;
  margin-top: -1px;
  position: relative;
}

.recruits .text-wrapper-207 {
  color: var(--gray);
  font-family: var(--tag-font-family);
  font-size: var(--tag-font-size);
  font-style: var(--tag-font-style);
  font-weight: var(--tag-font-weight);
  letter-spacing: var(--tag-letter-spacing);
  line-height: var(--tag-line-height);
  white-space: nowrap;
  width: fit-content;
  margin-top: -1px;
  position: relative;
}

.recruits .component-10930-2 {
  left: unset !important;
  top: unset !important;
  width: 4.57px !important;
  height: 8.14px !important;
  position: relative !important;
}

.recruits .recruit-list {
  background-color: var(--palepurple);
  flex-direction: column;
  flex: none;
  align-self: stretch;
  align-items: center;
  gap: 40px;
  width: 100%;
  display: flex;
  position: relative;
}

.recruits .frame-208 {
  flex-wrap: wrap;
  flex: none;
  justify-content: center;
  align-items: flex-start;
  position: relative;
}

.recruits .class-72 {
  font-family: var(--smalltextbold-SP-font-family) !important;
  font-size: var(--smalltextbold-SP-font-size) !important;
  font-style: var(--smalltextbold-SP-font-style) !important;
  font-weight: var(--smalltextbold-SP-font-weight) !important;
  letter-spacing: var(--smalltextbold-SP-letter-spacing) !important;
  line-height: var(--smalltextbold-SP-line-height) !important;
}

.recruits .class-73 {
  height: unset !important;
  width: unset !important;
  flex: 1 !important;
  min-width: 290px !important;
  max-width: 360px !important;
}

.recruits .class-74 {
  width: 360px !important;
  max-width: 360px !important;
  height: 442px !important;
}

.recruits .class-75 {
  width: unset !important;
  flex: none !important;
  padding: 8px 30px !important;
  display: inline-flex !important;
}

.recruits .class-76 {
  width: 120px !important;
}

.recruits .class-77 {
  font-family: var(--button-SP-font-family) !important;
  font-size: var(--button-SP-font-size) !important;
  font-style: var(--button-SP-font-style) !important;
  font-weight: var(--button-SP-font-weight) !important;
  letter-spacing: var(--button-SP-letter-spacing) !important;
  line-height: var(--button-SP-line-height) !important;
  white-space: unset !important;
}

.recruits .class-78 {
  margin-left: -12px !important;
  margin-right: -12px !important;
}

.recruits .class-79 {
  flex: none !important;
  flex-grow: unset !important;
}

.recruits .noimage-4 {
  width: 190.9px !important;
  height: 25.92px !important;
  position: relative !important;
}

.recruits .class-80 {
  font-family: var(--button-SP-font-family) !important;
  font-size: var(--button-SP-font-size) !important;
  font-style: var(--button-SP-font-style) !important;
  font-weight: var(--button-SP-font-weight) !important;
  letter-spacing: var(--button-SP-letter-spacing) !important;
  line-height: var(--button-SP-line-height) !important;
  margin-left: unset !important;
  margin-right: unset !important;
  white-space: unset !important;
}

.recruits .class-81 {
  margin-left: -19.5px !important;
  margin-right: -19.5px !important;
}

.recruits .class-82 {
  font-family: var(--textbold-SP-font-family) !important;
  font-size: var(--textbold-SP-font-size) !important;
  font-style: var(--textbold-SP-font-style) !important;
  font-weight: var(--textbold-SP-font-weight) !important;
  letter-spacing: var(--textbold-SP-letter-spacing) !important;
  line-height: var(--textbold-SP-line-height) !important;
  margin-left: unset !important;
  margin-right: unset !important;
}

.recruits .frame-209 {
  height: 16px;
  position: relative;
}

.recruits .text-wrapper-208 {
  color: #454545;
  font-family: var(--tag-font-family);
  font-size: var(--tag-font-size);
  font-style: var(--tag-font-style);
  font-weight: var(--tag-font-weight);
  letter-spacing: var(--tag-letter-spacing);
  line-height: var(--tag-line-height);
  white-space: nowrap;
  position: absolute;
  left: 29px;
}

.recruits .text-wrapper-209 {
  color: #454545;
  font-family: var(--tag-font-family);
  font-size: var(--tag-font-size);
  font-style: var(--tag-font-style);
  font-weight: var(--tag-font-weight);
  letter-spacing: var(--tag-letter-spacing);
  line-height: var(--tag-line-height);
  white-space: nowrap;
  position: absolute;
  left: 57px;
}

.recruits .text-wrapper-210 {
  color: #454545;
  font-family: var(--tag-font-family);
  font-size: var(--tag-font-size);
  font-style: var(--tag-font-style);
  font-weight: var(--tag-font-weight);
  letter-spacing: var(--tag-letter-spacing);
  line-height: var(--tag-line-height);
  white-space: nowrap;
  position: absolute;
  left: 85px;
}

.recruits .text-wrapper-211 {
  color: #454545;
  font-family: var(--tag-font-family);
  font-size: var(--tag-font-size);
  font-style: var(--tag-font-style);
  font-weight: var(--tag-font-weight);
  letter-spacing: var(--tag-letter-spacing);
  line-height: var(--tag-line-height);
  white-space: nowrap;
  position: absolute;
  left: 113px;
}

.recruits .text-wrapper-212 {
  color: #454545;
  font-family: var(--tag-font-family);
  font-size: var(--tag-font-size);
  font-style: var(--tag-font-style);
  font-weight: var(--tag-font-weight);
  letter-spacing: var(--tag-letter-spacing);
  line-height: var(--tag-line-height);
  white-space: nowrap;
  position: absolute;
  left: 141px;
}

.recruits .text-wrapper-213 {
  color: #454545;
  font-family: var(--tag-font-family);
  font-size: var(--tag-font-size);
  font-style: var(--tag-font-style);
  font-weight: var(--tag-font-weight);
  letter-spacing: var(--tag-letter-spacing);
  line-height: var(--tag-line-height);
  white-space: nowrap;
  position: absolute;
  left: 169px;
}

.recruits .class-83 {
  width: 10px !important;
  height: 16px !important;
  position: absolute !important;
  top: 0 !important;
  left: 197px !important;
}

.recruits .class-84 {
  width: 10px !important;
  height: 17px !important;
  position: absolute !important;
  top: 0 !important;
  left: 197px !important;
}

.recruits .class-85 {
  width: 10px !important;
  height: 16px !important;
  position: absolute !important;
  top: 0 !important;
  left: 0 !important;
}

.recruits .class-86 {
  width: 10px !important;
  height: 17px !important;
  position: absolute !important;
  top: 0 !important;
  left: -1px !important;
}

.recruits .CONTACT-14 {
  background-color: var(--backwhite);
  flex-direction: column;
  flex: none;
  align-items: center;
  gap: 40px;
  display: flex;
  position: relative;
}

.recruits .CONTACT-15 {
  height: 160px;
  display: block;
  position: relative;
}

.recruits .frame-210 {
  background-color: var(--purple);
  border-radius: 6px;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  max-width: 700px;
  display: flex;
  position: relative;
}

.recruits .group-29 {
  width: 216px;
  position: relative;
}

.recruits .text-wrapper-214 {
  color: var(--white);
  letter-spacing: 2px;
  text-align: center;
  width: 212px;
  font-family: Zen Kaku Gothic New, Helvetica;
  font-size: 20px;
  font-weight: 500;
  line-height: normal;
  position: absolute;
  top: 0;
  left: 0;
}

.recruits .text-wrapper-215 {
  color: var(--white);
  letter-spacing: 0;
  text-align: center;
  width: 134px;
  font-family: Slackside One, Helvetica;
  font-size: 15px;
  font-weight: 400;
  line-height: normal;
  position: absolute;
  top: 31px;
  left: 39px;
}

.recruits .overlap-group-8 {
  width: 212px;
  height: 60px;
  position: relative;
}

.recruits .text-wrapper-216 {
  color: var(--white);
  letter-spacing: 2.4px;
  text-align: center;
  width: 212px;
  font-family: Zen Kaku Gothic New, Helvetica;
  font-size: 24px;
  font-weight: 500;
  line-height: normal;
  position: absolute;
  top: 0;
  left: 0;
}

.recruits .text-wrapper-217 {
  color: var(--white);
  letter-spacing: 0;
  text-align: center;
  width: 134px;
  font-family: Slackside One, Helvetica;
  font-size: 20px;
  font-weight: 400;
  line-height: normal;
  position: absolute;
  top: 31px;
  left: 39px;
}

.recruits .group-30 {
  position: relative;
}

.recruits .flexcontainer-24 {
  flex-direction: column;
  align-items: flex-start;
  display: flex;
  position: relative;
}

.recruits .text-i-23, .recruits .text-i-24 {
  color: var(--white);
  align-self: stretch;
  position: relative;
}

.recruits .footer-8 {
  background-color: #454545;
  flex-direction: column;
  flex: none;
  align-self: stretch;
  align-items: center;
  gap: 50px;
  width: 100%;
  display: flex;
  position: relative;
}

.recruits .frame-211 {
  flex: none;
  align-items: flex-start;
  display: flex;
  position: relative;
}

.recruits .frame-212 {
  flex-direction: column;
  align-items: flex-start;
  display: flex;
  position: relative;
}

.recruits .logo-white-28 {
  width: 121px !important;
  height: 40px !important;
  position: relative !important;
}

.recruits .logo-white-24 {
  width: 163.65px !important;
  height: 54.26px !important;
  position: relative !important;
}

.recruits .flexcontainer-25 {
  flex-direction: column;
  align-items: flex-start;
  display: flex;
}

.recruits .text-i-25, .recruits .text-i-26 {
  color: #fff;
  align-self: stretch;
  position: relative;
}

.recruits .frame-213 {
  flex: none;
  align-items: flex-start;
  position: relative;
}

.recruits .frame-214, .recruits .frame-215 {
  flex-direction: column;
  flex: none;
  align-items: flex-start;
  display: inline-flex;
  position: relative;
}

.recruits .text-wrapper-218 {
  color: #fff;
  white-space: nowrap;
  width: fit-content;
  margin-top: -1px;
  display: block;
  position: relative;
}

.recruits .text-wrapper-219, .recruits .text-wrapper-220, .recruits .text-wrapper-221 {
  color: #fff;
  white-space: nowrap;
  width: fit-content;
  position: relative;
}

.recruits .frame-216 {
  flex-direction: column;
  flex: none;
  align-items: flex-start;
  display: inline-flex;
  position: relative;
}

.recruits .text-wrapper-222 {
  color: #fff;
  white-space: nowrap;
  width: fit-content;
  margin-top: -1px;
  display: block;
  position: relative;
}

.recruits .text-wrapper-223, .recruits .text-wrapper-224 {
  color: #fff;
  white-space: nowrap;
  width: fit-content;
  position: relative;
}

.recruits .frame-217 {
  flex-direction: column;
  flex: none;
  align-items: flex-start;
  display: inline-flex;
  position: relative;
}

.recruits .text-wrapper-225 {
  color: #fff;
  white-space: nowrap;
  width: fit-content;
  margin-top: -1px;
  display: block;
  position: relative;
}

.recruits .text-wrapper-226, .recruits .text-wrapper-227 {
  color: #fff;
  white-space: nowrap;
  width: fit-content;
  position: relative;
}

.recruits .frame-218 {
  flex-direction: column;
  align-items: flex-start;
  gap: 30px;
  position: relative;
}

.recruits .text-wrapper-228 {
  color: #fff;
  white-space: nowrap;
  width: fit-content;
  margin-top: -1px;
  display: block;
  position: relative;
}

.recruits .text-wrapper-229, .recruits .text-wrapper-230, .recruits .text-wrapper-231, .recruits .text-wrapper-232 {
  color: #fff;
  white-space: nowrap;
  width: fit-content;
  display: block;
  position: relative;
}

.recruits .text-wrapper-233 {
  color: #fff;
  letter-spacing: 1.31px;
  width: fit-content;
  font-family: Manrope, Helvetica;
  font-size: 13.1px;
  font-weight: 400;
  line-height: normal;
  position: relative;
}

.menuopen-TAB {
  justify-content: flex-end;
  align-items: flex-start;
  gap: 10px;
  width: 100%;
  min-width: 744px;
  height: 1133px;
  display: flex;
  position: relative;
  overflow-x: hidden;
}

.menuopen-TAB .menu-wrapper-2 {
  justify-content: flex-end;
  align-items: flex-start;
  gap: 10px;
  width: 744px;
  height: 1133px;
  display: flex;
}

.menuopen-TAB .menu-7 {
  background-color: var(--purple);
  flex-direction: column;
  justify-content: center;
  align-self: stretch;
  align-items: flex-end;
  gap: 10px;
  width: 512px;
  padding: 150px 60px;
  display: flex;
  position: relative;
}

.menuopen-TAB .frame-219 {
  flex-direction: column;
  flex: none;
  align-items: flex-start;
  gap: 50px;
  margin-left: -13px;
  display: inline-flex;
  position: relative;
}

.menuopen-TAB .text-wrapper-234 {
  color: #fff;
  letter-spacing: 1px;
  width: fit-content;
  margin-top: -1px;
  font-family: Noto Sans JP, Helvetica;
  font-size: 20px;
  font-weight: 700;
  line-height: normal;
  position: relative;
}

.menuopen-TAB .frame-220 {
  flex: none;
  align-items: flex-start;
  gap: 80px;
  display: inline-flex;
  position: relative;
}

.menuopen-TAB .frame-221 {
  flex-direction: column;
  flex: none;
  align-items: flex-start;
  gap: 50px;
  display: inline-flex;
  position: relative;
}

.menuopen-TAB .frame-222 {
  flex-direction: column;
  flex: none;
  align-items: flex-start;
  gap: 10px;
  display: inline-flex;
  position: relative;
}

.menuopen-TAB .frame-223 {
  flex-direction: column;
  flex: none;
  align-items: flex-start;
  display: inline-flex;
  position: relative;
}

.menuopen-TAB .text-wrapper-235 {
  color: #fff;
  letter-spacing: .8px;
  white-space: nowrap;
  width: fit-content;
  margin-top: -1px;
  font-family: Noto Sans JP, Helvetica;
  font-size: 16px;
  font-weight: 700;
  line-height: 30px;
  position: relative;
}

.menuopen-TAB .text-wrapper-236 {
  color: #fff;
  letter-spacing: .8px;
  white-space: nowrap;
  width: fit-content;
  font-family: Noto Sans JP, Helvetica;
  font-size: 16px;
  font-weight: 700;
  line-height: 30px;
  position: relative;
}

.menuopen-TAB .text-wrapper-237 {
  color: #fff;
  letter-spacing: 1px;
  width: fit-content;
  margin-top: -1px;
  font-family: Noto Sans JP, Helvetica;
  font-size: 20px;
  font-weight: 700;
  line-height: normal;
  display: block;
  position: relative;
}

.menuopen-TAB .frame-224 {
  flex-direction: column;
  flex: none;
  align-items: flex-start;
  gap: 20px;
  display: inline-flex;
  position: relative;
}

.menuopen-TAB .text-wrapper-238 {
  color: #fff;
  letter-spacing: .8px;
  width: fit-content;
  margin-top: -1px;
  font-family: Noto Sans JP, Helvetica;
  font-size: 16px;
  font-weight: 700;
  line-height: normal;
  position: relative;
}

.menuopen-TAB .text-wrapper-239 {
  color: #fff;
  letter-spacing: .8px;
  width: fit-content;
  font-family: Noto Sans JP, Helvetica;
  font-size: 16px;
  font-weight: 700;
  line-height: normal;
  position: relative;
}

.menuopen-TAB .button-15 {
  flex: none !important;
  width: 200px !important;
}

.menuopen-TAB .button-16 {
  width: 7.41px !important;
  height: 11.13px !important;
  margin-left: -1.65px !important;
  margin-right: -1.15px !important;
  position: relative !important;
}

.menuopen-TAB .menu-17 {
  width: 36px !important;
  height: 35px !important;
  position: absolute !important;
  top: 0 !important;
  left: 476px !important;
}

.line-wrapper {
  width: 80px;
  height: 1px;
  position: relative;
}

.line-wrapper .line-4 {
  width: 80px;
  height: 2px;
  position: absolute;
  top: -2px;
  left: 0;
}

.philosophy {
  background-color: var(--backwhite);
  flex-direction: column;
  align-items: center;
  display: flex;
  position: relative;
}

.philosophy .header-20 {
  z-index: 1 !important;
  align-self: stretch !important;
  width: 100% !important;
}

.philosophy .menu-24-instance {
  width: 36px !important;
  height: 35px !important;
  margin-bottom: -10px !important;
  position: relative !important;
}

.philosophy .header-21 {
  z-index: 1 !important;
  flex: none !important;
  justify-content: space-between !important;
  align-self: stretch !important;
  width: 100% !important;
  display: flex !important;
}

.philosophy .header-22 {
  z-index: 1 !important;
}

.philosophy .header-23 {
  z-index: 0 !important;
  width: 60px !important;
  height: 59px !important;
  position: relative !important;
}

.philosophy .div-17 {
  z-index: 0;
  background-color: #fff;
  flex-direction: column;
  flex: none;
  align-self: stretch;
  align-items: flex-start;
  width: 100%;
  display: flex;
  position: relative;
}

.philosophy .midashi-4 {
  flex-direction: column;
  flex: none;
  align-self: stretch;
  align-items: flex-start;
  width: 100%;
  display: flex;
  position: relative;
}

.philosophy .class-87 {
  color: #fff !important;
  left: 34px !important;
}

.philosophy .class-88 {
  color: #fff !important;
  left: -17px !important;
}

.philosophy .class-89 {
  color: #fff !important;
  left: 20px !important;
}

.philosophy .class-90 {
  color: #fff !important;
  left: 23px !important;
}

.philosophy .class-91 {
  flex: none !important;
  align-self: stretch !important;
  width: 100% !important;
  padding: 5px 0 !important;
  display: flex !important;
}

.philosophy .class-92 {
  flex: none !important;
  align-self: stretch !important;
  width: 100% !important;
}

.philosophy .breadcrumbs-7 {
  flex: none;
  justify-content: center;
  align-self: stretch;
  align-items: flex-start;
  gap: 30px;
  width: 100%;
  display: flex;
  position: relative;
}

.philosophy .frame-225 {
  flex: 1;
  align-items: center;
  display: flex;
  position: relative;
}

.philosophy .text-wrapper-240 {
  color: var(--gray);
  font-family: var(--smalltext-SP-font-family);
  font-size: var(--smalltext-SP-font-size);
  font-style: var(--smalltext-SP-font-style);
  font-weight: var(--smalltext-SP-font-weight);
  letter-spacing: var(--smalltext-SP-letter-spacing);
  line-height: var(--smalltext-SP-line-height);
  white-space: nowrap;
  width: fit-content;
  margin-top: -1px;
  display: block;
  position: relative;
}

.philosophy .instance-node-7 {
  width: 4.57px !important;
  height: 8.14px !important;
  position: relative !important;
}

.philosophy .TOP-3 {
  color: var(--gray);
  white-space: nowrap;
  width: fit-content;
  margin-top: -1px;
  position: relative;
}

.philosophy .text-wrapper-241 {
  color: var(--gray);
  font-family: var(--tag-font-family);
  font-size: var(--tag-font-size);
  font-style: var(--tag-font-style);
  font-weight: var(--tag-font-weight);
  letter-spacing: var(--tag-letter-spacing);
  line-height: var(--tag-line-height);
  white-space: nowrap;
  width: fit-content;
  margin-top: -1px;
  position: relative;
}

.philosophy .corporate {
  background-position: 50%;
  background-size: cover;
  flex-direction: column;
  flex: none;
  align-self: stretch;
  align-items: center;
  gap: 40px;
  width: 100%;
  display: flex;
  position: relative;
}

.philosophy .frame-226 {
  opacity: .9;
  background-color: #fff;
  border-radius: 10px;
  flex-direction: column;
  flex: none;
  align-items: center;
  position: relative;
}

.philosophy .class-93 {
  left: 12px !important;
}

.philosophy .class-94 {
  left: -87px !important;
}

.philosophy .heading-28 {
  position: relative !important;
}

.philosophy .class-95 {
  left: -15px !important;
}

.philosophy .class-96 {
  left: -10px !important;
}

.philosophy .flexcontainer-26 {
  flex-direction: column;
  align-items: flex-start;
  gap: 2px;
}

.philosophy .text-10, .philosophy .text-11, .philosophy .text-12, .philosophy .text-13 {
  color: #454545;
  align-self: stretch;
  font-family: Noto Sans JP, Helvetica;
  font-weight: 700;
  position: relative;
}

.philosophy .flexcontainer-27 {
  flex-direction: column;
  align-items: flex-start;
}

.philosophy .text-14, .philosophy .text-15, .philosophy .text-16 {
  color: var(--darkgray);
  align-self: stretch;
  position: relative;
}

.philosophy .management {
  background-color: var(--palegray);
  flex-direction: column;
  flex: none;
  align-self: stretch;
  align-items: center;
  width: 100%;
  display: flex;
  position: relative;
}

.philosophy .class-97 {
  left: -104px !important;
}

.philosophy .heading-29 {
  position: relative !important;
}

.philosophy .class-98 {
  left: -24px !important;
}

.philosophy .text-wrapper-242 {
  color: #454545;
  text-align: center;
  width: fit-content;
  position: relative;
}

.philosophy .flexcontainer-28 {
  flex-direction: column;
  align-items: flex-start;
}

.philosophy .text-17, .philosophy .text-18 {
  color: #454545;
  align-self: stretch;
  position: relative;
}

.philosophy .ill {
  position: relative;
}

.philosophy .motto {
  background-color: var(--backwhite);
  flex-direction: column;
  flex: none;
  align-self: stretch;
  align-items: center;
  width: 100%;
  display: flex;
  position: relative;
}

.philosophy .class-99 {
  left: 34px !important;
}

.philosophy .class-100 {
  left: 16px !important;
}

.philosophy .heading-30 {
  position: relative !important;
}

.philosophy .class-101 {
  left: 36px !important;
}

.philosophy .class-102 {
  left: 23px !important;
}

.philosophy .mottos {
  flex-direction: column;
  flex: none;
  align-items: flex-start;
  display: flex;
  position: relative;
}

.philosophy .frame-227 {
  flex-direction: column;
  flex: none;
  align-self: stretch;
  align-items: flex-start;
  gap: 15px;
  width: 100%;
  display: flex;
  position: relative;
}

.philosophy .text-wrapper-243 {
  align-self: stretch;
  margin-top: -1px;
  position: relative;
}

.philosophy .line-instance {
  width: 90px !important;
}

.philosophy .text-wrapper-244 {
  align-self: stretch;
  position: relative;
}

.philosophy .text-wrapper-245 {
  align-self: stretch;
  margin-top: -1px;
  position: relative;
}

.philosophy .text-wrapper-246 {
  align-self: stretch;
  position: relative;
}

.philosophy .text-wrapper-247 {
  align-self: stretch;
  margin-top: -1px;
  position: relative;
}

.philosophy .text-wrapper-248 {
  align-self: stretch;
  position: relative;
}

.philosophy .text-wrapper-249 {
  align-self: stretch;
  margin-top: -1px;
  position: relative;
}

.philosophy .text-wrapper-250 {
  align-self: stretch;
  position: relative;
}

.philosophy .text-wrapper-251 {
  align-self: stretch;
  margin-top: -1px;
  position: relative;
}

.philosophy .text-wrapper-252 {
  align-self: stretch;
  position: relative;
}

.philosophy .text-wrapper-253 {
  color: var(--darkgray);
  align-self: stretch;
  margin-top: -1px;
  position: relative;
}

.philosophy .text-wrapper-254 {
  align-self: stretch;
  position: relative;
}

.philosophy .CONTACT-16 {
  background-color: var(--backwhite);
  flex-direction: column;
  flex: none;
  align-items: center;
  gap: 40px;
  display: flex;
  position: relative;
}

.philosophy .CONTACT-17 {
  height: 160px;
  display: block;
  position: relative;
}

.philosophy .frame-228 {
  background-color: var(--purple);
  border-radius: 6px;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  max-width: 700px;
  display: flex;
  position: relative;
}

.philosophy .group-31 {
  width: 216px;
  position: relative;
}

.philosophy .text-wrapper-255 {
  color: var(--white);
  letter-spacing: 2px;
  text-align: center;
  width: 212px;
  font-family: Zen Kaku Gothic New, Helvetica;
  font-size: 20px;
  font-weight: 500;
  line-height: normal;
  position: absolute;
  top: 0;
  left: 0;
}

.philosophy .text-wrapper-256 {
  color: var(--white);
  letter-spacing: 0;
  text-align: center;
  width: 134px;
  font-family: Slackside One, Helvetica;
  font-size: 15px;
  font-weight: 400;
  line-height: normal;
  position: absolute;
  top: 31px;
  left: 39px;
}

.philosophy .overlap-group-9 {
  width: 212px;
  height: 60px;
  position: relative;
}

.philosophy .text-wrapper-257 {
  color: var(--white);
  letter-spacing: 2.4px;
  text-align: center;
  width: 212px;
  font-family: Zen Kaku Gothic New, Helvetica;
  font-size: 24px;
  font-weight: 500;
  line-height: normal;
  position: absolute;
  top: 0;
  left: 0;
}

.philosophy .text-wrapper-258 {
  color: var(--white);
  letter-spacing: 0;
  text-align: center;
  width: 134px;
  font-family: Slackside One, Helvetica;
  font-size: 20px;
  font-weight: 400;
  line-height: normal;
  position: absolute;
  top: 31px;
  left: 39px;
}

.philosophy .group-32 {
  position: relative;
}

.philosophy .flexcontainer-29 {
  flex-direction: column;
  align-items: flex-start;
  display: flex;
  position: relative;
}

.philosophy .text-i-27, .philosophy .text-i-28 {
  color: var(--white);
  align-self: stretch;
  position: relative;
}

.philosophy .footer-9 {
  background-color: #454545;
  flex-direction: column;
  flex: none;
  align-self: stretch;
  align-items: center;
  gap: 50px;
  width: 100%;
  display: flex;
  position: relative;
}

.philosophy .frame-229 {
  flex: none;
  align-items: flex-start;
  display: flex;
  position: relative;
}

.philosophy .frame-230 {
  flex-direction: column;
  align-items: flex-start;
  display: flex;
  position: relative;
}

.philosophy .logo-white-15 {
  width: 121px !important;
  height: 40px !important;
  position: relative !important;
}

.philosophy .logo-white-5 {
  width: 163.65px !important;
  height: 54.26px !important;
  position: relative !important;
}

.philosophy .flexcontainer-30 {
  flex-direction: column;
  align-items: flex-start;
  display: flex;
}

.philosophy .text-i-29, .philosophy .text-i-30 {
  color: #fff;
  align-self: stretch;
  position: relative;
}

.philosophy .frame-231 {
  flex: none;
  align-items: flex-start;
  position: relative;
}

.philosophy .frame-232, .philosophy .frame-233 {
  flex-direction: column;
  flex: none;
  align-items: flex-start;
  display: inline-flex;
  position: relative;
}

.philosophy .text-wrapper-259 {
  color: #fff;
  white-space: nowrap;
  width: fit-content;
  margin-top: -1px;
  display: block;
  position: relative;
}

.philosophy .text-wrapper-260, .philosophy .text-wrapper-261, .philosophy .text-wrapper-262 {
  color: #fff;
  white-space: nowrap;
  width: fit-content;
  position: relative;
}

.philosophy .frame-234 {
  flex-direction: column;
  flex: none;
  align-items: flex-start;
  display: inline-flex;
  position: relative;
}

.philosophy .text-wrapper-263 {
  color: #fff;
  white-space: nowrap;
  width: fit-content;
  margin-top: -1px;
  display: block;
  position: relative;
}

.philosophy .text-wrapper-264, .philosophy .text-wrapper-265 {
  color: #fff;
  white-space: nowrap;
  width: fit-content;
  position: relative;
}

.philosophy .frame-235 {
  flex-direction: column;
  flex: none;
  align-items: flex-start;
  display: inline-flex;
  position: relative;
}

.philosophy .text-wrapper-266 {
  color: #fff;
  white-space: nowrap;
  width: fit-content;
  margin-top: -1px;
  display: block;
  position: relative;
}

.philosophy .text-wrapper-267, .philosophy .text-wrapper-268 {
  color: #fff;
  white-space: nowrap;
  width: fit-content;
  position: relative;
}

.philosophy .frame-236 {
  flex-direction: column;
  align-items: flex-start;
  gap: 30px;
  position: relative;
}

.philosophy .text-wrapper-269 {
  color: #fff;
  white-space: nowrap;
  width: fit-content;
  margin-top: -1px;
  display: block;
  position: relative;
}

.philosophy .text-wrapper-270, .philosophy .text-wrapper-271, .philosophy .text-wrapper-272, .philosophy .text-wrapper-273 {
  color: #fff;
  white-space: nowrap;
  width: fit-content;
  display: block;
  position: relative;
}

.philosophy .text-wrapper-274 {
  color: #fff;
  letter-spacing: 1.31px;
  width: fit-content;
  font-family: Manrope, Helvetica;
  font-size: 13.1px;
  font-weight: 400;
  line-height: normal;
  position: relative;
}

.grayline-wrapper {
  background-image: url("line-13-5.a4f10b12.svg");
  background-position: 50%;
  background-size: cover;
  width: 358px;
  height: 1px;
  position: relative;
  top: -1px;
}

.profile {
  background-color: var(--backwhite);
  flex-direction: column;
  align-items: center;
  display: flex;
  position: relative;
}

.profile .header-24 {
  z-index: 1 !important;
  align-self: stretch !important;
  width: 100% !important;
}

.profile .menu-8 {
  width: 36px !important;
  height: 35px !important;
  margin-bottom: -10px !important;
  position: relative !important;
}

.profile .header-25 {
  z-index: 1 !important;
  flex: none !important;
  justify-content: space-between !important;
  align-self: stretch !important;
  width: 100% !important;
  display: flex !important;
}

.profile .header-26 {
  z-index: 1 !important;
}

.profile .header-27 {
  z-index: 0 !important;
  width: 60px !important;
  height: 59px !important;
  position: relative !important;
}

.profile .div-18 {
  z-index: 0;
  background-color: #fff;
  flex-direction: column;
  flex: none;
  align-self: stretch;
  align-items: flex-start;
  width: 100%;
  display: flex;
  position: relative;
}

.profile .midashi-5 {
  flex-direction: column;
  flex: none;
  align-self: stretch;
  align-items: flex-start;
  width: 100%;
  display: flex;
  position: relative;
}

.profile .class-103 {
  color: #fff !important;
  left: 12px !important;
}

.profile .class-104 {
  color: #fff !important;
  left: 9px !important;
}

.profile .class-105 {
  color: #fff !important;
  left: 32px !important;
}

.profile .class-106 {
  color: #fff !important;
  left: -10px !important;
}

.profile .class-107 {
  flex: none !important;
  align-self: stretch !important;
  width: 100% !important;
  padding: 5px 0 !important;
  display: flex !important;
}

.profile .class-108 {
  flex: none !important;
  align-self: stretch !important;
  width: 100% !important;
}

.profile .breadcrumbs-8 {
  flex: none;
  justify-content: center;
  align-self: stretch;
  align-items: flex-start;
  gap: 30px;
  width: 100%;
  display: flex;
  position: relative;
}

.profile .frame-237 {
  flex: 1;
  align-items: center;
  display: flex;
  position: relative;
}

.profile .text-wrapper-275 {
  color: var(--gray);
  font-family: var(--smalltext-SP-font-family);
  font-size: var(--smalltext-SP-font-size);
  font-style: var(--smalltext-SP-font-style);
  font-weight: var(--smalltext-SP-font-weight);
  letter-spacing: var(--smalltext-SP-letter-spacing);
  line-height: var(--smalltext-SP-line-height);
  white-space: nowrap;
  width: fit-content;
  margin-top: -1px;
  display: block;
  position: relative;
}

.profile .instance-node-8 {
  width: 4.57px !important;
  height: 8.14px !important;
  position: relative !important;
}

.profile .TOP-4 {
  color: var(--gray);
  white-space: nowrap;
  width: fit-content;
  margin-top: -1px;
  position: relative;
}

.profile .text-wrapper-276 {
  color: var(--gray);
  font-family: var(--tag-font-family);
  font-size: var(--tag-font-size);
  font-style: var(--tag-font-style);
  font-weight: var(--tag-font-weight);
  letter-spacing: var(--tag-letter-spacing);
  line-height: var(--tag-line-height);
  white-space: nowrap;
  width: fit-content;
  margin-top: -1px;
  position: relative;
}

.profile .greeting {
  background-color: var(--backwhite);
  flex-direction: column;
  flex: none;
  align-self: stretch;
  align-items: center;
  width: 100%;
  display: flex;
  position: relative;
}

.profile .class-109 {
  left: 12px !important;
}

.profile .heading-31 {
  position: relative !important;
}

.profile .class-110 {
  left: -10px !important;
}

.profile .flexcontainer-31 {
  flex-direction: column;
  align-items: flex-start;
  gap: 2px;
}

.profile .text-19, .profile .text-20, .profile .text-21 {
  color: #454545;
  text-align: center;
  align-self: stretch;
  position: relative;
}

.profile .members {
  background-color: var(--backwhite);
  flex-direction: column;
  flex: none;
  align-self: stretch;
  align-items: center;
  width: 100%;
  display: flex;
  position: relative;
}

.profile .class-111 {
  left: 3px !important;
}

.profile .heading-32 {
  position: relative !important;
}

.profile .class-112 {
  left: -44px !important;
}

.profile .frame-238 {
  flex-direction: column;
  flex: none;
  align-items: center;
  gap: 40px;
  position: relative;
}

.profile .member {
  flex: none;
  display: flex;
  position: relative;
}

.profile .rectangle-5 {
  position: relative;
}

.profile .frame-239 {
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
  display: flex;
  position: relative;
}

.profile .frame-240 {
  flex-direction: column;
  flex: none;
  align-items: flex-start;
  gap: 12px;
  display: inline-flex;
  position: relative;
}

.profile .text-wrapper-277 {
  color: var(--black);
  width: fit-content;
  margin-top: -1px;
  position: relative;
}

.profile .text-wrapper-278 {
  color: #454545;
  white-space: nowrap;
  width: fit-content;
  position: relative;
}

.profile .frame-241 {
  flex-direction: column;
  flex: none;
  align-self: stretch;
  align-items: flex-start;
  width: 100%;
  display: flex;
  position: relative;
}

.profile .frame-242 {
  flex-wrap: wrap;
  flex: none;
  align-self: stretch;
  align-items: flex-start;
  width: 100%;
  display: flex;
  position: relative;
}

.profile .text-wrapper-279 {
  color: var(--darkgray);
  white-space: nowrap;
  width: fit-content;
  margin-top: -1px;
  position: relative;
}

.profile .text-wrapper-280 {
  color: var(--darkgray);
  flex: 1;
  margin-top: -1px;
  position: relative;
}

.profile .frame-243 {
  flex-wrap: wrap;
  flex: none;
  align-self: stretch;
  align-items: flex-start;
  width: 100%;
  display: flex;
  position: relative;
}

.profile .text-wrapper-281 {
  color: var(--darkgray);
  white-space: nowrap;
  width: fit-content;
  margin-top: -1px;
  position: relative;
}

.profile .text-wrapper-282 {
  color: var(--darkgray);
  flex: 1;
  margin-top: -1px;
  position: relative;
}

.profile .frame-244 {
  flex-wrap: wrap;
  flex: none;
  align-self: stretch;
  align-items: flex-start;
  width: 100%;
  display: flex;
  position: relative;
}

.profile .text-wrapper-283 {
  color: var(--darkgray);
  white-space: nowrap;
  width: fit-content;
  margin-top: -1px;
  position: relative;
}

.profile .text-wrapper-284 {
  color: var(--darkgray);
  flex: 1;
  margin-top: -1px;
  position: relative;
}

.profile .frame-245 {
  flex-wrap: wrap;
  flex: none;
  align-self: stretch;
  align-items: flex-start;
  width: 100%;
  display: flex;
  position: relative;
}

.profile .text-wrapper-285 {
  color: var(--darkgray);
  white-space: nowrap;
  width: fit-content;
  margin-top: -1px;
  position: relative;
}

.profile .text-wrapper-286 {
  color: var(--darkgray);
  flex: 1;
  margin-top: -1px;
  position: relative;
}

.profile .line-13 {
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 10px;
  height: 1px;
  display: flex;
  position: relative;
}

.profile .grayline-4 {
  align-self: stretch;
  width: 100%;
  height: 1px;
  margin-bottom: -.5px;
  position: relative;
}

.profile .line-14 {
  object-fit: cover;
  width: 280px;
  height: 1px;
  position: absolute;
  top: -1px;
  left: 340px;
}

.profile .class-113 {
  top: unset !important;
  background-image: url("line-13-3-2.181fd52c.svg") !important;
  align-self: stretch !important;
  width: 100% !important;
}

.profile .class-114 {
  top: unset !important;
  background-image: url("line-13-8.b3323a70.svg") !important;
  align-self: stretch !important;
  width: 100% !important;
  margin-top: -.5px !important;
}

.profile .member-2 {
  flex: none;
  display: flex;
  position: relative;
}

.profile .rectangle-6 {
  position: relative;
}

.profile .frame-246 {
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
  display: flex;
  position: relative;
}

.profile .text-wrapper-287 {
  color: var(--black);
  width: fit-content;
  margin-top: -1px;
  position: relative;
}

.profile .text-wrapper-288 {
  color: #454545;
  white-space: nowrap;
  width: fit-content;
  position: relative;
}

.profile .frame-247 {
  flex-direction: column;
  flex: none;
  align-self: stretch;
  align-items: flex-start;
  width: 100%;
  display: flex;
  position: relative;
}

.profile .frame-248 {
  flex-wrap: wrap;
  flex: none;
  align-self: stretch;
  align-items: flex-start;
  width: 100%;
  display: flex;
  position: relative;
}

.profile .text-wrapper-289 {
  color: var(--darkgray);
  white-space: nowrap;
  width: fit-content;
  margin-top: -1px;
  position: relative;
}

.profile .text-wrapper-290 {
  color: var(--darkgray);
  flex: 1;
  margin-top: -1px;
  position: relative;
}

.profile .frame-249 {
  flex-wrap: wrap;
  flex: none;
  align-self: stretch;
  align-items: flex-start;
  width: 100%;
  display: flex;
  position: relative;
}

.profile .text-wrapper-291 {
  color: var(--darkgray);
  white-space: nowrap;
  width: fit-content;
  margin-top: -1px;
  position: relative;
}

.profile .text-wrapper-292 {
  color: var(--darkgray);
  flex: 1;
  margin-top: -1px;
  position: relative;
}

.profile .frame-250 {
  flex-wrap: wrap;
  flex: none;
  align-self: stretch;
  align-items: flex-start;
  width: 100%;
  display: flex;
  position: relative;
}

.profile .text-wrapper-293 {
  color: var(--darkgray);
  white-space: nowrap;
  width: fit-content;
  margin-top: -1px;
  position: relative;
}

.profile .text-wrapper-294 {
  color: var(--darkgray);
  flex: 1;
  margin-top: -1px;
  position: relative;
}

.profile .frame-251 {
  flex-wrap: wrap;
  flex: none;
  align-self: stretch;
  align-items: flex-start;
  width: 100%;
  display: flex;
  position: relative;
}

.profile .text-wrapper-295 {
  color: var(--darkgray);
  white-space: nowrap;
  width: fit-content;
  margin-top: -1px;
  position: relative;
}

.profile .text-wrapper-296 {
  color: var(--darkgray);
  flex: 1;
  margin-top: -1px;
  position: relative;
}

.profile .line-15 {
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 10px;
  height: 1px;
  display: flex;
  position: relative;
}

.profile .member-3 {
  flex: none;
  display: flex;
  position: relative;
}

.profile .rectangle-7 {
  position: relative;
}

.profile .frame-252 {
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
  display: flex;
  position: relative;
}

.profile .text-wrapper-297 {
  color: var(--black);
  width: fit-content;
  margin-top: -1px;
  position: relative;
}

.profile .text-wrapper-298 {
  color: #454545;
  white-space: nowrap;
  width: fit-content;
  position: relative;
}

.profile .frame-253 {
  flex-direction: column;
  flex: none;
  align-self: stretch;
  align-items: flex-start;
  width: 100%;
  display: flex;
  position: relative;
}

.profile .frame-254 {
  flex-wrap: wrap;
  flex: none;
  align-self: stretch;
  align-items: flex-start;
  width: 100%;
  display: flex;
  position: relative;
}

.profile .text-wrapper-299 {
  color: var(--darkgray);
  white-space: nowrap;
  width: fit-content;
  margin-top: -1px;
  position: relative;
}

.profile .text-wrapper-300 {
  color: var(--darkgray);
  flex: 1;
  margin-top: -1px;
  position: relative;
}

.profile .frame-255 {
  flex-wrap: wrap;
  flex: none;
  align-self: stretch;
  align-items: flex-start;
  width: 100%;
  display: flex;
  position: relative;
}

.profile .text-wrapper-301 {
  color: var(--darkgray);
  white-space: nowrap;
  width: fit-content;
  margin-top: -1px;
  position: relative;
}

.profile .text-wrapper-302 {
  color: var(--darkgray);
  flex: 1;
  margin-top: -1px;
  position: relative;
}

.profile .frame-256 {
  flex-wrap: wrap;
  flex: none;
  align-self: stretch;
  align-items: flex-start;
  width: 100%;
  display: flex;
  position: relative;
}

.profile .text-wrapper-303 {
  color: var(--darkgray);
  white-space: nowrap;
  width: fit-content;
  margin-top: -1px;
  position: relative;
}

.profile .text-wrapper-304 {
  color: var(--darkgray);
  flex: 1;
  margin-top: -1px;
  position: relative;
}

.profile .frame-257 {
  flex-wrap: wrap;
  flex: none;
  align-self: stretch;
  align-items: flex-start;
  width: 100%;
  display: flex;
  position: relative;
}

.profile .text-wrapper-305 {
  color: var(--darkgray);
  white-space: nowrap;
  width: fit-content;
  margin-top: -1px;
  position: relative;
}

.profile .text-wrapper-306 {
  color: var(--darkgray);
  flex: 1;
  margin-top: -1px;
  position: relative;
}

.profile .CONTACT-18 {
  background-color: var(--backwhite);
  flex-direction: column;
  flex: none;
  align-items: center;
  gap: 40px;
  display: flex;
  position: relative;
}

.profile .CONTACT-19 {
  height: 160px;
  display: block;
  position: relative;
}

.profile .frame-258 {
  background-color: var(--purple);
  border-radius: 6px;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  max-width: 700px;
  display: flex;
  position: relative;
}

.profile .group-33 {
  width: 216px;
  position: relative;
}

.profile .text-wrapper-307 {
  color: var(--white);
  letter-spacing: 2px;
  text-align: center;
  width: 212px;
  font-family: Zen Kaku Gothic New, Helvetica;
  font-size: 20px;
  font-weight: 500;
  line-height: normal;
  position: absolute;
  top: 0;
  left: 0;
}

.profile .text-wrapper-308 {
  color: var(--white);
  letter-spacing: 0;
  text-align: center;
  width: 134px;
  font-family: Slackside One, Helvetica;
  font-size: 15px;
  font-weight: 400;
  line-height: normal;
  position: absolute;
  top: 31px;
  left: 39px;
}

.profile .overlap-group-10 {
  width: 212px;
  height: 60px;
  position: relative;
}

.profile .text-wrapper-309 {
  color: var(--white);
  letter-spacing: 2.4px;
  text-align: center;
  width: 212px;
  font-family: Zen Kaku Gothic New, Helvetica;
  font-size: 24px;
  font-weight: 500;
  line-height: normal;
  position: absolute;
  top: 0;
  left: 0;
}

.profile .text-wrapper-310 {
  color: var(--white);
  letter-spacing: 0;
  text-align: center;
  width: 134px;
  font-family: Slackside One, Helvetica;
  font-size: 20px;
  font-weight: 400;
  line-height: normal;
  position: absolute;
  top: 31px;
  left: 39px;
}

.profile .group-34 {
  position: relative;
}

.profile .flexcontainer-32 {
  flex-direction: column;
  align-items: flex-start;
  display: flex;
  position: relative;
}

.profile .text-i-31, .profile .text-i-32 {
  color: var(--white);
  align-self: stretch;
  position: relative;
}

.profile .footer-10 {
  background-color: #454545;
  flex-direction: column;
  flex: none;
  align-self: stretch;
  align-items: center;
  gap: 50px;
  width: 100%;
  display: flex;
  position: relative;
}

.profile .frame-259 {
  flex: none;
  align-items: flex-start;
  display: flex;
  position: relative;
}

.profile .frame-260 {
  flex-direction: column;
  align-items: flex-start;
  display: flex;
  position: relative;
}

.profile .logo-white-4 {
  width: 121px !important;
  height: 40px !important;
  position: relative !important;
}

.profile .flexcontainer-33 {
  flex-direction: column;
  align-items: flex-start;
  gap: 3px;
  display: flex;
}

.profile .text-i-33 {
  color: #fff;
  letter-spacing: .26px;
  align-self: stretch;
  font-family: Noto Sans JP, Helvetica;
  font-size: 13px;
  font-weight: 400;
  line-height: 18px;
  position: relative;
}

.profile .text-wrapper-311 {
  letter-spacing: .03px;
}

.profile .logo-white-20 {
  width: 163.65px !important;
  height: 54.26px !important;
  position: relative !important;
}

.profile .flexcontainer-34 {
  flex-direction: column;
  align-items: flex-start;
  gap: 5px;
  display: flex;
}

.profile .text-i-34 {
  color: #fff;
  font-family: var(--text-font-family);
  font-size: var(--text-font-size);
  font-style: var(--text-font-style);
  font-weight: var(--text-font-weight);
  letter-spacing: var(--text-letter-spacing);
  line-height: var(--text-line-height);
  align-self: stretch;
  position: relative;
}

.profile .text-wrapper-312 {
  font-family: var(--text-font-family);
  font-size: var(--text-font-size);
  font-style: var(--text-font-style);
  font-weight: var(--text-font-weight);
  letter-spacing: var(--text-letter-spacing);
  line-height: var(--text-line-height);
}

.profile .frame-261 {
  flex: none;
  align-items: flex-start;
  position: relative;
}

.profile .frame-262, .profile .frame-263 {
  flex-direction: column;
  flex: none;
  align-items: flex-start;
  display: inline-flex;
  position: relative;
}

.profile .text-wrapper-313 {
  color: #fff;
  white-space: nowrap;
  width: fit-content;
  margin-top: -1px;
  display: block;
  position: relative;
}

.profile .text-wrapper-314, .profile .text-wrapper-315, .profile .text-wrapper-316 {
  color: #fff;
  white-space: nowrap;
  width: fit-content;
  position: relative;
}

.profile .frame-264 {
  flex-direction: column;
  flex: none;
  align-items: flex-start;
  display: inline-flex;
  position: relative;
}

.profile .text-wrapper-317 {
  color: #fff;
  white-space: nowrap;
  width: fit-content;
  margin-top: -1px;
  display: block;
  position: relative;
}

.profile .text-wrapper-318, .profile .text-wrapper-319 {
  color: #fff;
  white-space: nowrap;
  width: fit-content;
  position: relative;
}

.profile .frame-265 {
  flex-direction: column;
  flex: none;
  align-items: flex-start;
  display: inline-flex;
  position: relative;
}

.profile .text-wrapper-320 {
  color: #fff;
  white-space: nowrap;
  width: fit-content;
  margin-top: -1px;
  display: block;
  position: relative;
}

.profile .text-wrapper-321, .profile .text-wrapper-322 {
  color: #fff;
  white-space: nowrap;
  width: fit-content;
  position: relative;
}

.profile .frame-266 {
  flex-direction: column;
  align-items: flex-start;
  gap: 30px;
  position: relative;
}

.profile .text-wrapper-323 {
  color: #fff;
  white-space: nowrap;
  width: fit-content;
  margin-top: -1px;
  display: block;
  position: relative;
}

.profile .text-wrapper-324, .profile .text-wrapper-325, .profile .text-wrapper-326, .profile .text-wrapper-327 {
  color: #fff;
  white-space: nowrap;
  width: fit-content;
  display: block;
  position: relative;
}

.profile .text-wrapper-328 {
  color: #fff;
  letter-spacing: 1.31px;
  width: fit-content;
  font-family: Manrope, Helvetica;
  font-size: 13.1px;
  font-weight: 400;
  line-height: normal;
  position: relative;
}

.heading-02 {
  flex-direction: column;
  align-items: flex-start;
  gap: 7px;
  width: 880px;
  display: flex;
  position: relative;
}

.heading-02 .text-wrapper-23 {
  color: #454545;
  align-self: stretch;
  margin-top: -1px;
  position: relative;
}

.heading-02 .line-8 {
  flex: none !important;
  align-self: stretch !important;
  width: 100% !important;
  margin-bottom: -1px !important;
  position: relative !important;
}

.heading-02 .one-12-one {
  letter-spacing: 1.44px;
  font-family: Noto Sans JP, Helvetica;
  font-size: 24px;
  font-weight: 700;
  line-height: 43px;
}

.heading-02 .one-12-SP {
  font-family: var(--bold-SP-font-family);
  font-size: var(--bold-SP-font-size);
  font-style: var(--bold-SP-font-style);
  font-weight: var(--bold-SP-font-weight);
  letter-spacing: var(--bold-SP-letter-spacing);
  line-height: var(--bold-SP-line-height);
}

.line-2 {
  width: 80px;
  height: 1px;
  position: relative;
}

.line-2 .line-5 {
  width: 80px;
  height: 2px;
  position: absolute;
  top: -2px;
  left: 0;
}

.privacypolicy {
  background-color: var(--backwhite);
  flex-direction: column;
  align-items: center;
  display: flex;
  position: relative;
}

.privacypolicy .header-28 {
  z-index: 1 !important;
  flex: none !important;
  justify-content: space-between !important;
  align-self: stretch !important;
  width: 100% !important;
  display: flex !important;
}

.privacypolicy .header-29 {
  z-index: 1 !important;
}

.privacypolicy .header-30 {
  z-index: 0 !important;
  width: 60px !important;
  height: 59px !important;
  position: relative !important;
}

.privacypolicy .div-19 {
  z-index: 0;
  background-color: #fff;
  flex-direction: column;
  flex: none;
  align-self: stretch;
  align-items: flex-start;
  width: 100%;
  display: flex;
  position: relative;
}

.privacypolicy .midashi-6 {
  flex-direction: column;
  flex: none;
  align-self: stretch;
  align-items: flex-start;
  width: 100%;
  display: flex;
  position: relative;
}

.privacypolicy .heading-33 {
  flex: none !important;
  align-self: stretch !important;
  width: 100% !important;
}

.privacypolicy .heading-34 {
  color: #fff !important;
  left: -37px !important;
}

.privacypolicy .heading-35 {
  color: #fff !important;
  left: -111px !important;
}

.privacypolicy .breadcrumbs-9 {
  flex: none;
  justify-content: center;
  align-self: stretch;
  align-items: flex-start;
  gap: 30px;
  width: 100%;
  padding: 20px 15px 40px;
  display: flex;
  position: relative;
}

.privacypolicy .frame-267 {
  flex: 1;
  align-items: center;
  gap: 30px;
  padding: 0 50px;
  display: flex;
  position: relative;
}

.privacypolicy .text-wrapper-329 {
  color: var(--gray);
  font-family: var(--tag-font-family);
  font-size: var(--tag-font-size);
  font-style: var(--tag-font-style);
  font-weight: var(--tag-font-weight);
  letter-spacing: var(--tag-letter-spacing);
  line-height: var(--tag-line-height);
  white-space: nowrap;
  width: fit-content;
  margin-top: -1px;
  position: relative;
}

.privacypolicy .instance-12 {
  width: 4.57px !important;
  height: 8.14px !important;
  position: relative !important;
}

.privacypolicy .privacypolicys {
  background-color: var(--palepurple);
  flex-direction: column;
  flex: none;
  align-self: stretch;
  align-items: center;
  gap: 40px;
  width: 100%;
  padding: 80px 15px;
  display: flex;
  position: relative;
}

.privacypolicy .frame-268 {
  background-color: var(--back-white);
  border-radius: 14px;
  flex-direction: column;
  flex: none;
  align-items: flex-start;
  gap: 40px;
  width: 1000px;
  padding: 50px 60px;
  display: flex;
  position: relative;
}

.privacypolicy .frame-269 {
  flex-direction: column;
  flex: none;
  align-self: stretch;
  align-items: flex-start;
  gap: 24px;
  width: 100%;
  display: flex;
  position: relative;
}

.privacypolicy .heading-02-instance {
  flex: none !important;
}

.privacypolicy .div-20 {
  color: #454545;
  letter-spacing: .75px;
  align-self: stretch;
  font-family: Noto Sans JP, Helvetica;
  font-size: 15px;
  font-weight: 400;
  line-height: 25px;
  position: relative;
}

.privacypolicy .frame-270 {
  flex-direction: column;
  flex: none;
  align-self: stretch;
  align-items: flex-start;
  gap: 5px;
  width: 100%;
  display: flex;
  position: relative;
}

.privacypolicy .bulletpoint {
  flex-wrap: wrap;
  flex: none;
  align-self: stretch;
  align-items: flex-start;
  gap: 5px;
  width: 100%;
  display: flex;
  position: relative;
}

.privacypolicy .group-35 {
  width: 17px;
  height: 28px;
  position: relative;
}

.privacypolicy .ellipse-4 {
  background-color: #6079c1;
  border-radius: 5px;
  width: 10px;
  height: 10px;
  position: relative;
  top: 9px;
  left: 3px;
}

.privacypolicy .text-wrapper-330 {
  color: #454545;
  font-family: var(--text-font-family);
  font-size: var(--text-font-size);
  font-style: var(--text-font-style);
  font-weight: var(--text-font-weight);
  letter-spacing: var(--text-letter-spacing);
  line-height: var(--text-line-height);
  flex: 1;
  margin-top: -1px;
  position: relative;
}

.privacypolicy .flexcontainer-35 {
  flex-direction: column;
  align-items: flex-start;
  gap: 5px;
  display: flex;
}

.privacypolicy .text-wrapper-331 {
  letter-spacing: .11px;
}

.privacypolicy .text-22 {
  color: #0000;
  letter-spacing: .75px;
  align-self: stretch;
  font-family: Noto Sans JP, Helvetica;
  font-size: 15px;
  font-weight: 400;
  line-height: 25px;
  position: relative;
}

.privacypolicy .text-wrapper-332 {
  color: #454545;
  letter-spacing: .11px;
}

.privacypolicy .text-wrapper-333 {
  color: #d45252;
  letter-spacing: .11px;
}

.privacypolicy .frame-271 {
  flex-direction: column;
  flex: none;
  align-self: stretch;
  align-items: flex-start;
  gap: 30px;
  width: 100%;
  display: flex;
  position: relative;
}

.privacypolicy .text-23 {
  color: #454545;
  align-self: stretch;
  font-family: Noto Sans JP, Helvetica;
  font-size: 15px;
  font-weight: 400;
  line-height: 15px;
  position: relative;
}

.privacypolicy .text-wrapper-334 {
  font-family: var(--text-bold-font-family);
  font-size: var(--text-bold-font-size);
  font-style: var(--text-bold-font-style);
  font-weight: var(--text-bold-font-weight);
  letter-spacing: var(--text-bold-letter-spacing);
  line-height: var(--text-bold-line-height);
}

.privacypolicy .text-wrapper-335 {
  font-family: var(--text-font-family);
  font-size: var(--text-font-size);
  font-style: var(--text-font-style);
  font-weight: var(--text-font-weight);
  letter-spacing: var(--text-letter-spacing);
  line-height: var(--text-line-height);
}

.privacypolicy .text-wrapper-336 {
  color: #454545;
  font-family: var(--text-font-family);
  font-size: var(--text-font-size);
  font-style: var(--text-font-style);
  font-weight: var(--text-font-weight);
  letter-spacing: var(--text-letter-spacing);
  line-height: var(--text-line-height);
  align-self: stretch;
  position: relative;
}

.privacypolicy .CONTACT-20 {
  background-color: var(--backwhite);
  flex-direction: column;
  flex: none;
  align-self: stretch;
  align-items: center;
  gap: 40px;
  width: 100%;
  padding: 100px 15px;
  display: flex;
  position: relative;
}

.privacypolicy .CONTACT-21 {
  width: 700px;
  height: 160px;
  position: relative;
}

.privacypolicy .frame-272 {
  background-color: var(--purple);
  border-radius: 6px;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  gap: 20px 74px;
  width: 700px;
  max-width: 700px;
  padding: 50px 60px;
  display: flex;
  position: relative;
}

.privacypolicy .group-36 {
  width: 216px;
  height: 60px;
  position: relative;
}

.privacypolicy .overlap-group-11 {
  width: 212px;
  height: 60px;
  position: relative;
}

.privacypolicy .text-wrapper-337 {
  color: var(--white);
  letter-spacing: 2.4px;
  text-align: center;
  width: 212px;
  font-family: Zen Kaku Gothic New, Helvetica;
  font-size: 24px;
  font-weight: 500;
  line-height: normal;
  position: absolute;
  top: 0;
  left: 0;
}

.privacypolicy .text-wrapper-338 {
  color: var(--white);
  letter-spacing: 0;
  text-align: center;
  width: 134px;
  font-family: Slackside One, Helvetica;
  font-size: 20px;
  font-weight: 400;
  line-height: normal;
  position: absolute;
  top: 31px;
  left: 39px;
}

.privacypolicy .group-37 {
  width: 254px;
  height: 55px;
  position: relative;
}

.privacypolicy .flexcontainer-36 {
  flex-direction: column;
  align-items: flex-start;
  gap: 5px;
  width: 252px;
  height: 55px;
  display: flex;
  position: relative;
}

.privacypolicy .text-i-35 {
  color: var(--white);
  letter-spacing: .75px;
  align-self: stretch;
  font-family: Noto Sans JP, Helvetica;
  font-size: 15px;
  font-weight: 400;
  line-height: 25px;
  position: relative;
}

.privacypolicy .footer-11 {
  background-color: #454545;
  flex-direction: column;
  flex: none;
  align-self: stretch;
  align-items: center;
  gap: 50px;
  width: 100%;
  padding: 70px 40px 50px;
  display: flex;
  position: relative;
}

.privacypolicy .frame-273 {
  flex-wrap: wrap;
  flex: none;
  justify-content: space-between;
  align-items: flex-start;
  gap: 50px 150px;
  width: 1200px;
  margin-left: -40px;
  margin-right: -40px;
  padding: 0 50px;
  display: flex;
  position: relative;
}

.privacypolicy .frame-274 {
  flex-direction: column;
  flex: 1;
  align-items: flex-start;
  gap: 44px;
  display: flex;
  position: relative;
}

.privacypolicy .logo-white-17 {
  width: 163.65px !important;
  height: 54.26px !important;
  position: relative !important;
}

.privacypolicy .text-i-36 {
  color: #fff;
  font-family: var(--text-font-family);
  font-size: var(--text-font-size);
  font-style: var(--text-font-style);
  font-weight: var(--text-font-weight);
  letter-spacing: var(--text-letter-spacing);
  line-height: var(--text-line-height);
  align-self: stretch;
  position: relative;
}

.privacypolicy .frame-275 {
  flex: none;
  align-items: flex-start;
  gap: 60px;
  display: inline-flex;
  position: relative;
}

.privacypolicy .frame-276 {
  flex-direction: column;
  flex: none;
  align-items: flex-start;
  gap: 40px;
  padding: 0 20px;
  display: inline-flex;
  position: relative;
}

.privacypolicy .frame-277 {
  flex-direction: column;
  flex: none;
  align-items: flex-start;
  gap: 6px;
  display: inline-flex;
  position: relative;
}

.privacypolicy .text-wrapper-339 {
  color: #fff;
  letter-spacing: .75px;
  white-space: nowrap;
  width: fit-content;
  margin-top: -1px;
  font-family: Noto Sans JP, Helvetica;
  font-size: 15px;
  font-weight: 700;
  line-height: 20px;
  position: relative;
}

.privacypolicy .text-wrapper-340 {
  color: #fff;
  letter-spacing: .65px;
  white-space: nowrap;
  width: fit-content;
  font-family: Noto Sans JP, Helvetica;
  font-size: 13px;
  font-weight: 700;
  line-height: 20px;
  position: relative;
}

.privacypolicy .frame-278 {
  flex-direction: column;
  flex: none;
  align-items: flex-start;
  gap: 30px;
  padding: 0 20px;
  display: inline-flex;
  position: relative;
}

.privacypolicy .text-wrapper-341 {
  color: #fff;
  letter-spacing: .75px;
  white-space: nowrap;
  width: fit-content;
  font-family: Noto Sans JP, Helvetica;
  font-size: 15px;
  font-weight: 700;
  line-height: 20px;
  position: relative;
}

.privacypolicy .text-wrapper-342 {
  color: #fff;
  letter-spacing: .75px;
  white-space: nowrap;
  width: fit-content;
  font-family: Noto Sans JP, Helvetica;
  font-size: 15px;
  font-weight: 700;
  line-height: 20px;
  display: block;
  position: relative;
}

.privacypolicy .text-wrapper-343 {
  color: #fff;
  letter-spacing: 1.31px;
  width: fit-content;
  font-family: Manrope, Helvetica;
  font-size: 13.1px;
  font-weight: 400;
  line-height: normal;
  position: relative;
}

.blogs {
  background-color: var(--backwhite);
  flex-direction: column;
  align-items: center;
  display: flex;
  position: relative;
}

.blogs .header-31 {
  z-index: 1 !important;
  align-self: stretch !important;
  width: 100% !important;
}

.blogs .menu-11 {
  width: 36px !important;
  height: 35px !important;
  margin-bottom: -10px !important;
  position: relative !important;
}

.blogs .header-32 {
  z-index: 1 !important;
  flex: none !important;
  justify-content: space-between !important;
  align-self: stretch !important;
  width: 100% !important;
  display: flex !important;
}

.blogs .header-33 {
  z-index: 1 !important;
}

.blogs .header-34 {
  z-index: 0 !important;
  width: 60px !important;
  height: 59px !important;
  position: relative !important;
}

.blogs .div-21 {
  z-index: 0;
  background-color: #fff;
  flex-direction: column;
  flex: none;
  align-self: stretch;
  align-items: flex-start;
  width: 100%;
  display: flex;
  position: relative;
}

.blogs .midashi-7 {
  flex-direction: column;
  flex: none;
  align-self: stretch;
  align-items: flex-start;
  width: 100%;
  display: flex;
  position: relative;
}

.blogs .class-115 {
  color: #fff !important;
}

.blogs .class-116 {
  color: #fff !important;
  left: 28px !important;
}

.blogs .class-117 {
  color: #fff !important;
  left: 42px !important;
}

.blogs .class-118 {
  flex: none !important;
  align-self: stretch !important;
  width: 100% !important;
  padding: 5px 0 !important;
  display: flex !important;
}

.blogs .class-119 {
  flex: none !important;
  align-self: stretch !important;
  width: 100% !important;
}

.blogs .breadcrumbs-10 {
  flex: none;
  justify-content: center;
  align-self: stretch;
  align-items: flex-start;
  gap: 30px;
  width: 100%;
  display: flex;
  position: relative;
}

.blogs .frame-279 {
  flex: 1;
  align-items: center;
  display: flex;
  position: relative;
}

.blogs .text-wrapper-344 {
  color: var(--gray);
  font-family: var(--smalltext-SP-font-family);
  font-size: var(--smalltext-SP-font-size);
  font-style: var(--smalltext-SP-font-style);
  font-weight: var(--smalltext-SP-font-weight);
  letter-spacing: var(--smalltext-SP-letter-spacing);
  line-height: var(--smalltext-SP-line-height);
  white-space: nowrap;
  width: fit-content;
  margin-top: -1px;
  display: block;
  position: relative;
}

.blogs .instance-node-9 {
  width: 4.57px !important;
  height: 8.14px !important;
  position: relative !important;
}

.blogs .TOP-5 {
  color: var(--gray);
  white-space: nowrap;
  width: fit-content;
  margin-top: -1px;
  position: relative;
}

.blogs .text-wrapper-345 {
  color: var(--gray);
  font-family: var(--tag-font-family);
  font-size: var(--tag-font-size);
  font-style: var(--tag-font-style);
  font-weight: var(--tag-font-weight);
  letter-spacing: var(--tag-letter-spacing);
  line-height: var(--tag-line-height);
  white-space: nowrap;
  width: fit-content;
  margin-top: -1px;
  position: relative;
}

.blogs .div-22 {
  background-color: var(--palepurple);
  flex-direction: column;
  flex: none;
  align-self: stretch;
  align-items: center;
  gap: 40px;
  width: 100%;
  display: flex;
  position: relative;
}

.blogs .frame-280 {
  flex-direction: column;
  flex: none;
  align-items: center;
  position: relative;
}

.blogs .frame-281 {
  flex: none;
  align-items: flex-start;
  position: relative;
}

.blogs .frame-282 {
  position: relative;
}

.blogs .text-wrapper-346 {
  color: var(--purple);
  white-space: nowrap;
}

.blogs .line-16 {
  height: 3px;
}

.blogs .text-wrapper-347, .blogs .text-wrapper-348, .blogs .text-wrapper-349, .blogs .text-wrapper-350 {
  color: var(--purple);
  white-space: nowrap;
  width: fit-content;
  margin-top: -1px;
  position: relative;
}

.blogs .text-wrapper-351, .blogs .text-wrapper-352, .blogs .text-wrapper-353 {
  color: var(--purple);
  white-space: nowrap;
  width: fit-content;
  position: relative;
}

.blogs .frame-283 {
  flex-wrap: wrap;
  flex: none;
  justify-content: center;
  align-items: flex-start;
  position: relative;
}

.blogs .class-120 {
  height: unset !important;
  gap: 8px !important;
}

.blogs .class-121 {
  gap: 8px !important;
}

.blogs .class-122 {
  flex: none !important;
  flex-grow: unset !important;
}

.blogs .class-123 {
  font-family: var(--smalltext-SP-font-family) !important;
  font-size: var(--smalltext-SP-font-size) !important;
  font-style: var(--smalltext-SP-font-style) !important;
  font-weight: var(--smalltext-SP-font-weight) !important;
  letter-spacing: var(--smalltext-SP-letter-spacing) !important;
  line-height: var(--smalltext-SP-line-height) !important;
}

.blogs .class-124 {
  width: unset !important;
  flex: 1 !important;
  min-width: 290px !important;
  max-width: 360px !important;
}

.blogs .class-125 {
  width: 360px !important;
  max-width: 360px !important;
}

.blogs .class-126 {
  font-family: var(--smalltextbold-SP-font-family) !important;
  font-size: var(--smalltextbold-SP-font-size) !important;
  font-style: var(--smalltextbold-SP-font-style) !important;
  font-weight: var(--smalltextbold-SP-font-weight) !important;
  letter-spacing: var(--smalltextbold-SP-letter-spacing) !important;
  line-height: var(--smalltextbold-SP-line-height) !important;
}

.blogs .noimage-5 {
  width: 190.9px !important;
  height: 25.92px !important;
  position: relative !important;
}

.blogs .frame-284 {
  position: relative;
}

.blogs .polygon-3-3 {
  width: 7.41px !important;
  height: 11.13px !important;
  margin-left: -1.15px !important;
  position: relative !important;
}

.blogs .text-wrapper-354 {
  color: #454545;
  font-family: var(--tag-font-family);
  font-size: var(--tag-font-size);
  font-style: var(--tag-font-style);
  font-weight: var(--tag-font-weight);
  letter-spacing: var(--tag-letter-spacing);
  line-height: var(--tag-line-height);
  white-space: nowrap;
  width: fit-content;
  margin-top: -1px;
  position: relative;
}

.blogs .arrow-5 {
  width: 7.41px !important;
  height: 11.13px !important;
  margin-right: -1.15px !important;
  position: relative !important;
}

.blogs .text-wrapper-355 {
  color: #454545;
  font-family: var(--tag-font-family);
  font-size: var(--tag-font-size);
  font-style: var(--tag-font-style);
  font-weight: var(--tag-font-weight);
  letter-spacing: var(--tag-letter-spacing);
  line-height: var(--tag-line-height);
  white-space: nowrap;
  position: absolute;
  top: -1px;
  left: 29px;
}

.blogs .text-wrapper-356 {
  color: #454545;
  font-family: var(--tag-font-family);
  font-size: var(--tag-font-size);
  font-style: var(--tag-font-style);
  font-weight: var(--tag-font-weight);
  letter-spacing: var(--tag-letter-spacing);
  line-height: var(--tag-line-height);
  white-space: nowrap;
  position: absolute;
  top: -1px;
  left: 57px;
}

.blogs .text-wrapper-357 {
  color: #454545;
  font-family: var(--tag-font-family);
  font-size: var(--tag-font-size);
  font-style: var(--tag-font-style);
  font-weight: var(--tag-font-weight);
  letter-spacing: var(--tag-letter-spacing);
  line-height: var(--tag-line-height);
  white-space: nowrap;
  position: absolute;
  top: -1px;
  left: 85px;
}

.blogs .text-wrapper-358 {
  color: #454545;
  font-family: var(--tag-font-family);
  font-size: var(--tag-font-size);
  font-style: var(--tag-font-style);
  font-weight: var(--tag-font-weight);
  letter-spacing: var(--tag-letter-spacing);
  line-height: var(--tag-line-height);
  white-space: nowrap;
  position: absolute;
  top: -1px;
  left: 113px;
}

.blogs .text-wrapper-359 {
  color: #454545;
  font-family: var(--tag-font-family);
  font-size: var(--tag-font-size);
  font-style: var(--tag-font-style);
  font-weight: var(--tag-font-weight);
  letter-spacing: var(--tag-letter-spacing);
  line-height: var(--tag-line-height);
  white-space: nowrap;
  position: absolute;
  top: -1px;
  left: 141px;
}

.blogs .text-wrapper-360 {
  color: #454545;
  font-family: var(--tag-font-family);
  font-size: var(--tag-font-size);
  font-style: var(--tag-font-style);
  font-weight: var(--tag-font-weight);
  letter-spacing: var(--tag-letter-spacing);
  line-height: var(--tag-line-height);
  white-space: nowrap;
  position: absolute;
  top: -1px;
  left: 169px;
}

.blogs .polygon-2-8 {
  width: 10px !important;
  height: 16px !important;
  position: absolute !important;
  top: 0 !important;
  left: 197px !important;
}

.blogs .polygon-3-5 {
  width: 10px !important;
  height: 16px !important;
  position: absolute !important;
  top: 0 !important;
  left: 0 !important;
}

.blogs .CONTACT-22 {
  background-color: var(--backwhite);
  flex-direction: column;
  flex: none;
  align-items: center;
  gap: 40px;
  display: flex;
  position: relative;
}

.blogs .CONTACT-23 {
  height: 160px;
  display: block;
  position: relative;
}

.blogs .frame-285 {
  background-color: var(--purple);
  border-radius: 6px;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  max-width: 700px;
  display: flex;
  position: relative;
}

.blogs .group-38 {
  width: 216px;
  position: relative;
}

.blogs .text-wrapper-361 {
  color: var(--white);
  letter-spacing: 2px;
  text-align: center;
  width: 212px;
  font-family: Zen Kaku Gothic New, Helvetica;
  font-size: 20px;
  font-weight: 500;
  line-height: normal;
  position: absolute;
  top: 0;
  left: 0;
}

.blogs .text-wrapper-362 {
  color: var(--white);
  letter-spacing: 0;
  text-align: center;
  width: 134px;
  font-family: Slackside One, Helvetica;
  font-size: 15px;
  font-weight: 400;
  line-height: normal;
  position: absolute;
  top: 31px;
  left: 39px;
}

.blogs .overlap-group-12 {
  width: 212px;
  height: 60px;
  position: relative;
}

.blogs .text-wrapper-363 {
  color: var(--white);
  letter-spacing: 2.4px;
  text-align: center;
  width: 212px;
  font-family: Zen Kaku Gothic New, Helvetica;
  font-size: 24px;
  font-weight: 500;
  line-height: normal;
  position: absolute;
  top: 0;
  left: 0;
}

.blogs .text-wrapper-364 {
  color: var(--white);
  letter-spacing: 0;
  text-align: center;
  width: 134px;
  font-family: Slackside One, Helvetica;
  font-size: 20px;
  font-weight: 400;
  line-height: normal;
  position: absolute;
  top: 31px;
  left: 39px;
}

.blogs .group-39 {
  position: relative;
}

.blogs .flexcontainer-37 {
  flex-direction: column;
  align-items: flex-start;
  display: flex;
  position: relative;
}

.blogs .text-i-37, .blogs .text-i-38 {
  color: var(--white);
  align-self: stretch;
  position: relative;
}

.blogs .footer-12 {
  background-color: #454545;
  flex-direction: column;
  flex: none;
  align-self: stretch;
  align-items: center;
  gap: 50px;
  width: 100%;
  display: flex;
  position: relative;
}

.blogs .frame-286 {
  flex: none;
  align-items: flex-start;
  display: flex;
  position: relative;
}

.blogs .frame-287 {
  flex-direction: column;
  align-items: flex-start;
  display: flex;
  position: relative;
}

.blogs .logo-white-19 {
  width: 121px !important;
  height: 40px !important;
  position: relative !important;
}

.blogs .logo-white-14 {
  width: 163.65px !important;
  height: 54.26px !important;
  position: relative !important;
}

.blogs .flexcontainer-38 {
  flex-direction: column;
  align-items: flex-start;
  display: flex;
}

.blogs .text-i-39, .blogs .text-i-40 {
  color: #fff;
  align-self: stretch;
  position: relative;
}

.blogs .frame-288 {
  flex: none;
  align-items: flex-start;
  position: relative;
}

.blogs .frame-289, .blogs .frame-290 {
  flex-direction: column;
  flex: none;
  align-items: flex-start;
  display: inline-flex;
  position: relative;
}

.blogs .text-wrapper-365 {
  color: #fff;
  white-space: nowrap;
  width: fit-content;
  margin-top: -1px;
  display: block;
  position: relative;
}

.blogs .text-wrapper-366, .blogs .text-wrapper-367, .blogs .text-wrapper-368 {
  color: #fff;
  white-space: nowrap;
  width: fit-content;
  position: relative;
}

.blogs .frame-291 {
  flex-direction: column;
  flex: none;
  align-items: flex-start;
  display: inline-flex;
  position: relative;
}

.blogs .text-wrapper-369 {
  color: #fff;
  white-space: nowrap;
  width: fit-content;
  margin-top: -1px;
  display: block;
  position: relative;
}

.blogs .text-wrapper-370, .blogs .text-wrapper-371 {
  color: #fff;
  white-space: nowrap;
  width: fit-content;
  position: relative;
}

.blogs .frame-292 {
  flex-direction: column;
  flex: none;
  align-items: flex-start;
  display: inline-flex;
  position: relative;
}

.blogs .text-wrapper-372 {
  color: #fff;
  white-space: nowrap;
  width: fit-content;
  margin-top: -1px;
  display: block;
  position: relative;
}

.blogs .text-wrapper-373, .blogs .text-wrapper-374 {
  color: #fff;
  white-space: nowrap;
  width: fit-content;
  position: relative;
}

.blogs .frame-293 {
  flex-direction: column;
  align-items: flex-start;
  gap: 30px;
  position: relative;
}

.blogs .text-wrapper-375 {
  color: #fff;
  white-space: nowrap;
  width: fit-content;
  margin-top: -1px;
  display: block;
  position: relative;
}

.blogs .text-wrapper-376, .blogs .text-wrapper-377, .blogs .text-wrapper-378, .blogs .text-wrapper-379 {
  color: #fff;
  white-space: nowrap;
  width: fit-content;
  display: block;
  position: relative;
}

.blogs .text-wrapper-380 {
  color: #fff;
  letter-spacing: 1.31px;
  width: fit-content;
  font-family: Manrope, Helvetica;
  font-size: 13.1px;
  font-weight: 400;
  line-height: normal;
  position: relative;
}

.open-open-wrapper {
  width: 60px;
  height: 59px;
  display: block;
  position: absolute;
  top: 0;
  left: 0;
}

.information-security {
  flex-direction: column;
  align-items: center;
  display: flex;
  position: relative;
}

.information-security .header-35 {
  height: unset !important;
  z-index: 1 !important;
  flex: none !important;
  align-self: stretch !important;
  width: 100% !important;
}

.information-security .header-36 {
  margin-bottom: unset !important;
}

.information-security .menu-12 {
  width: 36px !important;
  height: 35px !important;
  position: relative !important;
}

.information-security .header-37 {
  background-image: unset !important;
  width: 145px !important;
  height: 54px !important;
  position: relative !important;
  top: 16px !important;
}

.information-security .header-38 {
  z-index: 1 !important;
  flex: none !important;
  justify-content: space-between !important;
  align-self: stretch !important;
  width: 100% !important;
  display: flex !important;
}

.information-security .header-39 {
  z-index: 1 !important;
}

.information-security .menu-instance {
  left: unset !important;
  top: unset !important;
  z-index: 0 !important;
  position: relative !important;
}

.information-security .blog-3 {
  z-index: 0;
  background-color: #fff;
  flex-direction: column;
  flex: none;
  align-self: stretch;
  align-items: flex-start;
  width: 100%;
  display: flex;
  position: relative;
}

.information-security .midashi-8 {
  flex-direction: column;
  flex: none;
  align-self: stretch;
  align-items: flex-start;
  width: 100%;
  display: flex;
  position: relative;
}

.information-security .class-127 {
  color: #fff !important;
  left: -76px !important;
}

.information-security .class-128 {
  color: #fff !important;
  left: -164px !important;
}

.information-security .class-129 {
  color: #fff !important;
  left: -54px !important;
}

.information-security .class-130 {
  color: #fff !important;
  left: -145px !important;
}

.information-security .class-131 {
  flex: none !important;
  align-self: stretch !important;
  width: 100% !important;
  padding: 5px 0 !important;
  display: flex !important;
}

.information-security .class-132 {
  background-image: unset !important;
  flex: none !important;
  align-self: stretch !important;
  width: 100% !important;
}

.information-security .breadcrumbs-11 {
  flex: none;
  justify-content: center;
  align-self: stretch;
  align-items: flex-start;
  gap: 30px;
  width: 100%;
  display: flex;
  position: relative;
}

.information-security .frame-294 {
  flex: 1;
  align-items: center;
  display: flex;
  position: relative;
}

.information-security .text-wrapper-381 {
  color: var(--gray);
  font-family: var(--smalltext-SP-font-family);
  font-size: var(--smalltext-SP-font-size);
  font-style: var(--smalltext-SP-font-style);
  font-weight: var(--smalltext-SP-font-weight);
  letter-spacing: var(--smalltext-SP-letter-spacing);
  line-height: var(--smalltext-SP-line-height);
  white-space: nowrap;
  width: fit-content;
  margin-top: -1px;
  display: block;
  position: relative;
}

.information-security .instance-6 {
  width: 4.57px !important;
  height: 8.14px !important;
  position: relative !important;
}

.information-security .TOP-6 {
  color: var(--gray);
  white-space: nowrap;
  width: fit-content;
  margin-top: -1px;
  position: relative;
}

.information-security .component-10930-3 {
  left: unset !important;
  top: unset !important;
  width: 4.57px !important;
  height: 8.14px !important;
  position: relative !important;
}

.information-security .text-wrapper-382 {
  color: var(--gray);
  font-family: var(--tag-font-family);
  font-size: var(--tag-font-size);
  font-style: var(--tag-font-style);
  font-weight: var(--tag-font-weight);
  letter-spacing: var(--tag-letter-spacing);
  line-height: var(--tag-line-height);
  white-space: nowrap;
  width: fit-content;
  margin-top: -1px;
  position: relative;
}

.information-security .security {
  background-color: var(--palepurple);
  flex-direction: column;
  flex: none;
  align-self: stretch;
  align-items: center;
  gap: 40px;
  width: 100%;
  display: flex;
  position: relative;
}

.information-security .frame-295 {
  background-color: var(--back-white);
  border-radius: 14px;
  flex-direction: column;
  flex: none;
  align-items: flex-start;
  display: flex;
  position: relative;
}

.information-security .frame-296 {
  flex-direction: column;
  flex: none;
  align-self: stretch;
  align-items: flex-start;
  width: 100%;
  display: flex;
  position: relative;
}

.information-security .text-wrapper-383 {
  color: #454545;
  align-self: stretch;
  margin-top: -1px;
  position: relative;
}

.information-security .frame-297 {
  flex-direction: column;
  flex: none;
  align-self: stretch;
  align-items: flex-start;
  gap: 5px;
  width: 100%;
  display: flex;
  position: relative;
}

.information-security .bulletpoint-2 {
  flex-wrap: wrap;
  flex: none;
  align-self: stretch;
  align-items: flex-start;
  gap: 5px;
  width: 100%;
  display: flex;
  position: relative;
}

.information-security .group-40 {
  height: 28px;
  position: relative;
}

.information-security .ellipse-5 {
  width: 6px;
  height: 6px;
  position: absolute;
  top: 9px;
  left: 3px;
}

.information-security .ellipse-6 {
  background-color: #6079c1;
  border-radius: 5px;
  width: 10px;
  height: 10px;
  position: relative;
  top: 9px;
  left: 3px;
}

.information-security .text-wrapper-384 {
  color: #454545;
  flex: 1;
  margin-top: -1px;
  position: relative;
}

.information-security .group-41 {
  height: 28px;
  position: relative;
}

.information-security .text-wrapper-385 {
  color: #454545;
  flex: 1;
  margin-top: -1px;
  position: relative;
}

.information-security .text-wrapper-386 {
  color: #454545;
  align-self: stretch;
  position: relative;
}

.information-security .frame-298 {
  flex-direction: column;
  flex: none;
  align-self: stretch;
  align-items: flex-start;
  gap: 30px;
  width: 100%;
  display: flex;
  position: relative;
}

.information-security .class-133 {
  flex: none !important;
  align-self: stretch !important;
  width: 100% !important;
}

.information-security .class-134 {
  flex: none !important;
}

.information-security .line-21 {
  align-self: stretch !important;
  width: 100% !important;
  height: 2px !important;
  margin-bottom: -1px !important;
  position: relative !important;
}

.information-security .flexcontainer-39 {
  flex-direction: column;
  align-items: flex-start;
  display: flex;
}

.information-security .text-24, .information-security .text-25, .information-security .text-26, .information-security .text-27, .information-security .text-wrapper-387 {
  color: #454545;
  align-self: stretch;
  position: relative;
}

.information-security .flexcontainer-40 {
  flex-direction: column;
  align-items: flex-start;
  display: flex;
}

.information-security .text-28, .information-security .text-29, .information-security .text-30 {
  color: #454545;
  align-self: stretch;
  position: relative;
}

.information-security .CONTACT-24 {
  background-color: var(--backwhite);
  flex-direction: column;
  flex: none;
  align-items: center;
  gap: 40px;
  display: flex;
  position: relative;
}

.information-security .CONTACT-25 {
  height: 160px;
  display: block;
  position: relative;
}

.information-security .frame-299 {
  background-color: var(--purple);
  border-radius: 6px;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  max-width: 700px;
  display: flex;
  position: relative;
}

.information-security .group-42 {
  width: 216px;
  position: relative;
}

.information-security .text-wrapper-388 {
  color: var(--white);
  letter-spacing: 2px;
  text-align: center;
  width: 212px;
  font-family: Zen Kaku Gothic New, Helvetica;
  font-size: 20px;
  font-weight: 500;
  line-height: normal;
  position: absolute;
  top: 0;
  left: 0;
}

.information-security .text-wrapper-389 {
  color: var(--white);
  letter-spacing: 0;
  text-align: center;
  width: 134px;
  font-family: Slackside One, Helvetica;
  font-size: 15px;
  font-weight: 400;
  line-height: normal;
  position: absolute;
  top: 31px;
  left: 39px;
}

.information-security .overlap-group-13 {
  width: 212px;
  height: 60px;
  position: relative;
}

.information-security .text-wrapper-390 {
  color: var(--white);
  letter-spacing: 2.4px;
  text-align: center;
  width: 212px;
  font-family: Zen Kaku Gothic New, Helvetica;
  font-size: 24px;
  font-weight: 500;
  line-height: normal;
  position: absolute;
  top: 0;
  left: 0;
}

.information-security .text-wrapper-391 {
  color: var(--white);
  letter-spacing: 0;
  text-align: center;
  width: 134px;
  font-family: Slackside One, Helvetica;
  font-size: 20px;
  font-weight: 400;
  line-height: normal;
  position: absolute;
  top: 31px;
  left: 39px;
}

.information-security .group-43 {
  position: relative;
}

.information-security .flexcontainer-41 {
  flex-direction: column;
  align-items: flex-start;
  display: flex;
  position: relative;
}

.information-security .text-i-41, .information-security .text-i-42 {
  color: var(--white);
  align-self: stretch;
  position: relative;
}

.information-security .footer-13 {
  background-color: #454545;
  flex-direction: column;
  flex: none;
  align-self: stretch;
  align-items: center;
  gap: 50px;
  width: 100%;
  display: flex;
  position: relative;
}

.information-security .frame-300 {
  flex: none;
  align-items: flex-start;
  display: flex;
  position: relative;
}

.information-security .frame-301 {
  flex-direction: column;
  align-items: flex-start;
  display: flex;
  position: relative;
}

.information-security .logo-white-27 {
  width: 121px !important;
  height: 40px !important;
  position: relative !important;
}

.information-security .logo-white-6 {
  left: unset !important;
  top: unset !important;
  width: 163.65px !important;
  height: 54.26px !important;
  position: relative !important;
}

.information-security .flexcontainer-42 {
  flex-direction: column;
  align-items: flex-start;
  display: flex;
}

.information-security .text-i-43, .information-security .text-i-44 {
  color: #fff;
  align-self: stretch;
  position: relative;
}

.information-security .frame-302 {
  flex: none;
  align-items: flex-start;
  position: relative;
}

.information-security .frame-303, .information-security .frame-304 {
  flex-direction: column;
  flex: none;
  align-items: flex-start;
  display: inline-flex;
  position: relative;
}

.information-security .text-wrapper-392 {
  color: #fff;
  white-space: nowrap;
  width: fit-content;
  margin-top: -1px;
  display: block;
  position: relative;
}

.information-security .text-wrapper-393, .information-security .text-wrapper-394, .information-security .text-wrapper-395 {
  color: #fff;
  white-space: nowrap;
  width: fit-content;
  position: relative;
}

.information-security .frame-305 {
  flex-direction: column;
  flex: none;
  align-items: flex-start;
  display: inline-flex;
  position: relative;
}

.information-security .text-wrapper-396 {
  color: #fff;
  white-space: nowrap;
  width: fit-content;
  margin-top: -1px;
  position: relative;
}

.information-security .text-wrapper-397, .information-security .text-wrapper-398 {
  color: #fff;
  white-space: nowrap;
  width: fit-content;
  position: relative;
}

.information-security .frame-306 {
  flex-direction: column;
  flex: none;
  align-items: flex-start;
  display: inline-flex;
  position: relative;
}

.information-security .text-wrapper-399 {
  color: #fff;
  white-space: nowrap;
  width: fit-content;
  margin-top: -1px;
  position: relative;
}

.information-security .text-wrapper-400, .information-security .text-wrapper-401 {
  color: #fff;
  white-space: nowrap;
  width: fit-content;
  position: relative;
}

.information-security .frame-307 {
  flex-direction: column;
  align-items: flex-start;
  gap: 30px;
  position: relative;
}

.information-security .text-wrapper-402 {
  color: #fff;
  white-space: nowrap;
  width: fit-content;
  margin-top: -1px;
  display: block;
  position: relative;
}

.information-security .text-wrapper-403, .information-security .text-wrapper-404, .information-security .text-wrapper-405, .information-security .text-wrapper-406 {
  color: #fff;
  white-space: nowrap;
  width: fit-content;
  display: block;
  position: relative;
}

.information-security .text-wrapper-407 {
  color: #fff;
  letter-spacing: 1.31px;
  width: fit-content;
  font-family: Manrope, Helvetica;
  font-size: 13.1px;
  font-weight: 400;
  line-height: normal;
  position: relative;
}

.recruit {
  background-color: #fff;
  flex-direction: column;
  align-items: flex-start;
  display: flex;
  position: relative;
}

.recruit .headrer {
  flex-direction: column;
  flex: none;
  align-self: stretch;
  align-items: flex-start;
  width: 100%;
  display: flex;
  position: relative;
}

.recruit .logo-3 {
  flex: none;
  justify-content: space-between;
  align-self: stretch;
  align-items: flex-start;
  width: 100%;
  padding: 0 0 20px 50px;
  display: flex;
  position: relative;
}

.recruit .group-44 {
  width: 152.61px;
  height: 70px;
  position: relative;
}

.recruit .logo-4 {
  width: 153px !important;
  height: 57px !important;
  position: relative !important;
  top: 13px !important;
}

.recruit .menu-13 {
  width: 71px;
  height: 70px;
  position: relative;
}

.recruit .component-wrapper {
  background-image: url("midashi-1.05af3916.png");
  background-position: 50%;
  background-size: cover;
  flex: none;
  justify-content: center;
  align-self: stretch;
  align-items: flex-start;
  gap: 10px;
  width: 100%;
  padding: 20px 0 30px;
  display: flex;
  position: relative;
}

.recruit .component {
  width: 108px;
  height: 89px;
  position: relative;
}

.recruit .text-wrapper-408 {
  color: #fff;
  letter-spacing: 1.6px;
  text-align: center;
  white-space: nowrap;
  font-family: Zen Kaku Gothic New, Helvetica;
  font-size: 32px;
  font-weight: 500;
  line-height: normal;
  position: absolute;
  top: 14px;
  left: -12px;
}

.recruit .TEXTEXT {
  color: #fff;
  letter-spacing: 0;
  text-align: center;
  white-space: nowrap;
  font-family: Slackside One, Helvetica;
  font-size: 23px;
  font-weight: 400;
  line-height: normal;
  position: absolute;
  top: 52px;
  left: 12px;
}

.recruit .frame-308 {
  flex: none;
  align-self: stretch;
  align-items: center;
  gap: 30px;
  width: 100%;
  padding: 50px;
  display: flex;
  position: relative;
}

.recruit .text-wrapper-409 {
  color: var(--gray);
  font-family: var(--tag-font-family);
  font-size: var(--tag-font-size);
  font-style: var(--tag-font-style);
  font-weight: var(--tag-font-weight);
  letter-spacing: var(--tag-letter-spacing);
  line-height: var(--tag-line-height);
  white-space: nowrap;
  width: fit-content;
  margin-top: -1px;
  position: relative;
}

.recruit .component-10930-4 {
  left: unset !important;
  top: unset !important;
  width: 4.57px !important;
  height: 8.14px !important;
  position: relative !important;
}

.recruit .view-4 {
  background-color: var(--palepurple);
  flex-direction: column;
  flex: none;
  align-self: stretch;
  align-items: center;
  gap: 40px;
  width: 100%;
  padding: 80px 15px;
  display: flex;
  position: relative;
}

.recruit .frame-309 {
  flex: none;
  align-items: flex-start;
  gap: 70px;
  max-width: 1100px;
  display: inline-flex;
  position: relative;
}

.recruit .frame-310 {
  flex-direction: column;
  flex: none;
  justify-content: center;
  align-items: flex-start;
  gap: 20px;
  display: inline-flex;
  position: relative;
}

.recruit .frame-311 {
  background-color: var(--back-white);
  border-radius: 14px;
  flex-direction: column;
  align-items: flex-start;
  gap: 32px;
  width: 800px;
  height: 735.92px;
  padding: 50px 20px;
  display: flex;
  position: relative;
}

.recruit .frame-312 {
  flex: none;
  align-self: stretch;
  align-items: flex-start;
  gap: 30px;
  width: 100%;
  max-width: 800px;
  display: flex;
  position: relative;
}

.recruit .group-45 {
  width: 93px;
  height: 18.74px;
  position: relative;
}

.recruit .text-wrapper-410 {
  color: var(--black);
  font-family: var(--date-font-family);
  font-size: var(--date-font-size);
  font-style: var(--date-font-style);
  font-weight: var(--date-font-weight);
  letter-spacing: var(--date-letter-spacing);
  line-height: var(--date-line-height);
  width: 91px;
  position: absolute;
  top: 0;
  left: 0;
}

.recruit .frame-313 {
  flex-wrap: wrap;
  flex: 1;
  align-items: flex-end;
  gap: 0 28px;
  display: flex;
  position: relative;
}

.recruit .text-wrapper-411 {
  color: var(--purple);
  font-family: var(--tag-font-family);
  font-size: var(--tag-font-size);
  font-style: var(--tag-font-style);
  font-weight: var(--tag-font-weight);
  letter-spacing: var(--tag-letter-spacing);
  line-height: var(--tag-line-height);
  white-space: nowrap;
  width: fit-content;
  position: relative;
}

.recruit .text-wrapper-412 {
  color: var(--purple);
  font-family: var(--tag-font-family);
  font-size: var(--tag-font-size);
  font-style: var(--tag-font-style);
  font-weight: var(--tag-font-weight);
  letter-spacing: var(--tag-letter-spacing);
  line-height: var(--tag-line-height);
  white-space: nowrap;
  width: 43px;
  height: 17px;
  margin-top: -1px;
  position: relative;
}

.recruit .div-23 {
  color: #454545;
  letter-spacing: 1px;
  align-self: stretch;
  font-family: Noto Sans JP, Helvetica;
  font-size: 20px;
  font-weight: 700;
  line-height: 20px;
  position: relative;
}

.recruit .text-wrapper-413 {
  letter-spacing: .2px;
  line-height: 37px;
}

.recruit .text-wrapper-414 {
  letter-spacing: .2px;
  line-height: 36px;
}

.recruit .grayline-5 {
  height: unset !important;
  top: unset !important;
  background-image: url("line-13-2-2.50ac3f4a.svg") !important;
  flex: 1 !important;
  align-self: stretch !important;
  width: 100% !important;
}

.recruit .frame-314 {
  flex-wrap: wrap;
  flex: none;
  align-self: stretch;
  align-items: flex-start;
  gap: 31px;
  width: 100%;
  display: flex;
  position: relative;
}

.recruit .group-46 {
  background-color: var(--gray);
  border-radius: 6px;
  flex: 1;
  height: 236.18px;
  position: relative;
}

.recruit .noimage-6 {
  width: 208px !important;
  top: 107px !important;
  left: 74px !important;
}

.recruit .group-47 {
  background-image: url("rectangle-23.50d46850.svg");
  background-size: 100% 100%;
  flex: 1;
  height: 236.18px;
  position: relative;
}

.recruit .noimage-7 {
  width: 208px !important;
  top: 107px !important;
  left: 79px !important;
}

.recruit .text-wrapper-415 {
  color: #454545;
  font-family: var(--text-font-family);
  font-size: var(--text-font-size);
  font-style: var(--text-font-style);
  font-weight: var(--text-font-weight);
  letter-spacing: var(--text-letter-spacing);
  line-height: var(--text-line-height);
  align-self: stretch;
  position: relative;
}

.recruit .frame-315 {
  flex: none;
  justify-content: space-between;
  align-self: stretch;
  align-items: flex-start;
  width: 100%;
  display: flex;
  position: relative;
}

.recruit .group-48 {
  flex: 1;
  height: 25px;
  position: relative;
}

.recruit .text-wrapper-416 {
  color: var(--darkgray);
  letter-spacing: .75px;
  width: 269px;
  font-family: Noto Sans JP, Helvetica;
  font-size: 15px;
  font-weight: 400;
  line-height: 25px;
  position: absolute;
  top: 0;
  left: 31px;
}

.recruit .polygon-3 {
  width: 15px !important;
  height: 17px !important;
  top: 5px !important;
  left: -1px !important;
}

.recruit .frame-316 {
  flex: 1 !important;
  min-width: 140px !important;
  max-width: 200px !important;
  margin-left: -2px !important;
  padding: 17px 30px !important;
  display: flex !important;
}

.recruit .frame-317 {
  font-weight: 700 !important;
}

.recruit .group-49 {
  flex: 1;
  height: 25px;
  margin-left: -2px;
  margin-right: -2px;
  position: relative;
}

.recruit .text-wrapper-417 {
  color: var(--darkgray);
  letter-spacing: .75px;
  text-align: right;
  width: 260px;
  font-family: Noto Sans JP, Helvetica;
  font-size: 15px;
  font-weight: 400;
  line-height: 25px;
  position: absolute;
  top: 0;
  left: 0;
}

.recruit .polygon-4 {
  width: 15px !important;
  height: 17px !important;
  top: 5px !important;
  left: 286px !important;
}

.recruit .view-5 {
  flex-direction: column;
  align-items: flex-start;
  width: 240px;
  margin-right: -10px;
  display: flex;
  position: relative;
}

.recruit .frame-318 {
  background-color: #6079c1;
  border-radius: 10px 10px 0 0;
  flex-direction: column;
  flex: none;
  align-self: stretch;
  align-items: center;
  gap: 30px;
  width: 100%;
  padding: 20px 15px;
  display: flex;
  position: relative;
}

.recruit .text-wrapper-418 {
  color: #fff;
  letter-spacing: 0;
  text-align: center;
  width: fit-content;
  margin-top: -1px;
  font-family: Noto Sans JP, Helvetica;
  font-size: 16px;
  font-weight: 700;
  line-height: normal;
  position: relative;
}

.recruit .frame-319 {
  background-color: #fff;
  border-radius: 0 0 10px 10px;
  flex-direction: column;
  flex: none;
  align-self: stretch;
  align-items: center;
  gap: 30px;
  width: 100%;
  padding: 30px 15px 45px;
  display: flex;
  position: relative;
}

.recruit .frame-320 {
  flex: none;
  justify-content: space-between;
  align-self: stretch;
  align-items: center;
  width: 100%;
  padding: 0 10px;
  display: flex;
  position: relative;
}

.recruit .text-wrapper-419 {
  color: var(--darkgray);
  letter-spacing: .75px;
  flex: 1;
  margin-top: -1px;
  font-family: Noto Sans JP, Helvetica;
  font-size: 15px;
  font-weight: 600;
  line-height: 26px;
  position: relative;
}

.recruit .polygon-2-10 {
  width: 6.25px !important;
  height: 16.55px !important;
  margin-left: -.42px !important;
  margin-right: -.6px !important;
  position: relative !important;
}

.recruit .line-17 {
  align-self: stretch;
  width: 100%;
  height: 2px;
  margin-left: -1px;
  margin-right: -1px;
  position: relative;
}

.recruit .CONTACT-26 {
  background-color: var(--backwhite);
  flex-direction: column;
  flex: none;
  align-self: stretch;
  align-items: center;
  gap: 40px;
  width: 100%;
  padding: 100px 15px;
  display: flex;
  position: relative;
}

.recruit .CONTACT-27 {
  width: 700px;
  height: 160px;
  position: relative;
}

.recruit .frame-321 {
  background-color: var(--purple);
  border-radius: 6px;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  gap: 20px 74px;
  width: 700px;
  max-width: 700px;
  padding: 50px 60px;
  display: flex;
  position: relative;
}

.recruit .group-50 {
  width: 216px;
  height: 60px;
  position: relative;
}

.recruit .overlap-group-14 {
  width: 212px;
  height: 60px;
  position: relative;
}

.recruit .text-wrapper-420 {
  color: var(--white);
  letter-spacing: 2.4px;
  text-align: center;
  width: 212px;
  font-family: Zen Kaku Gothic New, Helvetica;
  font-size: 24px;
  font-weight: 500;
  line-height: normal;
  position: absolute;
  top: 0;
  left: 0;
}

.recruit .text-wrapper-421 {
  color: var(--white);
  letter-spacing: 0;
  text-align: center;
  width: 134px;
  font-family: Slackside One, Helvetica;
  font-size: 20px;
  font-weight: 400;
  line-height: normal;
  position: absolute;
  top: 31px;
  left: 39px;
}

.recruit .group-51 {
  width: 254px;
  height: 55px;
  position: relative;
}

.recruit .flexcontainer-43 {
  flex-direction: column;
  align-items: flex-start;
  gap: 5px;
  width: 252px;
  height: 55px;
  display: flex;
  position: relative;
}

.recruit .text-i-45 {
  color: var(--white);
  letter-spacing: .75px;
  align-self: stretch;
  font-family: Noto Sans JP, Helvetica;
  font-size: 15px;
  font-weight: 400;
  line-height: 25px;
  position: relative;
}

.recruit .text-wrapper-422 {
  letter-spacing: .11px;
}

.recruit .footer-14 {
  background-color: #454545;
  flex-direction: column;
  flex: none;
  align-self: stretch;
  align-items: center;
  gap: 50px;
  width: 100%;
  padding: 70px 20px 50px;
  display: flex;
  position: relative;
}

.recruit .frame-322 {
  flex-wrap: wrap;
  flex: none;
  justify-content: space-between;
  align-items: flex-start;
  gap: 50px 150px;
  width: 1200px;
  padding: 0 100px;
  display: flex;
  position: relative;
}

.recruit .frame-323 {
  flex-direction: column;
  flex: 1;
  align-items: flex-start;
  gap: 44px;
  display: flex;
  position: relative;
}

.recruit .logo-white-8 {
  left: unset !important;
  top: unset !important;
  width: 163.65px !important;
  height: 54.26px !important;
  position: relative !important;
}

.recruit .flexcontainer-44 {
  flex-direction: column;
  align-items: flex-start;
  gap: 5px;
  display: flex;
}

.recruit .text-i-46 {
  color: #fff;
  font-family: var(--text-font-family);
  font-size: var(--text-font-size);
  font-style: var(--text-font-style);
  font-weight: var(--text-font-weight);
  letter-spacing: var(--text-letter-spacing);
  line-height: var(--text-line-height);
  align-self: stretch;
  position: relative;
}

.recruit .text-wrapper-423 {
  font-family: var(--text-font-family);
  font-size: var(--text-font-size);
  font-style: var(--text-font-style);
  font-weight: var(--text-font-weight);
  letter-spacing: var(--text-letter-spacing);
  line-height: var(--text-line-height);
}

.recruit .frame-324 {
  flex: none;
  align-items: flex-start;
  gap: 60px;
  display: inline-flex;
  position: relative;
}

.recruit .frame-325 {
  flex-direction: column;
  flex: none;
  align-items: flex-start;
  gap: 30px;
  display: inline-flex;
  position: relative;
}

.recruit .frame-326 {
  flex-direction: column;
  flex: none;
  align-items: flex-start;
  gap: 6px;
  display: inline-flex;
  position: relative;
}

.recruit .text-wrapper-424 {
  color: #fff;
  font-family: var(--text-bold-mini-font-family);
  font-size: var(--text-bold-mini-font-size);
  font-style: var(--text-bold-mini-font-style);
  font-weight: var(--text-bold-mini-font-weight);
  letter-spacing: var(--text-bold-mini-letter-spacing);
  line-height: var(--text-bold-mini-line-height);
  white-space: nowrap;
  width: fit-content;
  margin-top: -1px;
  position: relative;
}

.recruit .text-wrapper-425 {
  color: #fff;
  font-family: var(--text-bold-mini-font-family);
  font-size: var(--text-bold-mini-font-size);
  font-style: var(--text-bold-mini-font-style);
  font-weight: var(--text-bold-mini-font-weight);
  letter-spacing: var(--text-bold-mini-letter-spacing);
  line-height: var(--text-bold-mini-line-height);
  white-space: nowrap;
  width: fit-content;
  position: relative;
}

.recruit .text-wrapper-426 {
  color: #fff;
  font-family: var(--text-bold-mini-font-family);
  font-size: var(--text-bold-mini-font-size);
  font-style: var(--text-bold-mini-font-style);
  font-weight: var(--text-bold-mini-font-weight);
  letter-spacing: var(--text-bold-mini-letter-spacing);
  line-height: var(--text-bold-mini-line-height);
  white-space: nowrap;
  width: fit-content;
  display: block;
  position: relative;
}

.recruit .text-wrapper-427 {
  color: #fff;
  letter-spacing: 1.31px;
  width: fit-content;
  font-family: Manrope, Helvetica;
  font-size: 13.1px;
  font-weight: 400;
  line-height: normal;
  position: relative;
}

.recruit-screen {
  background-color: #fff;
  flex-direction: column;
  align-items: flex-start;
  display: flex;
  position: relative;
}

.recruit-screen .div-24 {
  flex-direction: column;
  flex: none;
  align-self: stretch;
  align-items: flex-start;
  width: 100%;
  display: flex;
  position: relative;
}

.recruit-screen .logo-5 {
  flex: none;
  justify-content: space-between;
  align-self: stretch;
  align-items: flex-start;
  width: 100%;
  padding: 0 0 20px 30px;
  display: flex;
  position: relative;
}

.recruit-screen .frame-327 {
  width: 164px;
  height: 64px;
  position: relative;
}

.recruit-screen .group-52 {
  background-image: url("zsykr-0.252254e0.png");
  background-size: 100% 100%;
  width: 132px;
  height: 49px;
  position: relative;
  top: 15px;
}

.recruit-screen .menu-14 {
  width: 53px;
  height: 52px;
  position: relative;
}

.recruit-screen .midashi-9 {
  background-color: #6079c1;
  flex: none;
  justify-content: center;
  align-self: stretch;
  align-items: flex-start;
  gap: 10px;
  width: 100%;
  padding: 20px 0 30px;
  display: flex;
  position: relative;
}

.recruit-screen .component-2 {
  width: 108px;
  height: 89px;
  position: relative;
}

.recruit-screen .text-wrapper-428 {
  color: #fff;
  letter-spacing: 1.6px;
  text-align: center;
  white-space: nowrap;
  font-family: Zen Kaku Gothic New, Helvetica;
  font-size: 32px;
  font-weight: 500;
  line-height: normal;
  position: absolute;
  top: 14px;
  left: -12px;
}

.recruit-screen .TEXTEXT-2 {
  color: #fff;
  letter-spacing: 0;
  text-align: center;
  white-space: nowrap;
  font-family: Slackside One, Helvetica;
  font-size: 23px;
  font-weight: 400;
  line-height: normal;
  position: absolute;
  top: 52px;
  left: 12px;
}

.recruit-screen .frame-328 {
  flex: none;
  align-self: stretch;
  align-items: center;
  gap: 30px;
  width: 100%;
  padding: 20px 30px;
  display: flex;
  position: relative;
}

.recruit-screen .group-53 {
  width: 137.57px;
  height: 15px;
  position: relative;
}

.recruit-screen .text-wrapper-429 {
  color: var(--gray);
  font-family: var(--tag-font-family);
  font-size: var(--tag-font-size);
  font-style: var(--tag-font-style);
  font-weight: var(--tag-font-weight);
  letter-spacing: var(--tag-letter-spacing);
  line-height: var(--tag-line-height);
  white-space: nowrap;
  display: block;
  position: absolute;
  top: 0;
  left: 0;
}

.recruit-screen .component-10930-5 {
  top: 3px !important;
  left: 58px !important;
}

.recruit-screen .text-wrapper-430 {
  color: var(--gray);
  font-family: var(--tag-font-family);
  font-size: var(--tag-font-size);
  font-style: var(--tag-font-style);
  font-weight: var(--tag-font-weight);
  letter-spacing: var(--tag-letter-spacing);
  line-height: var(--tag-line-height);
  white-space: nowrap;
  position: absolute;
  top: 0;
  left: 93px;
}

.recruit-screen .view-6 {
  background-color: var(--palepurple);
  flex-direction: column;
  flex: none;
  align-self: stretch;
  align-items: center;
  gap: 40px;
  width: 100%;
  padding: 80px 15px;
  display: flex;
  position: relative;
}

.recruit-screen .frame-329 {
  flex-direction: column;
  flex: none;
  align-self: stretch;
  align-items: center;
  gap: 70px;
  width: 100%;
  max-width: 1200px;
  display: flex;
  position: relative;
}

.recruit-screen .frame-330 {
  flex-direction: column;
  justify-content: center;
  align-self: stretch;
  align-items: flex-start;
  gap: 20px;
  width: 100%;
  height: 815.92px;
  display: flex;
  position: relative;
}

.recruit-screen .frame-331 {
  background-color: var(--back-white);
  border-radius: 14px;
  flex-direction: column;
  flex: none;
  align-self: stretch;
  align-items: flex-start;
  gap: 32px;
  width: 100%;
  padding: 50px 20px;
  display: flex;
  position: relative;
}

.recruit-screen .frame-332 {
  flex: none;
  align-self: stretch;
  align-items: flex-start;
  gap: 30px;
  width: 100%;
  max-width: 800px;
  display: flex;
  position: relative;
}

.recruit-screen .group-54 {
  width: 93px;
  height: 18.74px;
  position: relative;
}

.recruit-screen .text-wrapper-431 {
  color: var(--black);
  letter-spacing: 1.31px;
  width: 91px;
  font-family: Manrope-Medium, Helvetica;
  font-size: 13.1px;
  font-weight: 500;
  line-height: normal;
  position: absolute;
  top: 0;
  left: 0;
}

.recruit-screen .frame-333 {
  flex-wrap: wrap;
  flex: 1;
  align-items: flex-end;
  gap: 0 28px;
  display: flex;
  position: relative;
}

.recruit-screen .text-wrapper-432 {
  color: var(--purple);
  font-family: var(--tag-font-family);
  font-size: var(--tag-font-size);
  font-style: var(--tag-font-style);
  font-weight: var(--tag-font-weight);
  letter-spacing: var(--tag-letter-spacing);
  line-height: var(--tag-line-height);
  white-space: nowrap;
  width: fit-content;
  position: relative;
}

.recruit-screen .text-wrapper-433 {
  color: var(--purple);
  font-family: var(--tag-font-family);
  font-size: var(--tag-font-size);
  font-style: var(--tag-font-style);
  font-weight: var(--tag-font-weight);
  letter-spacing: var(--tag-letter-spacing);
  line-height: var(--tag-line-height);
  white-space: nowrap;
  width: 43px;
  height: 17px;
  margin-top: -1px;
  position: relative;
}

.recruit-screen .div-25 {
  color: #454545;
  letter-spacing: 1px;
  align-self: stretch;
  font-family: Noto Sans JP, Helvetica;
  font-size: 20px;
  font-weight: 700;
  line-height: 20px;
  position: relative;
}

.recruit-screen .text-wrapper-434 {
  letter-spacing: .2px;
  line-height: 37px;
}

.recruit-screen .text-wrapper-435 {
  letter-spacing: .2px;
  line-height: 36px;
}

.recruit-screen .grayline-6 {
  top: unset !important;
  background-image: url("line-13-3.68361f56.svg") !important;
  align-self: stretch !important;
  width: 100% !important;
}

.recruit-screen .frame-334 {
  flex: none;
  align-self: stretch;
  align-items: flex-start;
  gap: 31px;
  width: 100%;
  display: flex;
  position: relative;
}

.recruit-screen .frame-335 {
  background-color: var(--gray);
  border-radius: 6px;
  flex-direction: column;
  flex: 1;
  justify-content: center;
  align-self: stretch;
  align-items: center;
  gap: 10px;
  padding: 104px 70px;
  display: flex;
  position: relative;
  overflow: hidden;
}

.recruit-screen .noimage-8 {
  left: unset !important;
  top: unset !important;
  width: 196.71px !important;
  height: 25.92px !important;
  position: relative !important;
}

.recruit-screen .frame-336 {
  background-color: var(--gray);
  border-radius: 6px;
  flex-direction: column;
  flex: 1;
  justify-content: center;
  align-items: center;
  gap: 10px;
  padding: 104px 70px;
  display: flex;
  position: relative;
  overflow: hidden;
}

.recruit-screen .text-wrapper-436 {
  color: #454545;
  font-family: var(--text-font-family);
  font-size: var(--text-font-size);
  font-style: var(--text-font-style);
  font-weight: var(--text-font-weight);
  letter-spacing: var(--text-letter-spacing);
  line-height: var(--text-line-height);
  align-self: stretch;
  position: relative;
}

.recruit-screen .frame-337 {
  flex: none;
  justify-content: space-between;
  align-self: stretch;
  align-items: flex-start;
  width: 100%;
  display: flex;
  position: relative;
}

.recruit-screen .group-55 {
  flex: 1;
  height: 25px;
  position: relative;
}

.recruit-screen .text-wrapper-437 {
  color: var(--darkgray);
  letter-spacing: .75px;
  width: 251px;
  font-family: Noto Sans JP, Helvetica;
  font-size: 15px;
  font-weight: 400;
  line-height: 25px;
  position: absolute;
  top: 0;
  left: 29px;
}

.recruit-screen .polygon-5 {
  width: 14px !important;
  height: 17px !important;
  top: 5px !important;
  left: -1px !important;
}

.recruit-screen .frame-338 {
  flex: 1 !important;
  min-width: 140px !important;
  max-width: 200px !important;
  margin-left: -2px !important;
  padding: 17px 30px !important;
  display: flex !important;
}

.recruit-screen .frame-339 {
  font-weight: 700 !important;
}

.recruit-screen .group-56 {
  flex: 1;
  height: 25px;
  margin-left: -2px;
  margin-right: -2px;
  position: relative;
}

.recruit-screen .text-wrapper-438 {
  color: var(--darkgray);
  letter-spacing: .75px;
  text-align: right;
  width: 243px;
  font-family: Noto Sans JP, Helvetica;
  font-size: 15px;
  font-weight: 400;
  line-height: 25px;
  position: absolute;
  top: 0;
  left: 0;
}

.recruit-screen .polygon-6 {
  width: 14px !important;
  height: 17px !important;
  top: 5px !important;
  left: 267px !important;
}

.recruit-screen .frame-340 {
  background-color: #6079c1;
  border-radius: 10px 10px 0 0;
  flex-direction: column;
  flex: none;
  align-self: stretch;
  align-items: center;
  gap: 30px;
  width: 100%;
  padding: 20px 15px;
  display: flex;
  position: relative;
}

.recruit-screen .text-wrapper-439 {
  color: #fff;
  letter-spacing: 0;
  text-align: center;
  width: fit-content;
  margin-top: -1px;
  font-family: Noto Sans JP, Helvetica;
  font-size: 16px;
  font-weight: 700;
  line-height: normal;
  position: relative;
}

.recruit-screen .frame-341 {
  background-color: #fff;
  border-radius: 0 0 10px 10px;
  flex-direction: column;
  align-self: stretch;
  align-items: center;
  gap: 30px;
  width: 100%;
  height: 430px;
  padding: 30px 15px 45px;
  display: flex;
  position: relative;
}

.recruit-screen .frame-342 {
  flex: none;
  justify-content: space-between;
  align-self: stretch;
  align-items: center;
  width: 100%;
  padding: 0 10px;
  display: flex;
  position: relative;
}

.recruit-screen .text-wrapper-440 {
  color: var(--darkgray);
  letter-spacing: .75px;
  flex: 1;
  margin-top: -1px;
  font-family: Noto Sans JP, Helvetica;
  font-size: 15px;
  font-weight: 600;
  line-height: 26px;
  position: relative;
}

.recruit-screen .polygon-7 {
  left: unset !important;
  top: unset !important;
  width: 6.25px !important;
  height: 16.55px !important;
  margin-left: -.42px !important;
  margin-right: -.6px !important;
  position: relative !important;
}

.recruit-screen .line-18 {
  flex: 1;
  align-self: stretch;
  width: 100%;
  margin-left: -1px;
  margin-right: -1px;
  position: relative;
}

.recruit-screen .frame-343 {
  flex: none;
  justify-content: space-between;
  align-self: stretch;
  align-items: center;
  width: 100%;
  margin-bottom: -19px;
  padding: 0 10px;
  display: flex;
  position: relative;
}

.recruit-screen .frame-344 {
  background-color: var(--backwhite);
  flex-direction: column;
  flex: none;
  align-self: stretch;
  align-items: center;
  gap: 40px;
  width: 100%;
  padding: 100px 15px;
  display: flex;
  position: relative;
}

.recruit-screen .group-57 {
  width: 562px;
  height: 118px;
  position: relative;
}

.recruit-screen .frame-345 {
  background-color: var(--purple);
  border-radius: 6px;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  gap: 20px 40px;
  min-width: 280px;
  max-width: 700px;
  padding: 30px 70px;
  display: inline-flex;
  position: relative;
}

.recruit-screen .group-58 {
  width: 134px;
  height: 58px;
  position: relative;
}

.recruit-screen .text-wrapper-441 {
  color: var(--white);
  letter-spacing: 2px;
  text-align: center;
  font-family: Zen Kaku Gothic New, Helvetica;
  font-size: 20px;
  font-weight: 500;
  line-height: normal;
  position: absolute;
  top: 0;
  left: 0;
}

.recruit-screen .text-wrapper-442 {
  color: var(--white);
  letter-spacing: 0;
  text-align: center;
  font-family: Slackside One, Helvetica;
  font-size: 20px;
  font-weight: 400;
  line-height: normal;
  position: absolute;
  top: 29px;
  left: 24px;
}

.recruit-screen .group-59 {
  width: 254px;
  height: 55px;
  margin-right: -2px;
  position: relative;
}

.recruit-screen .flexcontainer-45 {
  flex-direction: column;
  align-items: flex-start;
  gap: 5px;
  width: 252px;
  height: 55px;
  display: flex;
  position: relative;
}

.recruit-screen .text-31 {
  color: var(--white);
  letter-spacing: .75px;
  align-self: stretch;
  font-family: Noto Sans JP, Helvetica;
  font-size: 15px;
  font-weight: 400;
  line-height: 25px;
  position: relative;
}

.recruit-screen .text-wrapper-443 {
  letter-spacing: .11px;
}

.recruit-screen .footer-15 {
  background-color: #454545;
  flex-direction: column;
  flex: none;
  align-self: stretch;
  align-items: center;
  gap: 50px;
  width: 100%;
  padding: 70px 30px 50px;
  display: flex;
  position: relative;
  overflow: hidden;
}

.recruit-screen .frame-346 {
  flex-wrap: wrap;
  flex: none;
  justify-content: space-between;
  align-items: flex-start;
  gap: 50px 150px;
  width: 1200px;
  margin-left: -235px;
  margin-right: -235px;
  padding: 0 15px;
  display: flex;
  position: relative;
}

.recruit-screen .frame-347 {
  flex-direction: column;
  align-items: flex-start;
  gap: 44px;
  width: 617px;
  display: flex;
  position: relative;
  overflow: hidden;
}

.recruit-screen .logo-white-10 {
  left: unset !important;
  top: unset !important;
  width: 163.65px !important;
  height: 54.26px !important;
  margin-top: -1684.92px !important;
  margin-left: -17159px !important;
  position: relative !important;
}

.recruit-screen .flexcontainer-46 {
  flex-direction: column;
  align-items: flex-start;
  gap: 5px;
  display: flex;
}

.recruit-screen .text-i-47 {
  color: #fff;
  font-family: var(--text-font-family);
  font-size: var(--text-font-size);
  font-style: var(--text-font-style);
  font-weight: var(--text-font-weight);
  letter-spacing: var(--text-letter-spacing);
  line-height: var(--text-line-height);
  align-self: stretch;
  position: relative;
}

.recruit-screen .text-wrapper-444 {
  font-family: var(--text-font-family);
  font-size: var(--text-font-size);
  font-style: var(--text-font-style);
  font-weight: var(--text-font-weight);
  letter-spacing: var(--text-letter-spacing);
  line-height: var(--text-line-height);
}

.recruit-screen .frame-348 {
  flex: none;
  align-items: flex-start;
  gap: 60px;
  display: inline-flex;
  position: relative;
}

.recruit-screen .frame-349 {
  flex-direction: column;
  flex: none;
  align-items: flex-start;
  gap: 30px;
  display: inline-flex;
  position: relative;
}

.recruit-screen .frame-350 {
  flex-direction: column;
  flex: none;
  align-items: flex-start;
  gap: 6px;
  display: inline-flex;
  position: relative;
}

.recruit-screen .text-wrapper-445 {
  color: #fff;
  font-family: var(--text-bold-mini-font-family);
  font-size: var(--text-bold-mini-font-size);
  font-style: var(--text-bold-mini-font-style);
  font-weight: var(--text-bold-mini-font-weight);
  letter-spacing: var(--text-bold-mini-letter-spacing);
  line-height: var(--text-bold-mini-line-height);
  white-space: nowrap;
  width: fit-content;
  margin-top: -1px;
  position: relative;
}

.recruit-screen .text-wrapper-446 {
  color: #fff;
  font-family: var(--text-bold-mini-font-family);
  font-size: var(--text-bold-mini-font-size);
  font-style: var(--text-bold-mini-font-style);
  font-weight: var(--text-bold-mini-font-weight);
  letter-spacing: var(--text-bold-mini-letter-spacing);
  line-height: var(--text-bold-mini-line-height);
  white-space: nowrap;
  width: fit-content;
  position: relative;
}

.recruit-screen .text-wrapper-447 {
  color: #fff;
  font-family: var(--text-bold-mini-font-family);
  font-size: var(--text-bold-mini-font-size);
  font-style: var(--text-bold-mini-font-style);
  font-weight: var(--text-bold-mini-font-weight);
  letter-spacing: var(--text-bold-mini-letter-spacing);
  line-height: var(--text-bold-mini-line-height);
  white-space: nowrap;
  width: fit-content;
  display: block;
  position: relative;
}

.recruit-screen .text-wrapper-448 {
  color: #fff;
  letter-spacing: 1.31px;
  width: fit-content;
  font-family: Manrope, Helvetica;
  font-size: 13.1px;
  font-weight: 400;
  line-height: normal;
  position: relative;
}

.home-screen {
  flex-direction: column;
  align-items: center;
  display: flex;
  position: relative;
}

.home-screen .z {
  z-index: 1 !important;
  flex: none !important;
  justify-content: space-between !important;
  align-self: stretch !important;
  width: 100% !important;
  padding: 0 0 20px 20px !important;
  display: flex !important;
}

.home-screen .z-2 {
  z-index: 1 !important;
}

.home-screen .home-2 {
  z-index: 0;
  flex-direction: column;
  flex: none;
  align-self: stretch;
  align-items: center;
  width: 100%;
  margin-top: -90px;
  display: flex;
  position: relative;
}

.home-screen .FV-wrapper {
  flex-direction: column;
  justify-content: flex-end;
  align-self: stretch;
  align-items: center;
  gap: 10px;
  width: 100%;
  height: 752px;
  display: flex;
  position: relative;
}

.home-screen .FV-3 {
  object-fit: cover;
  flex: 1;
  align-self: stretch;
  width: 100%;
  position: relative;
}

.home-screen .LEAD-3 {
  background-color: var(--backwhite);
  flex-direction: column;
  flex: none;
  align-self: stretch;
  align-items: center;
  gap: 50px;
  width: 100%;
  padding: 0 30px 100px;
  display: flex;
  position: relative;
}

.home-screen .lead-ill-2 {
  width: 396px !important;
  height: 248px !important;
}

.home-screen .lead-text-3 {
  flex-direction: column;
  align-self: stretch;
  align-items: center;
  gap: 40px;
  width: 100%;
  height: 324px;
  display: flex;
  position: relative;
}

.home-screen .flexcontainer-47 {
  flex-direction: column;
  align-items: flex-start;
  gap: 2px;
  display: inline-flex;
}

.home-screen .text-32 {
  color: var(--darkgray);
  letter-spacing: 2.3px;
  text-align: center;
  align-self: stretch;
  font-family: Noto Sans JP, Helvetica;
  font-size: 23px;
  font-weight: 700;
  line-height: 43px;
  position: relative;
}

.home-screen .text-wrapper-449 {
  letter-spacing: .53px;
}

.home-screen .text-wrapper-450 {
  color: var(--darkgray);
  font-family: var(--lead-font-family);
  font-size: var(--lead-font-size);
  font-style: var(--lead-font-style);
  font-weight: var(--lead-font-weight);
  letter-spacing: var(--lead-letter-spacing);
  line-height: var(--lead-line-height);
  align-self: stretch;
  position: relative;
}

.home-screen .FLOW-3 {
  background-image: url("flow.bddebfa8.png");
  background-position: 50%;
  background-size: cover;
  flex-direction: column;
  flex: none;
  align-self: stretch;
  align-items: center;
  gap: 60px;
  width: 100%;
  padding: 100px 20px;
  display: flex;
  position: relative;
}

.home-screen .heading-36 {
  position: relative !important;
}

.home-screen .heading-37 {
  left: -44px !important;
}

.home-screen .heading-38 {
  left: 24px !important;
}

.home-screen .flexcontainer-48 {
  flex-direction: column;
  align-items: flex-start;
  gap: 5px;
  display: flex;
}

.home-screen .text-33 {
  color: #454545;
  font-family: var(--lead-font-family);
  font-size: var(--lead-font-size);
  font-style: var(--lead-font-style);
  font-weight: var(--lead-font-weight);
  letter-spacing: var(--lead-letter-spacing);
  line-height: var(--lead-line-height);
  text-align: center;
  align-self: stretch;
  position: relative;
}

.home-screen .text-wrapper-451 {
  font-family: var(--lead-font-family);
  font-size: var(--lead-font-size);
  font-style: var(--lead-font-style);
  font-weight: var(--lead-font-weight);
  letter-spacing: var(--lead-letter-spacing);
  line-height: var(--lead-line-height);
}

.home-screen .processe-4 {
  flex-wrap: wrap;
  flex: none;
  justify-content: center;
  align-self: stretch;
  align-items: flex-start;
  gap: 30px 10px;
  width: 100%;
  max-width: 1200px;
  display: flex;
  position: relative;
}

.home-screen .process-18 {
  max-width: unset !important;
  flex: none !important;
}

.home-screen .process-19 {
  align-self: stretch !important;
  width: 100% !important;
  display: flex !important;
}

.home-screen .process-20 {
  max-width: unset !important;
  flex: none !important;
  height: 287px !important;
}

.home-screen .process-21 {
  flex-direction: column;
  flex: none;
  align-items: center;
  gap: 20px;
  padding: 10px 15px;
  display: inline-flex;
  position: relative;
}

.home-screen .frame-351 {
  background-color: var(--white);
  border-radius: 10px;
  flex-direction: column;
  flex: none;
  align-self: stretch;
  align-items: center;
  gap: 10px;
  width: 100%;
  padding: 17px 0;
  display: flex;
  position: relative;
  overflow: hidden;
}

.home-screen .image-9 {
  object-fit: cover;
  width: 179px;
  height: 145px;
  position: relative;
}

.home-screen .frame-352 {
  flex-direction: column;
  flex: none;
  align-items: center;
  gap: 10px;
  padding: 0 16px;
  display: inline-flex;
  position: relative;
}

.home-screen .process-22 {
  color: var(--purple);
  letter-spacing: 1.51px;
  text-align: center;
  width: fit-content;
  margin-top: -1px;
  font-family: Manrope, Helvetica;
  font-size: 15.1px;
  font-weight: 800;
  line-height: normal;
  position: relative;
}

.home-screen .text-i-48 {
  color: #272727;
  letter-spacing: 1px;
  text-align: center;
  align-self: stretch;
  font-family: Noto Sans JP, Helvetica;
  font-size: 20px;
  font-weight: 700;
  line-height: 30px;
  position: relative;
}

.home-screen .text-wrapper-452 {
  letter-spacing: .2px;
}

.home-screen .button-17 {
  flex: none !important;
}

.home-screen .button-18 {
  width: 7.41px !important;
  height: 11.13px !important;
  margin-left: -1.65px !important;
  margin-right: -1.15px !important;
  position: relative !important;
}

.home-screen .BLOGS-3 {
  background-color: var(--backwhite);
  flex-direction: column;
  flex: none;
  align-self: stretch;
  align-items: center;
  gap: 60px;
  width: 100%;
  padding: 100px 15px;
  display: flex;
  position: relative;
}

.home-screen .heading-39 {
  left: 28px !important;
}

.home-screen .div-26 {
  flex-wrap: wrap;
  flex: none;
  justify-content: center;
  align-self: stretch;
  align-items: flex-start;
  gap: 30px 10px;
  width: 100%;
  display: flex;
  position: relative;
}

.home-screen .frame-353 {
  background-color: #fff;
  border-radius: 10px;
  flex: 1;
  align-items: flex-start;
  gap: 14px;
  transition: all .3s;
  display: flex;
  position: relative;
}

.home-screen .frame-354 {
  background-color: #fff;
  border: 1px solid #efeeee;
  border-radius: 10px;
  flex-direction: column;
  flex: 1;
  align-items: flex-start;
  gap: 14px;
  height: 395px;
  padding: 12px 12px 20px;
  display: flex;
  position: relative;
}

.home-screen .frame-355 {
  background-color: #959595;
  border-radius: 6px;
  flex: 1;
  justify-content: center;
  align-self: stretch;
  align-items: center;
  gap: 10px;
  width: 100%;
  min-height: 200px;
  max-height: 220px;
  display: flex;
  position: relative;
}

.home-screen .noimage-10 {
  left: unset !important;
  top: unset !important;
  width: 190.9px !important;
  height: 25.92px !important;
  position: relative !important;
}

.home-screen .text-wrapper-453 {
  color: #8a8a8a;
  letter-spacing: 1.2px;
  width: fit-content;
  font-family: Manrope, Helvetica;
  font-size: 12px;
  font-weight: 400;
  line-height: normal;
  position: relative;
}

.home-screen .text-wrapper-454 {
  -webkit-line-clamp: 2;
  color: #454545;
  letter-spacing: .75px;
  text-overflow: ellipsis;
  -webkit-box-orient: vertical;
  align-self: stretch;
  font-family: Noto Sans JP, Helvetica;
  font-size: 15px;
  font-weight: 700;
  line-height: 26px;
  display: -webkit-box;
  position: relative;
  overflow: hidden;
}

.home-screen .vector-3 {
  object-fit: cover;
  align-self: stretch;
  width: 100%;
  height: 1px;
  position: relative;
}

.home-screen .text-wrapper-455 {
  -webkit-line-clamp: 2;
  color: var(--purple);
  letter-spacing: .65px;
  text-overflow: ellipsis;
  -webkit-box-orient: vertical;
  align-self: stretch;
  font-family: Noto Sans JP, Helvetica;
  font-size: 13px;
  font-weight: 500;
  line-height: 18.8px;
  display: -webkit-box;
  position: relative;
  overflow: hidden;
}

.home-screen .RECRUITES-3 {
  background-color: var(--palepurple);
  flex-direction: column;
  flex: none;
  align-self: stretch;
  align-items: center;
  gap: 60px;
  width: 100%;
  padding: 100px 20px;
  display: flex;
  position: relative;
}

.home-screen .heading-40 {
  left: -10px !important;
}

.home-screen .heading-41 {
  left: 10px !important;
}

.home-screen .frame-356 {
  background-color: #fff;
  border: 1px solid #efeeee;
  border-radius: 10px;
  flex-direction: column;
  flex: 1;
  align-items: flex-start;
  gap: 14px;
  height: 442px;
  padding: 12px 12px 20px;
  transition: all .3s;
  display: flex;
  position: relative;
}

.home-screen .noimage-13 {
  width: 190.9px !important;
  height: 25.92px !important;
  position: relative !important;
}

.home-screen .frame-357 {
  flex-wrap: wrap;
  flex: none;
  justify-content: space-between;
  align-self: stretch;
  align-items: center;
  gap: 10px 14px;
  width: 100%;
  padding: 0 5px;
  display: flex;
  position: relative;
}

.home-screen .frame-15-instance {
  width: 120px !important;
}

.home-screen .frame-358 {
  margin-left: -12px !important;
  margin-right: -12px !important;
}

.home-screen .frame-359 {
  flex: none;
  align-items: center;
  gap: 10px;
  margin-left: -120px;
  display: inline-flex;
  position: relative;
}

.home-screen .text-wrapper-456 {
  color: #8a8a8a;
  letter-spacing: 1.2px;
  width: fit-content;
  margin-top: -1px;
  font-family: Manrope, Helvetica;
  font-size: 12px;
  font-weight: 400;
  line-height: normal;
  position: relative;
}

.home-screen .ABOUTUS-3 {
  background-image: url("aboutus.ff037ac8.png");
  background-position: 50%;
  background-size: cover;
  flex-direction: column;
  flex: none;
  align-self: stretch;
  align-items: center;
  gap: 80px;
  width: 100%;
  padding: 120px 20px;
  display: flex;
  position: relative;
}

.home-screen .heading-42 {
  color: #fff !important;
  left: -10px !important;
}

.home-screen .heading-43 {
  color: #fff !important;
}

.home-screen .frame-360 {
  flex: none;
  justify-content: center;
  align-items: flex-start;
  gap: 40px;
  width: 700px;
  display: flex;
  position: relative;
}

.home-screen .white-button {
  background-color: var(--white);
  border-radius: 76px;
  flex-direction: column;
  flex: 1;
  justify-content: space-around;
  align-items: center;
  gap: 10px;
  height: 70px;
  padding: 16px 0;
  display: flex;
  position: relative;
}

.home-screen .frame-361 {
  background-color: #fff;
  border-radius: 100px;
  flex: none;
  justify-content: space-around;
  align-items: center;
  gap: 10px;
  width: 300px;
  margin-top: -16.5px;
  margin-bottom: -16.5px;
  padding: 24px 0;
  display: flex;
  position: relative;
  overflow: hidden;
}

.home-screen .text-wrapper-457 {
  color: #454545;
  letter-spacing: .8px;
  text-align: center;
  width: fit-content;
  margin-top: -1px;
  font-family: Noto Sans JP, Helvetica;
  font-size: 16px;
  font-weight: 700;
  line-height: normal;
  position: relative;
}

.home-screen .CONTACT-28 {
  background-color: var(--backwhite);
  flex-direction: column;
  flex: none;
  align-self: stretch;
  align-items: center;
  gap: 40px;
  width: 100%;
  padding: 100px 20px;
  display: flex;
  position: relative;
}

.home-screen .CONTACT-29 {
  width: 704px;
  height: 160px;
  position: relative;
}

.home-screen .frame-362 {
  background-color: var(--purple);
  border-radius: 6px;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  gap: 20px 74px;
  width: 700px;
  max-width: 700px;
  padding: 50px 60px;
  display: flex;
  position: relative;
  left: 2px;
}

.home-screen .group-60 {
  width: 216px;
  height: 60px;
  position: relative;
}

.home-screen .overlap-group-15 {
  width: 212px;
  height: 60px;
  position: relative;
}

.home-screen .text-wrapper-458 {
  color: var(--white);
  letter-spacing: 2.4px;
  text-align: center;
  width: 212px;
  font-family: Zen Kaku Gothic New, Helvetica;
  font-size: 24px;
  font-weight: 500;
  line-height: normal;
  position: absolute;
  top: 0;
  left: 0;
}

.home-screen .text-wrapper-459 {
  color: var(--white);
  letter-spacing: 0;
  text-align: center;
  width: 134px;
  font-family: Slackside One, Helvetica;
  font-size: 20px;
  font-weight: 400;
  line-height: normal;
  position: absolute;
  top: 31px;
  left: 39px;
}

.home-screen .group-61 {
  width: 254px;
  height: 55px;
  position: relative;
}

.home-screen .flexcontainer-49 {
  flex-direction: column;
  align-items: flex-start;
  gap: 5px;
  width: 252px;
  height: 55px;
  display: flex;
  position: relative;
}

.home-screen .text-i-49 {
  color: var(--white);
  letter-spacing: .75px;
  align-self: stretch;
  font-family: Noto Sans JP, Helvetica;
  font-size: 15px;
  font-weight: 400;
  line-height: 25px;
  position: relative;
}

.home-screen .text-wrapper-460 {
  letter-spacing: .11px;
}

.home-screen .footer-16 {
  background-color: #454545;
  flex-direction: column;
  flex: none;
  align-self: stretch;
  align-items: center;
  gap: 50px;
  width: 100%;
  padding: 70px 20px 50px;
  display: flex;
  position: relative;
}

.home-screen .frame-363 {
  flex-wrap: wrap;
  flex: none;
  justify-content: space-between;
  align-self: stretch;
  align-items: flex-start;
  gap: 50px 150px;
  width: 100%;
  display: flex;
  position: relative;
}

.home-screen .frame-364 {
  flex-direction: column;
  flex: none;
  align-items: flex-start;
  gap: 30px;
  display: inline-flex;
  position: relative;
}

.home-screen .logo-white-11 {
  left: unset !important;
  top: unset !important;
  width: 163.65px !important;
  height: 54.26px !important;
  position: relative !important;
}

.home-screen .text-i-50 {
  color: #fff;
  font-family: var(--text-font-family);
  font-size: var(--text-font-size);
  font-style: var(--text-font-style);
  font-weight: var(--text-font-weight);
  letter-spacing: var(--text-letter-spacing);
  line-height: var(--text-line-height);
  align-self: stretch;
  position: relative;
}

.home-screen .text-wrapper-461 {
  font-family: var(--text-font-family);
  font-size: var(--text-font-size);
  font-style: var(--text-font-style);
  font-weight: var(--text-font-weight);
  letter-spacing: var(--text-letter-spacing);
  line-height: var(--text-line-height);
}

.home-screen .frame-365 {
  flex: none;
  align-items: flex-start;
  gap: 60px;
  display: inline-flex;
  position: relative;
}

.home-screen .frame-366 {
  flex-direction: column;
  flex: none;
  align-items: flex-start;
  gap: 6px;
  display: inline-flex;
  position: relative;
}

.home-screen .text-wrapper-462 {
  color: #fff;
  font-family: var(--text-bold-mini-font-family);
  font-size: var(--text-bold-mini-font-size);
  font-style: var(--text-bold-mini-font-style);
  font-weight: var(--text-bold-mini-font-weight);
  letter-spacing: var(--text-bold-mini-letter-spacing);
  line-height: var(--text-bold-mini-line-height);
  white-space: nowrap;
  width: fit-content;
  margin-top: -1px;
  position: relative;
}

.home-screen .text-wrapper-463 {
  color: #fff;
  font-family: var(--text-bold-mini-font-family);
  font-size: var(--text-bold-mini-font-size);
  font-style: var(--text-bold-mini-font-style);
  font-weight: var(--text-bold-mini-font-weight);
  letter-spacing: var(--text-bold-mini-letter-spacing);
  line-height: var(--text-bold-mini-line-height);
  white-space: nowrap;
  width: fit-content;
  position: relative;
}

.home-screen .text-wrapper-464 {
  color: #fff;
  font-family: var(--text-bold-mini-font-family);
  font-size: var(--text-bold-mini-font-size);
  font-style: var(--text-bold-mini-font-style);
  font-weight: var(--text-bold-mini-font-weight);
  letter-spacing: var(--text-bold-mini-letter-spacing);
  line-height: var(--text-bold-mini-line-height);
  white-space: nowrap;
  width: fit-content;
  display: block;
  position: relative;
}

.home-screen .text-wrapper-465 {
  color: #fff;
  letter-spacing: 1.31px;
  width: fit-content;
  font-family: Manrope, Helvetica;
  font-size: 13.1px;
  font-weight: 400;
  line-height: normal;
  position: relative;
}

.recruit-wrapper {
  background-color: #fff;
  flex-direction: column;
  align-items: flex-start;
  display: flex;
  position: relative;
}

.recruit-wrapper .div-27 {
  flex-direction: column;
  flex: none;
  align-self: stretch;
  align-items: flex-start;
  width: 100%;
  display: flex;
  position: relative;
}

.recruit-wrapper .logo-6 {
  flex: none;
  justify-content: space-between;
  align-self: stretch;
  align-items: flex-start;
  width: 100%;
  padding: 0 0 20px 30px;
  display: flex;
  position: relative;
}

.recruit-wrapper .frame-367 {
  width: 164px;
  height: 53px;
  position: relative;
}

.recruit-wrapper .group-62 {
  background-image: url("zsykr-0.252254e0.png");
  background-size: 100% 100%;
  width: 101px;
  height: 38px;
  position: relative;
  top: 15px;
}

.recruit-wrapper .menu-15 {
  width: 53px;
  height: 52px;
  position: relative;
}

.recruit-wrapper .midashi-10 {
  background-image: url("midashi.dc95bedc.png");
  background-position: 50%;
  background-size: cover;
  flex-direction: column;
  flex: none;
  align-self: stretch;
  align-items: center;
  gap: 10px;
  width: 100%;
  padding: 10px 0;
  display: flex;
  position: relative;
}

.recruit-wrapper .component-3 {
  width: 108px;
  height: 89px;
  position: relative;
}

.recruit-wrapper .text-wrapper-466 {
  color: #fff;
  letter-spacing: 1.4px;
  text-align: center;
  white-space: nowrap;
  font-family: Zen Kaku Gothic New, Helvetica;
  font-size: 28px;
  font-weight: 500;
  line-height: normal;
  position: absolute;
  top: 20px;
  left: -4px;
}

.recruit-wrapper .TEXTEXT-3 {
  color: #fff;
  letter-spacing: 0;
  text-align: center;
  white-space: nowrap;
  font-family: Slackside One, Helvetica;
  font-size: 18px;
  font-weight: 400;
  line-height: normal;
  position: absolute;
  top: 54px;
  left: 22px;
}

.recruit-wrapper .frame-368 {
  flex: none;
  align-self: stretch;
  align-items: center;
  gap: 30px;
  width: 100%;
  padding: 20px 30px;
  display: flex;
  position: relative;
}

.recruit-wrapper .group-63 {
  width: 137.57px;
  height: 15px;
  position: relative;
}

.recruit-wrapper .text-wrapper-467 {
  color: var(--gray);
  font-family: var(--tag-font-family);
  font-size: var(--tag-font-size);
  font-style: var(--tag-font-style);
  font-weight: var(--tag-font-weight);
  letter-spacing: var(--tag-letter-spacing);
  line-height: var(--tag-line-height);
  white-space: nowrap;
  display: block;
  position: absolute;
  top: 0;
  left: 0;
}

.recruit-wrapper .component-10930-6 {
  top: 3px !important;
  left: 58px !important;
}

.recruit-wrapper .text-wrapper-468 {
  color: var(--gray);
  font-family: var(--tag-font-family);
  font-size: var(--tag-font-size);
  font-style: var(--tag-font-style);
  font-weight: var(--tag-font-weight);
  letter-spacing: var(--tag-letter-spacing);
  line-height: var(--tag-line-height);
  white-space: nowrap;
  position: absolute;
  top: 0;
  left: 93px;
}

.recruit-wrapper .view-7 {
  background-color: var(--palepurple);
  flex-direction: column;
  flex: none;
  align-self: stretch;
  align-items: center;
  gap: 50px;
  width: 100%;
  padding: 50px 10px;
  display: flex;
  position: relative;
}

.recruit-wrapper .frame-369 {
  flex-direction: column;
  flex: none;
  justify-content: center;
  align-self: stretch;
  align-items: flex-start;
  gap: 20px;
  width: 100%;
  display: flex;
  position: relative;
}

.recruit-wrapper .frame-370 {
  background-color: var(--back-white);
  border-radius: 14px;
  flex-direction: column;
  flex: none;
  align-self: stretch;
  align-items: flex-start;
  gap: 32px;
  width: 100%;
  padding: 50px 20px;
  display: flex;
  position: relative;
}

.recruit-wrapper .frame-371 {
  flex-direction: column;
  flex: none;
  align-self: stretch;
  align-items: flex-start;
  gap: 30px;
  width: 100%;
  max-width: 800px;
  display: flex;
  position: relative;
}

.recruit-wrapper .group-64 {
  width: 93px;
  height: 18.74px;
  position: relative;
}

.recruit-wrapper .text-wrapper-469 {
  color: var(--black);
  font-family: var(--date-font-family);
  font-size: var(--date-font-size);
  font-style: var(--date-font-style);
  font-weight: var(--date-font-weight);
  letter-spacing: var(--date-letter-spacing);
  line-height: var(--date-line-height);
  width: 91px;
  position: absolute;
  top: 0;
  left: 0;
}

.recruit-wrapper .frame-372 {
  flex-wrap: wrap;
  flex: none;
  align-self: stretch;
  align-items: flex-end;
  gap: 0 28px;
  width: 100%;
  display: flex;
  position: relative;
}

.recruit-wrapper .text-wrapper-470 {
  color: var(--purple);
  font-family: var(--tag-font-family);
  font-size: var(--tag-font-size);
  font-style: var(--tag-font-style);
  font-weight: var(--tag-font-weight);
  letter-spacing: var(--tag-letter-spacing);
  line-height: var(--tag-line-height);
  white-space: nowrap;
  width: fit-content;
  position: relative;
}

.recruit-wrapper .text-wrapper-471 {
  color: var(--purple);
  font-family: var(--tag-font-family);
  font-size: var(--tag-font-size);
  font-style: var(--tag-font-style);
  font-weight: var(--tag-font-weight);
  letter-spacing: var(--tag-letter-spacing);
  line-height: var(--tag-line-height);
  white-space: nowrap;
  width: 43px;
  height: 17px;
  margin-top: -1px;
  position: relative;
}

.recruit-wrapper .text-wrapper-472 {
  color: var(--purple);
  font-family: var(--tag-font-family);
  font-size: var(--tag-font-size);
  font-style: var(--tag-font-style);
  font-weight: var(--tag-font-weight);
  letter-spacing: var(--tag-letter-spacing);
  line-height: var(--tag-line-height);
  white-space: nowrap;
  width: 43px;
  height: 17px;
  position: relative;
}

.recruit-wrapper .div-28 {
  color: #454545;
  letter-spacing: 1px;
  align-self: stretch;
  font-family: Noto Sans JP, Helvetica;
  font-size: 20px;
  font-weight: 700;
  line-height: 20px;
  position: relative;
}

.recruit-wrapper .text-wrapper-473 {
  letter-spacing: .2px;
  line-height: 37px;
}

.recruit-wrapper .text-wrapper-474 {
  letter-spacing: .2px;
  line-height: 36px;
}

.recruit-wrapper .grayline-7 {
  top: unset !important;
  background-image: url("line-13-1.f8d35aae.svg") !important;
  align-self: stretch !important;
  width: 100% !important;
}

.recruit-wrapper .frame-373 {
  flex-direction: column;
  flex: none;
  align-self: stretch;
  align-items: flex-start;
  gap: 31px;
  width: 100%;
  display: flex;
  position: relative;
}

.recruit-wrapper .frame-374 {
  background-color: var(--gray);
  border-radius: 6px;
  flex-direction: column;
  justify-content: center;
  align-self: stretch;
  align-items: center;
  gap: 10px;
  width: 100%;
  height: 233.92px;
  padding: 104px 70px;
  display: flex;
  position: relative;
  overflow: hidden;
}

.recruit-wrapper .noimage-11 {
  left: unset !important;
  top: unset !important;
  width: 196.71px !important;
  height: 25.92px !important;
  margin-left: -38.35px !important;
  margin-right: -38.35px !important;
  position: relative !important;
}

.recruit-wrapper .frame-375 {
  background-color: var(--gray);
  border-radius: 6px;
  flex-direction: column;
  flex: none;
  justify-content: center;
  align-self: stretch;
  align-items: center;
  gap: 10px;
  width: 100%;
  padding: 104px 70px;
  display: flex;
  position: relative;
  overflow: hidden;
}

.recruit-wrapper .text-wrapper-475 {
  color: #454545;
  font-family: var(--text-font-family);
  font-size: var(--text-font-size);
  font-style: var(--text-font-style);
  font-weight: var(--text-font-weight);
  letter-spacing: var(--text-letter-spacing);
  line-height: var(--text-line-height);
  align-self: stretch;
  position: relative;
}

.recruit-wrapper .frame-376 {
  flex: none;
  justify-content: space-between;
  align-self: stretch;
  align-items: flex-start;
  width: 100%;
  display: flex;
  position: relative;
}

.recruit-wrapper .group-65 {
  flex: 1;
  height: 50px;
  position: relative;
}

.recruit-wrapper .text-wrapper-476 {
  color: var(--darkgray);
  letter-spacing: .75px;
  width: 72px;
  font-family: Noto Sans JP, Helvetica;
  font-size: 15px;
  font-weight: 400;
  line-height: 25px;
  position: absolute;
  top: 0;
  left: 8px;
}

.recruit-wrapper .polygon-8 {
  width: 5px !important;
  top: 5px !important;
  left: -1px !important;
}

.recruit-wrapper .frame-377 {
  flex: 1 !important;
  min-width: 140px !important;
  max-width: 200px !important;
  margin-left: -2px !important;
  padding: 17px 30px !important;
  display: flex !important;
}

.recruit-wrapper .frame-378 {
  font-weight: 700 !important;
}

.recruit-wrapper .group-66 {
  flex: 1;
  height: 50px;
  margin-left: -2px;
  margin-right: -2px;
  position: relative;
}

.recruit-wrapper .text-wrapper-477 {
  color: var(--darkgray);
  letter-spacing: .75px;
  text-align: right;
  width: 69px;
  font-family: Noto Sans JP, Helvetica;
  font-size: 15px;
  font-weight: 400;
  line-height: 25px;
  position: absolute;
  top: 0;
  left: 0;
}

.recruit-wrapper .polygon-9 {
  width: 5px !important;
  top: 5px !important;
  left: 76px !important;
}

.recruit-wrapper .frame-379 {
  background-color: #6079c1;
  border-radius: 10px 10px 0 0;
  flex-direction: column;
  flex: none;
  align-self: stretch;
  align-items: center;
  gap: 30px;
  width: 100%;
  padding: 20px 15px;
  display: flex;
  position: relative;
}

.recruit-wrapper .text-wrapper-478 {
  color: #fff;
  letter-spacing: 0;
  text-align: center;
  width: fit-content;
  margin-top: -1px;
  font-family: Noto Sans JP, Helvetica;
  font-size: 16px;
  font-weight: 700;
  line-height: normal;
  position: relative;
}

.recruit-wrapper .frame-380 {
  background-color: #fff;
  border-radius: 0 0 10px 10px;
  flex-direction: column;
  align-self: stretch;
  align-items: center;
  gap: 30px;
  width: 100%;
  height: 430px;
  padding: 30px 15px 45px;
  display: flex;
  position: relative;
}

.recruit-wrapper .frame-381 {
  flex: none;
  justify-content: space-between;
  align-self: stretch;
  align-items: center;
  width: 100%;
  padding: 0 10px;
  display: flex;
  position: relative;
}

.recruit-wrapper .text-wrapper-479 {
  color: var(--darkgray);
  letter-spacing: .75px;
  flex: 1;
  margin-top: -1px;
  font-family: Noto Sans JP, Helvetica;
  font-size: 15px;
  font-weight: 600;
  line-height: 26px;
  position: relative;
}

.recruit-wrapper .polygon-10 {
  left: unset !important;
  top: unset !important;
  width: 6.25px !important;
  height: 16.55px !important;
  margin-left: -.42px !important;
  margin-right: -.6px !important;
  position: relative !important;
}

.recruit-wrapper .line-19 {
  flex: 1;
  align-self: stretch;
  width: 100%;
  margin-left: -1px;
  margin-right: -1px;
  position: relative;
}

.recruit-wrapper .frame-382 {
  flex: none;
  justify-content: space-between;
  align-self: stretch;
  align-items: center;
  width: 100%;
  margin-bottom: -19px;
  padding: 0 10px;
  display: flex;
  position: relative;
}

.recruit-wrapper .CONTACT-30 {
  background-color: var(--backwhite);
  flex-direction: column;
  flex: none;
  justify-content: center;
  align-self: stretch;
  align-items: center;
  gap: 40px;
  width: 100%;
  padding: 100px 20px;
  display: flex;
  position: relative;
}

.recruit-wrapper .CONTACT-31 {
  width: 280px;
  height: 160px;
  position: relative;
}

.recruit-wrapper .frame-383 {
  background-color: var(--purple);
  border-radius: 6px;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  gap: 20px 74px;
  width: 280px;
  max-width: 700px;
  padding: 50px 60px;
  display: flex;
  position: relative;
  top: -38px;
}

.recruit-wrapper .group-67 {
  width: 216px;
  height: 60px;
  margin-left: -26px;
  margin-right: -30px;
  position: relative;
}

.recruit-wrapper .overlap-group-16 {
  width: 212px;
  height: 60px;
  position: relative;
}

.recruit-wrapper .text-wrapper-480 {
  color: var(--white);
  letter-spacing: 2.4px;
  text-align: center;
  width: 212px;
  font-family: Zen Kaku Gothic New, Helvetica;
  font-size: 24px;
  font-weight: 500;
  line-height: normal;
  position: absolute;
  top: 0;
  left: 0;
}

.recruit-wrapper .text-wrapper-481 {
  color: var(--white);
  letter-spacing: 0;
  text-align: center;
  width: 134px;
  font-family: Slackside One, Helvetica;
  font-size: 20px;
  font-weight: 400;
  line-height: normal;
  position: absolute;
  top: 31px;
  left: 39px;
}

.recruit-wrapper .group-68 {
  width: 254px;
  height: 55px;
  margin-left: -46px;
  margin-right: -48px;
  position: relative;
}

.recruit-wrapper .flexcontainer-50 {
  flex-direction: column;
  align-items: flex-start;
  gap: 5px;
  width: 252px;
  height: 55px;
  display: flex;
  position: relative;
}

.recruit-wrapper .text-i-51 {
  color: var(--white);
  letter-spacing: .75px;
  align-self: stretch;
  font-family: Noto Sans JP, Helvetica;
  font-size: 15px;
  font-weight: 400;
  line-height: 25px;
  position: relative;
}

.recruit-wrapper .text-wrapper-482 {
  letter-spacing: .11px;
}

.recruit-wrapper .footer-17 {
  background-color: #454545;
  flex-direction: column;
  flex: none;
  align-self: stretch;
  align-items: center;
  gap: 50px;
  width: 100%;
  padding: 50px 20px;
  display: flex;
  position: relative;
}

.recruit-wrapper .frame-384 {
  flex-direction: column;
  flex: none;
  align-self: stretch;
  align-items: flex-start;
  gap: 50px;
  width: 100%;
  display: flex;
  position: relative;
}

.recruit-wrapper .frame-385 {
  flex-direction: column;
  flex: none;
  align-self: stretch;
  align-items: flex-start;
  gap: 24px;
  width: 100%;
  display: flex;
  position: relative;
}

.recruit-wrapper .logo-white-13 {
  left: unset !important;
  top: unset !important;
  width: 121px !important;
  height: 40px !important;
  position: relative !important;
}

.recruit-wrapper .flexcontainer-51 {
  flex-direction: column;
  align-items: flex-start;
  gap: 3px;
  display: flex;
}

.recruit-wrapper .text-i-52 {
  color: #fff;
  font-family: var(--smalltext-SP-font-family);
  font-size: var(--smalltext-SP-font-size);
  font-style: var(--smalltext-SP-font-style);
  font-weight: var(--smalltext-SP-font-weight);
  letter-spacing: var(--smalltext-SP-letter-spacing);
  line-height: var(--smalltext-SP-line-height);
  align-self: stretch;
  position: relative;
}

.recruit-wrapper .text-wrapper-483 {
  font-family: var(--smalltext-SP-font-family);
  font-size: var(--smalltext-SP-font-size);
  font-style: var(--smalltext-SP-font-style);
  font-weight: var(--smalltext-SP-font-weight);
  letter-spacing: var(--smalltext-SP-letter-spacing);
  line-height: var(--smalltext-SP-line-height);
}

.recruit-wrapper .frame-386 {
  flex: none;
  align-items: flex-start;
  gap: 60px;
  margin-right: -14px;
  display: inline-flex;
  position: relative;
}

.recruit-wrapper .frame-387 {
  flex-direction: column;
  flex: none;
  align-items: flex-start;
  gap: 30px;
  display: inline-flex;
  position: relative;
}

.recruit-wrapper .frame-388 {
  flex-direction: column;
  flex: none;
  align-items: flex-start;
  gap: 4px;
  display: inline-flex;
  position: relative;
}

.recruit-wrapper .text-wrapper-484 {
  color: #fff;
  font-family: var(--text-bold-mini-font-family);
  font-size: var(--text-bold-mini-font-size);
  font-style: var(--text-bold-mini-font-style);
  font-weight: var(--text-bold-mini-font-weight);
  letter-spacing: var(--text-bold-mini-letter-spacing);
  line-height: var(--text-bold-mini-line-height);
  white-space: nowrap;
  width: fit-content;
  margin-top: -1px;
  display: block;
  position: relative;
}

.recruit-wrapper .text-wrapper-485 {
  color: #fff;
  font-family: var(--text-bold-mini-font-family);
  font-size: var(--text-bold-mini-font-size);
  font-style: var(--text-bold-mini-font-style);
  font-weight: var(--text-bold-mini-font-weight);
  letter-spacing: var(--text-bold-mini-letter-spacing);
  line-height: var(--text-bold-mini-line-height);
  white-space: nowrap;
  width: fit-content;
  position: relative;
}

.recruit-wrapper .text-wrapper-486 {
  color: #fff;
  font-family: var(--text-bold-mini-font-family);
  font-size: var(--text-bold-mini-font-size);
  font-style: var(--text-bold-mini-font-style);
  font-weight: var(--text-bold-mini-font-weight);
  letter-spacing: var(--text-bold-mini-letter-spacing);
  line-height: var(--text-bold-mini-line-height);
  white-space: nowrap;
  width: fit-content;
  margin-top: -1px;
  position: relative;
}

.recruit-wrapper .text-wrapper-487 {
  color: #fff;
  font-family: var(--text-bold-mini-font-family);
  font-size: var(--text-bold-mini-font-size);
  font-style: var(--text-bold-mini-font-style);
  font-weight: var(--text-bold-mini-font-weight);
  letter-spacing: var(--text-bold-mini-letter-spacing);
  line-height: var(--text-bold-mini-line-height);
  white-space: nowrap;
  width: fit-content;
  display: block;
  position: relative;
}

.recruit-wrapper .text-wrapper-488 {
  color: #fff;
  letter-spacing: 1.31px;
  width: fit-content;
  font-family: Manrope, Helvetica;
  font-size: 13.1px;
  font-weight: 400;
  line-height: normal;
  position: relative;
}

.recruits-screen {
  flex-direction: column;
  align-items: center;
  display: flex;
  position: relative;
}

.recruits-screen .header-40 {
  z-index: 1 !important;
  flex: none !important;
  justify-content: space-between !important;
  align-self: stretch !important;
  width: 100% !important;
  display: flex !important;
}

.recruits-screen .header-41 {
  width: 145px !important;
  height: 54px !important;
  position: relative !important;
  top: 16px !important;
}

.recruits-screen .recruits-2 {
  z-index: 0;
  background-color: #fff;
  flex-direction: column;
  flex: none;
  align-self: stretch;
  align-items: flex-start;
  width: 100%;
  display: flex;
  position: relative;
  overflow: hidden;
}

.recruits-screen .midashi-11 {
  flex-direction: column;
  flex: none;
  align-self: stretch;
  align-items: flex-start;
  width: 100%;
  display: flex;
  position: relative;
}

.recruits-screen .heading-44 {
  background-image: url("midashi-1.05af3916.png") !important;
  flex: none !important;
  align-self: stretch !important;
  width: 100% !important;
}

.recruits-screen .heading-45 {
  color: #fff !important;
  left: 10px !important;
}

.recruits-screen .heading-46 {
  color: #fff !important;
  left: -10px !important;
}

.recruits-screen .breadcrumbs-12 {
  flex: none;
  justify-content: center;
  align-self: stretch;
  align-items: flex-start;
  gap: 30px;
  width: 100%;
  padding: 40px 15px;
  display: flex;
  position: relative;
}

.recruits-screen .frame-389 {
  flex: 1;
  align-items: center;
  gap: 30px;
  display: flex;
  position: relative;
}

.recruits-screen .text-wrapper-489 {
  color: var(--gray);
  font-family: var(--tag-font-family);
  font-size: var(--tag-font-size);
  font-style: var(--tag-font-style);
  font-weight: var(--tag-font-weight);
  letter-spacing: var(--tag-letter-spacing);
  line-height: var(--tag-line-height);
  white-space: nowrap;
  width: fit-content;
  margin-top: -1px;
  display: block;
  position: relative;
}

.recruits-screen .component-10930-7 {
  left: unset !important;
  top: unset !important;
  width: 4.57px !important;
  height: 8.14px !important;
  position: relative !important;
}

.recruits-screen .text-wrapper-490 {
  color: var(--gray);
  font-family: var(--tag-font-family);
  font-size: var(--tag-font-size);
  font-style: var(--tag-font-style);
  font-weight: var(--tag-font-weight);
  letter-spacing: var(--tag-letter-spacing);
  line-height: var(--tag-line-height);
  white-space: nowrap;
  width: fit-content;
  margin-top: -1px;
  position: relative;
}

.recruits-screen .recruit-list-2 {
  background-color: var(--palepurple);
  flex-direction: column;
  flex: none;
  align-self: stretch;
  align-items: center;
  gap: 40px;
  width: 100%;
  padding: 80px 15px;
  display: flex;
  position: relative;
}

.recruits-screen .frame-390 {
  flex-wrap: wrap;
  flex: none;
  justify-content: center;
  align-self: stretch;
  align-items: flex-start;
  gap: 30px 10px;
  width: 100%;
  display: flex;
  position: relative;
}

.recruits-screen .frame-391 {
  background-color: #fff;
  border: 1px solid #efeeee;
  border-radius: 10px;
  flex-direction: column;
  flex: 1;
  align-items: flex-start;
  gap: 14px;
  min-width: 290px;
  max-width: 360px;
  height: 442px;
  padding: 12px 12px 20px;
  transition: all .3s;
  display: flex;
  position: relative;
}

.recruits-screen .frame-392 {
  background-color: #959595;
  border-radius: 6px;
  flex: 1;
  justify-content: center;
  align-self: stretch;
  align-items: center;
  gap: 10px;
  width: 100%;
  min-height: 200px;
  max-height: 220px;
  display: flex;
  position: relative;
}

.recruits-screen .noimage-12 {
  width: 190.9px !important;
  height: 25.92px !important;
  position: relative !important;
}

.recruits-screen .frame-393 {
  flex-wrap: wrap;
  flex: none;
  justify-content: space-between;
  align-self: stretch;
  align-items: center;
  gap: 10px 14px;
  width: 100%;
  padding: 0 5px;
  display: flex;
  position: relative;
}

.recruits-screen .frame-394 {
  width: 120px !important;
}

.recruits-screen .frame-395 {
  margin-left: -12px !important;
  margin-right: -12px !important;
}

.recruits-screen .frame-396 {
  flex: none;
  align-items: center;
  gap: 10px;
  padding: 0 0 0 20px;
  display: inline-flex;
  position: relative;
}

.recruits-screen .text-wrapper-491 {
  color: #8a8a8a;
  letter-spacing: 1.2px;
  width: fit-content;
  margin-top: -1px;
  font-family: Manrope, Helvetica;
  font-size: 12px;
  font-weight: 400;
  line-height: normal;
  position: relative;
}

.recruits-screen .vector-4 {
  object-fit: cover;
  align-self: stretch;
  width: 100%;
  height: 1px;
  position: relative;
}

.recruits-screen .text-wrapper-492 {
  -webkit-line-clamp: 2;
  color: #454545;
  letter-spacing: .75px;
  text-overflow: ellipsis;
  -webkit-box-orient: vertical;
  align-self: stretch;
  font-family: Noto Sans JP, Helvetica;
  font-size: 15px;
  font-weight: 700;
  line-height: 26px;
  display: -webkit-box;
  position: relative;
  overflow: hidden;
}

.recruits-screen .frame-397 {
  width: 206.48px;
  height: 16px;
  position: relative;
}

.recruits-screen .text-wrapper-493 {
  color: #454545;
  font-family: var(--tag-font-family);
  font-size: var(--tag-font-size);
  font-style: var(--tag-font-style);
  font-weight: var(--tag-font-weight);
  letter-spacing: var(--tag-letter-spacing);
  line-height: var(--tag-line-height);
  white-space: nowrap;
  position: absolute;
  top: -1px;
  left: 29px;
}

.recruits-screen .text-wrapper-494 {
  color: #454545;
  font-family: var(--tag-font-family);
  font-size: var(--tag-font-size);
  font-style: var(--tag-font-style);
  font-weight: var(--tag-font-weight);
  letter-spacing: var(--tag-letter-spacing);
  line-height: var(--tag-line-height);
  white-space: nowrap;
  position: absolute;
  top: -1px;
  left: 57px;
}

.recruits-screen .text-wrapper-495 {
  color: #454545;
  font-family: var(--tag-font-family);
  font-size: var(--tag-font-size);
  font-style: var(--tag-font-style);
  font-weight: var(--tag-font-weight);
  letter-spacing: var(--tag-letter-spacing);
  line-height: var(--tag-line-height);
  white-space: nowrap;
  position: absolute;
  top: -1px;
  left: 85px;
}

.recruits-screen .text-wrapper-496 {
  color: #454545;
  font-family: var(--tag-font-family);
  font-size: var(--tag-font-size);
  font-style: var(--tag-font-style);
  font-weight: var(--tag-font-weight);
  letter-spacing: var(--tag-letter-spacing);
  line-height: var(--tag-line-height);
  white-space: nowrap;
  position: absolute;
  top: -1px;
  left: 113px;
}

.recruits-screen .text-wrapper-497 {
  color: #454545;
  font-family: var(--tag-font-family);
  font-size: var(--tag-font-size);
  font-style: var(--tag-font-style);
  font-weight: var(--tag-font-weight);
  letter-spacing: var(--tag-letter-spacing);
  line-height: var(--tag-line-height);
  white-space: nowrap;
  position: absolute;
  top: -1px;
  left: 141px;
}

.recruits-screen .text-wrapper-498 {
  color: #454545;
  font-family: var(--tag-font-family);
  font-size: var(--tag-font-size);
  font-style: var(--tag-font-style);
  font-weight: var(--tag-font-weight);
  letter-spacing: var(--tag-letter-spacing);
  line-height: var(--tag-line-height);
  white-space: nowrap;
  position: absolute;
  top: -1px;
  left: 169px;
}

.recruits-screen .polygon-2-7-instance {
  width: 10px !important;
  height: 16px !important;
  position: absolute !important;
  top: 0 !important;
  left: 197px !important;
}

.recruits-screen .polygon-3-2 {
  width: 10px !important;
  height: 16px !important;
  position: absolute !important;
  top: 0 !important;
  left: 0 !important;
}

.recruits-screen .frame-398 {
  background-color: var(--backwhite);
  flex-direction: column;
  flex: none;
  align-self: stretch;
  align-items: center;
  gap: 40px;
  width: 100%;
  padding: 100px 15px;
  display: flex;
  position: relative;
}

.recruits-screen .group-69 {
  width: 562px;
  height: 138px;
  position: relative;
}

.recruits-screen .frame-399 {
  background-color: var(--purple);
  border-radius: 6px;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  gap: 20px 40px;
  min-width: 280px;
  max-width: 700px;
  padding: 40px 70px;
  display: inline-flex;
  position: relative;
}

.recruits-screen .group-70 {
  width: 134px;
  height: 58px;
  position: relative;
}

.recruits-screen .text-wrapper-499 {
  color: var(--white);
  letter-spacing: 2px;
  text-align: center;
  font-family: Zen Kaku Gothic New, Helvetica;
  font-size: 20px;
  font-weight: 500;
  line-height: normal;
  position: absolute;
  top: 0;
  left: 0;
}

.recruits-screen .text-wrapper-500 {
  color: var(--white);
  letter-spacing: 0;
  text-align: center;
  font-family: Slackside One, Helvetica;
  font-size: 20px;
  font-weight: 400;
  line-height: normal;
  position: absolute;
  top: 29px;
  left: 24px;
}

.recruits-screen .group-71 {
  width: 254px;
  height: 55px;
  margin-right: -2px;
  position: relative;
}

.recruits-screen .flexcontainer-52 {
  flex-direction: column;
  align-items: flex-start;
  gap: 5px;
  width: 252px;
  height: 55px;
  display: flex;
  position: relative;
}

.recruits-screen .text-34 {
  color: var(--white);
  letter-spacing: .75px;
  align-self: stretch;
  font-family: Noto Sans JP, Helvetica;
  font-size: 15px;
  font-weight: 400;
  line-height: 25px;
  position: relative;
}

.recruits-screen .text-wrapper-501 {
  letter-spacing: .11px;
}

.recruits-screen .footer-18 {
  background-color: #454545;
  flex-direction: column;
  flex: none;
  align-items: center;
  gap: 50px;
  margin-right: -496px;
  padding: 70px 20px 50px;
  display: inline-flex;
  position: relative;
}

.recruits-screen .frame-400 {
  flex-wrap: wrap;
  flex: none;
  justify-content: space-between;
  align-items: flex-start;
  gap: 50px 150px;
  width: 1200px;
  padding: 0 100px;
  display: flex;
  position: relative;
}

.recruits-screen .frame-401 {
  flex-direction: column;
  align-items: flex-start;
  gap: 44px;
  width: 617px;
  display: flex;
  position: relative;
}

.recruits-screen .logo-white-16 {
  left: unset !important;
  top: unset !important;
  width: 163.65px !important;
  height: 54.26px !important;
  position: relative !important;
}

.recruits-screen .flexcontainer-53 {
  flex-direction: column;
  align-items: flex-start;
  gap: 5px;
  display: flex;
}

.recruits-screen .text-i-53 {
  color: #fff;
  font-family: var(--text-font-family);
  font-size: var(--text-font-size);
  font-style: var(--text-font-style);
  font-weight: var(--text-font-weight);
  letter-spacing: var(--text-letter-spacing);
  line-height: var(--text-line-height);
  align-self: stretch;
  position: relative;
}

.recruits-screen .text-wrapper-502 {
  font-family: var(--text-font-family);
  font-size: var(--text-font-size);
  font-style: var(--text-font-style);
  font-weight: var(--text-font-weight);
  letter-spacing: var(--text-letter-spacing);
  line-height: var(--text-line-height);
}

.recruits-screen .frame-402 {
  flex: none;
  align-items: flex-start;
  gap: 60px;
  display: inline-flex;
  position: relative;
}

.recruits-screen .frame-403 {
  flex-direction: column;
  flex: none;
  align-items: flex-start;
  gap: 30px;
  display: inline-flex;
  position: relative;
}

.recruits-screen .frame-404 {
  flex-direction: column;
  flex: none;
  align-items: flex-start;
  gap: 6px;
  display: inline-flex;
  position: relative;
}

.recruits-screen .text-wrapper-503 {
  color: #fff;
  font-family: var(--text-bold-mini-font-family);
  font-size: var(--text-bold-mini-font-size);
  font-style: var(--text-bold-mini-font-style);
  font-weight: var(--text-bold-mini-font-weight);
  letter-spacing: var(--text-bold-mini-letter-spacing);
  line-height: var(--text-bold-mini-line-height);
  white-space: nowrap;
  width: fit-content;
  margin-top: -1px;
  position: relative;
}

.recruits-screen .text-wrapper-504 {
  color: #fff;
  font-family: var(--text-bold-mini-font-family);
  font-size: var(--text-bold-mini-font-size);
  font-style: var(--text-bold-mini-font-style);
  font-weight: var(--text-bold-mini-font-weight);
  letter-spacing: var(--text-bold-mini-letter-spacing);
  line-height: var(--text-bold-mini-line-height);
  white-space: nowrap;
  width: fit-content;
  position: relative;
}

.recruits-screen .text-wrapper-505 {
  color: #fff;
  font-family: var(--text-bold-mini-font-family);
  font-size: var(--text-bold-mini-font-size);
  font-style: var(--text-bold-mini-font-style);
  font-weight: var(--text-bold-mini-font-weight);
  letter-spacing: var(--text-bold-mini-letter-spacing);
  line-height: var(--text-bold-mini-line-height);
  white-space: nowrap;
  width: fit-content;
  display: block;
  position: relative;
}

.recruits-screen .text-wrapper-506 {
  color: #fff;
  letter-spacing: 1.31px;
  width: fit-content;
  font-family: Manrope, Helvetica;
  font-size: 13.1px;
  font-weight: 400;
  line-height: normal;
  position: relative;
}

.profile-screen {
  flex-direction: column;
  align-items: center;
  display: flex;
  position: relative;
}

.profile-screen .header-42 {
  z-index: 1 !important;
  flex: none !important;
  justify-content: space-between !important;
  align-self: stretch !important;
  width: 100% !important;
  display: flex !important;
}

.profile-screen .header-43 {
  width: 145px !important;
  height: 54px !important;
  position: relative !important;
  top: 16px !important;
}

.profile-screen .profile-2 {
  z-index: 0;
  background-color: #fff;
  flex-direction: column;
  flex: none;
  align-self: stretch;
  align-items: flex-start;
  width: 100%;
  display: flex;
  position: relative;
}

.profile-screen .midashi-12 {
  flex-direction: column;
  flex: none;
  align-self: stretch;
  align-items: flex-start;
  width: 100%;
  display: flex;
  position: relative;
}

.profile-screen .heading-47 {
  background-image: url("midashi-1.05af3916.png") !important;
  flex: none !important;
  align-self: stretch !important;
  width: 100% !important;
}

.profile-screen .heading-48 {
  color: #fff !important;
  left: 9px !important;
}

.profile-screen .heading-49 {
  color: #fff !important;
  left: -10px !important;
}

.profile-screen .breadcrumbs-13 {
  flex: none;
  justify-content: center;
  align-self: stretch;
  align-items: flex-start;
  gap: 30px;
  width: 100%;
  padding: 40px 15px;
  display: flex;
  position: relative;
}

.profile-screen .frame-405 {
  flex: 1;
  align-items: center;
  gap: 30px;
  display: flex;
  position: relative;
}

.profile-screen .text-wrapper-507 {
  color: var(--gray);
  font-family: var(--tag-font-family);
  font-size: var(--tag-font-size);
  font-style: var(--tag-font-style);
  font-weight: var(--tag-font-weight);
  letter-spacing: var(--tag-letter-spacing);
  line-height: var(--tag-line-height);
  white-space: nowrap;
  width: fit-content;
  margin-top: -1px;
  display: block;
  position: relative;
}

.profile-screen .component-10930-8 {
  left: unset !important;
  top: unset !important;
  width: 4.57px !important;
  height: 8.14px !important;
  position: relative !important;
}

.profile-screen .text-wrapper-508 {
  color: var(--gray);
  font-family: var(--tag-font-family);
  font-size: var(--tag-font-size);
  font-style: var(--tag-font-style);
  font-weight: var(--tag-font-weight);
  letter-spacing: var(--tag-letter-spacing);
  line-height: var(--tag-line-height);
  white-space: nowrap;
  width: fit-content;
  margin-top: -1px;
  position: relative;
}

.profile-screen .greeting-2 {
  background-color: var(--backwhite);
  flex-direction: column;
  flex: none;
  align-self: stretch;
  align-items: center;
  gap: 40px;
  width: 100%;
  padding: 80px 15px;
  display: flex;
  position: relative;
}

.profile-screen .heading-50 {
  position: relative !important;
}

.profile-screen .heading-51 {
  left: -10px !important;
}

.profile-screen .flexcontainer-54 {
  flex-direction: column;
  align-items: flex-start;
  gap: 2px;
  display: inline-flex;
}

.profile-screen .text-35 {
  color: #454545;
  letter-spacing: 1.25px;
  text-align: center;
  align-self: stretch;
  font-family: Noto Sans JP, Helvetica;
  font-size: 25px;
  font-weight: 700;
  line-height: normal;
  position: relative;
}

.profile-screen .text-wrapper-509 {
  letter-spacing: .31px;
}

.profile-screen .members-2 {
  background-color: var(--backwhite);
  flex-direction: column;
  flex: none;
  align-self: stretch;
  align-items: center;
  gap: 50px;
  width: 100%;
  padding: 80px 20px;
  display: flex;
  position: relative;
}

.profile-screen .heading-52 {
  left: -44px !important;
}

.profile-screen .heading-53 {
  left: 3px !important;
}

.profile-screen .frame-406 {
  flex-direction: column;
  flex: none;
  align-self: stretch;
  align-items: center;
  gap: 40px;
  width: 100%;
  display: flex;
  position: relative;
  overflow: hidden;
}

.profile-screen .member-4 {
  flex: none;
  align-self: stretch;
  align-items: flex-start;
  gap: 40px;
  width: 100%;
  display: flex;
  position: relative;
}

.profile-screen .rectangle-8 {
  width: 201px;
  height: 200px;
  position: relative;
}

.profile-screen .frame-407 {
  flex-direction: column;
  flex: 1;
  align-items: flex-start;
  gap: 16px;
  display: flex;
  position: relative;
}

.profile-screen .frame-408 {
  flex-direction: column;
  flex: none;
  align-items: flex-start;
  gap: 12px;
  display: inline-flex;
  position: relative;
}

.profile-screen .text-wrapper-510 {
  color: var(--black);
  letter-spacing: 1px;
  white-space: nowrap;
  width: fit-content;
  margin-top: -1px;
  font-family: Noto Sans JP, Helvetica;
  font-size: 20px;
  font-weight: 700;
  line-height: 37px;
  position: relative;
}

.profile-screen .text-wrapper-511 {
  color: #454545;
  font-family: var(--text-font-family);
  font-size: var(--text-font-size);
  font-style: var(--text-font-style);
  font-weight: var(--text-font-weight);
  letter-spacing: var(--text-letter-spacing);
  line-height: var(--text-line-height);
  white-space: nowrap;
  width: fit-content;
  position: relative;
}

.profile-screen .frame-409 {
  flex-direction: column;
  flex: none;
  align-self: stretch;
  align-items: flex-start;
  gap: 8px;
  width: 100%;
  display: flex;
  position: relative;
}

.profile-screen .frame-410 {
  flex-wrap: wrap;
  flex: none;
  align-self: stretch;
  align-items: flex-start;
  gap: 41px;
  width: 100%;
  display: flex;
  position: relative;
}

.profile-screen .text-wrapper-512 {
  color: var(--darkgray);
  font-family: var(--text-font-family);
  font-size: var(--text-font-size);
  font-style: var(--text-font-style);
  font-weight: var(--text-font-weight);
  letter-spacing: var(--text-letter-spacing);
  line-height: var(--text-line-height);
  white-space: nowrap;
  width: fit-content;
  margin-top: -1px;
  position: relative;
}

.profile-screen .text-wrapper-513 {
  color: var(--darkgray);
  font-family: var(--text-font-family);
  font-size: var(--text-font-size);
  font-style: var(--text-font-style);
  font-weight: var(--text-font-weight);
  letter-spacing: var(--text-letter-spacing);
  line-height: var(--text-line-height);
  flex: 1;
  margin-top: -1px;
  position: relative;
}

.profile-screen .line-20 {
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 10px;
  width: 1000px;
  height: 1px;
  margin-left: -148px;
  margin-right: -148px;
  padding: 0 20px;
  display: flex;
  position: relative;
}

.profile-screen .grayline-8 {
  align-self: stretch;
  width: 100%;
  height: 1px;
  margin-bottom: -.5px;
  position: relative;
}

.profile-screen .line-22 {
  object-fit: cover;
  width: 704px;
  height: 1px;
  position: absolute;
  top: -1px;
  left: 128px;
}

.profile-screen .CONTACT-32 {
  background-color: var(--backwhite);
  flex-direction: column;
  flex: none;
  align-self: stretch;
  align-items: center;
  gap: 40px;
  width: 100%;
  padding: 100px 20px;
  display: flex;
  position: relative;
}

.profile-screen .CONTACT-33 {
  width: 704px;
  height: 160px;
  position: relative;
}

.profile-screen .frame-411 {
  background-color: var(--purple);
  border-radius: 6px;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  gap: 20px 74px;
  width: 700px;
  max-width: 700px;
  padding: 50px 60px;
  display: flex;
  position: relative;
  left: 2px;
}

.profile-screen .group-72 {
  width: 216px;
  height: 60px;
  position: relative;
}

.profile-screen .overlap-group-17 {
  width: 212px;
  height: 60px;
  position: relative;
}

.profile-screen .text-wrapper-514 {
  color: var(--white);
  letter-spacing: 2.4px;
  text-align: center;
  width: 212px;
  font-family: Zen Kaku Gothic New, Helvetica;
  font-size: 24px;
  font-weight: 500;
  line-height: normal;
  position: absolute;
  top: 0;
  left: 0;
}

.profile-screen .text-wrapper-515 {
  color: var(--white);
  letter-spacing: 0;
  text-align: center;
  width: 134px;
  font-family: Slackside One, Helvetica;
  font-size: 20px;
  font-weight: 400;
  line-height: normal;
  position: absolute;
  top: 31px;
  left: 39px;
}

.profile-screen .group-73 {
  width: 254px;
  height: 55px;
  position: relative;
}

.profile-screen .flexcontainer-55 {
  flex-direction: column;
  align-items: flex-start;
  gap: 5px;
  width: 252px;
  height: 55px;
  display: flex;
  position: relative;
}

.profile-screen .text-i-54 {
  color: var(--white);
  letter-spacing: .75px;
  align-self: stretch;
  font-family: Noto Sans JP, Helvetica;
  font-size: 15px;
  font-weight: 400;
  line-height: 25px;
  position: relative;
}

.profile-screen .text-wrapper-516 {
  letter-spacing: .11px;
}

.profile-screen .footer-19 {
  background-color: #454545;
  flex-direction: column;
  flex: none;
  align-self: stretch;
  align-items: center;
  gap: 50px;
  width: 100%;
  padding: 70px 20px 50px;
  display: flex;
  position: relative;
}

.profile-screen .frame-412 {
  flex-wrap: wrap;
  flex: none;
  justify-content: space-between;
  align-self: stretch;
  align-items: flex-start;
  gap: 50px 150px;
  width: 100%;
  display: flex;
  position: relative;
}

.profile-screen .frame-413 {
  flex-direction: column;
  flex: none;
  align-items: flex-start;
  gap: 30px;
  display: inline-flex;
  position: relative;
}

.profile-screen .logo-white-21 {
  left: unset !important;
  top: unset !important;
  width: 163.65px !important;
  height: 54.26px !important;
  position: relative !important;
}

.profile-screen .flexcontainer-56 {
  flex-direction: column;
  align-items: flex-start;
  gap: 5px;
  display: flex;
}

.profile-screen .text-i-55 {
  color: #fff;
  font-family: var(--text-font-family);
  font-size: var(--text-font-size);
  font-style: var(--text-font-style);
  font-weight: var(--text-font-weight);
  letter-spacing: var(--text-letter-spacing);
  line-height: var(--text-line-height);
  align-self: stretch;
  position: relative;
}

.profile-screen .text-wrapper-517 {
  font-family: var(--text-font-family);
  font-size: var(--text-font-size);
  font-style: var(--text-font-style);
  font-weight: var(--text-font-weight);
  letter-spacing: var(--text-letter-spacing);
  line-height: var(--text-line-height);
}

.profile-screen .frame-414 {
  flex: none;
  align-items: flex-start;
  gap: 60px;
  display: inline-flex;
  position: relative;
}

.profile-screen .frame-415 {
  flex-direction: column;
  flex: none;
  align-items: flex-start;
  gap: 6px;
  display: inline-flex;
  position: relative;
}

.profile-screen .text-wrapper-518 {
  color: #fff;
  font-family: var(--text-bold-mini-font-family);
  font-size: var(--text-bold-mini-font-size);
  font-style: var(--text-bold-mini-font-style);
  font-weight: var(--text-bold-mini-font-weight);
  letter-spacing: var(--text-bold-mini-letter-spacing);
  line-height: var(--text-bold-mini-line-height);
  white-space: nowrap;
  width: fit-content;
  margin-top: -1px;
  position: relative;
}

.profile-screen .text-wrapper-519 {
  color: #fff;
  font-family: var(--text-bold-mini-font-family);
  font-size: var(--text-bold-mini-font-size);
  font-style: var(--text-bold-mini-font-style);
  font-weight: var(--text-bold-mini-font-weight);
  letter-spacing: var(--text-bold-mini-letter-spacing);
  line-height: var(--text-bold-mini-line-height);
  white-space: nowrap;
  width: fit-content;
  position: relative;
}

.profile-screen .text-wrapper-520 {
  color: #fff;
  font-family: var(--text-bold-mini-font-family);
  font-size: var(--text-bold-mini-font-size);
  font-style: var(--text-bold-mini-font-style);
  font-weight: var(--text-bold-mini-font-weight);
  letter-spacing: var(--text-bold-mini-letter-spacing);
  line-height: var(--text-bold-mini-line-height);
  white-space: nowrap;
  width: fit-content;
  display: block;
  position: relative;
}

.profile-screen .text-wrapper-521 {
  color: #fff;
  letter-spacing: 1.31px;
  width: fit-content;
  font-family: Manrope, Helvetica;
  font-size: 13.1px;
  font-weight: 400;
  line-height: normal;
  position: relative;
}

.philosophy-screen {
  flex-direction: column;
  align-items: center;
  display: flex;
  position: relative;
}

.philosophy-screen .header-44 {
  z-index: 1 !important;
  flex: none !important;
  justify-content: space-between !important;
  align-self: stretch !important;
  width: 100% !important;
  display: flex !important;
}

.philosophy-screen .header-45 {
  width: 145px !important;
  height: 54px !important;
  position: relative !important;
  top: 16px !important;
}

.philosophy-screen .philosophy-2 {
  z-index: 0;
  background-color: #fff;
  flex-direction: column;
  flex: none;
  align-self: stretch;
  align-items: flex-start;
  width: 100%;
  display: flex;
  position: relative;
}

.philosophy-screen .midashi-13 {
  flex-direction: column;
  flex: none;
  align-self: stretch;
  align-items: flex-start;
  width: 100%;
  display: flex;
  position: relative;
}

.philosophy-screen .heading-54 {
  background-image: url("midashi-1.05af3916.png") !important;
  flex: none !important;
  align-self: stretch !important;
  width: 100% !important;
}

.philosophy-screen .heading-55 {
  color: #fff !important;
  left: -17px !important;
}

.philosophy-screen .heading-56 {
  color: #fff !important;
  left: 23px !important;
}

.philosophy-screen .breadcrumbs-14 {
  flex: none;
  justify-content: center;
  align-self: stretch;
  align-items: flex-start;
  gap: 30px;
  width: 100%;
  padding: 40px 15px;
  display: flex;
  position: relative;
}

.philosophy-screen .frame-416 {
  flex: 1;
  align-items: center;
  gap: 30px;
  display: flex;
  position: relative;
}

.philosophy-screen .text-wrapper-522 {
  color: var(--gray);
  font-family: var(--tag-font-family);
  font-size: var(--tag-font-size);
  font-style: var(--tag-font-style);
  font-weight: var(--tag-font-weight);
  letter-spacing: var(--tag-letter-spacing);
  line-height: var(--tag-line-height);
  white-space: nowrap;
  width: fit-content;
  margin-top: -1px;
  display: block;
  position: relative;
}

.philosophy-screen .component-10930-9 {
  left: unset !important;
  top: unset !important;
  width: 4.57px !important;
  height: 8.14px !important;
  position: relative !important;
}

.philosophy-screen .text-wrapper-523 {
  color: var(--gray);
  font-family: var(--tag-font-family);
  font-size: var(--tag-font-size);
  font-style: var(--tag-font-style);
  font-weight: var(--tag-font-weight);
  letter-spacing: var(--tag-letter-spacing);
  line-height: var(--tag-line-height);
  white-space: nowrap;
  width: fit-content;
  margin-top: -1px;
  position: relative;
}

.philosophy-screen .corporate-2 {
  background-image: url("corporate-1.3642f11e.png");
  background-position: 50%;
  background-size: cover;
  flex-direction: column;
  flex: none;
  align-self: stretch;
  align-items: center;
  gap: 40px;
  width: 100%;
  padding: 100px 30px;
  display: flex;
  position: relative;
}

.philosophy-screen .frame-417 {
  opacity: .9;
  background-color: #fff;
  flex-direction: column;
  flex: none;
  align-self: stretch;
  align-items: center;
  gap: 50px;
  width: 100%;
  padding: 80px 30px;
  display: flex;
  position: relative;
}

.philosophy-screen .heading-57 {
  position: relative !important;
}

.philosophy-screen .heading-58 {
  left: -10px !important;
}

.philosophy-screen .heading-59 {
  left: -87px !important;
}

.philosophy-screen .flexcontainer-57 {
  flex-direction: column;
  align-items: flex-start;
  gap: 2px;
  display: inline-flex;
}

.philosophy-screen .text-36 {
  color: #454545;
  letter-spacing: 2.3px;
  text-align: center;
  align-self: stretch;
  font-family: Noto Sans JP, Helvetica;
  font-size: 23px;
  font-weight: 700;
  line-height: 43px;
  position: relative;
}

.philosophy-screen .text-wrapper-524 {
  letter-spacing: .53px;
}

.philosophy-screen .flexcontainer-58 {
  flex-direction: column;
  align-items: flex-start;
  gap: 5px;
  display: inline-flex;
}

.philosophy-screen .text-37 {
  color: var(--darkgray);
  font-family: var(--text-font-family);
  font-size: var(--text-font-size);
  font-style: var(--text-font-style);
  font-weight: var(--text-font-weight);
  letter-spacing: var(--text-letter-spacing);
  line-height: var(--text-line-height);
  text-align: center;
  align-self: stretch;
  position: relative;
}

.philosophy-screen .text-wrapper-525 {
  font-family: var(--text-font-family);
  font-size: var(--text-font-size);
  font-style: var(--text-font-style);
  font-weight: var(--text-font-weight);
  letter-spacing: var(--text-letter-spacing);
  line-height: var(--text-line-height);
}

.philosophy-screen .management-2 {
  background-color: var(--palegray);
  flex-direction: column;
  flex: none;
  align-self: stretch;
  align-items: center;
  gap: 50px;
  width: 100%;
  padding: 80px 15px;
  display: flex;
  position: relative;
}

.philosophy-screen .heading-60 {
  left: -104px !important;
}

.philosophy-screen .text-wrapper-526 {
  color: #454545;
  letter-spacing: 2.3px;
  text-align: center;
  white-space: nowrap;
  width: fit-content;
  font-family: Noto Sans JP, Helvetica;
  font-size: 23px;
  font-weight: 700;
  line-height: 43px;
  position: relative;
}

.philosophy-screen .text-38 {
  color: #454545;
  font-family: var(--text-font-family);
  font-size: var(--text-font-size);
  font-style: var(--text-font-style);
  font-weight: var(--text-font-weight);
  letter-spacing: var(--text-letter-spacing);
  line-height: var(--text-line-height);
  text-align: center;
  align-self: stretch;
  position: relative;
}

.philosophy-screen .ill-2 {
  object-fit: cover;
  width: 548px;
  height: 326px;
  position: relative;
}

.philosophy-screen .motto-2 {
  background-color: var(--backwhite);
  flex-direction: column;
  flex: none;
  align-self: stretch;
  align-items: center;
  gap: 50px;
  width: 100%;
  padding: 80px 15px;
  display: flex;
  position: relative;
}

.philosophy-screen .heading-61 {
  left: 23px !important;
}

.philosophy-screen .heading-62 {
  left: 16px !important;
}

.philosophy-screen .mottos-2 {
  flex-direction: column;
  flex: none;
  align-self: stretch;
  align-items: flex-start;
  gap: 30px;
  width: 100%;
  display: flex;
  position: relative;
}

.philosophy-screen .frame-418 {
  flex-direction: column;
  flex: none;
  align-self: stretch;
  align-items: flex-start;
  gap: 15px;
  width: 100%;
  display: flex;
  position: relative;
}

.philosophy-screen .text-wrapper-527 {
  color: var(--darkgray);
  letter-spacing: 2.3px;
  align-self: stretch;
  margin-top: -1px;
  font-family: Noto Sans JP, Helvetica;
  font-size: 23px;
  font-weight: 700;
  line-height: 43px;
  position: relative;
}

.philosophy-screen .line-2-instance {
  width: 90px !important;
}

.philosophy-screen .text-wrapper-528 {
  color: var(--darkgray);
  letter-spacing: .75px;
  align-self: stretch;
  font-family: Noto Sans JP, Helvetica;
  font-size: 15px;
  font-weight: 400;
  line-height: 25px;
  position: relative;
}

.philosophy-screen .text-wrapper-529 {
  color: var(--darkgray);
  letter-spacing: 1px;
  align-self: stretch;
  margin-top: -1px;
  font-family: Noto Sans JP, Helvetica;
  font-size: 20px;
  font-weight: 700;
  line-height: 37px;
  position: relative;
}

.philosophy-screen .CONTACT-34 {
  background-color: var(--backwhite);
  flex-direction: column;
  flex: none;
  align-self: stretch;
  align-items: center;
  gap: 40px;
  width: 100%;
  padding: 100px 20px;
  display: flex;
  position: relative;
}

.philosophy-screen .CONTACT-35 {
  width: 704px;
  height: 160px;
  position: relative;
}

.philosophy-screen .frame-419 {
  background-color: var(--purple);
  border-radius: 6px;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  gap: 20px 74px;
  width: 700px;
  max-width: 700px;
  padding: 50px 60px;
  display: flex;
  position: relative;
  left: 2px;
}

.philosophy-screen .group-74 {
  width: 216px;
  height: 60px;
  position: relative;
}

.philosophy-screen .overlap-group-18 {
  width: 212px;
  height: 60px;
  position: relative;
}

.philosophy-screen .text-wrapper-530 {
  color: var(--white);
  letter-spacing: 2.4px;
  text-align: center;
  width: 212px;
  font-family: Zen Kaku Gothic New, Helvetica;
  font-size: 24px;
  font-weight: 500;
  line-height: normal;
  position: absolute;
  top: 0;
  left: 0;
}

.philosophy-screen .text-wrapper-531 {
  color: var(--white);
  letter-spacing: 0;
  text-align: center;
  width: 134px;
  font-family: Slackside One, Helvetica;
  font-size: 20px;
  font-weight: 400;
  line-height: normal;
  position: absolute;
  top: 31px;
  left: 39px;
}

.philosophy-screen .group-75 {
  width: 254px;
  height: 55px;
  position: relative;
}

.philosophy-screen .flexcontainer-59 {
  flex-direction: column;
  align-items: flex-start;
  gap: 5px;
  width: 252px;
  height: 55px;
  display: flex;
  position: relative;
}

.philosophy-screen .text-i-56 {
  color: var(--white);
  letter-spacing: .75px;
  align-self: stretch;
  font-family: Noto Sans JP, Helvetica;
  font-size: 15px;
  font-weight: 400;
  line-height: 25px;
  position: relative;
}

.philosophy-screen .text-wrapper-532 {
  letter-spacing: .11px;
}

.philosophy-screen .footer-20 {
  background-color: #454545;
  flex-direction: column;
  flex: none;
  align-self: stretch;
  align-items: center;
  gap: 50px;
  width: 100%;
  padding: 70px 20px 50px;
  display: flex;
  position: relative;
}

.philosophy-screen .frame-420 {
  flex-wrap: wrap;
  flex: none;
  justify-content: space-between;
  align-self: stretch;
  align-items: flex-start;
  gap: 50px 150px;
  width: 100%;
  display: flex;
  position: relative;
}

.philosophy-screen .frame-421 {
  flex-direction: column;
  flex: none;
  align-items: flex-start;
  gap: 30px;
  display: inline-flex;
  position: relative;
}

.philosophy-screen .logo-white-23 {
  left: unset !important;
  top: unset !important;
  width: 163.65px !important;
  height: 54.26px !important;
  position: relative !important;
}

.philosophy-screen .flexcontainer-60 {
  flex-direction: column;
  align-items: flex-start;
  gap: 5px;
  display: flex;
}

.philosophy-screen .text-i-57 {
  color: #fff;
  font-family: var(--text-font-family);
  font-size: var(--text-font-size);
  font-style: var(--text-font-style);
  font-weight: var(--text-font-weight);
  letter-spacing: var(--text-letter-spacing);
  line-height: var(--text-line-height);
  align-self: stretch;
  position: relative;
}

.philosophy-screen .frame-422 {
  flex: none;
  align-items: flex-start;
  gap: 60px;
  display: inline-flex;
  position: relative;
}

.philosophy-screen .frame-423 {
  flex-direction: column;
  flex: none;
  align-items: flex-start;
  gap: 6px;
  display: inline-flex;
  position: relative;
}

.philosophy-screen .text-wrapper-533 {
  color: #fff;
  font-family: var(--text-bold-mini-font-family);
  font-size: var(--text-bold-mini-font-size);
  font-style: var(--text-bold-mini-font-style);
  font-weight: var(--text-bold-mini-font-weight);
  letter-spacing: var(--text-bold-mini-letter-spacing);
  line-height: var(--text-bold-mini-line-height);
  white-space: nowrap;
  width: fit-content;
  margin-top: -1px;
  position: relative;
}

.philosophy-screen .text-wrapper-534 {
  color: #fff;
  font-family: var(--text-bold-mini-font-family);
  font-size: var(--text-bold-mini-font-size);
  font-style: var(--text-bold-mini-font-style);
  font-weight: var(--text-bold-mini-font-weight);
  letter-spacing: var(--text-bold-mini-letter-spacing);
  line-height: var(--text-bold-mini-line-height);
  white-space: nowrap;
  width: fit-content;
  position: relative;
}

.philosophy-screen .text-wrapper-535 {
  color: #fff;
  font-family: var(--text-bold-mini-font-family);
  font-size: var(--text-bold-mini-font-size);
  font-style: var(--text-bold-mini-font-style);
  font-weight: var(--text-bold-mini-font-weight);
  letter-spacing: var(--text-bold-mini-letter-spacing);
  line-height: var(--text-bold-mini-line-height);
  white-space: nowrap;
  width: fit-content;
  display: block;
  position: relative;
}

.philosophy-screen .text-wrapper-536 {
  color: #fff;
  letter-spacing: 1.31px;
  width: fit-content;
  font-family: Manrope, Helvetica;
  font-size: 13.1px;
  font-weight: 400;
  line-height: normal;
  position: relative;
}

.blogs-screen {
  flex-direction: column;
  align-items: center;
  display: flex;
  position: relative;
}

.blogs-screen .header-46 {
  z-index: 1 !important;
  flex: none !important;
  justify-content: space-between !important;
  align-self: stretch !important;
  width: 100% !important;
  display: flex !important;
}

.blogs-screen .header-47 {
  width: 145px !important;
  height: 54px !important;
  position: relative !important;
  top: 16px !important;
}

.blogs-screen .blogs-2 {
  z-index: 0;
  background-color: #fff;
  flex-direction: column;
  flex: none;
  align-self: stretch;
  align-items: flex-start;
  width: 100%;
  display: flex;
  position: relative;
}

.blogs-screen .midashi-14 {
  flex-direction: column;
  flex: none;
  align-self: stretch;
  align-items: flex-start;
  width: 100%;
  display: flex;
  position: relative;
}

.blogs-screen .heading-63 {
  background-image: url("midashi-1.05af3916.png") !important;
  flex: none !important;
  align-self: stretch !important;
  width: 100% !important;
}

.blogs-screen .heading-64 {
  color: #fff !important;
  left: 28px !important;
}

.blogs-screen .heading-65 {
  color: #fff !important;
}

.blogs-screen .breadcrumbs-15 {
  flex: none;
  justify-content: center;
  align-self: stretch;
  align-items: flex-start;
  gap: 30px;
  width: 100%;
  padding: 40px 15px;
  display: flex;
  position: relative;
}

.blogs-screen .frame-424 {
  flex: 1;
  align-items: center;
  gap: 30px;
  display: flex;
  position: relative;
}

.blogs-screen .text-wrapper-537 {
  color: var(--gray);
  font-family: var(--tag-font-family);
  font-size: var(--tag-font-size);
  font-style: var(--tag-font-style);
  font-weight: var(--tag-font-weight);
  letter-spacing: var(--tag-letter-spacing);
  line-height: var(--tag-line-height);
  white-space: nowrap;
  width: fit-content;
  margin-top: -1px;
  display: block;
  position: relative;
}

.blogs-screen .component-10930-10 {
  left: unset !important;
  top: unset !important;
  width: 4.57px !important;
  height: 8.14px !important;
  position: relative !important;
}

.blogs-screen .text-wrapper-538 {
  color: var(--gray);
  font-family: var(--tag-font-family);
  font-size: var(--tag-font-size);
  font-style: var(--tag-font-style);
  font-weight: var(--tag-font-weight);
  letter-spacing: var(--tag-letter-spacing);
  line-height: var(--tag-line-height);
  white-space: nowrap;
  width: fit-content;
  margin-top: -1px;
  position: relative;
}

.blogs-screen .blogs-3 {
  background-color: var(--palepurple);
  flex-direction: column;
  flex: none;
  align-self: stretch;
  align-items: center;
  gap: 40px;
  width: 100%;
  padding: 80px 15px;
  display: flex;
  position: relative;
}

.blogs-screen .frame-425 {
  flex-direction: column;
  flex: none;
  align-self: stretch;
  align-items: center;
  gap: 40px;
  width: 100%;
  display: flex;
  position: relative;
}

.blogs-screen .frame-426 {
  flex: none;
  align-items: flex-start;
  gap: 20px;
  display: inline-flex;
  position: relative;
}

.blogs-screen .group-76 {
  width: 56px;
  height: 19px;
  position: relative;
}

.blogs-screen .text-wrapper-539 {
  color: var(--purple);
  font-family: var(--tag-font-family);
  font-size: var(--tag-font-size);
  font-style: var(--tag-font-style);
  font-weight: var(--tag-font-weight);
  letter-spacing: var(--tag-letter-spacing);
  line-height: var(--tag-line-height);
  white-space: nowrap;
  position: absolute;
  top: 0;
  left: 0;
}

.blogs-screen .line-23 {
  width: 52px;
  height: 3px;
  position: absolute;
  top: 18px;
  left: 0;
}

.blogs-screen .text-wrapper-540 {
  color: var(--purple);
  font-family: var(--tag-font-family);
  font-size: var(--tag-font-size);
  font-style: var(--tag-font-style);
  font-weight: var(--tag-font-weight);
  letter-spacing: var(--tag-letter-spacing);
  line-height: var(--tag-line-height);
  white-space: nowrap;
  width: fit-content;
  margin-top: -1px;
  position: relative;
}

.blogs-screen .frame-427 {
  flex-wrap: wrap;
  flex: none;
  justify-content: center;
  align-self: stretch;
  align-items: flex-start;
  gap: 30px 10px;
  width: 100%;
  display: flex;
  position: relative;
}

.blogs-screen .frame-428 {
  background-color: #fff;
  border-radius: 10px;
  flex: 1;
  align-items: flex-start;
  gap: 14px;
  min-width: 290px;
  max-width: 360px;
  transition: all .3s;
  display: flex;
  position: relative;
}

.blogs-screen .frame-429 {
  background-color: #fff;
  border: 1px solid #efeeee;
  border-radius: 10px;
  flex-direction: column;
  flex: 1;
  align-items: flex-start;
  gap: 8px;
  height: 395px;
  padding: 12px 12px 20px;
  display: flex;
  position: relative;
}

.blogs-screen .frame-430 {
  background-color: #959595;
  border-radius: 6px;
  flex: 1;
  justify-content: center;
  align-self: stretch;
  align-items: center;
  gap: 10px;
  width: 100%;
  min-height: 200px;
  max-height: 220px;
  display: flex;
  position: relative;
}

.blogs-screen .noimage-14 {
  width: 190.9px !important;
  height: 25.92px !important;
  position: relative !important;
}

.blogs-screen .text-wrapper-541 {
  color: #8a8a8a;
  letter-spacing: 1.2px;
  width: fit-content;
  font-family: Manrope, Helvetica;
  font-size: 12px;
  font-weight: 400;
  line-height: normal;
  position: relative;
}

.blogs-screen .text-wrapper-542 {
  -webkit-line-clamp: 2;
  color: #454545;
  letter-spacing: .75px;
  text-overflow: ellipsis;
  -webkit-box-orient: vertical;
  align-self: stretch;
  font-family: Noto Sans JP, Helvetica;
  font-size: 15px;
  font-weight: 700;
  line-height: 26px;
  display: -webkit-box;
  position: relative;
  overflow: hidden;
}

.blogs-screen .vector-5 {
  object-fit: cover;
  align-self: stretch;
  width: 100%;
  height: 1px;
  position: relative;
}

.blogs-screen .text-wrapper-543 {
  -webkit-line-clamp: 2;
  color: var(--purple);
  letter-spacing: .65px;
  text-overflow: ellipsis;
  -webkit-box-orient: vertical;
  align-self: stretch;
  font-family: Noto Sans JP, Helvetica;
  font-size: 13px;
  font-weight: 500;
  line-height: 18.8px;
  display: -webkit-box;
  position: relative;
  overflow: hidden;
}

.blogs-screen .frame-431 {
  width: 206.48px;
  height: 16px;
  position: relative;
}

.blogs-screen .text-wrapper-544 {
  color: #454545;
  font-family: var(--tag-font-family);
  font-size: var(--tag-font-size);
  font-style: var(--tag-font-style);
  font-weight: var(--tag-font-weight);
  letter-spacing: var(--tag-letter-spacing);
  line-height: var(--tag-line-height);
  white-space: nowrap;
  position: absolute;
  top: -1px;
  left: 29px;
}

.blogs-screen .text-wrapper-545 {
  color: #454545;
  font-family: var(--tag-font-family);
  font-size: var(--tag-font-size);
  font-style: var(--tag-font-style);
  font-weight: var(--tag-font-weight);
  letter-spacing: var(--tag-letter-spacing);
  line-height: var(--tag-line-height);
  white-space: nowrap;
  position: absolute;
  top: -1px;
  left: 57px;
}

.blogs-screen .text-wrapper-546 {
  color: #454545;
  font-family: var(--tag-font-family);
  font-size: var(--tag-font-size);
  font-style: var(--tag-font-style);
  font-weight: var(--tag-font-weight);
  letter-spacing: var(--tag-letter-spacing);
  line-height: var(--tag-line-height);
  white-space: nowrap;
  position: absolute;
  top: -1px;
  left: 85px;
}

.blogs-screen .text-wrapper-547 {
  color: #454545;
  font-family: var(--tag-font-family);
  font-size: var(--tag-font-size);
  font-style: var(--tag-font-style);
  font-weight: var(--tag-font-weight);
  letter-spacing: var(--tag-letter-spacing);
  line-height: var(--tag-line-height);
  white-space: nowrap;
  position: absolute;
  top: -1px;
  left: 113px;
}

.blogs-screen .text-wrapper-548 {
  color: #454545;
  font-family: var(--tag-font-family);
  font-size: var(--tag-font-size);
  font-style: var(--tag-font-style);
  font-weight: var(--tag-font-weight);
  letter-spacing: var(--tag-letter-spacing);
  line-height: var(--tag-line-height);
  white-space: nowrap;
  position: absolute;
  top: -1px;
  left: 141px;
}

.blogs-screen .text-wrapper-549 {
  color: #454545;
  font-family: var(--tag-font-family);
  font-size: var(--tag-font-size);
  font-style: var(--tag-font-style);
  font-weight: var(--tag-font-weight);
  letter-spacing: var(--tag-letter-spacing);
  line-height: var(--tag-line-height);
  white-space: nowrap;
  position: absolute;
  top: -1px;
  left: 169px;
}

.blogs-screen .polygon-2-8-instance {
  width: 10px !important;
  height: 16px !important;
  position: absolute !important;
  top: 0 !important;
  left: 197px !important;
}

.blogs-screen .polygon-3-5-instance {
  width: 10px !important;
  height: 16px !important;
  position: absolute !important;
  top: 0 !important;
  left: 0 !important;
}

.blogs-screen .CONTACT-36 {
  background-color: var(--backwhite);
  flex-direction: column;
  flex: none;
  align-self: stretch;
  align-items: center;
  gap: 40px;
  width: 100%;
  padding: 100px 20px;
  display: flex;
  position: relative;
}

.blogs-screen .CONTACT-37 {
  width: 704px;
  height: 160px;
  position: relative;
}

.blogs-screen .frame-432 {
  background-color: var(--purple);
  border-radius: 6px;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  gap: 20px 74px;
  width: 700px;
  max-width: 700px;
  padding: 50px 60px;
  display: flex;
  position: relative;
  left: 2px;
}

.blogs-screen .group-77 {
  width: 216px;
  height: 60px;
  position: relative;
}

.blogs-screen .overlap-group-19 {
  width: 212px;
  height: 60px;
  position: relative;
}

.blogs-screen .text-wrapper-550 {
  color: var(--white);
  letter-spacing: 2.4px;
  text-align: center;
  width: 212px;
  font-family: Zen Kaku Gothic New, Helvetica;
  font-size: 24px;
  font-weight: 500;
  line-height: normal;
  position: absolute;
  top: 0;
  left: 0;
}

.blogs-screen .text-wrapper-551 {
  color: var(--white);
  letter-spacing: 0;
  text-align: center;
  width: 134px;
  font-family: Slackside One, Helvetica;
  font-size: 20px;
  font-weight: 400;
  line-height: normal;
  position: absolute;
  top: 31px;
  left: 39px;
}

.blogs-screen .group-78 {
  width: 254px;
  height: 55px;
  position: relative;
}

.blogs-screen .flexcontainer-61 {
  flex-direction: column;
  align-items: flex-start;
  gap: 5px;
  width: 252px;
  height: 55px;
  display: flex;
  position: relative;
}

.blogs-screen .text-i-58 {
  color: var(--white);
  letter-spacing: .75px;
  align-self: stretch;
  font-family: Noto Sans JP, Helvetica;
  font-size: 15px;
  font-weight: 400;
  line-height: 25px;
  position: relative;
}

.blogs-screen .text-wrapper-552 {
  letter-spacing: .11px;
}

.blogs-screen .footer-21 {
  background-color: #454545;
  flex-direction: column;
  flex: none;
  align-self: stretch;
  align-items: center;
  gap: 50px;
  width: 100%;
  padding: 70px 20px 50px;
  display: flex;
  position: relative;
}

.blogs-screen .frame-433 {
  flex-wrap: wrap;
  flex: none;
  justify-content: space-between;
  align-self: stretch;
  align-items: flex-start;
  gap: 50px 150px;
  width: 100%;
  display: flex;
  position: relative;
}

.blogs-screen .frame-434 {
  flex-direction: column;
  flex: none;
  align-items: flex-start;
  gap: 30px;
  display: inline-flex;
  position: relative;
}

.blogs-screen .logo-white-29 {
  left: unset !important;
  top: unset !important;
  width: 163.65px !important;
  height: 54.26px !important;
  position: relative !important;
}

.blogs-screen .flexcontainer-62 {
  flex-direction: column;
  align-items: flex-start;
  gap: 5px;
  display: flex;
}

.blogs-screen .text-i-59 {
  color: #fff;
  font-family: var(--text-font-family);
  font-size: var(--text-font-size);
  font-style: var(--text-font-style);
  font-weight: var(--text-font-weight);
  letter-spacing: var(--text-letter-spacing);
  line-height: var(--text-line-height);
  align-self: stretch;
  position: relative;
}

.blogs-screen .text-wrapper-553 {
  font-family: var(--text-font-family);
  font-size: var(--text-font-size);
  font-style: var(--text-font-style);
  font-weight: var(--text-font-weight);
  letter-spacing: var(--text-letter-spacing);
  line-height: var(--text-line-height);
}

.blogs-screen .frame-435 {
  flex: none;
  align-items: flex-start;
  gap: 60px;
  display: inline-flex;
  position: relative;
}

.blogs-screen .frame-436 {
  flex-direction: column;
  flex: none;
  align-items: flex-start;
  gap: 6px;
  display: inline-flex;
  position: relative;
}

.blogs-screen .text-wrapper-554 {
  color: #fff;
  font-family: var(--text-bold-mini-font-family);
  font-size: var(--text-bold-mini-font-size);
  font-style: var(--text-bold-mini-font-style);
  font-weight: var(--text-bold-mini-font-weight);
  letter-spacing: var(--text-bold-mini-letter-spacing);
  line-height: var(--text-bold-mini-line-height);
  white-space: nowrap;
  width: fit-content;
  margin-top: -1px;
  position: relative;
}

.blogs-screen .text-wrapper-555 {
  color: #fff;
  font-family: var(--text-bold-mini-font-family);
  font-size: var(--text-bold-mini-font-size);
  font-style: var(--text-bold-mini-font-style);
  font-weight: var(--text-bold-mini-font-weight);
  letter-spacing: var(--text-bold-mini-letter-spacing);
  line-height: var(--text-bold-mini-line-height);
  white-space: nowrap;
  width: fit-content;
  position: relative;
}

.blogs-screen .text-wrapper-556 {
  color: #fff;
  font-family: var(--text-bold-mini-font-family);
  font-size: var(--text-bold-mini-font-size);
  font-style: var(--text-bold-mini-font-style);
  font-weight: var(--text-bold-mini-font-weight);
  letter-spacing: var(--text-bold-mini-letter-spacing);
  line-height: var(--text-bold-mini-line-height);
  white-space: nowrap;
  width: fit-content;
  display: block;
  position: relative;
}

.blogs-screen .text-wrapper-557 {
  color: #fff;
  letter-spacing: 1.31px;
  width: fit-content;
  font-family: Manrope, Helvetica;
  font-size: 13.1px;
  font-weight: 400;
  line-height: normal;
  position: relative;
}

.information-security-screen {
  flex-direction: column;
  align-items: center;
  display: flex;
  position: relative;
}

.information-security-screen .header-48 {
  z-index: 1 !important;
  flex: none !important;
  justify-content: space-between !important;
  align-self: stretch !important;
  width: 100% !important;
  display: flex !important;
}

.information-security-screen .header-49 {
  width: 145px !important;
  height: 54px !important;
  position: relative !important;
  top: 16px !important;
}

.information-security-screen .blog-4 {
  z-index: 0;
  background-color: #fff;
  flex-direction: column;
  flex: none;
  align-self: stretch;
  align-items: flex-start;
  width: 100%;
  display: flex;
  position: relative;
}

.information-security-screen .midashi-15 {
  flex-direction: column;
  flex: none;
  align-self: stretch;
  align-items: flex-start;
  width: 100%;
  display: flex;
  position: relative;
}

.information-security-screen .heading-66 {
  background-image: url("midashi-1.05af3916.png") !important;
  flex: none !important;
  align-self: stretch !important;
  width: 100% !important;
}

.information-security-screen .heading-67 {
  color: #fff !important;
  left: -164px !important;
}

.information-security-screen .heading-68 {
  color: #fff !important;
  left: -145px !important;
}

.information-security-screen .breadcrumbs-16 {
  flex: none;
  justify-content: center;
  align-self: stretch;
  align-items: flex-start;
  gap: 30px;
  width: 100%;
  padding: 40px 15px;
  display: flex;
  position: relative;
}

.information-security-screen .frame-437 {
  flex: 1;
  align-items: center;
  gap: 30px;
  display: flex;
  position: relative;
}

.information-security-screen .text-wrapper-558 {
  color: var(--gray);
  font-family: var(--tag-font-family);
  font-size: var(--tag-font-size);
  font-style: var(--tag-font-style);
  font-weight: var(--tag-font-weight);
  letter-spacing: var(--tag-letter-spacing);
  line-height: var(--tag-line-height);
  white-space: nowrap;
  width: fit-content;
  margin-top: -1px;
  display: block;
  position: relative;
}

.information-security-screen .component-10930-11 {
  left: unset !important;
  top: unset !important;
  width: 4.57px !important;
  height: 8.14px !important;
  position: relative !important;
}

.information-security-screen .text-wrapper-559 {
  color: var(--gray);
  font-family: var(--tag-font-family);
  font-size: var(--tag-font-size);
  font-style: var(--tag-font-style);
  font-weight: var(--tag-font-weight);
  letter-spacing: var(--tag-letter-spacing);
  line-height: var(--tag-line-height);
  flex: 1;
  margin-top: -1px;
  position: relative;
}

.information-security-screen .security-2 {
  background-color: var(--palepurple);
  flex-direction: column;
  flex: none;
  align-self: stretch;
  align-items: center;
  gap: 40px;
  width: 100%;
  padding: 80px 15px;
  display: flex;
  position: relative;
}

.information-security-screen .frame-438 {
  background-color: var(--back-white);
  border-radius: 14px;
  flex-direction: column;
  flex: none;
  align-self: stretch;
  align-items: flex-start;
  gap: 40px;
  width: 100%;
  padding: 50px 40px;
  display: flex;
  position: relative;
}

.information-security-screen .frame-439 {
  flex-direction: column;
  flex: none;
  align-self: stretch;
  align-items: flex-start;
  gap: 24px;
  width: 100%;
  display: flex;
  position: relative;
}

.information-security-screen .text-wrapper-560 {
  color: #454545;
  font-family: var(--text-font-family);
  font-size: var(--text-font-size);
  font-style: var(--text-font-style);
  font-weight: var(--text-font-weight);
  letter-spacing: var(--text-letter-spacing);
  line-height: var(--text-line-height);
  align-self: stretch;
  margin-top: -1px;
  position: relative;
}

.information-security-screen .frame-440 {
  flex-direction: column;
  flex: none;
  align-self: stretch;
  align-items: flex-start;
  gap: 5px;
  width: 100%;
  display: flex;
  position: relative;
}

.information-security-screen .bulletpoint-3 {
  flex-wrap: wrap;
  flex: none;
  align-self: stretch;
  align-items: flex-start;
  gap: 5px;
  width: 100%;
  display: flex;
  position: relative;
}

.information-security-screen .group-79 {
  width: 17px;
  height: 28px;
  position: relative;
}

.information-security-screen .ellipse-7 {
  background-color: #6079c1;
  border-radius: 5px;
  width: 10px;
  height: 10px;
  position: relative;
  top: 9px;
  left: 3px;
}

.information-security-screen .text-wrapper-561 {
  color: #454545;
  font-family: var(--text-font-family);
  font-size: var(--text-font-size);
  font-style: var(--text-font-style);
  font-weight: var(--text-font-weight);
  letter-spacing: var(--text-letter-spacing);
  line-height: var(--text-line-height);
  flex: 1;
  margin-top: -1px;
  position: relative;
}

.information-security-screen .div-29 {
  color: #454545;
  font-family: var(--text-font-family);
  font-size: var(--text-font-size);
  font-style: var(--text-font-style);
  font-weight: var(--text-font-weight);
  letter-spacing: var(--text-letter-spacing);
  line-height: var(--text-line-height);
  align-self: stretch;
  position: relative;
}

.information-security-screen .frame-441 {
  flex-direction: column;
  flex: none;
  align-self: stretch;
  align-items: flex-start;
  gap: 30px;
  width: 100%;
  display: flex;
  position: relative;
}

.information-security-screen .heading-69 {
  flex-direction: column;
  flex: none;
  align-self: stretch;
  align-items: flex-start;
  gap: 7px;
  width: 100%;
  display: flex;
  position: relative;
}

.information-security-screen .text-wrapper-562 {
  color: #454545;
  letter-spacing: 2.3px;
  align-self: stretch;
  margin-top: -1px;
  font-family: Noto Sans JP, Helvetica;
  font-size: 23px;
  font-weight: 700;
  line-height: 43px;
  position: relative;
}

.information-security-screen .line-pg {
  align-self: stretch;
  width: 100%;
  height: 2px;
  position: relative;
}

.information-security-screen .flexcontainer-63 {
  flex-direction: column;
  align-items: flex-start;
  gap: 5px;
  display: flex;
}

.information-security-screen .text-wrapper-563 {
  font-family: var(--text-font-family);
  font-size: var(--text-font-size);
  font-style: var(--text-font-style);
  font-weight: var(--text-font-weight);
  letter-spacing: var(--text-letter-spacing);
  line-height: var(--text-line-height);
}

.information-security-screen .CONTACT-38 {
  background-color: var(--backwhite);
  flex-direction: column;
  flex: none;
  align-self: stretch;
  align-items: center;
  gap: 40px;
  width: 100%;
  padding: 100px 20px;
  display: flex;
  position: relative;
}

.information-security-screen .CONTACT-39 {
  width: 704px;
  height: 160px;
  position: relative;
}

.information-security-screen .frame-442 {
  background-color: var(--purple);
  border-radius: 6px;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  gap: 20px 74px;
  width: 700px;
  max-width: 700px;
  padding: 50px 60px;
  display: flex;
  position: relative;
  left: 2px;
}

.information-security-screen .group-80 {
  width: 216px;
  height: 60px;
  position: relative;
}

.information-security-screen .overlap-group-20 {
  width: 212px;
  height: 60px;
  position: relative;
}

.information-security-screen .text-wrapper-564 {
  color: var(--white);
  letter-spacing: 2.4px;
  text-align: center;
  width: 212px;
  font-family: Zen Kaku Gothic New, Helvetica;
  font-size: 24px;
  font-weight: 500;
  line-height: normal;
  position: absolute;
  top: 0;
  left: 0;
}

.information-security-screen .text-wrapper-565 {
  color: var(--white);
  letter-spacing: 0;
  text-align: center;
  width: 134px;
  font-family: Slackside One, Helvetica;
  font-size: 20px;
  font-weight: 400;
  line-height: normal;
  position: absolute;
  top: 31px;
  left: 39px;
}

.information-security-screen .group-81 {
  width: 254px;
  height: 55px;
  position: relative;
}

.information-security-screen .flexcontainer-64 {
  flex-direction: column;
  align-items: flex-start;
  gap: 5px;
  width: 252px;
  height: 55px;
  display: flex;
  position: relative;
}

.information-security-screen .text-i-60 {
  color: var(--white);
  letter-spacing: .75px;
  align-self: stretch;
  font-family: Noto Sans JP, Helvetica;
  font-size: 15px;
  font-weight: 400;
  line-height: 25px;
  position: relative;
}

.information-security-screen .text-wrapper-566 {
  letter-spacing: .11px;
}

.information-security-screen .footer-22 {
  background-color: #454545;
  flex-direction: column;
  flex: none;
  align-self: stretch;
  align-items: center;
  gap: 50px;
  width: 100%;
  padding: 70px 20px 50px;
  display: flex;
  position: relative;
}

.information-security-screen .frame-443 {
  flex-wrap: wrap;
  flex: none;
  justify-content: space-between;
  align-self: stretch;
  align-items: flex-start;
  gap: 50px 150px;
  width: 100%;
  display: flex;
  position: relative;
}

.information-security-screen .frame-444 {
  flex-direction: column;
  flex: none;
  align-items: flex-start;
  gap: 30px;
  display: inline-flex;
  position: relative;
}

.information-security-screen .logo-white-31 {
  left: unset !important;
  top: unset !important;
  width: 163.65px !important;
  height: 54.26px !important;
  position: relative !important;
}

.information-security-screen .text-i-61 {
  color: #fff;
  font-family: var(--text-font-family);
  font-size: var(--text-font-size);
  font-style: var(--text-font-style);
  font-weight: var(--text-font-weight);
  letter-spacing: var(--text-letter-spacing);
  line-height: var(--text-line-height);
  align-self: stretch;
  position: relative;
}

.information-security-screen .frame-445 {
  flex: none;
  align-items: flex-start;
  gap: 60px;
  display: inline-flex;
  position: relative;
}

.information-security-screen .frame-446 {
  flex-direction: column;
  flex: none;
  align-items: flex-start;
  gap: 6px;
  display: inline-flex;
  position: relative;
}

.information-security-screen .text-wrapper-567 {
  color: #fff;
  font-family: var(--text-bold-mini-font-family);
  font-size: var(--text-bold-mini-font-size);
  font-style: var(--text-bold-mini-font-style);
  font-weight: var(--text-bold-mini-font-weight);
  letter-spacing: var(--text-bold-mini-letter-spacing);
  line-height: var(--text-bold-mini-line-height);
  white-space: nowrap;
  width: fit-content;
  margin-top: -1px;
  position: relative;
}

.information-security-screen .text-wrapper-568 {
  color: #fff;
  font-family: var(--text-bold-mini-font-family);
  font-size: var(--text-bold-mini-font-size);
  font-style: var(--text-bold-mini-font-style);
  font-weight: var(--text-bold-mini-font-weight);
  letter-spacing: var(--text-bold-mini-letter-spacing);
  line-height: var(--text-bold-mini-line-height);
  white-space: nowrap;
  width: fit-content;
  position: relative;
}

.information-security-screen .text-wrapper-569 {
  color: #fff;
  font-family: var(--text-bold-mini-font-family);
  font-size: var(--text-bold-mini-font-size);
  font-style: var(--text-bold-mini-font-style);
  font-weight: var(--text-bold-mini-font-weight);
  letter-spacing: var(--text-bold-mini-letter-spacing);
  line-height: var(--text-bold-mini-line-height);
  white-space: nowrap;
  width: fit-content;
  display: block;
  position: relative;
}

.information-security-screen .text-wrapper-570 {
  color: #fff;
  letter-spacing: 1.31px;
  width: fit-content;
  font-family: Manrope, Helvetica;
  font-size: 13.1px;
  font-weight: 400;
  line-height: normal;
  position: relative;
}

.privacypolicy-screen {
  flex-direction: column;
  align-items: center;
  display: flex;
  position: relative;
}

.privacypolicy-screen .header-50 {
  height: unset !important;
  z-index: 1 !important;
  flex: none !important;
  align-self: stretch !important;
  width: 100% !important;
}

.privacypolicy-screen .header-51 {
  margin-bottom: unset !important;
}

.privacypolicy-screen .menu-16 {
  width: 36px !important;
  height: 35px !important;
  position: relative !important;
}

.privacypolicy-screen .privacypolicy-2 {
  z-index: 0;
  background-color: #fff;
  flex-direction: column;
  flex: none;
  align-self: stretch;
  align-items: flex-start;
  width: 100%;
  display: flex;
  position: relative;
}

.privacypolicy-screen .midashi-16 {
  flex-direction: column;
  flex: none;
  align-self: stretch;
  align-items: flex-start;
  width: 100%;
  display: flex;
  position: relative;
}

.privacypolicy-screen .heading-70 {
  background-image: url("midashi-1.05af3916.png") !important;
  flex: none !important;
  align-self: stretch !important;
  width: 100% !important;
  display: flex !important;
}

.privacypolicy-screen .heading-71 {
  color: #fff !important;
  letter-spacing: 1.2px !important;
  font-size: 24px !important;
  top: 6px !important;
  left: -69px !important;
}

.privacypolicy-screen .heading-72 {
  color: #fff !important;
  letter-spacing: .8px !important;
  font-size: 16px !important;
  top: 36px !important;
  left: -6px !important;
}

.privacypolicy-screen .breadcrumbs-17 {
  flex: none;
  justify-content: center;
  align-self: stretch;
  align-items: flex-start;
  gap: 30px;
  width: 100%;
  padding: 20px 15px;
  display: flex;
  position: relative;
}

.privacypolicy-screen .frame-447 {
  flex: 1;
  align-items: center;
  gap: 17px;
  display: flex;
  position: relative;
}

.privacypolicy-screen .text-wrapper-571 {
  color: var(--gray);
  font-family: var(--tag-font-family);
  font-size: var(--tag-font-size);
  font-style: var(--tag-font-style);
  font-weight: var(--tag-font-weight);
  letter-spacing: var(--tag-letter-spacing);
  line-height: var(--tag-line-height);
  white-space: nowrap;
  width: fit-content;
  margin-top: -1px;
  display: block;
  position: relative;
}

.privacypolicy-screen .component-10930-12 {
  left: unset !important;
  top: unset !important;
  width: 4.57px !important;
  height: 8.14px !important;
  position: relative !important;
}

.privacypolicy-screen .text-wrapper-572 {
  color: var(--gray);
  font-family: var(--tag-font-family);
  font-size: var(--tag-font-size);
  font-style: var(--tag-font-style);
  font-weight: var(--tag-font-weight);
  letter-spacing: var(--tag-letter-spacing);
  line-height: var(--tag-line-height);
  white-space: nowrap;
  width: fit-content;
  margin-top: -1px;
  position: relative;
}

.privacypolicy-screen .privacypolicys-2 {
  background-color: var(--palepurple);
  flex-direction: column;
  flex: none;
  align-self: stretch;
  align-items: center;
  gap: 40px;
  width: 100%;
  padding: 40px 15px;
  display: flex;
  position: relative;
}

.privacypolicy-screen .frame-448 {
  background-color: var(--back-white);
  border-radius: 14px;
  flex-direction: column;
  flex: none;
  align-self: stretch;
  align-items: flex-start;
  gap: 40px;
  width: 100%;
  padding: 50px 20px;
  display: flex;
  position: relative;
}

.privacypolicy-screen .frame-449 {
  flex-direction: column;
  flex: none;
  align-self: stretch;
  align-items: flex-start;
  gap: 24px;
  width: 100%;
  display: flex;
  position: relative;
}

.privacypolicy-screen .heading-73 {
  flex-direction: column;
  flex: none;
  align-self: stretch;
  align-items: flex-start;
  gap: 7px;
  width: 100%;
  display: flex;
  position: relative;
}

.privacypolicy-screen .text-wrapper-573 {
  color: #454545;
  letter-spacing: 2.3px;
  align-self: stretch;
  margin-top: -1px;
  font-family: Noto Sans JP, Helvetica;
  font-size: 23px;
  font-weight: 700;
  line-height: 43px;
  position: relative;
}

.privacypolicy-screen .line-pg-2 {
  align-self: stretch;
  width: 100%;
  height: 2px;
  position: relative;
}

.privacypolicy-screen .div-30 {
  color: #454545;
  letter-spacing: .75px;
  align-self: stretch;
  font-family: Noto Sans JP, Helvetica;
  font-size: 15px;
  font-weight: 400;
  line-height: 25px;
  position: relative;
}

.privacypolicy-screen .frame-450 {
  flex-direction: column;
  flex: none;
  align-self: stretch;
  align-items: flex-start;
  gap: 5px;
  width: 100%;
  display: flex;
  position: relative;
}

.privacypolicy-screen .bulletpoint-4 {
  flex-wrap: wrap;
  flex: none;
  align-self: stretch;
  align-items: flex-start;
  gap: 5px;
  width: 100%;
  display: flex;
  position: relative;
}

.privacypolicy-screen .group-82 {
  width: 17px;
  height: 28px;
  position: relative;
}

.privacypolicy-screen .ellipse-8 {
  background-color: #6079c1;
  border-radius: 5px;
  width: 10px;
  height: 10px;
  position: relative;
  top: 9px;
  left: 3px;
}

.privacypolicy-screen .text-wrapper-574 {
  color: #454545;
  font-family: var(--text-font-family);
  font-size: var(--text-font-size);
  font-style: var(--text-font-style);
  font-weight: var(--text-font-weight);
  letter-spacing: var(--text-letter-spacing);
  line-height: var(--text-line-height);
  flex: 1;
  margin-top: -1px;
  position: relative;
}

.privacypolicy-screen .flexcontainer-65 {
  flex-direction: column;
  align-items: flex-start;
  gap: 5px;
  display: flex;
}

.privacypolicy-screen .text-wrapper-575 {
  letter-spacing: .11px;
}

.privacypolicy-screen .text-39 {
  color: #0000;
  letter-spacing: .75px;
  align-self: stretch;
  font-family: Noto Sans JP, Helvetica;
  font-size: 15px;
  font-weight: 400;
  line-height: 25px;
  position: relative;
}

.privacypolicy-screen .text-wrapper-576 {
  color: #454545;
  letter-spacing: .11px;
}

.privacypolicy-screen .text-wrapper-577 {
  color: #d45252;
  letter-spacing: .11px;
}

.privacypolicy-screen .frame-451 {
  flex-direction: column;
  flex: none;
  align-self: stretch;
  align-items: flex-start;
  gap: 30px;
  width: 100%;
  display: flex;
  position: relative;
}

.privacypolicy-screen .text-40 {
  color: #454545;
  letter-spacing: .75px;
  align-self: stretch;
  font-family: Noto Sans JP, Helvetica;
  font-size: 15px;
  font-weight: 400;
  line-height: 15px;
  position: relative;
}

.privacypolicy-screen .text-wrapper-578 {
  font-family: var(--text-bold-font-family);
  font-size: var(--text-bold-font-size);
  font-style: var(--text-bold-font-style);
  font-weight: var(--text-bold-font-weight);
  letter-spacing: var(--text-bold-letter-spacing);
  line-height: var(--text-bold-line-height);
}

.privacypolicy-screen .text-wrapper-579 {
  font-family: var(--text-font-family);
  font-size: var(--text-font-size);
  font-style: var(--text-font-style);
  font-weight: var(--text-font-weight);
  letter-spacing: var(--text-letter-spacing);
  line-height: var(--text-line-height);
}

.privacypolicy-screen .text-wrapper-580 {
  color: #454545;
  font-family: var(--text-font-family);
  font-size: var(--text-font-size);
  font-style: var(--text-font-style);
  font-weight: var(--text-font-weight);
  letter-spacing: var(--text-letter-spacing);
  line-height: var(--text-line-height);
  align-self: stretch;
  position: relative;
}

.privacypolicy-screen .CONTACT-40 {
  background-color: var(--backwhite);
  flex-direction: column;
  flex: none;
  justify-content: center;
  align-self: stretch;
  align-items: center;
  gap: 40px;
  width: 100%;
  padding: 100px 20px;
  display: flex;
  position: relative;
}

.privacypolicy-screen .CONTACT-41 {
  width: 280px;
  height: 160px;
  position: relative;
}

.privacypolicy-screen .frame-452 {
  background-color: var(--purple);
  border-radius: 6px;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  gap: 20px 74px;
  width: 280px;
  max-width: 700px;
  padding: 50px 60px;
  display: flex;
  position: relative;
  top: -38px;
}

.privacypolicy-screen .group-83 {
  width: 216px;
  height: 60px;
  margin-left: -26px;
  margin-right: -30px;
  position: relative;
}

.privacypolicy-screen .overlap-group-21 {
  width: 212px;
  height: 60px;
  position: relative;
}

.privacypolicy-screen .text-wrapper-581 {
  color: var(--white);
  letter-spacing: 2.4px;
  text-align: center;
  width: 212px;
  font-family: Zen Kaku Gothic New, Helvetica;
  font-size: 24px;
  font-weight: 500;
  line-height: normal;
  position: absolute;
  top: 0;
  left: 0;
}

.privacypolicy-screen .text-wrapper-582 {
  color: var(--white);
  letter-spacing: 0;
  text-align: center;
  width: 134px;
  font-family: Slackside One, Helvetica;
  font-size: 20px;
  font-weight: 400;
  line-height: normal;
  position: absolute;
  top: 31px;
  left: 39px;
}

.privacypolicy-screen .group-84 {
  width: 254px;
  height: 55px;
  margin-left: -46px;
  margin-right: -48px;
  position: relative;
}

.privacypolicy-screen .flexcontainer-66 {
  flex-direction: column;
  align-items: flex-start;
  gap: 5px;
  width: 252px;
  height: 55px;
  display: flex;
  position: relative;
}

.privacypolicy-screen .text-i-62 {
  color: var(--white);
  letter-spacing: .75px;
  align-self: stretch;
  font-family: Noto Sans JP, Helvetica;
  font-size: 15px;
  font-weight: 400;
  line-height: 25px;
  position: relative;
}

.privacypolicy-screen .footer-23 {
  background-color: #454545;
  flex-direction: column;
  flex: none;
  align-self: stretch;
  align-items: center;
  gap: 50px;
  width: 100%;
  padding: 50px 20px;
  display: flex;
  position: relative;
}

.privacypolicy-screen .frame-453 {
  flex-direction: column;
  flex: none;
  align-self: stretch;
  align-items: flex-start;
  gap: 50px;
  width: 100%;
  display: flex;
  position: relative;
}

.privacypolicy-screen .logo-white-32 {
  left: unset !important;
  top: unset !important;
  width: 121px !important;
  height: 40px !important;
  position: relative !important;
}

.privacypolicy-screen .flexcontainer-67 {
  flex-direction: column;
  align-items: flex-start;
  gap: 3px;
  display: flex;
}

.privacypolicy-screen .text-i-63 {
  color: #fff;
  font-family: var(--smalltext-SP-font-family);
  font-size: var(--smalltext-SP-font-size);
  font-style: var(--smalltext-SP-font-style);
  font-weight: var(--smalltext-SP-font-weight);
  letter-spacing: var(--smalltext-SP-letter-spacing);
  line-height: var(--smalltext-SP-line-height);
  align-self: stretch;
  position: relative;
}

.privacypolicy-screen .text-wrapper-583 {
  font-family: var(--smalltext-SP-font-family);
  font-size: var(--smalltext-SP-font-size);
  font-style: var(--smalltext-SP-font-style);
  font-weight: var(--smalltext-SP-font-weight);
  letter-spacing: var(--smalltext-SP-letter-spacing);
  line-height: var(--smalltext-SP-line-height);
}

.privacypolicy-screen .frame-454 {
  flex: none;
  align-items: flex-start;
  gap: 60px;
  margin-right: -14px;
  display: inline-flex;
  position: relative;
}

.privacypolicy-screen .frame-455 {
  flex-direction: column;
  flex: none;
  align-items: flex-start;
  gap: 30px;
  display: inline-flex;
  position: relative;
}

.privacypolicy-screen .frame-456 {
  flex-direction: column;
  flex: none;
  align-items: flex-start;
  gap: 4px;
  display: inline-flex;
  position: relative;
}

.privacypolicy-screen .text-wrapper-584 {
  color: #fff;
  font-family: var(--text-bold-mini-font-family);
  font-size: var(--text-bold-mini-font-size);
  font-style: var(--text-bold-mini-font-style);
  font-weight: var(--text-bold-mini-font-weight);
  letter-spacing: var(--text-bold-mini-letter-spacing);
  line-height: var(--text-bold-mini-line-height);
  white-space: nowrap;
  width: fit-content;
  margin-top: -1px;
  display: block;
  position: relative;
}

.privacypolicy-screen .text-wrapper-585 {
  color: #fff;
  font-family: var(--text-bold-mini-font-family);
  font-size: var(--text-bold-mini-font-size);
  font-style: var(--text-bold-mini-font-style);
  font-weight: var(--text-bold-mini-font-weight);
  letter-spacing: var(--text-bold-mini-letter-spacing);
  line-height: var(--text-bold-mini-line-height);
  white-space: nowrap;
  width: fit-content;
  position: relative;
}

.privacypolicy-screen .text-wrapper-586 {
  color: #fff;
  font-family: var(--text-bold-mini-font-family);
  font-size: var(--text-bold-mini-font-size);
  font-style: var(--text-bold-mini-font-style);
  font-weight: var(--text-bold-mini-font-weight);
  letter-spacing: var(--text-bold-mini-letter-spacing);
  line-height: var(--text-bold-mini-line-height);
  white-space: nowrap;
  width: fit-content;
  margin-top: -1px;
  position: relative;
}

.privacypolicy-screen .text-wrapper-587 {
  color: #fff;
  font-family: var(--text-bold-mini-font-family);
  font-size: var(--text-bold-mini-font-size);
  font-style: var(--text-bold-mini-font-style);
  font-weight: var(--text-bold-mini-font-weight);
  letter-spacing: var(--text-bold-mini-letter-spacing);
  line-height: var(--text-bold-mini-line-height);
  white-space: nowrap;
  width: fit-content;
  display: block;
  position: relative;
}

.privacypolicy-screen .text-wrapper-588 {
  color: #fff;
  letter-spacing: 1.31px;
  width: fit-content;
  font-family: Manrope, Helvetica;
  font-size: 13.1px;
  font-weight: 400;
  line-height: normal;
  position: relative;
}

.screen {
  background-color: #fff;
  flex-direction: column;
  align-items: flex-start;
  display: flex;
  position: relative;
}

.screen .headrer-2 {
  flex-direction: column;
  flex: none;
  align-self: stretch;
  align-items: flex-start;
  width: 100%;
  display: flex;
  position: relative;
}

.screen .logo-7 {
  flex: none;
  justify-content: space-between;
  align-self: stretch;
  align-items: flex-start;
  width: 100%;
  padding: 0 0 20px 50px;
  display: flex;
  position: relative;
}

.screen .group-85 {
  width: 152.61px;
  height: 70px;
  position: relative;
}

.screen .logo-8 {
  width: 153px !important;
  height: 57px !important;
  position: relative !important;
  top: 13px !important;
}

.screen .component-4 {
  left: unset !important;
  top: unset !important;
  width: 71px !important;
  height: 70px !important;
  position: relative !important;
}

.screen .midashi-17 {
  background-image: url("midashi-1.05af3916.png");
  background-position: 50%;
  background-size: cover;
  flex: none;
  justify-content: center;
  align-self: stretch;
  align-items: flex-start;
  gap: 10px;
  width: 100%;
  padding: 20px 0 30px;
  display: flex;
  position: relative;
}

.screen .component-5 {
  width: 108px;
  height: 89px;
  position: relative;
}

.screen .text-wrapper-589 {
  color: #fff;
  letter-spacing: 1.6px;
  text-align: center;
  white-space: nowrap;
  font-family: Zen Kaku Gothic New, Helvetica;
  font-size: 32px;
  font-weight: 500;
  line-height: normal;
  position: absolute;
  top: 14px;
  left: -12px;
}

.screen .TEXTEXT-4 {
  color: #fff;
  letter-spacing: 0;
  text-align: center;
  white-space: nowrap;
  font-family: Slackside One, Helvetica;
  font-size: 23px;
  font-weight: 400;
  line-height: normal;
  position: absolute;
  top: 52px;
  left: -11px;
}

.screen .frame-457 {
  flex: none;
  align-self: stretch;
  align-items: center;
  gap: 30px;
  width: 100%;
  padding: 50px;
  display: flex;
  position: relative;
}

.screen .text-wrapper-590 {
  color: var(--gray);
  font-family: var(--tag-font-family);
  font-size: var(--tag-font-size);
  font-style: var(--tag-font-style);
  font-weight: var(--tag-font-weight);
  letter-spacing: var(--tag-letter-spacing);
  line-height: var(--tag-line-height);
  white-space: nowrap;
  width: fit-content;
  margin-top: -1px;
  position: relative;
}

.screen .component-10930-13 {
  left: unset !important;
  top: unset !important;
  width: 4.57px !important;
  height: 8.14px !important;
  position: relative !important;
}

.screen .member-wrapper {
  background-image: url("image-2.9ec2d89a.png");
  background-position: 50%;
  background-size: cover;
  flex-direction: column;
  flex: none;
  align-self: stretch;
  align-items: center;
  gap: 40px;
  width: 100%;
  padding: 80px 15px;
  display: flex;
  position: relative;
}

.screen .member-5 {
  flex: none;
  justify-content: center;
  align-self: stretch;
  align-items: flex-start;
  width: 100%;
  display: flex;
  position: relative;
}

.screen .div-31 {
  align-self: stretch;
  width: 100px;
  position: relative;
}

.screen .frame-458 {
  opacity: .9;
  background-color: #fff;
  flex-direction: column;
  align-items: flex-start;
  gap: 30px;
  width: 900px;
  padding: 0 20px;
  display: flex;
  position: relative;
}

.screen .frame-459 {
  flex-direction: column;
  flex: none;
  align-self: stretch;
  align-items: center;
  gap: 50px;
  width: 100%;
  padding: 80px 0;
  display: flex;
  position: relative;
}

.screen .component-6 {
  width: 302px;
  height: 89px;
  position: relative;
}

.screen .text-wrapper-591 {
  color: #272727;
  letter-spacing: 1.6px;
  text-align: center;
  white-space: nowrap;
  font-family: Zen Kaku Gothic New, Helvetica;
  font-size: 32px;
  font-weight: 500;
  line-height: normal;
  position: absolute;
  top: 14px;
  left: 85px;
}

.screen .TEXTEXT-5 {
  color: var(--purple);
  letter-spacing: 0;
  text-align: center;
  white-space: nowrap;
  font-family: Slackside One, Helvetica;
  font-size: 23px;
  font-weight: 400;
  line-height: normal;
  position: absolute;
  top: 58px;
  left: 24px;
}

.screen .flexcontainer-68 {
  flex-direction: column;
  align-items: flex-start;
  gap: 2px;
  display: flex;
}

.screen .text-41 {
  color: var(--darkgray);
  letter-spacing: 1px;
  text-align: center;
  align-self: stretch;
  font-family: Noto Sans JP, Helvetica;
  font-size: 20px;
  font-weight: 500;
  line-height: 37px;
  position: relative;
}

.screen .text-wrapper-592 {
  letter-spacing: .2px;
}

.screen .flexcontainer-69 {
  flex-direction: column;
  align-items: flex-start;
  gap: 5px;
  display: flex;
}

.screen .text-42 {
  color: var(--darkgray);
  letter-spacing: .75px;
  text-align: center;
  align-self: stretch;
  font-family: Noto Sans JP, Helvetica;
  font-size: 15px;
  font-weight: 400;
  line-height: 25px;
  position: relative;
}

.screen .text-wrapper-593 {
  letter-spacing: .11px;
}

.screen .frame-460 {
  background-color: var(--palegray);
  flex-direction: column;
  flex: none;
  align-self: stretch;
  align-items: center;
  gap: 40px;
  width: 100%;
  padding: 80px 15px;
  display: flex;
  position: relative;
}

.screen .component-7 {
  width: 324px;
  height: 89px;
  position: relative;
}

.screen .text-wrapper-594 {
  color: #272727;
  letter-spacing: 1.6px;
  text-align: center;
  white-space: nowrap;
  font-family: Zen Kaku Gothic New, Helvetica;
  font-size: 32px;
  font-weight: 500;
  line-height: normal;
  position: absolute;
  top: 14px;
  left: 96px;
}

.screen .TEXTEXT-6 {
  color: #6079c1;
  letter-spacing: 0;
  text-align: center;
  white-space: nowrap;
  font-family: Slackside One, Helvetica;
  font-size: 23px;
  font-weight: 400;
  line-height: normal;
  position: absolute;
  top: 58px;
  left: 19px;
}

.screen .text-wrapper-595 {
  color: #454545;
  letter-spacing: 1.25px;
  text-align: center;
  align-self: stretch;
  font-family: Noto Sans JP, Helvetica;
  font-size: 25px;
  font-weight: 700;
  line-height: normal;
  position: relative;
}

.screen .text-43 {
  color: #454545;
  letter-spacing: .75px;
  text-align: center;
  align-self: stretch;
  font-family: Noto Sans JP, Helvetica;
  font-size: 15px;
  font-weight: 400;
  line-height: 25px;
  position: relative;
}

.screen .ill-3 {
  object-fit: cover;
  width: 428px;
  height: 254px;
  position: relative;
}

.screen .view-8 {
  background-color: var(--backwhite);
  flex-direction: column;
  flex: none;
  align-self: stretch;
  align-items: center;
  gap: 40px;
  width: 100%;
  padding: 50px 15px;
  display: flex;
  position: relative;
}

.screen .component-8 {
  width: 184px;
  height: 103px;
  position: relative;
}

.screen .text-wrapper-596 {
  color: #272727;
  letter-spacing: 1.6px;
  text-align: center;
  white-space: nowrap;
  font-family: Zen Kaku Gothic New, Helvetica;
  font-size: 32px;
  font-weight: 500;
  line-height: normal;
  position: absolute;
  top: 14px;
  left: 59px;
}

.screen .TEXTEXT-7 {
  color: #6079c1;
  letter-spacing: 0;
  text-align: center;
  white-space: nowrap;
  font-family: Slackside One, Helvetica;
  font-size: 23px;
  font-weight: 400;
  line-height: normal;
  position: absolute;
  top: 67px;
  left: 54px;
}

.screen .frame-461 {
  flex-direction: column;
  align-items: flex-start;
  gap: 30px;
  width: 800px;
  display: flex;
  position: relative;
}

.screen .frame-462 {
  flex-direction: column;
  flex: none;
  align-self: stretch;
  align-items: flex-start;
  gap: 15px;
  width: 100%;
  display: flex;
  position: relative;
}

.screen .text-wrapper-597 {
  color: var(--darkgray);
  letter-spacing: 1px;
  align-self: stretch;
  margin-top: -1px;
  font-family: Noto Sans JP, Helvetica;
  font-size: 20px;
  font-weight: 700;
  line-height: 37px;
  position: relative;
}

.screen .line-24 {
  width: 90px !important;
}

.screen .text-wrapper-598 {
  color: var(--darkgray);
  letter-spacing: .75px;
  align-self: stretch;
  font-family: Noto Sans JP, Helvetica;
  font-size: 15px;
  font-weight: 400;
  line-height: 25px;
  position: relative;
}

.screen .frame-463 {
  background-color: var(--backwhite);
  flex-direction: column;
  flex: none;
  align-self: stretch;
  align-items: center;
  gap: 40px;
  width: 100%;
  padding: 100px 15px;
  display: flex;
  position: relative;
}

.screen .group-86 {
  width: 562px;
  height: 138px;
  position: relative;
}

.screen .frame-464 {
  background-color: var(--purple);
  border-radius: 6px;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  gap: 40px 20px;
  min-width: 280px;
  max-width: 700px;
  padding: 40px 70px;
  display: inline-flex;
  position: relative;
}

.screen .group-87 {
  width: 134px;
  height: 58px;
  position: relative;
}

.screen .text-wrapper-599 {
  color: var(--white);
  letter-spacing: 2px;
  text-align: center;
  font-family: Zen Kaku Gothic New, Helvetica;
  font-size: 20px;
  font-weight: 500;
  line-height: normal;
  position: absolute;
  top: 0;
  left: 0;
}

.screen .text-wrapper-600 {
  color: var(--white);
  letter-spacing: 0;
  text-align: center;
  font-family: Slackside One, Helvetica;
  font-size: 20px;
  font-weight: 400;
  line-height: normal;
  position: absolute;
  top: 29px;
  left: 24px;
}

.screen .group-88 {
  width: 254px;
  height: 55px;
  margin-right: -2px;
  position: relative;
}

.screen .flexcontainer-70 {
  flex-direction: column;
  align-items: flex-start;
  gap: 5px;
  width: 252px;
  height: 55px;
  display: flex;
  position: relative;
}

.screen .text-44 {
  color: var(--white);
  letter-spacing: .75px;
  align-self: stretch;
  font-family: Noto Sans JP, Helvetica;
  font-size: 15px;
  font-weight: 400;
  line-height: 25px;
  position: relative;
}

.screen .footer-24 {
  background-color: #454545;
  flex-direction: column;
  flex: none;
  align-self: stretch;
  align-items: center;
  gap: 50px;
  width: 100%;
  padding: 100px 15px 40px;
  display: flex;
  position: relative;
}

.screen .frame-465 {
  flex-wrap: wrap;
  flex: none;
  justify-content: space-between;
  align-self: stretch;
  align-items: flex-start;
  gap: 150px 50px;
  width: 100%;
  padding: 0 100px;
  display: flex;
  position: relative;
}

.screen .frame-466 {
  flex-direction: column;
  flex: 1;
  align-items: flex-start;
  gap: 44px;
  display: flex;
  position: relative;
}

.screen .logo-white-33 {
  left: unset !important;
  top: unset !important;
  width: 163.65px !important;
  height: 54.26px !important;
  position: relative !important;
}

.screen .text-i-64 {
  color: #fff;
  font-family: var(--text-font-family);
  font-size: var(--text-font-size);
  font-style: var(--text-font-style);
  font-weight: var(--text-font-weight);
  letter-spacing: var(--text-letter-spacing);
  line-height: var(--text-line-height);
  align-self: stretch;
  position: relative;
}

.screen .text-wrapper-601 {
  font-family: var(--text-font-family);
  font-size: var(--text-font-size);
  font-style: var(--text-font-style);
  font-weight: var(--text-font-weight);
  letter-spacing: var(--text-letter-spacing);
  line-height: var(--text-line-height);
}

.screen .frame-467 {
  flex: none;
  align-items: flex-start;
  gap: 100px;
  display: inline-flex;
  position: relative;
}

.screen .frame-468 {
  flex-direction: column;
  flex: none;
  align-items: flex-start;
  gap: 30px;
  display: inline-flex;
  position: relative;
}

.screen .text-wrapper-602 {
  color: #fff;
  font-family: var(--text-bold-font-family);
  font-size: var(--text-bold-font-size);
  font-style: var(--text-bold-font-style);
  font-weight: var(--text-bold-font-weight);
  letter-spacing: var(--text-bold-letter-spacing);
  line-height: var(--text-bold-line-height);
  width: fit-content;
  margin-top: -1px;
  position: relative;
}

.screen .text-wrapper-603 {
  color: #fff;
  font-family: var(--text-bold-font-family);
  font-size: var(--text-bold-font-size);
  font-style: var(--text-bold-font-style);
  font-weight: var(--text-bold-font-weight);
  letter-spacing: var(--text-bold-letter-spacing);
  line-height: var(--text-bold-line-height);
  width: fit-content;
  position: relative;
}

.screen .text-wrapper-604 {
  color: #fff;
  font-family: var(--text-bold-font-family);
  font-size: var(--text-bold-font-size);
  font-style: var(--text-bold-font-style);
  font-weight: var(--text-bold-font-weight);
  letter-spacing: var(--text-bold-letter-spacing);
  line-height: var(--text-bold-line-height);
  white-space: nowrap;
  width: fit-content;
  margin-top: -1px;
  position: relative;
}

.screen .text-wrapper-605 {
  color: #fff;
  font-family: var(--text-bold-font-family);
  font-size: var(--text-bold-font-size);
  font-style: var(--text-bold-font-style);
  font-weight: var(--text-bold-font-weight);
  letter-spacing: var(--text-bold-letter-spacing);
  line-height: var(--text-bold-line-height);
  white-space: nowrap;
  width: fit-content;
  position: relative;
}

.screen .group-89 {
  width: 154px;
  height: 18px;
  position: relative;
}

.screen .text-wrapper-606 {
  color: #fff;
  letter-spacing: 1.31px;
  font-family: Manrope, Helvetica;
  font-size: 13.1px;
  font-weight: 400;
  line-height: normal;
  position: absolute;
  top: 0;
  left: 0;
}

.screen-screen {
  background-color: #fff;
  flex-direction: column;
  align-items: flex-start;
  display: flex;
  position: relative;
}

.screen-screen .headrer-3 {
  flex-direction: column;
  flex: none;
  align-self: stretch;
  align-items: flex-start;
  width: 100%;
  display: flex;
  position: relative;
}

.screen-screen .logo-9 {
  flex: none;
  justify-content: space-between;
  align-self: stretch;
  align-items: flex-start;
  width: 100%;
  padding: 0 0 20px 30px;
  display: flex;
  position: relative;
}

.screen-screen .frame-469 {
  width: 164px;
  height: 53px;
  position: relative;
}

.screen-screen .group-90 {
  background-image: url("zsykr-0.252254e0.png");
  background-size: 100% 100%;
  width: 101px;
  height: 38px;
  position: relative;
  top: 15px;
}

.screen-screen .component-9 {
  left: unset !important;
  top: unset !important;
  width: 53px !important;
  height: 52px !important;
  position: relative !important;
}

.screen-screen .midashi-18 {
  background-image: url("midashi.dc95bedc.png");
  background-position: 50%;
  background-size: cover;
  flex-direction: column;
  flex: none;
  align-self: stretch;
  align-items: center;
  gap: 10px;
  width: 100%;
  padding: 10px 0;
  display: flex;
  position: relative;
}

.screen-screen .component-10 {
  width: 108px;
  height: 89px;
  position: relative;
}

.screen-screen .text-wrapper-607 {
  color: #fff;
  letter-spacing: 1.4px;
  text-align: center;
  white-space: nowrap;
  font-family: Zen Kaku Gothic New, Helvetica;
  font-size: 28px;
  font-weight: 500;
  line-height: normal;
  position: absolute;
  top: 20px;
  left: -4px;
}

.screen-screen .TEXTEXT-8 {
  color: #fff;
  letter-spacing: 0;
  text-align: center;
  white-space: nowrap;
  font-family: Slackside One, Helvetica;
  font-size: 18px;
  font-weight: 400;
  line-height: normal;
  position: absolute;
  top: 54px;
  left: 4px;
}

.screen-screen .frame-470 {
  flex: none;
  align-self: stretch;
  align-items: center;
  gap: 30px;
  width: 100%;
  padding: 20px 30px;
  display: flex;
  position: relative;
}

.screen-screen .group-91 {
  width: 150.57px;
  height: 15px;
  position: relative;
}

.screen-screen .text-wrapper-608 {
  color: var(--gray);
  font-family: var(--tag-font-family);
  font-size: var(--tag-font-size);
  font-style: var(--tag-font-style);
  font-weight: var(--tag-font-weight);
  letter-spacing: var(--tag-letter-spacing);
  line-height: var(--tag-line-height);
  white-space: nowrap;
  position: absolute;
  top: 0;
  left: 0;
}

.screen-screen .component-10930-14 {
  top: 3px !important;
  left: 58px !important;
}

.screen-screen .text-wrapper-609 {
  color: var(--gray);
  font-family: var(--tag-font-family);
  font-size: var(--tag-font-size);
  font-style: var(--tag-font-style);
  font-weight: var(--tag-font-weight);
  letter-spacing: var(--tag-letter-spacing);
  line-height: var(--tag-line-height);
  white-space: nowrap;
  position: absolute;
  top: 0;
  left: 93px;
}

.screen-screen .view-wrapper {
  background-image: url("image.91f2f699.png");
  background-position: 50%;
  background-size: cover;
  flex-direction: column;
  flex: none;
  align-self: stretch;
  align-items: center;
  gap: 40px;
  width: 100%;
  padding: 80px 20px;
  display: flex;
  position: relative;
}

.screen-screen .frame-wrapper-2 {
  flex: none;
  justify-content: center;
  align-self: stretch;
  align-items: flex-start;
  width: 100%;
  display: flex;
  position: relative;
}

.screen-screen .frame-471 {
  opacity: .9;
  background-color: #fff;
  flex-direction: column;
  flex: 1;
  align-items: flex-start;
  gap: 30px;
  padding: 0 20px;
  display: flex;
  position: relative;
}

.screen-screen .frame-472 {
  flex-direction: column;
  flex: none;
  align-self: stretch;
  align-items: center;
  gap: 50px;
  width: 100%;
  padding: 80px 0;
  display: flex;
  position: relative;
}

.screen-screen .component-11 {
  width: 240px;
  height: 89px;
  position: relative;
}

.screen-screen .text-wrapper-610 {
  color: #272727;
  letter-spacing: 1.2px;
  white-space: nowrap;
  font-family: Zen Kaku Gothic New, Helvetica;
  font-size: 24px;
  font-weight: 500;
  line-height: normal;
  position: absolute;
  top: 14px;
  left: 54px;
}

.screen-screen .TEXTEXT-9 {
  color: var(--purple);
  letter-spacing: 0;
  text-align: center;
  white-space: nowrap;
  font-family: Slackside One, Helvetica;
  font-size: 16px;
  font-weight: 400;
  line-height: normal;
  position: absolute;
  top: 58px;
  left: 32px;
}

.screen-screen .flexcontainer-71 {
  flex-direction: column;
  align-items: flex-start;
  gap: 2px;
  display: flex;
}

.screen-screen .text-45 {
  color: var(--darkgray);
  letter-spacing: 1px;
  text-align: center;
  align-self: stretch;
  font-family: Noto Sans JP, Helvetica;
  font-size: 20px;
  font-weight: 500;
  line-height: 37px;
  position: relative;
}

.screen-screen .text-wrapper-611 {
  letter-spacing: .2px;
}

.screen-screen .flexcontainer-72 {
  flex-direction: column;
  align-items: flex-start;
  gap: 5px;
  display: flex;
}

.screen-screen .text-46 {
  color: var(--darkgray);
  letter-spacing: .75px;
  text-align: center;
  align-self: stretch;
  font-family: Noto Sans JP, Helvetica;
  font-size: 15px;
  font-weight: 400;
  line-height: 25px;
  position: relative;
}

.screen-screen .text-wrapper-612 {
  letter-spacing: .11px;
}

.screen-screen .frame-473 {
  background-color: var(--palegray);
  flex-direction: column;
  flex: none;
  align-self: stretch;
  align-items: center;
  gap: 40px;
  width: 100%;
  padding: 80px 15px;
  display: flex;
  position: relative;
  overflow: hidden;
}

.screen-screen .view-9 {
  width: 324px;
  height: 89px;
  margin-left: -17px;
  margin-right: -17px;
  position: relative;
}

.screen-screen .text-wrapper-613 {
  color: #272727;
  letter-spacing: 1.2px;
  text-align: center;
  white-space: nowrap;
  font-family: Zen Kaku Gothic New, Helvetica;
  font-size: 24px;
  font-weight: 500;
  line-height: normal;
  position: absolute;
  top: 24px;
  left: 112px;
}

.screen-screen .text-wrapper-614 {
  color: var(--purple);
  letter-spacing: 0;
  text-align: center;
  white-space: nowrap;
  font-family: Slackside One, Helvetica;
  font-size: 16px;
  font-weight: 400;
  line-height: normal;
  position: absolute;
  top: 55px;
  left: 73px;
}

.screen-screen .text-wrapper-615 {
  color: #454545;
  letter-spacing: 1.25px;
  text-align: center;
  align-self: stretch;
  font-family: Noto Sans JP, Helvetica;
  font-size: 25px;
  font-weight: 700;
  line-height: normal;
  position: relative;
}

.screen-screen .text-47 {
  color: #454545;
  letter-spacing: .75px;
  text-align: center;
  align-self: stretch;
  font-family: Noto Sans JP, Helvetica;
  font-size: 15px;
  font-weight: 400;
  line-height: 25px;
  position: relative;
}

.screen-screen .ill-4 {
  object-fit: cover;
  width: 300px;
  height: 178.04px;
  margin-left: -5px;
  margin-right: -5px;
  position: relative;
}

.screen-screen .div-32 {
  background-color: var(--backwhite);
  flex-direction: column;
  flex: none;
  align-self: stretch;
  align-items: center;
  gap: 40px;
  width: 100%;
  padding: 50px 15px;
  display: flex;
  position: relative;
}

.screen-screen .component-12 {
  width: 184px;
  height: 103px;
  position: relative;
}

.screen-screen .text-wrapper-616 {
  color: #272727;
  letter-spacing: 1.6px;
  text-align: center;
  white-space: nowrap;
  font-family: Zen Kaku Gothic New, Helvetica;
  font-size: 32px;
  font-weight: 500;
  line-height: normal;
  position: absolute;
  top: 14px;
  left: 59px;
}

.screen-screen .TEXTEXT-10 {
  color: #6079c1;
  letter-spacing: 0;
  text-align: center;
  white-space: nowrap;
  font-family: Slackside One, Helvetica;
  font-size: 23px;
  font-weight: 400;
  line-height: normal;
  position: absolute;
  top: 67px;
  left: 54px;
}

.screen-screen .frame-474 {
  flex-direction: column;
  flex: 1;
  align-items: flex-start;
  gap: 40px;
  display: flex;
  position: relative;
}

.screen-screen .frame-475 {
  flex-direction: column;
  flex: none;
  align-self: stretch;
  align-items: flex-start;
  gap: 15px;
  width: 100%;
  display: flex;
  position: relative;
}

.screen-screen .text-wrapper-617 {
  color: var(--darkgray);
  letter-spacing: 1px;
  align-self: stretch;
  margin-top: -1px;
  font-family: Noto Sans JP, Helvetica;
  font-size: 20px;
  font-weight: 700;
  line-height: 37px;
  position: relative;
}

.screen-screen .line-25 {
  width: 90px !important;
}

.screen-screen .text-wrapper-618 {
  color: var(--darkgray);
  letter-spacing: .75px;
  align-self: stretch;
  font-family: Noto Sans JP, Helvetica;
  font-size: 15px;
  font-weight: 400;
  line-height: 25px;
  position: relative;
}

.screen-screen .frame-476 {
  background-color: var(--purple);
  border-radius: 6px;
  flex-direction: column;
  flex: none;
  align-self: stretch;
  align-items: center;
  gap: 40px;
  width: 100%;
  padding: 30px 10px;
  display: flex;
  position: relative;
}

.screen-screen .group-92 {
  width: 134px;
  height: 58px;
  position: relative;
}

.screen-screen .text-wrapper-619 {
  color: var(--white);
  letter-spacing: 2px;
  text-align: center;
  font-family: Zen Kaku Gothic New, Helvetica;
  font-size: 20px;
  font-weight: 500;
  line-height: normal;
  position: absolute;
  top: 0;
  left: 0;
}

.screen-screen .text-wrapper-620 {
  color: var(--white);
  letter-spacing: 0;
  text-align: center;
  font-family: Slackside One, Helvetica;
  font-size: 20px;
  font-weight: 400;
  line-height: normal;
  position: absolute;
  top: 29px;
  left: 24px;
}

.screen-screen .text-48 {
  color: var(--white);
  letter-spacing: .75px;
  text-align: center;
  align-self: stretch;
  font-family: Noto Sans JP, Helvetica;
  font-size: 15px;
  font-weight: 400;
  line-height: 25px;
  position: relative;
}

.screen-screen .footer-25 {
  background-color: #454545;
  flex-direction: column;
  flex: none;
  align-self: stretch;
  align-items: center;
  gap: 50px;
  width: 100%;
  padding: 50px 15px 40px;
  display: flex;
  position: relative;
}

.screen-screen .frame-477 {
  flex-direction: column;
  flex: none;
  align-self: stretch;
  align-items: flex-start;
  gap: 60px;
  width: 100%;
  display: flex;
  position: relative;
}

.screen-screen .frame-478 {
  flex-direction: column;
  flex: none;
  align-items: flex-start;
  gap: 30px;
  display: inline-flex;
  position: relative;
}

.screen-screen .logo-white-34 {
  left: unset !important;
  top: unset !important;
  width: 163.65px !important;
  height: 54.26px !important;
  position: relative !important;
}

.screen-screen .text-i-65 {
  color: #fff;
  font-family: var(--text-mini-font-family);
  font-size: var(--text-mini-font-size);
  font-style: var(--text-mini-font-style);
  font-weight: var(--text-mini-font-weight);
  letter-spacing: var(--text-mini-letter-spacing);
  line-height: var(--text-mini-line-height);
  align-self: stretch;
  position: relative;
}

.screen-screen .text-wrapper-621 {
  font-family: var(--text-mini-font-family);
  font-size: var(--text-mini-font-size);
  font-style: var(--text-mini-font-style);
  font-weight: var(--text-mini-font-weight);
  letter-spacing: var(--text-mini-letter-spacing);
  line-height: var(--text-mini-line-height);
}

.screen-screen .frame-479 {
  flex: none;
  justify-content: space-between;
  align-self: stretch;
  align-items: flex-start;
  width: 100%;
  display: flex;
  position: relative;
}

.screen-screen .text-wrapper-622 {
  color: #fff;
  font-family: var(--text-bold-mini-font-family);
  font-size: var(--text-bold-mini-font-size);
  font-style: var(--text-bold-mini-font-style);
  font-weight: var(--text-bold-mini-font-weight);
  letter-spacing: var(--text-bold-mini-letter-spacing);
  line-height: var(--text-bold-mini-line-height);
  width: fit-content;
  margin-top: -1px;
  position: relative;
}

.screen-screen .text-wrapper-623 {
  color: #fff;
  font-family: var(--text-bold-mini-font-family);
  font-size: var(--text-bold-mini-font-size);
  font-style: var(--text-bold-mini-font-style);
  font-weight: var(--text-bold-mini-font-weight);
  letter-spacing: var(--text-bold-mini-letter-spacing);
  line-height: var(--text-bold-mini-line-height);
  width: fit-content;
  position: relative;
}

.screen-screen .text-wrapper-624 {
  color: #fff;
  font-family: var(--text-bold-mini-font-family);
  font-size: var(--text-bold-mini-font-size);
  font-style: var(--text-bold-mini-font-style);
  font-weight: var(--text-bold-mini-font-weight);
  letter-spacing: var(--text-bold-mini-letter-spacing);
  line-height: var(--text-bold-mini-line-height);
  white-space: nowrap;
  width: fit-content;
  margin-top: -1px;
  position: relative;
}

.screen-screen .text-wrapper-625 {
  color: #fff;
  font-family: var(--text-bold-mini-font-family);
  font-size: var(--text-bold-mini-font-size);
  font-style: var(--text-bold-mini-font-style);
  font-weight: var(--text-bold-mini-font-weight);
  letter-spacing: var(--text-bold-mini-letter-spacing);
  line-height: var(--text-bold-mini-line-height);
  white-space: nowrap;
  width: fit-content;
  position: relative;
}

.screen-screen .group-93 {
  width: 154px;
  height: 18px;
  position: relative;
}

.screen-screen .text-wrapper-626 {
  color: #fff;
  letter-spacing: 1.31px;
  font-family: Manrope, Helvetica;
  font-size: 13.1px;
  font-weight: 400;
  line-height: normal;
  position: absolute;
  top: 0;
  left: 0;
}

.screen-wrapper {
  background-color: #fff;
  flex-direction: column;
  align-items: flex-start;
  display: flex;
  position: relative;
}

.screen-wrapper .headrer-4 {
  flex-direction: column;
  flex: none;
  align-self: stretch;
  align-items: flex-start;
  width: 100%;
  display: flex;
  position: relative;
}

.screen-wrapper .logo-10 {
  flex: none;
  justify-content: space-between;
  align-self: stretch;
  align-items: flex-start;
  width: 100%;
  padding: 0 0 20px 30px;
  display: flex;
  position: relative;
}

.screen-wrapper .frame-480 {
  width: 164px;
  height: 64px;
  position: relative;
}

.screen-wrapper .group-94 {
  background-image: url("zsykr-0.252254e0.png");
  background-size: 100% 100%;
  width: 132px;
  height: 49px;
  position: relative;
  top: 15px;
}

.screen-wrapper .component-13 {
  left: unset !important;
  top: unset !important;
  width: 53px !important;
  height: 52px !important;
  position: relative !important;
}

.screen-wrapper .midashi-19 {
  background-color: #6079c1;
  flex: none;
  justify-content: center;
  align-self: stretch;
  align-items: flex-start;
  gap: 10px;
  width: 100%;
  padding: 20px 0 30px;
  display: flex;
  position: relative;
}

.screen-wrapper .component-14 {
  width: 108px;
  height: 89px;
  position: relative;
}

.screen-wrapper .text-wrapper-627 {
  color: #fff;
  letter-spacing: 1.6px;
  text-align: center;
  white-space: nowrap;
  font-family: Zen Kaku Gothic New, Helvetica;
  font-size: 32px;
  font-weight: 500;
  line-height: normal;
  position: absolute;
  top: 14px;
  left: -12px;
}

.screen-wrapper .TEXTEXT-11 {
  color: #fff;
  letter-spacing: 0;
  text-align: center;
  white-space: nowrap;
  font-family: Slackside One, Helvetica;
  font-size: 23px;
  font-weight: 400;
  line-height: normal;
  position: absolute;
  top: 52px;
  left: -11px;
}

.screen-wrapper .frame-481 {
  flex: none;
  align-self: stretch;
  align-items: center;
  gap: 30px;
  width: 100%;
  padding: 20px 30px;
  display: flex;
  position: relative;
}

.screen-wrapper .group-95 {
  width: 150.57px;
  height: 15px;
  position: relative;
}

.screen-wrapper .text-wrapper-628 {
  color: var(--gray);
  font-family: var(--tag-font-family);
  font-size: var(--tag-font-size);
  font-style: var(--tag-font-style);
  font-weight: var(--tag-font-weight);
  letter-spacing: var(--tag-letter-spacing);
  line-height: var(--tag-line-height);
  white-space: nowrap;
  position: absolute;
  top: 0;
  left: 0;
}

.screen-wrapper .component-10930-15 {
  top: 3px !important;
  left: 58px !important;
}

.screen-wrapper .text-wrapper-629 {
  color: var(--gray);
  font-family: var(--tag-font-family);
  font-size: var(--tag-font-size);
  font-style: var(--tag-font-style);
  font-weight: var(--tag-font-weight);
  letter-spacing: var(--tag-letter-spacing);
  line-height: var(--tag-line-height);
  white-space: nowrap;
  position: absolute;
  top: 0;
  left: 93px;
}

.screen-wrapper .view-10 {
  background-image: url("image-2.9ec2d89a.png");
  background-position: 50%;
  background-size: cover;
  flex-direction: column;
  flex: none;
  align-self: stretch;
  align-items: center;
  gap: 40px;
  width: 100%;
  padding: 80px 15px;
  display: flex;
  position: relative;
}

.screen-wrapper .view-11 {
  flex: none;
  justify-content: center;
  align-self: stretch;
  align-items: flex-start;
  width: 100%;
  display: flex;
  position: relative;
}

.screen-wrapper .frame-482 {
  opacity: .9;
  background-color: #fff;
  flex-direction: column;
  align-items: flex-start;
  gap: 30px;
  width: 700px;
  padding: 0 20px;
  display: flex;
  position: relative;
}

.screen-wrapper .frame-483 {
  flex-direction: column;
  flex: none;
  align-self: stretch;
  align-items: center;
  gap: 50px;
  width: 100%;
  padding: 80px 0;
  display: flex;
  position: relative;
}

.screen-wrapper .component-15 {
  width: 302px;
  height: 89px;
  position: relative;
}

.screen-wrapper .text-wrapper-630 {
  color: #272727;
  letter-spacing: 1.6px;
  text-align: center;
  white-space: nowrap;
  font-family: Zen Kaku Gothic New, Helvetica;
  font-size: 32px;
  font-weight: 500;
  line-height: normal;
  position: absolute;
  top: 14px;
  left: 85px;
}

.screen-wrapper .TEXTEXT-12 {
  color: var(--purple);
  letter-spacing: 0;
  text-align: center;
  white-space: nowrap;
  font-family: Slackside One, Helvetica;
  font-size: 23px;
  font-weight: 400;
  line-height: normal;
  position: absolute;
  top: 58px;
  left: 24px;
}

.screen-wrapper .flexcontainer-73 {
  flex-direction: column;
  align-items: flex-start;
  gap: 2px;
  display: flex;
}

.screen-wrapper .text-49 {
  color: var(--darkgray);
  letter-spacing: 1px;
  text-align: center;
  align-self: stretch;
  font-family: Noto Sans JP, Helvetica;
  font-size: 20px;
  font-weight: 500;
  line-height: 37px;
  position: relative;
}

.screen-wrapper .text-wrapper-631 {
  letter-spacing: .2px;
}

.screen-wrapper .flexcontainer-74 {
  flex-direction: column;
  align-items: flex-start;
  gap: 5px;
  display: flex;
}

.screen-wrapper .text-50 {
  color: var(--darkgray);
  letter-spacing: .75px;
  text-align: center;
  align-self: stretch;
  font-family: Noto Sans JP, Helvetica;
  font-size: 15px;
  font-weight: 400;
  line-height: 25px;
  position: relative;
}

.screen-wrapper .text-wrapper-632 {
  letter-spacing: .11px;
}

.screen-wrapper .view-12 {
  background-color: var(--palegray);
  flex-direction: column;
  flex: none;
  align-self: stretch;
  align-items: center;
  gap: 40px;
  width: 100%;
  padding: 80px 15px;
  display: flex;
  position: relative;
}

.screen-wrapper .component-16 {
  width: 324px;
  height: 89px;
  position: relative;
}

.screen-wrapper .text-wrapper-633 {
  color: #272727;
  letter-spacing: 1.6px;
  text-align: center;
  white-space: nowrap;
  font-family: Zen Kaku Gothic New, Helvetica;
  font-size: 32px;
  font-weight: 500;
  line-height: normal;
  position: absolute;
  top: 14px;
  left: 96px;
}

.screen-wrapper .TEXTEXT-13 {
  color: #6079c1;
  letter-spacing: 0;
  text-align: center;
  white-space: nowrap;
  font-family: Slackside One, Helvetica;
  font-size: 23px;
  font-weight: 400;
  line-height: normal;
  position: absolute;
  top: 58px;
  left: 19px;
}

.screen-wrapper .text-wrapper-634 {
  color: #454545;
  letter-spacing: 1.25px;
  text-align: center;
  align-self: stretch;
  font-family: Noto Sans JP, Helvetica;
  font-size: 25px;
  font-weight: 700;
  line-height: normal;
  position: relative;
}

.screen-wrapper .text-51 {
  color: #454545;
  letter-spacing: .75px;
  text-align: center;
  align-self: stretch;
  font-family: Noto Sans JP, Helvetica;
  font-size: 15px;
  font-weight: 400;
  line-height: 25px;
  position: relative;
}

.screen-wrapper .ill-5 {
  object-fit: cover;
  width: 428px;
  height: 254px;
  position: relative;
}

.screen-wrapper .view-13 {
  background-color: var(--backwhite);
  flex-direction: column;
  flex: none;
  align-self: stretch;
  align-items: center;
  gap: 40px;
  width: 100%;
  padding: 50px 30px;
  display: flex;
  position: relative;
}

.screen-wrapper .component-17 {
  width: 184px;
  height: 103px;
  position: relative;
}

.screen-wrapper .text-wrapper-635 {
  color: #272727;
  letter-spacing: 1.6px;
  text-align: center;
  white-space: nowrap;
  font-family: Zen Kaku Gothic New, Helvetica;
  font-size: 32px;
  font-weight: 500;
  line-height: normal;
  position: absolute;
  top: 14px;
  left: 59px;
}

.screen-wrapper .TEXTEXT-14 {
  color: #6079c1;
  letter-spacing: 0;
  text-align: center;
  white-space: nowrap;
  font-family: Slackside One, Helvetica;
  font-size: 23px;
  font-weight: 400;
  line-height: normal;
  position: absolute;
  top: 67px;
  left: 54px;
}

.screen-wrapper .frame-484 {
  flex-direction: column;
  flex: 1;
  align-items: flex-start;
  gap: 40px;
  display: flex;
  position: relative;
}

.screen-wrapper .frame-485 {
  flex-direction: column;
  flex: none;
  align-self: stretch;
  align-items: flex-start;
  gap: 15px;
  width: 100%;
  display: flex;
  position: relative;
}

.screen-wrapper .text-wrapper-636 {
  color: var(--darkgray);
  letter-spacing: 1px;
  align-self: stretch;
  margin-top: -1px;
  font-family: Noto Sans JP, Helvetica;
  font-size: 20px;
  font-weight: 700;
  line-height: 37px;
  position: relative;
}

.screen-wrapper .line-26 {
  width: 90px !important;
}

.screen-wrapper .text-wrapper-637 {
  color: var(--darkgray);
  letter-spacing: .75px;
  align-self: stretch;
  font-family: Noto Sans JP, Helvetica;
  font-size: 15px;
  font-weight: 400;
  line-height: 25px;
  position: relative;
}

.screen-wrapper .frame-486 {
  background-color: var(--backwhite);
  flex-direction: column;
  flex: none;
  align-self: stretch;
  align-items: center;
  gap: 40px;
  width: 100%;
  padding: 100px 15px;
  display: flex;
  position: relative;
}

.screen-wrapper .group-96 {
  width: 562px;
  height: 118px;
  position: relative;
}

.screen-wrapper .frame-487 {
  background-color: var(--purple);
  border-radius: 6px;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  gap: 40px 20px;
  min-width: 280px;
  max-width: 700px;
  padding: 30px 70px;
  display: inline-flex;
  position: relative;
}

.screen-wrapper .group-97 {
  width: 134px;
  height: 58px;
  position: relative;
}

.screen-wrapper .text-wrapper-638 {
  color: var(--white);
  letter-spacing: 2px;
  text-align: center;
  font-family: Zen Kaku Gothic New, Helvetica;
  font-size: 20px;
  font-weight: 500;
  line-height: normal;
  position: absolute;
  top: 0;
  left: 0;
}

.screen-wrapper .text-wrapper-639 {
  color: var(--white);
  letter-spacing: 0;
  text-align: center;
  font-family: Slackside One, Helvetica;
  font-size: 20px;
  font-weight: 400;
  line-height: normal;
  position: absolute;
  top: 29px;
  left: 24px;
}

.screen-wrapper .group-98 {
  width: 254px;
  height: 55px;
  margin-right: -2px;
  position: relative;
}

.screen-wrapper .flexcontainer-75 {
  flex-direction: column;
  align-items: flex-start;
  gap: 5px;
  width: 252px;
  height: 55px;
  display: flex;
  position: relative;
}

.screen-wrapper .text-52 {
  color: var(--white);
  letter-spacing: .75px;
  align-self: stretch;
  font-family: Noto Sans JP, Helvetica;
  font-size: 15px;
  font-weight: 400;
  line-height: 25px;
  position: relative;
}

.screen-wrapper .footer-26 {
  background-color: #454545;
  flex-direction: column;
  flex: none;
  align-self: stretch;
  align-items: center;
  gap: 50px;
  width: 100%;
  padding: 100px 30px 40px;
  display: flex;
  position: relative;
}

.screen-wrapper .frame-488 {
  flex-wrap: wrap;
  flex: none;
  justify-content: space-between;
  align-self: stretch;
  align-items: flex-start;
  gap: 150px 50px;
  width: 100%;
  display: flex;
  position: relative;
}

.screen-wrapper .frame-489 {
  flex-direction: column;
  flex: 1;
  align-items: flex-start;
  gap: 44px;
  display: flex;
  position: relative;
}

.screen-wrapper .logo-white-35 {
  left: unset !important;
  top: unset !important;
  width: 163.65px !important;
  height: 54.26px !important;
  position: relative !important;
}

.screen-wrapper .text-i-66 {
  color: #fff;
  font-family: var(--text-font-family);
  font-size: var(--text-font-size);
  font-style: var(--text-font-style);
  font-weight: var(--text-font-weight);
  letter-spacing: var(--text-letter-spacing);
  line-height: var(--text-line-height);
  align-self: stretch;
  position: relative;
}

.screen-wrapper .text-wrapper-640 {
  font-family: var(--text-font-family);
  font-size: var(--text-font-size);
  font-style: var(--text-font-style);
  font-weight: var(--text-font-weight);
  letter-spacing: var(--text-letter-spacing);
  line-height: var(--text-line-height);
}

.screen-wrapper .frame-490 {
  flex: none;
  align-items: flex-start;
  gap: 100px;
  display: inline-flex;
  position: relative;
}

.screen-wrapper .frame-491 {
  flex-direction: column;
  flex: none;
  align-items: flex-start;
  gap: 30px;
  display: inline-flex;
  position: relative;
}

.screen-wrapper .text-wrapper-641 {
  color: #fff;
  font-family: var(--text-bold-font-family);
  font-size: var(--text-bold-font-size);
  font-style: var(--text-bold-font-style);
  font-weight: var(--text-bold-font-weight);
  letter-spacing: var(--text-bold-letter-spacing);
  line-height: var(--text-bold-line-height);
  width: fit-content;
  margin-top: -1px;
  position: relative;
}

.screen-wrapper .text-wrapper-642 {
  color: #fff;
  font-family: var(--text-bold-font-family);
  font-size: var(--text-bold-font-size);
  font-style: var(--text-bold-font-style);
  font-weight: var(--text-bold-font-weight);
  letter-spacing: var(--text-bold-letter-spacing);
  line-height: var(--text-bold-line-height);
  width: fit-content;
  position: relative;
}

.screen-wrapper .text-wrapper-643 {
  color: #fff;
  font-family: var(--text-bold-font-family);
  font-size: var(--text-bold-font-size);
  font-style: var(--text-bold-font-style);
  font-weight: var(--text-bold-font-weight);
  letter-spacing: var(--text-bold-letter-spacing);
  line-height: var(--text-bold-line-height);
  white-space: nowrap;
  width: fit-content;
  margin-top: -1px;
  position: relative;
}

.screen-wrapper .text-wrapper-644 {
  color: #fff;
  font-family: var(--text-bold-font-family);
  font-size: var(--text-bold-font-size);
  font-style: var(--text-bold-font-style);
  font-weight: var(--text-bold-font-weight);
  letter-spacing: var(--text-bold-letter-spacing);
  line-height: var(--text-bold-line-height);
  white-space: nowrap;
  width: fit-content;
  position: relative;
}

.screen-wrapper .group-99 {
  width: 154px;
  height: 18px;
  position: relative;
}

.screen-wrapper .text-wrapper-645 {
  color: #fff;
  letter-spacing: 1.31px;
  font-family: Manrope, Helvetica;
  font-size: 13.1px;
  font-weight: 400;
  line-height: normal;
  position: absolute;
  top: 0;
  left: 0;
}

.HOME-wrapper {
  flex-direction: column;
  align-items: center;
  height: 6027px;
  display: flex;
  position: relative;
}

.HOME-wrapper .frame-492 {
  background-image: url("frame-1.633f97be.png");
  background-position: 50%;
  background-size: cover;
  width: 1440px;
  height: 1230px;
  position: relative;
}

.HOME-wrapper .frame-493 {
  background-color: var(--backwhite);
  flex-direction: column;
  flex: none;
  align-self: stretch;
  align-items: center;
  gap: 80px;
  width: 100%;
  padding: 100px 15px;
  display: flex;
  position: relative;
}

.HOME-wrapper .rectangle-9 {
  object-fit: cover;
  width: 491.25px;
  height: 308px;
  position: relative;
}

.HOME-wrapper .frame-494 {
  flex-wrap: wrap;
  flex: none;
  justify-content: center;
  align-self: stretch;
  align-items: flex-start;
  gap: 30px;
  width: 100%;
  display: flex;
  position: relative;
}

.HOME-wrapper .group-100 {
  flex: 1;
  min-width: 300px;
  max-width: 400px;
  height: 66px;
  position: relative;
}

.HOME-wrapper .text-wrapper-646 {
  color: var(--darkgray);
  letter-spacing: 1.15px;
  width: 400px;
  font-family: Noto Sans JP, Helvetica;
  font-size: 23px;
  font-weight: 700;
  line-height: normal;
  position: absolute;
  top: 0;
  left: 0;
}

.HOME-wrapper .group-101 {
  flex: 1;
  min-width: 300px;
  max-width: 500px;
  height: 150px;
  position: relative;
}

.HOME-wrapper .text-wrapper-647 {
  color: var(--darkgray);
  font-family: var(--text-font-family);
  font-size: var(--text-font-size);
  font-style: var(--text-font-style);
  font-weight: var(--text-font-weight);
  letter-spacing: var(--text-letter-spacing);
  line-height: var(--text-line-height);
  width: 500px;
  position: absolute;
  top: 0;
  left: 0;
}

.HOME-wrapper .frame-495 {
  background-image: url("frame-37.0a7512db.png");
  background-position: 50%;
  background-size: cover;
  flex-direction: column;
  flex: none;
  align-self: stretch;
  align-items: center;
  gap: 40px;
  width: 100%;
  padding: 100px 15px;
  display: flex;
  position: relative;
}

.HOME-wrapper .component-18 {
  width: 108px;
  height: 89px;
  position: relative;
}

.HOME-wrapper .text-wrapper-648 {
  color: #272727;
  letter-spacing: 1.6px;
  text-align: center;
  white-space: nowrap;
  font-family: Zen Kaku Gothic New, Helvetica;
  font-size: 32px;
  font-weight: 500;
  line-height: normal;
  position: absolute;
  top: 14px;
  left: -46px;
}

.HOME-wrapper .TEXTEXT-15 {
  color: var(--purple);
  letter-spacing: 0;
  text-align: center;
  white-space: nowrap;
  font-family: Slackside One, Helvetica;
  font-size: 23px;
  font-weight: 400;
  line-height: normal;
  position: absolute;
  top: 52px;
  left: 24px;
}

.HOME-wrapper .group-102 {
  align-self: stretch;
  width: 100%;
  height: 130px;
  margin-right: -2px;
  position: relative;
}

.HOME-wrapper .flexcontainer-76 {
  flex-direction: column;
  align-items: flex-start;
  gap: 5px;
  width: 1410px;
  height: 130px;
  display: flex;
  position: relative;
}

.HOME-wrapper .text-53 {
  color: #454545;
  font-family: var(--text-font-family);
  font-size: var(--text-font-size);
  font-style: var(--text-font-style);
  font-weight: var(--text-font-weight);
  letter-spacing: var(--text-letter-spacing);
  line-height: var(--text-line-height);
  text-align: center;
  align-self: stretch;
  position: relative;
}

.HOME-wrapper .text-wrapper-649 {
  font-family: var(--text-font-family);
  font-size: var(--text-font-size);
  font-style: var(--text-font-style);
  font-weight: var(--text-font-weight);
  letter-spacing: var(--text-letter-spacing);
  line-height: var(--text-line-height);
}

.HOME-wrapper .frame-496 {
  flex-wrap: wrap;
  flex: none;
  justify-content: center;
  align-self: stretch;
  align-items: flex-start;
  gap: 10px;
  width: 100%;
  max-width: 1500px;
  display: flex;
  position: relative;
}

.HOME-wrapper .frame-497 {
  font-weight: 400 !important;
}

.HOME-wrapper .frame-498 {
  max-width: unset !important;
  width: 212px !important;
  padding: 10px 20px !important;
  display: flex !important;
}

.HOME-wrapper .frame-499 {
  align-self: stretch !important;
  width: 100% !important;
  max-width: 200px !important;
  display: flex !important;
}

.HOME-wrapper .frame-500 {
  margin-left: -3.5px !important;
  margin-right: -3.5px !important;
}

.HOME-wrapper .frame-501 {
  line-height: 37px !important;
}

.HOME-wrapper .button-19 {
  all: unset;
  box-sizing: border-box;
  width: 330px;
  height: 76px;
  position: relative;
}

.HOME-wrapper .frame-502 {
  justify-content: center;
  align-items: center;
  gap: 10px;
  display: inline-flex;
  position: relative;
  top: 3px;
  left: 5px;
}

.HOME-wrapper .polygon-wrapper {
  background-color: var(--purple);
  border-radius: 36px;
  width: 320px;
  height: 70px;
  position: relative;
}

.HOME-wrapper .polygon-11 {
  width: 11px;
  height: 17px;
  position: absolute;
  top: 26px;
  left: 291px;
}

.HOME-wrapper .text-wrapper-650 {
  color: var(--white);
  letter-spacing: .8px;
  text-align: center;
  font-family: Noto Sans JP, Helvetica;
  font-size: 16px;
  font-weight: 700;
  line-height: normal;
  position: absolute;
  top: 24px;
  left: 118px;
}

.HOME-wrapper .frame-503 {
  background-color: var(--palegray);
  flex-direction: column;
  flex: none;
  align-self: stretch;
  align-items: center;
  gap: 40px;
  width: 100%;
  padding: 100px 15px;
  display: flex;
  position: relative;
}

.HOME-wrapper .text-wrapper-651 {
  color: #272727;
  letter-spacing: 1.6px;
  text-align: center;
  white-space: nowrap;
  font-family: Zen Kaku Gothic New, Helvetica;
  font-size: 32px;
  font-weight: 500;
  line-height: normal;
  position: absolute;
  top: 14px;
  left: 4px;
}

.HOME-wrapper .TEXTEXT-16 {
  color: var(--purple);
  letter-spacing: 0;
  text-align: center;
  white-space: nowrap;
  font-family: Slackside One, Helvetica;
  font-size: 23px;
  font-weight: 400;
  line-height: normal;
  position: absolute;
  top: 52px;
  left: 28px;
}

.HOME-wrapper .frame-504 {
  flex-wrap: wrap;
  flex: none;
  justify-content: center;
  align-self: stretch;
  align-items: flex-start;
  gap: 30px;
  width: 100%;
  max-width: 1500px;
  display: flex;
  position: relative;
}

.HOME-wrapper .frame-505 {
  background-color: #fff;
  border-radius: 10px;
  align-items: flex-start;
  gap: 14px;
  width: 360px;
  display: flex;
  position: relative;
}

.HOME-wrapper .frame-506 {
  background-color: #fff;
  border-radius: 10px;
  flex-direction: column;
  flex: 1;
  align-items: flex-start;
  gap: 14px;
  height: 442px;
  padding: 12px 12px 20px;
  display: flex;
  position: relative;
}

.HOME-wrapper .frame-507 {
  background-color: #959595;
  border-radius: 6px;
  justify-content: center;
  align-self: stretch;
  align-items: center;
  gap: 10px;
  width: 100%;
  height: 240px;
  display: flex;
  position: relative;
}

.HOME-wrapper .noimage-17 {
  width: 190.9px !important;
  height: 25.92px !important;
  position: relative !important;
}

.HOME-wrapper .text-wrapper-652 {
  color: #8a8a8a;
  font-family: var(--date-font-family);
  font-size: var(--date-font-size);
  font-style: var(--date-font-style);
  font-weight: var(--date-font-weight);
  letter-spacing: var(--date-letter-spacing);
  line-height: var(--date-line-height);
  width: fit-content;
  position: relative;
}

.HOME-wrapper .text-wrapper-653 {
  -webkit-line-clamp: 2;
  color: #454545;
  letter-spacing: .75px;
  text-overflow: ellipsis;
  -webkit-box-orient: vertical;
  align-self: stretch;
  font-family: Noto Sans JP, Helvetica;
  font-size: 15px;
  font-weight: 700;
  line-height: 26px;
  display: -webkit-box;
  position: relative;
  overflow: hidden;
}

.HOME-wrapper .vector-6 {
  object-fit: cover;
  align-self: stretch;
  width: 100%;
  height: 1px;
  position: relative;
}

.HOME-wrapper .text-wrapper-654 {
  -webkit-line-clamp: 2;
  color: var(--purple);
  letter-spacing: .65px;
  text-overflow: ellipsis;
  -webkit-box-orient: vertical;
  align-self: stretch;
  font-family: Noto Sans JP, Helvetica;
  font-size: 13px;
  font-weight: 500;
  line-height: 18.8px;
  display: -webkit-box;
  position: relative;
  overflow: hidden;
}

.HOME-wrapper .noimage-15 {
  left: unset !important;
  top: unset !important;
  width: 190.9px !important;
  height: 25.92px !important;
  position: relative !important;
}

.HOME-wrapper .text-wrapper-655 {
  color: var(--white);
  letter-spacing: .8px;
  text-align: center;
  font-family: Noto Sans JP, Helvetica;
  font-size: 16px;
  font-weight: 700;
  line-height: normal;
  position: absolute;
  top: 24px;
  left: 110px;
}

.HOME-wrapper .frame-508 {
  background-color: var(--palepurple);
  flex-direction: column;
  flex: none;
  align-self: stretch;
  align-items: center;
  gap: 40px;
  width: 100%;
  padding: 100px 15px;
  display: flex;
  position: relative;
}

.HOME-wrapper .text-wrapper-656 {
  color: #272727;
  letter-spacing: 1.6px;
  text-align: center;
  white-space: nowrap;
  font-family: Zen Kaku Gothic New, Helvetica;
  font-size: 32px;
  font-weight: 500;
  line-height: normal;
  position: absolute;
  top: 14px;
  left: -12px;
}

.HOME-wrapper .TEXTEXT-17 {
  color: #6079c1;
  letter-spacing: 0;
  text-align: center;
  white-space: nowrap;
  font-family: Slackside One, Helvetica;
  font-size: 23px;
  font-weight: 400;
  line-height: normal;
  position: absolute;
  top: 52px;
  left: 12px;
}

.HOME-wrapper .flexcontainer-77 {
  flex-direction: column;
  align-items: flex-start;
  gap: 5px;
  display: flex;
}

.HOME-wrapper .text-54 {
  color: #454545;
  letter-spacing: .75px;
  text-align: center;
  align-self: stretch;
  font-family: Noto Sans JP, Helvetica;
  font-size: 15px;
  font-weight: 400;
  line-height: 25px;
  position: relative;
}

.HOME-wrapper .text-wrapper-657 {
  letter-spacing: .11px;
}

.HOME-wrapper .frame-509 {
  flex-wrap: wrap;
  flex: none;
  justify-content: center;
  align-self: stretch;
  align-items: flex-start;
  gap: 30px 10px;
  width: 100%;
  max-width: 1500px;
  display: flex;
  position: relative;
}

.HOME-wrapper .frame-property {
  background-color: var(--white);
  border-radius: 10px;
  flex-direction: column;
  align-items: flex-start;
  gap: 14px;
  width: 360px;
  max-width: 450px;
  padding: 12px 12px 20px;
  display: flex;
  position: relative;
  overflow: hidden;
}

.HOME-wrapper .frame-510 {
  align-items: center;
  gap: 23px;
  width: 332px;
  height: 39px;
  display: flex;
  position: relative;
}

.HOME-wrapper .frame-511 {
  margin-top: -1px !important;
  margin-bottom: -1px !important;
}

.HOME-wrapper .group-103 {
  width: 93px;
  height: 18px;
  position: relative;
}

.HOME-wrapper .text-wrapper-658 {
  color: #8a8a8a;
  font-family: var(--date-font-family);
  font-size: var(--date-font-size);
  font-style: var(--date-font-style);
  font-weight: var(--date-font-weight);
  letter-spacing: var(--date-letter-spacing);
  line-height: var(--date-line-height);
  text-align: right;
  width: 91px;
  position: absolute;
  top: 0;
  left: 0;
}

.HOME-wrapper .frame-512 {
  width: 330px;
  height: 66px;
  margin-top: -13.5px;
  margin-bottom: -13.5px;
  margin-right: -285px;
  position: relative;
}

.HOME-wrapper .line-27 {
  object-fit: cover;
  width: 335px;
  height: 1px;
  position: relative;
}

.HOME-wrapper .text-wrapper-659 {
  -webkit-line-clamp: 2;
  color: #454545;
  letter-spacing: .75px;
  text-overflow: ellipsis;
  -webkit-box-orient: vertical;
  align-self: stretch;
  height: 58px;
  font-family: Noto Sans JP, Helvetica;
  font-size: 15px;
  font-weight: 700;
  line-height: 26px;
  display: -webkit-box;
  position: relative;
  overflow: hidden;
}

.HOME-wrapper .text-wrapper-660 {
  color: #fff;
  letter-spacing: .8px;
  text-align: center;
  font-family: Noto Sans JP, Helvetica;
  font-size: 16px;
  font-weight: 500;
  line-height: normal;
  position: absolute;
  top: 24px;
  left: 102px;
}

.HOME-wrapper .frame-513 {
  background-image: url("frame-40.be55c3fe.png");
  background-position: 50%;
  background-size: cover;
  flex-direction: column;
  flex: none;
  align-self: stretch;
  align-items: center;
  gap: 40px;
  width: 100%;
  padding: 100px 15px;
  display: flex;
  position: relative;
}

.HOME-wrapper .text-wrapper-661 {
  color: var(--white);
  letter-spacing: 1.6px;
  text-align: center;
  white-space: nowrap;
  font-family: Zen Kaku Gothic New, Helvetica;
  font-size: 32px;
  font-weight: 500;
  line-height: normal;
  position: absolute;
  top: 14px;
  left: -12px;
}

.HOME-wrapper .TEXTEXT-18 {
  color: #fff;
  letter-spacing: 0;
  text-align: center;
  white-space: nowrap;
  font-family: Slackside One, Helvetica;
  font-size: 23px;
  font-weight: 400;
  line-height: normal;
  position: absolute;
  top: 52px;
  left: 6px;
}

.HOME-wrapper .white-button-wrapper {
  width: 300px;
  height: 70px;
  position: relative;
}

.HOME-wrapper .white-button-2 {
  background-color: var(--white);
  border-radius: 60px;
  height: 70px;
  position: relative;
}

.HOME-wrapper .text-wrapper-662 {
  color: var(--darkgray);
  letter-spacing: .8px;
  text-align: center;
  font-family: Noto Sans JP, Helvetica;
  font-size: 16px;
  font-weight: 700;
  line-height: normal;
  position: absolute;
  top: 22px;
  left: 116px;
}

.HOME-wrapper .frame-514 {
  background-color: var(--backwhite);
  flex-direction: column;
  flex: none;
  align-self: stretch;
  align-items: center;
  gap: 40px;
  width: 100%;
  padding: 100px 15px;
  display: flex;
  position: relative;
}

.HOME-wrapper .group-104 {
  width: 588px;
  height: 142px;
  position: relative;
}

.HOME-wrapper .frame-515 {
  background-color: var(--purple);
  border-radius: 6px;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  gap: 40px 20px;
  min-width: 280px;
  max-width: 700px;
  padding: 40px 70px;
  display: inline-flex;
  position: relative;
}

.HOME-wrapper .group-105 {
  width: 160px;
  height: 62px;
  position: relative;
}

.HOME-wrapper .overlap-group-22 {
  width: 156px;
  height: 62px;
  position: relative;
}

.HOME-wrapper .text-wrapper-663 {
  color: var(--white);
  letter-spacing: 2.4px;
  text-align: center;
  font-family: Zen Kaku Gothic New, Helvetica;
  font-size: 24px;
  font-weight: 500;
  line-height: normal;
  position: absolute;
  top: 0;
  left: 0;
}

.HOME-wrapper .text-wrapper-664 {
  color: var(--white);
  letter-spacing: 0;
  text-align: center;
  font-family: Slackside One, Helvetica;
  font-size: 23px;
  font-weight: 400;
  line-height: normal;
  position: absolute;
  top: 29px;
  left: 31px;
}

.HOME-wrapper .group-106 {
  width: 254px;
  height: 55px;
  margin-right: -2px;
  position: relative;
}

.HOME-wrapper .flexcontainer-78 {
  flex-direction: column;
  align-items: flex-start;
  gap: 5px;
  width: 252px;
  height: 55px;
  display: flex;
  position: relative;
}

.HOME-wrapper .text-55 {
  color: var(--white);
  letter-spacing: .75px;
  align-self: stretch;
  font-family: Noto Sans JP, Helvetica;
  font-size: 15px;
  font-weight: 400;
  line-height: 25px;
  position: relative;
}

.HOME-wrapper .footer-27 {
  background-color: #454545;
  flex-direction: column;
  flex: none;
  align-self: stretch;
  align-items: center;
  gap: 50px;
  width: 100%;
  padding: 100px 15px 40px;
  display: flex;
  position: relative;
}

.HOME-wrapper .frame-516 {
  flex-wrap: wrap;
  flex: none;
  justify-content: space-between;
  align-self: stretch;
  align-items: flex-start;
  gap: 150px 50px;
  width: 100%;
  display: flex;
  position: relative;
}

.HOME-wrapper .frame-517 {
  flex-direction: column;
  flex: 1;
  align-items: flex-start;
  gap: 44px;
  display: flex;
  position: relative;
}

.HOME-wrapper .logo-white-36 {
  left: unset !important;
  top: unset !important;
  width: 163.65px !important;
  height: 54.26px !important;
  position: relative !important;
}

.HOME-wrapper .text-56 {
  color: #fff;
  font-family: var(--text-font-family);
  font-size: var(--text-font-size);
  font-style: var(--text-font-style);
  font-weight: var(--text-font-weight);
  letter-spacing: var(--text-letter-spacing);
  line-height: var(--text-line-height);
  align-self: stretch;
  position: relative;
}

.HOME-wrapper .frame-518 {
  flex: none;
  align-items: flex-start;
  gap: 100px;
  display: inline-flex;
  position: relative;
}

.HOME-wrapper .frame-519 {
  flex-direction: column;
  flex: none;
  align-items: flex-start;
  gap: 30px;
  display: inline-flex;
  position: relative;
}

.HOME-wrapper .text-wrapper-665 {
  color: #fff;
  font-family: var(--text-bold-font-family);
  font-size: var(--text-bold-font-size);
  font-style: var(--text-bold-font-style);
  font-weight: var(--text-bold-font-weight);
  letter-spacing: var(--text-bold-letter-spacing);
  line-height: var(--text-bold-line-height);
  width: fit-content;
  margin-top: -1px;
  position: relative;
}

.HOME-wrapper .text-wrapper-666 {
  color: #fff;
  font-family: var(--text-bold-font-family);
  font-size: var(--text-bold-font-size);
  font-style: var(--text-bold-font-style);
  font-weight: var(--text-bold-font-weight);
  letter-spacing: var(--text-bold-letter-spacing);
  line-height: var(--text-bold-line-height);
  width: fit-content;
  position: relative;
}

.HOME-wrapper .text-wrapper-667 {
  color: #fff;
  font-family: var(--text-bold-font-family);
  font-size: var(--text-bold-font-size);
  font-style: var(--text-bold-font-style);
  font-weight: var(--text-bold-font-weight);
  letter-spacing: var(--text-bold-letter-spacing);
  line-height: var(--text-bold-line-height);
  white-space: nowrap;
  width: fit-content;
  margin-top: -1px;
  position: relative;
}

.HOME-wrapper .text-wrapper-668 {
  color: #fff;
  font-family: var(--text-bold-font-family);
  font-size: var(--text-bold-font-size);
  font-style: var(--text-bold-font-style);
  font-weight: var(--text-bold-font-weight);
  letter-spacing: var(--text-bold-letter-spacing);
  line-height: var(--text-bold-line-height);
  white-space: nowrap;
  width: fit-content;
  position: relative;
}

.HOME-wrapper .group-107 {
  width: 154px;
  height: 18px;
  position: relative;
}

.HOME-wrapper .text-wrapper-669 {
  color: #fff;
  letter-spacing: 1.31px;
  font-family: Manrope, Helvetica;
  font-size: 13.1px;
  font-weight: 400;
  line-height: normal;
  position: absolute;
  top: 0;
  left: 0;
}
/*# sourceMappingURL=index.e8eb8bf0.css.map */
