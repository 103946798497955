.div-wrapper {
  background-color: var(--red);
  border-radius: 6px;
  height: 20px;
  position: relative;
  width: 40px;
}

.div-wrapper .text-wrapper-4 {
  color: var(--white);
  font-family: var(--tag-font-family);
  font-size: var(--tag-font-size);
  font-style: var(--tag-font-style);
  font-weight: var(--tag-font-weight);
  left: 6px;
  letter-spacing: var(--tag-letter-spacing);
  line-height: var(--tag-line-height);
  position: absolute;
  text-align: center;
  top: 1px;
  white-space: nowrap;
}
