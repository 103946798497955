.menuopen-SP {
  align-items: center;
  display: flex;
  gap: 10px;
  height: 568px;
  min-width: 320px;
  overflow: hidden;
  position: relative;
  width: 100%;
}

.menuopen-SP .menu-SP-wrapper {
  align-items: center;
  background-color: #ffffff;
  display: flex;
  gap: 10px;
  height: 568px;
  width: 320px;
}

.menuopen-SP .menu-SP {
  align-items: flex-start;
  align-self: stretch;
  background-color: #6079c1;
  display: flex;
  flex: 1;
  flex-direction: column;
  flex-grow: 1;
  gap: 10px;
  position: relative;
}

.menuopen-SP .frame-141 {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex: 1;
  flex-direction: column;
  flex-grow: 1;
  gap: 20px;
  position: relative;
  width: 100%;
}

.menuopen-SP .frame-142 {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  position: relative;
  width: 100%;
}

.menuopen-SP .rectangle-3 {
  flex: 1;
  flex-grow: 1;
  height: 36px;
  position: relative;
}

.menuopen-SP .menu-6 {
  height: 35px !important;
  position: relative !important;
  width: 36px !important;
}

.menuopen-SP .menu-4 {
  align-items: flex-start;
  align-self: stretch;
  background-color: var(--purple);
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 10px;
  padding: 0px 25px;
  position: relative;
  width: 100%;
}

.menuopen-SP .frame-143 {
  align-items: flex-start;
  display: inline-flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 20px;
  margin-right: -12px;
  position: relative;
}

.menuopen-SP .text-wrapper-137 {
  color: #ffffff;
  display: block;
  font-family: "Noto Sans JP", Helvetica;
  font-size: 15px;
  font-weight: 700;
  letter-spacing: 0.75px;
  line-height: normal;
  margin-top: -1px;
  position: relative;
  width: fit-content;
}

.menuopen-SP .frame-144 {
  align-items: flex-start;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 25px;
  position: relative;
}

.menuopen-SP .frame-145 {
  align-items: flex-start;
  display: inline-flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 50px;
  position: relative;
}

.menuopen-SP .frame-146 {
  align-items: flex-start;
  display: inline-flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 30px;
  position: relative;
}

.menuopen-SP .frame-147 {
  align-items: flex-start;
  display: inline-flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 10px;
  position: relative;
}

.menuopen-SP .frame-148 {
  align-items: flex-start;
  display: inline-flex;
  flex: 0 0 auto;
  flex-direction: column;
  position: relative;
}

.menuopen-SP .text-wrapper-138 {
  color: #ffffff;
  font-family: "Noto Sans JP", Helvetica;
  font-size: 12px;
  font-weight: 700;
  letter-spacing: 0.6px;
  line-height: 24px;
  margin-top: -1px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.menuopen-SP .text-wrapper-139 {
  color: #ffffff;
  font-family: "Noto Sans JP", Helvetica;
  font-size: 12px;
  font-weight: 700;
  letter-spacing: 0.6px;
  line-height: 24px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.menuopen-SP .text-wrapper-140 {
  color: #ffffff;
  font-family: "Noto Sans JP", Helvetica;
  font-size: 15px;
  font-weight: 700;
  letter-spacing: 0.75px;
  line-height: normal;
  margin-top: -1px;
  position: relative;
  width: fit-content;
}

.menuopen-SP .frame-149 {
  align-items: flex-start;
  display: inline-flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 30px;
  height: 313px;
  position: relative;
}

.menuopen-SP .frame-150 {
  align-items: flex-start;
  display: inline-flex;
  flex: 1;
  flex-direction: column;
  flex-grow: 1;
  gap: 20px;
  position: relative;
}

.menuopen-SP .text-wrapper-141 {
  color: #ffffff;
  display: block;
  font-family: "Noto Sans JP", Helvetica;
  font-size: 13px;
  font-weight: 700;
  letter-spacing: 0.65px;
  line-height: normal;
  margin-top: -1px;
  position: relative;
  width: fit-content;
}

.menuopen-SP .text-wrapper-142 {
  color: #ffffff;
  display: block;
  font-family: "Noto Sans JP", Helvetica;
  font-size: 13px;
  font-weight: 700;
  letter-spacing: 0.65px;
  line-height: normal;
  position: relative;
  width: fit-content;
}

.menuopen-SP .text-wrapper-143 {
  color: #ffffff;
  font-family: "Noto Sans JP", Helvetica;
  font-size: 13px;
  font-weight: 700;
  letter-spacing: 0.65px;
  line-height: normal;
  position: relative;
  width: fit-content;
}

.menuopen-SP .button-wrapper {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 10px;
  padding: 0px 20px;
  position: relative;
  width: 100%;
}

.menuopen-SP .button-8 {
  align-self: stretch !important;
  width: 100% !important;
}
