.screen-wrapper {
  align-items: flex-start;
  background-color: #ffffff;
  display: flex;
  flex-direction: column;
  position: relative;
}

.screen-wrapper .headrer-4 {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  position: relative;
  width: 100%;
}

.screen-wrapper .logo-10 {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  justify-content: space-between;
  padding: 0px 0px 20px 30px;
  position: relative;
  width: 100%;
}

.screen-wrapper .frame-480 {
  height: 64px;
  position: relative;
  width: 164px;
}

.screen-wrapper .group-94 {
  background-image: url(../../../static/img/zsykr-0.png);
  background-size: 100% 100%;
  height: 49px;
  position: relative;
  top: 15px;
  width: 132px;
}

.screen-wrapper .component-13 {
  height: 52px !important;
  left: unset !important;
  position: relative !important;
  top: unset !important;
  width: 53px !important;
}

.screen-wrapper .midashi-19 {
  align-items: flex-start;
  align-self: stretch;
  background-color: #6079c1;
  display: flex;
  flex: 0 0 auto;
  gap: 10px;
  justify-content: center;
  padding: 20px 0px 30px;
  position: relative;
  width: 100%;
}

.screen-wrapper .component-14 {
  height: 89px;
  position: relative;
  width: 108px;
}

.screen-wrapper .text-wrapper-627 {
  color: #ffffff;
  font-family: "Zen Kaku Gothic New", Helvetica;
  font-size: 32px;
  font-weight: 500;
  left: -12px;
  letter-spacing: 1.6px;
  line-height: normal;
  position: absolute;
  text-align: center;
  top: 14px;
  white-space: nowrap;
}

.screen-wrapper .TEXTEXT-11 {
  color: #ffffff;
  font-family: "Slackside One", Helvetica;
  font-size: 23px;
  font-weight: 400;
  left: -11px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  text-align: center;
  top: 52px;
  white-space: nowrap;
}

.screen-wrapper .frame-481 {
  align-items: center;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  gap: 30px;
  padding: 20px 30px;
  position: relative;
  width: 100%;
}

.screen-wrapper .group-95 {
  height: 15px;
  position: relative;
  width: 150.57px;
}

.screen-wrapper .text-wrapper-628 {
  color: var(--gray);
  font-family: var(--tag-font-family);
  font-size: var(--tag-font-size);
  font-style: var(--tag-font-style);
  font-weight: var(--tag-font-weight);
  left: 0;
  letter-spacing: var(--tag-letter-spacing);
  line-height: var(--tag-line-height);
  position: absolute;
  top: 0;
  white-space: nowrap;
}

.screen-wrapper .component-10930-15 {
  left: 58px !important;
  top: 3px !important;
}

.screen-wrapper .text-wrapper-629 {
  color: var(--gray);
  font-family: var(--tag-font-family);
  font-size: var(--tag-font-size);
  font-style: var(--tag-font-style);
  font-weight: var(--tag-font-weight);
  left: 93px;
  letter-spacing: var(--tag-letter-spacing);
  line-height: var(--tag-line-height);
  position: absolute;
  top: 0;
  white-space: nowrap;
}

.screen-wrapper .view-10 {
  align-items: center;
  align-self: stretch;
  background-image: url(../../../static/img/image-2.png);
  background-position: 50% 50%;
  background-size: cover;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 40px;
  padding: 80px 15px;
  position: relative;
  width: 100%;
}

.screen-wrapper .view-11 {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  justify-content: center;
  position: relative;
  width: 100%;
}

.screen-wrapper .frame-482 {
  align-items: flex-start;
  background-color: #ffffff;
  display: flex;
  flex-direction: column;
  gap: 30px;
  opacity: 0.9;
  padding: 0px 20px;
  position: relative;
  width: 700px;
}

.screen-wrapper .frame-483 {
  align-items: center;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 50px;
  padding: 80px 0px;
  position: relative;
  width: 100%;
}

.screen-wrapper .component-15 {
  height: 89px;
  position: relative;
  width: 302px;
}

.screen-wrapper .text-wrapper-630 {
  color: #272727;
  font-family: "Zen Kaku Gothic New", Helvetica;
  font-size: 32px;
  font-weight: 500;
  left: 85px;
  letter-spacing: 1.6px;
  line-height: normal;
  position: absolute;
  text-align: center;
  top: 14px;
  white-space: nowrap;
}

.screen-wrapper .TEXTEXT-12 {
  color: var(--purple);
  font-family: "Slackside One", Helvetica;
  font-size: 23px;
  font-weight: 400;
  left: 24px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  text-align: center;
  top: 58px;
  white-space: nowrap;
}

.screen-wrapper .flexcontainer-73 {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  gap: 2px;
}

.screen-wrapper .text-49 {
  align-self: stretch;
  color: var(--darkgray);
  font-family: "Noto Sans JP", Helvetica;
  font-size: 20px;
  font-weight: 500;
  letter-spacing: 1px;
  line-height: 37px;
  position: relative;
  text-align: center;
}

.screen-wrapper .text-wrapper-631 {
  letter-spacing: 0.2px;
}

.screen-wrapper .flexcontainer-74 {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.screen-wrapper .text-50 {
  align-self: stretch;
  color: var(--darkgray);
  font-family: "Noto Sans JP", Helvetica;
  font-size: 15px;
  font-weight: 400;
  letter-spacing: 0.75px;
  line-height: 25px;
  position: relative;
  text-align: center;
}

.screen-wrapper .text-wrapper-632 {
  letter-spacing: 0.11px;
}

.screen-wrapper .view-12 {
  align-items: center;
  align-self: stretch;
  background-color: var(--palegray);
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 40px;
  padding: 80px 15px;
  position: relative;
  width: 100%;
}

.screen-wrapper .component-16 {
  height: 89px;
  position: relative;
  width: 324px;
}

.screen-wrapper .text-wrapper-633 {
  color: #272727;
  font-family: "Zen Kaku Gothic New", Helvetica;
  font-size: 32px;
  font-weight: 500;
  left: 96px;
  letter-spacing: 1.6px;
  line-height: normal;
  position: absolute;
  text-align: center;
  top: 14px;
  white-space: nowrap;
}

.screen-wrapper .TEXTEXT-13 {
  color: #6079c1;
  font-family: "Slackside One", Helvetica;
  font-size: 23px;
  font-weight: 400;
  left: 19px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  text-align: center;
  top: 58px;
  white-space: nowrap;
}

.screen-wrapper .text-wrapper-634 {
  align-self: stretch;
  color: #454545;
  font-family: "Noto Sans JP", Helvetica;
  font-size: 25px;
  font-weight: 700;
  letter-spacing: 1.25px;
  line-height: normal;
  position: relative;
  text-align: center;
}

.screen-wrapper .text-51 {
  align-self: stretch;
  color: #454545;
  font-family: "Noto Sans JP", Helvetica;
  font-size: 15px;
  font-weight: 400;
  letter-spacing: 0.75px;
  line-height: 25px;
  position: relative;
  text-align: center;
}

.screen-wrapper .ill-5 {
  height: 254px;
  object-fit: cover;
  position: relative;
  width: 428px;
}

.screen-wrapper .view-13 {
  align-items: center;
  align-self: stretch;
  background-color: var(--backwhite);
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 40px;
  padding: 50px 30px;
  position: relative;
  width: 100%;
}

.screen-wrapper .component-17 {
  height: 103px;
  position: relative;
  width: 184px;
}

.screen-wrapper .text-wrapper-635 {
  color: #272727;
  font-family: "Zen Kaku Gothic New", Helvetica;
  font-size: 32px;
  font-weight: 500;
  left: 59px;
  letter-spacing: 1.6px;
  line-height: normal;
  position: absolute;
  text-align: center;
  top: 14px;
  white-space: nowrap;
}

.screen-wrapper .TEXTEXT-14 {
  color: #6079c1;
  font-family: "Slackside One", Helvetica;
  font-size: 23px;
  font-weight: 400;
  left: 54px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  text-align: center;
  top: 67px;
  white-space: nowrap;
}

.screen-wrapper .frame-484 {
  align-items: flex-start;
  display: flex;
  flex: 1;
  flex-direction: column;
  flex-grow: 1;
  gap: 40px;
  position: relative;
}

.screen-wrapper .frame-485 {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 15px;
  position: relative;
  width: 100%;
}

.screen-wrapper .text-wrapper-636 {
  align-self: stretch;
  color: var(--darkgray);
  font-family: "Noto Sans JP", Helvetica;
  font-size: 20px;
  font-weight: 700;
  letter-spacing: 1px;
  line-height: 37px;
  margin-top: -1px;
  position: relative;
}

.screen-wrapper .line-26 {
  width: 90px !important;
}

.screen-wrapper .text-wrapper-637 {
  align-self: stretch;
  color: var(--darkgray);
  font-family: "Noto Sans JP", Helvetica;
  font-size: 15px;
  font-weight: 400;
  letter-spacing: 0.75px;
  line-height: 25px;
  position: relative;
}

.screen-wrapper .frame-486 {
  align-items: center;
  align-self: stretch;
  background-color: var(--backwhite);
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 40px;
  padding: 100px 15px;
  position: relative;
  width: 100%;
}

.screen-wrapper .group-96 {
  height: 118px;
  position: relative;
  width: 562px;
}

.screen-wrapper .frame-487 {
  align-items: center;
  background-color: var(--purple);
  border-radius: 6px;
  display: inline-flex;
  flex-wrap: wrap;
  gap: 40px 20px;
  justify-content: center;
  max-width: 700px;
  min-width: 280px;
  padding: 30px 70px;
  position: relative;
}

.screen-wrapper .group-97 {
  height: 58px;
  position: relative;
  width: 134px;
}

.screen-wrapper .text-wrapper-638 {
  color: var(--white);
  font-family: "Zen Kaku Gothic New", Helvetica;
  font-size: 20px;
  font-weight: 500;
  left: 0;
  letter-spacing: 2px;
  line-height: normal;
  position: absolute;
  text-align: center;
  top: 0;
}

.screen-wrapper .text-wrapper-639 {
  color: var(--white);
  font-family: "Slackside One", Helvetica;
  font-size: 20px;
  font-weight: 400;
  left: 24px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  text-align: center;
  top: 29px;
}

.screen-wrapper .group-98 {
  height: 55px;
  margin-right: -2px;
  position: relative;
  width: 254px;
}

.screen-wrapper .flexcontainer-75 {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  gap: 5px;
  height: 55px;
  position: relative;
  width: 252px;
}

.screen-wrapper .text-52 {
  align-self: stretch;
  color: var(--white);
  font-family: "Noto Sans JP", Helvetica;
  font-size: 15px;
  font-weight: 400;
  letter-spacing: 0.75px;
  line-height: 25px;
  position: relative;
}

.screen-wrapper .footer-26 {
  align-items: center;
  align-self: stretch;
  background-color: #454545;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 50px;
  padding: 100px 30px 40px;
  position: relative;
  width: 100%;
}

.screen-wrapper .frame-488 {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  flex-wrap: wrap;
  gap: 150px 50px;
  justify-content: space-between;
  position: relative;
  width: 100%;
}

.screen-wrapper .frame-489 {
  align-items: flex-start;
  display: flex;
  flex: 1;
  flex-direction: column;
  flex-grow: 1;
  gap: 44px;
  position: relative;
}

.screen-wrapper .logo-white-35 {
  height: 54.26px !important;
  left: unset !important;
  position: relative !important;
  top: unset !important;
  width: 163.65px !important;
}

.screen-wrapper .text-i-66 {
  align-self: stretch;
  color: #ffffff;
  font-family: var(--text-font-family);
  font-size: var(--text-font-size);
  font-style: var(--text-font-style);
  font-weight: var(--text-font-weight);
  letter-spacing: var(--text-letter-spacing);
  line-height: var(--text-line-height);
  position: relative;
}

.screen-wrapper .text-wrapper-640 {
  font-family: var(--text-font-family);
  font-size: var(--text-font-size);
  font-style: var(--text-font-style);
  font-weight: var(--text-font-weight);
  letter-spacing: var(--text-letter-spacing);
  line-height: var(--text-line-height);
}

.screen-wrapper .frame-490 {
  align-items: flex-start;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 100px;
  position: relative;
}

.screen-wrapper .frame-491 {
  align-items: flex-start;
  display: inline-flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 30px;
  position: relative;
}

.screen-wrapper .text-wrapper-641 {
  color: #ffffff;
  font-family: var(--text-bold-font-family);
  font-size: var(--text-bold-font-size);
  font-style: var(--text-bold-font-style);
  font-weight: var(--text-bold-font-weight);
  letter-spacing: var(--text-bold-letter-spacing);
  line-height: var(--text-bold-line-height);
  margin-top: -1px;
  position: relative;
  width: fit-content;
}

.screen-wrapper .text-wrapper-642 {
  color: #ffffff;
  font-family: var(--text-bold-font-family);
  font-size: var(--text-bold-font-size);
  font-style: var(--text-bold-font-style);
  font-weight: var(--text-bold-font-weight);
  letter-spacing: var(--text-bold-letter-spacing);
  line-height: var(--text-bold-line-height);
  position: relative;
  width: fit-content;
}

.screen-wrapper .text-wrapper-643 {
  color: #ffffff;
  font-family: var(--text-bold-font-family);
  font-size: var(--text-bold-font-size);
  font-style: var(--text-bold-font-style);
  font-weight: var(--text-bold-font-weight);
  letter-spacing: var(--text-bold-letter-spacing);
  line-height: var(--text-bold-line-height);
  margin-top: -1px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.screen-wrapper .text-wrapper-644 {
  color: #ffffff;
  font-family: var(--text-bold-font-family);
  font-size: var(--text-bold-font-size);
  font-style: var(--text-bold-font-style);
  font-weight: var(--text-bold-font-weight);
  letter-spacing: var(--text-bold-letter-spacing);
  line-height: var(--text-bold-line-height);
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.screen-wrapper .group-99 {
  height: 18px;
  position: relative;
  width: 154px;
}

.screen-wrapper .text-wrapper-645 {
  color: #ffffff;
  font-family: "Manrope", Helvetica;
  font-size: 13.1px;
  font-weight: 400;
  left: 0;
  letter-spacing: 1.31px;
  line-height: normal;
  position: absolute;
  top: 0;
}
