.SP-white {
  align-items: flex-start;
  background-color: var(--backwhite);
  display: flex;
  height: 45px;
  justify-content: space-between;
  padding: 0px 0px 20px 10px;
  position: relative;
  width: 125px;
}

.SP-white .group {
  height: 35px;
  margin-bottom: -10px;
  position: relative;
  width: 79.18px;
}

.SP-white .logo-instance {
  height: 27px !important;
  position: relative !important;
  top: 8px !important;
  width: 72px !important;
}

.SP-white .menu-10 {
  height: 35px !important;
  margin-bottom: -10px !important;
  position: relative !important;
  width: 36px !important;
}
