.process {
  display: inline-flex;
  position: relative;
}

.process .image-wrapper {
  align-items: center;
  background-color: var(--white);
  border-radius: 10px;
  gap: 10px;
  overflow: hidden;
  padding: 17px 0px;
  position: relative;
}

.process .image {
  object-fit: cover;
  position: relative;
}

.process .frame-14 {
  display: inline-flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 10px;
  position: relative;
}

.process .text-wrapper-9 {
  color: var(--purple);
  font-family: "Manrope", Helvetica;
  font-weight: 800;
  line-height: normal;
  margin-top: -1px;
  position: relative;
  text-align: center;
  width: fit-content;
}

.process .text-wrapper-10 {
  color: #272727;
  font-family: "Noto Sans JP", Helvetica;
  font-weight: 700;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.process.one-2-two {
  align-items: flex-start;
  gap: 20px;
  padding: 10px 5px;
}

.process.one-2-tab {
  align-items: center;
  flex-direction: column;
  gap: 10px;
  padding: 10px 5px;
}

.process.one-2-one {
  align-items: center;
  flex-direction: column;
  gap: 20px;
  max-width: 280px;
  padding: 10px 15px;
}

.process.one-2-two .image-wrapper {
  display: flex;
  height: 100px;
  justify-content: center;
  width: 100px;
}

.process.one-2-tab .image-wrapper {
  display: flex;
  height: 150px;
  justify-content: center;
  width: 150px;
}

.process.one-2-one .image-wrapper {
  display: inline-flex;
  flex: 0 0 auto;
  flex-direction: column;
}

.process.one-2-two .image {
  height: 91px;
  margin-bottom: -12.5px;
  margin-left: -6px;
  margin-top: -12.5px;
  width: 112px;
}

.process.one-2-tab .image {
  height: 130px;
  margin-bottom: -7px;
  margin-left: -5px;
  margin-top: -7px;
  width: 160px;
}

.process.one-2-one .image {
  height: 145px;
  width: 179px;
}

.process.one-2-two .frame-14 {
  align-items: flex-start;
  padding: 10px 0px;
}

.process.one-2-tab .frame-14 {
  align-items: center;
  padding: 10px 0px;
}

.process.one-2-one .frame-14 {
  align-items: center;
  padding: 0px 16px;
}

.process.one-2-two .text-wrapper-9 {
  font-size: 13px;
  letter-spacing: 1.3px;
}

.process.one-2-tab .text-wrapper-9 {
  font-size: 13px;
  letter-spacing: 1.3px;
}

.process.one-2-one .text-wrapper-9 {
  font-size: 15.1px;
  letter-spacing: 1.51px;
}

.process.one-2-two .text-wrapper-10 {
  font-size: 20px;
  letter-spacing: 1px;
  line-height: 30px;
}

.process.one-2-tab .text-wrapper-10 {
  font-size: 18px;
  letter-spacing: 0.9px;
  line-height: 20px;
  text-align: center;
}

.process.one-2-one .text-wrapper-10 {
  font-size: 20px;
  letter-spacing: 1px;
  line-height: 30px;
  text-align: center;
}
