.blogs-screen {
  align-items: center;
  display: flex;
  flex-direction: column;
  position: relative;
}

.blogs-screen .header-46 {
  align-self: stretch !important;
  display: flex !important;
  flex: 0 0 auto !important;
  justify-content: space-between !important;
  width: 100% !important;
  z-index: 1 !important;
}

.blogs-screen .header-47 {
  height: 54px !important;
  position: relative !important;
  top: 16px !important;
  width: 145px !important;
}

.blogs-screen .blogs-2 {
  align-items: flex-start;
  align-self: stretch;
  background-color: #ffffff;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  position: relative;
  width: 100%;
  z-index: 0;
}

.blogs-screen .midashi-14 {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  position: relative;
  width: 100%;
}

.blogs-screen .heading-63 {
  align-self: stretch !important;
  background-image: url(../../../static/img/midashi-1.png) !important;
  flex: 0 0 auto !important;
  width: 100% !important;
}

.blogs-screen .heading-64 {
  color: #ffffff !important;
  left: 28px !important;
}

.blogs-screen .heading-65 {
  color: #ffffff !important;
}

.blogs-screen .breadcrumbs-15 {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  gap: 30px;
  justify-content: center;
  padding: 40px 15px;
  position: relative;
  width: 100%;
}

.blogs-screen .frame-424 {
  align-items: center;
  display: flex;
  flex: 1;
  flex-grow: 1;
  gap: 30px;
  position: relative;
}

.blogs-screen .text-wrapper-537 {
  color: var(--gray);
  display: block;
  font-family: var(--tag-font-family);
  font-size: var(--tag-font-size);
  font-style: var(--tag-font-style);
  font-weight: var(--tag-font-weight);
  letter-spacing: var(--tag-letter-spacing);
  line-height: var(--tag-line-height);
  margin-top: -1px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.blogs-screen .component-10930-10 {
  height: 8.14px !important;
  left: unset !important;
  position: relative !important;
  top: unset !important;
  width: 4.57px !important;
}

.blogs-screen .text-wrapper-538 {
  color: var(--gray);
  font-family: var(--tag-font-family);
  font-size: var(--tag-font-size);
  font-style: var(--tag-font-style);
  font-weight: var(--tag-font-weight);
  letter-spacing: var(--tag-letter-spacing);
  line-height: var(--tag-line-height);
  margin-top: -1px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.blogs-screen .blogs-3 {
  align-items: center;
  align-self: stretch;
  background-color: var(--palepurple);
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 40px;
  padding: 80px 15px;
  position: relative;
  width: 100%;
}

.blogs-screen .frame-425 {
  align-items: center;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 40px;
  position: relative;
  width: 100%;
}

.blogs-screen .frame-426 {
  align-items: flex-start;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 20px;
  position: relative;
}

.blogs-screen .group-76 {
  height: 19px;
  position: relative;
  width: 56px;
}

.blogs-screen .text-wrapper-539 {
  color: var(--purple);
  font-family: var(--tag-font-family);
  font-size: var(--tag-font-size);
  font-style: var(--tag-font-style);
  font-weight: var(--tag-font-weight);
  left: 0;
  letter-spacing: var(--tag-letter-spacing);
  line-height: var(--tag-line-height);
  position: absolute;
  top: 0;
  white-space: nowrap;
}

.blogs-screen .line-23 {
  height: 3px;
  left: 0;
  position: absolute;
  top: 18px;
  width: 52px;
}

.blogs-screen .text-wrapper-540 {
  color: var(--purple);
  font-family: var(--tag-font-family);
  font-size: var(--tag-font-size);
  font-style: var(--tag-font-style);
  font-weight: var(--tag-font-weight);
  letter-spacing: var(--tag-letter-spacing);
  line-height: var(--tag-line-height);
  margin-top: -1px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.blogs-screen .frame-427 {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  flex-wrap: wrap;
  gap: 30px 10px;
  justify-content: center;
  position: relative;
  width: 100%;
}

.blogs-screen .frame-428 {
  align-items: flex-start;
  background-color: #ffffff;
  border-radius: 10px;
  display: flex;
  flex: 1;
  flex-grow: 1;
  gap: 14px;
  max-width: 360px;
  min-width: 290px;
  position: relative;
  transition: all 0.3s ease;
}

.blogs-screen .frame-429 {
  align-items: flex-start;
  background-color: #ffffff;
  border: 1px solid;
  border-color: #efeeee;
  border-radius: 10px;
  display: flex;
  flex: 1;
  flex-direction: column;
  flex-grow: 1;
  gap: 8px;
  height: 395px;
  padding: 12px 12px 20px;
  position: relative;
}

.blogs-screen .frame-430 {
  align-items: center;
  align-self: stretch;
  background-color: #959595;
  border-radius: 6px;
  display: flex;
  flex: 1;
  flex-grow: 1;
  gap: 10px;
  justify-content: center;
  max-height: 220px;
  min-height: 200px;
  position: relative;
  width: 100%;
}

.blogs-screen .noimage-14 {
  height: 25.92px !important;
  position: relative !important;
  width: 190.9px !important;
}

.blogs-screen .text-wrapper-541 {
  color: #8a8a8a;
  font-family: "Manrope", Helvetica;
  font-size: 12px;
  font-weight: 400;
  letter-spacing: 1.2px;
  line-height: normal;
  position: relative;
  width: fit-content;
}

.blogs-screen .text-wrapper-542 {
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  align-self: stretch;
  color: #454545;
  display: -webkit-box;
  font-family: "Noto Sans JP", Helvetica;
  font-size: 15px;
  font-weight: 700;
  letter-spacing: 0.75px;
  line-height: 26px;
  overflow: hidden;
  position: relative;
  text-overflow: ellipsis;
}

.blogs-screen .vector-5 {
  align-self: stretch;
  height: 1px;
  object-fit: cover;
  position: relative;
  width: 100%;
}

.blogs-screen .text-wrapper-543 {
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  align-self: stretch;
  color: var(--purple);
  display: -webkit-box;
  font-family: "Noto Sans JP", Helvetica;
  font-size: 13px;
  font-weight: 500;
  letter-spacing: 0.65px;
  line-height: 18.8px;
  overflow: hidden;
  position: relative;
  text-overflow: ellipsis;
}

.blogs-screen .frame-431 {
  height: 16px;
  position: relative;
  width: 206.48px;
}

.blogs-screen .text-wrapper-544 {
  color: #454545;
  font-family: var(--tag-font-family);
  font-size: var(--tag-font-size);
  font-style: var(--tag-font-style);
  font-weight: var(--tag-font-weight);
  left: 29px;
  letter-spacing: var(--tag-letter-spacing);
  line-height: var(--tag-line-height);
  position: absolute;
  top: -1px;
  white-space: nowrap;
}

.blogs-screen .text-wrapper-545 {
  color: #454545;
  font-family: var(--tag-font-family);
  font-size: var(--tag-font-size);
  font-style: var(--tag-font-style);
  font-weight: var(--tag-font-weight);
  left: 57px;
  letter-spacing: var(--tag-letter-spacing);
  line-height: var(--tag-line-height);
  position: absolute;
  top: -1px;
  white-space: nowrap;
}

.blogs-screen .text-wrapper-546 {
  color: #454545;
  font-family: var(--tag-font-family);
  font-size: var(--tag-font-size);
  font-style: var(--tag-font-style);
  font-weight: var(--tag-font-weight);
  left: 85px;
  letter-spacing: var(--tag-letter-spacing);
  line-height: var(--tag-line-height);
  position: absolute;
  top: -1px;
  white-space: nowrap;
}

.blogs-screen .text-wrapper-547 {
  color: #454545;
  font-family: var(--tag-font-family);
  font-size: var(--tag-font-size);
  font-style: var(--tag-font-style);
  font-weight: var(--tag-font-weight);
  left: 113px;
  letter-spacing: var(--tag-letter-spacing);
  line-height: var(--tag-line-height);
  position: absolute;
  top: -1px;
  white-space: nowrap;
}

.blogs-screen .text-wrapper-548 {
  color: #454545;
  font-family: var(--tag-font-family);
  font-size: var(--tag-font-size);
  font-style: var(--tag-font-style);
  font-weight: var(--tag-font-weight);
  left: 141px;
  letter-spacing: var(--tag-letter-spacing);
  line-height: var(--tag-line-height);
  position: absolute;
  top: -1px;
  white-space: nowrap;
}

.blogs-screen .text-wrapper-549 {
  color: #454545;
  font-family: var(--tag-font-family);
  font-size: var(--tag-font-size);
  font-style: var(--tag-font-style);
  font-weight: var(--tag-font-weight);
  left: 169px;
  letter-spacing: var(--tag-letter-spacing);
  line-height: var(--tag-line-height);
  position: absolute;
  top: -1px;
  white-space: nowrap;
}

.blogs-screen .polygon-2-8-instance {
  height: 16px !important;
  left: 197px !important;
  position: absolute !important;
  top: 0 !important;
  width: 10px !important;
}

.blogs-screen .polygon-3-5-instance {
  height: 16px !important;
  left: 0 !important;
  position: absolute !important;
  top: 0 !important;
  width: 10px !important;
}

.blogs-screen .CONTACT-36 {
  align-items: center;
  align-self: stretch;
  background-color: var(--backwhite);
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 40px;
  padding: 100px 20px;
  position: relative;
  width: 100%;
}

.blogs-screen .CONTACT-37 {
  height: 160px;
  position: relative;
  width: 704px;
}

.blogs-screen .frame-432 {
  align-items: center;
  background-color: var(--purple);
  border-radius: 6px;
  display: flex;
  flex-wrap: wrap;
  gap: 20px 74px;
  justify-content: center;
  left: 2px;
  max-width: 700px;
  padding: 50px 60px;
  position: relative;
  width: 700px;
}

.blogs-screen .group-77 {
  height: 60px;
  position: relative;
  width: 216px;
}

.blogs-screen .overlap-group-19 {
  height: 60px;
  position: relative;
  width: 212px;
}

.blogs-screen .text-wrapper-550 {
  color: var(--white);
  font-family: "Zen Kaku Gothic New", Helvetica;
  font-size: 24px;
  font-weight: 500;
  left: 0;
  letter-spacing: 2.4px;
  line-height: normal;
  position: absolute;
  text-align: center;
  top: 0;
  width: 212px;
}

.blogs-screen .text-wrapper-551 {
  color: var(--white);
  font-family: "Slackside One", Helvetica;
  font-size: 20px;
  font-weight: 400;
  left: 39px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  text-align: center;
  top: 31px;
  width: 134px;
}

.blogs-screen .group-78 {
  height: 55px;
  position: relative;
  width: 254px;
}

.blogs-screen .flexcontainer-61 {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  gap: 5px;
  height: 55px;
  position: relative;
  width: 252px;
}

.blogs-screen .text-i-58 {
  align-self: stretch;
  color: var(--white);
  font-family: "Noto Sans JP", Helvetica;
  font-size: 15px;
  font-weight: 400;
  letter-spacing: 0.75px;
  line-height: 25px;
  position: relative;
}

.blogs-screen .text-wrapper-552 {
  letter-spacing: 0.11px;
}

.blogs-screen .footer-21 {
  align-items: center;
  align-self: stretch;
  background-color: #454545;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 50px;
  padding: 70px 20px 50px;
  position: relative;
  width: 100%;
}

.blogs-screen .frame-433 {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  flex-wrap: wrap;
  gap: 50px 150px;
  justify-content: space-between;
  position: relative;
  width: 100%;
}

.blogs-screen .frame-434 {
  align-items: flex-start;
  display: inline-flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 30px;
  position: relative;
}

.blogs-screen .logo-white-29 {
  height: 54.26px !important;
  left: unset !important;
  position: relative !important;
  top: unset !important;
  width: 163.65px !important;
}

.blogs-screen .flexcontainer-62 {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.blogs-screen .text-i-59 {
  align-self: stretch;
  color: #ffffff;
  font-family: var(--text-font-family);
  font-size: var(--text-font-size);
  font-style: var(--text-font-style);
  font-weight: var(--text-font-weight);
  letter-spacing: var(--text-letter-spacing);
  line-height: var(--text-line-height);
  position: relative;
}

.blogs-screen .text-wrapper-553 {
  font-family: var(--text-font-family);
  font-size: var(--text-font-size);
  font-style: var(--text-font-style);
  font-weight: var(--text-font-weight);
  letter-spacing: var(--text-letter-spacing);
  line-height: var(--text-line-height);
}

.blogs-screen .frame-435 {
  align-items: flex-start;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 60px;
  position: relative;
}

.blogs-screen .frame-436 {
  align-items: flex-start;
  display: inline-flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 6px;
  position: relative;
}

.blogs-screen .text-wrapper-554 {
  color: #ffffff;
  font-family: var(--text-bold-mini-font-family);
  font-size: var(--text-bold-mini-font-size);
  font-style: var(--text-bold-mini-font-style);
  font-weight: var(--text-bold-mini-font-weight);
  letter-spacing: var(--text-bold-mini-letter-spacing);
  line-height: var(--text-bold-mini-line-height);
  margin-top: -1px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.blogs-screen .text-wrapper-555 {
  color: #ffffff;
  font-family: var(--text-bold-mini-font-family);
  font-size: var(--text-bold-mini-font-size);
  font-style: var(--text-bold-mini-font-style);
  font-weight: var(--text-bold-mini-font-weight);
  letter-spacing: var(--text-bold-mini-letter-spacing);
  line-height: var(--text-bold-mini-line-height);
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.blogs-screen .text-wrapper-556 {
  color: #ffffff;
  display: block;
  font-family: var(--text-bold-mini-font-family);
  font-size: var(--text-bold-mini-font-size);
  font-style: var(--text-bold-mini-font-style);
  font-weight: var(--text-bold-mini-font-weight);
  letter-spacing: var(--text-bold-mini-letter-spacing);
  line-height: var(--text-bold-mini-line-height);
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.blogs-screen .text-wrapper-557 {
  color: #ffffff;
  font-family: "Manrope", Helvetica;
  font-size: 13.1px;
  font-weight: 400;
  letter-spacing: 1.31px;
  line-height: normal;
  position: relative;
  width: fit-content;
}
