.home {
  align-items: center;
  background-color: var(--backwhite);
  display: flex;
  flex-direction: column;
  position: relative;
}

.home .header-2 {
  z-index: 1 !important;
}

.home .SP-transparent-instance {
  height: 35px !important;
  margin-bottom: -10px !important;
  position: relative !important;
  width: 36px !important;
  z-index: 0 !important;
}

.home .header-3 {
  align-self: stretch !important;
  background-color: transparent !important;
  width: 100% !important;
  z-index: 1 !important;
}

.home .div-7 {
  align-items: center;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  margin-top: -90px;
  position: relative;
  width: 100%;
  z-index: 0;
}

.home .FV {
  align-items: center;
  align-self: stretch;
  display: flex;
  flex-direction: column;
  gap: 10px;
  position: relative;
  width: 100%;
}

.home .message-wrapper {
  background-size: 100% 100%;
  position: relative;
}

.home .message {
  position: absolute;
}

.home .LEAD {
  align-items: center;
  align-self: stretch;
  background-color: var(--backwhite);
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 30px;
  padding: 0px 20px 60px;
  position: relative;
  width: 100%;
}

.home .lead-ill-instance {
  background-image: url(../../../static/img/lead-ill-1.png) !important;
  background-position: unset !important;
  background-size: 100% 100% !important;
  height: 146px !important;
  width: 244px !important;
}

.home .lead-text {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 20px;
  position: relative;
  width: 100%;
}

.home .flexcontainer-4 {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  gap: 2px;
}

.home .text-2 {
  align-self: stretch;
  color: var(--darkgray);
  font-family: var(--bold-SP-font-family);
  font-size: var(--bold-SP-font-size);
  font-style: var(--bold-SP-font-style);
  font-weight: var(--bold-SP-font-weight);
  letter-spacing: var(--bold-SP-letter-spacing);
  line-height: var(--bold-SP-line-height);
  position: relative;
}

.home .text-wrapper-50 {
  font-family: var(--bold-SP-font-family);
  font-size: var(--bold-SP-font-size);
  font-style: var(--bold-SP-font-style);
  font-weight: var(--bold-SP-font-weight);
  letter-spacing: var(--bold-SP-letter-spacing);
  line-height: var(--bold-SP-line-height);
}

.home .text-3 {
  align-self: stretch;
  color: var(--darkgray);
  font-family: var(--bold-SP-font-family);
  font-size: var(--bold-SP-font-size);
  font-style: var(--bold-SP-font-style);
  font-weight: var(--bold-SP-font-weight);
  letter-spacing: var(--bold-SP-letter-spacing);
  line-height: var(--bold-SP-line-height);
  position: relative;
}

.home .text-wrapper-51 {
  align-self: stretch;
  color: var(--darkgray);
  font-family: var(--lead-SP-font-family);
  font-size: var(--lead-SP-font-size);
  font-style: var(--lead-SP-font-style);
  font-weight: var(--lead-SP-font-weight);
  letter-spacing: var(--lead-SP-letter-spacing);
  line-height: var(--lead-SP-line-height);
  position: relative;
}

.home .FLOW {
  align-items: center;
  align-self: stretch;
  background-image: url(../../../static/img/flow-2.png);
  background-position: 50% 50%;
  background-size: cover;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 20px;
  padding: 60px 20px;
  position: relative;
  width: 100%;
}

.home .heading-3 {
  left: -10px !important;
}

.home .heading-4 {
  position: relative !important;
}

.home .heading-5 {
  left: 40px !important;
}

.home .flexcontainer-5 {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  gap: 3px;
}

.home .text-4 {
  align-self: stretch;
  color: #454545;
  font-family: var(--lead-SP-font-family);
  font-size: var(--lead-SP-font-size);
  font-style: var(--lead-SP-font-style);
  font-weight: var(--lead-SP-font-weight);
  letter-spacing: var(--lead-SP-letter-spacing);
  line-height: var(--lead-SP-line-height);
  position: relative;
}

.home .text-wrapper-52 {
  font-family: var(--lead-SP-font-family);
  font-size: var(--lead-SP-font-size);
  font-style: var(--lead-SP-font-style);
  font-weight: var(--lead-SP-font-weight);
  letter-spacing: var(--lead-SP-letter-spacing);
  line-height: var(--lead-SP-line-height);
}

.home .processe {
  align-items: center;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  max-width: 1200px;
  position: relative;
  width: 280px;
}

.home .group-5 {
  height: 364px;
  position: relative;
  width: 280px;
}

.home .process-instance {
  display: flex !important;
  left: 0 !important;
  padding: 4px 5px !important;
  position: absolute !important;
  top: 0 !important;
  width: 280px !important;
}

.home .process-2 {
  display: flex !important;
  flex: 1 !important;
  flex-grow: 1 !important;
  gap: 5px !important;
}

.home .process-3 {
  font-family: var(--bold-SP-font-family) !important;
  font-size: var(--bold-SP-font-size) !important;
  font-style: var(--bold-SP-font-style) !important;
  font-weight: var(--bold-SP-font-weight) !important;
  letter-spacing: var(--bold-SP-letter-spacing) !important;
  line-height: var(--bold-SP-line-height) !important;
  white-space: unset !important;
}

.home .triangle02-18 {
  height: 20px !important;
  left: 130px !important;
  position: absolute !important;
  top: 108px !important;
  width: 20px !important;
}

.home .process-4 {
  display: flex !important;
  left: 0 !important;
  padding: 4px 5px !important;
  position: absolute !important;
  top: 128px !important;
  width: 280px !important;
}

.home .triangle-02 {
  height: 20px !important;
  left: 130px !important;
  position: absolute !important;
  top: 236px !important;
  width: 20px !important;
}

.home .process-5 {
  display: flex !important;
  left: 0 !important;
  padding: 4px 5px !important;
  position: absolute !important;
  top: 256px !important;
  width: 280px !important;
}

.home .group-6 {
  height: 256px;
  position: relative;
  width: 280px;
}

.home .triangle02-1 {
  height: 20px !important;
  left: 130px !important;
  position: absolute !important;
  top: 0 !important;
  width: 20px !important;
}

.home .process-6 {
  align-items: flex-start;
  display: flex;
  gap: 20px;
  left: 0;
  padding: 4px 5px;
  position: absolute;
  top: 20px;
  width: 280px;
}

.home .frame-60 {
  align-items: center;
  background-color: var(--white);
  border-radius: 10px;
  display: flex;
  gap: 10px;
  height: 100px;
  justify-content: center;
  overflow: hidden;
  padding: 17px 0px;
  position: relative;
  width: 100px;
}

.home .image-4 {
  height: 91px;
  margin-bottom: -12.5px;
  margin-left: -6px;
  margin-top: -12.5px;
  object-fit: cover;
  position: relative;
  width: 112px;
}

.home .frame-61 {
  align-items: flex-start;
  display: flex;
  flex: 1;
  flex-direction: column;
  flex-grow: 1;
  gap: 5px;
  padding: 10px 0px;
  position: relative;
}

.home .process-7 {
  color: var(--purple);
  font-family: "Manrope", Helvetica;
  font-size: 13px;
  font-weight: 800;
  letter-spacing: 1.3px;
  line-height: normal;
  margin-top: -1px;
  position: relative;
  text-align: center;
  width: fit-content;
}

.home .flexcontainer-6 {
  align-items: flex-start;
  display: inline-flex;
  flex-direction: column;
  gap: 2px;
}

.home .text-i-6 {
  align-self: stretch;
  color: #272727;
  font-family: var(--bold-SP-font-family);
  font-size: var(--bold-SP-font-size);
  font-style: var(--bold-SP-font-style);
  font-weight: var(--bold-SP-font-weight);
  letter-spacing: var(--bold-SP-letter-spacing);
  line-height: var(--bold-SP-line-height);
  position: relative;
}

.home .triangle02-17 {
  height: 20px !important;
  left: 130px !important;
  position: absolute !important;
  top: 128px !important;
  width: 20px !important;
}

.home .process-8 {
  display: flex !important;
  left: 0 !important;
  padding: 4px 5px !important;
  position: absolute !important;
  top: 148px !important;
  width: 280px !important;
}

.home .button-instance {
  max-width: 400px !important;
}

.home .button-2 {
  font-family: var(--button-SP-font-family) !important;
  font-size: var(--button-SP-font-size) !important;
  font-style: var(--button-SP-font-style) !important;
  font-weight: var(--button-SP-font-weight) !important;
  letter-spacing: var(--button-SP-letter-spacing) !important;
  line-height: var(--button-SP-line-height) !important;
}

.home .processe-2 {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  flex-wrap: wrap;
  gap: 0px 0px;
  justify-content: center;
  max-width: 1200px;
  position: relative;
  width: 100%;
}

.home .frame-62 {
  align-items: flex-start;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 5px;
  margin-left: -5px;
  margin-right: -5px;
  position: relative;
}

.home .process-9 {
  align-self: stretch !important;
  width: 100% !important;
}

.home .process-10 {
  margin-left: unset !important;
}

.home .instance-node-3 {
  flex: 0 0 auto !important;
}

.home .yajirushi {
  height: 170px;
  position: relative;
  width: 20px;
}

.home .triangle02-7 {
  height: 20px !important;
  left: 0 !important;
  position: absolute !important;
  top: 75px !important;
  width: 20px !important;
}

.home .process-11 {
  flex: 0 0 auto !important;
  height: 248px !important;
}

.home .process-12 {
  align-items: center;
  display: inline-flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 10px;
  padding: 10px 5px;
  position: relative;
}

.home .frame-63 {
  align-items: center;
  align-self: stretch;
  background-color: var(--white);
  border-radius: 10px;
  display: flex;
  gap: 10px;
  height: 150px;
  justify-content: center;
  overflow: hidden;
  padding: 17px 0px;
  position: relative;
  width: 100%;
}

.home .image-5 {
  height: 130px;
  margin-bottom: -7px;
  margin-top: -7px;
  object-fit: cover;
  position: relative;
  width: 160px;
}

.home .frame-64 {
  align-items: center;
  display: inline-flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 10px;
  padding: 10px 0px;
  position: relative;
}

.home .text-i-7 {
  align-self: stretch;
  color: #272727;
  font-family: "Noto Sans JP", Helvetica;
  font-size: 18px;
  font-weight: 700;
  letter-spacing: 0.9px;
  line-height: 20px;
  position: relative;
  text-align: center;
}

.home .text-wrapper-53 {
  letter-spacing: 0.16px;
}

.home .frame-65 {
  align-items: flex-start;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 5px;
  position: relative;
}

.home .BLOGS {
  align-items: center;
  align-self: stretch;
  background-color: var(--backwhite);
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 20px;
  padding: 40px 30px;
  position: relative;
  width: 100%;
}

.home .heading-6 {
  left: 42px !important;
}

.home .div-8 {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  flex-wrap: wrap;
  gap: 20px 20px;
  justify-content: center;
  position: relative;
  width: 100%;
}

.home .BLOG-instance {
  gap: 8px !important;
  height: unset !important;
  padding: 8px 8px 19px !important;
}

.home .frame-66 {
  flex: 0 0 auto !important;
  flex-grow: unset !important;
}

.home .frame-67 {
  font-family: var(--smalltext-SP-font-family) !important;
  font-size: var(--smalltext-SP-font-size) !important;
  font-style: var(--smalltext-SP-font-style) !important;
  font-weight: var(--smalltext-SP-font-weight) !important;
  letter-spacing: var(--smalltext-SP-letter-spacing) !important;
  line-height: var(--smalltext-SP-line-height) !important;
}

.home .class-40 {
  flex: 1 !important;
  flex-grow: 1 !important;
  margin-left: -20px !important;
  margin-right: -20px !important;
  max-width: 360px !important;
  min-width: 300px !important;
  width: unset !important;
}

.home .class-41 {
  width: 300px !important;
}

.home .frame-68 {
  font-family: var(--smalltextbold-SP-font-family) !important;
  font-size: var(--smalltextbold-SP-font-size) !important;
  font-style: var(--smalltextbold-SP-font-style) !important;
  font-weight: var(--smalltextbold-SP-font-weight) !important;
  letter-spacing: var(--smalltextbold-SP-letter-spacing) !important;
  line-height: var(--smalltextbold-SP-line-height) !important;
}

.home .RECRUITES {
  align-items: center;
  align-self: stretch;
  background-color: var(--palepurple);
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 20px;
  padding: 40px 20px;
  position: relative;
  width: 100%;
}

.home .heading-7 {
  left: 12px !important;
}

.home .heading-8 {
  left: 33px !important;
}

.home .class-42 {
  flex: 1 !important;
  flex-grow: 1 !important;
  height: unset !important;
  margin-left: -5px !important;
  margin-right: -5px !important;
  max-width: 360px !important;
  min-width: 290px !important;
  width: unset !important;
}

.home .class-43 {
  height: unset !important;
  max-width: 360px !important;
  width: 300px !important;
}

.home .RECRUITE-instance {
  display: inline-flex !important;
  flex: 0 0 auto !important;
  padding: 8px 30px !important;
  width: unset !important;
}

.home .frame-69 {
  font-family: var(--button-SP-font-family) !important;
  font-size: var(--button-SP-font-size) !important;
  font-style: var(--button-SP-font-style) !important;
  font-weight: var(--button-SP-font-weight) !important;
  letter-spacing: var(--button-SP-letter-spacing) !important;
  line-height: var(--button-SP-line-height) !important;
  white-space: unset !important;
}

.home .ABOUTUS {
  align-items: center;
  align-self: stretch;
  background-image: url(../../../static/img/aboutus-2.png);
  background-position: 50% 50%;
  background-size: cover;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 20px;
  padding: 40px 20px;
  position: relative;
  width: 100%;
}

.home .heading-9 {
  color: #ffffff !important;
  left: 12px !important;
}

.home .heading-10 {
  color: #ffffff !important;
}

.home .frame-70 {
  display: inline-flex;
  flex: 0 0 auto;
  gap: 20px;
  max-width: 400px;
  position: relative;
}

.home .button-3 {
  justify-content: center !important;
}

.home .CONTACT-wrapper {
  align-items: center;
  align-self: stretch;
  background-color: var(--backwhite);
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 40px;
  justify-content: center;
  position: relative;
  width: 100%;
}

.home .CONTACT-3 {
  display: block;
  height: 160px;
  position: relative;
}

.home .frame-71 {
  align-items: center;
  background-color: var(--purple);
  border-radius: 6px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  max-width: 700px;
  position: relative;
}

.home .group-7 {
  height: 53px;
  position: relative;
  width: 216px;
}

.home .text-wrapper-54 {
  color: var(--white);
  font-family: "Zen Kaku Gothic New", Helvetica;
  font-size: 20px;
  font-weight: 500;
  left: 0;
  letter-spacing: 2px;
  line-height: normal;
  position: absolute;
  text-align: center;
  top: 0;
  width: 212px;
}

.home .text-wrapper-55 {
  color: var(--white);
  font-family: "Slackside One", Helvetica;
  font-size: 15px;
  font-weight: 400;
  left: 39px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  text-align: center;
  top: 31px;
  width: 134px;
}

.home .flexcontainer-i-wrapper {
  height: 48px;
  position: relative;
  width: 212px;
}

.home .flexcontainer-7 {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  gap: 2px;
  height: 48px;
  position: relative;
  width: 210px;
}

.home .text-i-8 {
  align-self: stretch;
  color: var(--white);
  font-family: var(--text-SP-font-family);
  font-size: var(--text-SP-font-size);
  font-style: var(--text-SP-font-style);
  font-weight: var(--text-SP-font-weight);
  letter-spacing: var(--text-SP-letter-spacing);
  line-height: var(--text-SP-line-height);
  position: relative;
}

.home .text-wrapper-56 {
  font-family: var(--text-SP-font-family);
  font-size: var(--text-SP-font-size);
  font-style: var(--text-SP-font-style);
  font-weight: var(--text-SP-font-weight);
  letter-spacing: var(--text-SP-letter-spacing);
  line-height: var(--text-SP-line-height);
}

.home .footer-2 {
  align-items: center;
  align-self: stretch;
  background-color: #454545;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 50px;
  padding: 50px 20px;
  position: relative;
  width: 100%;
}

.home .frame-72 {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  position: relative;
  width: 100%;
}

.home .frame-73 {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  gap: 24px;
  position: relative;
}

.home .logo-white-26 {
  height: 40px !important;
  position: relative !important;
  width: 121px !important;
}

.home .text-i-9 {
  align-self: stretch;
  color: #ffffff;
  font-family: "Noto Sans JP", Helvetica;
  font-size: 13px;
  font-weight: 400;
  letter-spacing: 0.26px;
  line-height: 18px;
  position: relative;
}

.home .text-wrapper-57 {
  letter-spacing: 0.03px;
}

.home .frame-74 {
  align-items: flex-start;
  flex: 0 0 auto;
  position: relative;
}

.home .frame-75 {
  align-items: flex-start;
  display: inline-flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 30px;
  position: relative;
}

.home .frame-76 {
  align-items: flex-start;
  display: inline-flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 4px;
  position: relative;
}

.home .text-wrapper-58 {
  color: #ffffff;
  display: block;
  font-family: var(--text-bold-mini-font-family);
  font-size: var(--text-bold-mini-font-size);
  font-style: var(--text-bold-mini-font-style);
  font-weight: var(--text-bold-mini-font-weight);
  letter-spacing: var(--text-bold-mini-letter-spacing);
  line-height: var(--text-bold-mini-line-height);
  margin-top: -1px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.home .text-wrapper-59 {
  color: #ffffff;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.home .text-wrapper-60 {
  color: #ffffff;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.home .text-wrapper-61 {
  color: #ffffff;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.home .text-wrapper-62 {
  color: #ffffff;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.home .text-wrapper-63 {
  color: #ffffff;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.home .text-wrapper-64 {
  color: #ffffff;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.home .text-wrapper-65 {
  color: #ffffff;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.home .frame-77 {
  align-items: flex-start;
  align-self: stretch;
  flex-direction: column;
  gap: 30px;
  position: relative;
}

.home .text-wrapper-66 {
  color: #ffffff;
  display: block;
  font-family: var(--text-bold-mini-font-family);
  font-size: var(--text-bold-mini-font-size);
  font-style: var(--text-bold-mini-font-style);
  font-weight: var(--text-bold-mini-font-weight);
  letter-spacing: var(--text-bold-mini-letter-spacing);
  line-height: var(--text-bold-mini-line-height);
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.home .text-wrapper-67 {
  color: #ffffff;
  font-family: var(--text-bold-mini-font-family);
  font-size: var(--text-bold-mini-font-size);
  font-style: var(--text-bold-mini-font-style);
  font-weight: var(--text-bold-mini-font-weight);
  letter-spacing: var(--text-bold-mini-letter-spacing);
  line-height: var(--text-bold-mini-line-height);
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.home .text-wrapper-68 {
  color: #ffffff;
  font-family: "Manrope", Helvetica;
  font-size: 13.1px;
  font-weight: 400;
  letter-spacing: 1.31px;
  line-height: normal;
  position: relative;
  width: fit-content;
}

.home .color-transparent-instance {
  align-self: stretch !important;
  background-color: transparent !important;
  display: flex !important;
  flex: 0 0 auto !important;
  justify-content: space-between !important;
  width: 100% !important;
  z-index: 1 !important;
}

.home .header-4 {
  height: 59px !important;
  position: relative !important;
  width: 60px !important;
  z-index: 0 !important;
}

.home .div-9 {
  align-items: center;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  margin-bottom: -4630px;
  margin-top: -90px;
  position: relative;
  width: 100%;
  z-index: 0;
}

.home .frame-78 {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  position: relative;
  width: 100%;
  z-index: 6;
}

.home .FV-2 {
  align-items: center;
  align-self: stretch;
  display: flex;
  flex-direction: column;
  gap: 10px;
  height: 1194px;
  justify-content: flex-end;
  overflow: hidden;
  position: relative;
  width: 100%;
  z-index: 1;
}

.home .group-8 {
  background-image: url(../../../static/img/fv-2-2.png);
  background-position: 50% 50%;
  background-size: cover;
  height: 1329px;
  margin-left: -353.62px;
  margin-right: -353.62px;
  margin-top: -135px;
  position: relative;
  width: 1987.24px;
}

.home .message-2 {
  height: 306px;
  left: 904px;
  position: absolute;
  top: 406px;
  width: 169px;
}

.home .LEAD-2 {
  align-items: center;
  align-self: stretch;
  background-color: var(--backwhite);
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 50px;
  margin-top: -80px;
  padding: 0px 0px 100px;
  position: relative;
  width: 100%;
  z-index: 0;
}

.home .lead-text-2 {
  align-items: flex-start;
  display: flex;
  flex: 0 0 auto;
  gap: 60px;
  max-width: 1000px;
  position: relative;
  width: 1000px;
}

.home .flexcontainer-8 {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  gap: 2px;
  max-width: 450px;
  min-height: 140px;
  width: 350px;
}

.home .text-5 {
  align-self: stretch;
  color: var(--darkgray);
  font-family: "Noto Sans JP", Helvetica;
  font-size: 24px;
  font-weight: 700;
  letter-spacing: 1.44px;
  line-height: 43px;
  position: relative;
}

.home .text-wrapper-69 {
  letter-spacing: 0.35px;
}

.home .text-wrapper-70 {
  color: var(--darkgray);
  flex: 1;
  font-family: var(--lead-font-family);
  font-size: var(--lead-font-size);
  font-style: var(--lead-font-style);
  font-weight: var(--lead-font-weight);
  letter-spacing: var(--lead-letter-spacing);
  line-height: var(--lead-line-height);
  margin-top: -1px;
  position: relative;
}

.home .FLOW-2 {
  align-items: center;
  align-self: stretch;
  background-image: url(../../../static/img/flow-2.png);
  background-position: 50% 50%;
  background-size: cover;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 40px;
  padding: 100px 0px;
  position: relative;
  width: 100%;
  z-index: 5;
}

.home .heading-11 {
  left: -44px !important;
}

.home .heading-12 {
  left: 24px !important;
}

.home .flexcontainer-9 {
  align-items: flex-start;
  display: inline-flex;
  flex-direction: column;
  gap: 5px;
}

.home .text-6 {
  align-self: stretch;
  color: #454545;
  font-family: var(--lead-font-family);
  font-size: var(--lead-font-size);
  font-style: var(--lead-font-style);
  font-weight: var(--lead-font-weight);
  letter-spacing: var(--lead-letter-spacing);
  line-height: var(--lead-line-height);
  position: relative;
  text-align: center;
}

.home .text-wrapper-71 {
  font-family: var(--lead-font-family);
  font-size: var(--lead-font-size);
  font-style: var(--lead-font-style);
  font-weight: var(--lead-font-weight);
  letter-spacing: var(--lead-letter-spacing);
  line-height: var(--lead-line-height);
}

.home .processe-3 {
  align-items: flex-start;
  display: flex;
  height: 326px;
  justify-content: center;
  max-width: 1200px;
  position: relative;
  width: 1200px;
}

.home .frame-79 {
  align-items: flex-start;
  display: inline-flex;
  flex: 0 0 auto;
  position: relative;
}

.home .process-13 {
  flex: 0 0 auto !important;
  max-width: unset !important;
}

.home .process-14 {
  align-self: stretch !important;
  display: flex !important;
  width: 100% !important;
}

.home .triangle-wrapper {
  height: 189px;
  position: relative;
  width: 20px;
}

.home .triangle02-5 {
  height: 20px !important;
  left: 0 !important;
  position: absolute !important;
  top: 93px !important;
  width: 20px !important;
}

.home .process-15 {
  flex: 0 0 auto !important;
  height: 287px !important;
  max-width: unset !important;
}

.home .process-16 {
  align-items: center;
  display: inline-flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 20px;
  padding: 10px 15px;
  position: relative;
}

.home .frame-80 {
  align-items: center;
  align-self: stretch;
  background-color: var(--white);
  border-radius: 10px;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 10px;
  overflow: hidden;
  padding: 17px 0px;
  position: relative;
  width: 100%;
}

.home .image-6 {
  height: 145px;
  object-fit: cover;
  position: relative;
  width: 179px;
}

.home .frame-81 {
  align-items: center;
  display: inline-flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 10px;
  padding: 0px 16px;
  position: relative;
}

.home .process-17 {
  color: var(--purple);
  font-family: "Manrope", Helvetica;
  font-size: 15.1px;
  font-weight: 800;
  letter-spacing: 1.51px;
  line-height: normal;
  margin-top: -1px;
  position: relative;
  text-align: center;
  width: fit-content;
}

.home .text-i-10 {
  align-self: stretch;
  color: #272727;
  font-family: "Noto Sans JP", Helvetica;
  font-size: 20px;
  font-weight: 700;
  letter-spacing: 1px;
  line-height: 30px;
  position: relative;
  text-align: center;
}

.home .text-wrapper-72 {
  letter-spacing: 0.2px;
}

.home .button-4 {
  height: 11.13px !important;
  margin-left: -1.65px !important;
  margin-right: -1.15px !important;
  position: relative !important;
  width: 7.41px !important;
}

.home .BLOGS-2 {
  align-items: center;
  align-self: stretch;
  background-color: var(--backwhite);
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 40px;
  padding: 100px 0px;
  position: relative;
  width: 100%;
  z-index: 4;
}

.home .heading-13 {
  left: 28px !important;
}

.home .div-10 {
  align-items: flex-start;
  display: flex;
  flex: 0 0 auto;
  flex-wrap: wrap;
  gap: 30px 20px;
  justify-content: center;
  position: relative;
  width: 1200px;
}

.home .frame-82 {
  flex: 1 !important;
  flex-grow: 1 !important;
  max-width: 360px !important;
  min-width: 300px !important;
  width: unset !important;
}

.home .RECRUITES-2 {
  align-items: center;
  align-self: stretch;
  background-color: var(--palepurple);
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 40px;
  padding: 100px 0px;
  position: relative;
  width: 100%;
  z-index: 3;
}

.home .heading-14 {
  left: 10px !important;
}

.home .flexcontainer-10 {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.home .frame-83 {
  flex: 1 !important;
  flex-grow: 1 !important;
  height: 397px !important;
  max-width: 360px !important;
  min-width: 300px !important;
  width: unset !important;
}

.home .frame-84 {
  width: 120px !important;
}

.home .frame-85 {
  margin-left: -12px !important;
  margin-right: -12px !important;
}

.home .ABOUTUS-2 {
  align-items: center;
  align-self: stretch;
  background-image: url(../../../static/img/aboutus-2.png);
  background-position: 50% 50%;
  background-size: cover;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 60px;
  padding: 120px 0px;
  position: relative;
  width: 100%;
  z-index: 2;
}

.home .heading-15 {
  color: #ffffff !important;
  left: -10px !important;
}

.home .frame-86 {
  align-items: flex-start;
  display: flex;
  flex: 0 0 auto;
  gap: 40px;
  justify-content: center;
  position: relative;
  width: 700px;
}

.home .button-5 {
  align-items: flex-start !important;
  flex: 1 !important;
  flex-grow: 1 !important;
  width: unset !important;
}

.home .button-6 {
  height: 11.13px !important;
  margin-left: -1.65px !important;
  margin-right: -1.15px !important;
  margin-top: -0.57px !important;
  position: relative !important;
  width: 7.41px !important;
}

.home .button-7 {
  flex: 1 !important;
  flex-grow: 1 !important;
  width: unset !important;
}

.home .CONTACT-4 {
  align-items: center;
  align-self: stretch;
  background-color: var(--backwhite);
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 40px;
  padding: 100px 15px;
  position: relative;
  width: 100%;
  z-index: 1;
}

.home .CONTACT-5 {
  display: block;
  height: 160px;
  position: relative;
  width: 700px;
}

.home .frame-87 {
  align-items: center;
  background-color: var(--purple);
  border-radius: 6px;
  display: flex;
  flex-wrap: wrap;
  gap: 20px 74px;
  justify-content: center;
  max-width: 700px;
  padding: 50px 60px;
  position: relative;
  width: 700px;
}

.home .group-9 {
  height: 60px;
  position: relative;
  width: 216px;
}

.home .overlap-group-4 {
  height: 60px;
  position: relative;
  width: 212px;
}

.home .text-wrapper-73 {
  color: var(--white);
  font-family: "Zen Kaku Gothic New", Helvetica;
  font-size: 24px;
  font-weight: 500;
  left: 0;
  letter-spacing: 2.4px;
  line-height: normal;
  position: absolute;
  text-align: center;
  top: 0;
  width: 212px;
}

.home .text-wrapper-74 {
  color: var(--white);
  font-family: "Slackside One", Helvetica;
  font-size: 20px;
  font-weight: 400;
  left: 39px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  text-align: center;
  top: 31px;
  width: 134px;
}

.home .group-10 {
  height: 55px;
  position: relative;
  width: 254px;
}

.home .flexcontainer-11 {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  gap: 5px;
  height: 55px;
  position: relative;
  width: 252px;
}

.home .text-i-11 {
  align-self: stretch;
  color: var(--white);
  font-family: "Noto Sans JP", Helvetica;
  font-size: 15px;
  font-weight: 400;
  letter-spacing: 0.75px;
  line-height: 25px;
  position: relative;
}

.home .text-wrapper-75 {
  letter-spacing: 0.11px;
}

.home .footer-3 {
  align-items: center;
  align-self: stretch;
  background-color: #454545;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 50px;
  padding: 70px 40px 50px;
  position: relative;
  width: 100%;
  z-index: 0;
}

.home .frame-88 {
  align-items: flex-start;
  display: flex;
  flex: 0 0 auto;
  flex-wrap: wrap;
  gap: 50px 150px;
  justify-content: space-between;
  padding: 0px 50px;
  position: relative;
  width: 1200px;
}

.home .frame-89 {
  align-items: flex-start;
  display: flex;
  flex: 1;
  flex-direction: column;
  flex-grow: 1;
  gap: 44px;
  position: relative;
}

.home .logo-white-9 {
  height: 54.26px !important;
  position: relative !important;
  width: 163.65px !important;
}

.home .text-i-12 {
  align-self: stretch;
  color: #ffffff;
  font-family: var(--text-font-family);
  font-size: var(--text-font-size);
  font-style: var(--text-font-style);
  font-weight: var(--text-font-weight);
  letter-spacing: var(--text-letter-spacing);
  line-height: var(--text-line-height);
  position: relative;
}

.home .text-wrapper-76 {
  font-family: var(--text-font-family);
  font-size: var(--text-font-size);
  font-style: var(--text-font-style);
  font-weight: var(--text-font-weight);
  letter-spacing: var(--text-letter-spacing);
  line-height: var(--text-line-height);
}

.home .frame-90 {
  align-items: flex-start;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 60px;
  position: relative;
}

.home .frame-91 {
  align-items: flex-start;
  display: inline-flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 40px;
  padding: 0px 20px;
  position: relative;
}

.home .frame-92 {
  align-items: flex-start;
  display: inline-flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 6px;
  position: relative;
}

.home .text-wrapper-77 {
  color: #ffffff;
  font-family: "Noto Sans JP", Helvetica;
  font-size: 15px;
  font-weight: 700;
  letter-spacing: 0.75px;
  line-height: 20px;
  margin-top: -1px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.home .text-wrapper-78 {
  color: #ffffff;
  font-family: "Noto Sans JP", Helvetica;
  font-size: 13px;
  font-weight: 700;
  letter-spacing: 0.65px;
  line-height: 20px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.home .frame-93 {
  align-items: flex-start;
  display: inline-flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 30px;
  padding: 0px 20px;
  position: relative;
}

.home .text-wrapper-79 {
  color: #ffffff;
  font-family: "Noto Sans JP", Helvetica;
  font-size: 15px;
  font-weight: 700;
  letter-spacing: 0.75px;
  line-height: 20px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.home .text-wrapper-80 {
  color: #ffffff;
  display: block;
  font-family: "Noto Sans JP", Helvetica;
  font-size: 15px;
  font-weight: 700;
  letter-spacing: 0.75px;
  line-height: 20px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}
