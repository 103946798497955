.color-transparent {
  align-items: flex-start;
  display: inline-flex;
  padding: 0px 0px 20px 50px;
  position: relative;
}

.color-transparent .group-2 {
  height: 70px;
  position: relative;
  width: 158.36px;
}

.color-transparent .logo-2 {
  height: 54px !important;
  position: relative !important;
  top: 16px !important;
  width: 145px !important;
}
