.heading {
  height: 69px;
  width: 112px;
}

.heading .div {
  position: relative;
}

.heading .div-2 {
  line-height: normal;
  position: absolute;
  text-align: center;
  white-space: nowrap;
}

.heading .TEXTTEXT {
  line-height: normal;
  position: absolute;
  text-align: center;
  white-space: nowrap;
}

.heading .one {
  height: 67px;
  left: 2px;
}

.heading .SP {
  height: 59px;
  top: 10px;
  width: 117px;
}

.heading .div.one .div-2 {
  color: #272727;
  font-family: "Zen Kaku Gothic New", Helvetica;
  font-size: 32px;
  font-weight: 500;
  left: 6px;
  letter-spacing: 1.6px;
  top: 7px;
}

.heading .div.SP .div-2 {
  color: #6079c1;
  font-family: "Slackside One", Helvetica;
  font-size: 12px;
  font-weight: 400;
  left: 29px;
  letter-spacing: 0.6px;
  top: 34px;
}

.heading .div.one .TEXTTEXT {
  color: #6079c1;
  font-family: "Slackside One", Helvetica;
  font-size: 24px;
  font-weight: 400;
  left: 2px;
  letter-spacing: 1.2px;
  top: 51px;
}

.heading .div.SP .TEXTTEXT {
  color: #272727;
  font-family: "Zen Kaku Gothic New", Helvetica;
  font-size: 21px;
  font-weight: 500;
  left: 23px;
  letter-spacing: 1.05px;
  top: 7px;
}
