.recruit-screen {
  align-items: flex-start;
  background-color: #ffffff;
  display: flex;
  flex-direction: column;
  position: relative;
}

.recruit-screen .div-24 {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  position: relative;
  width: 100%;
}

.recruit-screen .logo-5 {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  justify-content: space-between;
  padding: 0px 0px 20px 30px;
  position: relative;
  width: 100%;
}

.recruit-screen .frame-327 {
  height: 64px;
  position: relative;
  width: 164px;
}

.recruit-screen .group-52 {
  background-image: url(../../../static/img/zsykr-0.png);
  background-size: 100% 100%;
  height: 49px;
  position: relative;
  top: 15px;
  width: 132px;
}

.recruit-screen .menu-14 {
  height: 52px;
  position: relative;
  width: 53px;
}

.recruit-screen .midashi-9 {
  align-items: flex-start;
  align-self: stretch;
  background-color: #6079c1;
  display: flex;
  flex: 0 0 auto;
  gap: 10px;
  justify-content: center;
  padding: 20px 0px 30px;
  position: relative;
  width: 100%;
}

.recruit-screen .component-2 {
  height: 89px;
  position: relative;
  width: 108px;
}

.recruit-screen .text-wrapper-428 {
  color: #ffffff;
  font-family: "Zen Kaku Gothic New", Helvetica;
  font-size: 32px;
  font-weight: 500;
  left: -12px;
  letter-spacing: 1.6px;
  line-height: normal;
  position: absolute;
  text-align: center;
  top: 14px;
  white-space: nowrap;
}

.recruit-screen .TEXTEXT-2 {
  color: #ffffff;
  font-family: "Slackside One", Helvetica;
  font-size: 23px;
  font-weight: 400;
  left: 12px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  text-align: center;
  top: 52px;
  white-space: nowrap;
}

.recruit-screen .frame-328 {
  align-items: center;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  gap: 30px;
  padding: 20px 30px;
  position: relative;
  width: 100%;
}

.recruit-screen .group-53 {
  height: 15px;
  position: relative;
  width: 137.57px;
}

.recruit-screen .text-wrapper-429 {
  color: var(--gray);
  display: block;
  font-family: var(--tag-font-family);
  font-size: var(--tag-font-size);
  font-style: var(--tag-font-style);
  font-weight: var(--tag-font-weight);
  left: 0;
  letter-spacing: var(--tag-letter-spacing);
  line-height: var(--tag-line-height);
  position: absolute;
  top: 0;
  white-space: nowrap;
}

.recruit-screen .component-10930-5 {
  left: 58px !important;
  top: 3px !important;
}

.recruit-screen .text-wrapper-430 {
  color: var(--gray);
  font-family: var(--tag-font-family);
  font-size: var(--tag-font-size);
  font-style: var(--tag-font-style);
  font-weight: var(--tag-font-weight);
  left: 93px;
  letter-spacing: var(--tag-letter-spacing);
  line-height: var(--tag-line-height);
  position: absolute;
  top: 0;
  white-space: nowrap;
}

.recruit-screen .view-6 {
  align-items: center;
  align-self: stretch;
  background-color: var(--palepurple);
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 40px;
  padding: 80px 15px;
  position: relative;
  width: 100%;
}

.recruit-screen .frame-329 {
  align-items: center;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 70px;
  max-width: 1200px;
  position: relative;
  width: 100%;
}

.recruit-screen .frame-330 {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex-direction: column;
  gap: 20px;
  height: 815.92px;
  justify-content: center;
  position: relative;
  width: 100%;
}

.recruit-screen .frame-331 {
  align-items: flex-start;
  align-self: stretch;
  background-color: var(--back-white);
  border-radius: 14px;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 32px;
  padding: 50px 20px;
  position: relative;
  width: 100%;
}

.recruit-screen .frame-332 {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  gap: 30px;
  max-width: 800px;
  position: relative;
  width: 100%;
}

.recruit-screen .group-54 {
  height: 18.74px;
  position: relative;
  width: 93px;
}

.recruit-screen .text-wrapper-431 {
  color: var(--black);
  font-family: "Manrope-Medium", Helvetica;
  font-size: 13.1px;
  font-weight: 500;
  left: 0;
  letter-spacing: 1.31px;
  line-height: normal;
  position: absolute;
  top: 0;
  width: 91px;
}

.recruit-screen .frame-333 {
  align-items: flex-end;
  display: flex;
  flex: 1;
  flex-grow: 1;
  flex-wrap: wrap;
  gap: 0px 28px;
  position: relative;
}

.recruit-screen .text-wrapper-432 {
  color: var(--purple);
  font-family: var(--tag-font-family);
  font-size: var(--tag-font-size);
  font-style: var(--tag-font-style);
  font-weight: var(--tag-font-weight);
  letter-spacing: var(--tag-letter-spacing);
  line-height: var(--tag-line-height);
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.recruit-screen .text-wrapper-433 {
  color: var(--purple);
  font-family: var(--tag-font-family);
  font-size: var(--tag-font-size);
  font-style: var(--tag-font-style);
  font-weight: var(--tag-font-weight);
  height: 17px;
  letter-spacing: var(--tag-letter-spacing);
  line-height: var(--tag-line-height);
  margin-top: -1px;
  position: relative;
  white-space: nowrap;
  width: 43px;
}

.recruit-screen .div-25 {
  align-self: stretch;
  color: #454545;
  font-family: "Noto Sans JP", Helvetica;
  font-size: 20px;
  font-weight: 700;
  letter-spacing: 1px;
  line-height: 20px;
  position: relative;
}

.recruit-screen .text-wrapper-434 {
  letter-spacing: 0.2px;
  line-height: 37px;
}

.recruit-screen .text-wrapper-435 {
  letter-spacing: 0.2px;
  line-height: 36px;
}

.recruit-screen .grayline-6 {
  align-self: stretch !important;
  background-image: url(../../../static/img/line-13-3.svg) !important;
  top: unset !important;
  width: 100% !important;
}

.recruit-screen .frame-334 {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  gap: 31px;
  position: relative;
  width: 100%;
}

.recruit-screen .frame-335 {
  align-items: center;
  align-self: stretch;
  background-color: var(--gray);
  border-radius: 6px;
  display: flex;
  flex: 1;
  flex-direction: column;
  flex-grow: 1;
  gap: 10px;
  justify-content: center;
  overflow: hidden;
  padding: 104px 70px;
  position: relative;
}

.recruit-screen .noimage-8 {
  height: 25.92px !important;
  left: unset !important;
  position: relative !important;
  top: unset !important;
  width: 196.71px !important;
}

.recruit-screen .frame-336 {
  align-items: center;
  background-color: var(--gray);
  border-radius: 6px;
  display: flex;
  flex: 1;
  flex-direction: column;
  flex-grow: 1;
  gap: 10px;
  justify-content: center;
  overflow: hidden;
  padding: 104px 70px;
  position: relative;
}

.recruit-screen .text-wrapper-436 {
  align-self: stretch;
  color: #454545;
  font-family: var(--text-font-family);
  font-size: var(--text-font-size);
  font-style: var(--text-font-style);
  font-weight: var(--text-font-weight);
  letter-spacing: var(--text-letter-spacing);
  line-height: var(--text-line-height);
  position: relative;
}

.recruit-screen .frame-337 {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  justify-content: space-between;
  position: relative;
  width: 100%;
}

.recruit-screen .group-55 {
  flex: 1;
  flex-grow: 1;
  height: 25px;
  position: relative;
}

.recruit-screen .text-wrapper-437 {
  color: var(--darkgray);
  font-family: "Noto Sans JP", Helvetica;
  font-size: 15px;
  font-weight: 400;
  left: 29px;
  letter-spacing: 0.75px;
  line-height: 25px;
  position: absolute;
  top: 0;
  width: 251px;
}

.recruit-screen .polygon-5 {
  height: 17px !important;
  left: -1px !important;
  top: 5px !important;
  width: 14px !important;
}

.recruit-screen .frame-338 {
  display: flex !important;
  flex: 1 !important;
  flex-grow: 1 !important;
  margin-left: -2px !important;
  max-width: 200px !important;
  min-width: 140px !important;
  padding: 17px 30px !important;
}

.recruit-screen .frame-339 {
  font-weight: 700 !important;
}

.recruit-screen .group-56 {
  flex: 1;
  flex-grow: 1;
  height: 25px;
  margin-left: -2px;
  margin-right: -2px;
  position: relative;
}

.recruit-screen .text-wrapper-438 {
  color: var(--darkgray);
  font-family: "Noto Sans JP", Helvetica;
  font-size: 15px;
  font-weight: 400;
  left: 0;
  letter-spacing: 0.75px;
  line-height: 25px;
  position: absolute;
  text-align: right;
  top: 0;
  width: 243px;
}

.recruit-screen .polygon-6 {
  height: 17px !important;
  left: 267px !important;
  top: 5px !important;
  width: 14px !important;
}

.recruit-screen .frame-340 {
  align-items: center;
  align-self: stretch;
  background-color: #6079c1;
  border-radius: 10px 10px 0px 0px;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 30px;
  padding: 20px 15px;
  position: relative;
  width: 100%;
}

.recruit-screen .text-wrapper-439 {
  color: #ffffff;
  font-family: "Noto Sans JP", Helvetica;
  font-size: 16px;
  font-weight: 700;
  letter-spacing: 0;
  line-height: normal;
  margin-top: -1px;
  position: relative;
  text-align: center;
  width: fit-content;
}

.recruit-screen .frame-341 {
  align-items: center;
  align-self: stretch;
  background-color: #ffffff;
  border-radius: 0px 0px 10px 10px;
  display: flex;
  flex-direction: column;
  gap: 30px;
  height: 430px;
  padding: 30px 15px 45px;
  position: relative;
  width: 100%;
}

.recruit-screen .frame-342 {
  align-items: center;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  justify-content: space-between;
  padding: 0px 10px;
  position: relative;
  width: 100%;
}

.recruit-screen .text-wrapper-440 {
  color: var(--darkgray);
  flex: 1;
  font-family: "Noto Sans JP", Helvetica;
  font-size: 15px;
  font-weight: 600;
  letter-spacing: 0.75px;
  line-height: 26px;
  margin-top: -1px;
  position: relative;
}

.recruit-screen .polygon-7 {
  height: 16.55px !important;
  left: unset !important;
  margin-left: -0.42px !important;
  margin-right: -0.6px !important;
  position: relative !important;
  top: unset !important;
  width: 6.25px !important;
}

.recruit-screen .line-18 {
  align-self: stretch;
  flex: 1;
  flex-grow: 1;
  margin-left: -1px;
  margin-right: -1px;
  position: relative;
  width: 100%;
}

.recruit-screen .frame-343 {
  align-items: center;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  justify-content: space-between;
  margin-bottom: -19px;
  padding: 0px 10px;
  position: relative;
  width: 100%;
}

.recruit-screen .frame-344 {
  align-items: center;
  align-self: stretch;
  background-color: var(--backwhite);
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 40px;
  padding: 100px 15px;
  position: relative;
  width: 100%;
}

.recruit-screen .group-57 {
  height: 118px;
  position: relative;
  width: 562px;
}

.recruit-screen .frame-345 {
  align-items: center;
  background-color: var(--purple);
  border-radius: 6px;
  display: inline-flex;
  flex-wrap: wrap;
  gap: 20px 40px;
  justify-content: center;
  max-width: 700px;
  min-width: 280px;
  padding: 30px 70px;
  position: relative;
}

.recruit-screen .group-58 {
  height: 58px;
  position: relative;
  width: 134px;
}

.recruit-screen .text-wrapper-441 {
  color: var(--white);
  font-family: "Zen Kaku Gothic New", Helvetica;
  font-size: 20px;
  font-weight: 500;
  left: 0;
  letter-spacing: 2px;
  line-height: normal;
  position: absolute;
  text-align: center;
  top: 0;
}

.recruit-screen .text-wrapper-442 {
  color: var(--white);
  font-family: "Slackside One", Helvetica;
  font-size: 20px;
  font-weight: 400;
  left: 24px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  text-align: center;
  top: 29px;
}

.recruit-screen .group-59 {
  height: 55px;
  margin-right: -2px;
  position: relative;
  width: 254px;
}

.recruit-screen .flexcontainer-45 {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  gap: 5px;
  height: 55px;
  position: relative;
  width: 252px;
}

.recruit-screen .text-31 {
  align-self: stretch;
  color: var(--white);
  font-family: "Noto Sans JP", Helvetica;
  font-size: 15px;
  font-weight: 400;
  letter-spacing: 0.75px;
  line-height: 25px;
  position: relative;
}

.recruit-screen .text-wrapper-443 {
  letter-spacing: 0.11px;
}

.recruit-screen .footer-15 {
  align-items: center;
  align-self: stretch;
  background-color: #454545;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 50px;
  overflow: hidden;
  padding: 70px 30px 50px;
  position: relative;
  width: 100%;
}

.recruit-screen .frame-346 {
  align-items: flex-start;
  display: flex;
  flex: 0 0 auto;
  flex-wrap: wrap;
  gap: 50px 150px;
  justify-content: space-between;
  margin-left: -235px;
  margin-right: -235px;
  padding: 0px 15px;
  position: relative;
  width: 1200px;
}

.recruit-screen .frame-347 {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  gap: 44px;
  overflow: hidden;
  position: relative;
  width: 617px;
}

.recruit-screen .logo-white-10 {
  height: 54.26px !important;
  left: unset !important;
  margin-left: -17159px !important;
  margin-top: -1684.92px !important;
  position: relative !important;
  top: unset !important;
  width: 163.65px !important;
}

.recruit-screen .flexcontainer-46 {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.recruit-screen .text-i-47 {
  align-self: stretch;
  color: #ffffff;
  font-family: var(--text-font-family);
  font-size: var(--text-font-size);
  font-style: var(--text-font-style);
  font-weight: var(--text-font-weight);
  letter-spacing: var(--text-letter-spacing);
  line-height: var(--text-line-height);
  position: relative;
}

.recruit-screen .text-wrapper-444 {
  font-family: var(--text-font-family);
  font-size: var(--text-font-size);
  font-style: var(--text-font-style);
  font-weight: var(--text-font-weight);
  letter-spacing: var(--text-letter-spacing);
  line-height: var(--text-line-height);
}

.recruit-screen .frame-348 {
  align-items: flex-start;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 60px;
  position: relative;
}

.recruit-screen .frame-349 {
  align-items: flex-start;
  display: inline-flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 30px;
  position: relative;
}

.recruit-screen .frame-350 {
  align-items: flex-start;
  display: inline-flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 6px;
  position: relative;
}

.recruit-screen .text-wrapper-445 {
  color: #ffffff;
  font-family: var(--text-bold-mini-font-family);
  font-size: var(--text-bold-mini-font-size);
  font-style: var(--text-bold-mini-font-style);
  font-weight: var(--text-bold-mini-font-weight);
  letter-spacing: var(--text-bold-mini-letter-spacing);
  line-height: var(--text-bold-mini-line-height);
  margin-top: -1px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.recruit-screen .text-wrapper-446 {
  color: #ffffff;
  font-family: var(--text-bold-mini-font-family);
  font-size: var(--text-bold-mini-font-size);
  font-style: var(--text-bold-mini-font-style);
  font-weight: var(--text-bold-mini-font-weight);
  letter-spacing: var(--text-bold-mini-letter-spacing);
  line-height: var(--text-bold-mini-line-height);
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.recruit-screen .text-wrapper-447 {
  color: #ffffff;
  display: block;
  font-family: var(--text-bold-mini-font-family);
  font-size: var(--text-bold-mini-font-size);
  font-style: var(--text-bold-mini-font-style);
  font-weight: var(--text-bold-mini-font-weight);
  letter-spacing: var(--text-bold-mini-letter-spacing);
  line-height: var(--text-bold-mini-line-height);
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.recruit-screen .text-wrapper-448 {
  color: #ffffff;
  font-family: "Manrope", Helvetica;
  font-size: 13.1px;
  font-weight: 400;
  letter-spacing: 1.31px;
  line-height: normal;
  position: relative;
  width: fit-content;
}
