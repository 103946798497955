.polygon {
  left: 0;
  position: absolute;
  top: 0;
}

.polygon.one-10-two {
  height: 17px;
  width: 11px;
}

.polygon.one-10-one {
  height: 16px;
  width: 10px;
}

.polygon.one-10-dark-blue {
  height: 17px;
  width: 11px;
}

.polygon.one-10-white {
  height: 17px;
  width: 11px;
}
