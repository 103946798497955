.aboutcontents {
  align-items: center;
  background-color: var(--backwhite);
  display: flex;
  flex-direction: column;
  position: relative;
}

.aboutcontents .header-10 {
  align-self: stretch !important;
  flex: 0 0 auto !important;
  height: unset !important;
  width: 100% !important;
  z-index: 1 !important;
}

.aboutcontents .header-11 {
  margin-bottom: unset !important;
}

.aboutcontents .menu-3-instance {
  height: 35px !important;
  position: relative !important;
  width: 36px !important;
}

.aboutcontents .header-12 {
  align-self: stretch !important;
  display: flex !important;
  flex: 0 0 auto !important;
  justify-content: space-between !important;
  width: 100% !important;
  z-index: 1 !important;
}

.aboutcontents .header-13 {
  z-index: 1 !important;
}

.aboutcontents .header-14 {
  height: 59px !important;
  position: relative !important;
  width: 60px !important;
  z-index: 0 !important;
}

.aboutcontents .div-14 {
  align-items: flex-start;
  align-self: stretch;
  background-color: #ffffff;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  position: relative;
  width: 100%;
  z-index: 0;
}

.aboutcontents .div-15 {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  position: relative;
  width: 100%;
}

.aboutcontents .class-52 {
  color: #ffffff !important;
  left: -10px !important;
}

.aboutcontents .class-53 {
  color: #ffffff !important;
  left: -45px !important;
}

.aboutcontents .class-54 {
  color: #ffffff !important;
  left: 5px !important;
}

.aboutcontents .class-55 {
  color: #ffffff !important;
  left: -44px !important;
}

.aboutcontents .class-56 {
  align-self: stretch !important;
  display: flex !important;
  flex: 0 0 auto !important;
  padding: 5px 0px !important;
  width: 100% !important;
}

.aboutcontents .class-57 {
  align-self: stretch !important;
  flex: 0 0 auto !important;
  width: 100% !important;
}

.aboutcontents .breadcrumbs-5 {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  gap: 30px;
  justify-content: center;
  position: relative;
  width: 100%;
}

.aboutcontents .frame-170 {
  align-items: center;
  display: flex;
  flex: 1;
  flex-grow: 1;
  position: relative;
}

.aboutcontents .text-wrapper-163 {
  color: var(--gray);
  display: block;
  font-family: var(--smalltext-SP-font-family);
  font-size: var(--smalltext-SP-font-size);
  font-style: var(--smalltext-SP-font-style);
  font-weight: var(--smalltext-SP-font-weight);
  letter-spacing: var(--smalltext-SP-letter-spacing);
  line-height: var(--smalltext-SP-line-height);
  margin-top: -1px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.aboutcontents .instance-node-5 {
  height: 8.14px !important;
  position: relative !important;
  width: 4.57px !important;
}

.aboutcontents .TOP {
  color: var(--gray);
  margin-top: -1px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.aboutcontents .text-wrapper-164 {
  color: var(--gray);
  font-family: var(--tag-font-family);
  font-size: var(--tag-font-size);
  font-style: var(--tag-font-style);
  font-weight: var(--tag-font-weight);
  letter-spacing: var(--tag-letter-spacing);
  line-height: var(--tag-line-height);
  margin-top: -1px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.aboutcontents .contents {
  align-items: center;
  align-self: stretch;
  background-color: var(--palepurple);
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  position: relative;
  width: 100%;
}

.aboutcontents .class-58 {
  left: 12px !important;
}

.aboutcontents .class-59 {
  left: -4px !important;
}

.aboutcontents .heading-26 {
  position: relative !important;
  z-index: 1 !important;
}

.aboutcontents .class-60 {
  left: 26px !important;
}

.aboutcontents .class-61 {
  left: -10px !important;
}

.aboutcontents .frame-171 {
  align-items: center;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  position: relative;
  width: 100%;
  z-index: 0;
}

.aboutcontents .group-23 {
  background-image: url(../../../static/img/lead-ill.png);
  background-position: 50% 50%;
  background-size: cover;
  position: relative;
  z-index: 1;
}

.aboutcontents .frame-172 {
  align-items: center;
  align-self: stretch;
  background-color: var(--white);
  border: 8px solid;
  border-color: #d6dfff;
  border-radius: 10px;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 20px;
  margin-top: -20px;
  padding: 40px 20px;
  position: relative;
  width: 100%;
  z-index: 0;
}

.aboutcontents .flexcontainer-20 {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  gap: 2px;
}

.aboutcontents .text-9 {
  align-self: stretch;
  color: var(--darkgray);
  font-family: var(--textbold-SP-font-family);
  font-size: var(--textbold-SP-font-size);
  font-style: var(--textbold-SP-font-style);
  font-weight: var(--textbold-SP-font-weight);
  letter-spacing: var(--textbold-SP-letter-spacing);
  line-height: var(--textbold-SP-line-height);
  position: relative;
  text-align: center;
}

.aboutcontents .text-wrapper-165 {
  font-family: var(--textbold-SP-font-family);
  font-size: var(--textbold-SP-font-size);
  font-style: var(--textbold-SP-font-style);
  font-weight: var(--textbold-SP-font-weight);
  letter-spacing: var(--textbold-SP-letter-spacing);
  line-height: var(--textbold-SP-line-height);
}

.aboutcontents .line-12 {
  align-self: stretch;
  height: 1px;
  object-fit: cover;
  position: relative;
  width: 100%;
}

.aboutcontents .frame-173 {
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  position: relative;
  width: 100%;
}

.aboutcontents .frame-174 {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  position: relative;
}

.aboutcontents .ellipse {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  flex-wrap: wrap;
  gap: 5px 5px;
  position: relative;
  width: 100%;
}

.aboutcontents .ellipse-wrapper {
  height: 28px;
  position: relative;
  width: 10px;
}

.aboutcontents .ellipse-2 {
  height: 6px;
  left: 3px;
  position: absolute;
  top: 9px;
  width: 6px;
}

.aboutcontents .text-wrapper-166 {
  color: #454545;
  flex: 1;
  font-family: var(--textbold-SP-font-family);
  font-size: var(--textbold-SP-font-size);
  font-style: var(--textbold-SP-font-style);
  font-weight: var(--textbold-SP-font-weight);
  height: 28px;
  letter-spacing: var(--textbold-SP-letter-spacing);
  line-height: var(--textbold-SP-line-height);
  margin-top: -1px;
  position: relative;
}

.aboutcontents .frame-175 {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  position: relative;
}

.aboutcontents .frame-176 {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  position: relative;
}

.aboutcontents .rectangle-4 {
  height: 283px;
  object-fit: cover;
  position: relative;
  width: 442px;
  z-index: 1;
}

.aboutcontents .frame-177 {
  align-items: center;
  background-color: var(--white);
  border: 8px solid;
  border-color: #d6dfff;
  border-radius: 10px;
  display: inline-flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 30px;
  margin-top: -40px;
  min-width: 1000px;
  padding: 80px;
  position: relative;
  z-index: 0;
}

.aboutcontents .text-wrapper-167 {
  color: var(--darkgray);
  font-family: "Noto Sans JP", Helvetica;
  font-size: 16px;
  font-weight: 700;
  letter-spacing: 0.8px;
  line-height: normal;
  margin-top: -8px;
  position: relative;
  text-align: center;
  width: fit-content;
}

.aboutcontents .frame-178 {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  gap: 40px;
  justify-content: center;
  position: relative;
  width: 100%;
}

.aboutcontents .frame-179 {
  align-items: flex-start;
  display: flex;
  flex: 1;
  flex-direction: column;
  flex-grow: 1;
  gap: 20px;
  position: relative;
}

.aboutcontents .group-24 {
  height: 28px;
  position: relative;
  width: 17px;
}

.aboutcontents .ellipse-3 {
  background-color: #6079c1;
  border-radius: 5px;
  height: 10px;
  left: 3px;
  position: relative;
  top: 9px;
  width: 10px;
}

.aboutcontents .text-wrapper-168 {
  color: #454545;
  flex: 1;
  font-family: var(--text-bold-font-family);
  font-size: var(--text-bold-font-size);
  font-style: var(--text-bold-font-style);
  font-weight: var(--text-bold-font-weight);
  height: 28px;
  letter-spacing: var(--text-bold-letter-spacing);
  line-height: var(--text-bold-line-height);
  margin-top: -1px;
  position: relative;
  white-space: nowrap;
}

.aboutcontents .flow-2 {
  align-items: center;
  align-self: stretch;
  background-color: var(--palepurple);
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  position: relative;
  width: 100%;
}

.aboutcontents .class-62 {
  left: 1px !important;
}

.aboutcontents .class-63 {
  left: 24px !important;
}

.aboutcontents .heading-27 {
  position: relative !important;
}

.aboutcontents .class-64 {
  left: 40px !important;
}

.aboutcontents .class-65 {
  left: -27px !important;
}

.aboutcontents .frame-180 {
  align-items: center;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  position: relative;
}

.aboutcontents .flow-3 {
  align-items: flex-start;
  align-self: stretch;
  background-color: #ffffff;
  border: 1px solid;
  display: flex;
  flex: 0 0 auto;
  margin-left: -1px;
  margin-right: -1px;
  margin-top: -1px;
  position: relative;
  width: 100%;
}

.aboutcontents .frame-181 {
  align-items: center;
  align-self: stretch;
  background-color: #6079c1;
  border-radius: 10px 0px 0px 10px;
  display: flex;
  flex-direction: column;
  gap: 10px;
  justify-content: center;
  padding: 0px 20px;
  position: relative;
  width: 40px;
  z-index: 3;
}

.aboutcontents .text-wrapper-169 {
  color: #ffffff;
  font-family: "Manrope", Helvetica;
  font-size: 22px;
  font-weight: 800;
  letter-spacing: 0;
  line-height: normal;
  margin-left: -5.5px;
  margin-right: -5.5px;
  position: relative;
  width: fit-content;
}

.aboutcontents .frame-182 {
  align-items: center;
  align-self: stretch;
  flex-direction: column;
  justify-content: center;
  position: relative;
  z-index: 2;
}

.aboutcontents .group-25 {
  height: 166px;
  position: relative;
  width: 168px;
}

.aboutcontents .image-7 {
  height: 137px;
  left: 0;
  position: absolute;
  top: 15px;
  width: 168px;
}

.aboutcontents .frame-183 {
  align-items: flex-start;
  display: flex;
  flex: 1;
  flex-direction: column;
  flex-grow: 1;
  gap: 12px;
  padding: 20px 24px;
  position: relative;
  z-index: 1;
}

.aboutcontents .frame-184 {
  align-items: center;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  gap: 12px;
  position: relative;
  width: 100%;
}

.aboutcontents .freeicon-3 {
  flex: 0 0 auto !important;
}

.aboutcontents .text-wrapper-170 {
  color: #454545;
  font-family: var(--bold-SP-font-family);
  font-size: var(--bold-SP-font-size);
  font-style: var(--bold-SP-font-style);
  font-weight: var(--bold-SP-font-weight);
  letter-spacing: var(--bold-SP-letter-spacing);
  line-height: var(--bold-SP-line-height);
  position: relative;
  width: fit-content;
}

.aboutcontents .text-wrapper-171 {
  align-self: stretch;
  color: #454545;
  position: relative;
}

.aboutcontents .frame-185 {
  align-items: flex-start;
  align-self: stretch;
  background-color: var(--palegray);
  border-radius: 0px 10px 10px 0px;
  display: flex;
  flex-direction: column;
  gap: 10px;
  max-width: 200px;
  padding: 20px 25px 20px 20px;
  position: relative;
  width: 200px;
  z-index: 0;
}

.aboutcontents .frame-186 {
  align-items: center;
  background-color: #4c70c1;
  border-radius: 20px;
  display: inline-flex;
  flex: 0 0 auto;
  justify-content: center;
  overflow: hidden;
  padding: 5px 14px;
  position: relative;
}

.aboutcontents .text-wrapper-172 {
  color: #ffffff;
  font-family: "Noto Sans JP", Helvetica;
  font-size: 14px;
  font-weight: 700;
  letter-spacing: 0.7px;
  line-height: 14.8px;
  margin-top: -1px;
  position: relative;
  text-align: center;
  white-space: nowrap;
  width: fit-content;
}

.aboutcontents .text-wrapper-173 {
  align-self: stretch;
  color: #454545;
  position: relative;
}

.aboutcontents .frame-187 {
  align-items: flex-start;
  display: flex;
  flex: 1;
  flex-direction: column;
  flex-grow: 1;
  position: relative;
}

.aboutcontents .frame-188 {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  height: 100px;
  position: relative;
  width: 100%;
}

.aboutcontents .frame-189 {
  align-items: center;
  background-color: #6079c1;
  border-radius: 6px 0px 0px 0px;
  display: flex;
  flex-direction: column;
  gap: 10px;
  height: 100px;
  justify-content: center;
  padding: 0px 20px;
  position: relative;
  width: 50px;
}

.aboutcontents .text-wrapper-174 {
  color: #ffffff;
  font-family: "Manrope", Helvetica;
  font-size: 19px;
  font-weight: 800;
  letter-spacing: 0;
  line-height: normal;
  position: relative;
  width: fit-content;
}

.aboutcontents .frame-190 {
  align-items: center;
  border-radius: 0px 6px 0px 0px;
  display: flex;
  flex: 1;
  flex-direction: column;
  flex-grow: 1;
  height: 100px;
  justify-content: flex-end;
  overflow: hidden;
  position: relative;
}

.aboutcontents .frame-191 {
  align-items: center;
  display: inline-flex;
  flex: 0 0 auto;
  flex-direction: column;
  justify-content: center;
  margin-top: -17px;
  position: relative;
}

.aboutcontents .group-26 {
  height: 117px;
  position: relative;
  width: 144px;
}

.aboutcontents .image-8 {
  height: 100px;
  left: 0;
  position: absolute;
  top: 17px;
  width: 144px;
}

.aboutcontents .frame-192 {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 12px;
  padding: 20px 15px;
  position: relative;
  width: 100%;
}

.aboutcontents .freeicon-4 {
  display: flex !important;
  height: 22px !important;
  width: 47px !important;
}

.aboutcontents .freeicon-5 {
  font-size: 12px !important;
  letter-spacing: 0.6px !important;
  margin-bottom: -1px !important;
  margin-left: -2px !important;
  margin-right: -2px !important;
  margin-top: -3px !important;
}

.aboutcontents .text-wrapper-175 {
  color: #454545;
  flex: 1;
  font-family: var(--bold-SP-font-family);
  font-size: var(--bold-SP-font-size);
  font-style: var(--bold-SP-font-style);
  font-weight: var(--bold-SP-font-weight);
  letter-spacing: var(--bold-SP-letter-spacing);
  line-height: var(--bold-SP-line-height);
  margin-top: -1px;
  position: relative;
}

.aboutcontents .text-wrapper-176 {
  align-self: stretch;
  color: #454545;
  font-family: var(--text-SP-font-family);
  font-size: var(--text-SP-font-size);
  font-style: var(--text-SP-font-style);
  font-weight: var(--text-SP-font-weight);
  letter-spacing: var(--text-SP-letter-spacing);
  line-height: var(--text-SP-line-height);
  position: relative;
}

.aboutcontents .frame-193 {
  align-items: flex-start;
  align-self: stretch;
  background-color: var(--palegray);
  border-radius: 0px 0px 6px 6px;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 6px;
  padding: 10px 15px 15px;
  position: relative;
  width: 100%;
}

.aboutcontents .text-wrapper-177 {
  color: #ffffff;
  font-family: "Noto Sans JP", Helvetica;
  font-size: 12px;
  font-weight: 700;
  letter-spacing: 0.6px;
  line-height: 14.8px;
  margin-top: -1px;
  position: relative;
  text-align: center;
  white-space: nowrap;
  width: fit-content;
}

.aboutcontents .text-wrapper-178 {
  align-self: stretch;
  color: #454545;
  font-family: var(--textbold-SP-font-family);
  font-size: var(--textbold-SP-font-size);
  font-style: var(--textbold-SP-font-style);
  font-weight: var(--textbold-SP-font-weight);
  letter-spacing: var(--textbold-SP-letter-spacing);
  line-height: var(--textbold-SP-line-height);
  position: relative;
}

.aboutcontents .triangle02-18-instance {
  height: 20px !important;
  position: relative !important;
  width: 20px !important;
}

.aboutcontents .triangle-02-instance {
  height: 34px !important;
  position: relative !important;
  width: 34px !important;
}

.aboutcontents .flow-instance {
  align-self: stretch !important;
  display: flex !important;
  flex: 0 0 auto !important;
  margin-left: -1px !important;
  margin-right: -1px !important;
  width: 100% !important;
}

.aboutcontents .flow-4 {
  display: flex !important;
  flex: 1 !important;
  flex-grow: 1 !important;
}

.aboutcontents .flow-5 {
  align-self: stretch !important;
  width: 100% !important;
}

.aboutcontents .flow-6 {
  margin-left: -1.5px !important;
  margin-right: -1.5px !important;
}

.aboutcontents .flow-7 {
  height: 100px !important;
  top: 17px !important;
}

.aboutcontents .flow-8 {
  height: 58px !important;
}

.aboutcontents .flow-9 {
  top: 0 !important;
}

.aboutcontents .flow-10 {
  align-self: stretch !important;
  white-space: unset !important;
  width: unset !important;
}

.aboutcontents .flow-11 {
  align-items: flex-start;
  align-self: stretch;
  background-color: #ffffff;
  border: 1px solid;
  border-color: #d7d7d7;
  border-radius: 10px;
  display: flex;
  flex: 0 0 auto;
  margin-left: -1px;
  margin-right: -1px;
  position: relative;
  width: 100%;
}

.aboutcontents .element-5 {
  color: #ffffff;
  font-family: "Manrope", Helvetica;
  font-size: 22px;
  font-weight: 800;
  letter-spacing: 0;
  line-height: normal;
  margin-left: -7px;
  margin-right: -7px;
  position: relative;
  width: fit-content;
}

.aboutcontents .frame-194 {
  align-items: center;
  align-self: stretch;
  flex-direction: column;
  justify-content: center;
  position: relative;
  z-index: 2;
}

.aboutcontents .text-wrapper-179 {
  align-self: stretch;
  color: #454545;
  position: relative;
}

.aboutcontents .element-6 {
  align-self: stretch;
  color: #454545;
  position: relative;
}

.aboutcontents .frame-195 {
  align-items: center;
  align-self: stretch;
  flex-direction: column;
  justify-content: center;
  position: relative;
  z-index: 2;
}

.aboutcontents .text-wrapper-180 {
  align-self: stretch;
  color: #454545;
  position: relative;
}

.aboutcontents .element-7 {
  align-self: stretch;
  color: #454545;
  position: relative;
}

.aboutcontents .element-8 {
  color: #ffffff;
  font-family: "Manrope", Helvetica;
  font-size: 22px;
  font-weight: 800;
  letter-spacing: 0;
  line-height: normal;
  margin-left: -7.5px;
  margin-right: -7.5px;
  position: relative;
  width: fit-content;
}

.aboutcontents .frame-196 {
  align-items: center;
  align-self: stretch;
  flex-direction: column;
  justify-content: center;
  position: relative;
  z-index: 2;
}

.aboutcontents .text-wrapper-181 {
  align-self: stretch;
  color: #454545;
  position: relative;
}

.aboutcontents .element-9 {
  align-self: stretch;
  color: #454545;
  position: relative;
}

.aboutcontents .flow-12 {
  align-items: flex-start;
  align-self: stretch;
  background-color: #ffffff;
  border: 1px solid;
  border-color: #d7d7d7;
  border-radius: 10px;
  display: flex;
  flex: 0 0 auto;
  margin-bottom: -1px;
  margin-left: -1px;
  margin-right: -1px;
  position: relative;
  width: 100%;
}

.aboutcontents .frame-197 {
  align-items: center;
  align-self: stretch;
  flex-direction: column;
  justify-content: center;
  position: relative;
  z-index: 2;
}

.aboutcontents .text-wrapper-182 {
  align-self: stretch;
  color: #454545;
  position: relative;
}

.aboutcontents .text-wrapper-183 {
  align-self: stretch;
  color: #454545;
  position: relative;
}

.aboutcontents .flow-13 {
  margin-left: -1px !important;
  margin-right: -1px !important;
}

.aboutcontents .flow-14 {
  background-color: #6079c1 !important;
  display: flex !important;
  height: 22px !important;
  width: 47px !important;
}

.aboutcontents .flow-15 {
  align-self: stretch !important;
  display: flex !important;
  flex: 0 0 auto !important;
  margin-bottom: -1px !important;
  margin-left: -1px !important;
  margin-right: -1px !important;
  width: 100% !important;
}

.aboutcontents .CONTACT-12 {
  align-items: center;
  background-color: var(--backwhite);
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 40px;
  position: relative;
}

.aboutcontents .CONTACT-13 {
  display: block;
  height: 160px;
  position: relative;
}

.aboutcontents .frame-198 {
  align-items: center;
  background-color: var(--purple);
  border-radius: 6px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  max-width: 700px;
  position: relative;
}

.aboutcontents .group-27 {
  position: relative;
  width: 216px;
}

.aboutcontents .text-wrapper-184 {
  color: var(--white);
  font-family: "Zen Kaku Gothic New", Helvetica;
  font-size: 20px;
  font-weight: 500;
  left: 0;
  letter-spacing: 2px;
  line-height: normal;
  position: absolute;
  text-align: center;
  top: 0;
  width: 212px;
}

.aboutcontents .text-wrapper-185 {
  color: var(--white);
  font-family: "Slackside One", Helvetica;
  font-size: 15px;
  font-weight: 400;
  left: 39px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  text-align: center;
  top: 31px;
  width: 134px;
}

.aboutcontents .overlap-group-7 {
  height: 60px;
  position: relative;
  width: 212px;
}

.aboutcontents .text-wrapper-186 {
  color: var(--white);
  font-family: "Zen Kaku Gothic New", Helvetica;
  font-size: 24px;
  font-weight: 500;
  left: 0;
  letter-spacing: 2.4px;
  line-height: normal;
  position: absolute;
  text-align: center;
  top: 0;
  width: 212px;
}

.aboutcontents .text-wrapper-187 {
  color: var(--white);
  font-family: "Slackside One", Helvetica;
  font-size: 20px;
  font-weight: 400;
  left: 39px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  text-align: center;
  top: 31px;
  width: 134px;
}

.aboutcontents .group-28 {
  position: relative;
}

.aboutcontents .flexcontainer-21 {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  position: relative;
}

.aboutcontents .text-i-19 {
  align-self: stretch;
  color: var(--white);
  position: relative;
}

.aboutcontents .text-i-20 {
  align-self: stretch;
  color: var(--white);
  position: relative;
}

.aboutcontents .footer-7 {
  align-items: center;
  align-self: stretch;
  background-color: #454545;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 50px;
  position: relative;
  width: 100%;
}

.aboutcontents .frame-199 {
  align-items: flex-start;
  display: flex;
  flex: 0 0 auto;
  position: relative;
}

.aboutcontents .frame-200 {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  position: relative;
}

.aboutcontents .logo-white-3 {
  height: 40px !important;
  position: relative !important;
  width: 121px !important;
}

.aboutcontents .flexcontainer-22 {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  gap: 3px;
}

.aboutcontents .text-i-21 {
  align-self: stretch;
  color: #ffffff;
  font-family: "Noto Sans JP", Helvetica;
  font-size: 13px;
  font-weight: 400;
  letter-spacing: 0.26px;
  line-height: 18px;
  position: relative;
}

.aboutcontents .text-wrapper-188 {
  letter-spacing: 0.03px;
}

.aboutcontents .logo-white-25 {
  height: 54.26px !important;
  position: relative !important;
  width: 163.65px !important;
}

.aboutcontents .flexcontainer-23 {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.aboutcontents .text-i-22 {
  align-self: stretch;
  color: #ffffff;
  font-family: var(--text-font-family);
  font-size: var(--text-font-size);
  font-style: var(--text-font-style);
  font-weight: var(--text-font-weight);
  letter-spacing: var(--text-letter-spacing);
  line-height: var(--text-line-height);
  position: relative;
}

.aboutcontents .text-wrapper-189 {
  font-family: var(--text-font-family);
  font-size: var(--text-font-size);
  font-style: var(--text-font-style);
  font-weight: var(--text-font-weight);
  letter-spacing: var(--text-letter-spacing);
  line-height: var(--text-line-height);
}

.aboutcontents .frame-201 {
  align-items: flex-start;
  flex: 0 0 auto;
  position: relative;
}

.aboutcontents .frame-202 {
  align-items: flex-start;
  display: inline-flex;
  flex: 0 0 auto;
  flex-direction: column;
  position: relative;
}

.aboutcontents .frame-203 {
  align-items: flex-start;
  display: inline-flex;
  flex: 0 0 auto;
  flex-direction: column;
  position: relative;
}

.aboutcontents .text-wrapper-190 {
  color: #ffffff;
  display: block;
  margin-top: -1px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.aboutcontents .text-wrapper-191 {
  color: #ffffff;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.aboutcontents .text-wrapper-192 {
  color: #ffffff;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.aboutcontents .text-wrapper-193 {
  color: #ffffff;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.aboutcontents .frame-204 {
  align-items: flex-start;
  display: inline-flex;
  flex: 0 0 auto;
  flex-direction: column;
  position: relative;
}

.aboutcontents .text-wrapper-194 {
  color: #ffffff;
  margin-top: -1px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.aboutcontents .text-wrapper-195 {
  color: #ffffff;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.aboutcontents .text-wrapper-196 {
  color: #ffffff;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.aboutcontents .frame-205 {
  align-items: flex-start;
  display: inline-flex;
  flex: 0 0 auto;
  flex-direction: column;
  position: relative;
}

.aboutcontents .text-wrapper-197 {
  color: #ffffff;
  margin-top: -1px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.aboutcontents .text-wrapper-198 {
  color: #ffffff;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.aboutcontents .text-wrapper-199 {
  color: #ffffff;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.aboutcontents .frame-206 {
  align-items: flex-start;
  flex-direction: column;
  gap: 30px;
  position: relative;
}

.aboutcontents .text-wrapper-200 {
  color: #ffffff;
  display: block;
  margin-top: -1px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.aboutcontents .text-wrapper-201 {
  color: #ffffff;
  display: block;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.aboutcontents .text-wrapper-202 {
  color: #ffffff;
  display: block;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.aboutcontents .text-wrapper-203 {
  color: #ffffff;
  display: block;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.aboutcontents .text-wrapper-204 {
  color: #ffffff;
  display: block;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.aboutcontents .text-wrapper-205 {
  color: #ffffff;
  font-family: "Manrope", Helvetica;
  font-size: 13.1px;
  font-weight: 400;
  letter-spacing: 1.31px;
  line-height: normal;
  position: relative;
  width: fit-content;
}
