.contact {
  align-items: center;
  display: flex;
  flex-direction: column;
  position: relative;
}

.contact .header {
  align-self: stretch !important;
  flex: 0 0 auto !important;
  height: unset !important;
  width: 100% !important;
  z-index: 1 !important;
}

.contact .SP-white-instance {
  margin-bottom: unset !important;
}

.contact .menu-3 {
  height: 35px !important;
  position: relative !important;
  width: 36px !important;
}

.contact .class-5 {
  height: 59px !important;
  position: relative !important;
  width: 60px !important;
  z-index: 0 !important;
}

.contact .color-white-instance {
  align-self: stretch !important;
  display: flex !important;
  flex: 0 0 auto !important;
  justify-content: space-between !important;
  width: 100% !important;
  z-index: 1 !important;
}

.contact .class-6 {
  z-index: 1 !important;
}

.contact .contacts {
  align-items: flex-start;
  align-self: stretch;
  background-color: #ffffff;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  position: relative;
  width: 100%;
  z-index: 0;
}

.contact .midashi {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  position: relative;
  width: 100%;
}

.contact .class-7 {
  color: #ffffff !important;
  left: -10px !important;
}

.contact .class-8 {
  color: #ffffff !important;
  left: 4px !important;
}

.contact .class-9 {
  color: #ffffff !important;
  left: 30px !important;
}

.contact .class-10 {
  color: #ffffff !important;
  left: -44px !important;
}

.contact .class-11 {
  align-self: stretch !important;
  display: flex !important;
  flex: 0 0 auto !important;
  padding: 5px 0px !important;
  width: 100% !important;
}

.contact .class-12 {
  align-self: stretch !important;
  background-image: url(../../../static/img/midashi-1.png) !important;
  flex: 0 0 auto !important;
  width: 100% !important;
}

.contact .q-a-instance {
  align-self: stretch !important;
  flex: 0 0 auto !important;
  width: 100% !important;
}

.contact .breadcrumbs {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  gap: 30px;
  justify-content: center;
  position: relative;
  width: 100%;
}

.contact .frame-38 {
  align-items: center;
  display: flex;
  flex: 1;
  flex-grow: 1;
  position: relative;
}

.contact .text-wrapper-24 {
  color: var(--gray);
  display: block;
  margin-top: -1px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.contact .instance-node-2 {
  height: 8.14px !important;
  position: relative !important;
  width: 4.57px !important;
}

.contact .component-10930 {
  height: 8.14px !important;
  left: unset !important;
  position: relative !important;
  top: unset !important;
  width: 4.57px !important;
}

.contact .div-6 {
  color: var(--gray);
  margin-top: -1px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.contact .text-wrapper-25 {
  color: var(--gray);
  font-family: var(--tag-font-family);
  font-size: var(--tag-font-size);
  font-style: var(--tag-font-style);
  font-weight: var(--tag-font-weight);
  letter-spacing: var(--tag-letter-spacing);
  line-height: var(--tag-line-height);
  margin-top: -1px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.contact .contactform {
  align-items: center;
  align-self: stretch;
  background-color: var(--palepurple);
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 40px;
  position: relative;
  width: 100%;
}

.contact .flexcontainer {
  align-items: flex-start;
  display: inline-flex;
  flex-direction: column;
}

.contact .text {
  align-self: stretch;
  color: #454545;
  position: relative;
  text-align: center;
}

.contact .span-wrapper {
  align-self: stretch;
  color: #454545;
  position: relative;
  text-align: center;
}

.contact .frame-39 {
  align-items: center;
  flex: 0 0 auto;
  position: relative;
}

.contact .p {
  align-self: stretch;
  color: #6079c1;
  font-family: var(--textbold-SP-font-family);
  font-size: var(--textbold-SP-font-size);
  font-style: var(--textbold-SP-font-style);
  font-weight: var(--textbold-SP-font-weight);
  letter-spacing: var(--textbold-SP-letter-spacing);
  line-height: var(--textbold-SP-line-height);
  position: relative;
  text-align: right;
}

.contact .text-wrapper-26 {
  font-family: var(--textbold-SP-font-family);
  font-size: var(--textbold-SP-font-size);
  font-style: var(--textbold-SP-font-style);
  font-weight: var(--textbold-SP-font-weight);
  letter-spacing: var(--textbold-SP-letter-spacing);
  line-height: var(--textbold-SP-line-height);
}

.contact .class-13 {
  font-family: var(--textbold-SP-font-family) !important;
  font-size: var(--textbold-SP-font-size) !important;
  font-style: var(--textbold-SP-font-style) !important;
  font-weight: var(--textbold-SP-font-weight) !important;
  letter-spacing: var(--textbold-SP-letter-spacing) !important;
  line-height: var(--textbold-SP-line-height) !important;
  white-space: nowrap !important;
}

.contact .class-14 {
  margin-bottom: -11.5px !important;
  margin-top: -11.5px !important;
}

.contact .class-15 {
  height: 11.13px !important;
  margin-left: -1.64px !important;
  margin-right: -1.15px !important;
  position: relative !important;
  width: 7.41px !important;
}

.contact .frame-40 {
  align-items: flex-start;
  background-color: var(--back-white);
  border-radius: 14px;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  position: relative;
}

.contact .frame-41 {
  align-items: flex-start;
  flex: 0 0 auto;
  position: relative;
}

.contact .class-16 {
  font-family: var(--text-bold-mini-font-family) !important;
  font-size: var(--text-bold-mini-font-size) !important;
  font-style: var(--text-bold-mini-font-style) !important;
  font-weight: var(--text-bold-mini-font-weight) !important;
  letter-spacing: var(--text-bold-mini-letter-spacing) !important;
  line-height: var(--text-bold-mini-line-height) !important;
}

.contact .class-17 {
  flex: 0 0 auto !important;
  gap: 8px !important;
}

.contact .checklist-instance {
  flex: 0 0 auto !important;
}

.contact .input-field-instance {
  align-self: stretch !important;
  display: flex !important;
  flex: 0 0 auto !important;
  width: 100% !important;
}

.contact .class-18 {
  margin-bottom: unset !important;
  margin-top: -1px !important;
}

.contact .class-19 {
  border-radius: unset !important;
  position: relative !important;
}

.contact .class-20 {
  position: relative !important;
}

.contact .class-21 {
  flex: 0 0 auto !important;
  height: unset !important;
}

.contact .class-22 {
  position: relative !important;
}

.contact .class-23 {
  position: relative !important;
}

.contact .class-24 {
  position: relative !important;
}

.contact .class-25 {
  align-self: stretch !important;
  display: flex !important;
  height: 82px !important;
  width: 100% !important;
}

.contact .class-26 {
  align-self: stretch !important;
  display: flex !important;
  height: 326px !important;
  width: 100% !important;
}

.contact .class-27 {
  flex: 1 !important;
  margin-bottom: unset !important;
  margin-top: -1px !important;
  white-space: unset !important;
  width: unset !important;
}

.contact .class-28 {
  position: relative !important;
}

.contact .input-field-2 {
  align-items: flex-start !important;
  flex: 1 !important;
  flex-grow: 1 !important;
  height: unset !important;
}

.contact .class-29 {
  font-family: var(--button-SP-font-family) !important;
  font-size: var(--button-SP-font-size) !important;
  font-style: var(--button-SP-font-style) !important;
  font-weight: var(--button-SP-font-weight) !important;
  letter-spacing: var(--button-SP-letter-spacing) !important;
  line-height: var(--button-SP-line-height) !important;
}

.contact .class-30 {
  align-self: stretch !important;
  width: 100% !important;
}

.contact .q-a-2 {
  align-items: center;
  align-self: stretch;
  background-color: var(--palepurple);
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  position: relative;
  width: 100%;
}

.contact .class-31 {
  left: -10px !important;
}

.contact .class-32 {
  left: 35px !important;
}

.contact .heading-2 {
  position: relative !important;
}

.contact .class-33 {
  left: 45px !important;
}

.contact .class-34 {
  left: -44px !important;
}

.contact .frame-42 {
  align-items: flex-start;
  display: flex;
  flex: 0 0 auto;
  position: relative;
}

.contact .frame-43 {
  align-items: center;
  display: flex;
  flex-direction: column;
  position: relative;
}

.contact .class-35 {
  gap: 12px !important;
}

.contact .class-36 {
  align-items: flex-start !important;
  display: flex !important;
  flex: 1 !important;
  flex-grow: 1 !important;
  justify-content: unset !important;
  margin-right: unset !important;
}

.contact .class-37 {
  align-self: stretch !important;
  flex: 0 0 auto !important;
  padding: 20px 10px !important;
  width: 100% !important;
}

.contact .class-38 {
  height: 12px !important;
  margin-left: -10.61px !important;
  position: relative !important;
  width: 12px !important;
}

.contact .triangle {
  height: 17px !important;
  position: relative !important;
  width: 17px !important;
}

.contact .q-aicon-2 {
  height: 24px !important;
  position: relative !important;
  width: 24px !important;
}

.contact .class-39 {
  display: flex !important;
  flex: 1 !important;
  flex-grow: 1 !important;
}

.contact .q-a-3 {
  align-items: flex-start;
  align-self: stretch;
  background-color: var(--white);
  border: 1px solid;
  border-color: #efeeee;
  border-radius: 6px;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 20px;
  padding: 30px 20px;
  position: relative;
  width: 100%;
}

.contact .frame-44 {
  align-items: center;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  justify-content: space-between;
  position: relative;
  width: 100%;
}

.contact .frame-45 {
  align-items: center;
  display: flex;
  gap: 14px;
  position: relative;
  width: 268.61px;
}

.contact .frame-46 {
  align-items: center;
  display: inline-flex;
  flex: 0 0 auto;
  flex-wrap: wrap;
  gap: 10px 10px;
  justify-content: center;
  margin-right: -4.39px;
  position: relative;
}

.contact .text-wrapper-27 {
  color: var(--dark-blue);
  flex: 1;
  font-family: "Noto Sans JP", Helvetica;
  font-size: 16px;
  font-weight: 700;
  letter-spacing: 0.8px;
  line-height: normal;
  margin-top: -1px;
  position: relative;
}

.contact .frame-47 {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  gap: 136px;
  position: relative;
  width: 100%;
}

.contact .frame-48 {
  align-items: flex-start;
  display: flex;
  flex: 1;
  flex-grow: 1;
  gap: 14px;
  position: relative;
}

.contact .frame-49 {
  align-items: flex-start;
  display: flex;
  flex: 1;
  flex-grow: 1;
  flex-wrap: wrap;
  gap: 10px 10px;
  position: relative;
}

.contact .text-wrapper-28 {
  color: #454545;
  flex: 1;
  font-family: var(--text-font-family);
  font-size: var(--text-font-size);
  font-style: var(--text-font-style);
  font-weight: var(--text-font-weight);
  letter-spacing: var(--text-letter-spacing);
  line-height: var(--text-line-height);
  margin-top: -1px;
  position: relative;
}

.contact .frame-50 {
  align-items: center;
  display: flex;
  flex-direction: column;
  position: relative;
}

.contact .CONTACT {
  align-items: center;
  align-self: stretch;
  background-color: var(--backwhite);
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 40px;
  position: relative;
  width: 100%;
}

.contact .CONTACT-2 {
  height: 160px;
  position: relative;
}

.contact .frame-51 {
  align-items: center;
  background-color: var(--purple);
  border-radius: 6px;
  display: flex;
  flex-wrap: wrap;
  gap: 20px 74px;
  justify-content: center;
  max-width: 700px;
  position: relative;
}

.contact .group-4 {
  position: relative;
  width: 216px;
}

.contact .text-wrapper-29 {
  color: var(--white);
  font-family: "Zen Kaku Gothic New", Helvetica;
  font-size: 20px;
  font-weight: 500;
  left: 0;
  letter-spacing: 2px;
  line-height: normal;
  position: absolute;
  text-align: center;
  top: 0;
  width: 212px;
}

.contact .text-wrapper-30 {
  color: var(--white);
  font-family: "Slackside One", Helvetica;
  font-size: 15px;
  font-weight: 400;
  left: 39px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  text-align: center;
  top: 31px;
  width: 134px;
}

.contact .overlap-group-3 {
  height: 60px;
  position: relative;
  width: 212px;
}

.contact .text-wrapper-31 {
  color: var(--white);
  font-family: "Zen Kaku Gothic New", Helvetica;
  font-size: 24px;
  font-weight: 500;
  left: 0;
  letter-spacing: 2.4px;
  line-height: normal;
  position: absolute;
  text-align: center;
  top: 0;
  width: 212px;
}

.contact .text-wrapper-32 {
  color: var(--white);
  font-family: "Slackside One", Helvetica;
  font-size: 20px;
  font-weight: 400;
  left: 39px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  text-align: center;
  top: 31px;
  width: 134px;
}

.contact .flexcontainer-wrapper {
  position: relative;
}

.contact .flexcontainer-i {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  position: relative;
}

.contact .text-i {
  align-self: stretch;
  color: var(--white);
  position: relative;
}

.contact .text-i-2 {
  align-self: stretch;
  color: var(--white);
  position: relative;
}

.contact .footer {
  align-items: center;
  align-self: stretch;
  background-color: #454545;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 50px;
  position: relative;
  width: 100%;
}

.contact .frame-52 {
  align-items: flex-start;
  display: flex;
  flex: 0 0 auto;
  position: relative;
}

.contact .frame-53 {
  align-items: flex-start;
  flex-direction: column;
  position: relative;
}

.contact .logo-white-22 {
  height: 40px !important;
  position: relative !important;
  width: 121px !important;
}

.contact .logo-white-instance {
  height: 54.26px !important;
  left: unset !important;
  position: relative !important;
  top: unset !important;
  width: 163.65px !important;
}

.contact .flexcontainer-2 {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
}

.contact .text-i-3 {
  align-self: stretch;
  color: #ffffff;
  position: relative;
}

.contact .text-i-4 {
  align-self: stretch;
  color: #ffffff;
  position: relative;
}

.contact .logo-white-30 {
  height: 54.26px !important;
  position: relative !important;
  width: 163.65px !important;
}

.contact .flexcontainer-3 {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.contact .text-i-5 {
  align-self: stretch;
  color: #ffffff;
  font-family: var(--text-font-family);
  font-size: var(--text-font-size);
  font-style: var(--text-font-style);
  font-weight: var(--text-font-weight);
  letter-spacing: var(--text-letter-spacing);
  line-height: var(--text-line-height);
  position: relative;
}

.contact .text-wrapper-33 {
  font-family: var(--text-font-family);
  font-size: var(--text-font-size);
  font-style: var(--text-font-style);
  font-weight: var(--text-font-weight);
  letter-spacing: var(--text-letter-spacing);
  line-height: var(--text-line-height);
}

.contact .frame-54 {
  align-items: flex-start;
  flex: 0 0 auto;
  position: relative;
}

.contact .frame-55 {
  align-items: flex-start;
  display: inline-flex;
  flex: 0 0 auto;
  flex-direction: column;
  position: relative;
}

.contact .frame-56 {
  align-items: flex-start;
  display: inline-flex;
  flex: 0 0 auto;
  flex-direction: column;
  position: relative;
}

.contact .text-wrapper-34 {
  color: #ffffff;
  display: block;
  margin-top: -1px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.contact .text-wrapper-35 {
  color: #ffffff;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.contact .text-wrapper-36 {
  color: #ffffff;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.contact .text-wrapper-37 {
  color: #ffffff;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.contact .frame-57 {
  align-items: flex-start;
  display: inline-flex;
  flex: 0 0 auto;
  flex-direction: column;
  position: relative;
}

.contact .text-wrapper-38 {
  color: #ffffff;
  margin-top: -1px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.contact .text-wrapper-39 {
  color: #ffffff;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.contact .text-wrapper-40 {
  color: #ffffff;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.contact .frame-58 {
  align-items: flex-start;
  display: inline-flex;
  flex: 0 0 auto;
  flex-direction: column;
  position: relative;
}

.contact .text-wrapper-41 {
  color: #ffffff;
  margin-top: -1px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.contact .text-wrapper-42 {
  color: #ffffff;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.contact .text-wrapper-43 {
  color: #ffffff;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.contact .frame-59 {
  align-items: flex-start;
  flex-direction: column;
  gap: 30px;
  position: relative;
}

.contact .text-wrapper-44 {
  color: #ffffff;
  display: block;
  margin-top: -1px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.contact .text-wrapper-45 {
  color: #ffffff;
  display: block;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.contact .text-wrapper-46 {
  color: #ffffff;
  display: block;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.contact .text-wrapper-47 {
  color: #ffffff;
  display: block;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.contact .text-wrapper-48 {
  color: #ffffff;
  display: block;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.contact .text-wrapper-49 {
  color: #ffffff;
  font-family: "Manrope", Helvetica;
  font-size: 13.1px;
  font-weight: 400;
  letter-spacing: 1.31px;
  line-height: normal;
  position: relative;
  width: fit-content;
}
