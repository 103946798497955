.recruit {
  align-items: flex-start;
  background-color: #ffffff;
  display: flex;
  flex-direction: column;
  position: relative;
}

.recruit .headrer {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  position: relative;
  width: 100%;
}

.recruit .logo-3 {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  justify-content: space-between;
  padding: 0px 0px 20px 50px;
  position: relative;
  width: 100%;
}

.recruit .group-44 {
  height: 70px;
  position: relative;
  width: 152.61px;
}

.recruit .logo-4 {
  height: 57px !important;
  position: relative !important;
  top: 13px !important;
  width: 153px !important;
}

.recruit .menu-13 {
  height: 70px;
  position: relative;
  width: 71px;
}

.recruit .component-wrapper {
  align-items: flex-start;
  align-self: stretch;
  background-image: url(../../../static/img/midashi-1.png);
  background-position: 50% 50%;
  background-size: cover;
  display: flex;
  flex: 0 0 auto;
  gap: 10px;
  justify-content: center;
  padding: 20px 0px 30px;
  position: relative;
  width: 100%;
}

.recruit .component {
  height: 89px;
  position: relative;
  width: 108px;
}

.recruit .text-wrapper-408 {
  color: #ffffff;
  font-family: "Zen Kaku Gothic New", Helvetica;
  font-size: 32px;
  font-weight: 500;
  left: -12px;
  letter-spacing: 1.6px;
  line-height: normal;
  position: absolute;
  text-align: center;
  top: 14px;
  white-space: nowrap;
}

.recruit .TEXTEXT {
  color: #ffffff;
  font-family: "Slackside One", Helvetica;
  font-size: 23px;
  font-weight: 400;
  left: 12px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  text-align: center;
  top: 52px;
  white-space: nowrap;
}

.recruit .frame-308 {
  align-items: center;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  gap: 30px;
  padding: 50px;
  position: relative;
  width: 100%;
}

.recruit .text-wrapper-409 {
  color: var(--gray);
  font-family: var(--tag-font-family);
  font-size: var(--tag-font-size);
  font-style: var(--tag-font-style);
  font-weight: var(--tag-font-weight);
  letter-spacing: var(--tag-letter-spacing);
  line-height: var(--tag-line-height);
  margin-top: -1px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.recruit .component-10930-4 {
  height: 8.14px !important;
  left: unset !important;
  position: relative !important;
  top: unset !important;
  width: 4.57px !important;
}

.recruit .view-4 {
  align-items: center;
  align-self: stretch;
  background-color: var(--palepurple);
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 40px;
  padding: 80px 15px;
  position: relative;
  width: 100%;
}

.recruit .frame-309 {
  align-items: flex-start;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 70px;
  max-width: 1100px;
  position: relative;
}

.recruit .frame-310 {
  align-items: flex-start;
  display: inline-flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 20px;
  justify-content: center;
  position: relative;
}

.recruit .frame-311 {
  align-items: flex-start;
  background-color: var(--back-white);
  border-radius: 14px;
  display: flex;
  flex-direction: column;
  gap: 32px;
  height: 735.92px;
  padding: 50px 20px;
  position: relative;
  width: 800px;
}

.recruit .frame-312 {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  gap: 30px;
  max-width: 800px;
  position: relative;
  width: 100%;
}

.recruit .group-45 {
  height: 18.74px;
  position: relative;
  width: 93px;
}

.recruit .text-wrapper-410 {
  color: var(--black);
  font-family: var(--date-font-family);
  font-size: var(--date-font-size);
  font-style: var(--date-font-style);
  font-weight: var(--date-font-weight);
  left: 0;
  letter-spacing: var(--date-letter-spacing);
  line-height: var(--date-line-height);
  position: absolute;
  top: 0;
  width: 91px;
}

.recruit .frame-313 {
  align-items: flex-end;
  display: flex;
  flex: 1;
  flex-grow: 1;
  flex-wrap: wrap;
  gap: 0px 28px;
  position: relative;
}

.recruit .text-wrapper-411 {
  color: var(--purple);
  font-family: var(--tag-font-family);
  font-size: var(--tag-font-size);
  font-style: var(--tag-font-style);
  font-weight: var(--tag-font-weight);
  letter-spacing: var(--tag-letter-spacing);
  line-height: var(--tag-line-height);
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.recruit .text-wrapper-412 {
  color: var(--purple);
  font-family: var(--tag-font-family);
  font-size: var(--tag-font-size);
  font-style: var(--tag-font-style);
  font-weight: var(--tag-font-weight);
  height: 17px;
  letter-spacing: var(--tag-letter-spacing);
  line-height: var(--tag-line-height);
  margin-top: -1px;
  position: relative;
  white-space: nowrap;
  width: 43px;
}

.recruit .div-23 {
  align-self: stretch;
  color: #454545;
  font-family: "Noto Sans JP", Helvetica;
  font-size: 20px;
  font-weight: 700;
  letter-spacing: 1px;
  line-height: 20px;
  position: relative;
}

.recruit .text-wrapper-413 {
  letter-spacing: 0.2px;
  line-height: 37px;
}

.recruit .text-wrapper-414 {
  letter-spacing: 0.2px;
  line-height: 36px;
}

.recruit .grayline-5 {
  align-self: stretch !important;
  background-image: url(../../../static/img/line-13-2-2.svg) !important;
  flex: 1 !important;
  flex-grow: 1 !important;
  height: unset !important;
  top: unset !important;
  width: 100% !important;
}

.recruit .frame-314 {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  flex-wrap: wrap;
  gap: 31px 31px;
  position: relative;
  width: 100%;
}

.recruit .group-46 {
  background-color: var(--gray);
  border-radius: 6px;
  flex: 1;
  flex-grow: 1;
  height: 236.18px;
  position: relative;
}

.recruit .noimage-6 {
  left: 74px !important;
  top: 107px !important;
  width: 208px !important;
}

.recruit .group-47 {
  background-image: url(../../../static/img/rectangle-23.svg);
  background-size: 100% 100%;
  flex: 1;
  flex-grow: 1;
  height: 236.18px;
  position: relative;
}

.recruit .noimage-7 {
  left: 79px !important;
  top: 107px !important;
  width: 208px !important;
}

.recruit .text-wrapper-415 {
  align-self: stretch;
  color: #454545;
  font-family: var(--text-font-family);
  font-size: var(--text-font-size);
  font-style: var(--text-font-style);
  font-weight: var(--text-font-weight);
  letter-spacing: var(--text-letter-spacing);
  line-height: var(--text-line-height);
  position: relative;
}

.recruit .frame-315 {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  justify-content: space-between;
  position: relative;
  width: 100%;
}

.recruit .group-48 {
  flex: 1;
  flex-grow: 1;
  height: 25px;
  position: relative;
}

.recruit .text-wrapper-416 {
  color: var(--darkgray);
  font-family: "Noto Sans JP", Helvetica;
  font-size: 15px;
  font-weight: 400;
  left: 31px;
  letter-spacing: 0.75px;
  line-height: 25px;
  position: absolute;
  top: 0;
  width: 269px;
}

.recruit .polygon-3 {
  height: 17px !important;
  left: -1px !important;
  top: 5px !important;
  width: 15px !important;
}

.recruit .frame-316 {
  display: flex !important;
  flex: 1 !important;
  flex-grow: 1 !important;
  margin-left: -2px !important;
  max-width: 200px !important;
  min-width: 140px !important;
  padding: 17px 30px !important;
}

.recruit .frame-317 {
  font-weight: 700 !important;
}

.recruit .group-49 {
  flex: 1;
  flex-grow: 1;
  height: 25px;
  margin-left: -2px;
  margin-right: -2px;
  position: relative;
}

.recruit .text-wrapper-417 {
  color: var(--darkgray);
  font-family: "Noto Sans JP", Helvetica;
  font-size: 15px;
  font-weight: 400;
  left: 0;
  letter-spacing: 0.75px;
  line-height: 25px;
  position: absolute;
  text-align: right;
  top: 0;
  width: 260px;
}

.recruit .polygon-4 {
  height: 17px !important;
  left: 286px !important;
  top: 5px !important;
  width: 15px !important;
}

.recruit .view-5 {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  margin-right: -10px;
  position: relative;
  width: 240px;
}

.recruit .frame-318 {
  align-items: center;
  align-self: stretch;
  background-color: #6079c1;
  border-radius: 10px 10px 0px 0px;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 30px;
  padding: 20px 15px;
  position: relative;
  width: 100%;
}

.recruit .text-wrapper-418 {
  color: #ffffff;
  font-family: "Noto Sans JP", Helvetica;
  font-size: 16px;
  font-weight: 700;
  letter-spacing: 0;
  line-height: normal;
  margin-top: -1px;
  position: relative;
  text-align: center;
  width: fit-content;
}

.recruit .frame-319 {
  align-items: center;
  align-self: stretch;
  background-color: #ffffff;
  border-radius: 0px 0px 10px 10px;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 30px;
  padding: 30px 15px 45px;
  position: relative;
  width: 100%;
}

.recruit .frame-320 {
  align-items: center;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  justify-content: space-between;
  padding: 0px 10px;
  position: relative;
  width: 100%;
}

.recruit .text-wrapper-419 {
  color: var(--darkgray);
  flex: 1;
  font-family: "Noto Sans JP", Helvetica;
  font-size: 15px;
  font-weight: 600;
  letter-spacing: 0.75px;
  line-height: 26px;
  margin-top: -1px;
  position: relative;
}

.recruit .polygon-2-10 {
  height: 16.55px !important;
  margin-left: -0.42px !important;
  margin-right: -0.6px !important;
  position: relative !important;
  width: 6.25px !important;
}

.recruit .line-17 {
  align-self: stretch;
  height: 2px;
  margin-left: -1px;
  margin-right: -1px;
  position: relative;
  width: 100%;
}

.recruit .CONTACT-26 {
  align-items: center;
  align-self: stretch;
  background-color: var(--backwhite);
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 40px;
  padding: 100px 15px;
  position: relative;
  width: 100%;
}

.recruit .CONTACT-27 {
  height: 160px;
  position: relative;
  width: 700px;
}

.recruit .frame-321 {
  align-items: center;
  background-color: var(--purple);
  border-radius: 6px;
  display: flex;
  flex-wrap: wrap;
  gap: 20px 74px;
  justify-content: center;
  max-width: 700px;
  padding: 50px 60px;
  position: relative;
  width: 700px;
}

.recruit .group-50 {
  height: 60px;
  position: relative;
  width: 216px;
}

.recruit .overlap-group-14 {
  height: 60px;
  position: relative;
  width: 212px;
}

.recruit .text-wrapper-420 {
  color: var(--white);
  font-family: "Zen Kaku Gothic New", Helvetica;
  font-size: 24px;
  font-weight: 500;
  left: 0;
  letter-spacing: 2.4px;
  line-height: normal;
  position: absolute;
  text-align: center;
  top: 0;
  width: 212px;
}

.recruit .text-wrapper-421 {
  color: var(--white);
  font-family: "Slackside One", Helvetica;
  font-size: 20px;
  font-weight: 400;
  left: 39px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  text-align: center;
  top: 31px;
  width: 134px;
}

.recruit .group-51 {
  height: 55px;
  position: relative;
  width: 254px;
}

.recruit .flexcontainer-43 {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  gap: 5px;
  height: 55px;
  position: relative;
  width: 252px;
}

.recruit .text-i-45 {
  align-self: stretch;
  color: var(--white);
  font-family: "Noto Sans JP", Helvetica;
  font-size: 15px;
  font-weight: 400;
  letter-spacing: 0.75px;
  line-height: 25px;
  position: relative;
}

.recruit .text-wrapper-422 {
  letter-spacing: 0.11px;
}

.recruit .footer-14 {
  align-items: center;
  align-self: stretch;
  background-color: #454545;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 50px;
  padding: 70px 20px 50px;
  position: relative;
  width: 100%;
}

.recruit .frame-322 {
  align-items: flex-start;
  display: flex;
  flex: 0 0 auto;
  flex-wrap: wrap;
  gap: 50px 150px;
  justify-content: space-between;
  padding: 0px 100px;
  position: relative;
  width: 1200px;
}

.recruit .frame-323 {
  align-items: flex-start;
  display: flex;
  flex: 1;
  flex-direction: column;
  flex-grow: 1;
  gap: 44px;
  position: relative;
}

.recruit .logo-white-8 {
  height: 54.26px !important;
  left: unset !important;
  position: relative !important;
  top: unset !important;
  width: 163.65px !important;
}

.recruit .flexcontainer-44 {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.recruit .text-i-46 {
  align-self: stretch;
  color: #ffffff;
  font-family: var(--text-font-family);
  font-size: var(--text-font-size);
  font-style: var(--text-font-style);
  font-weight: var(--text-font-weight);
  letter-spacing: var(--text-letter-spacing);
  line-height: var(--text-line-height);
  position: relative;
}

.recruit .text-wrapper-423 {
  font-family: var(--text-font-family);
  font-size: var(--text-font-size);
  font-style: var(--text-font-style);
  font-weight: var(--text-font-weight);
  letter-spacing: var(--text-letter-spacing);
  line-height: var(--text-line-height);
}

.recruit .frame-324 {
  align-items: flex-start;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 60px;
  position: relative;
}

.recruit .frame-325 {
  align-items: flex-start;
  display: inline-flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 30px;
  position: relative;
}

.recruit .frame-326 {
  align-items: flex-start;
  display: inline-flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 6px;
  position: relative;
}

.recruit .text-wrapper-424 {
  color: #ffffff;
  font-family: var(--text-bold-mini-font-family);
  font-size: var(--text-bold-mini-font-size);
  font-style: var(--text-bold-mini-font-style);
  font-weight: var(--text-bold-mini-font-weight);
  letter-spacing: var(--text-bold-mini-letter-spacing);
  line-height: var(--text-bold-mini-line-height);
  margin-top: -1px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.recruit .text-wrapper-425 {
  color: #ffffff;
  font-family: var(--text-bold-mini-font-family);
  font-size: var(--text-bold-mini-font-size);
  font-style: var(--text-bold-mini-font-style);
  font-weight: var(--text-bold-mini-font-weight);
  letter-spacing: var(--text-bold-mini-letter-spacing);
  line-height: var(--text-bold-mini-line-height);
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.recruit .text-wrapper-426 {
  color: #ffffff;
  display: block;
  font-family: var(--text-bold-mini-font-family);
  font-size: var(--text-bold-mini-font-size);
  font-style: var(--text-bold-mini-font-style);
  font-weight: var(--text-bold-mini-font-weight);
  letter-spacing: var(--text-bold-mini-letter-spacing);
  line-height: var(--text-bold-mini-line-height);
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.recruit .text-wrapper-427 {
  color: #ffffff;
  font-family: "Manrope", Helvetica;
  font-size: 13.1px;
  font-weight: 400;
  letter-spacing: 1.31px;
  line-height: normal;
  position: relative;
  width: fit-content;
}
