.line-wrapper {
  height: 1px;
  position: relative;
  width: 80px;
}

.line-wrapper .line-4 {
  height: 2px;
  left: 0;
  position: absolute;
  top: -2px;
  width: 80px;
}
