.element-wrapper {
  align-items: flex-start;
  background-image: url(../../../static/img/1.png);
  background-position: 50% 50%;
  background-size: cover;
  gap: 10px;
  justify-content: center;
  position: relative;
}

.element-wrapper .heading-instance {
  position: relative !important;
}

.element-wrapper.one-1-two {
  display: inline-flex;
  padding: 10px 0px;
}

.element-wrapper.one-1-one {
  display: flex;
  padding: 20px 0px 50px;
  width: 1200px;
}
