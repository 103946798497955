.blogs {
  align-items: center;
  background-color: var(--backwhite);
  display: flex;
  flex-direction: column;
  position: relative;
}

.blogs .header-31 {
  align-self: stretch !important;
  width: 100% !important;
  z-index: 1 !important;
}

.blogs .menu-11 {
  height: 35px !important;
  margin-bottom: -10px !important;
  position: relative !important;
  width: 36px !important;
}

.blogs .header-32 {
  align-self: stretch !important;
  display: flex !important;
  flex: 0 0 auto !important;
  justify-content: space-between !important;
  width: 100% !important;
  z-index: 1 !important;
}

.blogs .header-33 {
  z-index: 1 !important;
}

.blogs .header-34 {
  height: 59px !important;
  position: relative !important;
  width: 60px !important;
  z-index: 0 !important;
}

.blogs .div-21 {
  align-items: flex-start;
  align-self: stretch;
  background-color: #ffffff;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  position: relative;
  width: 100%;
  z-index: 0;
}

.blogs .midashi-7 {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  position: relative;
  width: 100%;
}

.blogs .class-115 {
  color: #ffffff !important;
}

.blogs .class-116 {
  color: #ffffff !important;
  left: 28px !important;
}

.blogs .class-117 {
  color: #ffffff !important;
  left: 42px !important;
}

.blogs .class-118 {
  align-self: stretch !important;
  display: flex !important;
  flex: 0 0 auto !important;
  padding: 5px 0px !important;
  width: 100% !important;
}

.blogs .class-119 {
  align-self: stretch !important;
  flex: 0 0 auto !important;
  width: 100% !important;
}

.blogs .breadcrumbs-10 {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  gap: 30px;
  justify-content: center;
  position: relative;
  width: 100%;
}

.blogs .frame-279 {
  align-items: center;
  display: flex;
  flex: 1;
  flex-grow: 1;
  position: relative;
}

.blogs .text-wrapper-344 {
  color: var(--gray);
  display: block;
  font-family: var(--smalltext-SP-font-family);
  font-size: var(--smalltext-SP-font-size);
  font-style: var(--smalltext-SP-font-style);
  font-weight: var(--smalltext-SP-font-weight);
  letter-spacing: var(--smalltext-SP-letter-spacing);
  line-height: var(--smalltext-SP-line-height);
  margin-top: -1px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.blogs .instance-node-9 {
  height: 8.14px !important;
  position: relative !important;
  width: 4.57px !important;
}

.blogs .TOP-5 {
  color: var(--gray);
  margin-top: -1px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.blogs .text-wrapper-345 {
  color: var(--gray);
  font-family: var(--tag-font-family);
  font-size: var(--tag-font-size);
  font-style: var(--tag-font-style);
  font-weight: var(--tag-font-weight);
  letter-spacing: var(--tag-letter-spacing);
  line-height: var(--tag-line-height);
  margin-top: -1px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.blogs .div-22 {
  align-items: center;
  align-self: stretch;
  background-color: var(--palepurple);
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 40px;
  position: relative;
  width: 100%;
}

.blogs .frame-280 {
  align-items: center;
  flex: 0 0 auto;
  flex-direction: column;
  position: relative;
}

.blogs .frame-281 {
  align-items: flex-start;
  flex: 0 0 auto;
  position: relative;
}

.blogs .frame-282 {
  position: relative;
}

.blogs .text-wrapper-346 {
  color: var(--purple);
  white-space: nowrap;
}

.blogs .line-16 {
  height: 3px;
}

.blogs .text-wrapper-347 {
  color: var(--purple);
  margin-top: -1px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.blogs .text-wrapper-348 {
  color: var(--purple);
  margin-top: -1px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.blogs .text-wrapper-349 {
  color: var(--purple);
  margin-top: -1px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.blogs .text-wrapper-350 {
  color: var(--purple);
  margin-top: -1px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.blogs .text-wrapper-351 {
  color: var(--purple);
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.blogs .text-wrapper-352 {
  color: var(--purple);
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.blogs .text-wrapper-353 {
  color: var(--purple);
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.blogs .frame-283 {
  align-items: flex-start;
  flex: 0 0 auto;
  flex-wrap: wrap;
  justify-content: center;
  position: relative;
}

.blogs .class-120 {
  gap: 8px !important;
  height: unset !important;
}

.blogs .class-121 {
  gap: 8px !important;
}

.blogs .class-122 {
  flex: 0 0 auto !important;
  flex-grow: unset !important;
}

.blogs .class-123 {
  font-family: var(--smalltext-SP-font-family) !important;
  font-size: var(--smalltext-SP-font-size) !important;
  font-style: var(--smalltext-SP-font-style) !important;
  font-weight: var(--smalltext-SP-font-weight) !important;
  letter-spacing: var(--smalltext-SP-letter-spacing) !important;
  line-height: var(--smalltext-SP-line-height) !important;
}

.blogs .class-124 {
  flex: 1 !important;
  flex-grow: 1 !important;
  max-width: 360px !important;
  min-width: 290px !important;
  width: unset !important;
}

.blogs .class-125 {
  max-width: 360px !important;
  width: 360px !important;
}

.blogs .class-126 {
  font-family: var(--smalltextbold-SP-font-family) !important;
  font-size: var(--smalltextbold-SP-font-size) !important;
  font-style: var(--smalltextbold-SP-font-style) !important;
  font-weight: var(--smalltextbold-SP-font-weight) !important;
  letter-spacing: var(--smalltextbold-SP-letter-spacing) !important;
  line-height: var(--smalltextbold-SP-line-height) !important;
}

.blogs .noimage-5 {
  height: 25.92px !important;
  position: relative !important;
  width: 190.9px !important;
}

.blogs .frame-284 {
  position: relative;
}

.blogs .polygon-3-3 {
  height: 11.13px !important;
  margin-left: -1.15px !important;
  position: relative !important;
  width: 7.41px !important;
}

.blogs .text-wrapper-354 {
  color: #454545;
  font-family: var(--tag-font-family);
  font-size: var(--tag-font-size);
  font-style: var(--tag-font-style);
  font-weight: var(--tag-font-weight);
  letter-spacing: var(--tag-letter-spacing);
  line-height: var(--tag-line-height);
  margin-top: -1px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.blogs .arrow-5 {
  height: 11.13px !important;
  margin-right: -1.15px !important;
  position: relative !important;
  width: 7.41px !important;
}

.blogs .text-wrapper-355 {
  color: #454545;
  font-family: var(--tag-font-family);
  font-size: var(--tag-font-size);
  font-style: var(--tag-font-style);
  font-weight: var(--tag-font-weight);
  left: 29px;
  letter-spacing: var(--tag-letter-spacing);
  line-height: var(--tag-line-height);
  position: absolute;
  top: -1px;
  white-space: nowrap;
}

.blogs .text-wrapper-356 {
  color: #454545;
  font-family: var(--tag-font-family);
  font-size: var(--tag-font-size);
  font-style: var(--tag-font-style);
  font-weight: var(--tag-font-weight);
  left: 57px;
  letter-spacing: var(--tag-letter-spacing);
  line-height: var(--tag-line-height);
  position: absolute;
  top: -1px;
  white-space: nowrap;
}

.blogs .text-wrapper-357 {
  color: #454545;
  font-family: var(--tag-font-family);
  font-size: var(--tag-font-size);
  font-style: var(--tag-font-style);
  font-weight: var(--tag-font-weight);
  left: 85px;
  letter-spacing: var(--tag-letter-spacing);
  line-height: var(--tag-line-height);
  position: absolute;
  top: -1px;
  white-space: nowrap;
}

.blogs .text-wrapper-358 {
  color: #454545;
  font-family: var(--tag-font-family);
  font-size: var(--tag-font-size);
  font-style: var(--tag-font-style);
  font-weight: var(--tag-font-weight);
  left: 113px;
  letter-spacing: var(--tag-letter-spacing);
  line-height: var(--tag-line-height);
  position: absolute;
  top: -1px;
  white-space: nowrap;
}

.blogs .text-wrapper-359 {
  color: #454545;
  font-family: var(--tag-font-family);
  font-size: var(--tag-font-size);
  font-style: var(--tag-font-style);
  font-weight: var(--tag-font-weight);
  left: 141px;
  letter-spacing: var(--tag-letter-spacing);
  line-height: var(--tag-line-height);
  position: absolute;
  top: -1px;
  white-space: nowrap;
}

.blogs .text-wrapper-360 {
  color: #454545;
  font-family: var(--tag-font-family);
  font-size: var(--tag-font-size);
  font-style: var(--tag-font-style);
  font-weight: var(--tag-font-weight);
  left: 169px;
  letter-spacing: var(--tag-letter-spacing);
  line-height: var(--tag-line-height);
  position: absolute;
  top: -1px;
  white-space: nowrap;
}

.blogs .polygon-2-8 {
  height: 16px !important;
  left: 197px !important;
  position: absolute !important;
  top: 0 !important;
  width: 10px !important;
}

.blogs .polygon-3-5 {
  height: 16px !important;
  left: 0 !important;
  position: absolute !important;
  top: 0 !important;
  width: 10px !important;
}

.blogs .CONTACT-22 {
  align-items: center;
  background-color: var(--backwhite);
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 40px;
  position: relative;
}

.blogs .CONTACT-23 {
  display: block;
  height: 160px;
  position: relative;
}

.blogs .frame-285 {
  align-items: center;
  background-color: var(--purple);
  border-radius: 6px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  max-width: 700px;
  position: relative;
}

.blogs .group-38 {
  position: relative;
  width: 216px;
}

.blogs .text-wrapper-361 {
  color: var(--white);
  font-family: "Zen Kaku Gothic New", Helvetica;
  font-size: 20px;
  font-weight: 500;
  left: 0;
  letter-spacing: 2px;
  line-height: normal;
  position: absolute;
  text-align: center;
  top: 0;
  width: 212px;
}

.blogs .text-wrapper-362 {
  color: var(--white);
  font-family: "Slackside One", Helvetica;
  font-size: 15px;
  font-weight: 400;
  left: 39px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  text-align: center;
  top: 31px;
  width: 134px;
}

.blogs .overlap-group-12 {
  height: 60px;
  position: relative;
  width: 212px;
}

.blogs .text-wrapper-363 {
  color: var(--white);
  font-family: "Zen Kaku Gothic New", Helvetica;
  font-size: 24px;
  font-weight: 500;
  left: 0;
  letter-spacing: 2.4px;
  line-height: normal;
  position: absolute;
  text-align: center;
  top: 0;
  width: 212px;
}

.blogs .text-wrapper-364 {
  color: var(--white);
  font-family: "Slackside One", Helvetica;
  font-size: 20px;
  font-weight: 400;
  left: 39px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  text-align: center;
  top: 31px;
  width: 134px;
}

.blogs .group-39 {
  position: relative;
}

.blogs .flexcontainer-37 {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  position: relative;
}

.blogs .text-i-37 {
  align-self: stretch;
  color: var(--white);
  position: relative;
}

.blogs .text-i-38 {
  align-self: stretch;
  color: var(--white);
  position: relative;
}

.blogs .footer-12 {
  align-items: center;
  align-self: stretch;
  background-color: #454545;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 50px;
  position: relative;
  width: 100%;
}

.blogs .frame-286 {
  align-items: flex-start;
  display: flex;
  flex: 0 0 auto;
  position: relative;
}

.blogs .frame-287 {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  position: relative;
}

.blogs .logo-white-19 {
  height: 40px !important;
  position: relative !important;
  width: 121px !important;
}

.blogs .logo-white-14 {
  height: 54.26px !important;
  position: relative !important;
  width: 163.65px !important;
}

.blogs .flexcontainer-38 {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
}

.blogs .text-i-39 {
  align-self: stretch;
  color: #ffffff;
  position: relative;
}

.blogs .text-i-40 {
  align-self: stretch;
  color: #ffffff;
  position: relative;
}

.blogs .frame-288 {
  align-items: flex-start;
  flex: 0 0 auto;
  position: relative;
}

.blogs .frame-289 {
  align-items: flex-start;
  display: inline-flex;
  flex: 0 0 auto;
  flex-direction: column;
  position: relative;
}

.blogs .frame-290 {
  align-items: flex-start;
  display: inline-flex;
  flex: 0 0 auto;
  flex-direction: column;
  position: relative;
}

.blogs .text-wrapper-365 {
  color: #ffffff;
  display: block;
  margin-top: -1px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.blogs .text-wrapper-366 {
  color: #ffffff;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.blogs .text-wrapper-367 {
  color: #ffffff;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.blogs .text-wrapper-368 {
  color: #ffffff;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.blogs .frame-291 {
  align-items: flex-start;
  display: inline-flex;
  flex: 0 0 auto;
  flex-direction: column;
  position: relative;
}

.blogs .text-wrapper-369 {
  color: #ffffff;
  display: block;
  margin-top: -1px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.blogs .text-wrapper-370 {
  color: #ffffff;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.blogs .text-wrapper-371 {
  color: #ffffff;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.blogs .frame-292 {
  align-items: flex-start;
  display: inline-flex;
  flex: 0 0 auto;
  flex-direction: column;
  position: relative;
}

.blogs .text-wrapper-372 {
  color: #ffffff;
  display: block;
  margin-top: -1px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.blogs .text-wrapper-373 {
  color: #ffffff;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.blogs .text-wrapper-374 {
  color: #ffffff;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.blogs .frame-293 {
  align-items: flex-start;
  flex-direction: column;
  gap: 30px;
  position: relative;
}

.blogs .text-wrapper-375 {
  color: #ffffff;
  display: block;
  margin-top: -1px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.blogs .text-wrapper-376 {
  color: #ffffff;
  display: block;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.blogs .text-wrapper-377 {
  color: #ffffff;
  display: block;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.blogs .text-wrapper-378 {
  color: #ffffff;
  display: block;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.blogs .text-wrapper-379 {
  color: #ffffff;
  display: block;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.blogs .text-wrapper-380 {
  color: #ffffff;
  font-family: "Manrope", Helvetica;
  font-size: 13.1px;
  font-weight: 400;
  letter-spacing: 1.31px;
  line-height: normal;
  position: relative;
  width: fit-content;
}
