.blog-screen {
  align-items: center;
  display: flex;
  flex-direction: column;
  position: relative;
}

.blog-screen .header-5 {
  align-self: stretch !important;
  flex: 0 0 auto !important;
  height: unset !important;
  width: 100% !important;
  z-index: 1 !important;
}

.blog-screen .header-6 {
  margin-bottom: unset !important;
}

.blog-screen .menu-2 {
  height: 35px !important;
  position: relative !important;
  width: 36px !important;
}

.blog-screen .blog {
  align-items: flex-start;
  background-color: #ffffff;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  overflow: hidden;
  position: relative;
  width: 320px;
  z-index: 0;
}

.blog-screen .div-11 {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  position: relative;
  width: 100%;
}

.blog-screen .heading-16 {
  align-self: stretch !important;
  display: flex !important;
  flex: 0 0 auto !important;
  padding: 5px 0px !important;
  width: 100% !important;
}

.blog-screen .heading-17 {
  color: #ffffff !important;
}

.blog-screen .heading-18 {
  color: #ffffff !important;
  left: 42px !important;
}

.blog-screen .breadcrumbs-2 {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  gap: 30px;
  justify-content: center;
  padding: 10px 15px 20px;
  position: relative;
  width: 100%;
}

.blog-screen .frame-94 {
  align-items: center;
  display: flex;
  flex: 1;
  flex-grow: 1;
  gap: 17px;
  position: relative;
}

.blog-screen .text-wrapper-81 {
  color: var(--gray);
  display: block;
  font-family: var(--smalltext-SP-font-family);
  font-size: var(--smalltext-SP-font-size);
  font-style: var(--smalltext-SP-font-style);
  font-weight: var(--smalltext-SP-font-weight);
  letter-spacing: var(--smalltext-SP-letter-spacing);
  line-height: var(--smalltext-SP-line-height);
  margin-top: -1px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.blog-screen .instance-node-4 {
  height: 8.14px !important;
  position: relative !important;
  width: 4.57px !important;
}

.blog-screen .text-wrapper-82 {
  color: var(--gray);
  font-family: var(--smalltext-SP-font-family);
  font-size: var(--smalltext-SP-font-size);
  font-style: var(--smalltext-SP-font-style);
  font-weight: var(--smalltext-SP-font-weight);
  letter-spacing: var(--smalltext-SP-letter-spacing);
  line-height: var(--smalltext-SP-line-height);
  margin-top: -1px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.blog-screen .view {
  align-items: center;
  align-self: stretch;
  background-color: var(--palepurple);
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 60px;
  padding: 40px 10px;
  position: relative;
  width: 100%;
}

.blog-screen .frame-95 {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 20px;
  justify-content: center;
  position: relative;
  width: 100%;
}

.blog-screen .frame-96 {
  align-items: flex-start;
  align-self: stretch;
  background-color: var(--back-white);
  border-radius: 14px;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 15px;
  padding: 20px 15px;
  position: relative;
  width: 100%;
}

.blog-screen .frame-97 {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  max-width: 800px;
  padding: 0px 5px;
  position: relative;
  width: 100%;
}

.blog-screen .group-11 {
  height: 18.74px;
  position: relative;
  width: 93px;
}

.blog-screen .text-wrapper-83 {
  color: var(--black);
  font-family: "Manrope", Helvetica;
  font-size: 12px;
  font-weight: 400;
  left: 0;
  letter-spacing: 1.2px;
  line-height: normal;
  position: absolute;
  top: 0;
  width: 91px;
}

.blog-screen .frame-98 {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  flex-wrap: wrap;
  gap: 0px 10px;
  position: relative;
  width: 100%;
}

.blog-screen .text-wrapper-84 {
  color: var(--purple);
  font-family: "Noto Sans JP", Helvetica;
  font-size: 10px;
  font-weight: 500;
  letter-spacing: 0.2px;
  line-height: 18px;
  margin-top: -1px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.blog-screen .text-wrapper-85 {
  color: var(--purple);
  font-family: "Noto Sans JP", Helvetica;
  font-size: 10px;
  font-weight: 500;
  height: 17px;
  letter-spacing: 0.2px;
  line-height: 18px;
  margin-top: -1px;
  position: relative;
  white-space: nowrap;
  width: 43px;
}

.blog-screen .text-wrapper-86 {
  color: var(--purple);
  font-family: "Noto Sans JP", Helvetica;
  font-size: 10px;
  font-weight: 500;
  height: 17px;
  letter-spacing: 0.2px;
  line-height: 18px;
  position: relative;
  white-space: nowrap;
  width: 43px;
}

.blog-screen .text-wrapper-87 {
  align-self: stretch;
  color: #454545;
  font-family: "Noto Sans JP", Helvetica;
  font-size: 14px;
  font-weight: 700;
  letter-spacing: 0.28px;
  line-height: 24px;
  position: relative;
}

.blog-screen .frame-99 {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 10px;
  position: relative;
  width: 100%;
}

.blog-screen .grayline-instance {
  align-self: stretch !important;
  background-image: url(../../../static/img/line-13-2.svg) !important;
  margin-top: -1px !important;
  top: unset !important;
  width: 100% !important;
}

.blog-screen .noimage-23-wrapper {
  align-items: center;
  align-self: stretch;
  background-color: var(--gray);
  border-radius: 6px;
  display: flex;
  flex-direction: column;
  gap: 10px;
  height: 233.92px;
  justify-content: center;
  overflow: hidden;
  padding: 104px 70px;
  position: relative;
  width: 100%;
}

.blog-screen .noimage-3 {
  height: 25.92px !important;
  margin-left: -33.35px !important;
  margin-right: -33.35px !important;
  position: relative !important;
  width: 196.71px !important;
}

.blog-screen .noimage-24-wrapper {
  align-items: center;
  align-self: stretch;
  background-color: var(--gray);
  border-radius: 6px;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 10px;
  justify-content: center;
  overflow: hidden;
  padding: 104px 70px;
  position: relative;
  width: 100%;
}

.blog-screen .text-wrapper-88 {
  align-self: stretch;
  color: #454545;
  font-family: var(--text-SP-font-family);
  font-size: var(--text-SP-font-size);
  font-style: var(--text-SP-font-style);
  font-weight: var(--text-SP-font-weight);
  letter-spacing: var(--text-SP-letter-spacing);
  line-height: var(--text-SP-line-height);
  position: relative;
}

.blog-screen .frame-100 {
  align-items: center;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  justify-content: space-between;
  padding: 0px 5px;
  position: relative;
  width: 100%;
}

.blog-screen .group-12 {
  height: 23px;
  position: relative;
  width: 82.68px;
}

.blog-screen .overlap-group-5 {
  height: 23px;
  position: relative;
  width: 81px;
}

.blog-screen .text-wrapper-89 {
  color: var(--darkgray);
  font-family: var(--text-SP-font-family);
  font-size: var(--text-SP-font-size);
  font-style: var(--text-SP-font-style);
  font-weight: var(--text-SP-font-weight);
  height: 23px;
  left: 0;
  letter-spacing: var(--text-SP-letter-spacing);
  line-height: var(--text-SP-line-height);
  position: absolute;
  text-align: right;
  top: 0;
  width: 81px;
}

.blog-screen .arrow-31 {
  height: 11px !important;
  left: 1px !important;
  position: absolute !important;
  top: 6px !important;
  width: 9px !important;
}

.blog-screen .frame-instance {
  display: flex !important;
  height: 40px !important;
  padding: 17px 30px !important;
  width: 100px !important;
}

.blog-screen .frame-36-instance {
  font-family: var(--button-SP-font-family) !important;
  font-size: var(--button-SP-font-size) !important;
  font-style: var(--button-SP-font-style) !important;
  font-weight: var(--button-SP-font-weight) !important;
  letter-spacing: var(--button-SP-letter-spacing) !important;
  line-height: var(--button-SP-line-height) !important;
  margin-bottom: -3.5px !important;
  margin-top: -7.5px !important;
  white-space: unset !important;
}

.blog-screen .group-13 {
  height: 23px;
  margin-right: -2px;
  position: relative;
  width: 84px;
}

.blog-screen .text-wrapper-90 {
  color: var(--darkgray);
  font-family: var(--text-SP-font-family);
  font-size: var(--text-SP-font-size);
  font-style: var(--text-SP-font-style);
  font-weight: var(--text-SP-font-weight);
  height: 23px;
  left: 0;
  letter-spacing: var(--text-SP-letter-spacing);
  line-height: var(--text-SP-line-height);
  position: absolute;
  text-align: right;
  top: 0;
  width: 66px;
}

.blog-screen .arrow-38 {
  height: 11px !important;
  left: 76px !important;
  position: absolute !important;
  top: 6px !important;
  width: 7px !important;
}

.blog-screen .frame-101 {
  align-items: center;
  align-self: stretch;
  background-color: #6079c1;
  border-radius: 10px 10px 0px 0px;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 30px;
  padding: 10px 15px;
  position: relative;
  width: 100%;
}

.blog-screen .text-wrapper-91 {
  color: #ffffff;
  font-family: var(--smalltextbold-SP-font-family);
  font-size: var(--smalltextbold-SP-font-size);
  font-style: var(--smalltextbold-SP-font-style);
  font-weight: var(--smalltextbold-SP-font-weight);
  letter-spacing: var(--smalltextbold-SP-letter-spacing);
  line-height: var(--smalltextbold-SP-line-height);
  margin-top: -1px;
  position: relative;
  text-align: center;
  white-space: nowrap;
  width: fit-content;
}

.blog-screen .frame-102 {
  align-items: center;
  align-self: stretch;
  background-color: #ffffff;
  border-radius: 0px 0px 10px 10px;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 17px;
  padding: 20px 15px;
  position: relative;
  width: 100%;
}

.blog-screen .frame-103 {
  align-items: center;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  justify-content: space-between;
  padding: 0px 10px;
  position: relative;
  width: 100%;
}

.blog-screen .text-wrapper-92 {
  color: var(--darkgray);
  flex: 1;
  font-family: var(--smalltextbold-SP-font-family);
  font-size: var(--smalltextbold-SP-font-size);
  font-style: var(--smalltextbold-SP-font-style);
  font-weight: var(--smalltextbold-SP-font-weight);
  letter-spacing: var(--smalltextbold-SP-letter-spacing);
  line-height: var(--smalltextbold-SP-line-height);
  margin-top: -1px;
  position: relative;
}

.blog-screen .arrow-6 {
  height: 11.13px !important;
  margin-left: -0.49px !important;
  margin-right: -1.15px !important;
  position: relative !important;
  width: 7.41px !important;
}

.blog-screen .line-6 {
  align-self: stretch;
  height: 1px;
  margin-left: -0.5px;
  margin-right: -0.5px;
  object-fit: cover;
  position: relative;
  width: 100%;
}

.blog-screen .frame-104 {
  align-items: center;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  justify-content: space-between;
  padding: 0px 10px;
  position: relative;
  width: 100%;
}

.blog-screen .frame-105 {
  align-items: center;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  justify-content: space-between;
  padding: 0px 10px;
  position: relative;
  width: 100%;
}

.blog-screen .frame-106 {
  align-items: center;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  justify-content: space-between;
  padding: 0px 10px;
  position: relative;
  width: 100%;
}

.blog-screen .frame-107 {
  align-items: center;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  justify-content: space-between;
  padding: 0px 10px;
  position: relative;
  width: 100%;
}

.blog-screen .CONTACT-6 {
  align-items: center;
  align-self: stretch;
  background-color: var(--backwhite);
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 40px;
  justify-content: center;
  padding: 80px 30px;
  position: relative;
  width: 100%;
}

.blog-screen .CONTACT-7 {
  align-self: stretch;
  height: 160px;
  position: relative;
  width: 100%;
}

.blog-screen .frame-108 {
  align-items: center;
  background-color: var(--purple);
  border-radius: 6px;
  display: flex;
  flex-wrap: wrap;
  gap: 20px 74px;
  justify-content: center;
  left: -10px;
  max-width: 700px;
  padding: 25px 60px;
  position: relative;
  top: -5px;
  width: 280px;
}

.blog-screen .group-14 {
  height: 53px;
  margin-left: -26px;
  margin-right: -30px;
  position: relative;
  width: 216px;
}

.blog-screen .text-wrapper-93 {
  color: var(--white);
  font-family: "Zen Kaku Gothic New", Helvetica;
  font-size: 20px;
  font-weight: 500;
  left: 0;
  letter-spacing: 2px;
  line-height: normal;
  position: absolute;
  text-align: center;
  top: 0;
  width: 212px;
}

.blog-screen .text-wrapper-94 {
  color: var(--white);
  font-family: "Slackside One", Helvetica;
  font-size: 15px;
  font-weight: 400;
  left: 39px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  text-align: center;
  top: 31px;
  width: 134px;
}

.blog-screen .group-15 {
  height: 48px;
  margin-left: -25px;
  margin-right: -27px;
  position: relative;
  width: 212px;
}

.blog-screen .flexcontainer-12 {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  gap: 2px;
  height: 48px;
  position: relative;
  width: 210px;
}

.blog-screen .text-i-13 {
  align-self: stretch;
  color: var(--white);
  font-family: var(--text-SP-font-family);
  font-size: var(--text-SP-font-size);
  font-style: var(--text-SP-font-style);
  font-weight: var(--text-SP-font-weight);
  letter-spacing: var(--text-SP-letter-spacing);
  line-height: var(--text-SP-line-height);
  position: relative;
}

.blog-screen .text-wrapper-95 {
  font-family: var(--text-SP-font-family);
  font-size: var(--text-SP-font-size);
  font-style: var(--text-SP-font-style);
  font-weight: var(--text-SP-font-weight);
  letter-spacing: var(--text-SP-letter-spacing);
  line-height: var(--text-SP-line-height);
}

.blog-screen .footer-4 {
  align-items: center;
  align-self: stretch;
  background-color: #454545;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 50px;
  padding: 50px 20px;
  position: relative;
  width: 100%;
}

.blog-screen .frame-109 {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 54px;
  position: relative;
  width: 100%;
}

.blog-screen .frame-110 {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 24px;
  position: relative;
  width: 100%;
}

.blog-screen .logo-white-12 {
  height: 40px !important;
  position: relative !important;
  width: 121px !important;
}

.blog-screen .flexcontainer-13 {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  gap: 3px;
}

.blog-screen .text-i-14 {
  align-self: stretch;
  color: #ffffff;
  font-family: "Noto Sans JP", Helvetica;
  font-size: 13px;
  font-weight: 400;
  letter-spacing: 0.26px;
  line-height: 18px;
  position: relative;
}

.blog-screen .text-wrapper-96 {
  letter-spacing: 0.03px;
}

.blog-screen .frame-111 {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  gap: 40px;
  position: relative;
  width: 100%;
}

.blog-screen .frame-112 {
  align-items: flex-start;
  display: inline-flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 30px;
  position: relative;
}

.blog-screen .frame-113 {
  align-items: flex-start;
  display: inline-flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 4px;
  position: relative;
}

.blog-screen .text-wrapper-97 {
  color: #ffffff;
  display: block;
  font-family: var(--text-bold-mini-font-family);
  font-size: var(--text-bold-mini-font-size);
  font-style: var(--text-bold-mini-font-style);
  font-weight: var(--text-bold-mini-font-weight);
  letter-spacing: var(--text-bold-mini-letter-spacing);
  line-height: var(--text-bold-mini-line-height);
  margin-top: -1px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.blog-screen .text-wrapper-98 {
  color: #ffffff;
  font-family: var(--text-bold-mini-font-family);
  font-size: var(--text-bold-mini-font-size);
  font-style: var(--text-bold-mini-font-style);
  font-weight: var(--text-bold-mini-font-weight);
  letter-spacing: var(--text-bold-mini-letter-spacing);
  line-height: var(--text-bold-mini-line-height);
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.blog-screen .text-wrapper-99 {
  color: #ffffff;
  font-family: var(--text-bold-mini-font-family);
  font-size: var(--text-bold-mini-font-size);
  font-style: var(--text-bold-mini-font-style);
  font-weight: var(--text-bold-mini-font-weight);
  letter-spacing: var(--text-bold-mini-letter-spacing);
  line-height: var(--text-bold-mini-line-height);
  margin-top: -1px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.blog-screen .frame-114 {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex: 1;
  flex-direction: column;
  flex-grow: 1;
  gap: 30px;
  position: relative;
}

.blog-screen .text-wrapper-100 {
  color: #ffffff;
  display: block;
  font-family: var(--text-bold-mini-font-family);
  font-size: var(--text-bold-mini-font-size);
  font-style: var(--text-bold-mini-font-style);
  font-weight: var(--text-bold-mini-font-weight);
  letter-spacing: var(--text-bold-mini-letter-spacing);
  line-height: var(--text-bold-mini-line-height);
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.blog-screen .text-wrapper-101 {
  color: #ffffff;
  font-family: "Manrope", Helvetica;
  font-size: 13.1px;
  font-weight: 400;
  letter-spacing: 1.31px;
  line-height: normal;
  position: relative;
  width: fit-content;
}

.blog-screen .class-44 {
  height: 59px !important;
  position: relative !important;
  width: 60px !important;
  z-index: 0 !important;
}

.blog-screen .header-7 {
  align-self: stretch !important;
  display: flex !important;
  flex: 0 0 auto !important;
  justify-content: space-between !important;
  width: 100% !important;
  z-index: 1 !important;
}

.blog-screen .class-45 {
  z-index: 1 !important;
}

.blog-screen .blog-2 {
  align-items: flex-start;
  align-self: stretch;
  background-color: #ffffff;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  position: relative;
  width: 100%;
  z-index: 0;
}

.blog-screen .heading-19 {
  color: #ffffff !important;
  left: 28px !important;
}

.blog-screen .class-46 {
  align-self: stretch !important;
  background-image: url(../../../static/img/midashi-1.png) !important;
  flex: 0 0 auto !important;
  width: 100% !important;
}

.blog-screen .class-47 {
  align-self: stretch !important;
  flex: 0 0 auto !important;
  width: 100% !important;
}

.blog-screen .breadcrumbs-3 {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  gap: 30px;
  justify-content: center;
  position: relative;
  width: 100%;
}

.blog-screen .frame-115 {
  align-items: center;
  display: flex;
  flex: 1;
  flex-grow: 1;
  gap: 30px;
  position: relative;
}

.blog-screen .text-wrapper-102 {
  color: var(--gray);
  display: block;
  font-family: var(--tag-font-family);
  font-size: var(--tag-font-size);
  font-style: var(--tag-font-style);
  font-weight: var(--tag-font-weight);
  letter-spacing: var(--tag-letter-spacing);
  line-height: var(--tag-line-height);
  margin-top: -1px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.blog-screen .component-10930-instance {
  height: 8.14px !important;
  left: unset !important;
  position: relative !important;
  top: unset !important;
  width: 4.57px !important;
}

.blog-screen .div-12 {
  color: var(--gray);
  font-family: var(--tag-font-family);
  font-size: var(--tag-font-size);
  font-style: var(--tag-font-style);
  font-weight: var(--tag-font-weight);
  letter-spacing: var(--tag-letter-spacing);
  line-height: var(--tag-line-height);
  margin-top: -1px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.blog-screen .view-2 {
  align-items: center;
  align-self: stretch;
  background-color: var(--palepurple);
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 40px;
  padding: 80px 15px;
  position: relative;
  width: 100%;
}

.blog-screen .frame-116 {
  display: flex;
  flex: 0 0 auto;
  position: relative;
}

.blog-screen .frame-117 {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative;
}

.blog-screen .frame-118 {
  align-items: flex-start;
  background-color: var(--back-white);
  border-radius: 14px;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  position: relative;
}

.blog-screen .frame-119 {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  gap: 30px;
  max-width: 800px;
  position: relative;
  width: 100%;
}

.blog-screen .text-wrapper-103 {
  color: var(--black);
  font-family: "Manrope-Medium", Helvetica;
  font-size: 13.1px;
  font-weight: 500;
  left: 0;
  letter-spacing: 1.31px;
  line-height: normal;
  position: absolute;
  top: 0;
  width: 91px;
}

.blog-screen .frame-120 {
  align-items: flex-end;
  display: flex;
  flex: 1;
  flex-grow: 1;
  flex-wrap: wrap;
  gap: 0px 28px;
  position: relative;
}

.blog-screen .text-wrapper-104 {
  color: var(--purple);
  font-family: var(--tag-font-family);
  font-size: var(--tag-font-size);
  font-style: var(--tag-font-style);
  font-weight: var(--tag-font-weight);
  letter-spacing: var(--tag-letter-spacing);
  line-height: var(--tag-line-height);
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.blog-screen .text-wrapper-105 {
  color: var(--purple);
  font-family: var(--tag-font-family);
  font-size: var(--tag-font-size);
  font-style: var(--tag-font-style);
  font-weight: var(--tag-font-weight);
  height: 17px;
  letter-spacing: var(--tag-letter-spacing);
  line-height: var(--tag-line-height);
  margin-top: -1px;
  position: relative;
  white-space: nowrap;
  width: 43px;
}

.blog-screen .frame-121 {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  gap: 30px;
  max-width: 800px;
  padding: 0px 5px;
  position: relative;
  width: 100%;
}

.blog-screen .text-wrapper-106 {
  color: var(--black);
  font-family: var(--date-font-family);
  font-size: var(--date-font-size);
  font-style: var(--date-font-style);
  font-weight: var(--date-font-weight);
  left: 0;
  letter-spacing: var(--date-letter-spacing);
  line-height: var(--date-line-height);
  position: absolute;
  top: 0;
  width: 91px;
}

.blog-screen .div-13 {
  align-self: stretch;
  color: #454545;
  font-family: "Noto Sans JP", Helvetica;
  font-size: 20px;
  font-weight: 700;
  letter-spacing: 1px;
  line-height: 20px;
  position: relative;
}

.blog-screen .text-wrapper-107 {
  letter-spacing: 0.2px;
  line-height: 37px;
}

.blog-screen .text-wrapper-108 {
  letter-spacing: 0.2px;
  line-height: 36px;
}

.blog-screen .frame-122 {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 16px;
  position: relative;
  width: 100%;
}

.blog-screen .grayline-2 {
  align-self: stretch !important;
  background-image: url(../../../static/img/line-13-1-2.svg) !important;
  margin-top: -1px !important;
  top: unset !important;
  width: 100% !important;
}

.blog-screen .frame-123 {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  flex-wrap: wrap;
  gap: 10px 10px;
  position: relative;
  width: 100%;
}

.blog-screen .noimage-9-wrapper {
  background-color: var(--gray);
  border-radius: 6px;
  flex: 1;
  flex-grow: 1;
  height: 236.18px;
  position: relative;
}

.blog-screen .noimage-23 {
  height: 26px !important;
  left: 75px !important;
  position: absolute !important;
  top: 107px !important;
  width: 211px !important;
}

.blog-screen .group-16 {
  background-image: url(../../../static/img/rectangle-23-2.svg);
  background-size: 100% 100%;
  flex: 1;
  flex-grow: 1;
  height: 236.18px;
  position: relative;
}

.blog-screen .noimage-9 {
  height: 26px !important;
  left: 80px !important;
  position: absolute !important;
  top: 107px !important;
  width: 211px !important;
}

.blog-screen .text-wrapper-109 {
  align-self: stretch;
  color: #454545;
  font-family: var(--text-font-family);
  font-size: var(--text-font-size);
  font-style: var(--text-font-style);
  font-weight: var(--text-font-weight);
  letter-spacing: var(--text-letter-spacing);
  line-height: var(--text-line-height);
  position: relative;
}

.blog-screen .grayline-3 {
  align-self: stretch !important;
  background-image: url(../../../static/img/line-13-5-2.svg) !important;
  top: unset !important;
  width: 100% !important;
}

.blog-screen .frame-124 {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  gap: 31px;
  position: relative;
  width: 100%;
}

.blog-screen .noimage-instance-wrapper {
  align-items: center;
  align-self: stretch;
  background-color: var(--gray);
  border-radius: 6px;
  display: flex;
  flex: 1;
  flex-direction: column;
  flex-grow: 1;
  gap: 10px;
  justify-content: center;
  overflow: hidden;
  padding: 104px 70px;
  position: relative;
}

.blog-screen .noimage-instance {
  height: 25.92px !important;
  left: unset !important;
  margin-left: -7.6px !important;
  margin-right: -7.6px !important;
  position: relative !important;
  top: unset !important;
  width: 196.71px !important;
}

.blog-screen .frame-125 {
  align-items: center;
  background-color: var(--gray);
  border-radius: 6px;
  display: flex;
  flex: 1;
  flex-direction: column;
  flex-grow: 1;
  gap: 10px;
  justify-content: center;
  overflow: hidden;
  padding: 104px 70px;
  position: relative;
}

.blog-screen .frame-126 {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  justify-content: space-between;
  position: relative;
  width: 100%;
}

.blog-screen .group-17 {
  position: relative;
}

.blog-screen .text-wrapper-110 {
  color: var(--darkgray);
  font-family: "Noto Sans JP", Helvetica;
  font-size: 15px;
  font-weight: 400;
  left: 27px;
  letter-spacing: 0.75px;
  line-height: 25px;
  position: absolute;
  top: 0;
  width: 230px;
}

.blog-screen .polygon-2 {
  height: 17px !important;
  left: -1px !important;
  top: 5px !important;
  width: 13px !important;
}

.blog-screen .class-48 {
  display: flex !important;
  flex: 1 !important;
  flex-grow: 1 !important;
  margin-left: -2px !important;
  max-width: 200px !important;
  min-width: 140px !important;
  padding: 17px 30px !important;
}

.blog-screen .class-49 {
  display: flex !important;
  height: 60px !important;
  padding: 17px 30px !important;
  width: 200px !important;
}

.blog-screen .class-50 {
  font-weight: 700 !important;
}

.blog-screen .class-51 {
  font-family: var(--button-SP-font-family) !important;
  font-size: var(--button-SP-font-size) !important;
  font-style: var(--button-SP-font-style) !important;
  font-weight: var(--button-SP-font-weight) !important;
  letter-spacing: var(--button-SP-letter-spacing) !important;
  line-height: var(--button-SP-line-height) !important;
  margin-top: unset !important;
  white-space: unset !important;
}

.blog-screen .group-18 {
  margin-right: -2px;
  position: relative;
}

.blog-screen .text-wrapper-111 {
  color: var(--darkgray);
  font-family: "Noto Sans JP", Helvetica;
  font-size: 15px;
  font-weight: 400;
  left: 0;
  letter-spacing: 0.75px;
  line-height: 25px;
  position: absolute;
  text-align: right;
  top: 0;
  width: 223px;
}

.blog-screen .polygon-instance {
  height: 17px !important;
  left: 245px !important;
  top: 5px !important;
  width: 13px !important;
}

.blog-screen .view-3 {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  position: relative;
}

.blog-screen .frame-127 {
  align-items: center;
  align-self: stretch;
  background-color: #6079c1;
  border-radius: 10px 10px 0px 0px;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 30px;
  position: relative;
  width: 100%;
}

.blog-screen .text-wrapper-112 {
  color: #ffffff;
  font-family: "Noto Sans JP", Helvetica;
  font-size: 16px;
  font-weight: 700;
  letter-spacing: 0;
  line-height: normal;
  margin-top: -1px;
  position: relative;
  text-align: center;
  width: fit-content;
}

.blog-screen .frame-128 {
  align-items: center;
  align-self: stretch;
  background-color: #ffffff;
  border-radius: 0px 0px 10px 10px;
  display: flex;
  flex-direction: column;
  position: relative;
  width: 100%;
}

.blog-screen .frame-129 {
  align-items: center;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  justify-content: space-between;
  padding: 0px 10px;
  position: relative;
  width: 100%;
}

.blog-screen .text-wrapper-113 {
  color: var(--darkgray);
  flex: 1;
  font-family: "Noto Sans JP", Helvetica;
  font-size: 15px;
  letter-spacing: 0.75px;
  line-height: 26px;
  margin-top: -1px;
  position: relative;
}

.blog-screen .polygon-2-instance {
  height: 16.55px !important;
  left: unset !important;
  margin-left: -0.42px !important;
  margin-right: -0.6px !important;
  position: relative !important;
  top: unset !important;
  width: 6.25px !important;
}

.blog-screen .polygon-2-7 {
  height: 16.55px !important;
  margin-left: -0.42px !important;
  margin-right: -0.6px !important;
  position: relative !important;
  width: 6.25px !important;
}

.blog-screen .line-7 {
  align-self: stretch;
  margin-left: -1px;
  margin-right: -1px;
  position: relative;
  width: 100%;
}

.blog-screen .frame-130 {
  align-items: center;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  justify-content: space-between;
  padding: 0px 10px;
  position: relative;
  width: 100%;
}

.blog-screen .text-wrapper-114 {
  color: var(--darkgray);
  flex: 1;
  font-family: "Noto Sans JP", Helvetica;
  font-size: 15px;
  letter-spacing: 0.75px;
  line-height: 26px;
  margin-top: -1px;
  position: relative;
}

.blog-screen .line-9 {
  align-self: stretch;
  margin-left: -1px;
  margin-right: -1px;
  position: relative;
  width: 100%;
}

.blog-screen .frame-131 {
  align-items: center;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  justify-content: space-between;
  padding: 0px 10px;
  position: relative;
  width: 100%;
}

.blog-screen .text-wrapper-115 {
  color: var(--darkgray);
  flex: 1;
  font-family: "Noto Sans JP", Helvetica;
  font-size: 15px;
  letter-spacing: 0.75px;
  line-height: 26px;
  margin-top: -1px;
  position: relative;
}

.blog-screen .line-10 {
  align-self: stretch;
  margin-left: -1px;
  margin-right: -1px;
  position: relative;
  width: 100%;
}

.blog-screen .frame-132 {
  align-items: center;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  justify-content: space-between;
  padding: 0px 10px;
  position: relative;
  width: 100%;
}

.blog-screen .text-wrapper-116 {
  color: var(--darkgray);
  flex: 1;
  font-family: "Noto Sans JP", Helvetica;
  font-size: 15px;
  letter-spacing: 0.75px;
  line-height: 26px;
  margin-top: -1px;
  position: relative;
}

.blog-screen .line-11 {
  align-self: stretch;
  margin-left: -1px;
  margin-right: -1px;
  position: relative;
  width: 100%;
}

.blog-screen .frame-133 {
  align-items: center;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  justify-content: space-between;
  padding: 0px 10px;
  position: relative;
  width: 100%;
}

.blog-screen .text-wrapper-117 {
  color: var(--darkgray);
  flex: 1;
  font-family: "Noto Sans JP", Helvetica;
  font-size: 15px;
  letter-spacing: 0.75px;
  line-height: 26px;
  margin-top: -1px;
  position: relative;
}

.blog-screen .CONTACT-8 {
  align-items: center;
  align-self: stretch;
  background-color: var(--backwhite);
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 40px;
  position: relative;
  width: 100%;
}

.blog-screen .CONTACT-9 {
  height: 160px;
  position: relative;
}

.blog-screen .frame-134 {
  align-items: center;
  background-color: var(--purple);
  border-radius: 6px;
  display: flex;
  flex-wrap: wrap;
  gap: 20px 74px;
  justify-content: center;
  max-width: 700px;
  padding: 50px 60px;
  position: relative;
  width: 700px;
}

.blog-screen .group-19 {
  height: 60px;
  position: relative;
  width: 216px;
}

.blog-screen .overlap-group-6 {
  height: 60px;
  position: relative;
  width: 212px;
}

.blog-screen .text-wrapper-118 {
  color: var(--white);
  font-family: "Zen Kaku Gothic New", Helvetica;
  font-size: 24px;
  font-weight: 500;
  left: 0;
  letter-spacing: 2.4px;
  line-height: normal;
  position: absolute;
  text-align: center;
  top: 0;
  width: 212px;
}

.blog-screen .text-wrapper-119 {
  color: var(--white);
  font-family: "Slackside One", Helvetica;
  font-size: 20px;
  font-weight: 400;
  left: 39px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  text-align: center;
  top: 31px;
  width: 134px;
}

.blog-screen .group-20 {
  height: 55px;
  position: relative;
  width: 254px;
}

.blog-screen .flexcontainer-14 {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  gap: 5px;
  height: 55px;
  position: relative;
  width: 252px;
}

.blog-screen .text-i-15 {
  align-self: stretch;
  color: var(--white);
  font-family: "Noto Sans JP", Helvetica;
  font-size: 15px;
  font-weight: 400;
  letter-spacing: 0.75px;
  line-height: 25px;
  position: relative;
}

.blog-screen .text-wrapper-120 {
  letter-spacing: 0.11px;
}

.blog-screen .footer-5 {
  align-items: center;
  align-self: stretch;
  background-color: #454545;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 50px;
  position: relative;
  width: 100%;
}

.blog-screen .frame-135 {
  align-items: flex-start;
  display: flex;
  flex: 0 0 auto;
  flex-wrap: wrap;
  gap: 50px 150px;
  justify-content: space-between;
  position: relative;
}

.blog-screen .frame-136 {
  align-items: flex-start;
  flex-direction: column;
  position: relative;
}

.blog-screen .logo-white-2 {
  height: 54.26px !important;
  left: unset !important;
  position: relative !important;
  top: unset !important;
  width: 163.65px !important;
}

.blog-screen .logo-white-7 {
  height: 54.26px !important;
  position: relative !important;
  width: 163.65px !important;
}

.blog-screen .flexcontainer-15 {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.blog-screen .text-i-16 {
  align-self: stretch;
  color: #ffffff;
  font-family: var(--text-font-family);
  font-size: var(--text-font-size);
  font-style: var(--text-font-style);
  font-weight: var(--text-font-weight);
  letter-spacing: var(--text-letter-spacing);
  line-height: var(--text-line-height);
  position: relative;
}

.blog-screen .text-wrapper-121 {
  font-family: var(--text-font-family);
  font-size: var(--text-font-size);
  font-style: var(--text-font-style);
  font-weight: var(--text-font-weight);
  letter-spacing: var(--text-letter-spacing);
  line-height: var(--text-line-height);
}

.blog-screen .frame-137 {
  align-items: flex-start;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 60px;
  position: relative;
}

.blog-screen .frame-138 {
  align-items: flex-start;
  display: inline-flex;
  flex: 0 0 auto;
  flex-direction: column;
  position: relative;
}

.blog-screen .frame-139 {
  align-items: flex-start;
  display: inline-flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 6px;
  position: relative;
}

.blog-screen .text-wrapper-122 {
  color: #ffffff;
  margin-top: -1px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.blog-screen .text-wrapper-123 {
  color: #ffffff;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.blog-screen .text-wrapper-124 {
  color: #ffffff;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.blog-screen .text-wrapper-125 {
  color: #ffffff;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.blog-screen .text-wrapper-126 {
  color: #ffffff;
  margin-top: -1px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.blog-screen .text-wrapper-127 {
  color: #ffffff;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.blog-screen .text-wrapper-128 {
  color: #ffffff;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.blog-screen .text-wrapper-129 {
  color: #ffffff;
  margin-top: -1px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.blog-screen .text-wrapper-130 {
  color: #ffffff;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.blog-screen .text-wrapper-131 {
  color: #ffffff;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.blog-screen .frame-140 {
  align-items: flex-start;
  display: inline-flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 30px;
  position: relative;
}

.blog-screen .text-wrapper-132 {
  color: #ffffff;
  margin-top: -1px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.blog-screen .text-wrapper-133 {
  color: #ffffff;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.blog-screen .text-wrapper-134 {
  color: #ffffff;
  display: block;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.blog-screen .text-wrapper-135 {
  color: #ffffff;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.blog-screen .text-wrapper-136 {
  color: #ffffff;
  display: block;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}
