.frame {
  align-items: center;
  border-radius: 30px;
  display: flex;
  gap: 10px;
  justify-content: center;
  overflow: hidden;
  padding: 8px 50px;
  position: relative;
  width: 150px;
}

.frame .div-4 {
  color: var(--white);
  font-family: var(--bold-font-family);
  font-size: var(--bold-font-size);
  font-style: var(--bold-font-style);
  font-weight: var(--bold-font-weight);
  letter-spacing: var(--bold-letter-spacing);
  line-height: var(--bold-line-height);
  margin-top: -1px;
  position: relative;
  text-align: center;
  white-space: nowrap;
  width: fit-content;
}

.frame.one-8-two {
  background-color: #8a8a8a66;
}

.frame.one-8-one {
  background-color: var(--pink);
}

.frame.one-8-two .div-4 {
  margin-left: -4.5px;
  margin-right: -4.5px;
}
