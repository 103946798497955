.menuopen-TAB {
  align-items: flex-start;
  display: flex;
  gap: 10px;
  height: 1133px;
  justify-content: flex-end;
  min-width: 744px;
  overflow-x: hidden;
  position: relative;
  width: 100%;
}

.menuopen-TAB .menu-wrapper-2 {
  align-items: flex-start;
  display: flex;
  gap: 10px;
  height: 1133px;
  justify-content: flex-end;
  width: 744px;
}

.menuopen-TAB .menu-7 {
  align-items: flex-end;
  align-self: stretch;
  background-color: var(--purple);
  display: flex;
  flex-direction: column;
  gap: 10px;
  justify-content: center;
  padding: 150px 60px;
  position: relative;
  width: 512px;
}

.menuopen-TAB .frame-219 {
  align-items: flex-start;
  display: inline-flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 50px;
  margin-left: -13px;
  position: relative;
}

.menuopen-TAB .text-wrapper-234 {
  color: #ffffff;
  font-family: "Noto Sans JP", Helvetica;
  font-size: 20px;
  font-weight: 700;
  letter-spacing: 1px;
  line-height: normal;
  margin-top: -1px;
  position: relative;
  width: fit-content;
}

.menuopen-TAB .frame-220 {
  align-items: flex-start;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 80px;
  position: relative;
}

.menuopen-TAB .frame-221 {
  align-items: flex-start;
  display: inline-flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 50px;
  position: relative;
}

.menuopen-TAB .frame-222 {
  align-items: flex-start;
  display: inline-flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 10px;
  position: relative;
}

.menuopen-TAB .frame-223 {
  align-items: flex-start;
  display: inline-flex;
  flex: 0 0 auto;
  flex-direction: column;
  position: relative;
}

.menuopen-TAB .text-wrapper-235 {
  color: #ffffff;
  font-family: "Noto Sans JP", Helvetica;
  font-size: 16px;
  font-weight: 700;
  letter-spacing: 0.8px;
  line-height: 30px;
  margin-top: -1px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.menuopen-TAB .text-wrapper-236 {
  color: #ffffff;
  font-family: "Noto Sans JP", Helvetica;
  font-size: 16px;
  font-weight: 700;
  letter-spacing: 0.8px;
  line-height: 30px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.menuopen-TAB .text-wrapper-237 {
  color: #ffffff;
  display: block;
  font-family: "Noto Sans JP", Helvetica;
  font-size: 20px;
  font-weight: 700;
  letter-spacing: 1px;
  line-height: normal;
  margin-top: -1px;
  position: relative;
  width: fit-content;
}

.menuopen-TAB .frame-224 {
  align-items: flex-start;
  display: inline-flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 20px;
  position: relative;
}

.menuopen-TAB .text-wrapper-238 {
  color: #ffffff;
  font-family: "Noto Sans JP", Helvetica;
  font-size: 16px;
  font-weight: 700;
  letter-spacing: 0.8px;
  line-height: normal;
  margin-top: -1px;
  position: relative;
  width: fit-content;
}

.menuopen-TAB .text-wrapper-239 {
  color: #ffffff;
  font-family: "Noto Sans JP", Helvetica;
  font-size: 16px;
  font-weight: 700;
  letter-spacing: 0.8px;
  line-height: normal;
  position: relative;
  width: fit-content;
}

.menuopen-TAB .button-15 {
  flex: 0 0 auto !important;
  width: 200px !important;
}

.menuopen-TAB .button-16 {
  height: 11.13px !important;
  margin-left: -1.65px !important;
  margin-right: -1.15px !important;
  position: relative !important;
  width: 7.41px !important;
}

.menuopen-TAB .menu-17 {
  height: 35px !important;
  left: 476px !important;
  position: absolute !important;
  top: 0 !important;
  width: 36px !important;
}
