.profile {
  align-items: center;
  background-color: var(--backwhite);
  display: flex;
  flex-direction: column;
  position: relative;
}

.profile .header-24 {
  align-self: stretch !important;
  width: 100% !important;
  z-index: 1 !important;
}

.profile .menu-8 {
  height: 35px !important;
  margin-bottom: -10px !important;
  position: relative !important;
  width: 36px !important;
}

.profile .header-25 {
  align-self: stretch !important;
  display: flex !important;
  flex: 0 0 auto !important;
  justify-content: space-between !important;
  width: 100% !important;
  z-index: 1 !important;
}

.profile .header-26 {
  z-index: 1 !important;
}

.profile .header-27 {
  height: 59px !important;
  position: relative !important;
  width: 60px !important;
  z-index: 0 !important;
}

.profile .div-18 {
  align-items: flex-start;
  align-self: stretch;
  background-color: #ffffff;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  position: relative;
  width: 100%;
  z-index: 0;
}

.profile .midashi-5 {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  position: relative;
  width: 100%;
}

.profile .class-103 {
  color: #ffffff !important;
  left: 12px !important;
}

.profile .class-104 {
  color: #ffffff !important;
  left: 9px !important;
}

.profile .class-105 {
  color: #ffffff !important;
  left: 32px !important;
}

.profile .class-106 {
  color: #ffffff !important;
  left: -10px !important;
}

.profile .class-107 {
  align-self: stretch !important;
  display: flex !important;
  flex: 0 0 auto !important;
  padding: 5px 0px !important;
  width: 100% !important;
}

.profile .class-108 {
  align-self: stretch !important;
  flex: 0 0 auto !important;
  width: 100% !important;
}

.profile .breadcrumbs-8 {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  gap: 30px;
  justify-content: center;
  position: relative;
  width: 100%;
}

.profile .frame-237 {
  align-items: center;
  display: flex;
  flex: 1;
  flex-grow: 1;
  position: relative;
}

.profile .text-wrapper-275 {
  color: var(--gray);
  display: block;
  font-family: var(--smalltext-SP-font-family);
  font-size: var(--smalltext-SP-font-size);
  font-style: var(--smalltext-SP-font-style);
  font-weight: var(--smalltext-SP-font-weight);
  letter-spacing: var(--smalltext-SP-letter-spacing);
  line-height: var(--smalltext-SP-line-height);
  margin-top: -1px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.profile .instance-node-8 {
  height: 8.14px !important;
  position: relative !important;
  width: 4.57px !important;
}

.profile .TOP-4 {
  color: var(--gray);
  margin-top: -1px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.profile .text-wrapper-276 {
  color: var(--gray);
  font-family: var(--tag-font-family);
  font-size: var(--tag-font-size);
  font-style: var(--tag-font-style);
  font-weight: var(--tag-font-weight);
  letter-spacing: var(--tag-letter-spacing);
  line-height: var(--tag-line-height);
  margin-top: -1px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.profile .greeting {
  align-items: center;
  align-self: stretch;
  background-color: var(--backwhite);
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  position: relative;
  width: 100%;
}

.profile .class-109 {
  left: 12px !important;
}

.profile .heading-31 {
  position: relative !important;
}

.profile .class-110 {
  left: -10px !important;
}

.profile .flexcontainer-31 {
  align-items: flex-start;
  flex-direction: column;
  gap: 2px;
}

.profile .text-19 {
  align-self: stretch;
  color: #454545;
  position: relative;
  text-align: center;
}

.profile .text-20 {
  align-self: stretch;
  color: #454545;
  position: relative;
  text-align: center;
}

.profile .text-21 {
  align-self: stretch;
  color: #454545;
  position: relative;
  text-align: center;
}

.profile .members {
  align-items: center;
  align-self: stretch;
  background-color: var(--backwhite);
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  position: relative;
  width: 100%;
}

.profile .class-111 {
  left: 3px !important;
}

.profile .heading-32 {
  position: relative !important;
}

.profile .class-112 {
  left: -44px !important;
}

.profile .frame-238 {
  align-items: center;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 40px;
  position: relative;
}

.profile .member {
  display: flex;
  flex: 0 0 auto;
  position: relative;
}

.profile .rectangle-5 {
  position: relative;
}

.profile .frame-239 {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  gap: 16px;
  position: relative;
}

.profile .frame-240 {
  align-items: flex-start;
  display: inline-flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 12px;
  position: relative;
}

.profile .text-wrapper-277 {
  color: var(--black);
  margin-top: -1px;
  position: relative;
  width: fit-content;
}

.profile .text-wrapper-278 {
  color: #454545;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.profile .frame-241 {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  position: relative;
  width: 100%;
}

.profile .frame-242 {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  flex-wrap: wrap;
  position: relative;
  width: 100%;
}

.profile .text-wrapper-279 {
  color: var(--darkgray);
  margin-top: -1px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.profile .text-wrapper-280 {
  color: var(--darkgray);
  flex: 1;
  margin-top: -1px;
  position: relative;
}

.profile .frame-243 {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  flex-wrap: wrap;
  position: relative;
  width: 100%;
}

.profile .text-wrapper-281 {
  color: var(--darkgray);
  margin-top: -1px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.profile .text-wrapper-282 {
  color: var(--darkgray);
  flex: 1;
  margin-top: -1px;
  position: relative;
}

.profile .frame-244 {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  flex-wrap: wrap;
  position: relative;
  width: 100%;
}

.profile .text-wrapper-283 {
  color: var(--darkgray);
  margin-top: -1px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.profile .text-wrapper-284 {
  color: var(--darkgray);
  flex: 1;
  margin-top: -1px;
  position: relative;
}

.profile .frame-245 {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  flex-wrap: wrap;
  position: relative;
  width: 100%;
}

.profile .text-wrapper-285 {
  color: var(--darkgray);
  margin-top: -1px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.profile .text-wrapper-286 {
  color: var(--darkgray);
  flex: 1;
  margin-top: -1px;
  position: relative;
}

.profile .line-13 {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  gap: 10px;
  height: 1px;
  justify-content: center;
  position: relative;
}

.profile .grayline-4 {
  align-self: stretch;
  height: 1px;
  margin-bottom: -0.5px;
  position: relative;
  width: 100%;
}

.profile .line-14 {
  height: 1px;
  left: 340px;
  object-fit: cover;
  position: absolute;
  top: -1px;
  width: 280px;
}

.profile .class-113 {
  align-self: stretch !important;
  background-image: url(../../../static/img/line-13-3-2.svg) !important;
  top: unset !important;
  width: 100% !important;
}

.profile .class-114 {
  align-self: stretch !important;
  background-image: url(../../../static/img/line-13-8.svg) !important;
  margin-top: -0.5px !important;
  top: unset !important;
  width: 100% !important;
}

.profile .member-2 {
  display: flex;
  flex: 0 0 auto;
  position: relative;
}

.profile .rectangle-6 {
  position: relative;
}

.profile .frame-246 {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  gap: 16px;
  position: relative;
}

.profile .text-wrapper-287 {
  color: var(--black);
  margin-top: -1px;
  position: relative;
  width: fit-content;
}

.profile .text-wrapper-288 {
  color: #454545;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.profile .frame-247 {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  position: relative;
  width: 100%;
}

.profile .frame-248 {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  flex-wrap: wrap;
  position: relative;
  width: 100%;
}

.profile .text-wrapper-289 {
  color: var(--darkgray);
  margin-top: -1px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.profile .text-wrapper-290 {
  color: var(--darkgray);
  flex: 1;
  margin-top: -1px;
  position: relative;
}

.profile .frame-249 {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  flex-wrap: wrap;
  position: relative;
  width: 100%;
}

.profile .text-wrapper-291 {
  color: var(--darkgray);
  margin-top: -1px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.profile .text-wrapper-292 {
  color: var(--darkgray);
  flex: 1;
  margin-top: -1px;
  position: relative;
}

.profile .frame-250 {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  flex-wrap: wrap;
  position: relative;
  width: 100%;
}

.profile .text-wrapper-293 {
  color: var(--darkgray);
  margin-top: -1px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.profile .text-wrapper-294 {
  color: var(--darkgray);
  flex: 1;
  margin-top: -1px;
  position: relative;
}

.profile .frame-251 {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  flex-wrap: wrap;
  position: relative;
  width: 100%;
}

.profile .text-wrapper-295 {
  color: var(--darkgray);
  margin-top: -1px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.profile .text-wrapper-296 {
  color: var(--darkgray);
  flex: 1;
  margin-top: -1px;
  position: relative;
}

.profile .line-15 {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  gap: 10px;
  height: 1px;
  justify-content: center;
  position: relative;
}

.profile .member-3 {
  display: flex;
  flex: 0 0 auto;
  position: relative;
}

.profile .rectangle-7 {
  position: relative;
}

.profile .frame-252 {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  gap: 16px;
  position: relative;
}

.profile .text-wrapper-297 {
  color: var(--black);
  margin-top: -1px;
  position: relative;
  width: fit-content;
}

.profile .text-wrapper-298 {
  color: #454545;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.profile .frame-253 {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  position: relative;
  width: 100%;
}

.profile .frame-254 {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  flex-wrap: wrap;
  position: relative;
  width: 100%;
}

.profile .text-wrapper-299 {
  color: var(--darkgray);
  margin-top: -1px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.profile .text-wrapper-300 {
  color: var(--darkgray);
  flex: 1;
  margin-top: -1px;
  position: relative;
}

.profile .frame-255 {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  flex-wrap: wrap;
  position: relative;
  width: 100%;
}

.profile .text-wrapper-301 {
  color: var(--darkgray);
  margin-top: -1px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.profile .text-wrapper-302 {
  color: var(--darkgray);
  flex: 1;
  margin-top: -1px;
  position: relative;
}

.profile .frame-256 {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  flex-wrap: wrap;
  position: relative;
  width: 100%;
}

.profile .text-wrapper-303 {
  color: var(--darkgray);
  margin-top: -1px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.profile .text-wrapper-304 {
  color: var(--darkgray);
  flex: 1;
  margin-top: -1px;
  position: relative;
}

.profile .frame-257 {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  flex-wrap: wrap;
  position: relative;
  width: 100%;
}

.profile .text-wrapper-305 {
  color: var(--darkgray);
  margin-top: -1px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.profile .text-wrapper-306 {
  color: var(--darkgray);
  flex: 1;
  margin-top: -1px;
  position: relative;
}

.profile .CONTACT-18 {
  align-items: center;
  background-color: var(--backwhite);
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 40px;
  position: relative;
}

.profile .CONTACT-19 {
  display: block;
  height: 160px;
  position: relative;
}

.profile .frame-258 {
  align-items: center;
  background-color: var(--purple);
  border-radius: 6px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  max-width: 700px;
  position: relative;
}

.profile .group-33 {
  position: relative;
  width: 216px;
}

.profile .text-wrapper-307 {
  color: var(--white);
  font-family: "Zen Kaku Gothic New", Helvetica;
  font-size: 20px;
  font-weight: 500;
  left: 0;
  letter-spacing: 2px;
  line-height: normal;
  position: absolute;
  text-align: center;
  top: 0;
  width: 212px;
}

.profile .text-wrapper-308 {
  color: var(--white);
  font-family: "Slackside One", Helvetica;
  font-size: 15px;
  font-weight: 400;
  left: 39px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  text-align: center;
  top: 31px;
  width: 134px;
}

.profile .overlap-group-10 {
  height: 60px;
  position: relative;
  width: 212px;
}

.profile .text-wrapper-309 {
  color: var(--white);
  font-family: "Zen Kaku Gothic New", Helvetica;
  font-size: 24px;
  font-weight: 500;
  left: 0;
  letter-spacing: 2.4px;
  line-height: normal;
  position: absolute;
  text-align: center;
  top: 0;
  width: 212px;
}

.profile .text-wrapper-310 {
  color: var(--white);
  font-family: "Slackside One", Helvetica;
  font-size: 20px;
  font-weight: 400;
  left: 39px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  text-align: center;
  top: 31px;
  width: 134px;
}

.profile .group-34 {
  position: relative;
}

.profile .flexcontainer-32 {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  position: relative;
}

.profile .text-i-31 {
  align-self: stretch;
  color: var(--white);
  position: relative;
}

.profile .text-i-32 {
  align-self: stretch;
  color: var(--white);
  position: relative;
}

.profile .footer-10 {
  align-items: center;
  align-self: stretch;
  background-color: #454545;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 50px;
  position: relative;
  width: 100%;
}

.profile .frame-259 {
  align-items: flex-start;
  display: flex;
  flex: 0 0 auto;
  position: relative;
}

.profile .frame-260 {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  position: relative;
}

.profile .logo-white-4 {
  height: 40px !important;
  position: relative !important;
  width: 121px !important;
}

.profile .flexcontainer-33 {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  gap: 3px;
}

.profile .text-i-33 {
  align-self: stretch;
  color: #ffffff;
  font-family: "Noto Sans JP", Helvetica;
  font-size: 13px;
  font-weight: 400;
  letter-spacing: 0.26px;
  line-height: 18px;
  position: relative;
}

.profile .text-wrapper-311 {
  letter-spacing: 0.03px;
}

.profile .logo-white-20 {
  height: 54.26px !important;
  position: relative !important;
  width: 163.65px !important;
}

.profile .flexcontainer-34 {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.profile .text-i-34 {
  align-self: stretch;
  color: #ffffff;
  font-family: var(--text-font-family);
  font-size: var(--text-font-size);
  font-style: var(--text-font-style);
  font-weight: var(--text-font-weight);
  letter-spacing: var(--text-letter-spacing);
  line-height: var(--text-line-height);
  position: relative;
}

.profile .text-wrapper-312 {
  font-family: var(--text-font-family);
  font-size: var(--text-font-size);
  font-style: var(--text-font-style);
  font-weight: var(--text-font-weight);
  letter-spacing: var(--text-letter-spacing);
  line-height: var(--text-line-height);
}

.profile .frame-261 {
  align-items: flex-start;
  flex: 0 0 auto;
  position: relative;
}

.profile .frame-262 {
  align-items: flex-start;
  display: inline-flex;
  flex: 0 0 auto;
  flex-direction: column;
  position: relative;
}

.profile .frame-263 {
  align-items: flex-start;
  display: inline-flex;
  flex: 0 0 auto;
  flex-direction: column;
  position: relative;
}

.profile .text-wrapper-313 {
  color: #ffffff;
  display: block;
  margin-top: -1px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.profile .text-wrapper-314 {
  color: #ffffff;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.profile .text-wrapper-315 {
  color: #ffffff;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.profile .text-wrapper-316 {
  color: #ffffff;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.profile .frame-264 {
  align-items: flex-start;
  display: inline-flex;
  flex: 0 0 auto;
  flex-direction: column;
  position: relative;
}

.profile .text-wrapper-317 {
  color: #ffffff;
  display: block;
  margin-top: -1px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.profile .text-wrapper-318 {
  color: #ffffff;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.profile .text-wrapper-319 {
  color: #ffffff;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.profile .frame-265 {
  align-items: flex-start;
  display: inline-flex;
  flex: 0 0 auto;
  flex-direction: column;
  position: relative;
}

.profile .text-wrapper-320 {
  color: #ffffff;
  display: block;
  margin-top: -1px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.profile .text-wrapper-321 {
  color: #ffffff;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.profile .text-wrapper-322 {
  color: #ffffff;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.profile .frame-266 {
  align-items: flex-start;
  flex-direction: column;
  gap: 30px;
  position: relative;
}

.profile .text-wrapper-323 {
  color: #ffffff;
  display: block;
  margin-top: -1px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.profile .text-wrapper-324 {
  color: #ffffff;
  display: block;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.profile .text-wrapper-325 {
  color: #ffffff;
  display: block;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.profile .text-wrapper-326 {
  color: #ffffff;
  display: block;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.profile .text-wrapper-327 {
  color: #ffffff;
  display: block;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.profile .text-wrapper-328 {
  color: #ffffff;
  font-family: "Manrope", Helvetica;
  font-size: 13.1px;
  font-weight: 400;
  letter-spacing: 1.31px;
  line-height: normal;
  position: relative;
  width: fit-content;
}
