.home-screen {
  align-items: center;
  display: flex;
  flex-direction: column;
  position: relative;
}

.home-screen .z {
  align-self: stretch !important;
  display: flex !important;
  flex: 0 0 auto !important;
  justify-content: space-between !important;
  padding: 0px 0px 20px 20px !important;
  width: 100% !important;
  z-index: 1 !important;
}

.home-screen .z-2 {
  z-index: 1 !important;
}

.home-screen .home-2 {
  align-items: center;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  margin-top: -90px;
  position: relative;
  width: 100%;
  z-index: 0;
}

.home-screen .FV-wrapper {
  align-items: center;
  align-self: stretch;
  display: flex;
  flex-direction: column;
  gap: 10px;
  height: 752px;
  justify-content: flex-end;
  position: relative;
  width: 100%;
}

.home-screen .FV-3 {
  align-self: stretch;
  flex: 1;
  flex-grow: 1;
  object-fit: cover;
  position: relative;
  width: 100%;
}

.home-screen .LEAD-3 {
  align-items: center;
  align-self: stretch;
  background-color: var(--backwhite);
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 50px;
  padding: 0px 30px 100px;
  position: relative;
  width: 100%;
}

.home-screen .lead-ill-2 {
  height: 248px !important;
  width: 396px !important;
}

.home-screen .lead-text-3 {
  align-items: center;
  align-self: stretch;
  display: flex;
  flex-direction: column;
  gap: 40px;
  height: 324px;
  position: relative;
  width: 100%;
}

.home-screen .flexcontainer-47 {
  align-items: flex-start;
  display: inline-flex;
  flex-direction: column;
  gap: 2px;
}

.home-screen .text-32 {
  align-self: stretch;
  color: var(--darkgray);
  font-family: "Noto Sans JP", Helvetica;
  font-size: 23px;
  font-weight: 700;
  letter-spacing: 2.3px;
  line-height: 43px;
  position: relative;
  text-align: center;
}

.home-screen .text-wrapper-449 {
  letter-spacing: 0.53px;
}

.home-screen .text-wrapper-450 {
  align-self: stretch;
  color: var(--darkgray);
  font-family: var(--lead-font-family);
  font-size: var(--lead-font-size);
  font-style: var(--lead-font-style);
  font-weight: var(--lead-font-weight);
  letter-spacing: var(--lead-letter-spacing);
  line-height: var(--lead-line-height);
  position: relative;
}

.home-screen .FLOW-3 {
  align-items: center;
  align-self: stretch;
  background-image: url(../../../static/img/flow.png);
  background-position: 50% 50%;
  background-size: cover;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 60px;
  padding: 100px 20px;
  position: relative;
  width: 100%;
}

.home-screen .heading-36 {
  position: relative !important;
}

.home-screen .heading-37 {
  left: -44px !important;
}

.home-screen .heading-38 {
  left: 24px !important;
}

.home-screen .flexcontainer-48 {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.home-screen .text-33 {
  align-self: stretch;
  color: #454545;
  font-family: var(--lead-font-family);
  font-size: var(--lead-font-size);
  font-style: var(--lead-font-style);
  font-weight: var(--lead-font-weight);
  letter-spacing: var(--lead-letter-spacing);
  line-height: var(--lead-line-height);
  position: relative;
  text-align: center;
}

.home-screen .text-wrapper-451 {
  font-family: var(--lead-font-family);
  font-size: var(--lead-font-size);
  font-style: var(--lead-font-style);
  font-weight: var(--lead-font-weight);
  letter-spacing: var(--lead-letter-spacing);
  line-height: var(--lead-line-height);
}

.home-screen .processe-4 {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  flex-wrap: wrap;
  gap: 30px 10px;
  justify-content: center;
  max-width: 1200px;
  position: relative;
  width: 100%;
}

.home-screen .process-18 {
  flex: 0 0 auto !important;
  max-width: unset !important;
}

.home-screen .process-19 {
  align-self: stretch !important;
  display: flex !important;
  width: 100% !important;
}

.home-screen .process-20 {
  flex: 0 0 auto !important;
  height: 287px !important;
  max-width: unset !important;
}

.home-screen .process-21 {
  align-items: center;
  display: inline-flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 20px;
  padding: 10px 15px;
  position: relative;
}

.home-screen .frame-351 {
  align-items: center;
  align-self: stretch;
  background-color: var(--white);
  border-radius: 10px;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 10px;
  overflow: hidden;
  padding: 17px 0px;
  position: relative;
  width: 100%;
}

.home-screen .image-9 {
  height: 145px;
  object-fit: cover;
  position: relative;
  width: 179px;
}

.home-screen .frame-352 {
  align-items: center;
  display: inline-flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 10px;
  padding: 0px 16px;
  position: relative;
}

.home-screen .process-22 {
  color: var(--purple);
  font-family: "Manrope", Helvetica;
  font-size: 15.1px;
  font-weight: 800;
  letter-spacing: 1.51px;
  line-height: normal;
  margin-top: -1px;
  position: relative;
  text-align: center;
  width: fit-content;
}

.home-screen .text-i-48 {
  align-self: stretch;
  color: #272727;
  font-family: "Noto Sans JP", Helvetica;
  font-size: 20px;
  font-weight: 700;
  letter-spacing: 1px;
  line-height: 30px;
  position: relative;
  text-align: center;
}

.home-screen .text-wrapper-452 {
  letter-spacing: 0.2px;
}

.home-screen .button-17 {
  flex: 0 0 auto !important;
}

.home-screen .button-18 {
  height: 11.13px !important;
  margin-left: -1.65px !important;
  margin-right: -1.15px !important;
  position: relative !important;
  width: 7.41px !important;
}

.home-screen .BLOGS-3 {
  align-items: center;
  align-self: stretch;
  background-color: var(--backwhite);
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 60px;
  padding: 100px 15px;
  position: relative;
  width: 100%;
}

.home-screen .heading-39 {
  left: 28px !important;
}

.home-screen .div-26 {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  flex-wrap: wrap;
  gap: 30px 10px;
  justify-content: center;
  position: relative;
  width: 100%;
}

.home-screen .frame-353 {
  align-items: flex-start;
  background-color: #ffffff;
  border-radius: 10px;
  display: flex;
  flex: 1;
  flex-grow: 1;
  gap: 14px;
  position: relative;
  transition: all 0.3s ease;
}

.home-screen .frame-354 {
  align-items: flex-start;
  background-color: #ffffff;
  border: 1px solid;
  border-color: #efeeee;
  border-radius: 10px;
  display: flex;
  flex: 1;
  flex-direction: column;
  flex-grow: 1;
  gap: 14px;
  height: 395px;
  padding: 12px 12px 20px;
  position: relative;
}

.home-screen .frame-355 {
  align-items: center;
  align-self: stretch;
  background-color: #959595;
  border-radius: 6px;
  display: flex;
  flex: 1;
  flex-grow: 1;
  gap: 10px;
  justify-content: center;
  max-height: 220px;
  min-height: 200px;
  position: relative;
  width: 100%;
}

.home-screen .noimage-10 {
  height: 25.92px !important;
  left: unset !important;
  position: relative !important;
  top: unset !important;
  width: 190.9px !important;
}

.home-screen .text-wrapper-453 {
  color: #8a8a8a;
  font-family: "Manrope", Helvetica;
  font-size: 12px;
  font-weight: 400;
  letter-spacing: 1.2px;
  line-height: normal;
  position: relative;
  width: fit-content;
}

.home-screen .text-wrapper-454 {
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  align-self: stretch;
  color: #454545;
  display: -webkit-box;
  font-family: "Noto Sans JP", Helvetica;
  font-size: 15px;
  font-weight: 700;
  letter-spacing: 0.75px;
  line-height: 26px;
  overflow: hidden;
  position: relative;
  text-overflow: ellipsis;
}

.home-screen .vector-3 {
  align-self: stretch;
  height: 1px;
  object-fit: cover;
  position: relative;
  width: 100%;
}

.home-screen .text-wrapper-455 {
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  align-self: stretch;
  color: var(--purple);
  display: -webkit-box;
  font-family: "Noto Sans JP", Helvetica;
  font-size: 13px;
  font-weight: 500;
  letter-spacing: 0.65px;
  line-height: 18.8px;
  overflow: hidden;
  position: relative;
  text-overflow: ellipsis;
}

.home-screen .RECRUITES-3 {
  align-items: center;
  align-self: stretch;
  background-color: var(--palepurple);
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 60px;
  padding: 100px 20px;
  position: relative;
  width: 100%;
}

.home-screen .heading-40 {
  left: -10px !important;
}

.home-screen .heading-41 {
  left: 10px !important;
}

.home-screen .frame-356 {
  align-items: flex-start;
  background-color: #ffffff;
  border: 1px solid;
  border-color: #efeeee;
  border-radius: 10px;
  display: flex;
  flex: 1;
  flex-direction: column;
  flex-grow: 1;
  gap: 14px;
  height: 442px;
  padding: 12px 12px 20px;
  position: relative;
  transition: all 0.3s ease;
}

.home-screen .noimage-13 {
  height: 25.92px !important;
  position: relative !important;
  width: 190.9px !important;
}

.home-screen .frame-357 {
  align-items: center;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  flex-wrap: wrap;
  gap: 10px 14px;
  justify-content: space-between;
  padding: 0px 5px;
  position: relative;
  width: 100%;
}

.home-screen .frame-15-instance {
  width: 120px !important;
}

.home-screen .frame-358 {
  margin-left: -12px !important;
  margin-right: -12px !important;
}

.home-screen .frame-359 {
  align-items: center;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 10px;
  margin-left: -120px;
  position: relative;
}

.home-screen .text-wrapper-456 {
  color: #8a8a8a;
  font-family: "Manrope", Helvetica;
  font-size: 12px;
  font-weight: 400;
  letter-spacing: 1.2px;
  line-height: normal;
  margin-top: -1px;
  position: relative;
  width: fit-content;
}

.home-screen .ABOUTUS-3 {
  align-items: center;
  align-self: stretch;
  background-image: url(../../../static/img/aboutus.png);
  background-position: 50% 50%;
  background-size: cover;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 80px;
  padding: 120px 20px;
  position: relative;
  width: 100%;
}

.home-screen .heading-42 {
  color: #ffffff !important;
  left: -10px !important;
}

.home-screen .heading-43 {
  color: #ffffff !important;
}

.home-screen .frame-360 {
  align-items: flex-start;
  display: flex;
  flex: 0 0 auto;
  gap: 40px;
  justify-content: center;
  position: relative;
  width: 700px;
}

.home-screen .white-button {
  align-items: center;
  background-color: var(--white);
  border-radius: 76px;
  display: flex;
  flex: 1;
  flex-direction: column;
  flex-grow: 1;
  gap: 10px;
  height: 70px;
  justify-content: space-around;
  padding: 16px 0px;
  position: relative;
}

.home-screen .frame-361 {
  align-items: center;
  background-color: #ffffff;
  border-radius: 100px;
  display: flex;
  flex: 0 0 auto;
  gap: 10px;
  justify-content: space-around;
  margin-bottom: -16.5px;
  margin-top: -16.5px;
  overflow: hidden;
  padding: 24px 0px;
  position: relative;
  width: 300px;
}

.home-screen .text-wrapper-457 {
  color: #454545;
  font-family: "Noto Sans JP", Helvetica;
  font-size: 16px;
  font-weight: 700;
  letter-spacing: 0.8px;
  line-height: normal;
  margin-top: -1px;
  position: relative;
  text-align: center;
  width: fit-content;
}

.home-screen .CONTACT-28 {
  align-items: center;
  align-self: stretch;
  background-color: var(--backwhite);
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 40px;
  padding: 100px 20px;
  position: relative;
  width: 100%;
}

.home-screen .CONTACT-29 {
  height: 160px;
  position: relative;
  width: 704px;
}

.home-screen .frame-362 {
  align-items: center;
  background-color: var(--purple);
  border-radius: 6px;
  display: flex;
  flex-wrap: wrap;
  gap: 20px 74px;
  justify-content: center;
  left: 2px;
  max-width: 700px;
  padding: 50px 60px;
  position: relative;
  width: 700px;
}

.home-screen .group-60 {
  height: 60px;
  position: relative;
  width: 216px;
}

.home-screen .overlap-group-15 {
  height: 60px;
  position: relative;
  width: 212px;
}

.home-screen .text-wrapper-458 {
  color: var(--white);
  font-family: "Zen Kaku Gothic New", Helvetica;
  font-size: 24px;
  font-weight: 500;
  left: 0;
  letter-spacing: 2.4px;
  line-height: normal;
  position: absolute;
  text-align: center;
  top: 0;
  width: 212px;
}

.home-screen .text-wrapper-459 {
  color: var(--white);
  font-family: "Slackside One", Helvetica;
  font-size: 20px;
  font-weight: 400;
  left: 39px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  text-align: center;
  top: 31px;
  width: 134px;
}

.home-screen .group-61 {
  height: 55px;
  position: relative;
  width: 254px;
}

.home-screen .flexcontainer-49 {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  gap: 5px;
  height: 55px;
  position: relative;
  width: 252px;
}

.home-screen .text-i-49 {
  align-self: stretch;
  color: var(--white);
  font-family: "Noto Sans JP", Helvetica;
  font-size: 15px;
  font-weight: 400;
  letter-spacing: 0.75px;
  line-height: 25px;
  position: relative;
}

.home-screen .text-wrapper-460 {
  letter-spacing: 0.11px;
}

.home-screen .footer-16 {
  align-items: center;
  align-self: stretch;
  background-color: #454545;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 50px;
  padding: 70px 20px 50px;
  position: relative;
  width: 100%;
}

.home-screen .frame-363 {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  flex-wrap: wrap;
  gap: 50px 150px;
  justify-content: space-between;
  position: relative;
  width: 100%;
}

.home-screen .frame-364 {
  align-items: flex-start;
  display: inline-flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 30px;
  position: relative;
}

.home-screen .logo-white-11 {
  height: 54.26px !important;
  left: unset !important;
  position: relative !important;
  top: unset !important;
  width: 163.65px !important;
}

.home-screen .text-i-50 {
  align-self: stretch;
  color: #ffffff;
  font-family: var(--text-font-family);
  font-size: var(--text-font-size);
  font-style: var(--text-font-style);
  font-weight: var(--text-font-weight);
  letter-spacing: var(--text-letter-spacing);
  line-height: var(--text-line-height);
  position: relative;
}

.home-screen .text-wrapper-461 {
  font-family: var(--text-font-family);
  font-size: var(--text-font-size);
  font-style: var(--text-font-style);
  font-weight: var(--text-font-weight);
  letter-spacing: var(--text-letter-spacing);
  line-height: var(--text-line-height);
}

.home-screen .frame-365 {
  align-items: flex-start;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 60px;
  position: relative;
}

.home-screen .frame-366 {
  align-items: flex-start;
  display: inline-flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 6px;
  position: relative;
}

.home-screen .text-wrapper-462 {
  color: #ffffff;
  font-family: var(--text-bold-mini-font-family);
  font-size: var(--text-bold-mini-font-size);
  font-style: var(--text-bold-mini-font-style);
  font-weight: var(--text-bold-mini-font-weight);
  letter-spacing: var(--text-bold-mini-letter-spacing);
  line-height: var(--text-bold-mini-line-height);
  margin-top: -1px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.home-screen .text-wrapper-463 {
  color: #ffffff;
  font-family: var(--text-bold-mini-font-family);
  font-size: var(--text-bold-mini-font-size);
  font-style: var(--text-bold-mini-font-style);
  font-weight: var(--text-bold-mini-font-weight);
  letter-spacing: var(--text-bold-mini-letter-spacing);
  line-height: var(--text-bold-mini-line-height);
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.home-screen .text-wrapper-464 {
  color: #ffffff;
  display: block;
  font-family: var(--text-bold-mini-font-family);
  font-size: var(--text-bold-mini-font-size);
  font-style: var(--text-bold-mini-font-style);
  font-weight: var(--text-bold-mini-font-weight);
  letter-spacing: var(--text-bold-mini-letter-spacing);
  line-height: var(--text-bold-mini-line-height);
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.home-screen .text-wrapper-465 {
  color: #ffffff;
  font-family: "Manrope", Helvetica;
  font-size: 13.1px;
  font-weight: 400;
  letter-spacing: 1.31px;
  line-height: normal;
  position: relative;
  width: fit-content;
}
