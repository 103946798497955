.privacypolicy-screen {
  align-items: center;
  display: flex;
  flex-direction: column;
  position: relative;
}

.privacypolicy-screen .header-50 {
  align-self: stretch !important;
  flex: 0 0 auto !important;
  height: unset !important;
  width: 100% !important;
  z-index: 1 !important;
}

.privacypolicy-screen .header-51 {
  margin-bottom: unset !important;
}

.privacypolicy-screen .menu-16 {
  height: 35px !important;
  position: relative !important;
  width: 36px !important;
}

.privacypolicy-screen .privacypolicy-2 {
  align-items: flex-start;
  align-self: stretch;
  background-color: #ffffff;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  position: relative;
  width: 100%;
  z-index: 0;
}

.privacypolicy-screen .midashi-16 {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  position: relative;
  width: 100%;
}

.privacypolicy-screen .heading-70 {
  align-self: stretch !important;
  background-image: url(../../../static/img/midashi-1.png) !important;
  display: flex !important;
  flex: 0 0 auto !important;
  width: 100% !important;
}

.privacypolicy-screen .heading-71 {
  color: #ffffff !important;
  font-size: 24px !important;
  left: -69px !important;
  letter-spacing: 1.2px !important;
  top: 6px !important;
}

.privacypolicy-screen .heading-72 {
  color: #ffffff !important;
  font-size: 16px !important;
  left: -6px !important;
  letter-spacing: 0.8px !important;
  top: 36px !important;
}

.privacypolicy-screen .breadcrumbs-17 {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  gap: 30px;
  justify-content: center;
  padding: 20px 15px;
  position: relative;
  width: 100%;
}

.privacypolicy-screen .frame-447 {
  align-items: center;
  display: flex;
  flex: 1;
  flex-grow: 1;
  gap: 17px;
  position: relative;
}

.privacypolicy-screen .text-wrapper-571 {
  color: var(--gray);
  display: block;
  font-family: var(--tag-font-family);
  font-size: var(--tag-font-size);
  font-style: var(--tag-font-style);
  font-weight: var(--tag-font-weight);
  letter-spacing: var(--tag-letter-spacing);
  line-height: var(--tag-line-height);
  margin-top: -1px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.privacypolicy-screen .component-10930-12 {
  height: 8.14px !important;
  left: unset !important;
  position: relative !important;
  top: unset !important;
  width: 4.57px !important;
}

.privacypolicy-screen .text-wrapper-572 {
  color: var(--gray);
  font-family: var(--tag-font-family);
  font-size: var(--tag-font-size);
  font-style: var(--tag-font-style);
  font-weight: var(--tag-font-weight);
  letter-spacing: var(--tag-letter-spacing);
  line-height: var(--tag-line-height);
  margin-top: -1px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.privacypolicy-screen .privacypolicys-2 {
  align-items: center;
  align-self: stretch;
  background-color: var(--palepurple);
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 40px;
  padding: 40px 15px;
  position: relative;
  width: 100%;
}

.privacypolicy-screen .frame-448 {
  align-items: flex-start;
  align-self: stretch;
  background-color: var(--back-white);
  border-radius: 14px;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 40px;
  padding: 50px 20px;
  position: relative;
  width: 100%;
}

.privacypolicy-screen .frame-449 {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 24px;
  position: relative;
  width: 100%;
}

.privacypolicy-screen .heading-73 {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 7px;
  position: relative;
  width: 100%;
}

.privacypolicy-screen .text-wrapper-573 {
  align-self: stretch;
  color: #454545;
  font-family: "Noto Sans JP", Helvetica;
  font-size: 23px;
  font-weight: 700;
  letter-spacing: 2.3px;
  line-height: 43px;
  margin-top: -1px;
  position: relative;
}

.privacypolicy-screen .line-pg-2 {
  align-self: stretch;
  height: 2px;
  position: relative;
  width: 100%;
}

.privacypolicy-screen .div-30 {
  align-self: stretch;
  color: #454545;
  font-family: "Noto Sans JP", Helvetica;
  font-size: 15px;
  font-weight: 400;
  letter-spacing: 0.75px;
  line-height: 25px;
  position: relative;
}

.privacypolicy-screen .frame-450 {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 5px;
  position: relative;
  width: 100%;
}

.privacypolicy-screen .bulletpoint-4 {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  flex-wrap: wrap;
  gap: 5px 5px;
  position: relative;
  width: 100%;
}

.privacypolicy-screen .group-82 {
  height: 28px;
  position: relative;
  width: 17px;
}

.privacypolicy-screen .ellipse-8 {
  background-color: #6079c1;
  border-radius: 5px;
  height: 10px;
  left: 3px;
  position: relative;
  top: 9px;
  width: 10px;
}

.privacypolicy-screen .text-wrapper-574 {
  color: #454545;
  flex: 1;
  font-family: var(--text-font-family);
  font-size: var(--text-font-size);
  font-style: var(--text-font-style);
  font-weight: var(--text-font-weight);
  letter-spacing: var(--text-letter-spacing);
  line-height: var(--text-line-height);
  margin-top: -1px;
  position: relative;
}

.privacypolicy-screen .flexcontainer-65 {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.privacypolicy-screen .text-wrapper-575 {
  letter-spacing: 0.11px;
}

.privacypolicy-screen .text-39 {
  align-self: stretch;
  color: transparent;
  font-family: "Noto Sans JP", Helvetica;
  font-size: 15px;
  font-weight: 400;
  letter-spacing: 0.75px;
  line-height: 25px;
  position: relative;
}

.privacypolicy-screen .text-wrapper-576 {
  color: #454545;
  letter-spacing: 0.11px;
}

.privacypolicy-screen .text-wrapper-577 {
  color: #d45252;
  letter-spacing: 0.11px;
}

.privacypolicy-screen .frame-451 {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 30px;
  position: relative;
  width: 100%;
}

.privacypolicy-screen .text-40 {
  align-self: stretch;
  color: #454545;
  font-family: "Noto Sans JP", Helvetica;
  font-size: 15px;
  font-weight: 400;
  letter-spacing: 0.75px;
  line-height: 15px;
  position: relative;
}

.privacypolicy-screen .text-wrapper-578 {
  font-family: var(--text-bold-font-family);
  font-size: var(--text-bold-font-size);
  font-style: var(--text-bold-font-style);
  font-weight: var(--text-bold-font-weight);
  letter-spacing: var(--text-bold-letter-spacing);
  line-height: var(--text-bold-line-height);
}

.privacypolicy-screen .text-wrapper-579 {
  font-family: var(--text-font-family);
  font-size: var(--text-font-size);
  font-style: var(--text-font-style);
  font-weight: var(--text-font-weight);
  letter-spacing: var(--text-letter-spacing);
  line-height: var(--text-line-height);
}

.privacypolicy-screen .text-wrapper-580 {
  align-self: stretch;
  color: #454545;
  font-family: var(--text-font-family);
  font-size: var(--text-font-size);
  font-style: var(--text-font-style);
  font-weight: var(--text-font-weight);
  letter-spacing: var(--text-letter-spacing);
  line-height: var(--text-line-height);
  position: relative;
}

.privacypolicy-screen .CONTACT-40 {
  align-items: center;
  align-self: stretch;
  background-color: var(--backwhite);
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 40px;
  justify-content: center;
  padding: 100px 20px;
  position: relative;
  width: 100%;
}

.privacypolicy-screen .CONTACT-41 {
  height: 160px;
  position: relative;
  width: 280px;
}

.privacypolicy-screen .frame-452 {
  align-items: center;
  background-color: var(--purple);
  border-radius: 6px;
  display: flex;
  flex-wrap: wrap;
  gap: 20px 74px;
  justify-content: center;
  max-width: 700px;
  padding: 50px 60px;
  position: relative;
  top: -38px;
  width: 280px;
}

.privacypolicy-screen .group-83 {
  height: 60px;
  margin-left: -26px;
  margin-right: -30px;
  position: relative;
  width: 216px;
}

.privacypolicy-screen .overlap-group-21 {
  height: 60px;
  position: relative;
  width: 212px;
}

.privacypolicy-screen .text-wrapper-581 {
  color: var(--white);
  font-family: "Zen Kaku Gothic New", Helvetica;
  font-size: 24px;
  font-weight: 500;
  left: 0;
  letter-spacing: 2.4px;
  line-height: normal;
  position: absolute;
  text-align: center;
  top: 0;
  width: 212px;
}

.privacypolicy-screen .text-wrapper-582 {
  color: var(--white);
  font-family: "Slackside One", Helvetica;
  font-size: 20px;
  font-weight: 400;
  left: 39px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  text-align: center;
  top: 31px;
  width: 134px;
}

.privacypolicy-screen .group-84 {
  height: 55px;
  margin-left: -46px;
  margin-right: -48px;
  position: relative;
  width: 254px;
}

.privacypolicy-screen .flexcontainer-66 {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  gap: 5px;
  height: 55px;
  position: relative;
  width: 252px;
}

.privacypolicy-screen .text-i-62 {
  align-self: stretch;
  color: var(--white);
  font-family: "Noto Sans JP", Helvetica;
  font-size: 15px;
  font-weight: 400;
  letter-spacing: 0.75px;
  line-height: 25px;
  position: relative;
}

.privacypolicy-screen .footer-23 {
  align-items: center;
  align-self: stretch;
  background-color: #454545;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 50px;
  padding: 50px 20px;
  position: relative;
  width: 100%;
}

.privacypolicy-screen .frame-453 {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 50px;
  position: relative;
  width: 100%;
}

.privacypolicy-screen .logo-white-32 {
  height: 40px !important;
  left: unset !important;
  position: relative !important;
  top: unset !important;
  width: 121px !important;
}

.privacypolicy-screen .flexcontainer-67 {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  gap: 3px;
}

.privacypolicy-screen .text-i-63 {
  align-self: stretch;
  color: #ffffff;
  font-family: var(--smalltext-SP-font-family);
  font-size: var(--smalltext-SP-font-size);
  font-style: var(--smalltext-SP-font-style);
  font-weight: var(--smalltext-SP-font-weight);
  letter-spacing: var(--smalltext-SP-letter-spacing);
  line-height: var(--smalltext-SP-line-height);
  position: relative;
}

.privacypolicy-screen .text-wrapper-583 {
  font-family: var(--smalltext-SP-font-family);
  font-size: var(--smalltext-SP-font-size);
  font-style: var(--smalltext-SP-font-style);
  font-weight: var(--smalltext-SP-font-weight);
  letter-spacing: var(--smalltext-SP-letter-spacing);
  line-height: var(--smalltext-SP-line-height);
}

.privacypolicy-screen .frame-454 {
  align-items: flex-start;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 60px;
  margin-right: -14px;
  position: relative;
}

.privacypolicy-screen .frame-455 {
  align-items: flex-start;
  display: inline-flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 30px;
  position: relative;
}

.privacypolicy-screen .frame-456 {
  align-items: flex-start;
  display: inline-flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 4px;
  position: relative;
}

.privacypolicy-screen .text-wrapper-584 {
  color: #ffffff;
  display: block;
  font-family: var(--text-bold-mini-font-family);
  font-size: var(--text-bold-mini-font-size);
  font-style: var(--text-bold-mini-font-style);
  font-weight: var(--text-bold-mini-font-weight);
  letter-spacing: var(--text-bold-mini-letter-spacing);
  line-height: var(--text-bold-mini-line-height);
  margin-top: -1px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.privacypolicy-screen .text-wrapper-585 {
  color: #ffffff;
  font-family: var(--text-bold-mini-font-family);
  font-size: var(--text-bold-mini-font-size);
  font-style: var(--text-bold-mini-font-style);
  font-weight: var(--text-bold-mini-font-weight);
  letter-spacing: var(--text-bold-mini-letter-spacing);
  line-height: var(--text-bold-mini-line-height);
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.privacypolicy-screen .text-wrapper-586 {
  color: #ffffff;
  font-family: var(--text-bold-mini-font-family);
  font-size: var(--text-bold-mini-font-size);
  font-style: var(--text-bold-mini-font-style);
  font-weight: var(--text-bold-mini-font-weight);
  letter-spacing: var(--text-bold-mini-letter-spacing);
  line-height: var(--text-bold-mini-line-height);
  margin-top: -1px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.privacypolicy-screen .text-wrapper-587 {
  color: #ffffff;
  display: block;
  font-family: var(--text-bold-mini-font-family);
  font-size: var(--text-bold-mini-font-size);
  font-style: var(--text-bold-mini-font-style);
  font-weight: var(--text-bold-mini-font-weight);
  letter-spacing: var(--text-bold-mini-letter-spacing);
  line-height: var(--text-bold-mini-line-height);
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.privacypolicy-screen .text-wrapper-588 {
  color: #ffffff;
  font-family: "Manrope", Helvetica;
  font-size: 13.1px;
  font-weight: 400;
  letter-spacing: 1.31px;
  line-height: normal;
  position: relative;
  width: fit-content;
}
