.philosophy {
  align-items: center;
  background-color: var(--backwhite);
  display: flex;
  flex-direction: column;
  position: relative;
}

.philosophy .header-20 {
  align-self: stretch !important;
  width: 100% !important;
  z-index: 1 !important;
}

.philosophy .menu-24-instance {
  height: 35px !important;
  margin-bottom: -10px !important;
  position: relative !important;
  width: 36px !important;
}

.philosophy .header-21 {
  align-self: stretch !important;
  display: flex !important;
  flex: 0 0 auto !important;
  justify-content: space-between !important;
  width: 100% !important;
  z-index: 1 !important;
}

.philosophy .header-22 {
  z-index: 1 !important;
}

.philosophy .header-23 {
  height: 59px !important;
  position: relative !important;
  width: 60px !important;
  z-index: 0 !important;
}

.philosophy .div-17 {
  align-items: flex-start;
  align-self: stretch;
  background-color: #ffffff;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  position: relative;
  width: 100%;
  z-index: 0;
}

.philosophy .midashi-4 {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  position: relative;
  width: 100%;
}

.philosophy .class-87 {
  color: #ffffff !important;
  left: 34px !important;
}

.philosophy .class-88 {
  color: #ffffff !important;
  left: -17px !important;
}

.philosophy .class-89 {
  color: #ffffff !important;
  left: 20px !important;
}

.philosophy .class-90 {
  color: #ffffff !important;
  left: 23px !important;
}

.philosophy .class-91 {
  align-self: stretch !important;
  display: flex !important;
  flex: 0 0 auto !important;
  padding: 5px 0px !important;
  width: 100% !important;
}

.philosophy .class-92 {
  align-self: stretch !important;
  flex: 0 0 auto !important;
  width: 100% !important;
}

.philosophy .breadcrumbs-7 {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  gap: 30px;
  justify-content: center;
  position: relative;
  width: 100%;
}

.philosophy .frame-225 {
  align-items: center;
  display: flex;
  flex: 1;
  flex-grow: 1;
  position: relative;
}

.philosophy .text-wrapper-240 {
  color: var(--gray);
  display: block;
  font-family: var(--smalltext-SP-font-family);
  font-size: var(--smalltext-SP-font-size);
  font-style: var(--smalltext-SP-font-style);
  font-weight: var(--smalltext-SP-font-weight);
  letter-spacing: var(--smalltext-SP-letter-spacing);
  line-height: var(--smalltext-SP-line-height);
  margin-top: -1px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.philosophy .instance-node-7 {
  height: 8.14px !important;
  position: relative !important;
  width: 4.57px !important;
}

.philosophy .TOP-3 {
  color: var(--gray);
  margin-top: -1px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.philosophy .text-wrapper-241 {
  color: var(--gray);
  font-family: var(--tag-font-family);
  font-size: var(--tag-font-size);
  font-style: var(--tag-font-style);
  font-weight: var(--tag-font-weight);
  letter-spacing: var(--tag-letter-spacing);
  line-height: var(--tag-line-height);
  margin-top: -1px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.philosophy .corporate {
  align-items: center;
  align-self: stretch;
  background-position: 50% 50%;
  background-size: cover;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 40px;
  position: relative;
  width: 100%;
}

.philosophy .frame-226 {
  align-items: center;
  background-color: #ffffff;
  border-radius: 10px;
  flex: 0 0 auto;
  flex-direction: column;
  opacity: 0.9;
  position: relative;
}

.philosophy .class-93 {
  left: 12px !important;
}

.philosophy .class-94 {
  left: -87px !important;
}

.philosophy .heading-28 {
  position: relative !important;
}

.philosophy .class-95 {
  left: -15px !important;
}

.philosophy .class-96 {
  left: -10px !important;
}

.philosophy .flexcontainer-26 {
  align-items: flex-start;
  flex-direction: column;
  gap: 2px;
}

.philosophy .text-10 {
  align-self: stretch;
  color: #454545;
  font-family: "Noto Sans JP", Helvetica;
  font-weight: 700;
  position: relative;
}

.philosophy .text-11 {
  align-self: stretch;
  color: #454545;
  font-family: "Noto Sans JP", Helvetica;
  font-weight: 700;
  position: relative;
}

.philosophy .text-12 {
  align-self: stretch;
  color: #454545;
  font-family: "Noto Sans JP", Helvetica;
  font-weight: 700;
  position: relative;
}

.philosophy .text-13 {
  align-self: stretch;
  color: #454545;
  font-family: "Noto Sans JP", Helvetica;
  font-weight: 700;
  position: relative;
}

.philosophy .flexcontainer-27 {
  align-items: flex-start;
  flex-direction: column;
}

.philosophy .text-14 {
  align-self: stretch;
  color: var(--darkgray);
  position: relative;
}

.philosophy .text-15 {
  align-self: stretch;
  color: var(--darkgray);
  position: relative;
}

.philosophy .text-16 {
  align-self: stretch;
  color: var(--darkgray);
  position: relative;
}

.philosophy .management {
  align-items: center;
  align-self: stretch;
  background-color: var(--palegray);
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  position: relative;
  width: 100%;
}

.philosophy .class-97 {
  left: -104px !important;
}

.philosophy .heading-29 {
  position: relative !important;
}

.philosophy .class-98 {
  left: -24px !important;
}

.philosophy .text-wrapper-242 {
  color: #454545;
  position: relative;
  text-align: center;
  width: fit-content;
}

.philosophy .flexcontainer-28 {
  align-items: flex-start;
  flex-direction: column;
}

.philosophy .text-17 {
  align-self: stretch;
  color: #454545;
  position: relative;
}

.philosophy .text-18 {
  align-self: stretch;
  color: #454545;
  position: relative;
}

.philosophy .ill {
  position: relative;
}

.philosophy .motto {
  align-items: center;
  align-self: stretch;
  background-color: var(--backwhite);
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  position: relative;
  width: 100%;
}

.philosophy .class-99 {
  left: 34px !important;
}

.philosophy .class-100 {
  left: 16px !important;
}

.philosophy .heading-30 {
  position: relative !important;
}

.philosophy .class-101 {
  left: 36px !important;
}

.philosophy .class-102 {
  left: 23px !important;
}

.philosophy .mottos {
  align-items: flex-start;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  position: relative;
}

.philosophy .frame-227 {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 15px;
  position: relative;
  width: 100%;
}

.philosophy .text-wrapper-243 {
  align-self: stretch;
  margin-top: -1px;
  position: relative;
}

.philosophy .line-instance {
  width: 90px !important;
}

.philosophy .text-wrapper-244 {
  align-self: stretch;
  position: relative;
}

.philosophy .text-wrapper-245 {
  align-self: stretch;
  margin-top: -1px;
  position: relative;
}

.philosophy .text-wrapper-246 {
  align-self: stretch;
  position: relative;
}

.philosophy .text-wrapper-247 {
  align-self: stretch;
  margin-top: -1px;
  position: relative;
}

.philosophy .text-wrapper-248 {
  align-self: stretch;
  position: relative;
}

.philosophy .text-wrapper-249 {
  align-self: stretch;
  margin-top: -1px;
  position: relative;
}

.philosophy .text-wrapper-250 {
  align-self: stretch;
  position: relative;
}

.philosophy .text-wrapper-251 {
  align-self: stretch;
  margin-top: -1px;
  position: relative;
}

.philosophy .text-wrapper-252 {
  align-self: stretch;
  position: relative;
}

.philosophy .text-wrapper-253 {
  align-self: stretch;
  color: var(--darkgray);
  margin-top: -1px;
  position: relative;
}

.philosophy .text-wrapper-254 {
  align-self: stretch;
  position: relative;
}

.philosophy .CONTACT-16 {
  align-items: center;
  background-color: var(--backwhite);
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 40px;
  position: relative;
}

.philosophy .CONTACT-17 {
  display: block;
  height: 160px;
  position: relative;
}

.philosophy .frame-228 {
  align-items: center;
  background-color: var(--purple);
  border-radius: 6px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  max-width: 700px;
  position: relative;
}

.philosophy .group-31 {
  position: relative;
  width: 216px;
}

.philosophy .text-wrapper-255 {
  color: var(--white);
  font-family: "Zen Kaku Gothic New", Helvetica;
  font-size: 20px;
  font-weight: 500;
  left: 0;
  letter-spacing: 2px;
  line-height: normal;
  position: absolute;
  text-align: center;
  top: 0;
  width: 212px;
}

.philosophy .text-wrapper-256 {
  color: var(--white);
  font-family: "Slackside One", Helvetica;
  font-size: 15px;
  font-weight: 400;
  left: 39px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  text-align: center;
  top: 31px;
  width: 134px;
}

.philosophy .overlap-group-9 {
  height: 60px;
  position: relative;
  width: 212px;
}

.philosophy .text-wrapper-257 {
  color: var(--white);
  font-family: "Zen Kaku Gothic New", Helvetica;
  font-size: 24px;
  font-weight: 500;
  left: 0;
  letter-spacing: 2.4px;
  line-height: normal;
  position: absolute;
  text-align: center;
  top: 0;
  width: 212px;
}

.philosophy .text-wrapper-258 {
  color: var(--white);
  font-family: "Slackside One", Helvetica;
  font-size: 20px;
  font-weight: 400;
  left: 39px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  text-align: center;
  top: 31px;
  width: 134px;
}

.philosophy .group-32 {
  position: relative;
}

.philosophy .flexcontainer-29 {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  position: relative;
}

.philosophy .text-i-27 {
  align-self: stretch;
  color: var(--white);
  position: relative;
}

.philosophy .text-i-28 {
  align-self: stretch;
  color: var(--white);
  position: relative;
}

.philosophy .footer-9 {
  align-items: center;
  align-self: stretch;
  background-color: #454545;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 50px;
  position: relative;
  width: 100%;
}

.philosophy .frame-229 {
  align-items: flex-start;
  display: flex;
  flex: 0 0 auto;
  position: relative;
}

.philosophy .frame-230 {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  position: relative;
}

.philosophy .logo-white-15 {
  height: 40px !important;
  position: relative !important;
  width: 121px !important;
}

.philosophy .logo-white-5 {
  height: 54.26px !important;
  position: relative !important;
  width: 163.65px !important;
}

.philosophy .flexcontainer-30 {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
}

.philosophy .text-i-29 {
  align-self: stretch;
  color: #ffffff;
  position: relative;
}

.philosophy .text-i-30 {
  align-self: stretch;
  color: #ffffff;
  position: relative;
}

.philosophy .frame-231 {
  align-items: flex-start;
  flex: 0 0 auto;
  position: relative;
}

.philosophy .frame-232 {
  align-items: flex-start;
  display: inline-flex;
  flex: 0 0 auto;
  flex-direction: column;
  position: relative;
}

.philosophy .frame-233 {
  align-items: flex-start;
  display: inline-flex;
  flex: 0 0 auto;
  flex-direction: column;
  position: relative;
}

.philosophy .text-wrapper-259 {
  color: #ffffff;
  display: block;
  margin-top: -1px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.philosophy .text-wrapper-260 {
  color: #ffffff;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.philosophy .text-wrapper-261 {
  color: #ffffff;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.philosophy .text-wrapper-262 {
  color: #ffffff;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.philosophy .frame-234 {
  align-items: flex-start;
  display: inline-flex;
  flex: 0 0 auto;
  flex-direction: column;
  position: relative;
}

.philosophy .text-wrapper-263 {
  color: #ffffff;
  display: block;
  margin-top: -1px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.philosophy .text-wrapper-264 {
  color: #ffffff;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.philosophy .text-wrapper-265 {
  color: #ffffff;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.philosophy .frame-235 {
  align-items: flex-start;
  display: inline-flex;
  flex: 0 0 auto;
  flex-direction: column;
  position: relative;
}

.philosophy .text-wrapper-266 {
  color: #ffffff;
  display: block;
  margin-top: -1px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.philosophy .text-wrapper-267 {
  color: #ffffff;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.philosophy .text-wrapper-268 {
  color: #ffffff;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.philosophy .frame-236 {
  align-items: flex-start;
  flex-direction: column;
  gap: 30px;
  position: relative;
}

.philosophy .text-wrapper-269 {
  color: #ffffff;
  display: block;
  margin-top: -1px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.philosophy .text-wrapper-270 {
  color: #ffffff;
  display: block;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.philosophy .text-wrapper-271 {
  color: #ffffff;
  display: block;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.philosophy .text-wrapper-272 {
  color: #ffffff;
  display: block;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.philosophy .text-wrapper-273 {
  color: #ffffff;
  display: block;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.philosophy .text-wrapper-274 {
  color: #ffffff;
  font-family: "Manrope", Helvetica;
  font-size: 13.1px;
  font-weight: 400;
  letter-spacing: 1.31px;
  line-height: normal;
  position: relative;
  width: fit-content;
}
