.recruits {
  align-items: center;
  background-color: var(--backwhite);
  display: flex;
  flex-direction: column;
  position: relative;
}

.recruits .header-15 {
  align-self: stretch !important;
  flex: 0 0 auto !important;
  height: unset !important;
  width: 100% !important;
  z-index: 1 !important;
}

.recruits .header-16 {
  margin-bottom: unset !important;
}

.recruits .menu-2-instance {
  height: 35px !important;
  position: relative !important;
  width: 36px !important;
}

.recruits .header-17 {
  align-self: stretch !important;
  display: flex !important;
  flex: 0 0 auto !important;
  justify-content: space-between !important;
  width: 100% !important;
  z-index: 1 !important;
}

.recruits .header-18 {
  z-index: 1 !important;
}

.recruits .header-19 {
  height: 59px !important;
  position: relative !important;
  width: 60px !important;
  z-index: 0 !important;
}

.recruits .div-16 {
  align-items: flex-start;
  align-self: stretch;
  background-color: #ffffff;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  position: relative;
  width: 100%;
  z-index: 0;
}

.recruits .midashi-3 {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  position: relative;
  width: 100%;
}

.recruits .class-66 {
  color: #ffffff !important;
  left: 12px !important;
}

.recruits .class-67 {
  color: #ffffff !important;
  left: 10px !important;
}

.recruits .class-68 {
  color: #ffffff !important;
  left: 33px !important;
}

.recruits .class-69 {
  color: #ffffff !important;
  left: -10px !important;
}

.recruits .class-70 {
  align-self: stretch !important;
  display: flex !important;
  flex: 0 0 auto !important;
  padding: 5px 0px !important;
  width: 100% !important;
}

.recruits .class-71 {
  align-self: stretch !important;
  flex: 0 0 auto !important;
  width: 100% !important;
}

.recruits .breadcrumbs-6 {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  gap: 30px;
  justify-content: center;
  position: relative;
  width: 100%;
}

.recruits .frame-207 {
  align-items: center;
  display: flex;
  flex: 1;
  flex-grow: 1;
  position: relative;
}

.recruits .text-wrapper-206 {
  color: var(--gray);
  display: block;
  font-family: var(--smalltext-SP-font-family);
  font-size: var(--smalltext-SP-font-size);
  font-style: var(--smalltext-SP-font-style);
  font-weight: var(--smalltext-SP-font-weight);
  letter-spacing: var(--smalltext-SP-letter-spacing);
  line-height: var(--smalltext-SP-line-height);
  margin-top: -1px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.recruits .instance-node-6 {
  height: 8.14px !important;
  position: relative !important;
  width: 4.57px !important;
}

.recruits .TOP-2 {
  color: var(--gray);
  margin-top: -1px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.recruits .text-wrapper-207 {
  color: var(--gray);
  font-family: var(--tag-font-family);
  font-size: var(--tag-font-size);
  font-style: var(--tag-font-style);
  font-weight: var(--tag-font-weight);
  letter-spacing: var(--tag-letter-spacing);
  line-height: var(--tag-line-height);
  margin-top: -1px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.recruits .component-10930-2 {
  height: 8.14px !important;
  left: unset !important;
  position: relative !important;
  top: unset !important;
  width: 4.57px !important;
}

.recruits .recruit-list {
  align-items: center;
  align-self: stretch;
  background-color: var(--palepurple);
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 40px;
  position: relative;
  width: 100%;
}

.recruits .frame-208 {
  align-items: flex-start;
  flex: 0 0 auto;
  flex-wrap: wrap;
  justify-content: center;
  position: relative;
}

.recruits .class-72 {
  font-family: var(--smalltextbold-SP-font-family) !important;
  font-size: var(--smalltextbold-SP-font-size) !important;
  font-style: var(--smalltextbold-SP-font-style) !important;
  font-weight: var(--smalltextbold-SP-font-weight) !important;
  letter-spacing: var(--smalltextbold-SP-letter-spacing) !important;
  line-height: var(--smalltextbold-SP-line-height) !important;
}

.recruits .class-73 {
  flex: 1 !important;
  flex-grow: 1 !important;
  height: unset !important;
  max-width: 360px !important;
  min-width: 290px !important;
  width: unset !important;
}

.recruits .class-74 {
  height: 442px !important;
  max-width: 360px !important;
  width: 360px !important;
}

.recruits .class-75 {
  display: inline-flex !important;
  flex: 0 0 auto !important;
  padding: 8px 30px !important;
  width: unset !important;
}

.recruits .class-76 {
  width: 120px !important;
}

.recruits .class-77 {
  font-family: var(--button-SP-font-family) !important;
  font-size: var(--button-SP-font-size) !important;
  font-style: var(--button-SP-font-style) !important;
  font-weight: var(--button-SP-font-weight) !important;
  letter-spacing: var(--button-SP-letter-spacing) !important;
  line-height: var(--button-SP-line-height) !important;
  white-space: unset !important;
}

.recruits .class-78 {
  margin-left: -12px !important;
  margin-right: -12px !important;
}

.recruits .class-79 {
  flex: 0 0 auto !important;
  flex-grow: unset !important;
}

.recruits .noimage-4 {
  height: 25.92px !important;
  position: relative !important;
  width: 190.9px !important;
}

.recruits .class-80 {
  font-family: var(--button-SP-font-family) !important;
  font-size: var(--button-SP-font-size) !important;
  font-style: var(--button-SP-font-style) !important;
  font-weight: var(--button-SP-font-weight) !important;
  letter-spacing: var(--button-SP-letter-spacing) !important;
  line-height: var(--button-SP-line-height) !important;
  margin-left: unset !important;
  margin-right: unset !important;
  white-space: unset !important;
}

.recruits .class-81 {
  margin-left: -19.5px !important;
  margin-right: -19.5px !important;
}

.recruits .class-82 {
  font-family: var(--textbold-SP-font-family) !important;
  font-size: var(--textbold-SP-font-size) !important;
  font-style: var(--textbold-SP-font-style) !important;
  font-weight: var(--textbold-SP-font-weight) !important;
  letter-spacing: var(--textbold-SP-letter-spacing) !important;
  line-height: var(--textbold-SP-line-height) !important;
  margin-left: unset !important;
  margin-right: unset !important;
}

.recruits .frame-209 {
  height: 16px;
  position: relative;
}

.recruits .text-wrapper-208 {
  color: #454545;
  font-family: var(--tag-font-family);
  font-size: var(--tag-font-size);
  font-style: var(--tag-font-style);
  font-weight: var(--tag-font-weight);
  left: 29px;
  letter-spacing: var(--tag-letter-spacing);
  line-height: var(--tag-line-height);
  position: absolute;
  white-space: nowrap;
}

.recruits .text-wrapper-209 {
  color: #454545;
  font-family: var(--tag-font-family);
  font-size: var(--tag-font-size);
  font-style: var(--tag-font-style);
  font-weight: var(--tag-font-weight);
  left: 57px;
  letter-spacing: var(--tag-letter-spacing);
  line-height: var(--tag-line-height);
  position: absolute;
  white-space: nowrap;
}

.recruits .text-wrapper-210 {
  color: #454545;
  font-family: var(--tag-font-family);
  font-size: var(--tag-font-size);
  font-style: var(--tag-font-style);
  font-weight: var(--tag-font-weight);
  left: 85px;
  letter-spacing: var(--tag-letter-spacing);
  line-height: var(--tag-line-height);
  position: absolute;
  white-space: nowrap;
}

.recruits .text-wrapper-211 {
  color: #454545;
  font-family: var(--tag-font-family);
  font-size: var(--tag-font-size);
  font-style: var(--tag-font-style);
  font-weight: var(--tag-font-weight);
  left: 113px;
  letter-spacing: var(--tag-letter-spacing);
  line-height: var(--tag-line-height);
  position: absolute;
  white-space: nowrap;
}

.recruits .text-wrapper-212 {
  color: #454545;
  font-family: var(--tag-font-family);
  font-size: var(--tag-font-size);
  font-style: var(--tag-font-style);
  font-weight: var(--tag-font-weight);
  left: 141px;
  letter-spacing: var(--tag-letter-spacing);
  line-height: var(--tag-line-height);
  position: absolute;
  white-space: nowrap;
}

.recruits .text-wrapper-213 {
  color: #454545;
  font-family: var(--tag-font-family);
  font-size: var(--tag-font-size);
  font-style: var(--tag-font-style);
  font-weight: var(--tag-font-weight);
  left: 169px;
  letter-spacing: var(--tag-letter-spacing);
  line-height: var(--tag-line-height);
  position: absolute;
  white-space: nowrap;
}

.recruits .class-83 {
  height: 16px !important;
  left: 197px !important;
  position: absolute !important;
  top: 0 !important;
  width: 10px !important;
}

.recruits .class-84 {
  height: 17px !important;
  left: 197px !important;
  position: absolute !important;
  top: 0 !important;
  width: 10px !important;
}

.recruits .class-85 {
  height: 16px !important;
  left: 0 !important;
  position: absolute !important;
  top: 0 !important;
  width: 10px !important;
}

.recruits .class-86 {
  height: 17px !important;
  left: -1px !important;
  position: absolute !important;
  top: 0 !important;
  width: 10px !important;
}

.recruits .CONTACT-14 {
  align-items: center;
  background-color: var(--backwhite);
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 40px;
  position: relative;
}

.recruits .CONTACT-15 {
  display: block;
  height: 160px;
  position: relative;
}

.recruits .frame-210 {
  align-items: center;
  background-color: var(--purple);
  border-radius: 6px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  max-width: 700px;
  position: relative;
}

.recruits .group-29 {
  position: relative;
  width: 216px;
}

.recruits .text-wrapper-214 {
  color: var(--white);
  font-family: "Zen Kaku Gothic New", Helvetica;
  font-size: 20px;
  font-weight: 500;
  left: 0;
  letter-spacing: 2px;
  line-height: normal;
  position: absolute;
  text-align: center;
  top: 0;
  width: 212px;
}

.recruits .text-wrapper-215 {
  color: var(--white);
  font-family: "Slackside One", Helvetica;
  font-size: 15px;
  font-weight: 400;
  left: 39px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  text-align: center;
  top: 31px;
  width: 134px;
}

.recruits .overlap-group-8 {
  height: 60px;
  position: relative;
  width: 212px;
}

.recruits .text-wrapper-216 {
  color: var(--white);
  font-family: "Zen Kaku Gothic New", Helvetica;
  font-size: 24px;
  font-weight: 500;
  left: 0;
  letter-spacing: 2.4px;
  line-height: normal;
  position: absolute;
  text-align: center;
  top: 0;
  width: 212px;
}

.recruits .text-wrapper-217 {
  color: var(--white);
  font-family: "Slackside One", Helvetica;
  font-size: 20px;
  font-weight: 400;
  left: 39px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  text-align: center;
  top: 31px;
  width: 134px;
}

.recruits .group-30 {
  position: relative;
}

.recruits .flexcontainer-24 {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  position: relative;
}

.recruits .text-i-23 {
  align-self: stretch;
  color: var(--white);
  position: relative;
}

.recruits .text-i-24 {
  align-self: stretch;
  color: var(--white);
  position: relative;
}

.recruits .footer-8 {
  align-items: center;
  align-self: stretch;
  background-color: #454545;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 50px;
  position: relative;
  width: 100%;
}

.recruits .frame-211 {
  align-items: flex-start;
  display: flex;
  flex: 0 0 auto;
  position: relative;
}

.recruits .frame-212 {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  position: relative;
}

.recruits .logo-white-28 {
  height: 40px !important;
  position: relative !important;
  width: 121px !important;
}

.recruits .logo-white-24 {
  height: 54.26px !important;
  position: relative !important;
  width: 163.65px !important;
}

.recruits .flexcontainer-25 {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
}

.recruits .text-i-25 {
  align-self: stretch;
  color: #ffffff;
  position: relative;
}

.recruits .text-i-26 {
  align-self: stretch;
  color: #ffffff;
  position: relative;
}

.recruits .frame-213 {
  align-items: flex-start;
  flex: 0 0 auto;
  position: relative;
}

.recruits .frame-214 {
  align-items: flex-start;
  display: inline-flex;
  flex: 0 0 auto;
  flex-direction: column;
  position: relative;
}

.recruits .frame-215 {
  align-items: flex-start;
  display: inline-flex;
  flex: 0 0 auto;
  flex-direction: column;
  position: relative;
}

.recruits .text-wrapper-218 {
  color: #ffffff;
  display: block;
  margin-top: -1px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.recruits .text-wrapper-219 {
  color: #ffffff;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.recruits .text-wrapper-220 {
  color: #ffffff;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.recruits .text-wrapper-221 {
  color: #ffffff;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.recruits .frame-216 {
  align-items: flex-start;
  display: inline-flex;
  flex: 0 0 auto;
  flex-direction: column;
  position: relative;
}

.recruits .text-wrapper-222 {
  color: #ffffff;
  display: block;
  margin-top: -1px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.recruits .text-wrapper-223 {
  color: #ffffff;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.recruits .text-wrapper-224 {
  color: #ffffff;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.recruits .frame-217 {
  align-items: flex-start;
  display: inline-flex;
  flex: 0 0 auto;
  flex-direction: column;
  position: relative;
}

.recruits .text-wrapper-225 {
  color: #ffffff;
  display: block;
  margin-top: -1px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.recruits .text-wrapper-226 {
  color: #ffffff;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.recruits .text-wrapper-227 {
  color: #ffffff;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.recruits .frame-218 {
  align-items: flex-start;
  flex-direction: column;
  gap: 30px;
  position: relative;
}

.recruits .text-wrapper-228 {
  color: #ffffff;
  display: block;
  margin-top: -1px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.recruits .text-wrapper-229 {
  color: #ffffff;
  display: block;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.recruits .text-wrapper-230 {
  color: #ffffff;
  display: block;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.recruits .text-wrapper-231 {
  color: #ffffff;
  display: block;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.recruits .text-wrapper-232 {
  color: #ffffff;
  display: block;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.recruits .text-wrapper-233 {
  color: #ffffff;
  font-family: "Manrope", Helvetica;
  font-size: 13.1px;
  font-weight: 400;
  letter-spacing: 1.31px;
  line-height: normal;
  position: relative;
  width: fit-content;
}
