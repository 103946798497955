.recruits-screen {
  align-items: center;
  display: flex;
  flex-direction: column;
  position: relative;
}

.recruits-screen .header-40 {
  align-self: stretch !important;
  display: flex !important;
  flex: 0 0 auto !important;
  justify-content: space-between !important;
  width: 100% !important;
  z-index: 1 !important;
}

.recruits-screen .header-41 {
  height: 54px !important;
  position: relative !important;
  top: 16px !important;
  width: 145px !important;
}

.recruits-screen .recruits-2 {
  align-items: flex-start;
  align-self: stretch;
  background-color: #ffffff;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  overflow: hidden;
  position: relative;
  width: 100%;
  z-index: 0;
}

.recruits-screen .midashi-11 {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  position: relative;
  width: 100%;
}

.recruits-screen .heading-44 {
  align-self: stretch !important;
  background-image: url(../../../static/img/midashi-1.png) !important;
  flex: 0 0 auto !important;
  width: 100% !important;
}

.recruits-screen .heading-45 {
  color: #ffffff !important;
  left: 10px !important;
}

.recruits-screen .heading-46 {
  color: #ffffff !important;
  left: -10px !important;
}

.recruits-screen .breadcrumbs-12 {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  gap: 30px;
  justify-content: center;
  padding: 40px 15px;
  position: relative;
  width: 100%;
}

.recruits-screen .frame-389 {
  align-items: center;
  display: flex;
  flex: 1;
  flex-grow: 1;
  gap: 30px;
  position: relative;
}

.recruits-screen .text-wrapper-489 {
  color: var(--gray);
  display: block;
  font-family: var(--tag-font-family);
  font-size: var(--tag-font-size);
  font-style: var(--tag-font-style);
  font-weight: var(--tag-font-weight);
  letter-spacing: var(--tag-letter-spacing);
  line-height: var(--tag-line-height);
  margin-top: -1px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.recruits-screen .component-10930-7 {
  height: 8.14px !important;
  left: unset !important;
  position: relative !important;
  top: unset !important;
  width: 4.57px !important;
}

.recruits-screen .text-wrapper-490 {
  color: var(--gray);
  font-family: var(--tag-font-family);
  font-size: var(--tag-font-size);
  font-style: var(--tag-font-style);
  font-weight: var(--tag-font-weight);
  letter-spacing: var(--tag-letter-spacing);
  line-height: var(--tag-line-height);
  margin-top: -1px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.recruits-screen .recruit-list-2 {
  align-items: center;
  align-self: stretch;
  background-color: var(--palepurple);
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 40px;
  padding: 80px 15px;
  position: relative;
  width: 100%;
}

.recruits-screen .frame-390 {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  flex-wrap: wrap;
  gap: 30px 10px;
  justify-content: center;
  position: relative;
  width: 100%;
}

.recruits-screen .frame-391 {
  align-items: flex-start;
  background-color: #ffffff;
  border: 1px solid;
  border-color: #efeeee;
  border-radius: 10px;
  display: flex;
  flex: 1;
  flex-direction: column;
  flex-grow: 1;
  gap: 14px;
  height: 442px;
  max-width: 360px;
  min-width: 290px;
  padding: 12px 12px 20px;
  position: relative;
  transition: all 0.3s ease;
}

.recruits-screen .frame-392 {
  align-items: center;
  align-self: stretch;
  background-color: #959595;
  border-radius: 6px;
  display: flex;
  flex: 1;
  flex-grow: 1;
  gap: 10px;
  justify-content: center;
  max-height: 220px;
  min-height: 200px;
  position: relative;
  width: 100%;
}

.recruits-screen .noimage-12 {
  height: 25.92px !important;
  position: relative !important;
  width: 190.9px !important;
}

.recruits-screen .frame-393 {
  align-items: center;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  flex-wrap: wrap;
  gap: 10px 14px;
  justify-content: space-between;
  padding: 0px 5px;
  position: relative;
  width: 100%;
}

.recruits-screen .frame-394 {
  width: 120px !important;
}

.recruits-screen .frame-395 {
  margin-left: -12px !important;
  margin-right: -12px !important;
}

.recruits-screen .frame-396 {
  align-items: center;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 10px;
  padding: 0px 0px 0px 20px;
  position: relative;
}

.recruits-screen .text-wrapper-491 {
  color: #8a8a8a;
  font-family: "Manrope", Helvetica;
  font-size: 12px;
  font-weight: 400;
  letter-spacing: 1.2px;
  line-height: normal;
  margin-top: -1px;
  position: relative;
  width: fit-content;
}

.recruits-screen .vector-4 {
  align-self: stretch;
  height: 1px;
  object-fit: cover;
  position: relative;
  width: 100%;
}

.recruits-screen .text-wrapper-492 {
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  align-self: stretch;
  color: #454545;
  display: -webkit-box;
  font-family: "Noto Sans JP", Helvetica;
  font-size: 15px;
  font-weight: 700;
  letter-spacing: 0.75px;
  line-height: 26px;
  overflow: hidden;
  position: relative;
  text-overflow: ellipsis;
}

.recruits-screen .frame-397 {
  height: 16px;
  position: relative;
  width: 206.48px;
}

.recruits-screen .text-wrapper-493 {
  color: #454545;
  font-family: var(--tag-font-family);
  font-size: var(--tag-font-size);
  font-style: var(--tag-font-style);
  font-weight: var(--tag-font-weight);
  left: 29px;
  letter-spacing: var(--tag-letter-spacing);
  line-height: var(--tag-line-height);
  position: absolute;
  top: -1px;
  white-space: nowrap;
}

.recruits-screen .text-wrapper-494 {
  color: #454545;
  font-family: var(--tag-font-family);
  font-size: var(--tag-font-size);
  font-style: var(--tag-font-style);
  font-weight: var(--tag-font-weight);
  left: 57px;
  letter-spacing: var(--tag-letter-spacing);
  line-height: var(--tag-line-height);
  position: absolute;
  top: -1px;
  white-space: nowrap;
}

.recruits-screen .text-wrapper-495 {
  color: #454545;
  font-family: var(--tag-font-family);
  font-size: var(--tag-font-size);
  font-style: var(--tag-font-style);
  font-weight: var(--tag-font-weight);
  left: 85px;
  letter-spacing: var(--tag-letter-spacing);
  line-height: var(--tag-line-height);
  position: absolute;
  top: -1px;
  white-space: nowrap;
}

.recruits-screen .text-wrapper-496 {
  color: #454545;
  font-family: var(--tag-font-family);
  font-size: var(--tag-font-size);
  font-style: var(--tag-font-style);
  font-weight: var(--tag-font-weight);
  left: 113px;
  letter-spacing: var(--tag-letter-spacing);
  line-height: var(--tag-line-height);
  position: absolute;
  top: -1px;
  white-space: nowrap;
}

.recruits-screen .text-wrapper-497 {
  color: #454545;
  font-family: var(--tag-font-family);
  font-size: var(--tag-font-size);
  font-style: var(--tag-font-style);
  font-weight: var(--tag-font-weight);
  left: 141px;
  letter-spacing: var(--tag-letter-spacing);
  line-height: var(--tag-line-height);
  position: absolute;
  top: -1px;
  white-space: nowrap;
}

.recruits-screen .text-wrapper-498 {
  color: #454545;
  font-family: var(--tag-font-family);
  font-size: var(--tag-font-size);
  font-style: var(--tag-font-style);
  font-weight: var(--tag-font-weight);
  left: 169px;
  letter-spacing: var(--tag-letter-spacing);
  line-height: var(--tag-line-height);
  position: absolute;
  top: -1px;
  white-space: nowrap;
}

.recruits-screen .polygon-2-7-instance {
  height: 16px !important;
  left: 197px !important;
  position: absolute !important;
  top: 0 !important;
  width: 10px !important;
}

.recruits-screen .polygon-3-2 {
  height: 16px !important;
  left: 0 !important;
  position: absolute !important;
  top: 0 !important;
  width: 10px !important;
}

.recruits-screen .frame-398 {
  align-items: center;
  align-self: stretch;
  background-color: var(--backwhite);
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 40px;
  padding: 100px 15px;
  position: relative;
  width: 100%;
}

.recruits-screen .group-69 {
  height: 138px;
  position: relative;
  width: 562px;
}

.recruits-screen .frame-399 {
  align-items: center;
  background-color: var(--purple);
  border-radius: 6px;
  display: inline-flex;
  flex-wrap: wrap;
  gap: 20px 40px;
  justify-content: center;
  max-width: 700px;
  min-width: 280px;
  padding: 40px 70px;
  position: relative;
}

.recruits-screen .group-70 {
  height: 58px;
  position: relative;
  width: 134px;
}

.recruits-screen .text-wrapper-499 {
  color: var(--white);
  font-family: "Zen Kaku Gothic New", Helvetica;
  font-size: 20px;
  font-weight: 500;
  left: 0;
  letter-spacing: 2px;
  line-height: normal;
  position: absolute;
  text-align: center;
  top: 0;
}

.recruits-screen .text-wrapper-500 {
  color: var(--white);
  font-family: "Slackside One", Helvetica;
  font-size: 20px;
  font-weight: 400;
  left: 24px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  text-align: center;
  top: 29px;
}

.recruits-screen .group-71 {
  height: 55px;
  margin-right: -2px;
  position: relative;
  width: 254px;
}

.recruits-screen .flexcontainer-52 {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  gap: 5px;
  height: 55px;
  position: relative;
  width: 252px;
}

.recruits-screen .text-34 {
  align-self: stretch;
  color: var(--white);
  font-family: "Noto Sans JP", Helvetica;
  font-size: 15px;
  font-weight: 400;
  letter-spacing: 0.75px;
  line-height: 25px;
  position: relative;
}

.recruits-screen .text-wrapper-501 {
  letter-spacing: 0.11px;
}

.recruits-screen .footer-18 {
  align-items: center;
  background-color: #454545;
  display: inline-flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 50px;
  margin-right: -496px;
  padding: 70px 20px 50px;
  position: relative;
}

.recruits-screen .frame-400 {
  align-items: flex-start;
  display: flex;
  flex: 0 0 auto;
  flex-wrap: wrap;
  gap: 50px 150px;
  justify-content: space-between;
  padding: 0px 100px;
  position: relative;
  width: 1200px;
}

.recruits-screen .frame-401 {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  gap: 44px;
  position: relative;
  width: 617px;
}

.recruits-screen .logo-white-16 {
  height: 54.26px !important;
  left: unset !important;
  position: relative !important;
  top: unset !important;
  width: 163.65px !important;
}

.recruits-screen .flexcontainer-53 {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.recruits-screen .text-i-53 {
  align-self: stretch;
  color: #ffffff;
  font-family: var(--text-font-family);
  font-size: var(--text-font-size);
  font-style: var(--text-font-style);
  font-weight: var(--text-font-weight);
  letter-spacing: var(--text-letter-spacing);
  line-height: var(--text-line-height);
  position: relative;
}

.recruits-screen .text-wrapper-502 {
  font-family: var(--text-font-family);
  font-size: var(--text-font-size);
  font-style: var(--text-font-style);
  font-weight: var(--text-font-weight);
  letter-spacing: var(--text-letter-spacing);
  line-height: var(--text-line-height);
}

.recruits-screen .frame-402 {
  align-items: flex-start;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 60px;
  position: relative;
}

.recruits-screen .frame-403 {
  align-items: flex-start;
  display: inline-flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 30px;
  position: relative;
}

.recruits-screen .frame-404 {
  align-items: flex-start;
  display: inline-flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 6px;
  position: relative;
}

.recruits-screen .text-wrapper-503 {
  color: #ffffff;
  font-family: var(--text-bold-mini-font-family);
  font-size: var(--text-bold-mini-font-size);
  font-style: var(--text-bold-mini-font-style);
  font-weight: var(--text-bold-mini-font-weight);
  letter-spacing: var(--text-bold-mini-letter-spacing);
  line-height: var(--text-bold-mini-line-height);
  margin-top: -1px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.recruits-screen .text-wrapper-504 {
  color: #ffffff;
  font-family: var(--text-bold-mini-font-family);
  font-size: var(--text-bold-mini-font-size);
  font-style: var(--text-bold-mini-font-style);
  font-weight: var(--text-bold-mini-font-weight);
  letter-spacing: var(--text-bold-mini-letter-spacing);
  line-height: var(--text-bold-mini-line-height);
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.recruits-screen .text-wrapper-505 {
  color: #ffffff;
  display: block;
  font-family: var(--text-bold-mini-font-family);
  font-size: var(--text-bold-mini-font-size);
  font-style: var(--text-bold-mini-font-style);
  font-weight: var(--text-bold-mini-font-weight);
  letter-spacing: var(--text-bold-mini-letter-spacing);
  line-height: var(--text-bold-mini-line-height);
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.recruits-screen .text-wrapper-506 {
  color: #ffffff;
  font-family: "Manrope", Helvetica;
  font-size: 13.1px;
  font-weight: 400;
  letter-spacing: 1.31px;
  line-height: normal;
  position: relative;
  width: fit-content;
}
