.recruit-wrapper {
  align-items: flex-start;
  background-color: #ffffff;
  display: flex;
  flex-direction: column;
  position: relative;
}

.recruit-wrapper .div-27 {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  position: relative;
  width: 100%;
}

.recruit-wrapper .logo-6 {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  justify-content: space-between;
  padding: 0px 0px 20px 30px;
  position: relative;
  width: 100%;
}

.recruit-wrapper .frame-367 {
  height: 53px;
  position: relative;
  width: 164px;
}

.recruit-wrapper .group-62 {
  background-image: url(../../../static/img/zsykr-0.png);
  background-size: 100% 100%;
  height: 38px;
  position: relative;
  top: 15px;
  width: 101px;
}

.recruit-wrapper .menu-15 {
  height: 52px;
  position: relative;
  width: 53px;
}

.recruit-wrapper .midashi-10 {
  align-items: center;
  align-self: stretch;
  background-image: url(../../../static/img/midashi.png);
  background-position: 50% 50%;
  background-size: cover;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 10px;
  padding: 10px 0px;
  position: relative;
  width: 100%;
}

.recruit-wrapper .component-3 {
  height: 89px;
  position: relative;
  width: 108px;
}

.recruit-wrapper .text-wrapper-466 {
  color: #ffffff;
  font-family: "Zen Kaku Gothic New", Helvetica;
  font-size: 28px;
  font-weight: 500;
  left: -4px;
  letter-spacing: 1.4px;
  line-height: normal;
  position: absolute;
  text-align: center;
  top: 20px;
  white-space: nowrap;
}

.recruit-wrapper .TEXTEXT-3 {
  color: #ffffff;
  font-family: "Slackside One", Helvetica;
  font-size: 18px;
  font-weight: 400;
  left: 22px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  text-align: center;
  top: 54px;
  white-space: nowrap;
}

.recruit-wrapper .frame-368 {
  align-items: center;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  gap: 30px;
  padding: 20px 30px;
  position: relative;
  width: 100%;
}

.recruit-wrapper .group-63 {
  height: 15px;
  position: relative;
  width: 137.57px;
}

.recruit-wrapper .text-wrapper-467 {
  color: var(--gray);
  display: block;
  font-family: var(--tag-font-family);
  font-size: var(--tag-font-size);
  font-style: var(--tag-font-style);
  font-weight: var(--tag-font-weight);
  left: 0;
  letter-spacing: var(--tag-letter-spacing);
  line-height: var(--tag-line-height);
  position: absolute;
  top: 0;
  white-space: nowrap;
}

.recruit-wrapper .component-10930-6 {
  left: 58px !important;
  top: 3px !important;
}

.recruit-wrapper .text-wrapper-468 {
  color: var(--gray);
  font-family: var(--tag-font-family);
  font-size: var(--tag-font-size);
  font-style: var(--tag-font-style);
  font-weight: var(--tag-font-weight);
  left: 93px;
  letter-spacing: var(--tag-letter-spacing);
  line-height: var(--tag-line-height);
  position: absolute;
  top: 0;
  white-space: nowrap;
}

.recruit-wrapper .view-7 {
  align-items: center;
  align-self: stretch;
  background-color: var(--palepurple);
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 50px;
  padding: 50px 10px;
  position: relative;
  width: 100%;
}

.recruit-wrapper .frame-369 {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 20px;
  justify-content: center;
  position: relative;
  width: 100%;
}

.recruit-wrapper .frame-370 {
  align-items: flex-start;
  align-self: stretch;
  background-color: var(--back-white);
  border-radius: 14px;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 32px;
  padding: 50px 20px;
  position: relative;
  width: 100%;
}

.recruit-wrapper .frame-371 {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 30px;
  max-width: 800px;
  position: relative;
  width: 100%;
}

.recruit-wrapper .group-64 {
  height: 18.74px;
  position: relative;
  width: 93px;
}

.recruit-wrapper .text-wrapper-469 {
  color: var(--black);
  font-family: var(--date-font-family);
  font-size: var(--date-font-size);
  font-style: var(--date-font-style);
  font-weight: var(--date-font-weight);
  left: 0;
  letter-spacing: var(--date-letter-spacing);
  line-height: var(--date-line-height);
  position: absolute;
  top: 0;
  width: 91px;
}

.recruit-wrapper .frame-372 {
  align-items: flex-end;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  flex-wrap: wrap;
  gap: 0px 28px;
  position: relative;
  width: 100%;
}

.recruit-wrapper .text-wrapper-470 {
  color: var(--purple);
  font-family: var(--tag-font-family);
  font-size: var(--tag-font-size);
  font-style: var(--tag-font-style);
  font-weight: var(--tag-font-weight);
  letter-spacing: var(--tag-letter-spacing);
  line-height: var(--tag-line-height);
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.recruit-wrapper .text-wrapper-471 {
  color: var(--purple);
  font-family: var(--tag-font-family);
  font-size: var(--tag-font-size);
  font-style: var(--tag-font-style);
  font-weight: var(--tag-font-weight);
  height: 17px;
  letter-spacing: var(--tag-letter-spacing);
  line-height: var(--tag-line-height);
  margin-top: -1px;
  position: relative;
  white-space: nowrap;
  width: 43px;
}

.recruit-wrapper .text-wrapper-472 {
  color: var(--purple);
  font-family: var(--tag-font-family);
  font-size: var(--tag-font-size);
  font-style: var(--tag-font-style);
  font-weight: var(--tag-font-weight);
  height: 17px;
  letter-spacing: var(--tag-letter-spacing);
  line-height: var(--tag-line-height);
  position: relative;
  white-space: nowrap;
  width: 43px;
}

.recruit-wrapper .div-28 {
  align-self: stretch;
  color: #454545;
  font-family: "Noto Sans JP", Helvetica;
  font-size: 20px;
  font-weight: 700;
  letter-spacing: 1px;
  line-height: 20px;
  position: relative;
}

.recruit-wrapper .text-wrapper-473 {
  letter-spacing: 0.2px;
  line-height: 37px;
}

.recruit-wrapper .text-wrapper-474 {
  letter-spacing: 0.2px;
  line-height: 36px;
}

.recruit-wrapper .grayline-7 {
  align-self: stretch !important;
  background-image: url(../../../static/img/line-13-1.svg) !important;
  top: unset !important;
  width: 100% !important;
}

.recruit-wrapper .frame-373 {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 31px;
  position: relative;
  width: 100%;
}

.recruit-wrapper .frame-374 {
  align-items: center;
  align-self: stretch;
  background-color: var(--gray);
  border-radius: 6px;
  display: flex;
  flex-direction: column;
  gap: 10px;
  height: 233.92px;
  justify-content: center;
  overflow: hidden;
  padding: 104px 70px;
  position: relative;
  width: 100%;
}

.recruit-wrapper .noimage-11 {
  height: 25.92px !important;
  left: unset !important;
  margin-left: -38.35px !important;
  margin-right: -38.35px !important;
  position: relative !important;
  top: unset !important;
  width: 196.71px !important;
}

.recruit-wrapper .frame-375 {
  align-items: center;
  align-self: stretch;
  background-color: var(--gray);
  border-radius: 6px;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 10px;
  justify-content: center;
  overflow: hidden;
  padding: 104px 70px;
  position: relative;
  width: 100%;
}

.recruit-wrapper .text-wrapper-475 {
  align-self: stretch;
  color: #454545;
  font-family: var(--text-font-family);
  font-size: var(--text-font-size);
  font-style: var(--text-font-style);
  font-weight: var(--text-font-weight);
  letter-spacing: var(--text-letter-spacing);
  line-height: var(--text-line-height);
  position: relative;
}

.recruit-wrapper .frame-376 {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  justify-content: space-between;
  position: relative;
  width: 100%;
}

.recruit-wrapper .group-65 {
  flex: 1;
  flex-grow: 1;
  height: 50px;
  position: relative;
}

.recruit-wrapper .text-wrapper-476 {
  color: var(--darkgray);
  font-family: "Noto Sans JP", Helvetica;
  font-size: 15px;
  font-weight: 400;
  left: 8px;
  letter-spacing: 0.75px;
  line-height: 25px;
  position: absolute;
  top: 0;
  width: 72px;
}

.recruit-wrapper .polygon-8 {
  left: -1px !important;
  top: 5px !important;
  width: 5px !important;
}

.recruit-wrapper .frame-377 {
  display: flex !important;
  flex: 1 !important;
  flex-grow: 1 !important;
  margin-left: -2px !important;
  max-width: 200px !important;
  min-width: 140px !important;
  padding: 17px 30px !important;
}

.recruit-wrapper .frame-378 {
  font-weight: 700 !important;
}

.recruit-wrapper .group-66 {
  flex: 1;
  flex-grow: 1;
  height: 50px;
  margin-left: -2px;
  margin-right: -2px;
  position: relative;
}

.recruit-wrapper .text-wrapper-477 {
  color: var(--darkgray);
  font-family: "Noto Sans JP", Helvetica;
  font-size: 15px;
  font-weight: 400;
  left: 0;
  letter-spacing: 0.75px;
  line-height: 25px;
  position: absolute;
  text-align: right;
  top: 0;
  width: 69px;
}

.recruit-wrapper .polygon-9 {
  left: 76px !important;
  top: 5px !important;
  width: 5px !important;
}

.recruit-wrapper .frame-379 {
  align-items: center;
  align-self: stretch;
  background-color: #6079c1;
  border-radius: 10px 10px 0px 0px;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 30px;
  padding: 20px 15px;
  position: relative;
  width: 100%;
}

.recruit-wrapper .text-wrapper-478 {
  color: #ffffff;
  font-family: "Noto Sans JP", Helvetica;
  font-size: 16px;
  font-weight: 700;
  letter-spacing: 0;
  line-height: normal;
  margin-top: -1px;
  position: relative;
  text-align: center;
  width: fit-content;
}

.recruit-wrapper .frame-380 {
  align-items: center;
  align-self: stretch;
  background-color: #ffffff;
  border-radius: 0px 0px 10px 10px;
  display: flex;
  flex-direction: column;
  gap: 30px;
  height: 430px;
  padding: 30px 15px 45px;
  position: relative;
  width: 100%;
}

.recruit-wrapper .frame-381 {
  align-items: center;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  justify-content: space-between;
  padding: 0px 10px;
  position: relative;
  width: 100%;
}

.recruit-wrapper .text-wrapper-479 {
  color: var(--darkgray);
  flex: 1;
  font-family: "Noto Sans JP", Helvetica;
  font-size: 15px;
  font-weight: 600;
  letter-spacing: 0.75px;
  line-height: 26px;
  margin-top: -1px;
  position: relative;
}

.recruit-wrapper .polygon-10 {
  height: 16.55px !important;
  left: unset !important;
  margin-left: -0.42px !important;
  margin-right: -0.6px !important;
  position: relative !important;
  top: unset !important;
  width: 6.25px !important;
}

.recruit-wrapper .line-19 {
  align-self: stretch;
  flex: 1;
  flex-grow: 1;
  margin-left: -1px;
  margin-right: -1px;
  position: relative;
  width: 100%;
}

.recruit-wrapper .frame-382 {
  align-items: center;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  justify-content: space-between;
  margin-bottom: -19px;
  padding: 0px 10px;
  position: relative;
  width: 100%;
}

.recruit-wrapper .CONTACT-30 {
  align-items: center;
  align-self: stretch;
  background-color: var(--backwhite);
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 40px;
  justify-content: center;
  padding: 100px 20px;
  position: relative;
  width: 100%;
}

.recruit-wrapper .CONTACT-31 {
  height: 160px;
  position: relative;
  width: 280px;
}

.recruit-wrapper .frame-383 {
  align-items: center;
  background-color: var(--purple);
  border-radius: 6px;
  display: flex;
  flex-wrap: wrap;
  gap: 20px 74px;
  justify-content: center;
  max-width: 700px;
  padding: 50px 60px;
  position: relative;
  top: -38px;
  width: 280px;
}

.recruit-wrapper .group-67 {
  height: 60px;
  margin-left: -26px;
  margin-right: -30px;
  position: relative;
  width: 216px;
}

.recruit-wrapper .overlap-group-16 {
  height: 60px;
  position: relative;
  width: 212px;
}

.recruit-wrapper .text-wrapper-480 {
  color: var(--white);
  font-family: "Zen Kaku Gothic New", Helvetica;
  font-size: 24px;
  font-weight: 500;
  left: 0;
  letter-spacing: 2.4px;
  line-height: normal;
  position: absolute;
  text-align: center;
  top: 0;
  width: 212px;
}

.recruit-wrapper .text-wrapper-481 {
  color: var(--white);
  font-family: "Slackside One", Helvetica;
  font-size: 20px;
  font-weight: 400;
  left: 39px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  text-align: center;
  top: 31px;
  width: 134px;
}

.recruit-wrapper .group-68 {
  height: 55px;
  margin-left: -46px;
  margin-right: -48px;
  position: relative;
  width: 254px;
}

.recruit-wrapper .flexcontainer-50 {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  gap: 5px;
  height: 55px;
  position: relative;
  width: 252px;
}

.recruit-wrapper .text-i-51 {
  align-self: stretch;
  color: var(--white);
  font-family: "Noto Sans JP", Helvetica;
  font-size: 15px;
  font-weight: 400;
  letter-spacing: 0.75px;
  line-height: 25px;
  position: relative;
}

.recruit-wrapper .text-wrapper-482 {
  letter-spacing: 0.11px;
}

.recruit-wrapper .footer-17 {
  align-items: center;
  align-self: stretch;
  background-color: #454545;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 50px;
  padding: 50px 20px;
  position: relative;
  width: 100%;
}

.recruit-wrapper .frame-384 {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 50px;
  position: relative;
  width: 100%;
}

.recruit-wrapper .frame-385 {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 24px;
  position: relative;
  width: 100%;
}

.recruit-wrapper .logo-white-13 {
  height: 40px !important;
  left: unset !important;
  position: relative !important;
  top: unset !important;
  width: 121px !important;
}

.recruit-wrapper .flexcontainer-51 {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  gap: 3px;
}

.recruit-wrapper .text-i-52 {
  align-self: stretch;
  color: #ffffff;
  font-family: var(--smalltext-SP-font-family);
  font-size: var(--smalltext-SP-font-size);
  font-style: var(--smalltext-SP-font-style);
  font-weight: var(--smalltext-SP-font-weight);
  letter-spacing: var(--smalltext-SP-letter-spacing);
  line-height: var(--smalltext-SP-line-height);
  position: relative;
}

.recruit-wrapper .text-wrapper-483 {
  font-family: var(--smalltext-SP-font-family);
  font-size: var(--smalltext-SP-font-size);
  font-style: var(--smalltext-SP-font-style);
  font-weight: var(--smalltext-SP-font-weight);
  letter-spacing: var(--smalltext-SP-letter-spacing);
  line-height: var(--smalltext-SP-line-height);
}

.recruit-wrapper .frame-386 {
  align-items: flex-start;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 60px;
  margin-right: -14px;
  position: relative;
}

.recruit-wrapper .frame-387 {
  align-items: flex-start;
  display: inline-flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 30px;
  position: relative;
}

.recruit-wrapper .frame-388 {
  align-items: flex-start;
  display: inline-flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 4px;
  position: relative;
}

.recruit-wrapper .text-wrapper-484 {
  color: #ffffff;
  display: block;
  font-family: var(--text-bold-mini-font-family);
  font-size: var(--text-bold-mini-font-size);
  font-style: var(--text-bold-mini-font-style);
  font-weight: var(--text-bold-mini-font-weight);
  letter-spacing: var(--text-bold-mini-letter-spacing);
  line-height: var(--text-bold-mini-line-height);
  margin-top: -1px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.recruit-wrapper .text-wrapper-485 {
  color: #ffffff;
  font-family: var(--text-bold-mini-font-family);
  font-size: var(--text-bold-mini-font-size);
  font-style: var(--text-bold-mini-font-style);
  font-weight: var(--text-bold-mini-font-weight);
  letter-spacing: var(--text-bold-mini-letter-spacing);
  line-height: var(--text-bold-mini-line-height);
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.recruit-wrapper .text-wrapper-486 {
  color: #ffffff;
  font-family: var(--text-bold-mini-font-family);
  font-size: var(--text-bold-mini-font-size);
  font-style: var(--text-bold-mini-font-style);
  font-weight: var(--text-bold-mini-font-weight);
  letter-spacing: var(--text-bold-mini-letter-spacing);
  line-height: var(--text-bold-mini-line-height);
  margin-top: -1px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.recruit-wrapper .text-wrapper-487 {
  color: #ffffff;
  display: block;
  font-family: var(--text-bold-mini-font-family);
  font-size: var(--text-bold-mini-font-size);
  font-style: var(--text-bold-mini-font-style);
  font-weight: var(--text-bold-mini-font-weight);
  letter-spacing: var(--text-bold-mini-letter-spacing);
  line-height: var(--text-bold-mini-line-height);
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.recruit-wrapper .text-wrapper-488 {
  color: #ffffff;
  font-family: "Manrope", Helvetica;
  font-size: 13.1px;
  font-weight: 400;
  letter-spacing: 1.31px;
  line-height: normal;
  position: relative;
  width: fit-content;
}
