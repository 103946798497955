.line-2 {
  height: 1px;
  position: relative;
  width: 80px;
}

.line-2 .line-5 {
  height: 2px;
  left: 0;
  position: absolute;
  top: -2px;
  width: 80px;
}
