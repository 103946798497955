.information-security-screen {
  align-items: center;
  display: flex;
  flex-direction: column;
  position: relative;
}

.information-security-screen .header-48 {
  align-self: stretch !important;
  display: flex !important;
  flex: 0 0 auto !important;
  justify-content: space-between !important;
  width: 100% !important;
  z-index: 1 !important;
}

.information-security-screen .header-49 {
  height: 54px !important;
  position: relative !important;
  top: 16px !important;
  width: 145px !important;
}

.information-security-screen .blog-4 {
  align-items: flex-start;
  align-self: stretch;
  background-color: #ffffff;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  position: relative;
  width: 100%;
  z-index: 0;
}

.information-security-screen .midashi-15 {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  position: relative;
  width: 100%;
}

.information-security-screen .heading-66 {
  align-self: stretch !important;
  background-image: url(../../../static/img/midashi-1.png) !important;
  flex: 0 0 auto !important;
  width: 100% !important;
}

.information-security-screen .heading-67 {
  color: #ffffff !important;
  left: -164px !important;
}

.information-security-screen .heading-68 {
  color: #ffffff !important;
  left: -145px !important;
}

.information-security-screen .breadcrumbs-16 {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  gap: 30px;
  justify-content: center;
  padding: 40px 15px;
  position: relative;
  width: 100%;
}

.information-security-screen .frame-437 {
  align-items: center;
  display: flex;
  flex: 1;
  flex-grow: 1;
  gap: 30px;
  position: relative;
}

.information-security-screen .text-wrapper-558 {
  color: var(--gray);
  display: block;
  font-family: var(--tag-font-family);
  font-size: var(--tag-font-size);
  font-style: var(--tag-font-style);
  font-weight: var(--tag-font-weight);
  letter-spacing: var(--tag-letter-spacing);
  line-height: var(--tag-line-height);
  margin-top: -1px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.information-security-screen .component-10930-11 {
  height: 8.14px !important;
  left: unset !important;
  position: relative !important;
  top: unset !important;
  width: 4.57px !important;
}

.information-security-screen .text-wrapper-559 {
  color: var(--gray);
  flex: 1;
  font-family: var(--tag-font-family);
  font-size: var(--tag-font-size);
  font-style: var(--tag-font-style);
  font-weight: var(--tag-font-weight);
  letter-spacing: var(--tag-letter-spacing);
  line-height: var(--tag-line-height);
  margin-top: -1px;
  position: relative;
}

.information-security-screen .security-2 {
  align-items: center;
  align-self: stretch;
  background-color: var(--palepurple);
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 40px;
  padding: 80px 15px;
  position: relative;
  width: 100%;
}

.information-security-screen .frame-438 {
  align-items: flex-start;
  align-self: stretch;
  background-color: var(--back-white);
  border-radius: 14px;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 40px;
  padding: 50px 40px;
  position: relative;
  width: 100%;
}

.information-security-screen .frame-439 {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 24px;
  position: relative;
  width: 100%;
}

.information-security-screen .text-wrapper-560 {
  align-self: stretch;
  color: #454545;
  font-family: var(--text-font-family);
  font-size: var(--text-font-size);
  font-style: var(--text-font-style);
  font-weight: var(--text-font-weight);
  letter-spacing: var(--text-letter-spacing);
  line-height: var(--text-line-height);
  margin-top: -1px;
  position: relative;
}

.information-security-screen .frame-440 {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 5px;
  position: relative;
  width: 100%;
}

.information-security-screen .bulletpoint-3 {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  flex-wrap: wrap;
  gap: 5px 5px;
  position: relative;
  width: 100%;
}

.information-security-screen .group-79 {
  height: 28px;
  position: relative;
  width: 17px;
}

.information-security-screen .ellipse-7 {
  background-color: #6079c1;
  border-radius: 5px;
  height: 10px;
  left: 3px;
  position: relative;
  top: 9px;
  width: 10px;
}

.information-security-screen .text-wrapper-561 {
  color: #454545;
  flex: 1;
  font-family: var(--text-font-family);
  font-size: var(--text-font-size);
  font-style: var(--text-font-style);
  font-weight: var(--text-font-weight);
  letter-spacing: var(--text-letter-spacing);
  line-height: var(--text-line-height);
  margin-top: -1px;
  position: relative;
}

.information-security-screen .div-29 {
  align-self: stretch;
  color: #454545;
  font-family: var(--text-font-family);
  font-size: var(--text-font-size);
  font-style: var(--text-font-style);
  font-weight: var(--text-font-weight);
  letter-spacing: var(--text-letter-spacing);
  line-height: var(--text-line-height);
  position: relative;
}

.information-security-screen .frame-441 {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 30px;
  position: relative;
  width: 100%;
}

.information-security-screen .heading-69 {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 7px;
  position: relative;
  width: 100%;
}

.information-security-screen .text-wrapper-562 {
  align-self: stretch;
  color: #454545;
  font-family: "Noto Sans JP", Helvetica;
  font-size: 23px;
  font-weight: 700;
  letter-spacing: 2.3px;
  line-height: 43px;
  margin-top: -1px;
  position: relative;
}

.information-security-screen .line-pg {
  align-self: stretch;
  height: 2px;
  position: relative;
  width: 100%;
}

.information-security-screen .flexcontainer-63 {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.information-security-screen .text-wrapper-563 {
  font-family: var(--text-font-family);
  font-size: var(--text-font-size);
  font-style: var(--text-font-style);
  font-weight: var(--text-font-weight);
  letter-spacing: var(--text-letter-spacing);
  line-height: var(--text-line-height);
}

.information-security-screen .CONTACT-38 {
  align-items: center;
  align-self: stretch;
  background-color: var(--backwhite);
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 40px;
  padding: 100px 20px;
  position: relative;
  width: 100%;
}

.information-security-screen .CONTACT-39 {
  height: 160px;
  position: relative;
  width: 704px;
}

.information-security-screen .frame-442 {
  align-items: center;
  background-color: var(--purple);
  border-radius: 6px;
  display: flex;
  flex-wrap: wrap;
  gap: 20px 74px;
  justify-content: center;
  left: 2px;
  max-width: 700px;
  padding: 50px 60px;
  position: relative;
  width: 700px;
}

.information-security-screen .group-80 {
  height: 60px;
  position: relative;
  width: 216px;
}

.information-security-screen .overlap-group-20 {
  height: 60px;
  position: relative;
  width: 212px;
}

.information-security-screen .text-wrapper-564 {
  color: var(--white);
  font-family: "Zen Kaku Gothic New", Helvetica;
  font-size: 24px;
  font-weight: 500;
  left: 0;
  letter-spacing: 2.4px;
  line-height: normal;
  position: absolute;
  text-align: center;
  top: 0;
  width: 212px;
}

.information-security-screen .text-wrapper-565 {
  color: var(--white);
  font-family: "Slackside One", Helvetica;
  font-size: 20px;
  font-weight: 400;
  left: 39px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  text-align: center;
  top: 31px;
  width: 134px;
}

.information-security-screen .group-81 {
  height: 55px;
  position: relative;
  width: 254px;
}

.information-security-screen .flexcontainer-64 {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  gap: 5px;
  height: 55px;
  position: relative;
  width: 252px;
}

.information-security-screen .text-i-60 {
  align-self: stretch;
  color: var(--white);
  font-family: "Noto Sans JP", Helvetica;
  font-size: 15px;
  font-weight: 400;
  letter-spacing: 0.75px;
  line-height: 25px;
  position: relative;
}

.information-security-screen .text-wrapper-566 {
  letter-spacing: 0.11px;
}

.information-security-screen .footer-22 {
  align-items: center;
  align-self: stretch;
  background-color: #454545;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 50px;
  padding: 70px 20px 50px;
  position: relative;
  width: 100%;
}

.information-security-screen .frame-443 {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  flex-wrap: wrap;
  gap: 50px 150px;
  justify-content: space-between;
  position: relative;
  width: 100%;
}

.information-security-screen .frame-444 {
  align-items: flex-start;
  display: inline-flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 30px;
  position: relative;
}

.information-security-screen .logo-white-31 {
  height: 54.26px !important;
  left: unset !important;
  position: relative !important;
  top: unset !important;
  width: 163.65px !important;
}

.information-security-screen .text-i-61 {
  align-self: stretch;
  color: #ffffff;
  font-family: var(--text-font-family);
  font-size: var(--text-font-size);
  font-style: var(--text-font-style);
  font-weight: var(--text-font-weight);
  letter-spacing: var(--text-letter-spacing);
  line-height: var(--text-line-height);
  position: relative;
}

.information-security-screen .frame-445 {
  align-items: flex-start;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 60px;
  position: relative;
}

.information-security-screen .frame-446 {
  align-items: flex-start;
  display: inline-flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 6px;
  position: relative;
}

.information-security-screen .text-wrapper-567 {
  color: #ffffff;
  font-family: var(--text-bold-mini-font-family);
  font-size: var(--text-bold-mini-font-size);
  font-style: var(--text-bold-mini-font-style);
  font-weight: var(--text-bold-mini-font-weight);
  letter-spacing: var(--text-bold-mini-letter-spacing);
  line-height: var(--text-bold-mini-line-height);
  margin-top: -1px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.information-security-screen .text-wrapper-568 {
  color: #ffffff;
  font-family: var(--text-bold-mini-font-family);
  font-size: var(--text-bold-mini-font-size);
  font-style: var(--text-bold-mini-font-style);
  font-weight: var(--text-bold-mini-font-weight);
  letter-spacing: var(--text-bold-mini-letter-spacing);
  line-height: var(--text-bold-mini-line-height);
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.information-security-screen .text-wrapper-569 {
  color: #ffffff;
  display: block;
  font-family: var(--text-bold-mini-font-family);
  font-size: var(--text-bold-mini-font-size);
  font-style: var(--text-bold-mini-font-style);
  font-weight: var(--text-bold-mini-font-weight);
  letter-spacing: var(--text-bold-mini-letter-spacing);
  line-height: var(--text-bold-mini-line-height);
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.information-security-screen .text-wrapper-570 {
  color: #ffffff;
  font-family: "Manrope", Helvetica;
  font-size: 13.1px;
  font-weight: 400;
  letter-spacing: 1.31px;
  line-height: normal;
  position: relative;
  width: fit-content;
}
