.screen-screen {
  align-items: flex-start;
  background-color: #ffffff;
  display: flex;
  flex-direction: column;
  position: relative;
}

.screen-screen .headrer-3 {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  position: relative;
  width: 100%;
}

.screen-screen .logo-9 {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  justify-content: space-between;
  padding: 0px 0px 20px 30px;
  position: relative;
  width: 100%;
}

.screen-screen .frame-469 {
  height: 53px;
  position: relative;
  width: 164px;
}

.screen-screen .group-90 {
  background-image: url(../../../static/img/zsykr-0.png);
  background-size: 100% 100%;
  height: 38px;
  position: relative;
  top: 15px;
  width: 101px;
}

.screen-screen .component-9 {
  height: 52px !important;
  left: unset !important;
  position: relative !important;
  top: unset !important;
  width: 53px !important;
}

.screen-screen .midashi-18 {
  align-items: center;
  align-self: stretch;
  background-image: url(../../../static/img/midashi.png);
  background-position: 50% 50%;
  background-size: cover;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 10px;
  padding: 10px 0px;
  position: relative;
  width: 100%;
}

.screen-screen .component-10 {
  height: 89px;
  position: relative;
  width: 108px;
}

.screen-screen .text-wrapper-607 {
  color: #ffffff;
  font-family: "Zen Kaku Gothic New", Helvetica;
  font-size: 28px;
  font-weight: 500;
  left: -4px;
  letter-spacing: 1.4px;
  line-height: normal;
  position: absolute;
  text-align: center;
  top: 20px;
  white-space: nowrap;
}

.screen-screen .TEXTEXT-8 {
  color: #ffffff;
  font-family: "Slackside One", Helvetica;
  font-size: 18px;
  font-weight: 400;
  left: 4px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  text-align: center;
  top: 54px;
  white-space: nowrap;
}

.screen-screen .frame-470 {
  align-items: center;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  gap: 30px;
  padding: 20px 30px;
  position: relative;
  width: 100%;
}

.screen-screen .group-91 {
  height: 15px;
  position: relative;
  width: 150.57px;
}

.screen-screen .text-wrapper-608 {
  color: var(--gray);
  font-family: var(--tag-font-family);
  font-size: var(--tag-font-size);
  font-style: var(--tag-font-style);
  font-weight: var(--tag-font-weight);
  left: 0;
  letter-spacing: var(--tag-letter-spacing);
  line-height: var(--tag-line-height);
  position: absolute;
  top: 0;
  white-space: nowrap;
}

.screen-screen .component-10930-14 {
  left: 58px !important;
  top: 3px !important;
}

.screen-screen .text-wrapper-609 {
  color: var(--gray);
  font-family: var(--tag-font-family);
  font-size: var(--tag-font-size);
  font-style: var(--tag-font-style);
  font-weight: var(--tag-font-weight);
  left: 93px;
  letter-spacing: var(--tag-letter-spacing);
  line-height: var(--tag-line-height);
  position: absolute;
  top: 0;
  white-space: nowrap;
}

.screen-screen .view-wrapper {
  align-items: center;
  align-self: stretch;
  background-image: url(../../../static/img/image.png);
  background-position: 50% 50%;
  background-size: cover;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 40px;
  padding: 80px 20px;
  position: relative;
  width: 100%;
}

.screen-screen .frame-wrapper-2 {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  justify-content: center;
  position: relative;
  width: 100%;
}

.screen-screen .frame-471 {
  align-items: flex-start;
  background-color: #ffffff;
  display: flex;
  flex: 1;
  flex-direction: column;
  flex-grow: 1;
  gap: 30px;
  opacity: 0.9;
  padding: 0px 20px;
  position: relative;
}

.screen-screen .frame-472 {
  align-items: center;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 50px;
  padding: 80px 0px;
  position: relative;
  width: 100%;
}

.screen-screen .component-11 {
  height: 89px;
  position: relative;
  width: 240px;
}

.screen-screen .text-wrapper-610 {
  color: #272727;
  font-family: "Zen Kaku Gothic New", Helvetica;
  font-size: 24px;
  font-weight: 500;
  left: 54px;
  letter-spacing: 1.2px;
  line-height: normal;
  position: absolute;
  top: 14px;
  white-space: nowrap;
}

.screen-screen .TEXTEXT-9 {
  color: var(--purple);
  font-family: "Slackside One", Helvetica;
  font-size: 16px;
  font-weight: 400;
  left: 32px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  text-align: center;
  top: 58px;
  white-space: nowrap;
}

.screen-screen .flexcontainer-71 {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  gap: 2px;
}

.screen-screen .text-45 {
  align-self: stretch;
  color: var(--darkgray);
  font-family: "Noto Sans JP", Helvetica;
  font-size: 20px;
  font-weight: 500;
  letter-spacing: 1px;
  line-height: 37px;
  position: relative;
  text-align: center;
}

.screen-screen .text-wrapper-611 {
  letter-spacing: 0.2px;
}

.screen-screen .flexcontainer-72 {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.screen-screen .text-46 {
  align-self: stretch;
  color: var(--darkgray);
  font-family: "Noto Sans JP", Helvetica;
  font-size: 15px;
  font-weight: 400;
  letter-spacing: 0.75px;
  line-height: 25px;
  position: relative;
  text-align: center;
}

.screen-screen .text-wrapper-612 {
  letter-spacing: 0.11px;
}

.screen-screen .frame-473 {
  align-items: center;
  align-self: stretch;
  background-color: var(--palegray);
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 40px;
  overflow: hidden;
  padding: 80px 15px;
  position: relative;
  width: 100%;
}

.screen-screen .view-9 {
  height: 89px;
  margin-left: -17px;
  margin-right: -17px;
  position: relative;
  width: 324px;
}

.screen-screen .text-wrapper-613 {
  color: #272727;
  font-family: "Zen Kaku Gothic New", Helvetica;
  font-size: 24px;
  font-weight: 500;
  left: 112px;
  letter-spacing: 1.2px;
  line-height: normal;
  position: absolute;
  text-align: center;
  top: 24px;
  white-space: nowrap;
}

.screen-screen .text-wrapper-614 {
  color: var(--purple);
  font-family: "Slackside One", Helvetica;
  font-size: 16px;
  font-weight: 400;
  left: 73px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  text-align: center;
  top: 55px;
  white-space: nowrap;
}

.screen-screen .text-wrapper-615 {
  align-self: stretch;
  color: #454545;
  font-family: "Noto Sans JP", Helvetica;
  font-size: 25px;
  font-weight: 700;
  letter-spacing: 1.25px;
  line-height: normal;
  position: relative;
  text-align: center;
}

.screen-screen .text-47 {
  align-self: stretch;
  color: #454545;
  font-family: "Noto Sans JP", Helvetica;
  font-size: 15px;
  font-weight: 400;
  letter-spacing: 0.75px;
  line-height: 25px;
  position: relative;
  text-align: center;
}

.screen-screen .ill-4 {
  height: 178.04px;
  margin-left: -5px;
  margin-right: -5px;
  object-fit: cover;
  position: relative;
  width: 300px;
}

.screen-screen .div-32 {
  align-items: center;
  align-self: stretch;
  background-color: var(--backwhite);
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 40px;
  padding: 50px 15px;
  position: relative;
  width: 100%;
}

.screen-screen .component-12 {
  height: 103px;
  position: relative;
  width: 184px;
}

.screen-screen .text-wrapper-616 {
  color: #272727;
  font-family: "Zen Kaku Gothic New", Helvetica;
  font-size: 32px;
  font-weight: 500;
  left: 59px;
  letter-spacing: 1.6px;
  line-height: normal;
  position: absolute;
  text-align: center;
  top: 14px;
  white-space: nowrap;
}

.screen-screen .TEXTEXT-10 {
  color: #6079c1;
  font-family: "Slackside One", Helvetica;
  font-size: 23px;
  font-weight: 400;
  left: 54px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  text-align: center;
  top: 67px;
  white-space: nowrap;
}

.screen-screen .frame-474 {
  align-items: flex-start;
  display: flex;
  flex: 1;
  flex-direction: column;
  flex-grow: 1;
  gap: 40px;
  position: relative;
}

.screen-screen .frame-475 {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 15px;
  position: relative;
  width: 100%;
}

.screen-screen .text-wrapper-617 {
  align-self: stretch;
  color: var(--darkgray);
  font-family: "Noto Sans JP", Helvetica;
  font-size: 20px;
  font-weight: 700;
  letter-spacing: 1px;
  line-height: 37px;
  margin-top: -1px;
  position: relative;
}

.screen-screen .line-25 {
  width: 90px !important;
}

.screen-screen .text-wrapper-618 {
  align-self: stretch;
  color: var(--darkgray);
  font-family: "Noto Sans JP", Helvetica;
  font-size: 15px;
  font-weight: 400;
  letter-spacing: 0.75px;
  line-height: 25px;
  position: relative;
}

.screen-screen .frame-476 {
  align-items: center;
  align-self: stretch;
  background-color: var(--purple);
  border-radius: 6px;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 40px;
  padding: 30px 10px;
  position: relative;
  width: 100%;
}

.screen-screen .group-92 {
  height: 58px;
  position: relative;
  width: 134px;
}

.screen-screen .text-wrapper-619 {
  color: var(--white);
  font-family: "Zen Kaku Gothic New", Helvetica;
  font-size: 20px;
  font-weight: 500;
  left: 0;
  letter-spacing: 2px;
  line-height: normal;
  position: absolute;
  text-align: center;
  top: 0;
}

.screen-screen .text-wrapper-620 {
  color: var(--white);
  font-family: "Slackside One", Helvetica;
  font-size: 20px;
  font-weight: 400;
  left: 24px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  text-align: center;
  top: 29px;
}

.screen-screen .text-48 {
  align-self: stretch;
  color: var(--white);
  font-family: "Noto Sans JP", Helvetica;
  font-size: 15px;
  font-weight: 400;
  letter-spacing: 0.75px;
  line-height: 25px;
  position: relative;
  text-align: center;
}

.screen-screen .footer-25 {
  align-items: center;
  align-self: stretch;
  background-color: #454545;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 50px;
  padding: 50px 15px 40px;
  position: relative;
  width: 100%;
}

.screen-screen .frame-477 {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 60px;
  position: relative;
  width: 100%;
}

.screen-screen .frame-478 {
  align-items: flex-start;
  display: inline-flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 30px;
  position: relative;
}

.screen-screen .logo-white-34 {
  height: 54.26px !important;
  left: unset !important;
  position: relative !important;
  top: unset !important;
  width: 163.65px !important;
}

.screen-screen .text-i-65 {
  align-self: stretch;
  color: #ffffff;
  font-family: var(--text-mini-font-family);
  font-size: var(--text-mini-font-size);
  font-style: var(--text-mini-font-style);
  font-weight: var(--text-mini-font-weight);
  letter-spacing: var(--text-mini-letter-spacing);
  line-height: var(--text-mini-line-height);
  position: relative;
}

.screen-screen .text-wrapper-621 {
  font-family: var(--text-mini-font-family);
  font-size: var(--text-mini-font-size);
  font-style: var(--text-mini-font-style);
  font-weight: var(--text-mini-font-weight);
  letter-spacing: var(--text-mini-letter-spacing);
  line-height: var(--text-mini-line-height);
}

.screen-screen .frame-479 {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  justify-content: space-between;
  position: relative;
  width: 100%;
}

.screen-screen .text-wrapper-622 {
  color: #ffffff;
  font-family: var(--text-bold-mini-font-family);
  font-size: var(--text-bold-mini-font-size);
  font-style: var(--text-bold-mini-font-style);
  font-weight: var(--text-bold-mini-font-weight);
  letter-spacing: var(--text-bold-mini-letter-spacing);
  line-height: var(--text-bold-mini-line-height);
  margin-top: -1px;
  position: relative;
  width: fit-content;
}

.screen-screen .text-wrapper-623 {
  color: #ffffff;
  font-family: var(--text-bold-mini-font-family);
  font-size: var(--text-bold-mini-font-size);
  font-style: var(--text-bold-mini-font-style);
  font-weight: var(--text-bold-mini-font-weight);
  letter-spacing: var(--text-bold-mini-letter-spacing);
  line-height: var(--text-bold-mini-line-height);
  position: relative;
  width: fit-content;
}

.screen-screen .text-wrapper-624 {
  color: #ffffff;
  font-family: var(--text-bold-mini-font-family);
  font-size: var(--text-bold-mini-font-size);
  font-style: var(--text-bold-mini-font-style);
  font-weight: var(--text-bold-mini-font-weight);
  letter-spacing: var(--text-bold-mini-letter-spacing);
  line-height: var(--text-bold-mini-line-height);
  margin-top: -1px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.screen-screen .text-wrapper-625 {
  color: #ffffff;
  font-family: var(--text-bold-mini-font-family);
  font-size: var(--text-bold-mini-font-size);
  font-style: var(--text-bold-mini-font-style);
  font-weight: var(--text-bold-mini-font-weight);
  letter-spacing: var(--text-bold-mini-letter-spacing);
  line-height: var(--text-bold-mini-line-height);
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.screen-screen .group-93 {
  height: 18px;
  position: relative;
  width: 154px;
}

.screen-screen .text-wrapper-626 {
  color: #ffffff;
  font-family: "Manrope", Helvetica;
  font-size: 13.1px;
  font-weight: 400;
  left: 0;
  letter-spacing: 1.31px;
  line-height: normal;
  position: absolute;
  top: 0;
}
