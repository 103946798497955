.contact-screen {
  align-items: center;
  background-color: var(--backwhite);
  display: flex;
  flex-direction: column;
  position: relative;
}

.contact-screen .header-8 {
  align-self: stretch !important;
  flex: 0 0 auto !important;
  height: unset !important;
  width: 100% !important;
  z-index: 1 !important;
}

.contact-screen .header-9 {
  margin-bottom: unset !important;
}

.contact-screen .menu-24 {
  height: 35px !important;
  position: relative !important;
  width: 36px !important;
}

.contact-screen .contacts-2 {
  align-items: flex-start;
  align-self: stretch;
  background-color: #ffffff;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  position: relative;
  width: 100%;
  z-index: 0;
}

.contact-screen .midashi-2 {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  position: relative;
  width: 100%;
}

.contact-screen .heading-20 {
  align-self: stretch !important;
  display: flex !important;
  flex: 0 0 auto !important;
  padding: 5px 0px !important;
  width: 100% !important;
}

.contact-screen .heading-21 {
  color: #ffffff !important;
  left: -10px !important;
}

.contact-screen .heading-22 {
  color: #ffffff !important;
  left: 30px !important;
}

.contact-screen .breadcrumbs-4 {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  gap: 30px;
  justify-content: center;
  padding: 10px 15px 20px;
  position: relative;
  width: 100%;
}

.contact-screen .frame-157 {
  align-items: center;
  display: flex;
  flex: 1;
  flex-grow: 1;
  gap: 17px;
  padding: 0px 10px;
  position: relative;
}

.contact-screen .text-wrapper-150 {
  color: var(--gray);
  display: block;
  font-family: var(--smalltext-SP-font-family);
  font-size: var(--smalltext-SP-font-size);
  font-style: var(--smalltext-SP-font-style);
  font-weight: var(--smalltext-SP-font-weight);
  letter-spacing: var(--smalltext-SP-letter-spacing);
  line-height: var(--smalltext-SP-line-height);
  margin-top: -1px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.contact-screen .instance-22 {
  height: 8.14px !important;
  position: relative !important;
  width: 4.57px !important;
}

.contact-screen .text-wrapper-151 {
  color: var(--gray);
  font-family: var(--smalltext-SP-font-family);
  font-size: var(--smalltext-SP-font-size);
  font-style: var(--smalltext-SP-font-style);
  font-weight: var(--smalltext-SP-font-weight);
  letter-spacing: var(--smalltext-SP-letter-spacing);
  line-height: var(--smalltext-SP-line-height);
  margin-top: -1px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.contact-screen .contactform-2 {
  align-items: center;
  align-self: stretch;
  background-color: var(--palepurple);
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 40px;
  padding: 40px 15px;
  position: relative;
  width: 100%;
}

.contact-screen .flexcontainer-16 {
  align-items: flex-start;
  display: inline-flex;
  flex-direction: column;
  gap: 8px;
}

.contact-screen .text-7 {
  align-self: stretch;
  color: #454545;
  font-family: var(--tag-font-family);
  font-size: var(--tag-font-size);
  font-style: var(--tag-font-style);
  font-weight: var(--tag-font-weight);
  letter-spacing: var(--tag-letter-spacing);
  line-height: var(--tag-line-height);
  position: relative;
  text-align: center;
}

.contact-screen .text-wrapper-152 {
  font-family: var(--tag-font-family);
  font-size: var(--tag-font-size);
  font-style: var(--tag-font-style);
  font-weight: var(--tag-font-weight);
  letter-spacing: var(--tag-letter-spacing);
  line-height: var(--tag-line-height);
}

.contact-screen .frame-158 {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  gap: 16px;
  justify-content: center;
  position: relative;
  width: 100%;
}

.contact-screen .flexcontainer-17 {
  align-items: flex-start;
  display: inline-flex;
  flex-direction: column;
  gap: 2px;
}

.contact-screen .text-8 {
  align-self: stretch;
  color: #6079c1;
  font-family: var(--textbold-SP-font-family);
  font-size: var(--textbold-SP-font-size);
  font-style: var(--textbold-SP-font-style);
  font-weight: var(--textbold-SP-font-weight);
  letter-spacing: var(--textbold-SP-letter-spacing);
  line-height: var(--textbold-SP-line-height);
  position: relative;
  text-align: right;
}

.contact-screen .text-wrapper-153 {
  font-family: var(--textbold-SP-font-family);
  font-size: var(--textbold-SP-font-size);
  font-style: var(--textbold-SP-font-style);
  font-weight: var(--textbold-SP-font-weight);
  letter-spacing: var(--textbold-SP-letter-spacing);
  line-height: var(--textbold-SP-line-height);
}

.contact-screen .button-11 {
  margin-bottom: -11.5px !important;
  margin-top: -11.5px !important;
}

.contact-screen .button-12 {
  font-family: var(--textbold-SP-font-family) !important;
  font-size: var(--textbold-SP-font-size) !important;
  font-style: var(--textbold-SP-font-style) !important;
  font-weight: var(--textbold-SP-font-weight) !important;
  letter-spacing: var(--textbold-SP-letter-spacing) !important;
  line-height: var(--textbold-SP-line-height) !important;
  white-space: nowrap !important;
}

.contact-screen .frame-159 {
  align-items: flex-start;
  align-self: stretch;
  background-color: var(--back-white);
  border-radius: 14px;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 30px;
  padding: 20px 20px 40px;
  position: relative;
  width: 100%;
}

.contact-screen .frame-160 {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  flex-wrap: wrap;
  gap: 7px 30px;
  position: relative;
  width: 100%;
}

.contact-screen .checklist-2 {
  flex: 0 0 auto !important;
  gap: 8px !important;
}

.contact-screen .checklist-3 {
  font-family: var(--text-bold-mini-font-family) !important;
  font-size: var(--text-bold-mini-font-size) !important;
  font-style: var(--text-bold-mini-font-style) !important;
  font-weight: var(--text-bold-mini-font-weight) !important;
  letter-spacing: var(--text-bold-mini-letter-spacing) !important;
  line-height: var(--text-bold-mini-line-height) !important;
}

.contact-screen .checklist-4 {
  flex: 0 0 auto !important;
}

.contact-screen .input-field-3 {
  align-self: stretch !important;
  display: flex !important;
  flex: 0 0 auto !important;
  width: 100% !important;
}

.contact-screen .input-field-4 {
  border-radius: 4px !important;
  position: relative !important;
  width: 36px !important;
}

.contact-screen .input-field-5 {
  flex: 0 0 auto !important;
  height: unset !important;
}

.contact-screen .input-field-6 {
  margin-bottom: unset !important;
  margin-top: -1px !important;
}

.contact-screen .input-field-7 {
  align-self: stretch !important;
  display: flex !important;
  height: 82px !important;
  width: 100% !important;
}

.contact-screen .input-field-8 {
  align-items: flex-start !important;
  flex: 1 !important;
  flex-grow: 1 !important;
  height: unset !important;
}

.contact-screen .input-field-9 {
  flex: 1 !important;
  margin-bottom: unset !important;
  margin-top: -1px !important;
  white-space: unset !important;
  width: unset !important;
}

.contact-screen .button-13 {
  align-self: stretch !important;
  width: 100% !important;
}

.contact-screen .button-14 {
  font-family: var(--button-SP-font-family) !important;
  font-size: var(--button-SP-font-size) !important;
  font-style: var(--button-SP-font-style) !important;
  font-weight: var(--button-SP-font-weight) !important;
  letter-spacing: var(--button-SP-letter-spacing) !important;
  line-height: var(--button-SP-line-height) !important;
}

.contact-screen .q-a-4 {
  align-items: center;
  align-self: stretch;
  background-color: var(--palepurple);
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 20px;
  padding: 20px 15px 40px;
  position: relative;
  width: 100%;
}

.contact-screen .heading-23 {
  position: relative !important;
}

.contact-screen .heading-24 {
  left: 45px !important;
}

.contact-screen .heading-25 {
  left: -10px !important;
}

.contact-screen .frame-161 {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 12px;
  position: relative;
  width: 100%;
}

.contact-screen .frame-162 {
  align-items: center;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 15px;
  position: relative;
  width: 100%;
}

.contact-screen .q-a-5 {
  align-self: stretch !important;
  flex: 0 0 auto !important;
  padding: 20px 10px !important;
  width: 100% !important;
}

.contact-screen .q-a-6 {
  gap: 12px !important;
}

.contact-screen .icon-instance-node {
  height: 24px !important;
  position: relative !important;
  width: 24px !important;
}

.contact-screen .q-a-7 {
  align-items: flex-start !important;
  display: flex !important;
  flex: 1 !important;
  flex-grow: 1 !important;
  justify-content: unset !important;
  margin-right: unset !important;
}

.contact-screen .q-a-8 {
  height: 12px !important;
  position: relative !important;
  width: 12px !important;
}

.contact-screen .q-a-9 {
  display: flex !important;
  flex: 1 !important;
  flex-grow: 1 !important;
}

.contact-screen .CONTACT-10 {
  align-items: center;
  background-color: var(--backwhite);
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 40px;
  justify-content: center;
  padding: 40px 50px;
  position: relative;
  width: 600px;
}

.contact-screen .CONTACT-11 {
  align-self: stretch;
  height: 160px;
  position: relative;
  width: 100%;
}

.contact-screen .frame-163 {
  align-items: center;
  background-color: var(--purple);
  border-radius: 6px;
  display: flex;
  flex-wrap: wrap;
  gap: 20px 20px;
  justify-content: center;
  max-width: 700px;
  padding: 40px 20px;
  position: relative;
  top: 14px;
  width: 500px;
}

.contact-screen .group-21 {
  height: 53px;
  position: relative;
  width: 216px;
}

.contact-screen .text-wrapper-154 {
  color: var(--white);
  font-family: "Zen Kaku Gothic New", Helvetica;
  font-size: 20px;
  font-weight: 500;
  left: 0;
  letter-spacing: 2px;
  line-height: normal;
  position: absolute;
  text-align: center;
  top: 0;
  width: 212px;
}

.contact-screen .text-wrapper-155 {
  color: var(--white);
  font-family: "Slackside One", Helvetica;
  font-size: 15px;
  font-weight: 400;
  left: 39px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  text-align: center;
  top: 31px;
  width: 134px;
}

.contact-screen .group-22 {
  height: 48px;
  position: relative;
  width: 212px;
}

.contact-screen .flexcontainer-18 {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  gap: 2px;
  height: 48px;
  position: relative;
  width: 210px;
}

.contact-screen .text-i-17 {
  align-self: stretch;
  color: var(--white);
  font-family: var(--text-SP-font-family);
  font-size: var(--text-SP-font-size);
  font-style: var(--text-SP-font-style);
  font-weight: var(--text-SP-font-weight);
  letter-spacing: var(--text-SP-letter-spacing);
  line-height: var(--text-SP-line-height);
  position: relative;
}

.contact-screen .text-wrapper-156 {
  font-family: var(--text-SP-font-family);
  font-size: var(--text-SP-font-size);
  font-style: var(--text-SP-font-style);
  font-weight: var(--text-SP-font-weight);
  letter-spacing: var(--text-SP-letter-spacing);
  line-height: var(--text-SP-line-height);
}

.contact-screen .footer-6 {
  align-items: center;
  align-self: stretch;
  background-color: #454545;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 50px;
  padding: 50px 20px;
  position: relative;
  width: 100%;
}

.contact-screen .frame-164 {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 54px;
  position: relative;
  width: 100%;
}

.contact-screen .frame-165 {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 24px;
  position: relative;
  width: 100%;
}

.contact-screen .logo-white-18 {
  height: 40px !important;
  position: relative !important;
  width: 121px !important;
}

.contact-screen .flexcontainer-19 {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  gap: 3px;
}

.contact-screen .text-i-18 {
  align-self: stretch;
  color: #ffffff;
  font-family: "Noto Sans JP", Helvetica;
  font-size: 13px;
  font-weight: 400;
  letter-spacing: 0.26px;
  line-height: 18px;
  position: relative;
}

.contact-screen .text-wrapper-157 {
  letter-spacing: 0.03px;
}

.contact-screen .frame-166 {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  gap: 40px;
  position: relative;
  width: 100%;
}

.contact-screen .frame-167 {
  align-items: flex-start;
  display: inline-flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 30px;
  position: relative;
}

.contact-screen .frame-168 {
  align-items: flex-start;
  display: inline-flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 4px;
  position: relative;
}

.contact-screen .text-wrapper-158 {
  color: #ffffff;
  display: block;
  font-family: var(--text-bold-mini-font-family);
  font-size: var(--text-bold-mini-font-size);
  font-style: var(--text-bold-mini-font-style);
  font-weight: var(--text-bold-mini-font-weight);
  letter-spacing: var(--text-bold-mini-letter-spacing);
  line-height: var(--text-bold-mini-line-height);
  margin-top: -1px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.contact-screen .text-wrapper-159 {
  color: #ffffff;
  font-family: var(--text-bold-mini-font-family);
  font-size: var(--text-bold-mini-font-size);
  font-style: var(--text-bold-mini-font-style);
  font-weight: var(--text-bold-mini-font-weight);
  letter-spacing: var(--text-bold-mini-letter-spacing);
  line-height: var(--text-bold-mini-line-height);
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.contact-screen .text-wrapper-160 {
  color: #ffffff;
  font-family: var(--text-bold-mini-font-family);
  font-size: var(--text-bold-mini-font-size);
  font-style: var(--text-bold-mini-font-style);
  font-weight: var(--text-bold-mini-font-weight);
  letter-spacing: var(--text-bold-mini-letter-spacing);
  line-height: var(--text-bold-mini-line-height);
  margin-top: -1px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.contact-screen .frame-169 {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex: 1;
  flex-direction: column;
  flex-grow: 1;
  gap: 30px;
  position: relative;
}

.contact-screen .text-wrapper-161 {
  color: #ffffff;
  display: block;
  font-family: var(--text-bold-mini-font-family);
  font-size: var(--text-bold-mini-font-size);
  font-style: var(--text-bold-mini-font-style);
  font-weight: var(--text-bold-mini-font-weight);
  letter-spacing: var(--text-bold-mini-letter-spacing);
  line-height: var(--text-bold-mini-line-height);
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.contact-screen .text-wrapper-162 {
  color: #ffffff;
  font-family: "Manrope", Helvetica;
  font-size: 13.1px;
  font-weight: 400;
  letter-spacing: 1.31px;
  line-height: normal;
  position: relative;
  width: fit-content;
}
