.screen {
  align-items: flex-start;
  background-color: #ffffff;
  display: flex;
  flex-direction: column;
  position: relative;
}

.screen .headrer-2 {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  position: relative;
  width: 100%;
}

.screen .logo-7 {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  justify-content: space-between;
  padding: 0px 0px 20px 50px;
  position: relative;
  width: 100%;
}

.screen .group-85 {
  height: 70px;
  position: relative;
  width: 152.61px;
}

.screen .logo-8 {
  height: 57px !important;
  position: relative !important;
  top: 13px !important;
  width: 153px !important;
}

.screen .component-4 {
  height: 70px !important;
  left: unset !important;
  position: relative !important;
  top: unset !important;
  width: 71px !important;
}

.screen .midashi-17 {
  align-items: flex-start;
  align-self: stretch;
  background-image: url(../../../static/img/midashi-1.png);
  background-position: 50% 50%;
  background-size: cover;
  display: flex;
  flex: 0 0 auto;
  gap: 10px;
  justify-content: center;
  padding: 20px 0px 30px;
  position: relative;
  width: 100%;
}

.screen .component-5 {
  height: 89px;
  position: relative;
  width: 108px;
}

.screen .text-wrapper-589 {
  color: #ffffff;
  font-family: "Zen Kaku Gothic New", Helvetica;
  font-size: 32px;
  font-weight: 500;
  left: -12px;
  letter-spacing: 1.6px;
  line-height: normal;
  position: absolute;
  text-align: center;
  top: 14px;
  white-space: nowrap;
}

.screen .TEXTEXT-4 {
  color: #ffffff;
  font-family: "Slackside One", Helvetica;
  font-size: 23px;
  font-weight: 400;
  left: -11px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  text-align: center;
  top: 52px;
  white-space: nowrap;
}

.screen .frame-457 {
  align-items: center;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  gap: 30px;
  padding: 50px;
  position: relative;
  width: 100%;
}

.screen .text-wrapper-590 {
  color: var(--gray);
  font-family: var(--tag-font-family);
  font-size: var(--tag-font-size);
  font-style: var(--tag-font-style);
  font-weight: var(--tag-font-weight);
  letter-spacing: var(--tag-letter-spacing);
  line-height: var(--tag-line-height);
  margin-top: -1px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.screen .component-10930-13 {
  height: 8.14px !important;
  left: unset !important;
  position: relative !important;
  top: unset !important;
  width: 4.57px !important;
}

.screen .member-wrapper {
  align-items: center;
  align-self: stretch;
  background-image: url(../../../static/img/image-2.png);
  background-position: 50% 50%;
  background-size: cover;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 40px;
  padding: 80px 15px;
  position: relative;
  width: 100%;
}

.screen .member-5 {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  justify-content: center;
  position: relative;
  width: 100%;
}

.screen .div-31 {
  align-self: stretch;
  position: relative;
  width: 100px;
}

.screen .frame-458 {
  align-items: flex-start;
  background-color: #ffffff;
  display: flex;
  flex-direction: column;
  gap: 30px;
  opacity: 0.9;
  padding: 0px 20px;
  position: relative;
  width: 900px;
}

.screen .frame-459 {
  align-items: center;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 50px;
  padding: 80px 0px;
  position: relative;
  width: 100%;
}

.screen .component-6 {
  height: 89px;
  position: relative;
  width: 302px;
}

.screen .text-wrapper-591 {
  color: #272727;
  font-family: "Zen Kaku Gothic New", Helvetica;
  font-size: 32px;
  font-weight: 500;
  left: 85px;
  letter-spacing: 1.6px;
  line-height: normal;
  position: absolute;
  text-align: center;
  top: 14px;
  white-space: nowrap;
}

.screen .TEXTEXT-5 {
  color: var(--purple);
  font-family: "Slackside One", Helvetica;
  font-size: 23px;
  font-weight: 400;
  left: 24px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  text-align: center;
  top: 58px;
  white-space: nowrap;
}

.screen .flexcontainer-68 {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  gap: 2px;
}

.screen .text-41 {
  align-self: stretch;
  color: var(--darkgray);
  font-family: "Noto Sans JP", Helvetica;
  font-size: 20px;
  font-weight: 500;
  letter-spacing: 1px;
  line-height: 37px;
  position: relative;
  text-align: center;
}

.screen .text-wrapper-592 {
  letter-spacing: 0.2px;
}

.screen .flexcontainer-69 {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.screen .text-42 {
  align-self: stretch;
  color: var(--darkgray);
  font-family: "Noto Sans JP", Helvetica;
  font-size: 15px;
  font-weight: 400;
  letter-spacing: 0.75px;
  line-height: 25px;
  position: relative;
  text-align: center;
}

.screen .text-wrapper-593 {
  letter-spacing: 0.11px;
}

.screen .frame-460 {
  align-items: center;
  align-self: stretch;
  background-color: var(--palegray);
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 40px;
  padding: 80px 15px;
  position: relative;
  width: 100%;
}

.screen .component-7 {
  height: 89px;
  position: relative;
  width: 324px;
}

.screen .text-wrapper-594 {
  color: #272727;
  font-family: "Zen Kaku Gothic New", Helvetica;
  font-size: 32px;
  font-weight: 500;
  left: 96px;
  letter-spacing: 1.6px;
  line-height: normal;
  position: absolute;
  text-align: center;
  top: 14px;
  white-space: nowrap;
}

.screen .TEXTEXT-6 {
  color: #6079c1;
  font-family: "Slackside One", Helvetica;
  font-size: 23px;
  font-weight: 400;
  left: 19px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  text-align: center;
  top: 58px;
  white-space: nowrap;
}

.screen .text-wrapper-595 {
  align-self: stretch;
  color: #454545;
  font-family: "Noto Sans JP", Helvetica;
  font-size: 25px;
  font-weight: 700;
  letter-spacing: 1.25px;
  line-height: normal;
  position: relative;
  text-align: center;
}

.screen .text-43 {
  align-self: stretch;
  color: #454545;
  font-family: "Noto Sans JP", Helvetica;
  font-size: 15px;
  font-weight: 400;
  letter-spacing: 0.75px;
  line-height: 25px;
  position: relative;
  text-align: center;
}

.screen .ill-3 {
  height: 254px;
  object-fit: cover;
  position: relative;
  width: 428px;
}

.screen .view-8 {
  align-items: center;
  align-self: stretch;
  background-color: var(--backwhite);
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 40px;
  padding: 50px 15px;
  position: relative;
  width: 100%;
}

.screen .component-8 {
  height: 103px;
  position: relative;
  width: 184px;
}

.screen .text-wrapper-596 {
  color: #272727;
  font-family: "Zen Kaku Gothic New", Helvetica;
  font-size: 32px;
  font-weight: 500;
  left: 59px;
  letter-spacing: 1.6px;
  line-height: normal;
  position: absolute;
  text-align: center;
  top: 14px;
  white-space: nowrap;
}

.screen .TEXTEXT-7 {
  color: #6079c1;
  font-family: "Slackside One", Helvetica;
  font-size: 23px;
  font-weight: 400;
  left: 54px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  text-align: center;
  top: 67px;
  white-space: nowrap;
}

.screen .frame-461 {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  gap: 30px;
  position: relative;
  width: 800px;
}

.screen .frame-462 {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 15px;
  position: relative;
  width: 100%;
}

.screen .text-wrapper-597 {
  align-self: stretch;
  color: var(--darkgray);
  font-family: "Noto Sans JP", Helvetica;
  font-size: 20px;
  font-weight: 700;
  letter-spacing: 1px;
  line-height: 37px;
  margin-top: -1px;
  position: relative;
}

.screen .line-24 {
  width: 90px !important;
}

.screen .text-wrapper-598 {
  align-self: stretch;
  color: var(--darkgray);
  font-family: "Noto Sans JP", Helvetica;
  font-size: 15px;
  font-weight: 400;
  letter-spacing: 0.75px;
  line-height: 25px;
  position: relative;
}

.screen .frame-463 {
  align-items: center;
  align-self: stretch;
  background-color: var(--backwhite);
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 40px;
  padding: 100px 15px;
  position: relative;
  width: 100%;
}

.screen .group-86 {
  height: 138px;
  position: relative;
  width: 562px;
}

.screen .frame-464 {
  align-items: center;
  background-color: var(--purple);
  border-radius: 6px;
  display: inline-flex;
  flex-wrap: wrap;
  gap: 40px 20px;
  justify-content: center;
  max-width: 700px;
  min-width: 280px;
  padding: 40px 70px;
  position: relative;
}

.screen .group-87 {
  height: 58px;
  position: relative;
  width: 134px;
}

.screen .text-wrapper-599 {
  color: var(--white);
  font-family: "Zen Kaku Gothic New", Helvetica;
  font-size: 20px;
  font-weight: 500;
  left: 0;
  letter-spacing: 2px;
  line-height: normal;
  position: absolute;
  text-align: center;
  top: 0;
}

.screen .text-wrapper-600 {
  color: var(--white);
  font-family: "Slackside One", Helvetica;
  font-size: 20px;
  font-weight: 400;
  left: 24px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  text-align: center;
  top: 29px;
}

.screen .group-88 {
  height: 55px;
  margin-right: -2px;
  position: relative;
  width: 254px;
}

.screen .flexcontainer-70 {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  gap: 5px;
  height: 55px;
  position: relative;
  width: 252px;
}

.screen .text-44 {
  align-self: stretch;
  color: var(--white);
  font-family: "Noto Sans JP", Helvetica;
  font-size: 15px;
  font-weight: 400;
  letter-spacing: 0.75px;
  line-height: 25px;
  position: relative;
}

.screen .footer-24 {
  align-items: center;
  align-self: stretch;
  background-color: #454545;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 50px;
  padding: 100px 15px 40px;
  position: relative;
  width: 100%;
}

.screen .frame-465 {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  flex-wrap: wrap;
  gap: 150px 50px;
  justify-content: space-between;
  padding: 0px 100px;
  position: relative;
  width: 100%;
}

.screen .frame-466 {
  align-items: flex-start;
  display: flex;
  flex: 1;
  flex-direction: column;
  flex-grow: 1;
  gap: 44px;
  position: relative;
}

.screen .logo-white-33 {
  height: 54.26px !important;
  left: unset !important;
  position: relative !important;
  top: unset !important;
  width: 163.65px !important;
}

.screen .text-i-64 {
  align-self: stretch;
  color: #ffffff;
  font-family: var(--text-font-family);
  font-size: var(--text-font-size);
  font-style: var(--text-font-style);
  font-weight: var(--text-font-weight);
  letter-spacing: var(--text-letter-spacing);
  line-height: var(--text-line-height);
  position: relative;
}

.screen .text-wrapper-601 {
  font-family: var(--text-font-family);
  font-size: var(--text-font-size);
  font-style: var(--text-font-style);
  font-weight: var(--text-font-weight);
  letter-spacing: var(--text-letter-spacing);
  line-height: var(--text-line-height);
}

.screen .frame-467 {
  align-items: flex-start;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 100px;
  position: relative;
}

.screen .frame-468 {
  align-items: flex-start;
  display: inline-flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 30px;
  position: relative;
}

.screen .text-wrapper-602 {
  color: #ffffff;
  font-family: var(--text-bold-font-family);
  font-size: var(--text-bold-font-size);
  font-style: var(--text-bold-font-style);
  font-weight: var(--text-bold-font-weight);
  letter-spacing: var(--text-bold-letter-spacing);
  line-height: var(--text-bold-line-height);
  margin-top: -1px;
  position: relative;
  width: fit-content;
}

.screen .text-wrapper-603 {
  color: #ffffff;
  font-family: var(--text-bold-font-family);
  font-size: var(--text-bold-font-size);
  font-style: var(--text-bold-font-style);
  font-weight: var(--text-bold-font-weight);
  letter-spacing: var(--text-bold-letter-spacing);
  line-height: var(--text-bold-line-height);
  position: relative;
  width: fit-content;
}

.screen .text-wrapper-604 {
  color: #ffffff;
  font-family: var(--text-bold-font-family);
  font-size: var(--text-bold-font-size);
  font-style: var(--text-bold-font-style);
  font-weight: var(--text-bold-font-weight);
  letter-spacing: var(--text-bold-letter-spacing);
  line-height: var(--text-bold-line-height);
  margin-top: -1px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.screen .text-wrapper-605 {
  color: #ffffff;
  font-family: var(--text-bold-font-family);
  font-size: var(--text-bold-font-size);
  font-style: var(--text-bold-font-style);
  font-weight: var(--text-bold-font-weight);
  letter-spacing: var(--text-bold-letter-spacing);
  line-height: var(--text-bold-line-height);
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.screen .group-89 {
  height: 18px;
  position: relative;
  width: 154px;
}

.screen .text-wrapper-606 {
  color: #ffffff;
  font-family: "Manrope", Helvetica;
  font-size: 13.1px;
  font-weight: 400;
  left: 0;
  letter-spacing: 1.31px;
  line-height: normal;
  position: absolute;
  top: 0;
}
