.button {
  align-items: center;
  border-radius: 100px;
  display: flex;
  justify-content: space-between;
  padding: 25px 20px;
  position: relative;
}

.button .frame-2 {
  align-items: center;
  display: flex;
  flex: 1;
  flex-direction: column;
  flex-grow: 1;
  gap: 10px;
  position: relative;
}

.button .text-wrapper {
  font-family: "Noto Sans JP", Helvetica;
  font-size: 16px;
  font-weight: 700;
  letter-spacing: 0.8px;
  line-height: normal;
  margin-top: -1px;
  position: relative;
  text-align: center;
  width: fit-content;
}

.button .img {
  height: 17.13px;
  margin-left: -0.49px;
  margin-right: -1.15px;
  position: relative;
  width: 10.87px;
}

.button .frame-3 {
  align-items: center;
  display: flex;
  flex: 1;
  flex-direction: column;
  flex-grow: 1;
  gap: 10px;
  margin-bottom: -8.5px;
  margin-top: -8.5px;
  position: relative;
}

.button .text-wrapper-2 {
  font-family: "Noto Sans JP", Helvetica;
  font-size: 12px;
  font-weight: 700;
  letter-spacing: 0.6px;
  line-height: normal;
  margin-top: -1px;
  position: relative;
  text-align: center;
  width: fit-content;
}

.button .arrow {
  height: 7.38px !important;
  margin-bottom: -4.32px !important;
  margin-left: -1.21px !important;
  margin-right: -1.15px !important;
  margin-top: -3.07px !important;
  position: relative !important;
  width: 5.25px !important;
}

.button.color-0-g-hover {
  background-color: #ffffff;
  border: 2px solid;
  border-color: #454545;
}

.button.color-0-white-textgray {
  background-color: #ffffff;
}

.button.color-0-gray {
  background-color: #454545;
  transition: all 0.3s ease;
}

.button.color-0-white {
  background-color: #ffffff;
}

.button.color-0-one {
  background-color: #6079c1;
  transition: all 0.3s ease;
}

.button.color-0-purple {
  background-color: #6079c1;
  transition: all 0.3s ease;
}

.button.device-PC {
  width: 308px;
}

.button.color-0-seven {
  background-color: #ffffff;
  border: 2px solid;
  border-color: #6079c1;
  transition: all 0.3s ease;
}

.button.color-0-eight {
  background-color: var(--lightpurple);
  border: 2px solid;
  border-color: var(--lightpurple);
}

.button.color-0-p-hover {
  background-color: #ffffff;
  border: 2px solid;
  border-color: #6079c1;
}

.button.color-0-w-hover {
  background-color: #ffffff;
  box-shadow: 0px 0px 10px #00000059;
}

.button.device-SP {
  height: 50px;
  width: 180px;
}

.button.device-SP.color-0-white {
  border: 2px solid;
  border-color: #6079c1;
}

.button.device-PC.color-0-white {
  transition: all 0.3s ease;
}

.button.color-0-g-hover .text-wrapper {
  color: #454545;
}

.button.color-0-gray .text-wrapper {
  color: #ffffff;
}

.button.color-0-white .text-wrapper {
  color: #454545;
}

.button.color-0-one .text-wrapper {
  color: #ffffff;
}

.button.color-0-seven .text-wrapper {
  color: #6079c1;
}

.button.color-0-eight .text-wrapper {
  color: #ffffff;
}

.button.color-0-p-hover .text-wrapper {
  color: #6079c1;
}

.button.color-0-w-hover .text-wrapper {
  color: #454545;
}

.button.color-0-g-hover .text-wrapper-2 {
  color: #ffffff;
}

.button.color-0-white-textgray .text-wrapper-2 {
  color: #454545;
}

.button.color-0-gray .text-wrapper-2 {
  color: #ffffff;
}

.button.color-0-white .text-wrapper-2 {
  color: #6079c1;
}

.button.color-0-one .text-wrapper-2 {
  color: #ffffff;
}

.button.color-0-purple .text-wrapper-2 {
  color: #ffffff;
}

.button.color-0-seven .text-wrapper-2 {
  color: #ffffff;
}

.button.color-0-eight .text-wrapper-2 {
  color: #ffffff;
}

.button.color-0-p-hover .text-wrapper-2 {
  color: #ffffff;
}

.button.color-0-w-hover .text-wrapper-2 {
  color: #ffffff;
}
