.SP-transparent {
  align-items: flex-start;
  display: flex;
  height: 45px;
  justify-content: space-between;
  padding: 0px 0px 20px 10px;
  position: relative;
  width: 320px;
}

.SP-transparent .logo-instance-wrapper {
  height: 35px;
  margin-bottom: -10px;
  position: relative;
  width: 79.18px;
}

.SP-transparent .design-component-instance-node {
  height: 27px !important;
  position: relative !important;
  top: 8px !important;
  width: 72px !important;
}
